const initialState = {
  instagram: {
    items: []
  }
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'GET_INSTAGRAM_FEED_FULFILLED':
      return {
        ...state,
        instagram: {
          items: action.payload
        }
      };
    default:
      return state;
  }
};
