import { redirect, back } from 'redux-first-router';

import routesMap from '../routesMap';
import config from '../config';
import { hasRole, hasExtendedRole } from '../helpers/user';

const { VISITOR, MEMBER } = config.roles;

const isAllowed = (type, state) => {
  const routeRole = routesMap[type] && routesMap[type].role;

  if (!routeRole) return true;

  const user = state.user;

  if (routeRole === VISITOR && hasRole(MEMBER, state)) {
    return false;
  }

  return user.roles.includes(routeRole);
};

const routeValidator = (dispatch, getState, action) => {
  const state = getState();
  const allowed = isAllowed(action.type, state);

  // Is user allowed to view the current page?
  if (!allowed) {
    // Is user logged in?
    if (hasRole('member', state)) {
      // Does user come from a redirect?
      if (action.kind === 'redirect') {
        // If user is a buyer, redirect to the dashboard page defined in routesMap
        // Make sure buyers don't land on hidden creator dashboard pages when switching between accounts
        if (!hasExtendedRole('creator', state)) {
          dispatch(
            redirect({
              type: 'DASHBOARD',
              payload: action.prev.payload
            })
          );
          return;
        }
        // then redirect him back from where he came
        dispatch(
          redirect({
            type: action.prev.type,
            payload: action.prev.payload
          })
        );
      } else {
        if (action.prev?.type) {
          back();

          return;
        }

        dispatch(redirect({ type: 'DASHBOARD' }));
      }
    } else {
      dispatch(redirect({ type: 'LOGIN' }));
    }
  }
};

export default routeValidator;
