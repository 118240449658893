import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import config from '../../../../config';
import { theme } from '../../../../theme-config';

import Image from '../../../atoms/Image';

import { Rail, RailSlide } from '..';
import ProductThumbnail from '../../Thumbnails/ProductThumbnail';

const FeaturedCampaignsRail = ({ title, subtitle, campaigns }) => {
  const dispatch = useDispatch();
  const type = 'Featured Campaigns';

  return (
    <Rail type={type} title={title} subtitle={subtitle}>
      {campaigns.map((item) => {
        return (
          <RailSlide key={item.campaignId}>
            <div
              className="py-15"
              onClick={() =>
                dispatch({
                  type: 'RAIL_CAMPAIGN_CLICK',
                  payload: {
                    type,
                    title
                  }
                })
              }
            >
              <ProductThumbnail
                image={
                  <Image
                    src={item.imageUrl}
                    alt={item.imageAlt}
                    width="875"
                    height={875 / config.productShotsRatio}
                    placeholderColor={
                      item.greyBackground
                        ? theme.colors['dark-grey']
                        : theme.colors.white
                    }
                  />
                }
                title={item.title}
                subTitle={item.subTitle}
                price={item.prices}
                timeLeft={item.endAt}
                slug={item.slug}
                isGreyBg={item.greyBackground}
              />
            </div>
          </RailSlide>
        );
      })}
    </Rail>
  );
};

FeaturedCampaignsRail.propTypes = {
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      campaignId: PropTypes.number,
      imageUrl: PropTypes.string,
      imageAlt: PropTypes.string,
      title: PropTypes.string,
      subTitle: PropTypes.string,
      prices: PropTypes.array,
      endAt: PropTypes.string,
      slug: PropTypes.string,
      greyBackground: PropTypes.bool
    })
  ).isRequired
};

export default FeaturedCampaignsRail;
