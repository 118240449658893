import React from 'react';
import { useSelector } from 'react-redux';

import Heading from '../../../../components/atoms/Heading';
import Paragraph from '../../../../components/atoms/Paragraph';
import Price from '../../../../components/atoms/Price';
import Spacing from '../../../../components/atoms/Spacing';
import { TooltipNew as Tooltip } from '../../../../components/atoms/Tooltip';
import Icon from '../../../../components/atoms/Icon';

import questionMarkIcon from '../../../../img/sprites/question-mark-circle.svg';

import generalStyles from './style/general.module.css';

const tooltipDtgContent = (
  <div className="flex flex-col gap-3">
    <Heading tag="h4" size="xxs">
      Digital printing
    </Heading>
    <Paragraph size="xxs">
      Digital printing is used on lower quantities, or designs with a lot of
      detail or colours. The low setup cost of digital printing means there's no
      minimum sales quantity and you profit from the first item sold.
    </Paragraph>
  </div>
);

const tooltipScreenContent = (
  <div className="flex flex-col gap-3">
    <Heading tag="h4" size="xxs">
      Screen printing
    </Heading>
    <Paragraph size="xxs">
      Screen printing produces excellent quality, long-lasting results and is
      better suited to artwork with a few solid colours. The number of colours
      in the design determines the price and minimum quantity required.
    </Paragraph>
  </div>
);

const ProfitEstimate = () => {
  const { profitEstimate, goal } = useSelector(
    (state) => state.campaignBuilder
  );

  const { amount, currencyIso, screenThreshold } = profitEstimate || {};

  return (
    <div>
      <div>
        <Heading tag="h4" size="xs">
          Profit estimate
        </Heading>

        <Spacing size={3} position="t">
          <Paragraph size="xxs">
            With {goal} sales you'll earn an estimated:
          </Paragraph>
        </Spacing>

        <Spacing size={1} position="t">
          <div className={generalStyles.profit}>
            <Price value={amount} currency={currencyIso} className="text-xxl" />
            <span>per campaign</span>
          </div>
        </Spacing>

        <div className="relative">
          <Spacing size={3}>
            <hr className="border-grey-lighter" />
          </Spacing>
        </div>

        <Heading tag="h4" size="xs">
          Print method
        </Heading>

        <Spacing size="05" position="t">
          <Paragraph size="xxs">
            The print method is based on your artwork and how many items are
            sold.
          </Paragraph>
        </Spacing>

        {screenThreshold && (
          <Spacing size={15} position="t">
            <table className={generalStyles.printMethod}>
              <thead>
                <tr>
                  <th>No. of Sales</th>
                  <th>Print Method</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1 - {screenThreshold - 1} sales</td>
                  <td>
                    <span className="flex items-center gap-3">
                      Digital Printing
                      <Tooltip
                        id="tooltip-dtg"
                        content={tooltipDtgContent}
                        placement="bottom"
                        offset={7}
                        align="start"
                        alignOffset={-10}
                        width={380}
                      >
                        <Icon
                          className="mb-px opacity-25 hover:opacity-100 transition-opacity duration-100"
                          glyph={questionMarkIcon}
                          width={14}
                          height={14}
                        />
                      </Tooltip>
                    </span>
                  </td>
                  <td>
                    {goal < screenThreshold && (
                      <span className={generalStyles.badge}>
                        Based on your estimate
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>{screenThreshold}+ sales</td>
                  <td>
                    <span className="flex items-center gap-3">
                      Screen Printing
                      <Tooltip
                        id="tooltip-dtg"
                        content={tooltipScreenContent}
                        placement="bottom"
                        offset={7}
                        align="start"
                        alignOffset={-10}
                        width={380}
                      >
                        <Icon
                          className="mb-px opacity-25 hover:opacity-100 transition-opacity duration-100"
                          glyph={questionMarkIcon}
                          width={14}
                          height={14}
                        />
                      </Tooltip>
                    </span>
                  </td>
                  <td>
                    {goal >= screenThreshold && (
                      <span className={generalStyles.badge}>
                        Based on your estimate
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Spacing>
        )}
      </div>
    </div>
  );
};

export default ProfitEstimate;
