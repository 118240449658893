import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import Page from '../../components/utils/Page';
import Loading from '../../components/utils/Loading';

import Alert from '../../components/atoms/Alert';
import { ButtonNew as Button } from '../../components/atoms/Button';
import { CSSGrid } from '../../components/atoms/Grid';
import Heading from '../../components/atoms/Heading';
import Icon from '../../components/atoms/Icon';
import Link from '../../components/atoms/Link';
import PageWrap from '../../components/atoms/PageWrap';
import Paragraph from '../../components/atoms/Paragraph';
import Spacing from '../../components/atoms/Spacing';

import FavouriteItem from './FavouriteItem';

import favouriteIcon from '../../img/sprites/favourite.svg';

import generalStyles from './style/general.module.css';

const Favourites = () => {
  const { list, status } = useSelector(
    (state) => state.user.favourites,
    shallowEqual
  );
  const isLoading = status === 'pending';
  const isRejected = status === 'rejected';

  if (isLoading) return <Loading />;

  return (
    <Page title="Favourites">
      <PageWrap>
        <Spacing size={3} position="t">
          <Heading size={['xs', 's']} tag="h1">
            Favourites
          </Heading>
        </Spacing>
        {list.length > 0 ? (
          <>
            <Spacing size="05" position="t">
              <Paragraph size="xxs" color="grey-dark">
                We’ll send you a heads up by email when campaigns in your
                Favourites are coming to an end. You can{' '}
                <Link to="/dashboard/settings/favourite-preferences">
                  opt out
                </Link>{' '}
                of these emails.
              </Paragraph>
            </Spacing>
            {isRejected && (
              <Spacing size={2}>
                <Alert message="Something went wrong, please refresh the page" />
              </Spacing>
            )}
            <Spacing size={2} position="t">
              <CSSGrid
                template={['auto / 1fr', 'auto / repeat(3, minmax(0, 1fr))']}
                gap={2}
              >
                {list.map((item) => (
                  <FavouriteItem key={item.userFavouriteId} favourite={item} />
                ))}
              </CSSGrid>
            </Spacing>
          </>
        ) : (
          <>
            {isRejected && (
              <Spacing size={3} position="b">
                <Spacing size={1}>
                  <Alert message="Something went wrong, please refresh the page" />
                </Spacing>
              </Spacing>
            )}
            <div className={generalStyles.emptyFavouriteContainer}>
              <div className={generalStyles.icon}>
                <Icon glyph={favouriteIcon} width="20" height="20" />
              </div>
              <Spacing size={2}>
                <Paragraph size="xxs">
                  <span className="block text-center">
                    Items added to your favourites will be saved here.
                  </span>
                  <span className="block text-center">
                    We’ll send you a reminder when a campaign in your favourites
                    is about to end.
                  </span>
                </Paragraph>
              </Spacing>
              <Button to="/shop">Shop now</Button>
            </div>
          </>
        )}
      </PageWrap>
    </Page>
  );
};

export default Favourites;
