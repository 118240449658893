import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import DisclosureContext from '../DisclosureContext'

const DisclosureButton = React.forwardRef(
  ({ as: Comp = 'button', toggle = false, children, ...props }, forwardedRef) => {
    const { onSelect, open, panelId } = useContext(DisclosureContext);

    const handleClick = (event) => {
      event.preventDefault();
      onSelect();
    };

    const isHidden = toggle ? false : open;

    return (
      <Comp
        ref={forwardedRef}
        hidden={isHidden}
        aria-controls={panelId}
        aria-expanded={open}
        onClick={handleClick}
        {...props}
      >
        {children}
      </Comp>
    );
  }
);

export default DisclosureButton;

DisclosureButton.displayName = "DisclosureButton";

DisclosureButton.propTypes = {
  as: PropTypes.any,
  toggle: PropTypes.bool,
  children: PropTypes.node,
};
