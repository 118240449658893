import React, { useState } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as Sentry from "@sentry/react";

import { leadSignUp } from '../../../actions/user';

import { ButtonNew } from '../../../components/atoms/Button';
import Heading from '../../../components/atoms/Heading';
import {
  DefaultField as Field,
  Error,
  Form,
  handleFormError
} from '../../../components/atoms/Form';
import Spacing from '../../../components/atoms/Spacing';
import Paragraph from '../../../components/atoms/Paragraph';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .max(150)
    .required('Please enter your first name'),
  email: yup
    .string()
    .email('The email address is invalid')
    .max(150)
    .required('Please enter your email'),
  url: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
    )
    .max(150)
    .required('Please enter your sites URL'),
  platform: yup
    .string()
    .max(150)
    .required('Please select your platform')
});

const IntegrationsLeadForm = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const [hasRegisterError, setRegisterError] = useState(false);

  const handleSignUp = async (values, actions) => {
    const { name, email, url, platform, otherPlatform } = values;

    if (platform === 'other' && !otherPlatform.length) {
      actions.setFieldError('otherPlatform', 'Please enter your platform');
      actions.setSubmitting(false);
    } else {
      setRegisterError(false);
      actions.setSubmitting(true);

      try {
        await dispatch(
          leadSignUp({
            source: 'Integrations',
            firstName: name,
            email,
            url,
            description: platform === 'other' ? otherPlatform : platform
          })
        );

        onSuccess();
      } catch (error) {
        setRegisterError(true);
        handleFormError(error, actions);
        actions.setSubmitting(false);
        Sentry.captureException(error);
      }
    }
  };

  return (
    <>
      <Heading tag="h1" size="m">
        Sell your Everpress merch through your third party shops
      </Heading>
      <Spacing size={15} position="t">
        <Paragraph size="xxs">
          We’re creating integrations with the best ecommerce platforms.
        </Paragraph>
        <Spacing size={15} position="y">
          <Paragraph size="xxs" color="grey-dark">
            Create t-shirts, hoodies or sweats with us. Promote and sell them
            through your own website or external shopping platform. We’ll get
            them printed and shipped to your customers. It’s zero risk, zero
            waste.
          </Paragraph>
        </Spacing>
        <Paragraph size="xxs" color="grey-dark">
          Register your interest in joining the beta.
        </Paragraph>
      </Spacing>
      <Spacing size={3} position="t">
        <Form
          initialValues={{
            name: '',
            email: '',
            url: '',
            platform: '',
            otherPlatform: ''
          }}
          onSubmit={handleSignUp}
          validationSchema={validationSchema}
          render={({ values, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Spacing size={15}>
                <Field type="input" name="name" placeholder="Name" />
              </Spacing>
              <Spacing size={15} position="t">
                <Field type="email" name="email" placeholder="Email address" />
              </Spacing>
              <Spacing size={15} position="t">
                <Field
                  type="input"
                  name="url"
                  placeholder="Your website's URL"
                />
              </Spacing>
              <Spacing size={15} position="t">
                <Field
                  type="select"
                  name="platform"
                  placeholder="Your website's platform"
                  options={[
                    { value: 'shopify', label: 'Shopify' },
                    { value: 'squarespace', label: 'Squarespace' },
                    { value: 'wix', label: 'Wix' },
                    { value: 'wooCommerce', label: 'WordPress (WooCommerce)' },
                    { value: 'etsy', label: 'Etsy' },
                    {
                      value: 'adobeCommerce',
                      label: 'Magento / Adobe Commerce'
                    },
                    {
                      value: 'bandcamp',
                      label: 'Bandcamp'
                    },
                    { value: 'other', label: 'Other' }
                  ]}
                />
              </Spacing>
              {values.platform === 'other' ? (
                <Spacing size={15} position="t">
                  <Field name="otherPlatform" placeholder="Please specify" />
                </Spacing>
              ) : null}
              <Spacing size={3} position="t">
                <ButtonNew
                  type="submit"
                  state={isSubmitting ? 'loading' : null}
                  fullWidth
                >
                  Register interest
                </ButtonNew>
                {hasRegisterError ? (
                  <Spacing size={[1, 2]} position="t">
                    <Error>
                      Unable to register your details, please try again later.
                    </Error>
                  </Spacing>
                ) : null}
              </Spacing>
            </form>
          )}
        />
      </Spacing>
    </>
  );
};

IntegrationsLeadForm.propTypes = {
  onSuccess: PropTypes.func.isRequired
};

export default IntegrationsLeadForm;
