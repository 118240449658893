import produce from 'immer';

const initialState = {
  dashboard: [] // Christmas time notification is triggered on Dashboard page index.js
};

export default (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case 'ADD_NOTIFICATION': {
        const { notification, page } = action.payload;
        if (state[page].some((item) => item.id === notification.id)) {
          return state;
        }
        newState[page].push(notification);
        return newState;
      }
      case 'REMOVE_NOTIFICATION': {
        const { id, page } = action.payload;
        newState[page] = state[page].filter((item) => item.id !== id);
        return newState;
      }
      default:
        return state;
    }
  });
