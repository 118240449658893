import config from '../config';

export default function fbq(...params) {
  if (window.fbq) {
    return window.fbq(...params);
  }

  return init(config.facebookPixel).then(() => window.fbq(...params));
}

export function init(facebookPixelId) {
  return import('react-facebook-pixel').then(module => {
    const ReactPixel = module.default;
    ReactPixel.init(facebookPixelId);
    window.fbq('track', 'PageView');
  });
}
