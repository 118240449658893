import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import * as userActions from '../../actions/user';

import Page from '../../components/utils/Page';
import MaxWidth from '../../components/atoms/MaxWidth';
import PageWrap from '../../components/atoms/PageWrap';

import Spacing from '../../components/atoms/Spacing';

import ResetPasswordForm from '../../components/molecules/AuthenticationContainer/ResetPasswordForm';
import ResetPasswordSuccessForm from '../../components/molecules/AuthenticationContainer/ResetPasswordSuccessForm';

import everpressLogo from '../../img/everpress-logo.svg';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [isPasswordReset, setPasswordReset] = useState(false);

  const handleUserStageChange = (stage) => {
    switch (stage) {
      case 'signIn':
        return dispatch({
          type: 'LOGIN'
        });
      case 'resetPasswordSuccess':
        return setPasswordReset(true);
      default:
        return null;
    }
  };

  const handleResetPassword = async ({ email }) => {
    await dispatch(userActions.recoverPassword(email));
    setEmail(email);
    handleUserStageChange('resetPasswordSuccess');
  };

  return (
    <Page title="Reset Password">
      <PageWrap className="text-center">
        <MaxWidth center value={415}>
          <Spacing size={15} position="y">
            <img
              src={everpressLogo}
              alt="Everpress logo"
              width="40"
              height="35"
              className="m-auto"
            />
            <Spacing size={15} position="y">
              {isPasswordReset ? (
                <ResetPasswordSuccessForm
                  onUserStageChange={handleUserStageChange}
                  email={email}
                />
              ) : (
                <ResetPasswordForm
                  onUserStageChange={handleUserStageChange}
                  onResetPassword={handleResetPassword}
                />
              )}
            </Spacing>
          </Spacing>
        </MaxWidth>
      </PageWrap>
    </Page>
  );
};

const mapStateToProps = ({ location }) => ({
  token: location.payload.token
});

export default connect(mapStateToProps, {
  ...userActions,
  goToLogin: () => ({ type: 'LOGIN' })
})(ResetPassword);
