import config from '../config';

export const getFeaturedProfiles = () => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'GET_FEATURED_PROFILES',
    payload: baseApi.get('/profiles/featured')
  });

export const getCategoryTags = () => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'GET_CATEGORY_TAGS',
    payload: baseApi.get('/discovery/categories')
  });

export const getFeaturedCampaigns = (tag = config.shop.defaultTag.value) => (
  dispatch,
  _,
  { baseApi }
) =>
  dispatch({
    type: 'GET_FEATURED_CAMPAIGNS',
    payload: baseApi.get(`/discovery/campaigns/${tag}`),
    meta: { tag }
  });

export const getFeaturedTags = (tag) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'GET_FEATURED_TAGS',
    payload: baseApi.get('/discovery/campaigns/v1/featured')
  });
