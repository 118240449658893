import React, { Fragment } from 'react';

import Spacing from '../../../components/atoms/Spacing';
import Heading from '../../../components/atoms/Heading';
import Paragraph from '../../../components/atoms/Paragraph';
import MaxWidth from '../../../components/atoms/MaxWidth';
import Link from '../../../components/atoms/Link';
import { Grid, GridItem } from '../../../components/atoms/Grid';
import Icon from '../../../components/atoms/Icon';

import Item from '../Item';
import Feature from '../Feature';

import creatorsItems from '../creatorsItems';
import features from '../features';

import coinsIcon from '../../../img/sprites/coins.svg';
import stockIcon from '../../../img/sprites/stock.svg';
import meetingIcon from '../../../img/sprites/meeting.svg';
import webIcon from '../../../img/sprites/web-design.svg';

import tableStyles from '../style/table.module.css';

export default function Creators() {
  return (
    <Fragment>
      {creatorsItems.map((item, index) => {
        const newIndex = `0${index + 1}`;

        return <Item key={item.id} {...item} index={newIndex} />;
      })}

      <Spacing size={8} type="padding">
        <MaxWidth center value={992}>
          <Heading tag="div" center size={['m', 'l', 'xl']}>
            Explore the features that make Everpress the simplest and most
            powerful way to sell high quality products online.
          </Heading>
        </MaxWidth>
      </Spacing>

      <div className={tableStyles.table}>
        <div className={tableStyles.tableItem}>
          <Grid gap={0}>
            <GridItem columnSize={3}>
              <Icon width={76} height={71} glyph={coinsIcon} />
            </GridItem>
            <GridItem columnSize={9}>
              <Spacing size={2} position="b">
                <Heading>Pricing & Profits</Heading>
              </Spacing>
              <Paragraph>
                How much you make depends on how much is sold.{' '}
                <Link to="/price-calculator">Calculate your earnings</Link> to
                see how much you can earn on your first campaign.
              </Paragraph>
            </GridItem>
          </Grid>
        </div>
        <div className={tableStyles.tableItem}>
          <Grid gap={0}>
            <GridItem columnSize={3}>
              <Icon width={76} height={71} glyph={stockIcon} />
            </GridItem>
            <GridItem columnSize={9}>
              <Spacing size={2} position="b">
                <Heading>Advanced sales analytics</Heading>
              </Spacing>
              <Paragraph>
                Track the performance of your design and use advanced data and
                analytics to help make more sales.
              </Paragraph>
            </GridItem>
          </Grid>
        </div>
        <div className={tableStyles.tableItem}>
          <Grid gap={0}>
            <GridItem columnSize={3}>
              <Icon width={76} height={71} glyph={meetingIcon} />
            </GridItem>
            <GridItem columnSize={9}>
              <Spacing size={2} position="b">
                <Heading>Customer Support</Heading>
              </Spacing>
              <Paragraph>
                Our customer service team of living, breathing humans are only
                happy if you and your customers are happy. Have a question?{' '}
                <Link to="/contact">Get in touch</Link>.
              </Paragraph>
            </GridItem>
          </Grid>
        </div>
        <div className={tableStyles.tableItem}>
          <Grid gap={0}>
            <GridItem columnSize={3}>
              <Icon width={76} height={71} glyph={webIcon} />
            </GridItem>
            <GridItem columnSize={9}>
              <Spacing size={2} position="b">
                <Heading>Personalise your shop</Heading>
              </Spacing>
              <Paragraph>
                You have the keys to your own personal online shop; dress it up
                with whatever bio, photos, video or social media embeds you
                want.
              </Paragraph>
            </GridItem>
          </Grid>
        </div>
      </div>

      <Spacing size={8}>
        <Heading size={['l', 'xl', 'xxl']} center tag="h2">
          More features
        </Heading>
      </Spacing>

      <Grid gap={[0, 2, 2, 4]} align="start">
        {features.map((item) => {
          return (
            <GridItem key={item.id} columnSize={[12, 6, 6, 4]}>
              <Feature {...item}>{item.content}</Feature>
            </GridItem>
          );
        })}
      </Grid>
    </Fragment>
  );
}
