import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';
import classNames from 'classnames';

import WrapIf from '../../../utils/WrapIf';

import Heading from '../../../atoms/Heading';
import Paragraph from '../../../atoms/Paragraph';
import Spacing from '../../../atoms/Spacing';
import Price from '../../../atoms/Price';
import Image from '../../../atoms/Image';
import CampaignTimeLeft from '../../../atoms/CampaignTimeLeft';
import { BadgeNew as Badge } from '../../../atoms/Badge';
import Icon from '../../../atoms/Icon';
import { InlineGrid } from '../../../atoms/Grid';

import ShopPODCampaign from '../../ShopPODCampaign';

import config from '../../../../config';
import { theme } from '../../../../theme-config';

import trendingIcon from '../../../../img/sprites/trending-up.svg';

import generalStyles from './style/general.module.css';

export default function ProductThumbnail({
  image,
  title,
  subTitle,
  price,
  timeLeft,
  slug,
  isGreyBg,
  isPrintOnDemand,
  isFeatured,
  alt,
  children
}) {
  return (
    <div className={generalStyles.container}>
      {isFeatured && (
        <div className={generalStyles.badge}>
          <Badge kind="inverted" size="xxxs">
            <InlineGrid align="center">
              <Spacing size="05" position="r">
                <Icon glyph={trendingIcon} width={13} height={13} />
              </Spacing>
              Trending
            </InlineGrid>
          </Badge>
        </div>
      )}
      {children && (
        <div
          className={classNames(generalStyles.children, {
            [generalStyles.multipleChildren]:
              React.Children.toArray(children).length > 1
          })}
        >
          {children}
        </div>
      )}
      <WrapIf
        condition={slug}
        wrapper={(children) => (
          <Link to={`/${slug}`} className={generalStyles.link}>
            {children}
          </Link>
        )}
      >
        <Fragment>
          <Spacing position="b" size={1}>
            {typeof image === 'string' ? (
              <Image
                src={image}
                alt={alt ? alt : title}
                width="875"
                height={875 / config.productShotsRatio}
                placeholderColor={
                  isGreyBg ? theme.colors['dark-grey'] : '#FFFFFF'
                }
              />
            ) : (
              image
            )}
          </Spacing>
          {isPrintOnDemand ? (
            <ShopPODCampaign title={title} />
          ) : (
            <Heading
              className="ellipsis leading-relaxed"
              size={['xxs', 'xs']}
              tag="h2"
            >
              {title}
            </Heading>
          )}
          <Paragraph size="xxs">{subTitle}</Paragraph>
          {timeLeft && (
            <Paragraph size="xxs">
              <CampaignTimeLeft endAt={timeLeft} />
            </Paragraph>
          )}
          <Paragraph size={['xxs', 'xs']}>
            <Price
              value={price}
              className={classNames('inline-block', { 'my-px': !price })}
            />
          </Paragraph>
        </Fragment>
      </WrapIf>
    </div>
  );
}

ProductThumbnail.propTypes = {
  image: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  price: PropTypes.any.isRequired,
  timeLeft: PropTypes.string.isRequired,
  slug: PropTypes.any,
  isGreyBg: PropTypes.bool,
  isPrintOnDemand: PropTypes.bool,
  isFeatured: PropTypes.bool,
  alt: PropTypes.string,
  children: PropTypes.node
};

ProductThumbnail.defaultProps = {
  isGreyBg: true,
  isFeatured: false
};
