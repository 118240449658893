import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import every from 'lodash/every';
import some from 'lodash/some';

import * as orderActions from '../../actions/orders';
import * as userActions from '../../actions/user';

import { getCampaignById } from '../../helpers/selectors/campaigns';

import FollowArtists from './FollowArtists';
import SignUpForm from './SignUpForm';
import SignInForm from './SignInForm';

import {
  handleFormError,
  handleFormSuccess
} from '../../components/atoms/Form';
import { Grid, GridItem } from '../../components/atoms/Grid';
import Heading from '../../components/atoms/Heading';
import Link from '../../components/atoms/Link';
import { PageWrapNew as PageWrap } from '../../components/atoms/PageWrap';
import Paragraph from '../../components/atoms/Paragraph';
import MaxWidth from '../../components/atoms/MaxWidth';
import Price from '../../components/atoms/Price';
import Spacing from '../../components/atoms/Spacing';

import CheckoutBox from '../../components/molecules/CheckoutBox/New';
import ShippingAddress from '../../components/molecules/ShippingAddress';
import { CtaBox } from '../../components/molecules/Box';

import OrderOverview from '../../components/organisms/OrderOverview';

import Loading from '../../components/utils/Loading';
import Page from '../../components/utils/Page';

class Order extends PureComponent {
  constructor() {
    super();

    this.state = {
      isGuestInitial: false
    };
  }

  componentDidMount() {
    if (this.props.isGuest) {
      this.setState({
        isGuestInitial: true
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.order && this.props.order !== prevProps.order) {
      if (this.props.isGuest) {
        this.props.lookupUser(this.props.order.email);
      }
    }
  }

  async handleSignUp(formData, actions) {
    try {
      await this.props.signUp(formData);
      await this.props.claimOrder(this.props.order.token);
      actions.resetForm();
      const successMessage = (
        <span>
          Thanks, you can now view details of your orders within your{' '}
          <Link to={`/dashboard/orders/${this.props.order.orderId}`}>
            account dashboard
          </Link>
          .
        </span>
      );
      handleFormSuccess(successMessage, actions);
    } catch (error) {
      handleFormError(error, actions);
      return actions.setSubmitting(false);
    }
  }

  async handleSignIn(formData, actions) {
    try {
      await this.props.login(formData.email, formData.password);
      await this.props.claimOrder(this.props.order.token);
      this.props.getUserFollowees();
      actions.resetForm();
      const successMessage = (
        <span>
          Thanks, you can now check the status of your order within your{' '}
          <Link to={`/dashboard/orders/${this.props.order.orderId}`}>
            account dashboard
          </Link>
          .
        </span>
      );
      handleFormSuccess(successMessage, actions);
    } catch (error) {
      handleFormError(error, actions);
      return actions.setSubmitting(false);
    }
  }

  render() {
    const {
      order,
      totalPrice,
      campaigns,
      isLoaded,
      isGuest,
      isExisting,
      userId
    } = this.props;

    const { isGuestInitial } = this.state;
    const isMyOwnCampaign =
      campaigns.length === 1 && userId === campaigns[0].user.userId;
    const isOnlySamples = every(campaigns, { state: 'draft' });
    const hasGiftCards = some(order?.items, 'giftCardId');

    if (!isLoaded) {
      return <Loading />;
    }

    if (order && isLoaded) {
      return (
        <Page title="Order confirmation">
          <PageWrap>
            <Spacing size={2} position="t" type="padding">
              <Grid gap={[0, 0, 15, 25]} align="start">
                <GridItem columnSize={[12, 12, 12, 6]}>
                  <div className="mdlg:border-b-1">
                    <Spacing size={[2, 1]} position={['t', 'b']}>
                      <Heading size="xs">
                        {order.orderId && `Order #${order.orderId}`}
                      </Heading>
                    </Spacing>
                  </div>
                  <Spacing size={[1, 1, 1, 4]} position="t">
                    <Heading size="m">
                      Thank you <br /> For your order
                    </Heading>
                  </Spacing>
                  <Spacing size={2} position="b">
                    <MaxWidth value={500}>
                      {order.email && (
                        <Spacing size={1}>
                          <Paragraph size="xxs">
                            We've sent a confirmation to {order.email} and we’ll
                            keep you updated on the progress of your order.
                          </Paragraph>
                        </Spacing>
                      )}
                    </MaxWidth>
                  </Spacing>
                  {isGuestInitial && (
                    <div className="border-t-1 border-grey-lighter">
                      <Spacing size={2} position="y">
                        {isExisting ? (
                          <SignInForm
                            initialValues={order}
                            isDisabled={!isGuest}
                            onSignIn={this.handleSignIn.bind(this)}
                          />
                        ) : (
                          <SignUpForm
                            initialValues={order}
                            isDisabled={!isGuest}
                            onSignUp={this.handleSignUp.bind(this)}
                          />
                        )}
                      </Spacing>
                    </div>
                  )}
                  {!isMyOwnCampaign && !isOnlySamples && (
                    <div className="border-t-1 border-grey-lighter">
                      <FollowArtists
                        order={order}
                        campaignLength={campaigns.length}
                        campaigns={campaigns}
                      />
                    </div>
                  )}
                  <div className="mdlg:border-b-1 mt-1 md:mt-2" />
                </GridItem>

                <GridItem columnSize={[12, 12, 12, 6]}>
                  <Spacing size={2} position="t">
                    <div className="relative">
                      <div className="-mx-15 absolute w-screen h-full bg-grey-lightest -z-1 md:hidden" />
                      <div className="-mx-15 border-t-1 border-grey-lighter md:mx-0 mdlg:border-t-0" />
                      <Spacing size={1} position="y">
                        <Heading size="xs">Shopping bag overview</Heading>
                      </Spacing>
                      <div className="-mx-15 border-b-1 border-grey-lighter md:mx-0 mdlg:border-black" />
                      <Spacing size={[15, 15, 15, 2]} position="t">
                        <OrderOverview
                          items={order.items}
                          campaigns={campaigns}
                          deliveries={order.deliveries}
                          currency={order.currency}
                        />
                        <div className="border-t-1 border-grey-lighter" />
                        <CheckoutBox title="Total">
                          <Price
                            value={totalPrice}
                            currency={order.currency}
                            size={['xs', 'xs', 'xs', 's']}
                          />
                        </CheckoutBox>
                        <div className="mdlg:border-b-1 mdlg:border-black" />
                        <div className="-mx-15 border-b-1 border-grey-lighter md:mx-0 mdlg:border-0" />
                      </Spacing>
                    </div>
                  </Spacing>

                  <Spacing size={[15, 15, 15, 1]} position="t">
                    {hasGiftCards ? (
                      <CtaBox title="Gift Cards">
                        <Paragraph size="xxs">
                          You’ll receive your gift card in your email inbox
                          shortly, along with instructions on how to redeem it.
                        </Paragraph>
                        <Paragraph size="xxs">
                          For more information, check our{' '}
                          <Link href="/creator-toolkit/everpress-gift-cards-another-way-to-support-creativity">
                            Gift Card FAQs
                          </Link>
                          .
                        </Paragraph>
                      </CtaBox>
                    ) : (
                      <CtaBox title="Gift Cards">
                        <Paragraph size="xxs">
                          Everpress does Gift Cards. Delivered directly to your
                          inbox and easy to share.{' '}
                          <Link to="/gift-cards">Check it out</Link>.
                        </Paragraph>
                      </CtaBox>
                    )}
                  </Spacing>

                  <Spacing size={[15, 15, 15, 1]} position="t">
                    <ShippingAddress order={order} isGuest={isGuest} />
                  </Spacing>
                </GridItem>
              </Grid>
            </Spacing>
          </PageWrap>
        </Page>
      );
    }

    if (!order && isLoaded) {
      return 'Order not found.';
    }
  }
}

const mapStateToProps = ({ location, orders, campaigns, countries, user }) => {
  const { token } = location.payload;
  const order = orders.bySlug[token] && orders.bySlug[token].data;
  const isLoaded = order && orders.bySlug[token].isLoaded;
  return {
    isLoaded,
    totalPrice: order && order.total,
    shippingPrice: order && order.delivery,
    deliveries: order && order.deliveries,
    order,
    campaigns: order
      ? order.items
          .filter((item) => item.campaignId)
          .map((campaign) => getCampaignById(campaign.campaignId, campaigns))
      : [],
    giftCards: order ? order.items.filter((item) => item.giftCardId) : [],
    country:
      isLoaded &&
      countries.find(
        (country) => country.iso === order.deliveryAddress.country
      ),
    isGuest: !user.token,
    isExisting: user.isExisting,
    userId: user.userId
  };
};

export default connect(mapStateToProps, {
  ...orderActions,
  ...userActions
})(Order);
