import React from 'react';

import Heading from '../../../../components/atoms/Heading';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';
import { CSSGrid } from '../../../../components/atoms/Grid';

import heroImg from '../../../../img/pages/people/hero.jpg';

const Hero = () => {
  return (
    <section>
      <CSSGrid columns={['none', 'none', 'none', 'repeat(2, 1fr)']}>
        <div className="flex flex-col h-100">
          <div className="border-b-1 border-grey-lighter flex-1 flex p-3">
            <Heading
              size={['m', 'l', 'xl', 'xl', 'xxl']}
              center
              tag="h1"
              className="m-auto py-7 mdlg:py-0"
            >
              People & Culture at Everpress
            </Heading>
          </div>
          <Spacing size={[0, 0, 0, 3]} position="x">
            <Spacing size={[4, 6, 8]}>
              <Paragraph>
                At the heart of Everpress is a belief in the power of creativity
                for good. We started with a clear mission - to support
                grassroots creators while reducing the waste in fashion - and
                every year we see more potential to give back and do more as we
                grow.
              </Paragraph>
              <Paragraph className="mt-2">
                We’ve learnt a lot along the way, finding better ways to support
                the community, protect the planet, improve our garments and
                build a business. We want to share our journey (both the
                learnings and the mistakes) openly with our community, always.
                This page shares more about our actions and aspirations for the
                people and culture of Everpress.
              </Paragraph>
            </Spacing>
          </Spacing>
        </div>
        <img src={heroImg} alt="People &amp; Culture at Everpress" />
      </CSSGrid>
    </section>
  );
};

export default Hero;
