import React, { Fragment } from 'react';

import Spacing from '../../../../components/atoms/Spacing';
import Heading from '../../../../components/atoms/Heading';
import Paragraph from '../../../../components/atoms/Paragraph';
import Tag from '../../../../components/atoms/Tag';
import { Grid, GridItem } from '../../../../components/atoms/Grid';

import { Box, BoxItem } from '../../../../components/molecules/Box';
import { Modal } from '../../../../components/molecules/Modal';
import {
  Table,
  TableHeader,
  TableItem,
  TableRow
} from '../../../../components/molecules/Table';

import styles from './styles/general.module.css';

import everpressLogo from '../../../../img/everpress-logo.svg';
import TrustBox from '../../../../components/atoms/TrustBox';

class ProductInfo extends React.Component {
  state = {
    deliveryModalOpen: false
  };

  toggleDeliveryModal() {
    this.setState({ deliveryModalOpen: !this.state.deliveryModalOpen });
  }

  render() {
    const { campaign, campaignProductId, onSizeChartClick } = this.props;
    const { deliveryModalOpen } = this.state;
    const selectedItem = campaign.products.find(
      (product) => product.campaignProductId === campaignProductId
    );

    return (
      <Fragment>
        <Modal
          onRequestClose={this.toggleDeliveryModal.bind(this)}
          isOpen={deliveryModalOpen}
          isTransparent
        >
          <Spacing size={[2, 5]} position="x">
            <Spacing size={2} position="b">
              <Heading tag="div" size="xs">
                Shipping times & costs
              </Heading>
            </Spacing>
            <Table
              className={styles.deliveryTable}
              RowProps={{
                borderColor: 'dark-grey',
                borderWidth: { x: 0, y: 1 }
              }}
              ItemProps={{ paddingSize: { y: 1, x: 0 } }}
            >
              <TableHeader className={styles.deliveryTableHeader}>
                <TableItem columnSize={2}>Country</TableItem>
                <TableItem columnSize={4}>Standard</TableItem>
                <TableItem columnSize={4}>Tracked</TableItem>
                <TableItem columnSize={2}>Shipping time</TableItem>
              </TableHeader>
              <TableRow>
                <TableItem columnSize={2}>UK</TableItem>
                <TableItem columnSize={4}>
                  £3.95 + £1.00 per additional item
                </TableItem>
                <TableItem columnSize={4}>
                  £5.95 + £2.00 per additional item
                </TableItem>
                <TableItem columnSize={2}>2-5 business days</TableItem>
              </TableRow>
              <TableRow>
                <TableItem columnSize={2}>EU</TableItem>
                <TableItem columnSize={4}>
                  £6.00 + £1.50 per additional item
                </TableItem>
                <TableItem columnSize={4}>
                  £8.50 + £2.50 per additional item
                </TableItem>
                <TableItem columnSize={2}>8-10 business days</TableItem>
              </TableRow>
              <TableRow>
                <TableItem columnSize={2}>US</TableItem>
                <TableItem columnSize={4}>
                  £6.50 + £1.50 per additional item
                </TableItem>
                <TableItem columnSize={4}>
                  £9.50 + £2.50 per additional item
                </TableItem>
                <TableItem columnSize={2}>14 business days</TableItem>
              </TableRow>
              <TableRow>
                <TableItem columnSize={2}>Rest of World</TableItem>
                <TableItem columnSize={4}>
                  £7.00 + £2.00 per additional item
                </TableItem>
                <TableItem columnSize={4}>
                  £9.50 + £2.50 per additional item
                </TableItem>
                <TableItem columnSize={2}>14-21 business days</TableItem>
              </TableRow>
            </Table>
          </Spacing>
        </Modal>
        <Box size="m">
          <Grid gap={0} align="stretch">
            <GridItem columnSize={[12, 4]}>
              <BoxItem className="h-full">
                <Heading tag="h2" size="xs">
                  Product info
                </Heading>
                <Spacing size={2}>
                  <Spacing size={2}>
                    <Paragraph size="xxxs">Product ID</Paragraph>
                    <Tag>{selectedItem.product.sku}</Tag>
                  </Spacing>
                  <Paragraph
                    size="xxs"
                    dangerouslySetInnerHTML={{
                      __html:
                        selectedItem.product.customInfo ||
                        selectedItem.product.description
                    }}
                  />
                </Spacing>
              </BoxItem>
            </GridItem>
            <GridItem columnSize={[12, 4]}>
              <BoxItem>
                <Heading tag="h2" size="xs">
                  Garment care
                </Heading>
                <Spacing size={2}>
                  <Paragraph
                    size="xxs"
                    dangerouslySetInnerHTML={{
                      __html: selectedItem.product.care
                    }}
                  />
                </Spacing>
              </BoxItem>
              <BoxItem>
                <Heading tag="h2" size="xs">
                  Sizing
                </Heading>
                <Spacing size={2}>
                  <div
                    className="inline-block border-b-1 border-darker-grey cursor-pointer"
                    onClick={() => onSizeChartClick()}
                  >
                    <Paragraph size="xxs">View size chart</Paragraph>
                  </div>
                </Spacing>
              </BoxItem>
            </GridItem>
            <GridItem columnSize={[12, 4]}>
              <BoxItem border={['0', 'l']} className="h-full">
                <Heading tag="h2" size="xs">
                  Delivery
                </Heading>
                <Spacing size={2} position="t">
                  <Spacing size={1} position="b">
                    {campaign.printOnDemand ? (
                      <Paragraph size="xxs">
                        Each Everpress product is individually printed when you
                        order it, so please allow 3-5 days manufacture time for
                        your custom product. All items are mailed first-class.
                      </Paragraph>
                    ) : (
                      <Paragraph size="xxs">
                        Orders are printed and shipped when the campaign ends
                        and are mailed first-class. Each campaign needs to reach
                        it's minimum sales in order to be successful.
                      </Paragraph>
                    )}
                    <Spacing size={2}>
                      <Paragraph size="xxs">
                        NOTE: if you order garments from multiple campaigns they
                        will be printed & shipped separately. Delivery charges
                        are calculated separately for each campaign and added to
                        your shopping bag.
                      </Paragraph>
                    </Spacing>
                    <div
                      className="inline-block border-b-1 border-darker-grey cursor-pointer"
                      onClick={this.toggleDeliveryModal.bind(this)}
                    >
                      <Paragraph size="xxs">
                        View shipping times & costs
                      </Paragraph>
                    </div>
                    <Spacing size={2}>
                      <div className={styles.logo}>
                        <img
                          src={everpressLogo}
                          alt="Everpress store logo"
                          className="max-h-full block"
                        />
                      </div>
                      <Paragraph size="xxs" className={styles.soldBy}>
                        Sold by Everpress
                      </Paragraph>
                    </Spacing>
                    <div className={styles.trustbox}>
                      <TrustBox align="left" />
                    </div>
                  </Spacing>
                </Spacing>
              </BoxItem>
            </GridItem>
          </Grid>
        </Box>
      </Fragment>
    );
  }
}

export default ProductInfo;
