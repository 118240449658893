import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import Page from '../../../components/utils/Page';
import { PageWrapNew as PageWrap } from '../../../components/atoms/PageWrap';
import Spacing from '../../../components/atoms/Spacing';

import Slices from '../../../components/molecules/Slices';

import generalStyles from '../style/general.module.css';

const About = () => {
  const data = useSelector((state) =>
    get(state.pages.bySlug['about'], 'data', {})
  );
  const slices = data.body || [];

  return (
    <Page title="BCorp">
      <PageWrap>
        <Spacing size={[2, 2, 2, 0]}>
          <div className={generalStyles.container}>
            <Slices slices={slices} />
          </div>
        </Spacing>
      </PageWrap>
    </Page>
  );
};

export default About;
