import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../../Icon';

import generalStyles from './style/general.module.css';

import downArrow from '../../../../img/sprites/arrow-down.svg';
import tinyDownArrow from '../../../../img/sprites/tiny-down-arrow.svg';

export default class TinySelect extends PureComponent {
  handleChange(e) {
    const parsedValue = parseFloat(e.target.value)
      ? parseFloat(e.target.value)
      : e.target.value;

    this.props.onChange && this.props.onChange(parsedValue);
    this.props.onCustomChange && this.props.onCustomChange(parsedValue);
  }

  render() {
    const {
      children,
      options,
      name,
      onChange,
      onCustomChange,
      newStyles,
      ...props
    } = this.props;

    return (
      <label
        htmlFor={name}
        className={classNames(generalStyles.label, {
          [generalStyles.borderLabel]: !newStyles,
          [generalStyles.spacingLabel]: !newStyles
        })}
      >
        {children}
        <select
          className={generalStyles.tinySelect}
          onChange={this.handleChange.bind(this)}
          name={name}
          id={name}
          {...props}
        >
          {options.map((item) => {
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
        <Icon
          className={generalStyles.tinyArrow}
          width={8}
          height={6}
          glyph={newStyles ? tinyDownArrow : downArrow}
        />
      </label>
    );
  }
}

TinySelect.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onCustomChange: PropTypes.func
};
