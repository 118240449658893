import React from 'react';
import { connect } from 'react-redux';

import Page from '../../../components/utils/Page';

import PageWrap from '../../../components/atoms/PageWrap';
import Heading from '../../../components/atoms/Heading';
import Image from '../../../components/atoms/Image';
import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';
import { Grid, GridItem, InlineGrid } from '../../../components/atoms/Grid';
import WrapIf from '../../../components/utils/WrapIf';

import ProductThumbnail from '../../../components/molecules/Thumbnails/ProductThumbnail';
import ReadMoreArea from '../../../components/molecules/ReadMoreArea';

import SocialLink from './SocialLink';

import { theme } from '../../../theme-config';
import getOptimImgUrl from '../../../helpers/getOptimImgUrl';

import generalStyles from './style/general.module.css';

import instagramIcon from '../../../img/sprites/instagram.svg';
import facebookIcon from '../../../img/sprites/facebook.svg';
import twitterIcon from '../../../img/sprites/twitter.svg';
import globeIcon from '../../../img/sprites/globe.svg';

// import productImage from '../../../img/_temp/product.jpg';

export class Store extends React.Component {
  render() {
    const { store } = this.props;
    const bannerUrl =
      store.bannerUrl &&
      (window.matchMedia(`(min-width: ${theme.screens.mdlg})`).matches
        ? getOptimImgUrl(store.bannerUrl, 2000, 2000 / 1.37)
        : getOptimImgUrl(store.bannerUrl, 1280, 1280 / 1.37));
    const bannerProps = {
      className: generalStyles.banner,
      style: {
        backgroundImage: `url(${bannerUrl}`
      }
    };

    return (
      <Page title={store.name}>
        {store.bannerUrl && (
          <div className={generalStyles.bannerContainer}>
            <div {...bannerProps} />
          </div>
        )}
        <PageWrap>
          <Spacing size={3} type="padding">
            <Grid gap={[0, 0, 1]} align="end">
              <GridItem columnSize={[12, 12, 6]}>
                {store.logoUrl && (
                  <WrapIf
                    condition={!store.nameHidden}
                    wrapper={(children) => (
                      <Spacing size={2} position="b">
                        {children}
                      </Spacing>
                    )}
                  >
                    <Image
                      imgClassName={generalStyles.logo}
                      src={store.logoUrl}
                      height="60"
                      withPlaceholder={false}
                      alt={`${store.name} logo`}
                    />
                  </WrapIf>
                )}
                {!store.nameHidden && (
                  <Heading tag="h1" size={['l', 'xl', 'xxl']}>
                    {store.name}
                  </Heading>
                )}
                {store.description && (
                  <Spacing size={2} position="t">
                    <Paragraph tag="h2" size={['xxs', 'xxs', 'xs']}>
                      <ReadMoreArea wordsLimit={42}>
                        {store.description}
                      </ReadMoreArea>
                    </Paragraph>
                  </Spacing>
                )}
              </GridItem>
              <GridItem columnSize={[12, 12, 6]}>
                <div className="text-right pt-2 md:pt-0 pb-1">
                  <InlineGrid
                    className="flex-wrap"
                    justify={['start', 'start', 'end']}
                    align="center"
                  >
                    {store.links &&
                      store.links.map((item) => {
                        if (!item.url) return false;

                        const width = item.type === 'facebook' ? 8 : 12;
                        let icon = null;

                        switch (item.type) {
                          case 'facebook':
                            icon = facebookIcon;
                            break;
                          case 'twitter':
                            icon = twitterIcon;
                            break;
                          case 'instagram':
                            icon = instagramIcon;
                            break;
                          case 'website':
                            icon = globeIcon;
                            break;
                          default:
                            icon = null;
                        }

                        return (
                          <SocialLink
                            key={item.url}
                            url={item.url}
                            title={item.alt}
                            icon={icon}
                            width={width}
                            height={12}
                          />
                        );
                      })}
                  </InlineGrid>
                </div>
              </GridItem>
            </Grid>
          </Spacing>
          <Spacing size={2}>
            <Grid gap={2}>
              {store.tiles.map((tile) => {
                return (
                  <GridItem columnSize={[12, 6, 4]} key={tile.title}>
                    <Spacing position="b">
                      <ProductThumbnail
                        image={tile.imageUrl}
                        // image={productImage}
                        title={tile.title}
                        subTitle={tile.subTitle || ''}
                        price={tile.prices}
                        timeLeft={!tile.printOnDemand && tile.endAt}
                        slug={tile.slug}
                        isGreyBg={tile.greyBackground}
                      />
                    </Spacing>
                  </GridItem>
                );
              })}
            </Grid>
          </Spacing>
        </PageWrap>
      </Page>
    );
  }
}

const mapStateToProps = ({ location, stores, user }) => {
  const slug = location.pathname.substr(1);

  return {
    store: stores.bySlug[slug],
    currency: user.currency
  };
};

export default connect(mapStateToProps)(Store);
