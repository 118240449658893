import * as Sentry from "@sentry/react";
import pick from 'lodash/pick';
import { nanoid } from 'nanoid';

const validateCart = () => async (dispatch, getState) => {
  const { token: cartToken } = getState().cart;
  const { currency: userCurrency } = getState().user;
  const { source: origin } = getState().tracking.origin;

  let token = cartToken;

  if (!token) {
    try {
      const response = await dispatch(createCart(userCurrency, origin));
      token = response.value.token;
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  }

  return {
    token,
    currency: userCurrency
  };
};

export const createCart = (currency = 'GBP', origin) => (
  dispatch,
  _,
  { baseApi }
) => {
  const payload = { currency, origin };

  return dispatch({
    type: 'CREATE_CART',
    payload: baseApi.post('/cart', payload)
  });
};

export const getCart = (token) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'GET_CART',
    payload: baseApi.get(`/cart/${token}`)
  });

export const addCartItem = (item) => async (
  dispatch,
  getState,
  { baseApi }
) => {
  const { origin, utmParams } = getState().tracking;

  const itemData = {
    ...pick(item, ['campaignProductId', 'colourId', 'size', 'giftCardId' ]),
    quantity: 1,
    origin: origin.source,
    utmParams
  };

  const { token } = await dispatch(validateCart());

  return dispatch({
    type: 'ADD_CART_ITEM',
    payload: baseApi.post(`/cart/${token}/item`, itemData),
    meta: item // Data required to track the action in trackingEmitter.js
  });
};

export const editCartItem = (item) => (dispatch, getState, { baseApi }) => {
  const { token } = getState().cart;

  return dispatch({
    type: 'EDIT_CART_ITEM',
    payload: baseApi.put(`/cart/${token}/item/${item.orderItemId}`, item)
  });
};

export const deleteCartItem = (orderItemId) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { token, ...cart } = getState().cart;

  if (!orderItemId) {
    return;
  }

  const newItems = cart.items
    .filter((item) => item.orderItemId !== orderItemId)
    .map(({ orderItemId, ...rest }) => rest);

  const meta = {
    items: newItems,
    optimist: { id: nanoid() } // See optimistMiddleware.js before changing this
  };

  return dispatch({
    type: 'DELETE_CART_ITEM',
    payload: baseApi.delete(`/cart/${token}/item/${orderItemId}`),
    meta
  });
};

export const setCartCurrency = (currency = 'GBP') => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { token } = getState().cart;

  return dispatch({
    type: 'SET_CART_CURRENCY',
    payload: baseApi.put(`/cart/${token}`, { currency })
  });
};

export const findMoreItems = () => (dispatch) => {
  return dispatch({
    type: 'FIND_MORE_ITEMS'
  });
};

export const getRecommendedItems = () => (dispatch, _, { baseApi }) => {
  return dispatch({
    type: 'GET_RECOMMENDED_ITEMS',
    payload: baseApi.get('/cart/recommended/campaigns')
  });
};
