import React from 'react';
import { useDispatch } from 'react-redux';
import { redirect } from 'redux-first-router';
import PropTypes from 'prop-types';

import { sendAmplitudeEvent } from '../../../../services/amplitude';

import { ButtonNew as Button } from '../../../../components/atoms/Button';
import Heading from '../../../../components/atoms/Heading';
import Icon from '../../../../components/atoms/Icon';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';

import { ModalNew as Modal } from '../../../../components/molecules/Modal';

import roundCheckIcon from '../../../../img/sprites/round-check.svg';

import { theme } from '../../../../theme-config';

const SuccessModal = ({ campaign, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { profile } = campaign;

  const handleClose = () => {
    onClose?.();
  };

  const handleEditProfile = () => {
    sendAmplitudeEvent('Campaign Launch Modal: edit profile button clicked', {
      slug: profile.slug
    });

    dispatch(
      redirect({
        type: 'DASHBOARD_ACCOUNT_SETTINGS',
        payload: { tab: 'profile' }
      })
    );
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Spacing size={4} position="t">
        <div className="flex justify-center">
          <Icon
            glyph={roundCheckIcon}
            width={30}
            height={30}
            fill={theme.extend.colors.green.primary}
          />
        </div>
      </Spacing>
      <Spacing size={15} position="t">
        <Heading size="xs" tag="h3" center>
          Success
        </Heading>
      </Spacing>
      <Spacing size={5} position="t">
        <Paragraph size="xxs" center>
          We've added this campaign to your profile.
        </Paragraph>
      </Spacing>
      <Spacing size={2} position="t">
        <div className="flex justify-center">
          <Button onClick={handleEditProfile}>Edit profile</Button>
        </div>
      </Spacing>
    </Modal>
  );
};

SuccessModal.propTypes = {
  campaign: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

SuccessModal.defaultProps = {
  isOpen: false
};

export default SuccessModal;
