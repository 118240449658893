import shopifyLogo from '../../../img/sprites/platforms/shopify.svg';
import squarespaceLogo from '../../../img/sprites/platforms/squarespace.svg';
import woocommerceLogo from '../../../img/sprites/platforms/woocommerce.svg';
import bandcampLogo from '../../../img/sprites/platforms/bandcamp.svg';

const platforms = [
  {
    title: 'Shopify',
    icon: shopifyLogo
  },
  {
    title: 'Squarespace',
    icon: squarespaceLogo
  },
  {
    title: 'WooCommerce',
    icon: woocommerceLogo
  },
  {
    title: 'Bandcamp',
    icon: bandcampLogo
  }
];

export default platforms;
