import React, { PureComponent } from 'react';

import config from '../../config';

import Page from '../../components/utils/Page';

import Heading from '../../components/atoms/Heading';
import Paragraph from '../../components/atoms/Paragraph';
import Icon from '../../components/atoms/Icon';
import Link from '../../components/atoms/Link';
import PageWrap from '../../components/atoms/PageWrap';
import Spacing from '../../components/atoms/Spacing';
import LiveChat from '../../components/atoms/LiveChat';
import { ListWrapper, ListItem } from '../../components/atoms/List';

import locationIcon from '../../img/sprites/location.svg';
import instagramIcon from '../../img/sprites/instagram.svg';
import twitterIcon from '../../img/sprites/twitter.svg';

import customerServiceLinks from './data';

export default class Contact extends PureComponent {
  render() {
    const locationIconSize = {
      width: '8px',
      height: '12px'
    };
    const twitterIconSize = {
      width: '25px',
      height: '21px'
    };
    const instagramIconSize = {
      width: '24px',
      height: '24px'
    };

    return (
      <Page title="Contact">
        <LiveChat />
        <PageWrap>
          <Spacing type="padding" size={[3, 5, 7]}>
            <Spacing type="padding" size={7} position="b">
              <Spacing size={3} position="b">
                <Heading size="xxs" tag="h2">
                  Customer service queries
                </Heading>
              </Spacing>
              <Paragraph size="xs" tag="h2">
                If you have a query related to a purchase that you have made,
                please check our buyer FAQs in order to get more information…
              </Paragraph>
              <ListWrapper>
                {customerServiceLinks.map((cs, i) => (
                  <ListItem key={i}>
                    <Link href={cs.link}>
                      <Paragraph className="inline" size="xs">
                        {cs.content}
                      </Paragraph>
                    </Link>
                  </ListItem>
                ))}
              </ListWrapper>
            </Spacing>

            <Heading size="xxs" tag="h2">
              Contact us
            </Heading>
            <Spacing size={2}>
              <Heading tag="h1" size={['l', 'xl', 'xxl']}>
                Everpress
                <br />
                Unit 2, 55 Dalston Lane
                <br />
                London, E8 2NG
                <br />
              </Heading>
            </Spacing>
            <Link
              isUnderlined={false}
              to="https://goo.gl/maps/F1TirAEw3pZ3M4j28"
            >
              <span className="flex items-center">
                <Icon
                  glyph={locationIcon}
                  className="mr-1"
                  style={locationIconSize}
                />
                <Heading size="xxs" tag="div">
                  show on a map
                </Heading>
              </span>
            </Link>

            <Spacing size={5}>
              <Spacing size={1}>
                <Heading tag="div" size={['m', 'm', 'l', 'xl']}>
                  <Link
                    onClick={() => {
                      if (window.zE) window.zE.activate();
                    }}
                  >
                    Contact us
                  </Link>
                </Heading>
              </Spacing>
              <Spacing size={1}>
                <Heading tag="div" size={['m', 'm', 'l', 'xl']}>
                  +44 (0) 1223 320 578
                </Heading>
              </Spacing>
              <Spacing size={1}>
                <div className="flex items-center">
                  <Spacing position="x" size={1}>
                    <Link isUnderlined={false} to={config.socials.instagram}>
                      <Icon glyph={instagramIcon} style={instagramIconSize} />
                    </Link>
                  </Spacing>
                  <Spacing position="x" size={1}>
                    <Link isUnderlined={false} to={config.socials.twitter}>
                      <Icon glyph={twitterIcon} style={twitterIconSize} />
                    </Link>
                  </Spacing>
                </div>
              </Spacing>
            </Spacing>
          </Spacing>
        </PageWrap>
      </Page>
    );
  }
}
