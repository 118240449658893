import produce from 'immer';
import isWithinRange from 'date-fns/is_within_range';

const initialState = {
  navigation: {
    isNavigationVisible: true,
    isMinimalNavigation: false,
    isHidden: false
  },
  banner: {
    isBannerActive: false,
    announcementCopy: ''
  },
  theme: {
    color: 'white'
  }
};

export default (state = initialState, action) =>
  produce(state, (newState) => {
    if (action.meta && action.meta.location) {
      newState.navigation = { ...initialState.navigation };
      newState.banner.isBannerActive = false;
      newState.theme.color = initialState.theme.color;

      if (action.type.match(/CAMPAIGN_BUILDER/)) {
        newState.navigation.isHidden = true;
      }

      if (['HOME', 'SHOP', 'CAMPAIGN_OR_STORE', 'CART'].includes(action.type)) {
        if (action.type !== 'HOME') {
          const isCreatorWeekendPeriod = isWithinRange(
            new Date(),
            new Date(2024, 10, 27),
            new Date(2024, 11, 3)
          );

          newState.banner.isBannerActive = !!isCreatorWeekendPeriod;
          newState.banner.announcementCopy = isCreatorWeekendPeriod
            ? 'Creators Make 25% More All Black Friday Week. Support Creativity.'
            : '';
        }
        newState.navigation.isHidden = false;
      }

      if (action.type === 'ORDER' || action.type === 'CHECKOUT') {
        newState.navigation.isMinimalNavigation = true;
        newState.banner.isBannerActive = false;
      }

      if (action.type === 'CAREERS') {
        newState.navigation.isMinimalNavigation = true;
        newState.banner.isBannerActive = false;
      }

      return newState;
    }

    return state;
  });
