import React from 'react';
import PropTypes from 'prop-types';
import { styled, setup } from 'goober';
import classNames from 'classnames';

import propToClassName from '../../../../helpers/propToClassName';
import createMediaQueries from '../../../../helpers/createMediaQueries';

setup(React.createElement);

const GAP_MAP = {
  0: 'gap-0',
  '05': 'gap-2',
  1: 'gap-4',
  15: 'gap-6',
  2: 'gap-8',
  3: 'gap-12',
  4: 'gap-16',
  5: 'gap-20',
  6: 'gap-24',
  7: 'gap-32'
};

const GAP_ROW_MAP = {
  0: 'gap-y-0',
  '05': 'gap-y-2',
  1: 'gap-y-4',
  15: 'gap-y-6',
  2: 'gap-y-8',
  3: 'gap-y-12',
  4: 'gap-y-16',
  5: 'gap-y-20',
  6: 'gap-y-24',
  7: 'gap-y-32'
};

const GAP_COLUMN_MAP = {
  0: 'gap-x-0',
  '05': 'gap-x-2',
  1: 'gap-x-4',
  15: 'gap-x-6',
  2: 'gap-x-8',
  3: 'gap-x-12',
  4: 'gap-x-16',
  5: 'gap-x-20',
  6: 'gap-x-24',
  7: 'gap-x-32'
};

const ALIGN_MAP = {
  center: 'items-center',
  start: 'items-start',
  end: 'items-end',
  baseline: 'items-baseline',
  stretch: 'items-stretch'
};

const ALIGN_CONTENT_MAP = {
  center: 'content-center',
  start: 'content-start',
  end: 'content-end',
  between: 'content-between',
  around: 'content-around',
  evenly: 'content-evenly',
  stretch: 'content-stretch'
};

const JUSTIFY_MAP = {
  auto: 'justify-items-auto',
  center: 'justify-items-center',
  start: 'justify-items-start',
  end: 'justify-items-end',
  stretch: 'justify-items-stretch'
};

const JUSTIFY_CONTENT_MAP = {
  center: 'justify-center',
  start: 'justify-start',
  end: 'justify-end',
  between: 'justify-between',
  around: 'justify-around',
  evenly: 'justify-evenly',
  stretch: 'justify-stretch'
};

const StyledCSSGrid = styled('div')(({ template, rows, columns }) => [
  createMediaQueries([
    {
      property: 'grid-template',
      values: template
    },
    {
      property: 'grid-template-rows',
      values: rows
    },
    {
      property: 'grid-template-columns',
      values: columns
    }
  ])
]);

const CSSGrid = ({
  className,
  children,
  gap,
  rowGap,
  columnGap,
  areas,
  align,
  alignContent,
  justify,
  justifyContent,
  ...props
}) => {
  const gapClassName = propToClassName(gap, GAP_MAP);
  const rowGapClassName = !gap ? propToClassName(rowGap, GAP_ROW_MAP) : null;
  const columnGapClassName = !gap
    ? propToClassName(columnGap, GAP_COLUMN_MAP)
    : null;
  const alignClassName = propToClassName(align, ALIGN_MAP);
  const alignContentClassName = propToClassName(
    alignContent,
    ALIGN_CONTENT_MAP
  );
  const justifyClassName = propToClassName(justify, JUSTIFY_MAP);
  const justifyContentClassName = propToClassName(
    justifyContent,
    JUSTIFY_CONTENT_MAP
  );
  const gridClassName = classNames(
    className,
    gapClassName,
    rowGapClassName,
    columnGapClassName,
    alignClassName,
    alignContentClassName,
    justifyClassName,
    justifyContentClassName
  );

  return (
    <StyledCSSGrid
      className={classNames('grid', 'box-border', gridClassName)}
      {...props}
    >
      {children}
    </StyledCSSGrid>
  );
};

export default CSSGrid;

CSSGrid.defaultProps = {
  gap: 0,
  rowGap: 0,
  columnGap: 0,
  align: 'start',
  alignContent: 'start',
  justify: 'start',
  justifyContent: 'start'
};

const templatePropTypes = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string)
]);

CSSGrid.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  template: templatePropTypes,
  rows: templatePropTypes,
  columns: templatePropTypes,
  gap: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOf(Object.keys(GAP_MAP).map((key) => parseInt(key, 10)))
    ),
    PropTypes.oneOf(Object.keys(GAP_MAP).map((key) => parseInt(key, 10)))
  ]),
  rowGap: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOf(Object.keys(GAP_ROW_MAP).map((key) => parseInt(key, 10)))
    ),
    PropTypes.oneOf(Object.keys(GAP_ROW_MAP).map((key) => parseInt(key, 10)))
  ]),
  columnGap: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOf(
        Object.keys(GAP_COLUMN_MAP).map((key) => parseInt(key, 10))
      )
    ),
    PropTypes.oneOf(Object.keys(GAP_COLUMN_MAP).map((key) => parseInt(key, 10)))
  ]),
  align: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ALIGN_MAP))),
    PropTypes.oneOf(Object.keys(ALIGN_MAP))
  ]),
  alignContent: PropTypes.oneOf(Object.keys(ALIGN_CONTENT_MAP)),
  justify: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(Object.keys(JUSTIFY_MAP))),
    PropTypes.oneOf(Object.keys(JUSTIFY_MAP))
  ]),
  justifyContent: PropTypes.oneOf(Object.keys(JUSTIFY_CONTENT_MAP))
};
