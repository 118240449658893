import { useState, useRef } from 'react';
import * as Sentry from "@sentry/react";

const useLocalStorage = (key, initialValue) => {
  if (!key) {
    throw new Error('useLocalStorage key is not valid');
  }

  const initialiser = useRef((key) => {
    try {
      const localStorageValue = window.localStorage.getItem(key);

      if (localStorageValue) {
        return JSON.parse(localStorageValue);
      }

      initialValue &&
        window.localStorage.setItem(key, JSON.stringify(initialValue));

      return initialValue;
    } catch (error) {
      // User browser has a storage restriction, localStorage can throw.
      // JSON.parse and JSON.stringify can throw, too.
      Sentry.captureException(error);
      return initialValue;
    }
  });

  const [storedValue, setStoredValue] = useState(() =>
    initialiser.current(key)
  );

  const setValue = (value) => {
    try {
      // Allow value to be a function so we have the same API as useState.
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);

      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // User browser has a storage restriction, localStorage can throw.
      // Also JSON.stringify can throw.
      Sentry.captureException(error);
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
