import image1 from '../../../img/pages/about/hero-1.jpg';
import image2 from '../../../img/pages/about/hero-2.jpg';
import image3 from '../../../img/pages/about/hero-3.jpg';
import image4 from '../../../img/pages/about/hero-4.jpg';
import image6 from '../../../img/pages/about/hero-6.jpg';
import image7 from '../../../img/pages/about/hero-7.jpg';
import image8 from '../../../img/pages/about/hero-8.jpg';
import image10 from '../../../img/pages/about/hero-10.jpg';
import image11 from '../../../img/pages/about/hero-11.jpg';
import image12 from '../../../img/pages/about/hero-12.jpg';
import image13 from '../../../img/pages/about/hero-13.jpg';

const images = [
  {
    id: 0,
    image: image1
  },
  {
    id: 1,
    image: image2
  },
  {
    id: 2,
    image: image3
  },
  {
    id: 3,
    image: image4
  },
  {
    id: 5,
    image: image6
  },
  {
    id: 6,
    image: image7
  },
  {
    id: 7,
    image: image8
  },
  {
    id: 9,
    image: image10
  },
  {
    id: 10,
    image: image11
  },
  {
    id: 11,
    image: image12
  },
  {
    id: 12,
    image: image13
  }
];

export default images;
