import React from 'react';

import { ButtonNew } from '../../../components/atoms/Button';
import Heading from '../../../components/atoms/Heading';
import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';

const BandcampLeadConfirmation = () => {
  return (
    <>
      <Heading size="xs">You're on the list</Heading>
      <Spacing size={15} position="t">
        <Spacing size={15} position="b">
          <Paragraph size="xxs">
            Someone on our team will reach out to help you get set up soon.
          </Paragraph>
        </Spacing>
        <Paragraph size="xxs">
          To speed things up, make an Everpress account now and start designing
          your merch in our campaign builder. When you launch it, we can connect
          it to your Bandcamp profile.
        </Paragraph>
      </Spacing>
      <Spacing size={4} position="t">
        <ButtonNew to="/create" fullWidth isExternal>
          Upload your design
        </ButtonNew>
      </Spacing>
    </>
  );
};

export default BandcampLeadConfirmation;
