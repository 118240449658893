import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../../../atoms/Icon';

import elipsisIcon from '../../../../img/sprites/elipsis-icon.svg';

import styles from './styles/general.module.css';

const ContextualMenu = ({
  menuPosition,
  menuButton,
  menuOpen,
  setMenuOpen,
  buttonClassName,
  children
}) => {
  const menuRef = useRef();
  const buttonRef = useRef();

  const clickOutside = useCallback(
    (e) => {
      if (menuOpen && !menuRef.current.contains(e.target)) {
        e.preventDefault();
        setMenuOpen(false);
      }
    },
    [menuOpen, setMenuOpen]
  );

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, [clickOutside]);

  return (
    <>
      {menuButton || (
        <button
          ref={buttonRef}
          onClick={() => setMenuOpen(true)}
          title="Open Menu"
          className={classNames(styles.toggleButton, buttonClassName)}
        >
          <Icon glyph={elipsisIcon} width={20} height={20} />
        </button>
      )}
      {menuOpen && (
        <div
          className={styles.contextualMenu}
          style={menuPosition}
          ref={menuRef}
        >
          <ul>{children}</ul>
        </div>
      )}
    </>
  );
};

ContextualMenu.propTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  menuPosition: PropTypes.object,
  menuButton: PropTypes.node,
  menuOpen: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired
};

ContextualMenu.defaultProps = {
  menuPosition: {},
  menuOpen: false,
  setMenuOpen: () => {}
};

export default ContextualMenu;
