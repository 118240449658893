import React from 'react';
import PropTypes from 'prop-types';

import Alert from '../../../../components/atoms/Alert';
import Heading from '../../../../components/atoms/Heading';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';

import { ModalNew as Modal } from '../../../../components/molecules/Modal';

import { InlineGrid } from '../../../../components/atoms/Grid';
import { ButtonNew as Button } from '../../../../components/atoms/Button';

const LaunchModal = ({ campaign, onSubmit, onClose, isOpen, errorMessage }) => {
  const handleSubmit = () => {
    onSubmit?.();
  };

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Spacing size={4} position="t">
        <Heading size="xs" tag="h3" center>
          Ready to launch?
        </Heading>
      </Spacing>
      <Spacing size={5} position="t">
        <Paragraph size="xxs" center>
          Once your campaign is live, you won't be able to make any changes. By
          choosing to launch you are confirming:
        </Paragraph>
        <Spacing size={2} position="t">
          <ol
            className="px-3 py-15 bg-grey-lightest rounded-md text-xxs"
            type="1"
          >
            <li style={{ listStyleType: 'decimal' }}>
              You hold the copyrights to, or permissions to use, all of the
              content within your campaign.
            </li>
            <li className="mt-1" style={{ listStyleType: 'decimal' }}>
              Your campaign does not feature any content that incites hatred, is
              libellous, or is likely to cause undue embarrassment or offence.
            </li>
          </ol>
        </Spacing>
        <Spacing size={2} position="t">
          <Paragraph size="xxs" center>
            Everpress retains the right to remove any content in breach of our
            community guidelines. If you’re happy with all of that, go ahead and
            launch!
          </Paragraph>
        </Spacing>
        <Spacing size={2} position="t">
          <InlineGrid direction={['col', 'row']}>
            <Button
              onClick={handleSubmit}
              state={campaign.isLaunching ? 'loading' : 'default'}
              fullWidth
            >
              Launch
            </Button>
            <Button
              kind="outline"
              to={`/campaign/edit/${campaign.campaignId}`}
              className="ml-1"
              fullWidth
              isExternal
            >
              Edit
            </Button>
          </InlineGrid>
        </Spacing>
        {errorMessage && (
          <Spacing position="t" size={2}>
            <Alert kind="error">{errorMessage}</Alert>
          </Spacing>
        )}
      </Spacing>
    </Modal>
  );
};

LaunchModal.propTypes = {
  campaign: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string
};

LaunchModal.defaultProps = {
  isOpen: false
};

export default LaunchModal;
