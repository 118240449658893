import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import inRange from 'lodash/inRange';
import debounce from 'lodash/debounce';

import {
  updateProfitEstimate,
  updateCampaign
} from '../../../../actions/campaign-builder';

import Heading from '../../../../components/atoms/Heading';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';
import { Form, DefaultField as Field } from '../../../../components/atoms/Form';
import Alert from '../../../../components/atoms/Alert';
import { TooltipNew as Tooltip } from '../../../../components/atoms/Tooltip';
import Icon from '../../../../components/atoms/Icon';

import Slider from '../../../../components/molecules/Slider';

import questionMarkIcon from '../../../../img/sprites/question-mark-circle.svg';

import generalStyles from './style/general.module.css';

const tooltipContent = (
  <div>
    <Paragraph size="xxs">
      This is just a goal, we won't hold you to it and there's no upper limit.
      The minimum order quantity is 1 item.
    </Paragraph>
  </div>
);

const SalesGoal = () => {
  const dispatch = useDispatch();

  const goal = useSelector((state) => state.campaignBuilder.goal);

  useEffect(() => {
    if (goal) {
      dispatch(updateProfitEstimate(goal));
    }
  }, [goal, dispatch]);

  const handleGoalChange = useCallback(
    async (goal) => {
      await dispatch(updateCampaign({ goal }));
    },
    [dispatch]
  );

  const debouncedHandleGoalChangeSlider = useMemo(
    () => debounce(handleGoalChange, 500, { trailing: true }),
    [handleGoalChange]
  );

  const debouncedHandleGoalChangeInput = useMemo(
    () => debounce(handleGoalChange, 2000, { trailing: true }),
    [handleGoalChange]
  );

  return (
    <div>
      <span className="flex items-center gap-3">
        <Heading tag="h4" size="xs">
          Sales goal
        </Heading>
        <Tooltip
          id="tooltip-sales-duration"
          content={tooltipContent}
          placement="bottom"
          offset={7}
          align="start"
          alignOffset={-10}
          width={320}
        >
          <Icon
            className="mb-px opacity-25 hover:opacity-100 transition-opacity duration-100"
            glyph={questionMarkIcon}
            width={14}
            height={14}
          />
        </Tooltip>
      </span>
      <Spacing size="05" position="t">
        <Paragraph size="xxs">
          How many items do you estimate your design will sell?
        </Paragraph>
      </Spacing>
      <Spacing size={3} position="t">
        <Form
          onSubmit={() => null}
          onChange={({ goalSlider, goalInput }, { initialValues }) => {
            const { goalSlider: prevGoalSlider, goalInput: prevGoalInput } = initialValues;

            // Check slider first as it has a shorter delay
            if (goalSlider !== prevGoalSlider && inRange(goalSlider, 1, 1251)) {
              return debouncedHandleGoalChangeSlider(goalSlider);
            }

            if (goalInput !== prevGoalInput) {
              // NOTE: Checks if n is between start and up to, but not including, end
              if (inRange(goalInput, 1, 1251)) {
                return debouncedHandleGoalChangeInput(goalInput);
              }

              // Reset to original value instead of last valid typed value
              return debouncedHandleGoalChangeInput(goal);
            }
          }}
          initialValues={{ goalInput: goal, goalSlider: goal }}
          validationSchema={yup.object().shape({
            goalInput: yup
              .number()
              .min(1, 'Sales goal is below minimum.')
              .max(1250, 'Sales goal is above maximum.')
              .required('Sales goal is required.')
          })}
          validateOnChange={true}
          enableReinitialize
          render={({ handleSubmit, setValues, values, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={generalStyles.inputs}>
                  <Slider
                    value={[Math.min(Math.max(1, values.goalSlider), 1250)]}
                    min={1}
                    max={1250}
                    step={1}
                    marks={[
                      { value: 1, label: '1' },
                      { value: 250, label: '250' },
                      { value: 500, label: '500' },
                      { value: 750, label: '750' },
                      { value: 1000, label: '1000' },
                      { value: 1250, label: '1250' }
                    ]}
                    onValueChange={(values) =>
                      setValues({ goalSlider: values[0], goalInput: values[0] })
                    }
                    onMarkClick={(value) =>
                      setValues({ goalSlider: value, goalInput: value })
                    }
                  />
                  <div>
                    <Field
                      type="number"
                      name="goalInput"
                      placeholder="Sales"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>

                {errors?.goalInput && (
                  <Spacing size={1} position="t">
                    <Alert>{errors.goalInput}</Alert>
                  </Spacing>
                )}
              </form>
            );
          }}
        />
      </Spacing>
    </div>
  );
};

export default SalesGoal;
