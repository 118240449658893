import * as React from 'react';
import classNames from 'classnames';

import Link from '../../../atoms/Link';
import Spacing from '../../../atoms/Spacing';

export default function FooterLink({ children, isDark, ...props }) {
  const textClassName = classNames('text-xxxs', {
    'text-white': isDark
  });
  return (
    <Spacing size={1}>
      <Link isUnderlined={false} {...props}>
        <div className={textClassName}>{children}</div>
      </Link>
    </Spacing>
  );
}
