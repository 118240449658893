import React from 'react';
import PropTypes from 'prop-types';
import RCTooltip from 'rc-tooltip';
import classNames from 'classnames';
import 'rc-tooltip/assets/bootstrap.css';

import Icon from '../../Icon';
import questionMarkBox from '../../../../img/sprites/question-mark-box.svg';

import './style/rc-tooltip-overrides.css';
import styles from './style/general.module.css';

Tooltip.propTypes = {
  children: PropTypes.node,
  placement: PropTypes.string,
  content: PropTypes.node,
  glyph: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object // BrowserSpriteSymbol
  ]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default function Tooltip({
  className,
  children,
  placement,
  content,
  glyph,
  glyphWidth,
  glyphHeight,
  id,
  ...props
}) {
  return (
    <RCTooltip
      placement={placement}
      overlay={content}
      transitionName="fade"
      id={id || 'tooltip'}
      {...props}
    >
      {children ? (
        children
      ) : (
        <div className={styles.overlay} name={id} tabIndex={0}>
          <Icon
            className={classNames(className)}
            glyph={glyph || questionMarkBox}
            width={glyphWidth || '14'}
            height={glyphHeight || '14'}
          />
        </div>
      )}
    </RCTooltip>
  );
}

Tooltip.defaultProps = {
  placement: 'right'
};
