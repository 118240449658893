import React from 'react';
import { connect } from 'react-redux';
import universal from 'react-universal-component';

import config from '../../../config';

const Component =
  process.env.NODE_ENV === 'production'
    ? universal(import('./ZendeskChat'), { loading: () => null })
    : () => null;

function LiveChatWrapper({ ...props }) {
  return <Component zendeskKey={config.zendeskKey} {...props} />;
}

const mapStateToProps = ({ user }) => {
  let visitor = {};

  if (user.userId) {
    visitor = {
      email: user.details.email
    };
  }

  return {
    visitor
  };
};

export default connect(mapStateToProps)(LiveChatWrapper);
