import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import propToClassName from '../../../../helpers/propToClassName';

import { TabsContext } from '../index';

import generalStyles from './style/general.module.css';

const directionMap = {
  horizontal: 'flex-row',
  vertical: 'flex-col'
};

const justifyMap = {
  center: 'justify-center',
  start: 'justify-start',
  end: 'justify-end',
  'space-between': 'justify-between',
  'space-around': 'justify-around'
};

class TabsList extends React.Component {
  render() {
    const {
      children,
      kind,
      selectedTabId,
      direction,
      onChange,
      navLabel,
      justify,
      forwardedRef,
      className,
      ...props
    } = this.props;

    return (
      <TabsContext.Provider
        value={{
          direction,
          kind,
          onSelect: onChange,
          selectedId: selectedTabId
        }}
      >
        <nav title={navLabel}>
          <ul
            ref={forwardedRef}
            className={classNames(
              generalStyles.links,
              propToClassName(direction, directionMap),
              propToClassName(justify, justifyMap),
              className
            )}
            {...props}
          >
            {children}
          </ul>
        </nav>
      </TabsContext.Provider>
    );
  }
}

export default TabsList;

TabsList.defaultProps = {
  kind: 'default',
  direction: 'horizontal',
  navLabel: null,
  justify: 'start'
};

TabsList.propTypes = {
  children: PropTypes.node.isRequired,
  selectedTabIndex: PropTypes.number,
  direction: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(Object.keys(directionMap))),
    PropTypes.oneOf(Object.keys(directionMap))
  ]),
  navLabel: PropTypes.string,
  justify: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(Object.keys(justifyMap))),
    PropTypes.oneOf(Object.keys(justifyMap))
  ]),
  className: PropTypes.string
};
