import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../atoms/Icon';
import { InlineGrid } from '../../atoms/Grid';
import Spacing from '../../atoms/Spacing';
import MaxWidth from '../../atoms/MaxWidth';

import generalStyles from './style/general.module.css';

export default function LogoRow({ logos, isPrismic }) {
  return (
    <div className={generalStyles.logoRow}>
      <Spacing type="padding" size={[2, 4]}>
        <InlineGrid justify="space-around">
          {logos.map((item, i) => (
            <Spacing key={i} position="x" size={[1, 2]}>
              {isPrismic ? (
                <MaxWidth value={item.width}>
                  <img src={item.url} alt={item.alt} />
                </MaxWidth>
              ) : (
                <MaxWidth value={item.width}>
                  <Icon
                    className={generalStyles.icon}
                    height={item.height}
                    key={item.id}
                    glyph={item.logo}
                  />
                </MaxWidth>
              )}
            </Spacing>
          ))}
        </InlineGrid>
      </Spacing>
    </div>
  );
}

LogoRow.propTypes = {
  logos: PropTypes.array
};
