import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { theme } from '../../../theme-config';

import { CSSGrid } from '../../../components/atoms/Grid';
import Icon from '../../../components/atoms/Icon';
import Paragraph from '../../../components/atoms/Paragraph';

import infoCircle from '../../../img/sprites/info-circle.svg';
import warningCircle from '../../../img/sprites/warning-circle.svg';
import checkCircle from '../../../img/sprites/check-circle.svg';

import generalStyles from './style/general.module.css';
import kindStyles from './style/kind.module.css';

const cx = classNames.bind({
  ...generalStyles,
  ...kindStyles
});

const iconFillMap = {
  info: theme.extend.colors.black,
  error: theme.extend.colors.red.primary,
  warning: theme.extend.colors.yellow.primary,
  success: theme.extend.colors.green.primary
};

const iconMap = {
  info: infoCircle,
  error: warningCircle,
  warning: warningCircle,
  success: checkCircle
};

const Alert = ({ kind, message, icon, className, children }) => {
  return (
    <div className={cx('alert', className, kind)} role="alert">
      <CSSGrid template="1fr / auto 1fr">
        <div className={generalStyles.icon}>
          <Icon
            glyph={icon ? icon : iconMap[kind]}
            fill={iconFillMap[kind]}
            width={13}
            height={13}
          />
        </div>
        <div className={generalStyles.message}>
          <Paragraph tag="span" size="xxs">
            {children || message}
          </Paragraph>
        </div>
      </CSSGrid>
    </div>
  );
};

export default Alert;

Alert.defaultProps = {
  kind: 'error',
  message: 'An unexpected error occurred'
};

Alert.propTypes = {
  kind: PropTypes.oneOf(Object.keys(kindStyles)),
  message: PropTypes.string,
  icon: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node
};
