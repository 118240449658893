// Packages
import React, { Fragment } from 'react';
import { Formik } from 'formik';
import FormikObserver from '../FormikObserver';

// We just build a wrapper so rest of the app is not
// aware of Formik dependency.
const Form = React.forwardRef(({ onChange, ...props }, ref) => {
  return (
    <Formik
      {...props}
      ref={ref}
      render={formikProps => (
        <Fragment>
          <FormikObserver onChange={onChange} />
          {props.render(formikProps)}
        </Fragment>
      )}
    />
  );
});

export default Form;

Form.defaultProps = {
  onChange: () => null
};
