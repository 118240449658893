import React from 'react';
import classNames from 'classnames';

import Heading from '../../Heading';
import Paragraph from '../../Paragraph';
import Spacing from '../../Spacing';

import generalStyles from './style/general.module.css';

const Label = ({
  children,
  type,
  className,
  noPad,
  size,
  isRequired,
  ...props
}) => {
  const labelClassName = classNames(generalStyles.label, className);
  const labelSpacing = noPad ? 0 : [1, 2];
  const CopyTag = type === 'heading' ? Heading : Paragraph;

  return (
    <label className={labelClassName} {...props}>
      <Spacing size={labelSpacing} position="all" type="padding">
        <CopyTag tag="div" size={size}>
          {children}
          {isRequired && props.htmlFor && '*'}
        </CopyTag>
      </Spacing>
    </label>
  );
};

Label.defaultProps = {
  type: 'heading',
  noPad: false,
  size: ['xxxs', 'xs'],
  isRequired: false
};

export default Label;
