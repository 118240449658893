import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import propToClassName from '../../../../helpers/propToClassName';

import { GridContext } from '../';

const gapMap = {
  '0': '-mx-0',
  '05': '-mx-05',
  '1': '-mx-1',
  '15': '-mx-15',
  '2': '-mx-2',
  '25': '-mx-25',
  '3': '-mx-3',
  '4': '-mx-4',
  '5': '-mx-5'
};

const alignMap = {
  center: 'items-center',
  start: 'items-start',
  end: 'items-end',
  baseline: 'items-baseline',
  stretch: 'items-stretch'
};

const justifyMap = {
  center: 'justify-center',
  start: 'justify-start',
  end: 'justify-end',
  'space-between': 'justify-between',
  'space-around': 'justify-around'
};

FlexboxGrid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  row: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  gap: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOf(Object.keys(gapMap).map((key) => parseInt(key, 10)))
    ),
    PropTypes.oneOf(Object.keys(gapMap).map((key) => parseInt(key, 10)))
  ]),
  nowrap: PropTypes.bool
};

export default function FlexboxGrid({
  className,
  children,
  row,
  gap,
  align,
  justify,
  nowrap
}) {
  const rowClassName = propToClassName(row);
  const gapClassName = propToClassName(gap, gapMap);
  const alignClassName = propToClassName(align, alignMap);
  const justifyClassName = propToClassName(justify, justifyMap);

  const gridClassName = classNames(
    'flex-grid',
    className,
    rowClassName,
    alignClassName,
    justifyClassName,
    gapClassName,
    {
      'flex-wrap': !nowrap,
      'flex-no-wrap': nowrap
    }
  );

  return (
    <GridContext.Provider value={{ gap }}>
      <div className={gridClassName}>{children}</div>
    </GridContext.Provider>
  );
}

FlexboxGrid.defaultProps = {
  gap: [2, 2, 5],
  row: 'flex-row',
  align: 'center',
  justify: 'start'
};
