import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import * as campaignsActions from '../../actions/campaigns';

import Page from '../../components/utils/Page';
import Concierge from '../../components/utils/Concierge';

import PageWrap from '../../components/atoms/PageWrap';
import Spacing from '../../components/atoms/Spacing';

import Cta from '../../components/molecules/Cta';
import { UGCRail } from '../../components/molecules/Rail';
import FeaturedProfileRail from '../../components/molecules/Rail/FeaturedProfileRail';
import FeaturedCampaignsRail from '../../components/molecules/Rail/FeaturedCampaignsRail';

import { ContentCardsGrid } from '../../components/prismic/molecules/ContentCards';
import { FeatureCardGrid } from '../../components/prismic/molecules/FeatureCards';
import FeaturedBlogPosts from '../../components/prismic/molecules/FeaturedBlogPosts';
import Hero from '../../components/prismic/molecules/Hero';

// import MainFeatures from './MainFeatures';

import tshirtIcon from '../../img/sprites/tshirt.svg';
// import mapIcon from '../../img/sprites/map.svg';
import emailIcon from '../../img/sprites/email.svg';

export const Home = ({
  heading,
  seoTitle,
  slides,
  featuredTags,
  ugcRailData,
  featureCardsData,
  featureCards1Data,
  collectionCardsData,
  blogPostsData,
  featuredProfiles
}) => {
  return (
    <Page title={seoTitle}>
      <Concierge />
      <Hero slides={slides} heading={heading} />
      {/*<MainFeatures campaigns={campaigns} />*/}

      <PageWrap>
        {/*<Spacing size={8}>
            <Cta
              icon={mapIcon}
              linkText="Learn more"
              linkProps={{
                to: '/how-it-works'
              }}
            >
              We produce, pack and ship globally, so you don't have to
            </Cta>
          </Spacing>*/}

        {featuredTags?.map((tagData) =>
          tagData.campaigns.length >= 4 ? (
            <Spacing key={tagData.tag.slug} size={[4, 4, 8]}>
              <FeaturedCampaignsRail
                title={tagData.tag.name}
                subtitle={tagData.tag.description}
                campaigns={tagData.campaigns}
              />
            </Spacing>
          ) : null
        )}

        {ugcRailData?.campaigns?.length ? (
          <Spacing size={8}>
            <UGCRail ugcRailData={ugcRailData} />
          </Spacing>
        ) : null}

        {featureCardsData?.cards?.length ? (
          <Spacing size={8}>
            <FeatureCardGrid {...featureCardsData} />
          </Spacing>
        ) : null}

        <Spacing size={8}>
          <Cta
            isDark
            icon={tshirtIcon}
            linkProps={{
              to: '/create',
              isExternal: true
            }}
            linkText="Start designing"
          >
            Have a design idea? Head to our campaign builder to create your own
            custom products to sell.
          </Cta>
        </Spacing>

        {featuredProfiles.length ? (
          <Spacing size={8}>
            <FeaturedProfileRail featuredProfiles={featuredProfiles} />
          </Spacing>
        ) : null}

        {collectionCardsData?.cards?.length ? (
          <Spacing size={8}>
            <ContentCardsGrid {...collectionCardsData} />
          </Spacing>
        ) : null}

        {blogPostsData?.blog_posts?.length ? (
          <Spacing size={8}>
            <FeaturedBlogPosts data={blogPostsData} />
          </Spacing>
        ) : null}

        {/*}
          <Spacing size={8}>
            <Cta
              icon={mapIcon}
              linkProps={{
                to: '/garments'
              }}
            >
              See our range of garments including ethically sourced and organic
              options
            </Cta>
          </Spacing>
        */}

        {featureCards1Data?.cards?.length ? (
          <Spacing size={8}>
            <FeatureCardGrid {...featureCards1Data} />
          </Spacing>
        ) : null}

        <Spacing size={8}>
          <Cta isDark icon={emailIcon} kind="newsletter">
            Stay updated: new drops, recommendations, long reads and more
          </Cta>
        </Spacing>
      </PageWrap>
    </Page>
  );
};

const mapStateToProps = ({ pages, campaigns, discovery }) => ({
  heading: get(pages.homepage, 'data.heading'),
  seoTitle: get(pages.homepage, 'data.seo_title'),
  slides: get(pages.homepage, 'data.hero_slides') || [],
  featuredTags: campaigns.featuredTags,
  ugcRailData: get(pages.homepage, 'data.ugc_rail.data'),
  featureCardsData: get(pages.homepage, 'data.hero_feature_cards.data'),
  featureCards1Data: get(pages.homepage, 'data.hero_feature_cards1.data'),
  collectionCardsData: get(pages.homepage, 'data.collection_cards.data'),
  blogPostsData: get(pages.homepage, 'data.blog_posts.data'),
  featuredProfiles: discovery.featuredProfiles
});

export default connect(mapStateToProps, {
  ...campaignsActions
})(Home);
