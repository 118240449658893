import React, { useState, useEffect } from 'react';
import toast, { Toaster, ToastBar, useToasterStore } from 'react-hot-toast';
import useResizeAware from 'react-resize-aware';
import { FocusScope } from '@react-aria/focus';
import { AnimatePresence, motion } from 'framer-motion';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';

import { theme } from '../../../theme-config';

import generalStyles from './style/general.module.css';

import { overlayVariants } from './variants';

const MOBILE_MQ = `(orientation: portrait) and (max-width: ${theme.screens.md}),
       (orientation: landscape) and (max-width: 811px)`;

const defaultOptions = {
  duration: 5000,
  style: {
    margin: 0,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    boxShadow: 'none'
  },
  ariaProps: {
    role: 'status',
    'aria-live': 'polite'
  }
};

const desktopOptions = merge({}, defaultOptions, {
  position: 'top-right',
  style: { padding: '11px 5px', minWidth: '368px' }
});

const mobileOptions = merge({}, defaultOptions, {
  position: 'bottom-center',
  style: {
    padding: '12px 5px 16px',
    minWidth: '100%',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  }
});

const mqReporter = (target) => ({
  width: target != null ? target.clientWidth : null,
  isMobile: target != null ? window.matchMedia(MOBILE_MQ).matches : null
});

const getContainerOffset = (container) => {
  if (!(container instanceof Element))
    return {
      top: 15,
      bottom: 15,
      right: 15,
      left: 15
    };

  const rect = container.getBoundingClientRect();

  const paddingLeft = parseFloat(
    window.getComputedStyle(container, null).getPropertyValue('padding-left')
  );

  const paddingRight = parseFloat(
    window.getComputedStyle(container, null).getPropertyValue('padding-right')
  );

  return {
    top: 77, // Using rect.top causes the toaster to lose visibility on scroll due to nav position change
    bottom: rect.bottom,
    right: rect.right - rect.width + paddingRight + 12,
    left: rect.left + paddingLeft
  };
};

const ToastManager = ({ container, gutter, shade, ...props }) => {
  const { toasts } = useToasterStore();
  const [isMobile, setIsMobile] = useState(true);
  const [resizeListener, sizes] = useResizeAware(mqReporter);
  const offset = getContainerOffset(container);

  const options = isMobile ? mobileOptions : desktopOptions;

  const visibleToasts = toasts.filter((t) => t.visible);

  const containerStyle = isMobile
    ? { left: 0, right: 0, bottom: 0 }
    : { top: offset.top, right: offset.right };

  useEffect(() => {
    setIsMobile(sizes.isMobile);
  }, [sizes.isMobile]);

  const dismissAllToasts = () => {
    for (const t in toasts) toast.dismiss(t.id);
  };

  return (
    <FocusScope contain restoreFocus autoFocus>
      <Toaster
        position={options.position}
        containerStyle={containerStyle}
        gutter={gutter}
        toastOptions={options}
        {...props}
      >
        {(t) => (
          <ToastBar
            toast={t}
            style={{
              ...t.style,
              animation: t.visible
                ? '0.35s linear 0s 1 normal forwards running fade-in'
                : '0.35s linear 0s 1 reverse forwards running fade-in'
            }}
          />
        )}
      </Toaster>
      <div className="fixed w-screen z-header">
        {resizeListener}
        {shade && (
          <AnimatePresence>
            {visibleToasts.length > 0 && (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={overlayVariants}
                className={generalStyles.overlay}
                onClick={dismissAllToasts}
              />
            )}
          </AnimatePresence>
        )}
      </div>
    </FocusScope>
  );
};

export default ToastManager;

export { toast };

ToastManager.propTypes = {
  container: PropTypes.instanceOf(Element),
  gutter: PropTypes.number,
  shade: PropTypes.bool
};

ToastManager.defaultProps = {
  gutter: 8,
  shade: true
};
