import React from 'react';
import PropTypes from 'prop-types';

import throttle from 'lodash/throttle';

export const ScrollTrackerContext = React.createContext();

class ScrollTracker extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    el: PropTypes.object
  };

  static defaultProps = {
    el: window
  };

  constructor() {
    super();

    this.state = {
      y: 0,
      x: 0
    };

    this.handleScroll = throttle(this.handleScroll.bind(this), 100);
  }

  componentDidMount() {
    this.props.el.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.props.el.removeEventListener('scroll', this.handleScroll);
    this.handleScroll.cancel();
  }

  handleScroll(e) {
    if (this.props.el === window) {
      this.setState({
        y:
          window.document.documentElement.scrollTop ||
          window.document.scrollingElement.scrollTop,
        x:
          window.document.documentElement.scrollLeft ||
          window.document.scrollingElement.scrollTop
      });
    } else {
      this.setState({
        y: e.target.scrollTop,
        x: e.target.scrollLeft
      });
    }
  }

  render() {
    return (
      <ScrollTrackerContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </ScrollTrackerContext.Provider>
    );
  }
}

export default ScrollTracker;
