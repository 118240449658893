import amplitudeJs from 'amplitude-js';

import config from '../config';

export default function amplitude() {
  if (!amplitudeJs.getInstance()._isInitialized) {
    amplitudeJs.getInstance().init(config.amplitudeKey, null, {
      deferInitialization: true,
      includeUtm: true
    });
  }
  // Need amplitude available on window to make sure the same instance is accessed from the GTM tag
  window.amplitude = amplitudeJs;

  return amplitudeJs.getInstance();
}

export const sendAmplitudeEvent = (eventType, eventProperties) => {
  amplitude().logEvent(eventType, eventProperties);
};

export const setAmplitudeUserProperties = (properties) => {
  amplitude().setUserProperties(properties);
};

export const setAmplitudeUserId = (userId) => {
  amplitude().setUserId(userId);
};

export const regenerateAmplitudeDeviceId = () => {
  amplitude().regenerateDeviceId();
};
