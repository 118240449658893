export const getOrder = token => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'GET_ORDER',
    payload: baseApi.get(`/order/${token}`),
    meta: { token }
  });

export const editOrder = (token, data) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'EDIT_ORDER',
    payload: baseApi.put(`/order/${token}`, data),
    meta: { token }
  });

export const bulkOrder = formData => (dispatch, getState, { baseApi }) =>
  dispatch({
    type: 'BULK_ORDER',
    payload: baseApi.post('bulk-orders', formData)
  });

export const claimOrder = token => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'CLAIM_ORDER',
    payload: baseApi.post(`/order/${token}/claim`),
    meta: { token }
  });
