import React from 'react';

export { default as TableItem } from './TableItem';
export { default as TableRow } from './TableRow';
export { default as TableHeader } from './TableHeader';

export const TableContext = React.createContext();

export const Table = ({ className, children, ItemProps, RowProps }) => {
  return (
    <TableContext.Provider
      value={{
        ItemProps,
        RowProps
      }}
    >
      <div className={className}>{children}</div>
    </TableContext.Provider>
  );
};
