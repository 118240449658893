import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as userActions from '../../../actions/user';

import Spacing from '../../../components/atoms/Spacing';
import Alert from '../../../components/atoms/Alert';
import { ButtonNew as Button } from '../../../components/atoms/Button';
import { InlineGrid } from '../../../components/atoms/Grid';

import { ModalNew } from '../../../components/molecules/Modal';
import AuthenticationContainer from '../../../components/molecules/AuthenticationContainer';

const VisitorActions = ({ profileUserId }) => {
  const user = useSelector((state) => state.user, shallowEqual);

  const [authModal, setAuthModal] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const timedSetError = (errorValue) => {
    setError(errorValue);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const handleFollowProfile = async () => {
    if (user.userId) {
      if (isFollowing) {
        try {
          await dispatch(userActions.unfollowUser(profileUserId));
          setIsFollowing(false);
        } catch (err) {
          if (err.response.status === 400) {
            timedSetError('Unable to unfollow this creator.');
          }
        }
      } else {
        try {
          await dispatch(
            userActions.followUser({
              userFollower: user.userId,
              userFollowee: profileUserId
            })
          );
          setIsFollowing(true);
        } catch (err) {
          if (err.response.status === 400) {
            timedSetError('You are already following this creator.');
          }
        }
      }
    } else {
      setAuthModal(true);
    }
  };

  const handleAuthenticationSuccess = async () => {
    await dispatch(userActions.getUser());
    if (user.userId !== profileUserId) {
      await dispatch(userActions.getUserFollowees());
      handleFollowProfile();
    }
    setAuthModal(false);
  };

  useEffect(() => {
    if (user.userId) {
      setIsFollowing(
        !!user.following.list.find((item) => item.userId === profileUserId)
      );
    }
  }, [user, profileUserId]);

  return (
    <>
      <Spacing size={error ? 1 : 4} position="b">
        <InlineGrid>
          <Button
            kind={isFollowing ? 'solid' : 'outline'}
            size="small"
            onClick={handleFollowProfile}
          >
            {isFollowing ? 'Following' : 'Follow'}
          </Button>
        </InlineGrid>
      </Spacing>
      {error && (
        <Spacing size={4} position="b">
          <Alert message={error} kind="info" />
        </Spacing>
      )}
      <ModalNew isOpen={authModal} handleClose={() => setAuthModal(false)}>
        <AuthenticationContainer
          initialUserStage="signUp"
          onSuccess={handleAuthenticationSuccess}
        />
      </ModalNew>
    </>
  );
};

export default VisitorActions;
