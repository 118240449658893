import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import propToClassName from '../../../../helpers/propToClassName';
import { fontSizeMap } from '../../../../helpers/themeMap';

import { InlineGrid } from '../../../../components/atoms/Grid';

import generalStyles from './style/general.module.css';
import kindStyles from './style/kind.module.css';

const cx = classNames.bind({
  ...generalStyles,
  ...kindStyles
});

const Pill = ({ kind, size, children, ...props }) => {
  const sizeClassName = propToClassName(size, fontSizeMap);

  return (
    <div className={cx('pill', kind, sizeClassName)} {...props}>
      <InlineGrid align="center">
        <span className="uppercase">{children}</span>
      </InlineGrid>
    </div>
  );
};

Pill.defaultProps = {
  kind: 'default',
  size: 'xxs'
};

Pill.propTypes = {
  kind: PropTypes.oneOf(Object.keys(kindStyles)),
  size: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(Object.keys(fontSizeMap))),
    PropTypes.oneOf(Object.keys(fontSizeMap))
  ]),
  children: PropTypes.node
};

export default Pill;
