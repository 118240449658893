import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment
} from 'react';
import { connect } from 'react-redux';
import isEqual from 'react-fast-compare';
import { motion } from 'framer-motion';
import each from 'lodash/each';
import omit from 'lodash/omit';
import * as Sentry from "@sentry/react";

import usePrevious from '../../../helpers/hooks/usePrevious';
import { transformActiveFilterSets } from '../../../helpers/shop';

import * as shopActions from '../../../actions/shop';

import { ButtonNew as Button } from '../../../components/atoms/Button';
import { Form, Field, Label } from '../../../components/atoms/Form';
import { InlineGrid, Grid, GridItem } from '../../../components/atoms/Grid';
import Heading from '../../../components/atoms/Heading';
import Icon from '../../../components/atoms/Icon';
import Spacing from '../../../components/atoms/Spacing';

import ClearButton from './ClearButton';

import generalStyles from './style/general.module.css';

import { containerVariants, overlayVariants } from './variants';

import crossIcon from '../../../img/sprites/cross.svg';

const ShopFilters = ({
  options,
  activeFilters,
  onClose,
  onSubmit,
  getShopAvailableItemsCount,
  defaultFilters
}) => {
  const [selectedFilters, setSelectedFilters] = useState(activeFilters);
  const [availableItemsCount, setAvailableItemsCount] = useState(null);
  const prevSelectedFilters = usePrevious(selectedFilters);
  const formRef = useRef(null);

  const handleFormChange = (values) => {
    setSelectedFilters(values);
  };

  const handleFormReset = () => {
    formRef.current && formRef.current.resetForm(defaultFilters);
  };

  const handleFormFilterSetReset = (filter, state) => {
    formRef.current &&
      each(activeFilters[filter], (val, key) => {
        formRef.current.setFieldValue(`${filter}.${key}`, state);
      });
  };

  const handleFormSubmit = (values) => {
    onSubmit(values);
  };

  const resetAvailableItemsCount = useCallback(
    async (filters) => {
      const transformedActiveFilterSets = transformActiveFilterSets({
        ...omit(filters, 'sort')
      });

      try {
        const response = await getShopAvailableItemsCount(
          transformedActiveFilterSets
        );
        setAvailableItemsCount(response.value);
      } catch (err) {
        Sentry.captureException(err);
        setAvailableItemsCount(null);
      }
    },
    [getShopAvailableItemsCount]
  );

  useEffect(() => {
    /**
     * We use usePrevious hook and check for equality to avoid
     * having to split selectedFilters into separate state objects
     */
    if (
      prevSelectedFilters &&
      !isEqual(omit(selectedFilters, 'sort'), omit(prevSelectedFilters, 'sort'))
    ) {
      resetAvailableItemsCount(selectedFilters);
    }
    if (!prevSelectedFilters) resetAvailableItemsCount(selectedFilters);
  }, [selectedFilters, prevSelectedFilters, resetAvailableItemsCount]);

  return (
    <Fragment>
      <motion.aside
        initial="closed"
        animate="open"
        exit="closed"
        variants={containerVariants}
        className={generalStyles.container}
      >
        <Form
          enableReinitialize
          initialValues={activeFilters}
          onSubmit={handleFormSubmit}
          onChange={handleFormChange}
          ref={formRef}
          render={({ isSubmitting, handleSubmit, values }) => (
            <form onSubmit={handleSubmit} className={generalStyles.form}>
              <Grid gap={0}>
                <GridItem columnSize={12}>
                  <InlineGrid justify="end">
                    <Button
                      type="button"
                      aria-label="close"
                      onClick={onClose}
                      kind="ghost"
                    >
                      <Icon glyph={crossIcon} width="20" height="20" />
                    </Button>
                  </InlineGrid>
                  <Spacing size={2} position="t">
                    <InlineGrid>
                      <Heading size={['s', 'm']} tag="h2">
                        Filters
                      </Heading>
                      <ClearButton onClick={handleFormReset}>
                        Reset all
                      </ClearButton>
                    </InlineGrid>
                  </Spacing>
                  <div className="border-b-1">
                    <Spacing size={2} position="t">
                      <Heading
                        size={['xxs', 'xs']}
                        tag="h4"
                        className="leading-relaxed"
                      >
                        Sort by
                      </Heading>
                    </Spacing>
                    <div className={generalStyles.filterSet}>
                      {options.sort.map((item) => {
                        return (
                          <Field
                            type="tickRadio"
                            name="sort"
                            id={`sort_${item.value}`}
                            value={item.value}
                            checked={values.sort === item.value}
                            key={item.value}
                          >
                            <Label
                              htmlFor={`sort_${item.value}`}
                              noPad
                              type="paragraph"
                              size={['xxs', 'xs']}
                            >
                              {item.label}
                            </Label>
                          </Field>
                        );
                      })}
                    </div>
                  </div>
                  {!!options.productCategories ? (
                    <div className="border-b-1">
                      <Spacing size={2} position="t">
                        <InlineGrid>
                          <Heading
                            size={['xxs', 'xs']}
                            tag="h4"
                            className="leading-relaxed"
                          >
                            Garment type
                          </Heading>
                          <ClearButton
                            onClick={() =>
                              handleFormFilterSetReset(
                                'productCategories',
                                false
                              )
                            }
                          >
                            Clear
                          </ClearButton>
                        </InlineGrid>
                      </Spacing>
                      <div className={generalStyles.filterSet}>
                        {options.productCategories.map((item) => {
                          return (
                            <Field
                              type="tickCheckbox"
                              name={`productCategories.${item.value}`}
                              key={item.value}
                            >
                              <Label
                                htmlFor={`productCategories.${item.value}`}
                                noPad
                                type="paragraph"
                                size={['xxs', 'xs']}
                              >
                                {item.label}
                              </Label>
                            </Field>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                  <div className="border-b-1">
                    <Spacing size={2} position="t">
                      <InlineGrid>
                        <Heading
                          size={['xxs', 'xs']}
                          tag="h4"
                          className="leading-relaxed"
                        >
                          Garment fit
                        </Heading>
                        <ClearButton
                          onClick={() =>
                            handleFormFilterSetReset('productFits', false)
                          }
                        >
                          Clear
                        </ClearButton>
                      </InlineGrid>
                    </Spacing>
                    <div className={generalStyles.filterSet}>
                      {options.productFits.map((item) => {
                        return (
                          <Field
                            type="tickCheckbox"
                            name={`productFits.${item.value}`}
                            key={item.value}
                          >
                            <Label
                              htmlFor={`productFits.${item.value}`}
                              noPad
                              type="paragraph"
                              size={['xxs', 'xs']}
                            >
                              {item.label}
                            </Label>
                          </Field>
                        );
                      })}
                    </div>
                  </div>
                  <div className="border-b-1">
                    <Spacing size={2} position="t">
                      <InlineGrid>
                        <Heading
                          size={['xxs', 'xs']}
                          tag="h4"
                          className="leading-relaxed"
                        >
                          Front & back print
                        </Heading>
                        <ClearButton
                          onClick={() =>
                            handleFormFilterSetReset(
                              'productPrintPositions',
                              false
                            )
                          }
                        >
                          Clear
                        </ClearButton>
                      </InlineGrid>
                    </Spacing>
                    <div className={generalStyles.filterSet}>
                      {options.productPrintPositions.map((item) => {
                        return (
                          <Field
                            type="tickCheckbox"
                            name={`productPrintPositions.${item.value}`}
                            key={item.value}
                          >
                            <Label
                              htmlFor={`productPrintPositions.${item.value}`}
                              noPad
                              type="paragraph"
                              size={['xxs', 'xs']}
                            >
                              {item.label}
                            </Label>
                          </Field>
                        );
                      })}
                    </div>
                  </div>
                  <div className="border-b-1">
                    <Spacing size={2} position="t">
                      <InlineGrid>
                        <Heading
                          size={['xxs', 'xs']}
                          tag="h4"
                          className="leading-relaxed"
                        >
                          Size
                        </Heading>
                        <ClearButton
                          onClick={() =>
                            handleFormFilterSetReset('productSizes', false)
                          }
                        >
                          Clear
                        </ClearButton>
                      </InlineGrid>
                    </Spacing>
                    <Spacing size="05" position="b" type="padding">
                      <div className={generalStyles.filterSet}>
                        <Grid gap="05">
                          {options.productSizes.map((item) => {
                            return (
                              <GridItem columnSize={4} key={item.value}>
                                <Spacing size="05">
                                  <Field
                                    type="buttonCheckbox"
                                    name={`productSizes.${item.value}`}
                                    size={['m', 'l']}
                                  >
                                    <Label
                                      htmlFor={`productSizes.${item.value}`}
                                      noPad
                                      type="paragraph"
                                      size={['xxs', 'xs']}
                                    >
                                      {item.label}
                                    </Label>
                                  </Field>
                                </Spacing>
                              </GridItem>
                            );
                          })}
                        </Grid>
                      </div>
                    </Spacing>
                  </div>
                  <div>
                    <Spacing size={2} position="t">
                      <InlineGrid>
                        <Heading
                          size={['xxs', 'xs']}
                          tag="h4"
                          className="leading-relaxed"
                        >
                          Colour
                        </Heading>
                        <button
                          type="button"
                          className={generalStyles.clearButton}
                          onClick={() =>
                            handleFormFilterSetReset('productColours', false)
                          }
                        >
                          Clear
                        </button>
                      </InlineGrid>
                    </Spacing>
                    <Spacing size={2} position="b">
                      <div
                        className={`${generalStyles.filterSet} overflow-x-hidden`}
                      >
                        <Grid gap={[1, 2]}>
                          {options.productColours.map((item) => {
                            return (
                              <GridItem columnSize={3} key={item.value}>
                                <Spacing size="05">
                                  <Field
                                    type="colourCircleCheckbox"
                                    name={`productColours.${item.value}`}
                                    hex={item.hex}
                                  >
                                    <Label
                                      htmlFor={`productColours.${item.value}`}
                                      noPad
                                      type="paragraph"
                                      size={['xxs', 'xs']}
                                    >
                                      {item.label}
                                    </Label>
                                  </Field>
                                </Spacing>
                              </GridItem>
                            );
                          })}
                        </Grid>
                      </div>
                    </Spacing>
                  </div>
                </GridItem>
              </Grid>
              <div className={generalStyles.stickyContainer}>
                <Spacing size={2} position="b" type="padding">
                  <div className={generalStyles.buttonContainer}>
                    <Button
                      type="submit"
                      state={
                        isSubmitting ||
                        (!availableItemsCount && availableItemsCount !== null)
                          ? 'disabled'
                          : null
                      }
                      fullWidth
                      className="relative"
                    >
                      {!!availableItemsCount
                        ? `Apply (${availableItemsCount})`
                        : availableItemsCount === null
                        ? 'Apply'
                        : 'No items available'}
                    </Button>
                  </div>
                </Spacing>
                <div className={generalStyles.stickyOverlay} />
              </div>
            </form>
          )}
        />
      </motion.aside>
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={overlayVariants}
        className={generalStyles.overlay}
        onClick={onClose}
      />
    </Fragment>
  );
};

const mapStateToProps = ({ shop }) => {
  return {
    activeFilters: shop.activeFilters
  };
};

export default connect(mapStateToProps, { ...shopActions })(ShopFilters);
