export const subscribe = (email, listId, source, props) => (
  dispatch,
  _,
  { baseApi }
) => {
  const payload = {
    email,
    listId,
    source,
    profile: {
      email
    },
    properties: {
      ...props
    }
  };

  return dispatch({
    type: 'KLAVIYO_SUBSCRIBE',
    payload: baseApi.post('/subscribe', payload)
  });
};
