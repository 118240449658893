import React from 'react';

import Spacing from '../../../../components/atoms/Spacing';
import Paragraph from '../../../../components/atoms/Paragraph';
import { InlineGrid } from '../../../../components/atoms/Grid';
import { ButtonNew as Button } from '../../../../components/atoms/Button';
import MaxWidth from '../../../../components/atoms/MaxWidth';

const ContactUs = () => {
  return (
    <div className="border-t-1 border-b-1 border-grey-lighter">
      <Spacing size={[6, 6, 6, 8]} position="y">
        <MaxWidth value={471} center>
          <Paragraph size={['xs', 'xs', 's']} className="uppercase" center>
            If you have any questions about Diversity and Inclusion at
            Everpress, you can reach out to us.
          </Paragraph>
        </MaxWidth>
        <Spacing size={2} type="margin" position="t">
          <InlineGrid justify="center">
            <Button
              to="mailto:diversity@everpress.com"
              kind="solid"
              className="m-1"
              isExternal
            >
              Contact us
            </Button>
          </InlineGrid>
        </Spacing>
      </Spacing>
    </div>
  );
};

export default ContactUs;
