import React from 'react';
import isEqual from 'react-fast-compare';
import get from 'lodash/get';

import Heading from '../../../components/atoms/Heading';
import { Field, Label, TinySelect } from '../../../components/atoms/Form';
import Paragraph from '../../../components/atoms/Paragraph';
import { ButtonNew as Button } from '../../../components/atoms/Button';
import Price from '../../../components/atoms/Price';
import MaxWidth from '../../../components/atoms/MaxWidth';
import Spacing from '../../../components/atoms/Spacing';
import Badge from '../../../components/atoms/Badge';
import SelectCurrencies from '../../../components/atoms/Select/SelectCurrencies';
import { Grid, InlineGrid, GridItem } from '../../../components/atoms/Grid';
import Slider from '../../../components/molecules/Slider';
import { Box, BoxItem } from '../../../components/molecules/Box';
import Tooltip from '../../../components/atoms/Tooltip';

import sliderStyles from './style/slider.module.css';
import badgeStyles from './style/badge.module.css';
import accessibleStyles from './style/accessible.module.css';

export default class PriceCalculatorForm extends React.Component {
  state = {
    campaignsCount: 4
  };

  componentDidMount() {
    if (!this.props.values.price) {
      this.props.setFieldValue(
        'price',
        this.props.payout.requested.products[0].recommendedPrice
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { payout, initialValues, touched, values } = this.props;

    // If new prices received
    if (!isEqual(prevProps.payout.requested, payout.requested)) {
      // If price is lower than minimum or is not touched
      if (
        values.price < payout.requested.products[0].minimumPrice ||
        values.price === prevProps.payout.requested.products[0].recommendedPrice
      ) {
        // Set price to recommended price
        this.props.setFieldValue(
          'price',
          payout.requested.products[0].recommendedPrice
        );

        // If price was touched by user
        if (touched.price) {
          console.log('Sorry we had to reset your price!');
        }

        // Set price as untouched
        this.props.setFieldTouched('price', false, false);
      }
    }

    // Only allow the price to be reset if it is valid otherwise continue to highlight the error
    if (values.price > payout.requested.products[0].minimumPrice) {
      if (
        (values.currency !== prevProps.values.currency ||
          values.product !== prevProps.values.product) &&
        values.price !== payout.requested.products[0].recommendedPrice
      ) {
        // If currency or product changed
        // Reset price
        this.props.setFieldValue('price', initialValues.price);
        this.props.setFieldTouched('price', false, false);
      }

      // If changing to coloured garments with the price close to minimum
      if (
        values.darkGarment &&
        !prevProps.values.darkGarment &&
        values.price <= Math.ceil(payout.requested.products[0].minimumPrice)
      ) {
        // Reset price
        this.props.setFieldValue('price', initialValues.price);
        this.props.setFieldTouched('price', false, false);
      }

      // If changing from one to two sides with a price lower than recommended
      if (
        values.coloursFront !== 0 &&
        values.coloursBack !== 0 &&
        (prevProps.values.coloursFront === 0 ||
          prevProps.values.coloursBack === 0) &&
        values.price < payout.requested.products[0].recommendedPrice
      ) {
        // Reset price
        this.props.setFieldValue('price', initialValues.price);
        this.props.setFieldTouched('price', false, false);
      }
    }

    // If both front and back colours are set to 0
    if (values.coloursFront === 0 && values.coloursBack === 0) {
      // Reset front colour to initial value
      if (prevProps.values.coloursFront === values.coloursFront) {
        this.props.setFieldValue('coloursFront', 1, false);
      } else {
        this.props.setFieldValue('coloursBack', 1, false);
      }
    }
  }

  handleCampaignsCountChange(value) {
    this.setState({
      campaignsCount: value
    });
  }

  render() {
    const {
      garments,
      payout,
      handleSubmit,
      values,
      setFieldValue
    } = this.props;
    const { campaignsCount } = this.state;
    const product = get(payout, 'requested.products[0]', {});
    const { screenThreshold, amount } = payout.requested;

    const garmentTooltip = (
      <MaxWidth value={300}>
        The cost of printing onto coloured garments is slightly higher than
        printing onto whites because a base layer is required.
      </MaxWidth>
    );
    const frontTooltip = (
      <MaxWidth value={300}>How many colours in the front design ?</MaxWidth>
    );
    const backTooltip = (
      <MaxWidth value={300}>How many colours in the back design ?</MaxWidth>
    );
    const saleTooltip = (
      <MaxWidth value={300}>
        How much do you recommend these items sell for?
      </MaxWidth>
    );
    const howmanyTooltip = (
      <MaxWidth value={300}>
        This is just a goal, we won't hold you to it and there's no upper limit.
        The minimum order quantity is 5 items.
      </MaxWidth>
    );
    const digitalTooltip = (
      <MaxWidth value={300}>
        <Spacing position="b" size={1}>
          Digital printing is used on lower quantities or full colour designs.
          It works in a similar way to a desktop printer in an office.
        </Spacing>
        The low setup costs of digital printing ensures that a profit can be
        generated if just 5 or more items are sold.
      </MaxWidth>
    );
    const screenTooltip = (
      <MaxWidth value={300}>
        At higher quantities, screen printing produces excellent quality,
        long-lasting results and is great value for higher volume sales due to
        economies of scale. It's better suited to artwork with 1-3 spot colours
        as opposed to photos or full colour images with gradients.
      </MaxWidth>
    );
    const minimumTooltip = (
      <MaxWidth value={300}>
        This covers the garment and production costs necessary for a campaign to
        be printed at the minimum of 5 items.
      </MaxWidth>
    );
    const currencyTooltip = (
      <MaxWidth value={300}>
        Garments can be sold in 4 default currencies, or get in touch with us if
        you’d like to use a custom currency.
      </MaxWidth>
    );

    return (
      <form onSubmit={handleSubmit}>
        <Grid gap={2} align="start">
          <GridItem columnSize={[12, 12, 12, 12, 6]}>
            <Box size="s">
              <Spacing size={2} position="b">
                <Heading tag="h2" size="s">
                  Price calculator
                </Heading>
              </Spacing>

              <Spacing size={1}>
                <Grid gap={1}>
                  <GridItem columnSize={5}>
                    <Label size={['xxxs', 'xxs']} noPad htmlFor="product">
                      Product
                    </Label>
                  </GridItem>
                  <GridItem columnSize={7}>
                    <Field
                      type="select"
                      name="product"
                      options={garments.map((item) => ({
                        value: item.sku,
                        label: `${item.brand} — ${item.name}`
                      }))}
                      disabled={!garments.length}
                      hasNoBorder
                    />
                  </GridItem>
                </Grid>
              </Spacing>

              <Spacing size={2}>
                <Grid gap={1}>
                  <GridItem columnSize={5}>
                    <Label size={['xxxs', 'xxs']} noPad htmlFor="darkGarment">
                      <InlineGrid justify="start">
                        <span className="mr-1">Garment colour</span>
                        <Tooltip
                          className="flex-shrink-0"
                          content={garmentTooltip}
                          id="darkGarment"
                        />
                      </InlineGrid>
                    </Label>
                  </GridItem>
                  <GridItem columnSize={7}>
                    <Field
                      type="toggle"
                      border={false}
                      leftTitle="White"
                      rightTitle="Coloured"
                      name="darkGarment"
                    />
                  </GridItem>
                </Grid>
              </Spacing>

              <Spacing size={1}>
                <Grid gap={1}>
                  <GridItem columnSize={5}>
                    <Label size={['xxxs', 'xxs']} noPad htmlFor="coloursFront">
                      <InlineGrid justify="start">
                        <span className="mr-1">Front Artwork Colours</span>
                        <Tooltip
                          className="flex-shrink-0"
                          content={frontTooltip}
                          id="coloursFront"
                        />
                      </InlineGrid>
                    </Label>
                  </GridItem>
                  <GridItem columnSize={7}>
                    <Field
                      type="select"
                      name="coloursFront"
                      options={[
                        { value: 0, label: '0' },
                        { value: 1, label: '1' },
                        { value: 2, label: '2' },
                        { value: 3, label: '3' },
                        { value: 4, label: '4' },
                        { value: 5, label: '5' },
                        { value: 6, label: '6' },
                        { value: 7, label: '7' },
                        { value: 8, label: '8' },
                        { value: 9, label: '9+' },
                        { value: 10, label: 'DTG' }
                      ]}
                    />
                  </GridItem>
                </Grid>
              </Spacing>

              <Spacing size={1}>
                <Grid gap={1}>
                  <GridItem columnSize={5}>
                    <Label size={['xxxs', 'xxs']} noPad htmlFor="coloursBack">
                      <InlineGrid justify="start">
                        <span className="mr-1">Back Artwork Colours</span>
                        <Tooltip
                          className="flex-shrink-0"
                          content={backTooltip}
                          id="coloursBack"
                        />
                      </InlineGrid>
                    </Label>
                  </GridItem>
                  <GridItem columnSize={7}>
                    <Field
                      type="select"
                      name="coloursBack"
                      options={[
                        { value: 0, label: '0' },
                        { value: 1, label: '1' },
                        { value: 2, label: '2' },
                        { value: 3, label: '3' },
                        { value: 4, label: '4' },
                        { value: 5, label: '5' },
                        { value: 6, label: '6' },
                        { value: 7, label: '7' },
                        { value: 8, label: '8' },
                        { value: 9, label: '9+' },
                        { value: 10, label: 'DTG' }
                      ]}
                    />
                  </GridItem>
                </Grid>
              </Spacing>

              <Spacing size={1}>
                <Grid gap={1}>
                  <GridItem columnSize={5}>
                    <Label size={['xxxs', 'xxs']} noPad htmlFor="price">
                      <InlineGrid justify="start">
                        <span className="mr-1">Sale Price</span>
                        <Tooltip
                          className="flex-shrink-0"
                          content={saleTooltip}
                          id="price"
                        />
                      </InlineGrid>
                    </Label>
                  </GridItem>
                  <GridItem columnSize={7}>
                    <Field type="number" name="price" />
                  </GridItem>
                </Grid>
              </Spacing>

              <div className={sliderStyles.container}>
                <Spacing size={2} type="padding">
                  <Heading tag="h3" size="s">
                    Set a goal
                  </Heading>
                  <Spacing size={1}>
                    <Heading tag="h4" size="xxs">
                      <span>
                        <InlineGrid justify="start">
                          <span className="mr-1">
                            How many garments do you estimate your design will
                            sell?
                          </span>
                          <Tooltip
                            className="flex-shrink-0"
                            content={howmanyTooltip}
                            id="quantity"
                          />
                        </InlineGrid>
                      </span>
                    </Heading>
                  </Spacing>
                  <Grid gap={1}>
                    <GridItem columnSize={[12, 9]}>
                      <Spacing size={[0, 1]} position="r" type="padding">
                        <Slider
                          defaultValue={[
                            Math.min(Math.max(1, values.quantity), 3000)
                          ]}
                          min={1}
                          max={3000}
                          step={1}
                          marks={[
                            {
                              value: 1,
                              label: '1'
                            },
                            {
                              value: 500,
                              label: '500'
                            },
                            {
                              value: 1000,
                              label: '1000'
                            },
                            {
                              value: 1500,
                              label: '1500'
                            },
                            {
                              value: 2000,
                              label: '2000'
                            },
                            {
                              value: 2500,
                              label: '2500'
                            },
                            {
                              value: 3000,
                              label: '3000'
                            }
                          ]}
                          onValueCommit={(values) => {
                            setFieldValue('quantity', values[0]);
                          }}
                        />
                      </Spacing>
                    </GridItem>
                    <GridItem columnSize={[12, 3]}>
                      <Spacing size={[1, 0]} position="t">
                        <Field type="number" name="quantity" textCenter />
                      </Spacing>
                    </GridItem>
                  </Grid>
                </Spacing>
              </div>

              <section
                className={accessibleStyles.readerContainer}
                tabIndex={0}
              >
                <Heading tag="div" size="xxs">
                  The print method is based on how many items are sold.
                </Heading>

                <BoxItem size="s" border="b" isBorderLight>
                  <Grid gap={1}>
                    <GridItem columnSize={4}>
                      <Paragraph size="xxxs">No. of Sales</Paragraph>
                    </GridItem>
                    <GridItem columnSize={4}>
                      <Paragraph size="xxxs">Print Method</Paragraph>
                    </GridItem>
                  </Grid>
                </BoxItem>
                <BoxItem size="s" border="b" isBorderLight>
                  <Grid gap={1}>
                    <GridItem columnSize={4}>
                      <Paragraph size="xxs">
                        {screenThreshold
                          ? `0 - ${screenThreshold - 1} sales`
                          : '0+ sales'}
                      </Paragraph>
                    </GridItem>
                    <GridItem columnSize={4}>
                      <Paragraph size="xxs">
                        <InlineGrid justify="start">
                          <span className="mr-1">Digital printing</span>
                          <Tooltip
                            className="flex-shrink-0"
                            content={digitalTooltip}
                            id="digitalPrinting"
                          />
                        </InlineGrid>
                      </Paragraph>
                    </GridItem>
                    <GridItem columnSize={4}>
                      <div className={badgeStyles.container}>
                        {values.quantity < screenThreshold && (
                          <span className={badgeStyles.badge}>
                            <Badge>Based on your estimate</Badge>
                          </span>
                        )}
                      </div>
                    </GridItem>
                  </Grid>
                </BoxItem>
                {screenThreshold && (
                  <BoxItem size="s" border="b" isBorderLight>
                    <Grid gap={1}>
                      <GridItem columnSize={4}>
                        <Paragraph size="xxs">{`${screenThreshold}+ sales`}</Paragraph>
                      </GridItem>
                      <GridItem columnSize={4}>
                        <Paragraph size="xxs">
                          <InlineGrid justify="start">
                            <span className="mr-1">Screen Printing</span>
                            <Tooltip
                              className="flex-shrink-0"
                              content={screenTooltip}
                              id="screenPrinting"
                            />
                          </InlineGrid>
                        </Paragraph>
                      </GridItem>
                      <GridItem columnSize={4}>
                        <div className={badgeStyles.container}>
                          {values.quantity >= screenThreshold && (
                            <span className={badgeStyles.badge}>
                              <Badge>Based on your estimate</Badge>
                            </span>
                          )}
                        </div>
                      </GridItem>
                    </Grid>
                  </BoxItem>
                )}
              </section>
            </Box>
          </GridItem>
          <GridItem columnSize={[12, 12, 12, 12, 6]}>
            <Spacing size={[4, 4, 4, 4, 0]} position="t">
              <Box size="s">
                <section
                  className={accessibleStyles.readerContainer}
                  tabIndex={0}
                >
                  <Heading tag="h3" size="s">
                    Estimated payout
                  </Heading>
                  <Spacing size={1}>
                    <Heading tag="div" size="xxs">
                      With {values.quantity} sales and
                      <TinySelect
                        title="campaigns count"
                        name="campaignsCount"
                        onChange={this.handleCampaignsCountChange.bind(this)}
                        value={campaignsCount}
                        options={[
                          { value: 1, label: '1' },
                          { value: 2, label: '2' },
                          { value: 3, label: '3' },
                          { value: 4, label: '4' },
                          { value: 5, label: '5' },
                          { value: 6, label: '6' },
                          { value: 7, label: '7' },
                          { value: 8, label: '8' },
                          { value: 9, label: '9' },
                          { value: 10, label: '10' },
                          { value: 11, label: '11' }
                        ]}
                      />
                      campaigns, you'll earn a minimum:
                    </Heading>
                  </Spacing>
                  <div className="flex items-baseline">
                    <Heading
                      tag="div"
                      size={['xl', 'xxl', 'xxxl', 'xxl', 'xxl', 'xxxl']}
                    >
                      <Price currency={values.currency} value={amount} />
                    </Heading>
                    <Spacing size="05" position="l">
                      <Paragraph size="xxxs">Per campaign</Paragraph>
                    </Spacing>
                  </div>
                  <Paragraph size="xxxs">
                    or{' '}
                    <Price
                      currency={values.currency}
                      value={amount * campaignsCount}
                    />{' '}
                    per year
                  </Paragraph>
                </section>
                <Spacing size={2}>
                  <section
                    className={accessibleStyles.readerContainer}
                    tabIndex={0}
                  >
                    <Grid gap={1}>
                      <GridItem columnSize={5}>
                        <Heading tag="div" size="xxs">
                          <InlineGrid justify="start">
                            <span className="mr-1">Minimum sales price?</span>
                            <Tooltip
                              className="flex-shrink-0"
                              content={minimumTooltip}
                              id="minimumPrice"
                            />
                          </InlineGrid>
                        </Heading>
                      </GridItem>
                      <GridItem columnSize={7}>
                        <Price
                          currency={values.currency}
                          value={product.minimumPrice}
                        />
                      </GridItem>
                    </Grid>
                  </section>
                </Spacing>
                <Spacing size={2}>
                  <Grid gap={1}>
                    <GridItem columnSize={5}>
                      <Label size={['xxxs', 'xxs']} noPad htmlFor="currency">
                        <InlineGrid justify="start">
                          <span className="mr-1">Sales currency</span>
                          <Tooltip
                            className="flex-shrink-0"
                            content={currencyTooltip}
                            id="currency"
                          />
                        </InlineGrid>
                      </Label>
                    </GridItem>
                    <GridItem columnSize={7}>
                      <SelectCurrencies name="currency" />
                    </GridItem>
                  </Grid>
                </Spacing>
                <Button to={`create/product/${values.product}`} isExternal>
                  Create campaign
                </Button>
              </Box>
              <Spacing position="t" size={4}>
                <section
                  className={accessibleStyles.readerContainer}
                  tabIndex={0}
                >
                  <Box size="xs">
                    <BoxItem size="s">
                      <Grid gap={0}>
                        <GridItem columnSize={4}>
                          <Paragraph size="xxxs">shirts sold</Paragraph>
                        </GridItem>
                        <GridItem columnSize={4}>
                          <Paragraph size="xxxs">profit p/item</Paragraph>
                        </GridItem>
                        <GridItem columnSize={4}>
                          <Paragraph size="xxxs">total profit</Paragraph>
                        </GridItem>
                      </Grid>
                    </BoxItem>
                    {Object.keys(payout.range).map((key) => (
                      <BoxItem size="s" border="t" isBorderLight key={key}>
                        <Grid gap={0}>
                          <GridItem columnSize={4}>
                            <Heading tag="div" size="xxs">
                              {key}
                            </Heading>
                          </GridItem>
                          <GridItem columnSize={4}>
                            <Heading tag="div" size="xxs">
                              <Price
                                currency={values.currency}
                                value={payout.range[key].products[0].amount}
                              />
                            </Heading>
                          </GridItem>
                          <GridItem columnSize={4}>
                            <Heading tag="div" size="xxs">
                              <Price
                                currency={values.currency}
                                value={payout.range[key].amount}
                              />
                            </Heading>
                          </GridItem>
                        </Grid>
                      </BoxItem>
                    ))}
                  </Box>
                </section>
              </Spacing>
            </Spacing>
          </GridItem>
        </Grid>
      </form>
    );
  }
}
