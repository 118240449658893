import React from 'react';
import PropTypes from 'prop-types';

export default class StrictNumber extends React.PureComponent {
  constructor() {
    super();

    this.handleKeyPress = this.onKeyPress.bind(this);
    this.handleUpdate = this.onChange.bind(this);
  }

  onKeyPress(e) {
    const allowedChars = /[0-9]+/;

    if (!allowedChars.test(e.key)) e.preventDefault();
  }

  onChange(e) {
    const { onChange, min, max } = this.props;
    const value = e.target.value;

    // Remove leading 0
    this.inputRef.value = value.replace(/^0+/, '');

    const parsedVal = parseInt(value || 0, 10);

    // Respect min/max value
    this.inputRef.value = Math.max(Number(min), Math.min(Number(max), Number(parsedVal)));

    if (onChange) onChange(e);
  }

  render() {
    const { className, value, id, min, max, ...props } = this.props;

    return (
      <input
        ref={(ref) => (this.inputRef = ref)}
        className={className}
        type="number"
        id={id}
        value={value}
        {...props}
        onKeyPress={this.handleKeyPress}
        onChange={this.handleUpdate}
      />
    );
  }
}

StrictNumber.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func
};
