import React from 'react';
import { connect } from 'react-redux';

import Loading from '../../components/utils/Loading';
import Store from './Store';
import CampaignSingle from './CampaignSingle';
import { getCampaignBySlug } from '../../helpers/selectors/campaigns';

const Campaign = ({ Component }) => {
  return <Component />;
};

const mapStateToProps = ({ location, campaigns, stores }) => {
  const { slug_1, slug_2 } = location.payload;

  if (!getCampaignBySlug(slug_2, campaigns) && !stores.bySlug[slug_2]) {
    return { Component: Loading };
  } else {
    if (slug_1 || getCampaignBySlug(slug_2, campaigns)) {
      return { Component: CampaignSingle };
    } else {
      return { Component: Store };
    }
  }
};

export default connect(mapStateToProps)(Campaign);
