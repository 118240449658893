import React from 'react';

import config from '../../../config';

import Announcement from '../../atoms/Announcement';

import ErrorBoundary from '../../utils/ErrorBoundary';
import ScrollTracker from '../../utils/ScrollTracker';
import Switcher from '../../utils/Switcher';
import UserImpersonator from '../../utils/UserImpersonator';

import { HeaderNew as Header } from '../Header';
import CampaignAdminPanel from '../CampaignAdminPanel';
import Footer from '../Footer';

class App extends React.Component {
  componentDidMount() {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'optimize.euConcierge' });
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <ScrollTracker>
          <Header />
          <Announcement />
          <Switcher />
          <Footer />
          <aside className="fixed bottom-0 left-0 w-full z-header">
            {config.allowImpersonation && <UserImpersonator />}
            <CampaignAdminPanel />
          </aside>
        </ScrollTracker>
      </ErrorBoundary>
    );
  }
}

export default App;
