import boxIcon from '../../img/sprites/box.svg';
import chatIcon from '../../img/sprites/chat.svg';
import cardIcon from '../../img/sprites/card.svg';
import refreshIcon from '../../img/sprites/refresh.svg';
import globeIcon from '../../img/sprites/globe.svg';

const features = [
  {
    id: 0,
    title: 'Shipping & handling',
    content: `Shipping & handling can be tough work, that’s why we do it for you. We print, pack and send all of the items direct to the customer at the end of your campaign. Our team work hard to make sure our customers are happy throughout the whole process.`,
    icon: boxIcon
  },
  {
    id: 1,
    title: 'Marketing tips, tricks and tools',
    content: `We’re here to help refine & define the process behind selling more stuff. Through ‘The Block’ and our <a href="/support" class="underline">support center</a></Link> you have direct access to the Everpress community of sellers, and a ton of insights behind their success.`,
    icon: chatIcon
  },
  {
    id: 2,
    title: 'Payment processing',
    content: `Launching an Everpress campaign requires no upfront costs and when the campaign ends, assuming it’s a success, we pay you your Creator fee.`,
    icon: cardIcon
  },
  {
    id: 3,
    title: 'Evergreen campaigns',
    content: `Itching to get a favourite campaign back on the airwaves? Just hit the re-launch button and your shop will be instantly back-in-action.`,
    icon: refreshIcon
  },
  {
    id: 4,
    title: 'Online stores',
    content: `Sell your items on an e-commerce platform that comfortably sits alongside the world’s biggest names and best designs. Showcase your products and tell your story without compromising your brand.`,
    icon: globeIcon
  }
];

export default features;
