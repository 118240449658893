import React from 'react';
import PropTypes from 'prop-types';

import { Grid, GridItem } from '../Grid';
import Paragraph from '../Paragraph';
import Icon from '../Icon';

import roundCheck from '../../../img/sprites/round-check.svg';

const SuccessFeedback = ({ message = 'Success' }) => {
  return (
    <Grid gap="05">
      <GridItem columnSize={0}>
        <Icon glyph={roundCheck} width={13} height={13} />
      </GridItem>
      <GridItem columnSize={0}>
        <Paragraph size="xxs">{message}</Paragraph>
      </GridItem>
    </Grid>
  );
};

SuccessFeedback.propTypes = {
  message: PropTypes.string.isRequired
};

export default SuccessFeedback;
