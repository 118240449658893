import pick from 'lodash/pick';
import omit from 'lodash/omit';
import config from '../config';

export const log = (message, body) => (dispatch, getState, { baseApi }) => {
  const state = getState();

  if (config.isAppLogging) {
    baseApi.post('/log', {
      message,
      context: JSON.stringify({
        payload: body,
        redux: {
          ...pick(state, [
            'user',
            'campaigns',
            'stores',
            'orders',
            'cart',
            'checkout',
            'tracking'
          ]),
          location: omit(state.location, 'routesMap')
        }
      })
    });
  }

  return { type: 'LOG', payload: { message, body } };
};
