import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import { initialsPropTypes } from './propTypes';

import userIcon from '../../../img/sprites/user.svg';

import generalStyles from './style/general.module.css';
import sizeStyles from './style/size.module.css';

const cx = classNames.bind({
  ...generalStyles,
  ...sizeStyles
});

const Avatar = ({ initials, size, src }) => {
  const avatarBgStyles = {
    backgroundImage: `url(${src})`
  };

  return (
    <div className={cx('avatar', size)} style={src ? avatarBgStyles : null}>
      {!src && (
        <div className={cx('initials')}>
          {initials || <Icon className={cx(size)} glyph={userIcon} />}
        </div>
      )}
    </div>
  );
};

Avatar.propTypes = {
  initials: initialsPropTypes,
  size: PropTypes.oneOf(Object.keys(sizeStyles)),
  src: PropTypes.string
};

Avatar.defaultProps = {
  size: 'm'
};

export default Avatar;
