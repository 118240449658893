import pick from 'lodash/pick';

export const createCampaign = () => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'CREATE_BUILDER_CAMPAIGN',
    payload: baseApi.post(`/v1/campaign`)
  });

export const reset = () => (dispatch) =>
  dispatch({
    type: 'RESET_BUILDER'
  });

export const getCampaign = () => (dispatch, getState, { baseApi }) => {
  const { campaignId } = getState().campaignBuilder;

  return dispatch({
    type: 'GET_BUILDER_CAMPAIGN',
    payload: baseApi.get(`/v1/campaign/${campaignId}`)
  });
};

export const getCatalogueProducts = () => (dispatch, getState, { baseApi }) => {
  const { campaignId } = getState().campaignBuilder;

  return dispatch({
    type: 'GET_BUILDER_CATALOGUE_PRODUCTS',
    payload: campaignId
      ? baseApi.get(`/v1/catalogue/products/${campaignId}`)
      : baseApi.get('/v1/catalogue/products')
  });
};

export const getCampaignProducts = () => (dispatch, getState, { baseApi }) => {
  const { campaignId } = getState().campaignBuilder;

  if (!campaignId) return;

  return dispatch({
    type: 'GET_BUILDER_CAMPAIGN_PRODUCTS',
    payload: baseApi.get(`/v1/campaign/${campaignId}/products`)
  });
};

export const uploadArtwork = (formData) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { campaignId } = getState().campaignBuilder;

  return dispatch({
    type: 'UPLOAD_BUILDER_ARTWORK',
    payload: baseApi.post(`/v1/campaign/${campaignId}/artwork/upload`, formData)
  });
};

export const updateArtwork = (payload) => (dispatch, getState, { baseApi }) => {
  const { campaignId } = getState().campaignBuilder;
  const { campaignArtworkId, colours: userColours, ...data } = payload;

  return dispatch({
    type: 'UPDATE_BUILDER_ARTWORK',
    payload: baseApi.put(
      `/v1/campaign/${campaignId}/artwork/${campaignArtworkId}`,
      { ...data, userColours }
    )
  });
};

export const createArtworkPositions = (payload) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { campaignId, artwork } = getState().campaignBuilder;

  return dispatch({
    type: 'CREATE_BUILDER_ARTWORK_POSITIONS',
    payload: baseApi.post(`/v1/campaign/${campaignId}/artwork-positions`, {
      ...payload,
      artwork
    })
  });
};

export const getArtworkPositions = (payload) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { campaignId } = getState().campaignBuilder;

  return dispatch({
    type: 'GET_BUILDER_ARTWORK_POSITIONS',
    payload: baseApi.get(
      `/v1/campaign/${campaignId}/artwork-positions`,
      payload
    )
  });
};

export const updateArtworkPositions = (payload) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { campaignId } = getState().campaignBuilder;
  const { campaignArtworkPositionId } = payload;

  return dispatch({
    type: 'UPDATE_BUILDER_ARTWORK_POSITIONS',
    payload: baseApi.put(
      `/v1/campaign/${campaignId}/artwork-positions/${campaignArtworkPositionId}`,
      payload
    )
  });
};

export const deleteArtworkPosition = (payload) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { campaignId } = getState().campaignBuilder;
  const { campaignArtworkPositionId } = payload;

  return dispatch({
    type: 'DELETE_BUILDER_ARTWORK_POSITION',
    payload: baseApi.delete(
      `/v1/campaign/${campaignId}/artwork-positions/${campaignArtworkPositionId}`
    ),
    meta: { campaignArtworkPositionId }
  });
};

export const addCampaignProduct = (productData) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { campaignId } = getState().campaignBuilder;

  // Hack: API breaks when additional data is included
  const payload = {
    featured: true,
    enabled: true,
    colours: productData?.colours,
    colourFeatured: productData?.colourFeatured,
    product: {
      productId: productData?.product?.productId
    }
  };

  return dispatch({
    type: 'ADD_BUILDER_CAMPAIGN_PRODUCT',
    payload: baseApi.post(`/v1/campaign/${campaignId}/products`, payload),
    meta: { productData, campaignId }
  });
};

export const deleteCampaignProduct = (campaignProductId) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { campaignId } = getState().campaignBuilder;

  return dispatch({
    type: 'DELETE_BUILDER_CAMPAIGN_PRODUCT',
    payload: baseApi.delete(
      `/v1/campaign/${campaignId}/products/${campaignProductId}`
    ),
    meta: { campaignProductId }
  });
};

export const updateCampaignProduct = (productData) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { campaignId } = getState().campaignBuilder;
  const { campaignProductId } = productData;

  const defaults = {
    featured: true,
    enabled: true
  };

  // Hack: API breaks when additional data is included
  const payload = {
    featured: productData?.featured ?? defaults.featured,
    enabled: productData?.enabled ?? defaults.enabled,
    colours: productData?.colours,
    colourFeatured: productData?.colourFeatured,
    product: {
      productId: productData?.product?.productId
    },
    price: productData?.price
  };

  return dispatch({
    type: 'UPDATE_BUILDER_CAMPAIGN_PRODUCT',
    payload: baseApi.put(
      `/v1/campaign/${campaignId}/products/${campaignProductId}`,
      payload
    ),
    meta: { productData }
  });
};

export const updateCampaignId = (campaignId) => (dispatch) => {
  return dispatch({
    type: 'UPDATE_BUILDER_CAMPAIGN_ID',
    payload: campaignId
  });
};

export const updateProfitEstimate = (quantity) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { campaignId, goal } = getState().campaignBuilder;

  const payload = {
    quantity: quantity || goal
  };

  return dispatch({
    type: 'UPDATE_BUILDER_PROFIT_ESTIMATE',
    payload: baseApi.post(`/v1/campaign/${campaignId}/profit-estimate`, payload)
  });
};

export const updateCampaign = (values) => (dispatch, getState, { baseApi }) => {
  const { campaignId, ...campaign } = getState().campaignBuilder;

  const currState = pick(campaign, [
    'length',
    'goal',
    'promoOptIn',
    'currencyIso'
  ]);

  const payload = {
    campaignId,
    ...currState,
    ...values
  };

  return dispatch({
    type: 'UPDATE_BUILDER_CAMPAIGN',
    payload: baseApi.patch(`/v1/campaign/${campaignId}/builder`, payload)
  });
};

export const incrementStep2TimesViewed = () => (dispatch) => {
  return dispatch({
    type: 'INCREMENT_STEP2_TIMES_VIEWED'
  });
};

export const updateDarkColoursToastDismissed = (dismissed) => (dispatch) => {
  return dispatch({
    type: 'UPDATE_DARK_COLOURS_TOAST_DISMISSED',
    payload: dismissed
  });
};

export const uploadCampaignDescriptionImage = (file, formData) => (
  dispatch,
  _,
  { fileUploaderApi }
) =>
  dispatch({
    type: 'UPLOAD_CAMPAIGN_DESCRIPTION_IMAGE',
    payload: fileUploaderApi.post('/content/upload', formData),
    meta: file
  });
