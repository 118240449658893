import produce from 'immer';
import set from 'lodash/set';

const initialState = {
  shop: {},
  homepage: {},
  showcase: {},
  showcase_v2: {
    bySlug: {}
  },
  case_studies_landing: {},
  landing: {
    bySlug: {}
  },
  submit_your_design: {},
  sustainability: {},
  bySlug: {}
};

export default (state = initialState, action) =>
  produce(state, (newState) => {
    const { type, payload, meta } = action;

    switch (type) {
      case 'FETCH_PAGE_FULFILLED':
        return { ...state, [meta]: payload };
      case 'FETCH_COLLECTION_PAGE_FULFILLED':
        if (meta.collection === 'page') {
          return set(newState, ['bySlug', meta.page], payload);
        } else {
          return set(newState, [meta.collection, 'bySlug', meta.page], payload);
        }
      default:
        return newState;
    }
  });
