import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import last from 'lodash/last';

import { sortColours } from '../../../../helpers/campaignBuilder';

import Spacing from '../../../../components/atoms/Spacing';
import Heading from '../../../../components/atoms/Heading';
import Image from '../../../../components/atoms/Image';
import { PillNew as Pill } from '../../../../components/atoms/Pill';
import Paragraph from '../../../../components/atoms/Paragraph';
import { Field } from '../../../../components/atoms/Form';
import { TooltipNew as Tooltip } from '../../../../components/atoms/Tooltip';

const getSizesLabel = (sizes = []) => {
  if (sizes.length < 1) {
    return '-'
  }

  if (sizes.length > 1) {
    return `${sizes[0].reference} - ${last(sizes).reference}`;
  }

  if (sizes[0].reference === 'OS') {
    return `${sizes[0].name}`;
  }

  return `${sizes[0].reference}`;
};

export default function GarmentThumbnail({
  product,
  featuredColour,
  defaultColourCode,
  onClick,
  disabled,
  disabledColour,
  disabledColourCode
}) {
  const [activeBgColour, setActiveBgColour] = useState(defaultColourCode);
  const [tempBgColour, setTempBgColour] = useState(null);

  const { sku, brandName, name, colours, areas, sizes } = product;

  const activeColours = colours.filter((colour) => colour.active);
  const sortedActiveColours = sortColours(activeColours);
  const sizesLabel = getSizesLabel(sizes);
  const imageUrl = areas[0]?.whiteUrl || areas[0]?.greyUrl;

  useEffect(() => {
    setActiveBgColour(featuredColour?.code || defaultColourCode);
  }, [featuredColour, defaultColourCode]);

  return (
    <>
      <div role="button" onClick={() => !disabled && onClick?.()}>
        {imageUrl ? (
          <Image
            style={{
              width: '200px',
              height: '200px',
              backgroundColor: `#${tempBgColour || activeBgColour}`,
              transition: 'background-color .15s linear'
            }}
            src={imageUrl}
            alt={name}
            width="200"
            height="200"
            placeholderColor={defaultColourCode}
          />
        ) : (
          <div style={{ width: '200px', height: '200px' }} />
        )}
        <Spacing size={1} position="t">
          <Spacing size="05" position="t">
            <Heading tag="p" size="xxs">
              {brandName}
            </Heading>
          </Spacing>
          <Spacing size="05" position="t">
            <Paragraph size="xxs">{name.replace('-', '\u2011')}</Paragraph>
          </Spacing>
          <Spacing size="05" position="t">
            <Pill kind="info" size="xxxs">
              <span className="leading-relaxed">{sizesLabel}</span>
            </Pill>
          </Spacing>
        </Spacing>
      </div>
      <Spacing size={1} position="t">
        <div
          className="inline-flex flex-wrap items-center gap-2"
          style={{ height: 'fit-content' }}
        >
          {sortedActiveColours.map((colour) => (
            <Tooltip
              id="tooltip-colour"
              hasListContent
              content={colour.name}
              placement="bottom"
              offset={5}
              align="start"
              alignOffset={-10}
              key={colour.colourId}
            >
              <div
                style={{ width: '20px' }}
                onMouseEnter={() => setTempBgColour(colour.code)}
                onMouseLeave={() => setTempBgColour(null)}
                onClick={() => setTempBgColour(colour.code)}
              >
                <Field
                  type="colourCircleCheckbox"
                  name={`${sku}.colours.${colour.code}`}
                  hex={`#${colour.code}`}
                  size="m"
                  disabled={
                    // disabled || disabledColour?.colourId === colour.colourId
                    disabled || disabledColourCode === colour.code
                  }
                />
              </div>
            </Tooltip>
          ))}
        </div>
      </Spacing>
    </>
  );
}

GarmentThumbnail.propTypes = {
  product: PropTypes.shape({
    productId: PropTypes.number.isRequired,
    brandName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    areas: PropTypes.array.isRequired,
    sizes: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, code: PropTypes.string })
    ).isRequired
  }).isRequired,
  featuredColour: PropTypes.object,
  defaultColourCode: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  disabledColour: PropTypes.object,
  disabledColourCode: PropTypes.string
};
