import React from 'react';
import PropTypes from 'prop-types';

import { InlineGrid } from '../../../atoms/Grid';
import Heading from '../../../atoms/Heading';
import Paragraph from '../../../atoms/Paragraph';
import Spacing from '../../../atoms/Spacing';

import generalStyles from './style/general.module.css';

export default function CheckoutBox({
  title,
  note = null,
  children
}) {
  return (
    <div className={generalStyles.checkoutBox}>
      <InlineGrid>
        <Spacing size={[1, 2]} position="r">
          <Heading tag="div" size="xxs">
            {title}
          </Heading>
          {note && (
            <Spacing size={1} position="t">
              <Paragraph size={['xxxs', 'xxs']}>{note}</Paragraph>
            </Spacing>
          )}
        </Spacing>
        {children}
      </InlineGrid>
    </div>
  );
}

CheckoutBox.propTypes = {
  title: PropTypes.string.isRequired,
  note: PropTypes.string,
  children: PropTypes.node
};
