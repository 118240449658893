import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import richTextSerializer from '../../../../helpers/prismic/richTextSerializer';

import { ButtonNew as Button } from '../../../atoms/Button';
import PageWrap from '../../../atoms/PageWrap';
import Heading from '../../../atoms/Heading';
import MaxWidth from '../../../atoms/MaxWidth';
import Paragraph from '../../../atoms/Paragraph';
import Spacing from '../../../atoms/Spacing';
import { CarouselFade, CarouselItem } from '../../../atoms/Carousel';
import Link from '../../../atoms/Link';

import WrapIf from '../../../utils/WrapIf';

import generalStyles from './style/general.module.css';

const Hero = ({ slides, heading }) => {
  const isBannerActive = useSelector(
    (state) => state.headerNew.banner.isBannerActive
  );

  const headingText = richTextSerializer(heading);

  return (
    <div
      className={classNames(generalStyles.singleFeature, {
        [generalStyles.bannerActive]: isBannerActive,
        [generalStyles.bannerInactive]: !isBannerActive
      })}
    >
      {!!heading ? (
        <div className="z-10 pointer-events-none">
          <PageWrap>
            <MaxWidth value={614}>
              <Spacing size={3} type="padding">
                <Heading tag="h1" size={['l', 'l', 'xl', 'xxl']} color="white">
                  {headingText}
                </Heading>
              </Spacing>
            </MaxWidth>
          </PageWrap>
        </div>
      ) : null}

      <CarouselFade duration={6000}>
        {slides.map((item, index) => {
          const title = richTextSerializer(item.title);
          const subtitle = richTextSerializer(item.description);
          const slug = item.slug?.[0].text;
          const cta = item.cta;

          return (
            <CarouselItem
              key={`carouselItem-${title}`}
              className="flex flex-col justify-end"
              index={index}
            >
              <WrapIf
                condition={!!slug}
                wrapper={(children) => (
                  <Link to={slug} isUnderlined={false} noHover>
                    {children}
                  </Link>
                )}
              >
                <div
                  className={generalStyles.bgImage}
                  style={{
                    backgroundImage: `url(${item.image && item.image.url})`,
                    backgroundPosition: 'center'
                  }}
                />
              </WrapIf>

              <div className="z-10 pointer-events-none">
                <PageWrap>
                  <Spacing size={[2, 2, 2, 2, 3]} position="y" type="padding">
                    {!!title || !!subtitle ? (
                      <>
                        <Spacing size={15} position="b">
                          <Heading
                            size={['m', 'm', 'l']}
                            tag="h3"
                            color="white"
                            className="z-20"
                          >
                            {title}
                          </Heading>
                        </Spacing>
                        <Spacing size={15} position="b">
                          <Paragraph size="xxs" color="white" className="z-20">
                            {subtitle}
                          </Paragraph>
                        </Spacing>
                      </>
                    ) : null}
                    {cta ? (
                      <Button
                        kind="solid"
                        color="white"
                        className={generalStyles.ctaButton}
                      >
                        {cta}
                      </Button>
                    ) : null}
                  </Spacing>
                </PageWrap>
              </div>
            </CarouselItem>
          );
        })}
      </CarouselFade>
    </div>
  );
};

export default Hero;
