import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import {
  DefaultField as Field,
  Form,
  Error,
  Success
} from '../../../components/atoms/Form';
import Heading from '../../../components/atoms/Heading';
import Paragraph from '../../../components/atoms/Paragraph';
import { ButtonNew as Button } from '../../../components/atoms/Button';
import Spacing from '../../../components/atoms/Spacing';
import SuccessFeedback from '../../../components/atoms/SuccessFeedback';

const SignUpForm = ({ initialValues, isDisabled, onSignUp }) => {
  const isMobile = window.innerWidth < 900;

  if (isDisabled) {
    return <SuccessFeedback message="Account created" />;
  }
  return (
    <Fragment>
      <Spacing size={2}>
        {!isMobile && <Heading size="xs">Sign up to track your order</Heading>}
      </Spacing>
      <Spacing size={1}>
        <Paragraph size="xxs">
          Create an account so that you can track and manage your order and
          checkout faster in the future.
        </Paragraph>
      </Spacing>
      <Spacing size={2}>
        <Form
          initialValues={{
            email: initialValues.email,
            password: ''
          }}
          validationSchema={yup.object().shape({
            password: yup.string().required()
          })}
          onSubmit={onSignUp}
          render={({ isSubmitting, handleSubmit, status }) => {
            let statusMessage = null;
            const { error, success } = { ...status };
            if (error) {
              statusMessage = (
                <Error>
                  <span className="text-xxxs">{status.error}</span>
                </Error>
              );
            }
            if (success) {
              statusMessage = (
                <Success>
                  <span className="text-xxxs">{status.success}</span>
                </Success>
              );
            }
            return (
              <form onSubmit={handleSubmit} data-test-id="sign-in-up-form">
                <Spacing size={15}>
                  <Spacing size={1}>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email address"
                      disabled
                    />
                  </Spacing>
                  <Spacing size={1}>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Create a password"
                    />
                  </Spacing>
                </Spacing>
                <Spacing size={15}>
                  <Button
                    type="submit"
                    state={isSubmitting ? 'loading' : null}
                    fullWidth
                    data-test-id="sign-in-up-submit"
                  >
                    Sign Up
                  </Button>
                </Spacing>
                <Spacing size={15}>{statusMessage}</Spacing>
              </form>
            );
          }}
        />
      </Spacing>
    </Fragment>
  );
};

export default SignUpForm;

SignUpForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onSignUp: PropTypes.func.isRequired
};

SignUpForm.defaultProps = {
  initialValues: {
    email: '',
    password: ''
  }
};
