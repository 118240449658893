import React from 'react';
import PropTypes from 'prop-types';

import { Grid, GridItem } from '../../../atoms/Grid';
import Heading from '../../../atoms/Heading';
import Spacing from '../../../atoms/Spacing';
import Paragraph from '../../../atoms/Paragraph';

export default function QuoteThumbnail({
  quote,
  author,
  title,
  image,
  row,
  size,
  isLight,
  isNaked,
  ratio
}) {
  const sizeMap = {
    s: {
      left: [12, 6, 6, 9],
      right: [12, 6, 6, 3]
    },
    m: {
      left: [12, 6],
      right: [12, 6]
    }
  };
  const ratioStyle = {
    backgroundImage: `url(${image})`,
    paddingBottom: `${ratio}%`,
    height: 0
  };
  const ratioProps = {
    className: 'bg-cover bg-center',
    style: ratioStyle
  };

  return (
    <Grid gap={[2, 3]} align="start" row={row}>
      <GridItem columnSize={sizeMap[size].left}>
        {!isNaked && (
          <div style={{ maxHeight: '60px' }}>
            <Heading color={isLight ? 'white' : 'black'} tag="div" size="xxl">
              “
            </Heading>
          </div>
        )}
        <Heading
          color={isLight ? 'white' : 'black'}
          tag="h4"
          size={['m', 'm', 'l']}
        >
          <span dangerouslySetInnerHTML={{ __html: quote }} />
        </Heading>
        <Spacing size={4}>
          <Heading color={isLight ? 'white' : 'black'} tag="h3" size="xs">
            {author}
          </Heading>
          <Paragraph color={isLight ? 'white' : 'black'} tag="div" size="xxxs">
            {title}
          </Paragraph>
        </Spacing>
      </GridItem>
      <GridItem columnSize={sizeMap[size].right}>
        {ratio > 0 ? <div {...ratioProps} /> : <img src={image} alt={author} />}
      </GridItem>
    </Grid>
  );
}

QuoteThumbnail.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['s', 'm']),
  isLight: PropTypes.bool,
  isNaked: PropTypes.bool,
  ratio: PropTypes.number
};

QuoteThumbnail.defaultProps = {
  size: 'm',
  isLight: false,
  isNaked: false,
  ratio: 0
};
