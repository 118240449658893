import React from 'react';
import { connect } from 'react-redux';
import universal from 'react-universal-component';
import { pathToAction, addRoutes, NOT_FOUND } from 'redux-first-router';

import Loading from './Loading';
import NotFound from '../../pages/NotFound';

const UniversalComponent = universal(
  ({ page }) => import(`../../pages/${page}`),
  {
    minDelay: 500,
    loading: Loading,
    error: () => <NotFound isError />,
    onError: error => console.error(error),
    onLoad: (module, info, props, context) => {
      if (module.routesMap) {
        const { dispatch, getState } = context.store;
        dispatch(addRoutes(module.routesMap));
        dispatch({
          ...pathToAction(getState().location.pathname, module.routesMap),
          meta: { location: { kind: 'load' } }
        });
      }
    }
  }
);

const Switcher = ({ type, routesMap }) => {
  if (type === NOT_FOUND) {
    return <NotFound />;
  } else {
    if (typeof routesMap[type]?.component === 'string') {
      // If component prop is a string, it means it's a dynamic import!
      return (
        <UniversalComponent
          page={routesMap[type].component}
          isLoading={false}
        />
      );
    } else if (typeof routesMap[type]?.component !== 'undefined') {
      const Component = routesMap[type].component;

      // Otherwise, return whatever is defined
      return <Component />;
    } else {
      return null;
    }
  }
};

const mapStateToProps = ({ location }, props) => {
  const { type, routesMap } = props;

  return {
    type:
      type ||
      (routesMap
        ? pathToAction(location.pathname, routesMap).type
        : location.type),
    routesMap: routesMap || location.routesMap
  };
};

export default connect(mapStateToProps)(Switcher);
