import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import TrustedByCommunity from '../Home/TrustedByCommunity';

import Page from '../../components/utils/Page';

import { ButtonNew } from '../../components/atoms/Button';
import { CSSGrid } from '../../components/atoms/Grid';
import Heading from '../../components/atoms/Heading';
import PageWrap from '../../components/atoms/PageWrap';
import Paragraph from '../../components/atoms/Paragraph';
import Spacing from '../../components/atoms/Spacing';

import openData from '../../img/pages/careers/open-data.jpg';
import teamTshirt from '../../img/pages/careers/team-tshirts.jpeg';
import teamPolaroid from '../../img/pages/careers/team-polaroid.jpeg';

import './style/careers.css';

const Careers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://everpress.bamboohr.com/js/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const onJobClick = async (e) => {
    if (e.target.href && e.target.innerHTML) {
      await dispatch({ type: 'CAREERS_REDIRECT', payload: e.target.innerHTML });
    }
  };

  const bambooRef = useRef();

  return (
    <Page>
      <div className="bg-black">
        <PageWrap>
          <section>
            <CSSGrid columns={['none', 'none', 'none', 'repeat(2, 1fr)']}>
              <div className="flex flex-col h-100">
                <div className="border-b-1 border-grey-lighter flex-1 flex p-3">
                  <Heading
                    size={['m', 'l', 'xl', 'xl', 'xxl']}
                    center
                    tag="h1"
                    className="m-auto py-7 mdlg:py-0"
                    color="white"
                  >
                    Careers
                  </Heading>
                </div>
                <ButtonNew
                  color="white"
                  kind="ghost"
                  onClick={() => {
                    window.scrollTo({
                      top: bambooRef.current.offsetTop,
                      behavior: 'smooth'
                    });
                    dispatch({ type: 'CAREERS_VIEW_JOBS' });
                  }}
                >
                  Go to open positions
                </ButtonNew>
                <Spacing size={[0, 0, 0, 3]} position="x">
                  <Spacing size={[4, 6, 8]}>
                    <Heading size={['xs', 'xs', 's']} tag="h2" color="white">
                      WHO WE ARE
                    </Heading>
                    <Paragraph color="white" className="mt-2">
                      A global fashion marketplace that connects consumers to
                      unique & sustainable products from independent designers.
                      Our mission is to champion creativity, supporting
                      grassroots creators while reducing the waste in fashion.
                    </Paragraph>
                    <Paragraph className="mt-2" color="white">
                      We are an ambitious, tight-knit and happy bunch who get
                      together and get stuff done out of our lovely Dalston
                      office (right behind the best coffee shop in London). A
                      typical week involves fruit bowls, socials (virtual and in
                      person), lunch n learns and yoga.
                      <span role="img" aria-label="emoji">
                        ✌️🌱
                      </span>
                    </Paragraph>
                  </Spacing>
                </Spacing>
              </div>
              <img
                className="mdlg:hidden"
                src={teamPolaroid}
                alt="People &amp; Culture at Everpress"
              />
              <img
                className="hidden mdlg:block"
                src={openData}
                alt="People &amp; Culture at Everpress"
              />
            </CSSGrid>
          </section>
        </PageWrap>
      </div>

      <PageWrap>
        <div className="flex justify-center mdlg:py-6">
          <div ref={bambooRef} className="py-6 mdlg:w-50">
            <Heading
              size={['m', 'l', 'xl', 'xl', 'xxl']}
              tag="h2"
              center
              className="block"
            >
              Open Positions
            </Heading>
            <Spacing>
              <div
                id="BambooHR"
                className="bambooContainer"
                data-domain="everpress.bamboohr.com"
                data-version="1.0.0"
                data-departmentId=""
                onClick={(e) => onJobClick(e)}
              />
            </Spacing>
          </div>
        </div>
      </PageWrap>

      <div className="bg-black">
        <PageWrap>
          <section>
            <CSSGrid columns={['none', 'none', 'none', 'repeat(2, 1fr)']}>
              <img src={teamTshirt} alt="People &amp; Culture at Everpress" />
              <div className="flex flex-col h-100">
                <div className="m-auto py-7 mdlg:py-0">
                  <Spacing size={[0, 0, 0, 3]} position="x">
                    <Spacing size={[4, 6, 8]}>
                      <Heading size={['xs', 'xs', 's']} tag="h2" color="white">
                        WHY WE'RE HERE
                      </Heading>
                      <Paragraph className="mt-2" color="white">
                        If retail doesn’t change, we will have even more
                        mountains of waste, fewer choices, less originality, and
                        only lower quality. It’s not about us. It’s about
                        shaping the future in the best way possible.
                      </Paragraph>
                      <Paragraph className="mt-2" color="white">
                        If you're up for joining us on our mission to support
                        grassroots creators and reduce waste in fashion, then
                        we'd love to hear from you.
                        <span role="img" aria-label="emoji">
                          ☀️☀️
                        </span>
                      </Paragraph>
                    </Spacing>
                  </Spacing>
                </div>
              </div>
            </CSSGrid>
          </section>
        </PageWrap>
      </div>
      <Spacing type="padding">
        <PageWrap>
          <TrustedByCommunity />
        </PageWrap>
      </Spacing>
    </Page>
  );
};

export default Careers;
