import isEmpty from 'lodash/isEmpty';

export const hasRole = (role, state) => {
  return state.user.roles.includes(role);
};

export const hasExtendedRole = (extendedRole, state) => {
  return state.user.extendedRoles.includes(extendedRole);
};

export const getUserRutterConnection = (user) => {
  const { rutter: rutterConnections } = user.connections;

  return isEmpty(rutterConnections) ? null : rutterConnections[0];
};

export const getUserPaymentMethod = (user) => {
  const { paymentMethods } = user.stripe;

  return isEmpty(paymentMethods) ? null : paymentMethods[0];
};

export const getUserPrimaryConnection = (user) => {
  const { rutter, bandcamp } = user.connections;

  return !isEmpty(rutter)
    ? { type: 'rutter', ...rutter[0] }
    : !isEmpty(bandcamp)
    ? { type: 'bandcamp', ...bandcamp[0] }
    : null;
};

export const getUserConnections = (user) => {
  const { rutter, bandcamp } = user.connections;

  const rutterConnections = rutter.map((item) => ({ type: 'rutter', ...item }));
  const bandcampConnections = bandcamp.map((item) => ({
    type: 'bandcamp',
    ...item
  }));

  return [...rutterConnections, ...bandcampConnections];
};

export const getUserConnectionById = (user, connectionId) => {
  const userConnections = getUserConnections(user) || [];

  const userConnection = userConnections.find(
    (connection) => connection.connectionId === connectionId
  );

  return userConnection;
};

export const getUserDefaultBandcampConnection = (user) => {
  const userConnections = getUserConnections(user) || [];

  const userDefaultBandcampConnection = userConnections.find(
    (cxn) => cxn.type === 'bandcamp'
  );

  return userDefaultBandcampConnection;
};
