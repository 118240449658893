import React, { Component } from 'react';
import { connect } from 'react-redux';

import PageWrap from '../PageWrap';
import Heading from '../Heading';

import generalStyles from './style/general.module.css';

class Announcement extends Component {
  render() {
    const { theme, overrideTheme = false, copy } = this.props;

    // The visibility and copy of this component is managed in src/reducers/headers.js
    if (!theme.withAnnouncement && !overrideTheme) {
      return false;
    }

    const announcement = copy || theme.announcementMessage;

    return (
      <div className={generalStyles.container}>
        <PageWrap>
          <Heading
            className={generalStyles.copy}
            center
            size="xxs"
            color="white"
          >
            <span dangerouslySetInnerHTML={{ __html: announcement }} />
          </Heading>
        </PageWrap>
      </div>
    );
  }
}

const mapStateToProps = ({ location, header }) => ({
  location,
  ...header
});

export default connect(mapStateToProps)(Announcement);
