import React from 'react';
import * as yup from 'yup';

import { convertPxToMm, convertMmToIn } from '../../../../../helpers/artwork';

import { ButtonNew as Button } from '../../../../../components/atoms/Button';
import {
  Form,
  DefaultField as Field
} from '../../../../../components/atoms/Form';
import Heading from '../../../../../components/atoms/Heading';
import { ModalNew as Modal } from '../../../../../components/molecules/Modal';
import Paragraph from '../../../../../components/atoms/Paragraph';
import Spacing from '../../../../../components/atoms/Spacing';

import generalStyles from './style/general.module.css';

const ColourDetectModal = ({ artwork, isOpen, onSubmit }) => {
  const { colours = 8, width, height } = artwork;

  const handleSubmit = (data) => {
    // This is a hack
    if (data.colours === -1) {
      onSubmit?.({ colours: 0 });
      return;
    }

    onSubmit?.(data);
  };

  const mm = {
    width: convertPxToMm(width),
    height: convertPxToMm(height)
  };

  const inch = {
    width: convertMmToIn(mm.width).toFixed(1),
    height: convertMmToIn(mm.height).toFixed(1)
  };

  const artworkWidth = `${mm.width}mm (${inch.width} inch)`;
  const artworkHeight = `${mm.height}mm (${inch.height} inch)`;

  return (
    <Modal isOpen={isOpen}>
      <Heading size="xs">Artwork colours</Heading>
      <Spacing size={2}>
        <div className={generalStyles.artworkContainer}>
          <img
            className={generalStyles.artwork}
            src={artwork?.url}
            alt="Artwork"
          />
        </div>
      </Spacing>
      <Spacing size={2} position="t">
        <Form
          enableReinitialize
          initialValues={{
            colours
          }}
          validationSchema={yup.object().shape({
            colours: yup.number().required()
          })}
          onSubmit={handleSubmit}
          render={({ isSubmitting, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Spacing size={25} position="t">
                  <Paragraph size="xxs">
                    We detected {colours} colours in this artwork. Please
                    confirm this is correct, as it is used to calculate your
                    profits.
                  </Paragraph>
                </Spacing>
                <Spacing size={1}>
                  <Field
                    type="select"
                    name="colours"
                    options={[...Array(9)].map((_, i) => {
                      if (!i) return { value: -1, label: 'Photo' };

                      return {
                        value: i,
                        label: `${i} colours`
                      };
                    })}
                    placeholder="Artwork colours"
                    scrollToError={false}
                    maxMenuHeight={233}
                  />
                </Spacing>
                <Spacing size={2} position="t">
                  <Paragraph tag="span" size="xxs">
                    Max print size of this artwork:
                    <br />
                    {artworkWidth} by {artworkHeight}.
                  </Paragraph>
                </Spacing>
                <Spacing size="05" position="t">
                  <Paragraph tag="span" size="xxs">
                    Upload 300 DPI artwork now if you have it, otherwise we’ll
                    reach out to help you later.
                  </Paragraph>
                </Spacing>
                <Spacing size={8} position="t">
                  <div className="flex justify-center">
                    <Button
                      fullWidth
                      type="submit"
                      state={isSubmitting ? 'loading' : undefined}
                    >
                      Submit
                    </Button>
                  </div>
                </Spacing>
              </form>
            );
          }}
        />
      </Spacing>
    </Modal>
  );
};

export default ColourDetectModal;
