import React, { useState } from 'react';

import Spacing from '../../../components/atoms/Spacing';
import { ButtonNew as Button } from '../../../components/atoms/Button';
import { InlineGrid } from '../../../components/atoms/Grid';

import ShareProfileModal from '../ShareProfileModal';

const OwnerActions = ({ slug }) => {
  const [shareProfile, setShareProfile] = useState(false);

  return (
    <>
      <Spacing size={4} position="b">
        <InlineGrid>
          <Button kind="outline" size="small" to="/dashboard/settings/profile">
            Edit Profile
          </Button>
          <Spacing size={15} position="l">
            <Button
              kind="outline"
              size="small"
              onClick={() => {
                setShareProfile(true);
              }}
            >
              Share
            </Button>
          </Spacing>
        </InlineGrid>
      </Spacing>
      <ShareProfileModal
        slug={slug}
        showModal={shareProfile}
        setShowModal={setShareProfile}
      />
    </>
  );
};

export default OwnerActions;
