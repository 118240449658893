import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';

import * as trackingActions from '../../actions/tracking';

import Page from '../../components/utils/Page';
import PageWrap from '../../components/atoms/PageWrap';
import {
  Error,
  Field,
  Form,
  handleFormError,
  validateFormWithSchema
} from '../../components/atoms/Form';
import { Grid, GridItem } from '../../components/atoms/Grid';
import { ButtonNew as Button } from '../../components/atoms/Button';
import Heading from '../../components/atoms/Heading';
import MaxWidth from '../../components/atoms/MaxWidth';
import Paragraph from '../../components/atoms/Paragraph';
import Spacing from '../../components/atoms/Spacing';

import TermsFormField from '../../components/molecules/TermsFormField';

import generalStyles from './style/general.module.css';

import { readCookie } from '../../services/cookies';

class LeadCapture extends PureComponent {
  state = {
    isMobile: true // mobile first
  };

  async handleSubmit(formData, actions) {
    const data = {
      mobile: this.state.isMobile,
      source: this.props.prevUrlPathname || 'organic',
      hubspotCookie: readCookie('hubspotutk'),
      ...formData
    };

    try {
      await this.props.captureLead(data);
    } catch (error) {
      handleFormError(error, actions);
      return actions.setSubmitting(false);
    }

    this.props.goToSuccess();
  }

  componentWillUnmount() {
    clearTimeout(this.messageTimeout);
  }

  componentDidMount() {
    // Viewport size detection. This could be a global context in a mobileTracker.js
    // but it's used only here so leave it contextual for now.
    this.setState({
      isMobile: window.matchMedia(`(max-width: 768px)`).matches // 768px is defined as "campaign builder not usable"
    });

    // Prefetch "thank you" page
    import('./Thanks').then();
  }

  handleValidate(values) {
    const schema = yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup
        .string()
        .required()
        .email(),
      company: yup.string(),
      instagram: yup.string(),
      optIn: yup.bool()
    });

    return validateFormWithSchema(schema, values);
  }

  render() {
    return (
      <Page title="Get in touch with our team">
        <PageWrap>
          <MaxWidth value={415} center>
            <Spacing position="t" type="padding">
              <Heading size={['s', 'm', 'm']} tag="h2">
                Build your everpress store.
                <br />
                It’s fast, easy and free.
              </Heading>
            </Spacing>
            <Spacing size={2} position="t" type="padding">
              <Form
                initialValues={{
                  email: '',
                  firstname: '',
                  lastname: '',
                  company: '',
                  instagram: '',
                  optIn: false
                }}
                onSubmit={this.handleSubmit.bind(this)}
                validate={this.handleValidate.bind(this)}
                render={({ isSubmitting, handleSubmit, status }) => (
                  <form onSubmit={handleSubmit}>
                    <Spacing size={[1, 2, 2]} position="b">
                      <Field name="email" placeholder="EMAIL" />
                    </Spacing>
                    <Spacing size={[1, 2, 2]} position="b">
                      <Field name="firstName" placeholder="FIRST NAME" />
                    </Spacing>
                    <Spacing size={[1, 2, 2]} position="b">
                      <Field name="lastName" placeholder="LAST NAME" />
                    </Spacing>
                    <Spacing size={[1, 2, 2]} position="b">
                      <Field
                        name="organisation"
                        placeholder="ORGANISATION (IF APPLICABLE)"
                      />
                    </Spacing>
                    <Spacing size={[1, 2, 2]} position="b">
                      <Field
                        name="socialHandle"
                        placeholder="INSTAGRAM USERNAME (IF APPLICABLE)"
                      />
                    </Spacing>
                    <Spacing size={1} position="b">
                      <TermsFormField name="acceptTerms" size="xxs" />
                    </Spacing>
                    <Spacing size={[1, 2, 2]} position="b">
                      <Field type="checkbox" name="optIn">
                        <Paragraph size="xxs">
                          Stay updated: new drops, recommendations, long reads
                          and more.
                        </Paragraph>
                      </Field>
                    </Spacing>
                    <Grid
                      gap={1}
                      row={['flex-col-reverse', 'flex-row', 'flex-row']}
                    >
                      <GridItem columnSize={[12, 7, 7]} />
                      <GridItem columnSize={[12, 5, 5]}>
                        <Button
                          type="submit"
                          fullWidth
                          isLoading={isSubmitting}
                          state={isSubmitting && 'loading'}
                          className={generalStyles.button}
                        >
                          Next
                        </Button>
                      </GridItem>
                    </Grid>
                    <Spacing size={2}>
                      {status &&
                        ((status.error && (
                          <Paragraph center>
                            <Error>{status.error}</Error>
                          </Paragraph>
                        )) ||
                          (status.message && (
                            <Paragraph center>{status.message}</Paragraph>
                          )))}
                    </Spacing>
                  </form>
                )}
              />
            </Spacing>
          </MaxWidth>
        </PageWrap>
      </Page>
    );
  }
}

const mapStateToProps = ({ location }) => {
  return {
    prevUrlPathname: location.prev.pathname
  };
};

const mapDispatchToProps = {
  ...trackingActions,
  goToSuccess: () => ({ type: 'LEAD_CAPTURE_THANKS' })
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadCapture);
