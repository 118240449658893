import React, { useState } from 'react';

import BandcampSignUpForm from './BandcampLeadForm';
import BandcampLeadConfirmation from './BandcampLeadConfirmation';

import Spacing from '../../components/atoms/Spacing';
import Icon from '../../components/atoms/Icon';
import { PageWrapNew as PageWrap } from '../../components/atoms/PageWrap';

import IntegrationsForm from '../../components/molecules/IntegrationsForm';

import Page from '../../components/utils/Page';

import everpressBandcampLogo from '../../img/sprites/everpress-x-bandcamp.svg';
import formImg from '../../img/pages/bandcamp/person-on-speaker.png';
import confirmationImg from '../../img/pages/bandcamp/person-standing.png';

import generalStyles from './style/general.module.css';

const Bandcamp = () => {
  const [hasLeadSubmitted, setLeadSubmitted] = useState(false);
  const imgProps = hasLeadSubmitted
    ? {
        className: generalStyles.image,
        src: confirmationImg,
        alt: 'Bandcamp sign-up confirmation'
      }
    : {
        className: generalStyles.image,
        src: formImg,
        alt: 'Bandcamp sign-up'
      };

  const handleSuccessfulSubmission = () => {
    setLeadSubmitted(true);
  };

  return (
    <Page title="Bandcamp">
      <PageWrap>
        <Spacing size={2} position="t">
          <IntegrationsForm imgProps={imgProps}>
            <Spacing size={[2, 2, 0]} position="t">
              <Spacing size={4} position="b">
                <Icon glyph={everpressBandcampLogo} width="194" height="32" />
              </Spacing>
              {hasLeadSubmitted ? (
                <BandcampLeadConfirmation />
              ) : (
                <BandcampSignUpForm onSuccess={handleSuccessfulSubmission} />
              )}
            </Spacing>
          </IntegrationsForm>
        </Spacing>
      </PageWrap>
    </Page>
  );
};

export default Bandcamp;
