import produce from 'immer';

const initialState = {
  items: [],
  isLoading: false
};

export default (state = initialState, action) =>
  produce(state, newState => {
    const { type, payload } = action;

    switch (type) {
      case 'GET_PAYOUTS_HISTORY_PENDING':
        newState.isLoading = true;
        return newState;
      case 'GET_PAYOUTS_HISTORY_FULFILLED':
        newState.items = payload;
        newState.isLoading = false;
        return newState;
      default:
        return state;
    }
  });
