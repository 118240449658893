import { useReducer } from 'react';
import findIndex from 'lodash/findIndex';

export const checkoutSteps = {
  STEP_ONE: 'STEP_ONE',
  STEP_TWO: 'STEP_TWO',
  STEP_THREE: 'STEP_THREE',
  STEP_FOUR: 'STEP_FOUR'
};

const stepActionTypes = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  SAVE: 'SAVE',
  EDIT: 'EDIT'
};

const nextStepInOrder = (state) => {
  const orderedState = [
    state.STEP_ONE,
    state.STEP_TWO,
    state.STEP_THREE,
    state.STEP_FOUR
  ];

  // if there already is a step open and not saved
  // keep it open
  const nextOpen = findIndex(orderedState, { open: true, saved: false });

  if (nextOpen >= 0) return null;

  const nextEmptyIndex = findIndex(orderedState, 'empty');
  const orderedSteps = [
    checkoutSteps.STEP_ONE,
    checkoutSteps.STEP_TWO,
    checkoutSteps.STEP_THREE,
    checkoutSteps.STEP_FOUR
  ];
  let nextStep;
  if (nextEmptyIndex >= 0) {
    nextStep = orderedSteps[nextEmptyIndex];
  }
  return nextStep;
};

const closeAllOtherSteps = (updatedState, state, step) => {
  for (const [key] of Object.entries(state)) {
    if (key && key !== step) {
      updatedState[key].open = !state[key].empty;
      updatedState[key].saved = !state[key].empty;
    }
  }
};

export const checkoutStepsReducer = (state, action) => {
  const updatedState = { ...state };
  switch (action.type) {
    case stepActionTypes.OPEN:
      updatedState[action.step].open = true;
      return updatedState;
    case stepActionTypes.CLOSE:
      updatedState[action.step].open = false;
      updatedState[action.step].saved = false;
      updatedState[action.step].empty = true;
      return updatedState;
    case stepActionTypes.SAVE:
      updatedState[action.step].empty = false;
      updatedState[action.step].saved = true;
      updatedState[action.step].open = true;

      const next = nextStepInOrder(updatedState);
      if (next) {
        closeAllOtherSteps(updatedState, state, action.step);
        updatedState[next].open = true;
      }
      return updatedState;
    case stepActionTypes.EDIT:
      closeAllOtherSteps(updatedState, state, action.step);
      updatedState[action.step].saved = false;
      return updatedState;
    default:
      return state;
  }
};

const stepsInit = (initialSteps) => initialSteps;

export const useCheckoutSteps = ({
  init = stepsInit,
  reducer = checkoutStepsReducer
} = {}) => {
  const [stepsState, dispatch] = useReducer(
    reducer,
    {
      STEP_ONE: {
        open: true,
        saved: false,
        empty: true
      },
      STEP_TWO: {
        open: false,
        saved: false,
        empty: true
      },
      STEP_THREE: {
        open: false,
        saved: false,
        empty: true
      },
      STEP_FOUR: {
        open: false,
        saved: false,
        empty: true
      }
    },
    init
  );

  const openStep = (openStep) =>
    dispatch({ type: stepActionTypes.OPEN, step: openStep });
  const closeStep = (closeStep) =>
    dispatch({ type: stepActionTypes.CLOSE, step: closeStep });
  const saveStep = (saveStep) =>
    dispatch({ type: stepActionTypes.SAVE, step: saveStep });
  const editStep = (editStep) =>
    dispatch({ type: stepActionTypes.EDIT, step: editStep });

  return { stepsState, openStep, closeStep, saveStep, editStep };
};
