import { createContext } from 'react';

const DisclosureContext = createContext({});

/**
 * If required, export a useDisclosureContext hook to expose data for a given
 * `Disclosure` component to its descendants.
 */

export default DisclosureContext;
