import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import RouterLink from 'redux-first-router-link';

import config from '../../../config';

import * as userActions from '../../../actions/user';

import PageWrap from '../../atoms/PageWrap';
import Spacing from '../../atoms/Spacing';
import SelectCurrencies from '../../atoms/Select/SelectCurrencies';
import { CSSGrid, Grid, InlineGrid } from '../../atoms/Grid';
import Link from '../../atoms/Link';
import TrustBox from '../../atoms/TrustBox';

import FooterColumn from './FooterColumn';
import FooterLink from './FooterLink';

import everpressLogo from '../../../img/everpress-logo.svg';
import bCorpLogo from '../../../img/b-corp-logo.svg';

import generalStyle from './style/general.module.css';

const cx = classNames.bind(generalStyle);

class Footer extends Component {
  state = {
    currencyMenuIsActive: false
  };

  closeCurrencyMenu() {
    this.setState({
      currencyMenuIsActive: false
    });
  }

  onCurrencyClick() {
    this.setState({
      currencyMenuIsActive: !this.state.currencyMenuIsActive
    });
  }

  handleCurrencyChange(currency) {
    this.props.setUserCurrency(currency.value);
  }

  render() {
    const { theme, user, location } = this.props;
    const isDark = theme.color === 'dark' || theme.color === 'red';
    const isHidden =
      user.extendedRoles.includes(config.roles.INTEGRATOR) &&
      /DASHBOARD|CONNECT/.test(location.type);

    let currencyDropdownClassName = null;

    if (theme.isMatchingCurrencyDropdownColor) {
      currencyDropdownClassName = cx({
        'Select-grey': theme.color === 'darker-grey',
        'Select-pastel-grey': theme.color === 'pastel-grey'
      });
    }

    const footerClassName = cx({
      footer: !theme.isMinimal,
      'footer-minimal': theme.isMinimal,
      'bg-black': theme.color === 'dark',
      'bg-white': theme.color === 'light',
      'bg-red': theme.color === 'red',
      'bg-pastel-grey': theme.color === 'pastel-grey'
    });

    if (!theme.isVisible || isHidden) {
      return null;
    }

    if (theme.isMinimal) {
      return (
        <footer className={footerClassName}>
          <PageWrap>
            <InlineGrid
              justify="start"
              align={['start', 'start', 'center', 'center', 'center']}
              direction={['col', 'col', 'row', 'row', 'row']}
            >
              <Spacing size={['05', '05', 0]}>
                <RouterLink to="/">
                  <img
                    src={everpressLogo}
                    alt="Everpress logo"
                    width="40"
                    height="35"
                  />
                </RouterLink>
              </Spacing>
              <Spacing size={['05', '05', 0]}>
                <Spacing size={[0, 0, 8]} position="l">
                  <Link to={{ type: 'SHOP' }} isNav>
                    Shop
                  </Link>
                </Spacing>
              </Spacing>
              <Spacing size={['05', '05', 0]}>
                <Spacing size={[0, 0, 3, 6]} position="l">
                  <Link to={{ type: 'HOW_IT_WORKS' }} isNav>
                    How It Works
                  </Link>
                </Spacing>
              </Spacing>
              <Spacing size={['05', '05', 0]}>
                <Spacing size={[0, 0, 3, 6]} position="l">
                  <Link to={{ type: 'CONTACT' }} isNav>
                    Contact
                  </Link>
                </Spacing>
              </Spacing>
              <Spacing size={['05', '05', 0]}>
                <Spacing size={[0, 0, 3, 6]} position="l">
                  <Link to={{ type: 'TERMS' }} isNav>
                    T&Cs
                  </Link>
                </Spacing>
              </Spacing>
              {!theme.isMinimalCurrencyDropdownHidden && (
                <div className={cx('currencyDropdown')}>
                  <Spacing size={['05', '05', 0]}>
                    <SelectCurrencies
                      standalone
                      onChange={this.handleCurrencyChange.bind(this)}
                      value={user.currency}
                    />
                  </Spacing>
                </div>
              )}
            </InlineGrid>
          </PageWrap>
        </footer>
      );
    }

    return (
      <footer className={footerClassName}>
        <PageWrap>
          <Grid gap={0} align="start">
            <FooterColumn title="Selling" isDark={isDark}>
              <FooterLink isDark={isDark} to="/create">
                Design your own
              </FooterLink>
              <FooterLink
                isDark={isDark}
                to="/how-it-works"
                aria-label="How it works for sellers"
              >
                How it works
              </FooterLink>
              <FooterLink isDark={isDark} to="/price-calculator">
                Calculate your earnings
              </FooterLink>
              <FooterLink isDark={isDark} to="/garments">
                Garments
              </FooterLink>
            </FooterColumn>
            <FooterColumn title="Buying" isDark={isDark}>
              <FooterLink isDark={isDark} to="/shop">
                Shop
              </FooterLink>
              <FooterLink isDark={isDark} to="/terms-of-use">
                Terms of Use
              </FooterLink>
              <FooterLink isDark={isDark} to="/privacy-policy">
                Privacy
              </FooterLink>
              <FooterLink
                isDark={isDark}
                to="/how-it-works/buyers"
                aria-label="How it works for buyers"
              >
                How it works
              </FooterLink>
              <FooterLink isDark={isDark} href="/contact">
                Customer Service
              </FooterLink>
              <FooterLink isDark={isDark} to="/gift-cards">
                Gift Cards
              </FooterLink>
            </FooterColumn>
            <FooterColumn title="Community" isDark={isDark}>
              <FooterLink isDark={isDark} href="/creator-toolkit">
                Creator Toolkit
              </FooterLink>
              <FooterLink isDark={isDark} to="/contact">
                Contact
              </FooterLink>
              <FooterLink isDark={isDark} to="/sustainability">
                Sustainability
              </FooterLink>
              <FooterLink isDark={isDark} to="/bcorp">
                B Corp
              </FooterLink>
              <FooterLink isDark={isDark} to="/people">
                Diversity &amp; Inclusion
              </FooterLink>
              <FooterLink isDark={isDark} href="/blog">
                Blog
              </FooterLink>
              <FooterLink isDark={isDark} to="/about">
                About
              </FooterLink>
              <FooterLink isDark={isDark} href="/careers">
                Careers
              </FooterLink>
            </FooterColumn>
            <FooterColumn title="Social" isDark={isDark}>
              <FooterLink
                isDark={isDark}
                to="https://www.instagram.com/everpresshq/"
              >
                Instagram
              </FooterLink>
              <FooterLink
                isDark={isDark}
                to="https://www.facebook.com/EverPressHQ/"
              >
                Facebook
              </FooterLink>
              <FooterLink isDark={isDark} to="https://twitter.com/everpresshq">
                Twitter
              </FooterLink>
            </FooterColumn>
            <FooterColumn title="Everpress For" isDark={isDark}>
              <FooterLink isDark={isDark} to="/landing/sell-your-artwork">
                Artists
              </FooterLink>
              <FooterLink isDark={isDark} to="/landing/create-band-t-shirts">
                Music
              </FooterLink>
              <FooterLink isDark={isDark} to="/landing/t-shirt-fundraising">
                Charities
              </FooterLink>
              <FooterLink isDark={isDark} to="/landing/start-a-clothing-line">
                Brands
              </FooterLink>
              <FooterLink
                isDark={isDark}
                to="/landing/make-your-own-merchandise"
              >
                Storytellers
              </FooterLink>
            </FooterColumn>
            {!theme.isCurrencyDropdownHidden && (
              <FooterColumn title="Currency" isDark={isDark}>
                <Spacing size={1} position="t">
                  <SelectCurrencies
                    standalone
                    onChange={this.handleCurrencyChange.bind(this)}
                    value={user.currency}
                    className={currencyDropdownClassName}
                  />
                </Spacing>
              </FooterColumn>
            )}
          </Grid>
          <Spacing size={[15, 2]} position="t">
            <CSSGrid
              gap={[15, 2]}
              template={['repeat(2, auto) / 1fr', '1fr / repeat(2, auto)']}
              justify="center"
              justifyContent="center"
              align="center"
            >
              <img
                src={bCorpLogo}
                alt="Certified B Corp"
                className={generalStyle.bcorpLogo}
              />
              <TrustBox align="center" />
            </CSSGrid>
          </Spacing>
        </PageWrap>
      </footer>
    );
  }
}

const mapStateToProps = ({ user, location, footer }) => ({
  user,
  location,
  ...footer
});

export default connect(mapStateToProps, { ...userActions })(Footer);
