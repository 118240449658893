import React from 'react';

import { ButtonNew } from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import MaxWidth from '../../atoms/MaxWidth';
import PageWrap from '../../atoms/PageWrap';
import Paragraph from '../../atoms/Paragraph';
import Spacing from '../../atoms/Spacing';

export default function AltCta({
  title = 'Make your own luck',
  copy,
  buttonLabel = 'Do it',
  buttonSlug = '/create',
  hasBorderTop
}) {
  return (
    <PageWrap>
      <div className={hasBorderTop ? 'border-t-1 border-solid' : undefined}>
        <Spacing size={[2, 4, 6]} type="padding" position="t">
          <MaxWidth value={690} center>
            <Spacing size={[2, 3]} position="b">
              <Heading size={['s', 'l', 'xl']} center tag="h2">
                {title}
              </Heading>
            </Spacing>

            {copy && (
              <Spacing size={[3, 4]} position="b">
                <Paragraph size={['base', 's']} center>
                  {copy}
                </Paragraph>
              </Spacing>
            )}
            <Paragraph center>
              <ButtonNew to={buttonSlug} isExternal={buttonSlug === '/create'}>
                {buttonLabel}
              </ButtonNew>
            </Paragraph>
          </MaxWidth>
        </Spacing>
      </div>
    </PageWrap>
  );
}
