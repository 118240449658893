import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Paragraph from '../../atoms/Paragraph';
import Spacing from '../../atoms/Spacing';
import Link from '../../atoms/Link';

import { CtaBox } from '../Box';

import styles from './style/general.module.css';

const ShippingAddress = ({ order, isGuest }) => (
  <div data-test-id="shipping-address-box">
    <CtaBox title="Shipping Address">
      <Paragraph size="xxs">
        {order.deliveryAddress.firstName} {order.deliveryAddress.lastName}
        <br />
        {order.deliveryAddress.line1}
        {order.deliveryAddress.line2 && ', '}
        {order.deliveryAddress.line2}
        ,&nbsp;
        {order.deliveryAddress.city}
        <br />
        {order.deliveryAddress.county}
        {order.deliveryAddress.county && ', '}
        {order.deliveryAddress.postcode}
        ,&nbsp;
        {order.deliveryAddress.countryName}
      </Paragraph>
    </CtaBox>
    <Spacing size={2}>
      <div className={styles.manageOrderCta}>
        {isGuest ? (
          <Fragment>
            Anything we can help with? Check our{' '}
            <Link href="/creator-toolkit/ive-placed-my-order-on-a-campaign-what-next">
              FAQs
            </Link>{' '}
            or <Link to="/contact">Contact Us</Link>
          </Fragment>
        ) : (
          <Link to={`/dashboard/orders/${order.orderId}`}>
            Manage your order
          </Link>
        )}
      </div>
    </Spacing>
  </div>
);

ShippingAddress.propTypes = {
  order: PropTypes.object.isRequired,
  isGuest: PropTypes.bool.isRequired
};

export default ShippingAddress;
