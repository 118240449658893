import React from 'react';

import Link from '../../atoms/Link';
import Paragraph from '../../atoms/Paragraph';

export default function TermsFormField({ name, size, ...props }) {
  return (
    <Paragraph size={size}>
      By signing up I agree to the{' '}
      <Link to={{ type: 'TERMS' }} target="_blank">
        terms and conditions
      </Link>{' '}
      and{' '}
      <Link to={{ type: 'PRIVACY_POLICY' }} target="_blank">
        privacy policy
      </Link>
      .
    </Paragraph>
  );
}
