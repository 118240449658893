import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import partition from 'lodash/partition';
import isEmpty from 'lodash/isEmpty';

import config from '../../../config';

import Paragraph from '../../atoms/Paragraph';
import { CSSGrid, InlineGrid } from '../../../components/atoms/Grid';
import Spacing from '../../../components/atoms/Spacing';
import Icon from '../../atoms/Icon';

import ProductOverview from '../../../components/molecules/ProductOverview/New';
import ProductShippingOnInfo from '../../../components/molecules/ProductShippingOnInfo';
import ProductShot from '../../../components/molecules/ProductShot';

import mailDownloadIcon from '../../../img/sprites/mail-download-line.svg';

const OrderOverview = ({ items, campaigns, deliveries, currency }) => {
  const [giftCards, products] = partition(items, 'giftCardId');

  const sortedProducts = useMemo(() => sortBy(products, ['campaignId']), [
    products
  ]);

  return (
    <CSSGrid rowGap={[2, 2, 2, 15]} justify="stretch" justifyContent="stretch">
      {giftCards.map((giftCard, index) => {
        const isLastItem = isEmpty(products) && giftCards.length - 1 === index;

        return (
          <ProductOverview
            key={giftCard.giftCardId}
            name={giftCard.giftCardName}
            image={giftCard.image}
            quantity={giftCard.quantity}
            currency={currency}
            price={giftCard.price}
          >
            <div className="border-t-1 border-grey-lighter" />
            <Spacing size={15}>
              <InlineGrid justify="start">
                <Spacing size="05" position="r">
                  <Icon glyph={mailDownloadIcon} width={18} height={18} />
                </Spacing>
                <Paragraph size="xxs" className="text-blue-primary">
                  Delivered digitally
                </Paragraph>
              </InlineGrid>
            </Spacing>
            {!isLastItem && (
              <div className="-mt-px absolute left-0 w-full border-b-1 border-grey-lighter mdlg:border-0" />
            )}
          </ProductOverview>
        );
      })}
      {sortedProducts.map((product, index) => {
        const delivery = deliveries.find(
          (delivery) => delivery.campaignId === product.campaignId
        );
        const campaign = campaigns.find(
          (campaign) => campaign.campaignId === product.campaignId
        );
        const campaignProduct = campaign.products.find(
          (item) => item.campaignProductId === product.campaignProductId
        );
        const selectedColour = campaignProduct.colours.find(
          (colour) => colour.colourId === product.colourId
        );
        const isSample = product.type === 'sample';
        const isLastOrderItem = sortedProducts.length - 1 === index;
        const isLastOccurrence =
          sortedProducts.indexOf(
            [...sortedProducts]
              .reverse()
              .find((orderItem) => orderItem.campaignId === product.campaignId)
          ) === index;
        return (
          <ProductOverview
            key={product.orderItemId}
            name={campaign.title}
            image={() =>
              !isSample && (
                <ProductShot
                  item={campaignProduct}
                  campaign={campaign}
                  colourId={product.colourId}
                  width={64}
                  height={54 / config.productShotsRatio}
                  side="front"
                  overrideImageUrl={product.image}
                  hasContainerPadding={false}
                />
              )
            }
            currency={currency}
            price={product.total}
            colour={selectedColour}
            quantity={product.quantity}
            size={product.size}
          >
            {isLastOccurrence && delivery && (
              <>
                <div className="border-t-1 border-grey-lighter" />
                <Spacing size={15}>
                  <ProductShippingOnInfo
                    delivery={delivery}
                    minimumSales={campaign.minimum}
                    currency={currency}
                    isPrintOnDemand={campaign.printOnDemand}
                  />
                </Spacing>
              </>
            )}
            {isLastOccurrence && !delivery && (
              <>
                <div className="border-t-1 border-grey-lighter" />
                <Spacing size={15}>
                  <Paragraph size="xxs" color="grey-primary">
                    Delivery calculated from delivery address
                  </Paragraph>
                </Spacing>
              </>
            )}
            {!isLastOrderItem && isLastOccurrence && (
              <div className="-mt-px absolute left-0 w-full border-b-1 border-grey-lighter mdlg:border-0" />
            )}
          </ProductOverview>
        );
      })}
    </CSSGrid>
  );
};

export default OrderOverview;

OrderOverview.propTypes = {
  items: PropTypes.array.isRequired,
  campaigns: PropTypes.array.isRequired,
  deliveries: PropTypes.arrayOf(
    PropTypes.shape({
      campaignId: PropTypes.number.isRequired,
      expectedDispatchDate: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired
    })
  ),
  currency: PropTypes.string.isRequired
};
