import React from 'react';

import { ButtonNew as Button } from '../../../../components/atoms/Button';
import Alert from '../../../../components/atoms/Alert';
import { DefaultField as Field, Form } from '../../../../components/atoms/Form';
import Icon from '../../../../components/atoms/Icon';
import Price from '../../../../components/atoms/Price';
import Spacing from '../../../../components/atoms/Spacing';
import Paragraph from '../../../../components/atoms/Paragraph';

import { CtaBox } from '../../../../components/molecules/Box';
import TermsFormField from '../../../../components/molecules/TermsFormField';

import paypalLogo from '../../../../img/sprites/paypal.svg';

const PayPalForm = ({
  initialValues,
  handleSubmit,
  priceTotal,
  currency,
  isGuest
}) => {
  return (
    <Form
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ isSubmitting, handleSubmit, status }) => {
        let statusMessage = null;
        const { error } = { ...status };
        if (error) {
          statusMessage = <Alert kind="error" message={status.error} />;
        }

        return (
          <form onSubmit={handleSubmit} data-test-id="checkout-form">
            <Spacing size={1} position="t">
              <CtaBox title="4. Payment Details">
                <Icon
                  className="-my-15 -mx-1"
                  glyph={paypalLogo}
                  width={110}
                  height={65}
                />
              </CtaBox>
            </Spacing>

            <div className="my-4 border-b-1 border-grey-lighter" />
            <Spacing size={2} position="y">
              {isGuest && (
                <Spacing size={1} position="y">
                  <TermsFormField name="acceptTerms" size="xxs" />
                </Spacing>
              )}
              <Field type="checkbox" name="isGift">
                <Paragraph size="xxs">This order is a gift</Paragraph>
              </Field>
            </Spacing>
            <Spacing size={4} position="b">
              <Button
                type="submit"
                state={isSubmitting ? 'loading' : null}
                fullWidth
              >
                Place order and pay{' '}
                <Price
                  value={priceTotal}
                  currency={currency}
                  className="contents"
                />
              </Button>
              <Spacing size={15} position="t">
                {statusMessage}
              </Spacing>
            </Spacing>
          </form>
        );
      }}
    />
  );
};

export default PayPalForm;
