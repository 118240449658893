import React from 'react';

import { Grid } from '../../../components/atoms/Grid';

import CampaignTile from './CampaignTile';

const CreatorCampaigns = ({ profileName, isOwner, campaigns }) => {
  return (
    <Grid gap={[1, 1, 1, 15, 2]} align="start">
      {campaigns.map((campaign) => (
        <CampaignTile
          key={campaign.slug}
          isOwner={isOwner}
          campaign={campaign}
          profileName={profileName}
        />
      ))}
    </Grid>
  );
};

export default CreatorCampaigns;
