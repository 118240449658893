import formatDistanceToNowStrict from 'date-fns-latest/formatDistanceToNowStrict';
import parse from 'date-fns/parse';

export const getUTCDate = (dateString = Date.now()) => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const getDistanceToNowInWords = (date, locale) => {
  try {
    const parsedDate = parse(date);
    const distanceToNow = formatDistanceToNowStrict(parsedDate, {
      locale,
      addSuffix: true
    });

    return distanceToNow;
  } catch (err) {
    console.error('`date` must not be Invalid Date');

    return undefined;
  }
};
