import React from 'react';
import PropTypes from 'prop-types';

import CarouselContext from '../CarouselContext';

import generalStyles from './style/general.module.css';

export default class CarouselFade extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      currentIndex: 0
    };
  }

  componentDidMount() {
    this.launchLoop();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children.length !== this.props.children.length) {
      this.launchLoop();
    }
  }

  componentWillUnmount() {
    clearInterval(this.fadeInterval);
  }

  launchLoop() {
    const { duration } = this.props;
    const itemCount = this.props.children.length;

    if (itemCount > 0) {
      this.fadeInterval = setInterval(() => {
        this.loop();
      }, duration);
    }
  }

  loop() {
    const { currentIndex } = this.state;
    const itemCount = this.props.children.length;

    if (currentIndex >= 0 && currentIndex < itemCount - 1) {
      this.setState({ currentIndex: currentIndex + 1 });
    } else {
      this.setState({ currentIndex: 0 });
    }
  }

  render() {
    const { currentIndex } = this.state;
    const contextValues = {
      currentIndex,
      gap: 0,
      isFade: true
    };

    return (
      <div className={generalStyles.carouselFade}>
        <CarouselContext.Provider value={contextValues}>
          {this.props.children}
        </CarouselContext.Provider>
      </div>
    );
  }
}

CarouselFade.propTypes = {
  duration: PropTypes.number
};

CarouselFade.defaultProps = {
  duration: 4000
};
