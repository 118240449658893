module.exports = {
  prefix: '',
  important: false,
  separator: ':',
  theme: {
    colors: {
      transparent: 'transparent',
      inherit: 'inherit',
      black: '#000',
      grey: '#fafafa',
      'dark-grey': '#F7F8F9',
      'darker-grey': '#bbbbbb',
      'darkest-grey': '#949494',
      'darkest-dark-grey': '#656464',
      'pastel-grey': '#CCCDC8',
      'soft-grey': '#DFDFDF',
      white: '#ffffff',
      red: '#ff0000',
      green: '#00CF3A'
    },
    screens: {
      sm: '576px',
      md: '768px',
      mdlg: '900px',
      lg: '1100px',
      xl: '2000px'
    },
    fontFamily: {
      sans: [
        '"Helvetica Neue LT Std 53 Extended"',
        'Helvetica Neue',
        'sans-serif'
      ]
    },
    fontSize: {
      xxxxs: '0.8rem',
      xxxs: '1rem',
      xxs: '1.2rem',
      xs: '1.4rem',
      base: '1.4rem', // 14px
      s: '1.8rem',
      m: '2.2rem',
      l: '3rem',
      xl: '3.66rem',
      xxl: '6rem',
      xxxl: '8.2rem'
    },
    fontWeight: {
      normal: 'normal',
      bold: '800'
    },
    lineHeight: {
      none: 1,
      tight: 1.15,
      snug: 1.375,
      normal: 1.5,
      relaxed: 1.75,
      loose: 2
    },
    letterSpacing: {
      tight: '-0.05em',
      normal: '0',
      wide: '0.05em'
    },
    textColor: (theme) => theme('colors'),
    backgroundColor: (theme) => theme('colors'),
    borderWidth: {
      default: '1px',
      0: '0',
      1: '1px',
      2: '2px',
      3: '3px',
      4: '4px'
    },
    borderColor: (theme) =>
      Object.assign({ default: theme('colors.black') }, theme('colors')),
    borderRadius: {
      none: '0',
      default: '3px',
      md: '5px',
      round: '50%',
      full: '9999px'
    },
    width: {
      auto: 'auto',
      px: '1px',
      5: '5%',
      8: '8.33333%',
      10: '10%',
      15: '15%',
      16: '16.66667%',
      20: '20%',
      25: '25%',
      30: '30%',
      33: '33.333%',
      35: '35%',
      40: '40%',
      41: '41.66667%',
      45: '45%',
      50: '50%',
      55: '55%',
      58: '58.33333%',
      60: '60%',
      65: '65%',
      66: '66.66667%',
      70: '70%',
      75: '75%',
      80: '80%',
      83: '83.33333%',
      85: '85%',
      90: '90%',
      91: '91.66667%',
      95: '95%',
      100: '100%',
      full: '100%',
      screen: '100vw',
      max: 'max-content'
    },
    height: {
      auto: 'auto',
      px: '1px',
      5: '5%',
      10: '10%',
      15: '15%',
      16: '16.66667%',
      20: '20%',
      25: '25%',
      30: '30%',
      33: '33.333%',
      35: '35%',
      40: '40%',
      45: '45%',
      50: '50%',
      55: '55%',
      60: '60%',
      65: '65%',
      66: '66.66667%',
      70: '70%',
      75: '75%',
      80: '80%',
      83: '83.33333%',
      85: '85%',
      90: '90%',
      95: '95%',
      100: '100%',
      full: '100%',
      screen: '100vh'
    },
    minWidth: {
      0: '0',
      full: '100%'
    },
    minHeight: {
      0: '0',
      full: '100%',
      screen: '100vh'
    },
    maxWidth: (theme) => ({
      xs: '20rem',
      sm: '30rem',
      md: '40rem',
      lg: '50rem',
      xl: theme('screens.xl'),
      90: '90%',
      full: '100%'
    }),
    maxHeight: {
      full: '100%',
      screen: '100vh'
    },
    padding: {
      px: '1px',
      0: '0',
      '05': '0.5rem',
      1: '1rem',
      15: '1.5rem',
      2: '2rem',
      25: '2.5rem',
      3: '3rem',
      4: '4rem',
      45: '4.5rem',
      5: '5rem',
      6: '6rem',
      7: '7rem',
      8: '8rem',
      9: '9rem',
      10: '10rem',
      50: '50%',
      full: '100%'
    },
    margin: {
      auto: 'auto',
      px: '1px',
      0: '0',
      '05': '0.5rem',
      1: '1rem',
      15: '1.5rem',
      2: '2rem',
      25: '2.5rem',
      3: '3rem',
      4: '4rem',
      5: '5rem',
      6: '6rem',
      7: '7rem',
      8: '8rem',
      9: '9rem',
      10: '10rem',
      '-px': '-1px',
      '-05': '-0.5rem',
      '-15': '-1.5rem',
      '-1': '-1rem',
      '-2': '-2rem',
      '-3': '-3rem',
      '-4': '-4rem',
      '-5': '-5rem',
      '-6': '-6rem',
      '-7': '-7rem',
      '-8': '-8rem',
      '-9': '-9rem',
      '-10': '-10rem'
    },
    boxShadow: {
      default: '0 2px 4px 0 rgba(0,0,0,0.10)',
      sm: '0px 1px 3px rgba(0, 0, 0, 0.1)',
      md: '0px 2px 5px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.1)',
      lg: '0px 3px 8px -1px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.1)',
      xl:
        '0px 10px 15px -2px rgba(0, 0, 0, 0.15), 0px 1px 6px rgba(0, 0, 0, 0.1)',
      inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
      flat: '0 0 4px 0 rgba(0,0,0,0.10)',
      none: 'none'
    },
    zIndex: {
      '-1': '-1',
      auto: 'auto',
      0: 0,
      10: 10,
      20: 20,
      30: 30,
      40: 40,
      50: 50,
      header: 1000,
      modal: 2000
    },
    opacity: {
      0: '0',
      15: '.15',
      25: '.25',
      30: '.3',
      50: '.5',
      55: '.55',
      60: '.6',
      75: '.75',
      100: '1'
    },
    fill: {
      current: 'currentColor'
    },
    stroke: {
      current: 'currentColor'
    },
    extend: {
      colors: {
        grey: {
          lightest: '#F9F9F9',
          lighter: '#E4E6EA',
          light: '#BDBFC3',
          primary: '#8C8C8E',
          dark: '#5A5B5D',
          darker: '#2B2B2C',
          darkest: '#131314'
        },
        green: {
          lightest: '#EDFFF0',
          light: '#C3F3C4',
          primary: '#0FAE48',
          dark: '#0B7C44',
          darkest: '#0F332B'
        },
        yellow: {
          lightest: '#FFFBE7',
          light: '#FFE49C',
          primary: '#F7B91B',
          dark: '#A85E06',
          darkest: '#402405'
        },
        red: {
          lightest: '#FFEAE9',
          light: '#FFA4A4',
          primary: '#E63D3D',
          dark: '#B90606',
          darkest: '#360000'
        },
        blue: {
          lightest: '#E4F2FF',
          light: '#6CB3FF',
          primary: '#266BFB',
          dark: '#1746A8',
          darkest: '#151F55'
        }
      },
      borderRadius: {
        sm: '2px',
        md: '5px',
        lg: '10px',
        xl: '18px'
      },
      width: {
        wrapped: '1400px',
        container: '1900px'
      },
      maxWidth: {
        wrapped: '1400px',
        container: '1900px'
      },
      padding: {
        '08': '8px'
      },
      margin: {
        '-25': '-2.5rem'
      },
      gap: {
        14: '3.5rem',
        16: '4rem',
        18: '4.5rem',
        20: '5rem',
        22: '5.5rem',
        24: '6rem'
      },
      zIndex: {
        '-1': '-1'
      },
      fontSize: {
        caption: '1rem',
        'body-s': '1.2rem',
        body: '1.4rem',
        'heading-xxs': '1rem',
        'heading-xs': '1.2rem',
        'heading-s': '1.4rem',
        'heading-m': '1.8rem',
        'heading-l': '2.6rem',
        'heading-xl': '4.8rem'
      }
    }
  },
  variants: {
    alignContent: ['responsive'],
    alignItems: ['responsive'],
    alignSelf: ['responsive'],
    appearance: ['responsive'],
    backgroundAttachment: ['responsive'],
    backgroundColor: ['focus', 'group-hover', 'hover', 'responsive'],
    backgroundPosition: ['responsive'],
    backgroundRepeat: ['responsive'],
    backgroundSize: ['responsive'],
    borderColor: ['hover', 'responsive', 'focus-visible'],
    borderRadius: ['responsive', 'focus-visible'],
    borderStyle: ['responsive', 'focus-visible'],
    borderWidth: ['responsive', 'focus-visible'],
    cursor: ['responsive'],
    display: ['responsive', 'group-hover'],
    fill: ['responsive'],
    flex: ['responsive'],
    flexDirection: ['responsive'],
    flexGrow: ['responsive'],
    flexShrink: ['responsive'],
    flexWrap: ['responsive'],
    fontFamily: ['responsive'],
    fontSize: ['responsive'],
    fontStyle: ['focus', 'hover', 'responsive'],
    fontWeight: ['responsive'],
    height: ['responsive'],
    inset: ['responsive'],
    justifyContent: ['responsive'],
    justifyItems: ['responsive'],
    justifySelf: ['responsive'],
    letterSpacing: ['responsive'],
    lineHeight: ['responsive'],
    listStylePosition: ['responsive'],
    listStyleType: ['responsive'],
    margin: ['responsive'],
    maxHeight: ['responsive'],
    maxWidth: ['responsive'],
    minHeight: ['responsive'],
    minWidth: ['responsive'],
    negativeMargin: ['responsive'],
    opacity: ['hover', 'responsive'],
    outline: ['responsive', 'focus-visible'],
    overflow: ['responsive'],
    padding: ['responsive'],
    pointerEvents: ['responsive'],
    position: ['responsive'],
    resize: ['responsive'],
    stroke: ['responsive'],
    textAlign: ['responsive'],
    textColor: ['focus', 'group-hover', 'hover', 'responsive'],
    textDecoration: ['focus', 'hover', 'responsive'],
    textTransform: ['focus', 'hover', 'responsive'],
    userSelect: ['responsive'],
    verticalAlign: ['responsive'],
    visibility: ['responsive'],
    whitespace: ['responsive'],
    width: ['responsive'],
    wordBreak: ['responsive'],
    zIndex: ['responsive']
  },
  corePlugins: {
    container: false,
    boxShadow: true,
    float: false
  },
  plugins: []
};
