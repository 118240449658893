import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import throttle from 'lodash/throttle';

import Raf from '../../../../../helpers/Raf';

import imageStyles from './style/image.module.css';
import storyStyles from './style/story.module.css';

const cx = classNames.bind({
  ...imageStyles,
  ...storyStyles
});

export default class CustomerStory extends Component {
  constructor() {
    super();

    this.state = {
      bubbleReversed: false,
      isHover: false,
      hideBubble: false,
      windowWidth: window.innerWidth
    };

    this.followX = 0;
    this.x = 0;
    this.factor = -0.99;
    this.friction = 0.08;

    this.storyRef = null;
    this.imageRef = null;

    this.handleMouseMove = throttle(this.handleMouseMove.bind(this), 40);
    this.handleResize = throttle(this.handleResize.bind(this), 100);

    this.RAF = new Raf();
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('resize', this.handleResize);

    this.RAF.subscribe(this.animatePosition.bind(this));
    this.RAF.start();

    this.forceUpdate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isInView !== this.props.isInView) {
      if (this.props.isInView) {
        this.RAF.start();
      } else {
        this.RAF.stop();
      }
    }
  }

  componentWillUnmount() {
    this.RAF.unsubscribe();
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handleMouseMove(e) {
    const newX = window.innerWidth / 2 - e.clientX;
    this.followX = (this.factor * newX) / 100;
  }

  animatePosition() {
    const { offsetDiff } = this.props;
    const newX = (this.followX - this.x) * this.friction;
    this.x += newX;

    if (offsetDiff > 0) {
      const translateX = `translateX(${this.x}px)`;
      const scale = 'scale(1.1)';
      const rotate = 'rotate(0.01deg)'; // fake Anti Aliasing on engines that don't support it (firefox for example)

      if (this.imageRef) {
        this.imageRef.style.transform = `${translateX} ${rotate} ${scale}`;
      }
    }
  }

  render() {
    const { image, imagesCount } = this.props;
    const { windowWidth } = this.state; // Forward parent wrapRef and use its width instead
    const targetWidth = Math.min(1400, windowWidth);

    const storyClassName = cx('story');

    const storyProps = {
      className: storyClassName,
      style: {
        width: `${Math.floor(targetWidth / imagesCount - 25)}px`
      },
      ref: (ref) => {
        this.storyRef = ref;
      }
    };

    const imageProps = {
      className: cx('image'),
      style: {
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      },
      ref: (ref) => {
        this.imageRef = ref;
      }
    };

    return (
      <div {...storyProps}>
        <div className={cx('imageContainer')}>
          <div {...imageProps} />
        </div>
      </div>
    );
  }
}

CustomerStory.propTypes = {
  image: PropTypes.node.isRequired,
  isInView: PropTypes.bool,
  offsetDiff: PropTypes.number.isRequired
};
