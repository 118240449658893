import React, { Component } from 'react';
import classNames from 'classnames';
import throttle from 'lodash/throttle';

import generalStyles from './style/general.module.css';

import images from './images';

export default class HeroImages extends Component {
  constructor() {
    super();

    this.state = {
      currentIndex: 0
    };

    this.imagesLength = images.length;
    this.handleMouseMove = throttle(this.handleMouseMove.bind(this), 300, {
      trailing: false
    });
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleMouseMove() {
    const { currentIndex } = this.state;
    const length = this.imagesLength;

    if (currentIndex < length - 1) {
      this.setState({ currentIndex: currentIndex + 1 });
    } else {
      this.setState({ currentIndex: 0 });
    }
  }

  render() {
    const { currentIndex } = this.state;

    return (
      <div className={generalStyles.container}>
        {images.map((item, index) => {
          return (
            <img
              key={item.id}
              className={classNames(generalStyles.img, {
                [generalStyles.imgActive]: index === currentIndex
              })}
              src={item.image}
              alt="We’re on a mission to empower creative independence in a commercial world."
            />
          );
        })}
      </div>
    );
  }
}
