import React from 'react';

import { ButtonNew as Button } from '../../Button';
import Field from '../Field';

import generalStyles from './style/general.module.css';

export default function FieldSubmit(props) {
  return (
    <div className={generalStyles.container}>
      <Field border={false} {...props} />
      <Button type="submit" kind="ghost" className={generalStyles.submit}>
        Submit
      </Button>
    </div>
  );
}
