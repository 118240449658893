import queryString from 'query-string';

export default function getOptimImgUrl(
  src,
  width = 1,
  height = 1,
  options = {
    fit: 'max'
  }
) {
  if ('gif' === src.substr(src.lastIndexOf('.') + 1)) {
    options.auto = 'format,compress';
    options['gif-q'] = 35;
  }

  const _src = src.replace(
    process.env.REACT_APP_CDN_URL,
    process.env.REACT_APP_IMGIX_URL
  );
  return `${_src}?w=${width}&h=${height}&auto=format&${queryString.stringify(
    options
  )}`;
}
