import React from 'react';
import { NOT_FOUND } from 'redux-first-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import omit from 'lodash/omit';
import some from 'lodash/some';
import throttle from 'lodash/throttle';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'react-fast-compare';
import { RemoveScroll } from 'react-remove-scroll';
import { AnimatePresence } from 'framer-motion';
import { Swipeable } from 'react-swipeable';
import * as Sentry from "@sentry/react";

import {
  getEnabledFilterOptions,
  getActiveFiltersCount,
  transformInitialFilterSets,
  transformActiveFilterSets
} from '../../helpers/shop';
import pluralise from '../../helpers/pluralise';

import { getUserFavourite } from '../../helpers/selectors/shop';

import * as shopActions from '../../actions/shop';
import * as userActions from '../../actions/user';
import * as discoveryActions from '../../actions/discovery';

import config from '../../config';

import { buildTags } from './tagsData';

import { ScrollTrackerContext } from '../../components/utils/ScrollTracker';
import Page from '../../components/utils/Page';
import Loading from '../../components/utils/Loading';
import Concierge from '../../components/utils/Concierge';

import Alert from '../../components/atoms/Alert';
import PageWrap from '../../components/atoms/PageWrap';
import Spacing from '../../components/atoms/Spacing';
import { ButtonNew as Button } from '../../components/atoms/Button';
import IconButton from '../../components/atoms/IconButton';
import Icon from '../../components/atoms/Icon';
import { Grid, GridItem, InlineGrid } from '../../components/atoms/Grid';
import Paragraph from '../../components/atoms/Paragraph';

import Cta from '../../components/molecules/Cta';
import ProductThumbnail from '../../components/molecules/Thumbnails/ProductThumbnail';
import { ModalNew } from '../../components/molecules/Modal';
import AuthenticationContainer from '../../components/molecules/AuthenticationContainer';

import { garmentTypes } from '../../components/molecules/MegaMenu/garmentTypes';

import ShopCollections from './ShopCollections';
import ShopFilters from './ShopFilters';
import ShopRecentlyViewed from './ShopRecentlyViewed';
import SearchHeading from './SearchHeading';

import emailIcon from '../../img/sprites/email.svg';
import loadingIcon from '../../img/sprites/loading.svg';
import settingsIcon from '../../img/sprites/settings.svg';
import heartIcon from '../../img/sprites/favourite.svg';
import filledHeartIcon from '../../img/sprites/favourite-filled.svg';

const { sortings, filters, defaultTag, podTag } = config.shop;

const initialFilterSets = transformInitialFilterSets(filters, false);

class Shop extends React.Component {
  state = {
    filtersToggleIsVisible: false,
    filtersMenuIsVisible: false,
    showAuthModal: false,
    attemptedFavourite: {
      campaignId: null,
      userFavouriteId: null
    }
  };

  componentDidMount() {
    const {
      sort,
      saveShopActiveFilters,
      prevScroll,
      location,
      initialActiveFilterSets
    } = this.props;

    const activeFilters = { sort, ...initialActiveFilterSets };
    const transformedActiveFilterSets = transformActiveFilterSets(
      initialActiveFilterSets
    );

    saveShopActiveFilters(activeFilters);

    (async () => {
      await this.update(transformedActiveFilterSets);
      if (prevScroll.y > 0) {
        this.restoreScroll(prevScroll);
      }
    })();

    this.setState({
      filtersToggleIsVisible: !get(location, 'query.q')
    });
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.scroll === this.props.scroll;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      sort,
      initialActiveFilterSets,
      location,
      items,
      saveShopActiveFilters,
      dispatch
    } = this.props;

    // Remove any active filter when a search happens
    if (!get(prevProps.location, 'query.q') && get(location, 'query.q')) {
      dispatch({
        type: 'SHOP',
        query: omit(location.query, 'sort')
      });
    }

    // If search changed
    if (get(prevProps.location, 'query.q') !== get(location, 'query.q')) {
      this.setState({
        filtersToggleIsVisible: !get(location, 'query.q')
      });
    }

    // If url changed
    if (
      prevProps.location.search !== location.search ||
      !isEqual(prevProps.location.query, location.query) ||
      !isEqual(prevProps.location.payload, location.payload)
    ) {
      const activeFilters = { sort, ...initialActiveFilterSets };
      const transformedActiveFilterSets = transformActiveFilterSets(
        initialActiveFilterSets
      );

      saveShopActiveFilters(activeFilters);
      this.update(transformedActiveFilterSets);
    }

    // If items changed
    if (prevProps.items !== items) {
      this.lookupUserFavourites();
    }
  }

  componentWillUnmount() {
    this.props.saveScrollPosition(this.props.scroll);
  }

  async update(params, options) {
    const { location, tag, sort, offset, isGarmentPage } = this.props;
    const direction = this.getDirection();
    const q = get(location, 'query.q');

    let nextParams = {
      tag,
      q,
      sort,
      direction,
      offset,
      isGarmentPage,
      ...params,
      // Hack for POD param being used as a collection
      ...(tag === podTag.value
        ? { tag: defaultTag.value, printOnDemand: true }
        : {}),
      // Hack for garment page items being used as a collection, see shop reducer
      ...(isGarmentPage ? { tag: defaultTag.value } : {})
    };

    try {
      await this.props.getShopItems(nextParams, {
        mergeExisting: options?.mergeExisting && !isGarmentPage
      });
    } catch (err) {
      this.props.dispatch({ type: NOT_FOUND });
    }
  }

  handleLoadMore = (e) => {
    const { offset, activeFilters, isGarmentPage } = this.props;
    const { sort, ...activeFilterSets } = activeFilters;

    if (!offset) {
      e.preventDefault();
    }

    const direction = this.getDirection({ sort });
    const query = get(this.props.location, 'query.q');

    this.props.getMoreShopItems({
      tag: this.props.tag,
      sort: this.props.sort,
      direction: direction,
      isGarmentPage,
      q: query,
      // Conditionally overwrite existing params with selected filters
      ...(activeFilters &&
        !query && {
          sort,
          ...transformActiveFilterSets(activeFilterSets)
        }),
      // Hack for POD param being used as a collection
      ...(this.props.tag === podTag.value
        ? { tag: defaultTag.value, printOnDemand: true }
        : {}),
      // Hack for garment page items being used as a collection, see shop reducer
      ...(isGarmentPage ? { tag: defaultTag.value } : {})
    });
  };

  handleCollectionChange = (tag) => {
    this.props.dispatch({
      type: 'SHOP',
      payload: { tag }
    });

    window.scrollTo(0, 0);
  };

  handleFiltersMenuOpen = () => {
    this.setState({
      filtersMenuIsVisible: true
    });
  };

  handleFiltersMenuClose = () => {
    this.setState({
      filtersMenuIsVisible: false
    });
  };

  handleFiltersMenuSubmit = (values) => {
    const { sort, ...filterSets } = values;

    this.update(
      {
        sort,
        direction: this.getDirection({ sort }),
        ...transformActiveFilterSets(filterSets)
      },
      { mergeExisting: false }
    );
    this.props.saveShopActiveFilters(values);
    this.handleFiltersMenuClose();
  };

  toggleFiltersMenu = () => {
    this.setState({
      filtersMenuIsVisible: !this.state.filtersMenuIsVisible
    });
  };

  restoreScroll = (coords) => {
    window.scrollTo(coords.x, coords.y);
  };

  lookupUserFavourites = async () => {
    if (!this.props.userToken) return;
    const campaignIds = this.props.items.map((campaign) => campaign.campaignId);

    try {
      await this.props.lookupFavourites(campaignIds);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  getDirection(options) {
    const { sort } = options || this.props;

    const direction =
      sort && sortings.find((sorting) => sort === sorting.value).direction;

    return direction;
  }

  handleFavouriteClick = async (campaignId, userFavouriteId) => {
    if (this.props.userToken) {
      try {
        if (userFavouriteId) {
          await this.props.deleteUserFavourite({
            userFavouriteId,
            campaignId
          });
        } else {
          await this.props.favouriteCampaign({
            campaignId
          });
        }
      } catch (err) {
        Sentry.captureException(err);
      }
    } else {
      this.props.favouriteCampaignAttempt({ campaignId });
      this.setState({
        showAuthModal: true,
        attemptedFavourite: {
          campaignId,
          userFavouriteId
        }
      });
    }
  };

  async handleAuthModalSuccess() {
    await this.lookupUserFavourites();
    await this.handleFavouriteClick(
      this.state.attemptedFavourite.campaignId,
      this.state.attemptedFavourite.userFavouriteId
    );
    await this.setState({
      showAuthModal: false,
      attemptedFavourite: {
        campaignId: null,
        userFavouriteId: null
      }
    });
  }

  renderGridItem(item, index) {
    const {
      campaignId,
      featuredCampaignProductImage,
      isFeatured = false,
      images
    } = item;

    const featuredCampaignProduct = item.products.find(
      (product) => product.featured
    );

    let defaultCampaignProductImage;

    if (!featuredCampaignProductImage) {
      // Discovery campaign not handled by shop middleware, see services/shopMiddleware.js
      defaultCampaignProductImage =
        images.find(
          (image) =>
            image.campaignProductId ===
              featuredCampaignProduct.campaignProductId &&
            image.colourId === featuredCampaignProduct.featuredColourId &&
            image.type === 'grey'
        ) || images[0];
    }

    const campaignProductImage =
      featuredCampaignProductImage || defaultCampaignProductImage;

    const userFavourite = getUserFavourite(this.props.favourites, campaignId);

    const handleCampaignClick = () => {
      const { tag } = this.props;

      this.props.dispatch({
        type: 'SHOP_CAMPAIGN_CLICKED',
        payload: {
          tag,
          isFeatured
        }
      });
    };

    return (
      <GridItem key={item.slug} columnSize={[12, 12, 4, 4, 33, 4]}>
        <div
          data-test-id={`campaign-${index + 1}`}
          onClick={handleCampaignClick}
        >
          <Spacing size={2}>
            <ProductThumbnail
              slug={item.slug}
              title={item.title}
              subTitle={item.subTitle}
              image={campaignProductImage.url}
              alt={campaignProductImage.alt}
              price={featuredCampaignProduct.prices}
              timeLeft={!item.printOnDemand ? item.endAt : ''}
              isGreyBg={item.greyBackground}
              isPrintOnDemand={item.printOnDemand}
              isFeatured={isFeatured}
            >
              {!isFeatured && (
                <IconButton
                  glyph={userFavourite ? filledHeartIcon : heartIcon}
                  title={
                    userFavourite
                      ? 'Remove campaign from favourites'
                      : 'Add campaign to favourites'
                  }
                  kind="ghost"
                  onClick={throttle(
                    () => {
                      this.handleFavouriteClick(
                        campaignId,
                        userFavourite?.userFavouriteId
                      );
                    },
                    1000,
                    { trailing: false }
                  )}
                />
              )}
            </ProductThumbnail>
          </Spacing>
        </div>
      </GridItem>
    );
  }

  render() {
    const {
      activeFilters,
      location,
      tag,
      tags,
      seoTitle,
      items,
      itemsCount,
      isLoading,
      isLoadingMore,
      isGarmentPage,
      garmentType,
      initialActiveFilterSets,
      featuredCampaigns
    } = this.props;

    const {
      filtersToggleIsVisible,
      filtersMenuIsVisible,
      showAuthModal
    } = this.state;

    const hasSearchQuery = !!location.query?.q;

    const activeFiltersCount = getActiveFiltersCount(
      omit(activeFilters, 'sort')
    );
    const showActiveFiltersMessage = activeFiltersCount > 0 && !isGarmentPage;

    return (
      <Page title={seoTitle}>
        <Concierge />
        <ModalNew
          isOpen={showAuthModal}
          handleClose={() => this.setState({ showAuthModal: false })}
        >
          <AuthenticationContainer
            initialUserStage="signUp"
            onSuccess={this.handleAuthModalSuccess.bind(this)}
          />
        </ModalNew>
        <PageWrap className="no-overflow-anchor">
          <div className="relative">
            {hasSearchQuery ? (
              <SearchHeading
                query={location.query?.q}
                itemsCount={itemsCount}
              />
            ) : (
              <ShopCollections
                collections={isGarmentPage ? garmentTypes : tags}
                activeCollection={isGarmentPage ? garmentType : tag}
                onCollectionChange={this.handleCollectionChange}
              />
            )}

            <Spacing size={4} position={hasSearchQuery ? 'b' : 'y'}>
              <InlineGrid
                direction="row"
                align="center"
                justify="space-between"
              >
                {hasSearchQuery ? null : (
                  <div className="mr-2">
                    <Paragraph color="grey-dark" size="xxs">
                      {itemsCount
                        ? `${itemsCount} campaign${itemsCount === 1 ? '' : 's'}`
                        : ''}
                    </Paragraph>
                  </div>
                )}
                {filtersToggleIsVisible && (
                  <Button
                    onClick={this.toggleFiltersMenu}
                    kind="ghost"
                    size="small"
                    className="border-0 text-xs"
                  >
                    <div className="flex items-center">
                      {showActiveFiltersMessage
                        ? `${activeFiltersCount} ${pluralise(
                            activeFiltersCount,
                            'filter'
                          )} applied`
                        : 'Filters'}
                      <div style={{ marginBottom: '1px' }}>
                        <Spacing size={1} position="l">
                          <Icon glyph={settingsIcon} width={18} height={13} />
                        </Spacing>
                      </div>
                    </div>
                  </Button>
                )}
              </InlineGrid>
              {showActiveFiltersMessage && (
                <Spacing size={15}>
                  <InlineGrid justify={['start', 'end']}>
                    <Alert kind="info">
                      Some products have multiple styles available
                    </Alert>
                  </InlineGrid>
                </Spacing>
              )}
              {!isEmpty(featuredCampaigns) &&
                featuredCampaigns.length > 2 &&
                !activeFiltersCount && (
                  <Grid gap={[1, 1, 1, 15, 2]} align="start">
                    {featuredCampaigns.map((item, index) =>
                      this.renderGridItem({ ...item, isFeatured: true }, index)
                    )}
                  </Grid>
                )}
              <Swipeable onSwipedLeft={this.handleFiltersMenuOpen}>
                <Grid gap={[0, 2]} align="start">
                  <GridItem columnSize={12}>
                    {isLoading ? (
                      <Loading />
                    ) : (
                      <Grid gap={[1, 1, 1, 15, 2]} align="start">
                        {items.length > 0 ? (
                          items.map((item, index) =>
                            this.renderGridItem(item, index)
                          )
                        ) : (
                          <GridItem columnSize={12}>
                            <Spacing>
                              <Paragraph center>
                                No campaign matches your criteria. Try to search
                                something else.
                              </Paragraph>
                            </Spacing>
                          </GridItem>
                        )}
                      </Grid>
                    )}
                  </GridItem>
                </Grid>
              </Swipeable>
            </Spacing>
            {items.length <= itemsCount - 1 && (
              <Spacing size={2}>
                <InlineGrid justify="center">
                  <Button
                    onClick={(e) => this.handleLoadMore(e)}
                    state={isLoadingMore ? 'loading' : 'default'}
                    to={{
                      type: location.type,
                      payload: location.payload,
                      query: {
                        ...location.query,
                        offset:
                          location.query && location.query.offset
                            ? Number(location.query.offset) + 15
                            : items.length
                      }
                    }}
                  >
                    <div className="flex items-center">
                      Load more
                      <Spacing size={8} position="l">
                        <Icon glyph={loadingIcon} width={30} height={7} />
                      </Spacing>
                    </div>
                  </Button>
                </InlineGrid>
              </Spacing>
            )}
          </div>

          <div className="md:hidden">
            <Spacing size={8}>
              <hr className="-mx-2 border-grey-lighter" />
            </Spacing>
          </div>

          <Spacing size={[8, 8, 10]}>
            <ShopRecentlyViewed />
          </Spacing>

          <Spacing size={8}>
            <Cta icon={emailIcon} kind="newsletter">
              Stay updated: new drops, recommendations, long reads and more
            </Cta>
          </Spacing>
        </PageWrap>
        <RemoveScroll enabled={filtersMenuIsVisible}>
          <AnimatePresence exitBeforeEnter>
            {filtersMenuIsVisible && (
              <ShopFilters
                options={{
                  sort: sortings,
                  productCategories: isGarmentPage
                    ? null
                    : getEnabledFilterOptions(filters.productCategories),
                  productFits: getEnabledFilterOptions(filters.productFits),
                  productPrintPositions: getEnabledFilterOptions(
                    filters.productPrintPositions
                  ),
                  productSizes: getEnabledFilterOptions(filters.productSizes),
                  productColours: getEnabledFilterOptions(
                    filters.productColours
                  )
                }}
                defaultFilters={{
                  sort:
                    tag === defaultTag.value
                      ? sortings.find((item) => item.default).value
                      : 'sales',
                  ...initialActiveFilterSets
                }}
                onClose={this.handleFiltersMenuClose}
                onSubmit={this.handleFiltersMenuSubmit}
              />
            )}
          </AnimatePresence>
        </RemoveScroll>
      </Page>
    );
  }
}

const mapStateToProps = ({ location, pages, shop, user, discovery }) => {
  const data = get(pages.shop, 'data', {});
  const isGarmentPage = some(garmentTypes, ['slug', location.payload.tag]);
  const garmentType = isGarmentPage ? location.payload.tag : null;
  const initialActiveFilterSets = {
    ...initialFilterSets,
    productCategories: isGarmentPage
      ? { [garmentType]: true }
      : initialFilterSets.productCategories
  };
  const tag = get(location, 'query.q')
    ? null
    : isGarmentPage
    ? config.shop.garmentPageTag.value
    : location.payload.tag || defaultTag.value;

  const items = tag
    ? shop.list.byTag[tag]
      ? shop.list.byTag[tag].items
      : []
    : shop.list.items;
  const featuredCampaigns = discovery.featuredCampaigns.byTag[tag]
    ? discovery.featuredCampaigns.byTag[tag].campaigns
    : [];
  const itemsCount = tag
    ? shop.list.byTag[tag]
      ? shop.list.byTag[tag].itemsCount
      : null
    : shop.list.itemsCount;
  const tags = buildTags(shop.tags);
  return {
    location,
    tag,
    tags,
    offset: get(location, 'query.offset'),
    sort: get(location, 'query.q')
      ? null
      : tag === defaultTag.value
      ? get(location, 'query.sort', sortings[0].value)
      : 'sales',
    seoTitle: get(data, 'seo_title'),
    items,
    itemsCount,
    activeFilters: shop.activeFilters,
    isLoading: shop.list.isLoading,
    isLoadingMore: shop.list.isLoadingMore,
    prevScroll: shop.scroll,
    favourites: user.favourites.list,
    userToken: user.token,
    isGarmentPage,
    garmentType,
    initialActiveFilterSets,
    featuredCampaigns
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(shopActions, dispatch),
  ...bindActionCreators(userActions, dispatch),
  ...bindActionCreators(discoveryActions, dispatch),
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => (
  <ScrollTrackerContext.Consumer>
    {(value) => <Shop {...props} scroll={value} />}
  </ScrollTrackerContext.Consumer>
));
