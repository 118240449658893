import React from 'react';
import { useDispatch } from 'react-redux';
import partition from 'lodash/partition';

import config from '../../../config';

import { garmentTypes } from './garmentTypes';

import { servicesItems } from '../Header/HeaderNew/menuItemsConfig';

import Heading from '../../atoms/Heading';
import Icon from '../../atoms/Icon';
import Link from '../../atoms/Link';
import Paragraph from '../../atoms/Paragraph';
import Spacing from '../../atoms/Spacing';

import { AccordionElement, AccordionManager } from '../Accordion';

import generalStyles from './style/general.module.css';

import shiningIcon from '../../../img/sprites/shining-fill.svg';

const MegaMenuMobile = ({ collections = [] }) => {
  const dispatch = useDispatch();
  let [seasonalCollections, mainCollections] = partition(collections, (item) =>
    config.shop.seasonalTags.includes(item.slug)
  );
  const _mainCollections = mainCollections.filter(
    (item) => item.slug !== config.shop.defaultTag.value
  );
  const slicedCollections = _mainCollections.slice(0, 6);

  const trackMegaMenuClick = (name, slug) => {
    dispatch({
      type: 'MEGA_MENU_CLICKED',
      payload: {
        type: 'Mobile',
        name,
        slug
      }
    });
  };

  return (
    <>
      <AccordionManager>
        <AccordionElement
          label="Shop by"
          size="s"
          arrows
          labelColumnSize={12}
          className={generalStyles.levelOneItemMobile}
        >
          <AccordionManager>
            <AccordionElement
              label="Collections"
              labelColumnSize={12}
              size="m"
              arrows
              className={generalStyles.levelTwoItemMobile}
            >
              <ul className={generalStyles.levelThreeItemMobile}>
                {slicedCollections.map((collection) => (
                  <li
                    key={collection.slug}
                    onClick={() =>
                      trackMegaMenuClick(collection.name, collection.slug)
                    }
                  >
                    <Spacing size={1} position="y" type="padding">
                      <Link
                        to={`/shop/${collection.slug}`}
                        isUnderlined={false}
                      >
                        <Paragraph
                          size="xxs"
                          className={generalStyles.collectionName}
                        >
                          {collection.name}
                        </Paragraph>
                      </Link>
                    </Spacing>
                  </li>
                ))}
              </ul>
            </AccordionElement>
          </AccordionManager>

          <AccordionManager>
            <AccordionElement
              label="Products"
              size="m"
              arrows
              labelColumnSize={12}
              className={generalStyles.levelTwoItemMobile}
            >
              <ul className={generalStyles.levelThreeItemMobile}>
                {garmentTypes.map((garment) => (
                  <li
                    key={garment.slug}
                    onClick={() =>
                      trackMegaMenuClick(garment.name, garment.slug)
                    }
                  >
                    <Spacing size={1} position="y" type="padding">
                      <Link to={`/shop/${garment.slug}`} isUnderlined={false}>
                        <Paragraph size="xxs">{garment.name}</Paragraph>
                      </Link>
                    </Spacing>
                  </li>
                ))}
              </ul>
            </AccordionElement>
          </AccordionManager>

          <AccordionManager>
            <AccordionElement
              label={
                <Heading size="xs" className="leading-normal">
                  <span className="inline-flex items-center gap-3">
                    Gifting
                    <Icon
                      glyph={shiningIcon}
                      width={10}
                      height={10}
                      className="text-black"
                    />
                  </span>
                </Heading>
              }
              labelColumnSize={12}
              size="m"
              arrows
              className={generalStyles.levelTwoItemMobile}
            >
              <ul className={generalStyles.levelThreeItemMobile}>
                {seasonalCollections.map((collection) => (
                  <li
                    key={collection.slug}
                    onClick={() =>
                      trackMegaMenuClick(collection.name, collection.slug)
                    }
                  >
                    <Spacing size={1} position="y" type="padding">
                      <Link
                        to={`/shop/${collection.slug}`}
                        isUnderlined={false}
                      >
                        <Paragraph
                          size="xxs"
                          className={generalStyles.collectionName}
                        >
                          {collection.name}
                        </Paragraph>
                      </Link>
                    </Spacing>
                  </li>
                ))}
                <li
                  onClick={() => trackMegaMenuClick('Gift Cards', 'gift-cards')}
                >
                  <Spacing size={1} position="y" type="padding">
                    <Link to="/gift-cards" isUnderlined={false}>
                      <Paragraph size="xxs">Gift Cards</Paragraph>
                    </Link>
                  </Spacing>
                </li>
              </ul>
            </AccordionElement>
          </AccordionManager>
        </AccordionElement>
      </AccordionManager>

      <AccordionManager>
        <AccordionElement
          label="Services"
          labelColumnSize={12}
          size="m"
          arrows
          className={generalStyles.levelOneItemMobile}
        >
          <ul className={generalStyles.levelTwoItemMobile}>
            {servicesItems.map((item) => {
              if (item.to === '/create') return null;

              return (
                <li
                  key={item.to}
                  onClick={() => trackMegaMenuClick(item.title, item.to)}
                >
                  <Spacing size={2} position="y" type="padding">
                    <Link to={item.to} isUnderlined={false} noHover>
                      <Paragraph size="xs" className="uppercase">
                        {item.title}
                      </Paragraph>
                    </Link>
                  </Spacing>
                </li>
              );
            })}
          </ul>
        </AccordionElement>
      </AccordionManager>
    </>
  );
};

export default MegaMenuMobile;
