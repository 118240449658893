import React from 'react';
import PropTypes from 'prop-types';

import Heading from '../../../atoms/Heading';
import Spacing from '../../../atoms/Spacing';
import Paragraph from '../../../atoms/Paragraph';
import { CSSGrid, CSSGridItem } from '../../../atoms/Grid';

import FeatureCard from './FeatureCard';

export default function FeatureCardGrid({
  title,
  subtitle,
  cards,
  noBorderRadius
}) {
  const fullWidth = cards.length === 1;
  const compact = cards.length > 4;
  const columns = fullWidth
    ? ['1fr', '1fr']
    : compact
    ? ['1fr', '1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']
    : ['1fr', '1fr', 'repeat(2, 1fr)'];

  return (
    <>
      {!!title ? (
        <Spacing size="2" position="b">
          <Spacing size="05" position="b">
            <Heading size="m">{title}</Heading>
          </Spacing>
          <Paragraph size="xxs" color="grey-dark">
            {subtitle}
          </Paragraph>
        </Spacing>
      ) : null}
      <CSSGrid gap={2} columns={columns}>
        {cards.map((card) => (
          <CSSGridItem
            key={card.card_title}
            row={{ start: 'auto', end: 'auto' }}
            column={{ start: 'auto', end: 'auto' }}
          >
            <FeatureCard
              title={card.card_title}
              subtitle={card.card_subtitle}
              addtionalTitle={card.additional_title}
              cta={card.cta}
              slug={card.slug}
              image={card.image}
              fullWidth={fullWidth}
              noBorderRadius={noBorderRadius}
            />
          </CSSGridItem>
        ))}
      </CSSGrid>
    </>
  );
}

FeatureCardGrid.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      card_title: PropTypes.string,
      card_subtitle: PropTypes.string,
      cta: PropTypes.string,
      slug: PropTypes.string
    })
  ).isRequired,
  noBorderRadius: PropTypes.bool
};
