export const setUtmParams = (params) => (dispatch) =>
  dispatch({
    type: 'SET_UTM_PARAMS',
    payload: params
  });

export const captureLead = (data) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'CAPTURE_LEAD',
    payload: baseApi.post('/lead', data)
  });

export const captureSubmitDesign = (data) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'SUBMIT_YOUR_DESIGN',
    payload: baseApi.post('/lead', data)
  });

export const setInitialLoad = () => (dispatch) => {
  dispatch({
    type: 'SET_INITIAL_LOAD'
  });
};

export const setOrigin = (origin) => (dispatch) => {
  dispatch({
    type: 'SET_ORIGIN',
    payload: origin
  });
};
