import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isPast } from 'date-fns';

import { getFirstErrorWithCode } from '../../../helpers/api';

import { requestRelaunchCampaign } from '../../../actions/campaigns';
import {
  hideProfileCampaign,
  getUserProfileCampaigns
} from '../../../actions/user-profile';

import Alert from '../../../components/atoms/Alert';
import { GridItem } from '../../../components/atoms/Grid';
import Spacing from '../../../components/atoms/Spacing';

import ProductThumbnail from '../../../components/molecules/Thumbnails/ProductThumbnail';
import {
  ContextualMenu,
  ContextualMenuItem
} from '../../../components/molecules/ContextualMenu';

import RelaunchCampaignModal from './RelaunchCampaignModal';
import RelaunchErrorModal from './RelaunchErrorModal';

import hiddenEye from '../../../img/sprites/hidden-eye.svg';

import styles from './styles/general.module.css';

const CampaignTile = ({ isOwner, campaign, profileName }) => {
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isRelaunchModalOpen, setRelaunchModalOpen] = useState(false);
  const [errorOnRelaunch, setErrorOnRelaunch] = useState(null);
  const [swapOnRelaunch, setSwapOnRelaunch] = useState(false);
  const [duplicateProductMessage, setDuplicateProductMessage] = useState('');
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalBody, setErrorModalBody] = useState('');

  const hideCampaign = async () => {
    await dispatch(
      hideProfileCampaign(campaign.campaignId, !campaign.hiddenOnProfile)
    );
    setMenuOpen(false);
  };

  const openRelaunchModal = (hasUnavailableProduct, duplicateMessage) => {
    setRelaunchModalOpen(true);
    setErrorModalOpen(false);
    setErrorOnRelaunch(null);
    setSwapOnRelaunch(hasUnavailableProduct);
    setDuplicateProductMessage(duplicateMessage);
  };

  const closeRelaunchModal = () => {
    setRelaunchModalOpen(false);
  };

  const handleSuccessfulRelaunch = () => {
    dispatch(getUserProfileCampaigns());
    closeRelaunchModal();
    window.scrollTo(0, 0);
  };

  const openErrorModal = (errorMessage) => {
    setRelaunchModalOpen(false);
    setErrorModalOpen(true);
    setErrorModalBody(errorMessage);
  };

  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };

  const requestRelaunch = async () => {
    try {
      await dispatch(requestRelaunchCampaign(campaign.campaignId));

      openRelaunchModal(false);
    } catch (err) {
      const error = getFirstErrorWithCode(err);
      switch (error.code) {
        case 'e1000':
          return openErrorModal(error.message);
        case 'e1001':
          return openRelaunchModal(true, error.message);
        default: {
          return openErrorModal('Please try again later.');
        }
      }
    }
  };

  const thumbnailProps = isOwner
    ? {
        // sold: campaign.sold,
        // profit: campaign.profit,
        // status: campaign.status
        subTitle: campaign.subTitle || profileName,
        prices: campaign.prices
      }
    : {
        subTitle: campaign.subTitle || profileName,
        prices: campaign.prices
      };

  return (
    <GridItem key={campaign.slug} columnSize={[12, 12, 4, 4, 33, 4]}>
      <Spacing size={2}>
        <ProductThumbnail
          slug={campaign.slug}
          title={campaign.title}
          image={campaign.imageUrl}
          alt={campaign.imageAlt}
          endAt={campaign.endAt}
          isOwner={isOwner}
          price={campaign.prices}
          isGreyBg={true}
          timeLeft={!campaign.printOnDemand ? campaign.endAt : ''}
          {...thumbnailProps}
        >
          {isOwner && campaign.hiddenOnProfile ? (
            <div className={styles.hiddenOverlay}>
              <Alert kind="info" message="Campaign Hidden" icon={hiddenEye} />
            </div>
          ) : null}
          {isOwner ? (
            <ContextualMenu
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              menuPosition={{ top: '4rem', right: '0' }}
              buttonClassName="right-0"
            >
              <ContextualMenuItem
                label={
                  campaign.hiddenOnProfile ? 'Show Campaign' : 'Hide Campaign'
                }
                onClick={hideCampaign}
              />
              {isPast(campaign.endAt) ? (
                <ContextualMenuItem
                  label="Relaunch"
                  onClick={requestRelaunch}
                />
              ) : null}
            </ContextualMenu>
          ) : null}
        </ProductThumbnail>
      </Spacing>

      <RelaunchCampaignModal
        isOpen={isRelaunchModalOpen}
        onClose={closeRelaunchModal}
        onSuccess={handleSuccessfulRelaunch}
        campaignId={campaign.campaignId}
        swapOnRelaunch={swapOnRelaunch}
        duplicateProductMessage={duplicateProductMessage}
        errorOnRelaunch={errorOnRelaunch}
        handleErrorOnRelaunch={setErrorOnRelaunch}
      />

      <RelaunchErrorModal
        isOpen={isErrorModalOpen}
        onClose={closeErrorModal}
        errorBody={errorModalBody}
      />
    </GridItem>
  );
};

export default CampaignTile;
