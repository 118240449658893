import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import Page from '../../components/utils/Page';
import PageWrap from '../../components/atoms/PageWrap';
import Spacing from '../../components/atoms/Spacing';
import Heading from '../../components/atoms/Heading';
import Paragraph from '../../components/atoms/Paragraph';
import MaxWidth from '../../components/atoms/MaxWidth';
import Link from '../../components/atoms/Link';
import LiveChat from '../../components/atoms/LiveChat';

import Creators from './Creators';
import Buyers from './Buyers';

import logotype from '../../img/everpress-logotype.svg';

import generalStyles from './style/general.module.css';
import switchStyles from './style/switch.module.css';

const cx = classNames.bind({
  ...generalStyles,
  ...switchStyles
});

class HowItWorks extends Component {
  render() {
    const { currentTab } = this.props;

    return (
      <Page title="Create and Sell Your Own T-Shirts">
        <PageWrap>
          <Spacing position="t">
            <div className={cx('hero')}>
              <Heading tag="h1" size="m">
                How
                <span className="py-2 block flex justify-center">
                  <img src={logotype} width={230} alt="Everpress" />
                </span>
                works for
              </Heading>
              <Spacing size={3}>
                <div className={cx('container')}>
                  <Link
                    to={{
                      type: 'HOW_IT_WORKS',
                      payload: { category: 'creators' }
                    }}
                    className={cx('switch', {
                      switchActive: currentTab === 'creators'
                    })}
                    isUnderlined={false}
                    noHover
                  >
                    Creators
                  </Link>
                  <Link
                    to={{
                      type: 'HOW_IT_WORKS',
                      payload: { category: 'buyers' }
                    }}
                    className={cx('switch', {
                      switchActive: currentTab === 'buyers'
                    })}
                    isUnderlined={false}
                    noHover
                  >
                    Buyers
                  </Link>
                </div>
              </Spacing>
              <MaxWidth center value={645}>
                {currentTab === 'creators' ? (
                  <Paragraph size="xs">
                    Everpress is for independent creatives. By offering our
                    tools to sell, manufacture and distribute your creations;
                    we’re changing the way things get made. No risk. No waste.
                  </Paragraph>
                ) : (
                  <Paragraph size="xs">
                    On Everpress you’ll find a curated marketplace of truly
                    original designs, printed with ethics and quality you can
                    trust. We aren’t any ordinary online shop though; to
                    minimise waste and risk for our creators we only print as
                    many items as are sold.
                  </Paragraph>
                )}
              </MaxWidth>
            </div>
          </Spacing>

          {currentTab === 'creators' ? <Creators /> : <Buyers />}
        </PageWrap>
        <LiveChat />
      </Page>
    );
  }
}

const mapStateToProps = ({ location }) => ({
  currentTab: location.payload.category || 'creators'
});

export default connect(mapStateToProps)(HowItWorks);
