import produce from 'immer';

const initialState = {
  settings: {}
};

export default (state = initialState, action) =>
  produce(state, newState => {
    const { type, payload } = action;

    switch (type) {
      case 'GET_SETTINGS_FULFILLED':
        newState.settings = {
          ...payload
        };

        return newState;
      default:
        return state;
    }
  });
