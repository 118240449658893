import React, { useState, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames/bind';

import { CSSGrid, InlineGrid } from '../../../components/atoms/Grid';
import Heading from '../../../components/atoms/Heading';
import Icon from '../../../components/atoms/Icon';
import Paragraph from '../../../components/atoms/Paragraph';

import everpressLogo from '../../../img/everpress-logo.svg';

import generalStyles from './style/general.module.css';

import platforms from './featuredPlatforms';

import coverImg from '../../../img/pages/connect/cover.jpg';

const cx = classNames.bind({ ...generalStyles });

const Hero = ({ ...props }) => {
  const [tickerItemIndex, setTickerItemIndex] = useState(0);
  const containerRef = useRef();

  useEffect(() => {
    const interval = setInterval(() => {
      setTickerItemIndex((tickerItemIndex) =>
        tickerItemIndex === platforms.length - 1 ? 0 : tickerItemIndex + 1
      );
    }, 2500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={cx('container')} ref={containerRef} {...props}>
      <div
        className={cx('background')}
        style={{
          backgroundImage: `url(${coverImg})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          filter: 'brightness(0.8)'
        }}
      />
      <CSSGrid
        className={cx('hero')}
        template={['20% auto 20% / 1fr', '20% auto 20% / 1fr', '1fr / 1fr auto 1fr']}
        gap={3}
        justifyContent="center"
        justify="center"
        alignContent="center"
        align="center"
      >
        <div className="md:justify-self-end">
          <InlineGrid justify="start">
            <img
              src={everpressLogo}
              alt="Everpress logo"
              width="50"
              className="mr-2 md:mr-3"
            />
            <Heading className="justify-self-end" size="m">
              Everpress
            </Heading>
          </InlineGrid>
        </div>
        <span className="px-2">&#10005;</span>
        <ul className={cx('ticker')}>
          <AnimatePresence exitBeforeEnter>
            <motion.li
              key={tickerItemIndex}
              transition={{ type: 'tween', duration: 0.5 }}
              initial={{ opacity: 0, y: '-100%' }}
              animate={{ opacity: 1, y: '0%' }}
              exit={{ opacity: 0, y: '100%' }}
              className={cx('item')}
            >
              <InlineGrid justify={['center', 'center', 'start']}>
                <Icon
                  glyph={platforms[tickerItemIndex].icon}
                  width={50}
                  height={50}
                  className="mr-2 md:mr-3"
                />
                <Paragraph size="s">
                  {platforms[tickerItemIndex].title}
                </Paragraph>
              </InlineGrid>
            </motion.li>
          </AnimatePresence>
        </ul>
      </CSSGrid>
    </div>
  );
};

export default Hero;
