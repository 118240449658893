import produce from 'immer';
import merge from 'lodash/merge';

const initialState = {
  theme: {
    color: 'dark',
    logo: 'default',
    isCartBtn: true,
    isVisible: true,
    isFixed: true
  },
  navItems: [
    {
      name: 'Shop',
      to: { type: 'SHOP' }
    },
    {
      name: 'Design Your Own',
      to: '/create',
      reRouteMobile: { type: 'CREATOR_MOBILE' }
    },
    {
      name: 'How It Works',
      to: '/how-it-works'
    },
    {
      name: 'How You Profit',
      to: { type: 'PRICE_CALCULATOR' }
    },
    {
      name: 'Blog',
      href: '/blog'
    },
    {
      name: 'Sign In',
      to: { type: 'LOGIN' },
      userRole: 'visitor'
    },
    {
      name: 'Dashboard',
      to: { type: 'DASHBOARD' },
      userRole: 'member'
    }
  ]
};

export default (state = initialState, action) =>
  produce(state, (newState) => {
    // If it's a route change
    if (action.meta && action.meta.location) {
      // Reset to initial state
      merge(newState, initialState);

      if (action.type === 'ORDER' || action.type.match(/DASHBOARD_/)) {
        newState.theme.isVisible = false;
      }

      if (action.type === 'CHECKOUT') {
        newState.navItems = [];
        newState.theme.isFixed = false;
      } else {
        newState.navItems = initialState.navItems;
        newState.theme.isFixed = initialState.theme.isFixed;
      }

      if (action.type === 'CAMPAIGN_OR_STORE') {
        let navItems = [];

        if (action.payload.slug_1) {
          navItems.push({
            name: 'Back to Store',
            to: `/${action.payload.slug_1}`
          });
        } else if (action.meta.location.prev.type === 'SHOP') {
          navItems.push({
            name: 'Back to shop',
            to: {
              type: 'SHOP',
              ...(action.meta.location.prev.payload.tag && {
                payload: {
                  tag: action.meta.location.prev.payload.tag
                }
              }),
              ...(action.meta.location.prev.query && {
                query: action.meta.location.prev.query
              })
            }
          });
        } else if (action.meta.location.prev.type === 'FIFTY_FIFTY') {
          navItems.push({
            name: 'Back to 50-50',
            to: { type: 'FIFTY_FIFTY' }
          });
        }

        newState.theme.logo = null;
        newState.navItems = navItems;
      }

      switch (action.type) {
        case 'HOME':
        case 'ABOUT':
        case 'SHOP':
        case 'FIFTY_FIFTY':
        case 'SUPPORT_CATEGORY':
        case 'CASE_STUDY':
        case 'LANDING':
          newState.theme.color = 'light';
          break;
        case 'SUSTAINABILITY':
        case 'BCORP':
        case 'SAVE_OUR_SPACES':
          newState.theme.color = 'pastel-grey';
          break;
        default:
          break;
      }

      // Show the Announcement component on the following pages
      switch (action.type) {
        case 'HOME':
          newState.theme.withAnnouncement = false;
          newState.theme.announcementMessage = '';
          break;
        case 'SHOP':
          newState.theme.withAnnouncement = false;
          newState.theme.announcementMessage = '';
          break;
        case 'CAMPAIGN_OR_STORE':
          // Announcements are handled in the Campaign component
          break;
        default:
          newState.theme.withAnnouncement = false;
          newState.theme.announcementMessage = '';
          break;
      }

      return newState;
    }

    if (action.type === 'STORE_RECEIVED') {
      newState.theme.logo = action.payload.logoUrl ? 'store' : null;
    }

    if (action.type === 'EDIT_ANNOUNCEMENT_MESSAGE') {
      newState.theme.withAnnouncement = !!action.payload;
      newState.theme.announcementMessage = action.payload;
    }

    return newState;
  });
