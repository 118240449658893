import React from 'react';

import image1 from '../../img/pages/how-it-works/creators-1.jpg';
import image2 from '../../img/pages/how-it-works/creators-2.jpg';
import image3 from '../../img/pages/how-it-works/creators-3.jpg';
import image4 from '../../img/pages/how-it-works/creators-4.jpg';

const creatorsItems = [
  {
    id: 0,
    title: 'Upload your work',
    copy: 'Upload your design to Everpress and we’ll start selling in minutes.',
    image: image1
  },
  {
    id: 1,
    title: 'Choose your garment, set a price',
    copy:
      'Choose from a wide range of high quality garments, suggest a retail price and launch an Everpress campaign.',
    image: image2
  },
  {
    id: 2,
    title: 'Tell your friends and followers',
    copy:
      'Share with your fans and followers and generate pre-orders for the duration of the campaign. 30 days is the maximum. But short and sweet works well too.',
    image: image3
  },
  {
    id: 3,
    title: 'We sell and you get paid',
    copy: (
      <>
        <span className="block">
          We manufacture all of your orders, and ship directly to your fans
          worldwide. Enter your payment details, and we’ll pay you when the
          campaign ends.
        </span>
        <span className="block my-1">
          There’s no limit on how many you can sell, but some designs and
          products have to reach a bespoke minimum order quantity to go to
          print.
        </span>
      </>
    ),
    image: image4,
    buttons: [
      {
        id: 0,
        text: 'Visit our Faqs',
        to: '/creator-toolkit'
      },
      {
        id: 1,
        text: 'Calculate your earnings',
        to: '/price-calculator'
      }
    ]
  }
];

export default creatorsItems;
