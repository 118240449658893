import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';

import generalStyles from './styles/general.module.css';
import sizeStyles from './styles/size.module.css';

const cx = classNames.bind({
  ...generalStyles,
  ...sizeStyles
});

const marginMap = {
  s: {
    size: '05',
    position: 'b'
  },
  m: {
    size: 3,
    position: 'y'
  }
};

const Card = ({ imageSrc, caption, title, subtitle, size }) => {
  return (
    <div className={cx('card', size)}>
      {imageSrc && (
        <div
          className={generalStyles.image}
          style={{ backgroundImage: `url(${imageSrc})` }}
          alt={caption || title}
        />
      )}
      {caption && (
        <Paragraph size="xxs" center className="uppercase mt-1">
          {caption}
        </Paragraph>
      )}
      <Spacing position={marginMap[size].position} size={marginMap[size].size}>
        <Paragraph size={['s', 's', 's', 'm']} center className="uppercase">
          {title}
        </Paragraph>
      </Spacing>
      <Paragraph size="xs" center>
        {subtitle}
      </Paragraph>
    </div>
  );
};

Card.propTypes = {
  imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  caption: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.keys(sizeStyles))
};

Card.defaultProps = {
  size: 'm'
};

export default Card;
