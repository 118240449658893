import React from 'react';
import * as yup from 'yup';

import { ButtonNew as Button } from '../../../atoms/Button';
import {
  DefaultField as Field,
  Form,
  handleFormError
} from '../../../atoms/Form';
import Heading from '../../../atoms/Heading';
import Link from '../../../atoms/Link';
import Paragraph from '../../../atoms/Paragraph';
import Spacing from '../../../atoms/Spacing';

const ResetPasswordForm = ({ onResetPassword, onUserStageChange }) => {
  const handleResetPassword = async ({ email }, actions) => {
    actions.setSubmitting(true);

    try {
      await onResetPassword({ email });
    } catch (error) {
      handleFormError(error, actions);
    }
    actions.setSubmitting(false);
  };

  return (
    <>
      <Heading size="xs">Reset Password</Heading>
      <Spacing size={1} position="t">
        <Paragraph size="xxs">
          Enter your email to receive instructions on how to reset your
          password.
        </Paragraph>
      </Spacing>
      <Form
        initialValues={{
          email: ''
        }}
        validationSchema={yup.object().shape({
          email: yup.string().required()
        })}
        onSubmit={handleResetPassword}
        render={({ isSubmitting, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="text-left">
                <Spacing size={2} position="t">
                  <Spacing size={1}>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email address"
                      data-test-id="email"
                    />
                  </Spacing>
                </Spacing>
              </div>
              <Button
                type="submit"
                state={isSubmitting ? 'loading' : null}
                fullWidth
              >
                Reset
              </Button>
            </form>
          );
        }}
      />
      <Spacing size={2} position="t">
        <Paragraph color="grey-dark" size="xxs">
          Return to{' '}
          <Link
            isUnderlined
            onClick={() => onUserStageChange('signIn')}
            className="text-xxs text-black"
            data-test-id="auth-sign-in"
          >
            Sign in
          </Link>
        </Paragraph>
      </Spacing>
    </>
  );
};

export default ResetPasswordForm;
