import React from 'react';
import PropTypes from 'prop-types';

import Heading from '../../../../components/atoms/Heading';
import Paragraph from '../../../../components/atoms/Paragraph';

import generalStyles from './style/general.module.css';

const Step = ({ title, description, isActive }) => {
  const color = isActive ? 'black' : 'grey-primary';

  return (
    <div className={generalStyles.step}>
      <Paragraph color={color} size="xxs">
        {title}
      </Paragraph>
      <Heading className="leading-normal" tag="h4" color={color} size="xxs">
        {description}
      </Heading>
    </div>
  );
};

Step.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isActive: PropTypes.bool
};

export default Step;
