export const getUserOrders = options => (dispatch, _, { baseApi }) => {
  const defaultOptions = {
    offset: 0,
    limit: 5,
    state: null,
    sort: 'createdAt',
    direction: 'DESC'
  };

  const params = { ...defaultOptions, ...options };

  return dispatch({
    type: 'GET_USER_ORDERS',
    payload: baseApi.get(`/user/orders`, { params })
  });
};

export const getUserOrder = orderId => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'GET_USER_ORDER',
    payload: baseApi.get(`/user/order/${orderId}`)
  });

export const editUserOrderItem = (orderId, orderItemId, data) => (
  dispatch,
  _,
  { baseApi }
) =>
  dispatch({
    type: 'EDIT_USER_ORDER_ITEM',
    payload: baseApi.put(`/user/order/${orderId}/item/${orderItemId}`, data),
    meta: { orderId, orderItemId, data }
  });
