export const initialsPropTypes = (props, propName, componentName) => {
  if (props.initials && typeof props.initials !== 'string') {
    return new Error(`${propName} must be of type string in ${componentName}`);
  }

  if (props.initials && props.initials.length > 2) {
    return new Error(
      `${propName} must not exceed 2 letters in ${componentName}`
    );
  }
};
