import React from 'react';

import chunk from 'lodash/chunk';

import PageWrap from '../../../atoms/PageWrap';
import Image from '../../../atoms/Image';
import Spacing from '../../../atoms/Spacing';
import Heading from '../../../atoms/Heading';
import Paragraph from '../../../atoms/Paragraph';
import MaxWidth from '../../../atoms/MaxWidth';
import { Grid, GridItem } from '../../../atoms/Grid';

import generalStyles from './style/general.module.css';

const HowItWorks = ({ title, copy, steps }) => {
  return (
    <PageWrap className={generalStyles.container}>
      <Spacing size={[2, 3, 4, 5, 6]} type="padding">
        <Spacing size={2} position="b">
          <Heading size={['l', 'xl', 'xxl']} center tag="h2">
            {title}
          </Heading>
        </Spacing>
        <Spacing size={[2, 4, 5]} position="b">
          <MaxWidth center value={420}>
            <Paragraph center>{copy}</Paragraph>
          </MaxWidth>
        </Spacing>
        {chunk(steps, 2).map((groupOfSteps, groupIndex) => (
          <div key={groupIndex} className={generalStyles.table}>
            {groupOfSteps.map((step, index) => (
              <div key={index} className={generalStyles.tableItem}>
                <Grid gap={2} align={['center', 'start']}>
                  <GridItem columnSize={[12, 12, 7]}>
                    <Spacing size={2} position="b">
                      <Heading tag="h2" size={['xl', 'xxl']}>
                        {steps.indexOf(step) < 10
                          ? '0' + (steps.indexOf(step) + 1)
                          : steps.indexOf(step)}
                      </Heading>
                    </Spacing>
                    <MaxWidth value={350}>
                      <Spacing size={2} position="b">
                        <Heading size="m" tag="h3">
                          {step.title}
                        </Heading>
                      </Spacing>
                      <Paragraph>{step.description}</Paragraph>
                    </MaxWidth>
                  </GridItem>
                  <GridItem columnSize={[12, 12, 5]}>
                    <Spacing size={[3, 3, 0]} position="t">
                      <div
                        className={
                          groupIndex === 0 && index === 0
                            ? generalStyles.image_large
                            : undefined
                        }
                      >
                        <Image
                          src={step.image.url}
                          alt={step.image.alt}
                          width={step.image.width}
                          height={step.image.height}
                          withPlaceholder
                        />
                      </div>
                    </Spacing>
                  </GridItem>
                </Grid>
              </div>
            ))}
          </div>
        ))}
      </Spacing>
    </PageWrap>
  );
};

export default HowItWorks;
