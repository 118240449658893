export function get(key) {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (err) {
    return err;
  }
}

export function set(key, value) {
  // We wrap in a try/catch because Safari 10 has weird
  // behaviour with local storage and crashes in anonymous mode
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    return err;
  }
}

export function transform(key, value) {
  try {
    const localStorageValue = window.localStorage.getItem(key);
    const state = JSON.parse(localStorageValue);
    const valueToStore = value instanceof Function ? value(state) : value;

    window.localStorage.setItem(key, JSON.stringify(valueToStore));
  } catch (error) {
    return undefined;
  }
}
