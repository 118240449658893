import React from 'react';
import PropTypes from 'prop-types';

import { InlineGrid } from '../../../components/atoms/Grid';
import Icon from '../../../components/atoms/Icon';
import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';

import donationIcon from '../../../img/sprites/donation.svg';

const DEFAULT_MESSAGE =
  'We donate £1 to the creator’s chosen cause with each sale';

const CampaignDonationInfo = ({ children, ...props }) => {
  return (
    <InlineGrid justify="start" align={['start', 'start', 'center']} {...props}>
      <Spacing size="05" position="r">
        <Icon glyph={donationIcon} width={22} height={22} />
      </Spacing>
      <Paragraph size="xxs">{children}</Paragraph>
    </InlineGrid>
  );
};

export default CampaignDonationInfo;

CampaignDonationInfo.defaultProps = {
  children: DEFAULT_MESSAGE
};

CampaignDonationInfo.propTypes = {
  children: PropTypes.node
};
