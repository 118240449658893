const termsSellers = [
  {
    sections: [
      {
        type: 'paragraph',
        content:
          'Everpress.com (“our site”) is a site  where creators can submit designs for application by us to products sold by us to buyers on our site. This page (together with the documents expressly referred to in it) tells you information about us and the legal terms and conditions (Terms) on which you license to us your design to us, and we provide you with access to the Everpress campaign management tools to you to create campaigns for the promotion of products bearing your designs, to be sold by us. The products bearing your designs are sold by us, Everpress Limited, to consumers using our site. We sell the products as principal on our own behalf, and not as your agent. The contract for sale of the products bearing your design is between us and the buyer. We are Everpress Ltd, a limited company registered in England and Wales under company number 09419903, whose registered office is at 55a Dalston Lane, Dalston, London, United Kingdom, E8 2NG. Our UK VAT number is 219254804.'
      },
      {
        type: 'paragraph',
        content:
          'These Terms will apply to any contract between us for the licensing by you to us of your designs (Contract). Please read these Terms carefully and make sure that you understand them, before registering on our site. Please note that by registering on our site, you agree to be bound by these Terms and the other documents expressly referred to in them.'
      },
      {
        type: 'paragraph',
        content:
          'Please be aware that clause 11 puts limits on our liability to you.'
      },
      {
        type: 'paragraph',
        content:
          'IMPORTANT: Before you upload a design, you must make sure it complies with our Artwork Guidelines, outlined here: <a href="/creator-toolkit/a-guide-to-intellectual-property-infringement/">"Guidance for creators on our Intellectual Property Policy"</a> and <a href="/creator-toolkit/our-content-review-policy/">"Content Review Policy"</a>.'
      },
      {
        type: 'paragraph',
        content:
          'Please click on the button marked "I Accept" when submitting your registration if you accept these Terms. If you refuse to accept these Terms, you will not be able to register on our site.'
      },
      {
        type: 'paragraph',
        content:
          'You should print a copy of these Terms or save them to your computer for future reference.'
      },
      {
        type: 'paragraph',
        content:
          'Please note that these Terms, and any Contract between us, are only in the English language.'
      }
    ]
  },
  {
    title: '1. Information about us',
    sections: [
      {
        type: 'paragraph',
        content:
          '<b>1.1</b> We operate our site. We are Everpress Limited, a limited company registered in England and Wales under company number 09419903 and with our registered office and main trading address at Unit 2, 55 Dalston Lane, London E8 2NG. Our UK VAT number is 2192548 04.'
      },
      {
        type: 'paragraph',
        content:
          '<b>1.2</b> To contact us, please see our homepage where you will find our contact details.'
      }
    ]
  },
  {
    title: '2. Interpretation',
    sections: [
      {
        type: 'paragraph',
        content:
          '<b>2.1</b> Definitions. In these Terms, the following definitions apply:'
      },
      {
        type: 'paragraph',
        content:
          '<b>Promotional Network:</b> Everpress Shop and platform, third party social media and other advertising media by means of which, if you opt-in, we may utilise to promote the Campaign Products.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Promotional Network Creator Fee:</b> the Creator Fee payable by us to you in respect of Promotional Network Sales, being the amount of Creator Fee payable by us to you in respect of sales of Campaign Products which are not Promotional Network Sales, minus a sum equal to 50% of your Creator Fee from any individual and specific Promotional Network Sale.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Promotional Network Sales:</b> sales of Campaign Products arising from a Buyer arriving from an Promotional Network URL.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Promotional Network URL:</b> a URL dedicated to visitors to our site from a particular part of the Promotional Network, which denotes that a Buyer entering our site from that URL has done so from the Promotional Network, and that the Promotional Network Creator Fee will apply to any purchase by that Buyer of Campaign Products.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Brand Elements:</b> any brand, image, trade mark, or graphic work which you include in a Design or request us to include in a Design by specifying it to us in a brief for a Design Enhancement.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Business Day:</b> a day (other than a Saturday, Sunday or public holiday) when banks in London are open for business.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Buyer:</b> a consumer making a purchase of Campaign Products from us by means of our site.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Buyer Contract:</b> the contract between us and a Buyer for supply of Campaign Products under the Buyer Terms.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Buyer Terms:</b> the terms applicable to sales of Campaign Products by us to buyers, as set out <a href="/terms-for-buyers">here</a>.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Campaign Products:</b> the Products on which the Design has been, or is to be, reproduced and that you wish to promote for sale by us on our site.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Commencement Date:</b> has the meaning set out in clause 3.3.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Contract:</b> the contract between you and us for the provision of the Tools and the licensing to us by you of your Design in accordance with these Terms.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Creator Fee:</b> the amount payable to you by us in relation to each sale of a Campaign Product by us, having the meaning set out in clause 8.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Design:</b> the drawing, design, logo, artwork, photograph, illustration or other image, including any Brand Elements, which you upload via our site (or receive from us following any agreed Design Enhancement) and licence to us for reproduction on the Products.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Design Enhancement:</b> any services we agree to provide to assist you in enhancing the Design. If we agree to provide Design Enhancement, it forms part of the Tools.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Intellectual Property Rights:</b> all patents, rights to inventions, utility models, copyright and related rights, trade marks, service marks, trade, business and domain names, rights in trade dress or get-up, rights in goodwill or to sue for passing off, unfair competition rights, rights in designs, rights in computer software, database right, topography rights, moral rights, rights in confidential information (including know-how and trade secrets) and any other intellectual property rights, in each case whether registered or unregistered and including all applications for and renewals or extensions of such rights, and all similar or equivalent rights or forms of protection in any part of the world.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Minimum Order Benchmark:</b> the minimum number of orders for units of the Campaign Products placed by Buyers via our site, as specified by you during Registration for the campaign.'
      },
      {
        type: 'paragraph',
        content:
          '<b>PCI DSS:</b> the payment card industry data security standard, which is a worldwide standard for ensuring the secure processing of card payments and the reduction of card fraud. The standard requires, among other things, that cardholder data and other sensitive information is encrypted when transmitted across public networks.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Products:</b> T-shirts, hooded sweatshirts, sweatshirts, vests and carrying bags, and other items listed on our site as being available for Campaign Products.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Purchase Price:</b> the purchase price for the Campaign Products stated on our site, including VAT (if applicable).'
      },
      {
        type: 'paragraph',
        content:
          '<b>Registration:</b> your registration to use the Tools as set out in your Registration Form.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Registration Form:</b> the online form available on our site, which you complete and submit to us when you register to use the Tools.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Terms:</b> these terms and conditions as amended from time to time in accordance with clause 9.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Tools:</b> the campaign creation and management tools made available by us on website at www.everpress.com (our site) which enable creators to upload the Design to our site for reproduction on the Products, thereby creating the Campaign Products for sale by us, and to set parameters for the promotion of the Campaign Products.'
      },
      {
        type: 'paragraph',
        content:
          '<b>Work:</b> the drawing, design, logo, artwork, photograph, illustration or other image owned by you, which you upload via our site (or receive from us following any agreed Design Enhancement) and licence to us for reproduction on the Products.'
      },
      {
        type: 'paragraph',
        content:
          '<b>2.2</b> Construction. In these Terms, the following rules apply:'
      },
      {
        type: 'paragraph',
        content:
          '2.2.1 a <b>person</b> includes a natural person, corporate or unincorporated body (whether or not having separate legal personality);'
      },
      {
        type: 'paragraph',
        content:
          '2.2.2 references to the Tools includes the content of the site (<b>Content</b>).'
      },
      {
        type: 'paragraph',
        content:
          '2.2.3 a reference to a party includes its personal representatives, successors or permitted assigns;'
      },
      {
        type: 'paragraph',
        content:
          '2.2.4 a reference to a statute or statutory provision is a reference to such statute or statutory provision as amended or re-enacted. A reference to a statute or statutory provision includes any subordinate legislation made under that statute or statutory provision, as amended or re-enacted;'
      },
      {
        type: 'paragraph',
        content:
          '2.2.5 any phrase introduced by the terms <b>including, include, in particular</b> or any similar expression, shall be construed as illustrative and shall not limit the sense of the words preceding those terms; and'
      },
      {
        type: 'paragraph',
        content: '2.2.6 a reference to writing or written includes email.'
      }
    ]
  },
  {
    title: '3. Basis of contract',
    sections: [
      {
        type: 'paragraph',
        content:
          '<b>3.1</b> The Registration Form constitutes your offer to subscribe to the Tools in accordance with these Terms.'
      },
      {
        type: 'paragraph',
        content: '<b>3.2</b> By registering on our site, you confirm that:'
      },
      {
        type: 'paragraph',
        content:
          '3.2.1 you are legally capable of entering into binding contracts and, if you are entering into this Contract on behalf of a business entity of any kind, that you are duly authorised by the legal entity on whose behalf you are registering to bind it to this Contract; and'
      },
      {
        type: 'paragraph',
        content:
          '3.2.2 if you are an individual, you are at least 18 years old;'
      },
      {
        type: 'paragraph',
        content:
          '3.2.3 you agree to be bound by our website <a href="/terms-of-use" target="_blank">terms of use</a>, <a href="/use-policy" target="_blank">acceptable use policy</a> and <a href="/privacy-policy" target="_blank">privacy policy</a>, all of which apply to your use of our site.'
      },
      {
        type: 'paragraph',
        content:
          '<b>3.3</b> Your registration for the Tools shall only be deemed to be accepted when:'
      },
      {
        type: 'paragraph',
        content:
          '3.3.1 we send you an email confirming our acceptance (where you have registered on our site); or'
      },
      {
        type: 'paragraph',
        content:
          '3.3.2 if you have registered for the Tools via Facebook, whichever is the later of:'
      },
      {
        type: 'list',
        content: [
          '3.3.2.1	you authorising Facebook to communicate with our site; or',
          '3.3.2.2 our receiving your details from Facebook, at which point and on which date the Contract between us shall come into existence (<b>Commencement Date</b>).'
        ]
      },
      {
        type: 'paragraph',
        content:
          '<b>3.4</b> The Contract constitutes the entire agreement between you and us, and you acknowledge that you have not relied on any statement, promise or representation (whether made or given by us or on our behalf) which is not set out in the Contract.'
      },
      {
        type: 'paragraph',
        content:
          '<b>3.5</b> Any descriptive matter or advertising issued by us or displayed on our site is for the sole purpose of giving an approximate idea of the Tools. They shall not form part of the Contract or have any contractual force.'
      },
      {
        type: 'paragraph',
        content:
          '<b>3.6</b> These Terms apply to the Contract to the exclusion of any other terms that you may seek to impose or incorporate, or which are implied by trade, custom, practice or course of dealing.'
      }
    ]
  },
  {
    title: '4. Your obligations',
    sections: [
      {
        type: 'paragraph',
        content: '4.1 You acknowledge and agree that:'
      },
      {
        type: 'paragraph',
        content:
          '4.1.1 we have sole and complete discretion as to the look, feel and content of our site, and of any offers for Campaign Products posted on our site, as well as in respect of the inclusion, omission, positioning, location and all other aspects of presentation of the offers and the Campaign Products they relate to, and that we have the right in our sole discretion to remove any offer or Campaign Product or any information about you from our site, at any time;'
      },
      {
        type: 'paragraph',
        content:
          '4.1.2 we do not offer you exclusivity of presence on our site, and that third parties may offer for sale via our site Products that are the same as, are similar to, or compete with, the Campaign Products;'
      },
      {
        type: 'paragraph',
        content:
          '4.1.3 we do not warrant or guarantee that any offers posted on our site in relation to the Campaign Products will be error-free, nor that the posting of an offer on our site will result in the sale of any Campaign Products to our customers, or will result in any Creator Fee becoming payable to you.'
      },
      {
        type: 'paragraph',
        content:
          '<b>4.2</b> Except for any design elements created by us as part of a Design Enhancement, you warrant that:'
      },
      {
        type: 'paragraph',
        content:
          '4.2.1 you are the sole legal and beneficial owner of, and own all the rights and interests in, the Intellectual Property Rights in the Design and Brand Elements;'
      },
      {
        type: 'paragraph',
        content:
          '4.2.2 the Design meets all requirements of our Artwork Guidelines, set out in <a href="/creator-toolkit/a-guide-to-intellectual-property-infringement/">"Guidance for creators on our Intellectual Property Policy"</a> and <a href="/creator-toolkit/our-content-review-policy/">"Content Review Policy"</a>.'
      },
      {
        type: 'paragraph',
        content:
          '4.2.3 the Design and each Brand Element is your original work and have not been copied wholly or substantially from any other source without a licence permitting their reproduction on Campaign Products to be sold by you;'
      },
      {
        type: 'paragraph',
        content:
          '4.2.4 our provision of the Tools in respect of the Design and our sale of any Campaign Products bearing the Design will not infringe the Intellectual Property Rights of any third party; and'
      },
      {
        type: 'paragraph',
        content:
          '4.2.5 where sales of the Campaign Products are purported to support any third party good cause (whether or not a registered charity) (Good Cause), you will pay the stated proceeds to such Good Cause immediately on receipt of the Creator Fee, and you will only describe the Good Cause as a charity if it qualifies as such under the Charities Act 2011.'
      },
      {
        type: 'paragraph',
        content:
          '<b>4.3</b> You agree to provide true, accurate, current and complete information about yourself and / or your business as prompted by the Tools registration process (<b>Registration Details</b>). You further agree that, in providing such Registration Details, you will not knowingly omit or misrepresent any material facts or information, and that you will promptly enter corrected or updated Registration Details via the Tools, or otherwise advise us promptly in writing of any such changes or updates. You also agree to update your Registration Details (including, but not limited to, your current email address) as soon as they change.'
      },
      {
        type: 'paragraph',
        content: '<b>4.4</b> You will:'
      },
      {
        type: 'paragraph',
        content:
          '4.4.1 co-operate with us in all matters relating to the Tools, and to the promotion and offering for sale by us of the Campaign Products on our site;'
      },
      {
        type: 'paragraph',
        content:
          '4.4.2 provide us with such information and materials as we may reasonably require in order to post and offer for sale the Campaign Products on our site and to supply the Tools to you, and ensure that such information is complete and accurate in all material respects; and'
      },
      {
        type: 'paragraph',
        content:
          '4.4.3 permit and cooperate with all activities undertaken by us (or on our behalf) to promote, sell or market the Campaign Products, whether directly via our site or through print publications or other media, whether or not owned or operated by us.'
      },
      {
        type: 'paragraph',
        content:
          '<b>4.5</b> When you register to use the Tools, you will need to enter either a unique user name and password or your Facebook credentials in order to access your account (<b>User ID</b>). You agree that you will not allow another person to use your User ID to access or use the Tools under any circumstances. You agree that you are solely and entirely responsible for your User ID and for any charges, damages, liabilities or losses incurred or suffered as a result of your failure to keep it secure. We are not liable for any charges, damages, liabilities or losses caused by or related to the theft of your User ID, your disclosure of your User ID, or you allowing another person to access and use the Tools using your User ID.'
      },
      {
        type: 'paragraph',
        content:
          '<b>4.6</b> You are solely and entirely responsible for any and all use of your account, including any charges incurred relating to the Tools. You agree to immediately notify us of any unauthorised use of your account or any other breach of security known to you.'
      },
      {
        type: 'paragraph',
        content:
          '4.6.1 will ensure that any such integration is PCI DSS compliant and served via https, so that any payments made by a customer through the iframe in question are encrypted and are secure at all times; and'
      },
      {
        type: 'paragraph',
        content:
          '4.6.2 are solely responsible for ensuring PCI DSS compliance in relation to any such payments.'
      },
      {
        type: 'paragraph',
        content:
          'In all other respects, the relevant payments will be subject to these Terms.'
      },
      {
        type: 'paragraph',
        content:
          '<b>4.7</b> You acknowledge that the complete privacy of your data and messages transmitted over the internet while using the Tools cannot be guaranteed.'
      },
      {
        type: 'paragraph',
        content:
          '<b>4.8</b> You shall indemnify us against any liability incurred by us in respect of:'
      },
      {
        type: 'paragraph',
        content:
          '<b>4.8.1</b> any breach by you of any of the provisions of this clause 4; and'
      },
      {
        type: 'paragraph',
        content:
          '<b>4.8.2</b> any liabilities, costs (including legal costs), claims, demands, fines, damages, losses (whether direct, indirect or consequential) and expenses arising out of or in connection with that liability or failure, or paid or agreed to be paid by us in relation to the defence or settlement thereof, except to the extent the liability or failure arises as a result of our own action or omission.'
      }
    ]
  },
  {
    title: '5. Your licence to us',
    sections: [
      {
        type: 'paragraph',
        content:
          '<b>5.1</b> You hereby grant to us a non-exclusive licence on a worldwide basis to do the following, subject to, and in accordance with, the terms of this agreement:'
      },
      {
        type: 'paragraph',
        content:
          '5.1.1 reproduce the Design on the Products for sale by us (as principal) as Campaign Products;'
      },
      {
        type: 'paragraph',
        content: '5.1.2 sell the Campaign Products to Buyers via our site; and'
      },
      {
        type: 'paragraph',
        content:
          '5.1.3 reproduce the Design and / or any Campaign Product in any advertising or promotional material relating to the Campaign Product, including (but not limited to) our site.'
      }
    ]
  },
  {
    title: '6. How the Service works',
    sections: [
      {
        type: 'paragraph',
        content:
          '<b>6.1</b> Once we have accepted your Registration as described in clause 3.3 above, unless we have agreed to provide you with a Design Enhancement, you will be able to upload the Design to our site. In order to do this, the Design must:'
      },
      {
        type: 'paragraph',
        content:
          '6.1.1 comply in all respects with the standards set out in our <a href="/use-policy" target="_blank">acceptable use policy</a>;'
      },
      {
        type: 'paragraph',
        content:
          '6.1.2 comply in all respects with the terms of this agreement; and'
      },
      {
        type: 'paragraph',
        content:
          '6.1.3 be in either PNG, SVG or JPG format, and in each case be less than 8mb in size.'
      },
      {
        type: 'paragraph',
        content:
          '<b>6.2</b> If we have accepted your Registration and agreed to provide you with Design Enhancement, we will agree a brief with you by email or via our site (including any Brand Elements to be included in the Design Enhancement), and provide you with the Design Enhancement service accordingly. You must within 5 Business Days of receipt of the Design Enhancement notify us by email at hello@everpress.com of any failure of the Design Enhancement to comply with the agreed brief, or of any other specific changes you wish made to Design Enhancement. We will use reasonable endeavours to make the changes as soon as we reasonably can, except where they are likely to impair the production or quality of the Campaign Products.  In the absence of such a notice from you, or in the event that you indicate that the Design Enhancement is approved, the Design Enhancement shall be deemed accepted and approved by you. Any further changes beyond the initial round of changes shall be chargeable at our then-current hourly rate for Design Enhancement as advised to you.'
      },
      {
        type: 'paragraph',
        content:
          '<b>6.3</b> Once you have successfully uploaded the Design to our site in compliance with clause 6.1 above, (or as the case may be, you have approved or are deemed to approve the Design Enhancement under clause 6.2) and we have received all of the relevant information regarding the offer and the Campaign Products it relates to from you, we may offer the Campaign Products for sale by us to Buyers. Any Buyers who wish to do so will be able to purchase the Campaign Products from our site by placing an order through our site, and Stripe will take payment from of the Purchase Price for the Campaign Products stated to Buyers on our site, including VAT (where VAT is applicable) (the Purchase Price).'
      },
      {
        type: 'paragraph',
        content:
          '6.3.1 As the creator of the campaign, you have the opportunity to purchase a sample order from the campaign while the campaign is in draft state. We do not provide refunds on sample orders unless there is a product or print defect.'
      },
      {
        type: 'paragraph',
        content:
          '<b>6.4</b> We will pay you a Creator Fee in relation to all Campaign Products we sell to Buyers, in accordance with clause 8 below. We will not produce, sell or despatch any Campaign Products until the Minimum Order Benchmark has been satisfied.'
      },
      {
        type: 'paragraph',
        content:
          '<b>6.5</b> Payment for the Design Enhancement shall be by means of a flat fee, payable in advance.'
      },
      {
        type: 'paragraph',
        content:
          '<b>6.6</b> You may opt-in to the Promotional Network in respect of each campaign undertaken on our site. You may subsequently opt-out using the opt-out button on our site, or by emailing hello@everpress.com. If you opt-in, that means that we will be entitled (but have no obligation) to promote the Campaign Products through the Promotional Network and will apply the Promotional Network Creator Fee in respect of any Promotional Network Sales. If you opt-out subsequent to Registration, we will within 3 Business Days of receiving your opt-out cease to promote the Campaign Products in the Promotional Network. You acknowledge that Promotional Network Sales may continue to be made following that date. For example, a Buyer may have bookmarked an Promotional Network URL prior to the opt-out being actioned and use it to visit our site following activation of the opt-out. Further information about the advertising inventory we use in the Promotional Network is available <a href="/creator-toolkit/ad-network/">here</a>.'
      },
      {
        type: 'paragraph',
        content:
          '<b>6.7</b> We will have absolute discretion over whether we promote the Campaign Products (including by means of the Promotional Network), and the targeting and composition of any promotion (including in the Promotional Network). For example, such promotions may comprise a depiction of the Campaign Products, your name, the Design, any Brand Elements and/or any title you give the Design, and you authorise us to include those in any promotion.'
      },
      {
        type: 'paragraph',
        content:
          '<b>6.8</b> In the rare occurrence that there is a failure of a delivery of a Campaign Product to a Buyer, and the Campaign Product is returned to us, you authorise us to destroy it, or donate it to a charity, provided that we will require any such charity not to re-sell the Campaign Product.'
      }
    ]
  },
  {
    title: '7. Supply of the Service',
    sections: [
      {
        type: 'paragraph',
        content:
          '<b>7.1</b> Although we aim to offer you the best service possible, we make no promise that the Tools will meet your requirements, and we make no warranties or representations that any Campaign Products offered for sale on our site will be purchased by any of the third parties who use our site.'
      },
      {
        type: 'paragraph',
        content:
          '<b>7.2</b> We cannot guarantee that the Tools will be fault free. If a fault occurs in the Tools you should report it to us immediately by contacting us at <a href="mailto:hello@everpress.com">hello@everpress.com</a> and we will attempt to correct the fault as soon as we reasonably can.'
      },
      {
        type: 'paragraph',
        content:
          '<b>7.3</b> Your access to the Tools may occasionally be restricted to allow for repairs, maintenance or the introduction of new facilities or services. We will attempt to restore the service as soon as we reasonably can.'
      },
      {
        type: 'paragraph',
        content:
          '<b>7.4</b> We reserve the right at any time to make any changes to the Tools which are necessary to comply with any applicable laws or regulations, or which do not materially affect the nature or quality of the Tools.'
      },
      {
        type: 'paragraph',
        content:
          '<b>7.5</b> The Service does not include the provision of computer or other necessary equipment to access the Tools. To use the Tools you will require internet connectivity and appropriate telecommunication links. We will not be liable for any telephone or other costs that you may incur.'
      },
      {
        type: 'paragraph',
        content:
          '<b>7.6</b> Registration for the Tools is conducted in the English language only.'
      },
      {
        type: 'paragraph',
        content:
          '<b>7.7</b> During the period of your Registration you will be given access to the following information regarding your campaigns on our site: Stats on orders split by country and product; Stats on orders included in the Promotional Network; Print method used for the campaign. A download of the email address and other details for Buyers that have consented that we share those details with you.'
      },
      {
        type: 'paragraph',
        content:
          '<b>7.8</b> We may share information about your Registration and campaigns with third parties, including as set out in our Privacy Policy.'
      },
      {
        type: 'paragraph',
        content:
          '<b>7.9</b> For all Everpress campaigns, creators will select a specific garment, or selection of garments, to print their design onto. In the rare event that the chosen garment is out of stock when the campaign goes to print, we reserve the right to swap it for an equivalent alternative garment. This will always be like-for-like in terms of both colour and garment type (i.e. a white T-shirt for a white T-shirt, a red long sleeve for a red long sleeve etc). This will never affect the design you’ve created.'
      }
    ]
  },
  {
    title: '8. Creator Fee and payment',
    sections: [
      {
        type: 'paragraph',
        content:
          '<b>8.1</b> Subject to any amendment brought into effect in accordance with clause 9, the Creator Fee payable to you in relation to each sale of a Campaign Product via our site shall be as set out on our site from time to time <a href="/price-calculator" target="_blank">here</a> and as described in this clause 8. Creator Fee will be calculated on the Purchase Price we have received on your behalf from Buyers, less the VAT thereon (if applicable), and will vary according to the number of and the type of Campaign Products sold by us, and whether the Promotional Network Creator Fee applies. Sales of Campaign Products will not be fulfilled and no Creator Fee will be payable until such time as the Minimum Order Benchmark has been reached.</b>'
      },
      {
        type: 'paragraph',
        content:
          '<b>8.2</b> Once the Minimum Order Benchmark has been reached, and we have received the Purchase Price from the Buyer in respect of the sale of the Campaign Products, we will transfer to you the Creator Fee.'
      },
      {
        type: 'paragraph',
        content:
          '<b>8.3</b> We will make a single payment to you in arrears for your Creator Fee, within 7 (seven) days of the end of the campaign period in question.'
      },
      {
        type: 'paragraph',
        content:
          '<b>8.4</b> You must ensure that at all times your correct bank or PayPal details are notified to us, and you must inform us in writing of any changes immediately. Please note that if you are located outside the UK we only make payment to you of your Creator Fee by means of a valid PayPal account. You shall reimburse any charges or administrative costs levied by your bank or PayPal (as the case may be) in relation to any payments we make to you, and any charges or costs we incur as a consequence of any error in the bank or PayPal details you provide to us, or your failure to notify us of any changes.'
      },
      {
        type: 'paragraph',
        content:
          '<b>8.5</b> In the event of any dispute as to the amount of Creator Fee payable by us to you, the same shall be referred to our external accountants for settlement and their decision, except in the case of manifest error, shall be final and binding on both parties.'
      },
      {
        type: 'paragraph',
        content:
          '<b>8.6</b> You acknowledge that you will not receive any Creator Fee in respect of orders for Campaign Products cancelled by Buyers under applicable  consumer laws, including the 14-day right of cancellation under the UK’s Consumer Contracts Regulations 2013. and in respect of any defective Campaign Products returned by Buyers.'
      },
      {
        type: 'paragraph',
        content:
          '<b>8.7</b> If you are registered for UK VAT, you must accurately specify your VAT number on the Registration Form. If you become UK VAT registered status prior to the end of the Campaign, you must notify us of this, together with your VAT number, by email to hello@everpress.com. If you are registered for UK VAT, UK VAT will be added to the Creator Fee where payable.'
      },
      {
        type: 'paragraph',
        content:
          '<b>8.8</b> If you are registered for UK VAT, and we have obtained and validated your VAT registration number,  the following self-billing terms will apply:'
      },
      {
        type: 'paragraph',
        content:
          '<b>8.8.1</b> We (Everpress Ltd) agree to do the following: (i) issue self-billed invoices for all supplies and services supplied to us by you until the end of the Contract term; (ii) complete self-billed invoices showing your name, address and VAT registration number, together with all details which constitute a full VAT invoice; (iii) make a new self-billing agreement in the event that your VAT registration number changes.'
      },
      {
        type: 'paragraph',
        content:
          '<b>8.8.2</b> You agree to do the following: (i) to accept invoices raised by us on your behalf the until the end of the Contract term; (ii) not to raise sales invoices for the Creator Fee covered by the Contract; (iii) notify us immediately if you: (a) change your UK VAT Number; (b) cease to be UK VAT registered; or (c) sell your business as a whole or in part.'
      }
    ]
  },
  {
    title: '9. Our right to vary these terms',
    sections: [
      {
        type: 'paragraph',
        content:
          '<b>9.1</b> We may make changes to these Terms immediately if we are subject to a legal or regulatory obligation which obliges us to change them in a manner which does not permit us to give advance notice, or to address an unforeseen and imminent danger related to defending the Tools or our site from fraud, malware, spam, data breaches or technical security risks.'
      },
      {
        type: 'paragraph',
        content:
          '<b>9.2</b> We will provide at least 15 days’ advance notice to you by email for any other changes to these Terms. You may terminate your use of the Tools and the Contract at any time prior to or following such changes coming into effect.'
      },
      {
        type: 'paragraph',
        content:
          '<b>9.3</b> Any new Campaigns after the effective date of any change to these Terms will be subject to that change. If you do not accept any change, you must not use the Tools in respect of any new campaigns after the effective date of the change.'
      }
    ]
  },
  {
    title: '10. Intellectual property rights',
    sections: [
      {
        type: 'paragraph',
        content:
          '<b>10.1</b> We own all Intellectual Property Rights in or arising out of or in connection with the Tools, the Content and our site.'
      },
      {
        type: 'paragraph',
        content:
          '<b>10.2</b> You hereby grant to us a non-exclusive, perpetual and irrevocable worldwide licence to include the information you provide to us (the Information) on our site, and to use such information in our own editorial content or promotional materials in any medium, including on our site. The Information includes the Design, any title of your campaign or the Campaign Products, your name, brand, likeness, promotional material and any Brand Elements.'
      },
      {
        type: 'paragraph',
        content:
          '<b>10.3</b> You acknowledge that, in respect of any third party Intellectual Property Rights in any Information provided for use by us on our site or otherwise in connection with the Tools (including text, data, photographs and other materials), our use of any such Intellectual Property Rights requires you to have first obtained a written licence from the relevant licensor on such terms as will either entitle you to license such rights to us, or grant such rights direct to us. You warrant that you have obtained any and all such licences and shall provide copies of them to us on demand.'
      },
      {
        type: 'paragraph',
        content:
          '<b>10.4</b>  You shall indemnify us against all costs, claims, damages, losses and expenses arising as a result of any claim or action that the Design(s) and/or the Information infringes Intellectual Property Rights belonging to a third party (IP Claim). This will not apply to any infringement solely relating to any Design Enhancement.'
      }
    ]
  },
  {
    title:
      '11. Limitation of liability: your attention is drawn to this clause',
    sections: [
      {
        type: 'paragraph',
        content:
          '<b>11.1</b> We do not warrant that our site, the Tools or the Content is virus free. You must take your own precautions in this respect as we accept no responsibility for any infection by virus or other contamination or by anything that has destructive properties.'
      },
      {
        type: 'paragraph',
        content:
          '<b>11.2</b> In particular, we disclaim all liabilities in connection with the following:'
      },
      {
        type: 'paragraph',
        content:
          '11.2.1 incompatibility of the Content with any of your equipment, software or telecommunications links;'
      },
      {
        type: 'paragraph',
        content:
          '11.2.2 technical problems including errors in or interruptions to the Tools; and'
      },
      {
        type: 'paragraph',
        content: '11.2.3 unreliability or inaccuracy of the Content.'
      },
      {
        type: 'paragraph',
        content:
          '<b>11.3</b> Except as expressly and specifically provided in these Terms:'
      },
      {
        type: 'paragraph',
        content:
          '11.3.1 we shall have no liability for any damage caused by errors or omissions in any information or instructions provided by you in connection with the Tools, or any actions taken by us at your direction; and'
      },
      {
        type: 'paragraph',
        content:
          '11.3.2 all warranties, conditions and other terms implied by statute or common law are, to the fullest extent permitted by law, excluded from these Terms.'
      },
      {
        type: 'paragraph',
        content:
          '<b>11.4</b> Subject to clause 11.6, we are not responsible for any of the following:'
      },
      {
        type: 'paragraph',
        content: '11.4.1 loss of income or revenue;'
      },
      {
        type: 'paragraph',
        content: '11.4.2 loss of business;'
      },
      {
        type: 'paragraph',
        content: '11.4.3 loss of profits or contracts'
      },
      {
        type: 'paragraph',
        content: '11.4.4 loss of anticipated savings;'
      },
      {
        type: 'paragraph',
        content: '11.4.5 loss of data, or'
      },
      {
        type: 'paragraph',
        content:
          '11.4.6 waste of management or office time however arising and whether caused by tort (including negligence), breach of contract or otherwise;'
      },
      {
        type: 'paragraph',
        content:
          '	provided that this clause 11.4 shall not prevent claims for any other claims for direct financial loss that are not excluded by any of categories 11.4.1 to 11.4.6.'
      },
      {
        type: 'paragraph',
        content:
          '<b>11.5</b> Subject to paragraph 11.6, our liability for losses you suffer as a result of us breaking these Terms is strictly limited to the total amount of any Creator Fee paid by us to you over the preceding 12 months, or if none, to a total amount of £100.'
      },
      {
        type: 'paragraph',
        content:
          '<b>11.6</b> The exclusions and limitations set out in paragraphs 11.4 and 11.5 do not exclude or limit in any way our liability for'
      },
      {
        type: 'paragraph',
        content: '11.6.1 death or personal injury caused by our negligence;'
      },
      {
        type: 'paragraph',
        content: '11.6.2 fraud or fraudulent misrepresentation; or'
      },
      {
        type: 'paragraph',
        content:
          '11.6.3 any matter for which it would be illegal for us to exclude, or attempt to exclude, our liability.'
      },
      {
        type: 'paragraph',
        content:
          '<b>11.7</b> This clause 11 will survive termination of the Contract for any reason.'
      }
    ]
  },
  {
    title: '12. Suspension and cancellation of your registration',
    sections: [
      {
        type: 'paragraph',
        content:
          '<b>12.1</b> You can cancel your Registration at any time by informing us in writing. If you do so, you must stop using the Tools immediately.'
      },
      {
        type: 'paragraph',
        content:
          '<b>12.2</b> These Terms for Creators set out a number of different grounds for us suspending, restricting or terminating the Contract or your Registration. We may also terminate your Registration or the Contract if we need to for commercial, reputational, technical or operational reasons connected with our business.'
      }
    ]
  },
  {
    sections: [
      {
        type: 'paragraph',
        content:
          '<b>12.3</b> We may terminate the Contract (or suspend or terminate your Registration) with any or no notice in the following circumstances. First, if you have breached these Terms for Creators. Second, it appears to us that your Registration has been or may be used for dishonest, deceptive or unlawful activity. Third, your use of the Tools has damaged (or we determine in good faith that it may damage) legitimate interests of Buyers, other designers submitting designs on our site, or us. Fourth, if we consider that we are under a legal or regulatory obligation to terminate your access to the Tools.'
      },
      {
        type: 'paragraph',
        content:
          '<b>12.4</b> You may terminate your Registration at any time by ceasing all use of the Tools and deactivating your account or by emailing us at hello@everpress.com requesting us to deactivate your account. You may terminate the Contract at any time by emailing us at hello@everpress.com. You acknowledge and agree that we may continue to fulfil sales of Campaign Products already concluded by us with Buyers prior to termination.'
      },
      {
        type: 'paragraph',
        content:
          '<b>12.5</b> We will promptly notify you of any termination or suspension of the Contract, a campaign or your Registration. If we suspend your campaign or your Registration due to your not having complied with your obligations under the Contract, this suspension will continue until we are reasonably satisfied that they have fixed their non-compliance such that it will not recur.'
      },
      {
        type: 'paragraph',
        content:
          '<b>12.6</b> Once your Registration is terminated, you shall have no access to any data on your account, including any data regarding Campaign Products or Buyers.'
      },
      {
        type: 'paragraph',
        content:
          '<b>12.7</b> Unless we specifically agree in writing otherwise, if we terminate your Registration, you are prohibited from using our Tools again by re-registering with a different User ID, or by using Facebook to register again.'
      },
      {
        type: 'paragraph',
        content:
          '<b>12.8</b> In the event that either you or we cancel your Registration and/or the Contract for any reason, and the Campaign Products have not yet been produced as at the date of such cancellation, we will:'
      },
      {
        type: 'paragraph',
        content:
          '12.8.1 terminate the relevant campaign and remove it from our site (including, at our discretion, any user-created pages); and'
      },
      {
        type: 'paragraph',
        content:
          '12.8.2 arrange for refunds to be made in respect of any orders already placed for the Campaign Products. For the avoidance of doubt, you will not be entitled to any Design in respect of any orders so refunded.'
      },
      {
        type: 'paragraph',
        content:
          '<b>12.9</b> The suspension or cancellation of your Registration and/or the Contract and/or your right to use the Tools shall not affect any rights or remedies we may have.'
      }
    ]
  },
  {
    title: '13. Discontinuation of Tools',
    sections: [
      {
        type: 'paragraph',
        content:
          'We reserve the right to discontinue the Tools (or any part of it) without notice to you and shall not be liable to you if we exercise these rights. Where reasonably practicable, we will try to give you reasonable notice of any discontinuation of the Tools.'
      }
    ]
  },
  {
    title: '14. How we may use your personal information',
    sections: [
      {
        type: 'paragraph',
        content:
          'We will only use your personal information as set out in our privacy policy. Please read our <a href="/privacy-policy" target="_blank"privacy policy</a> for full details of how your personal data will be used.'
      }
    ]
  },
  {
    title: '15. General',
    sections: [
      {
        type: 'paragraph',
        content: '<b>15.1</b> Event Outside Our Control:'
      },
      {
        type: 'paragraph',
        content:
          '15.1.1 For the purposes of this Contract, <b>Event Outside Our Control</b> means an event beyond our reasonable control including but not limited to strikes, lock-outs or other industrial disputes (whether involving our workforce or that of any other party), failure of a utility service or transport network, act of God, war, riot, civil commotion, malicious damage, compliance with any law or governmental order, rule, regulation or direction, accident, breakdown of plant or machinery, fire, flood, storm or default of suppliers or subcontractors.'
      },
      {
        type: 'paragraph',
        content:
          '15.1.2 We will not be liable to you as a result of any delay or failure to perform our obligations under the Contract as a result of an Event Outside Our Control.'
      },
      {
        type: 'paragraph',
        content:
          '15.1.3 If the Event Outside Our Control prevents us from providing all or part of the Tools for more than six weeks, we will, without limiting our other rights or remedies, have the right to terminate the Contract immediately by giving written notice to you.'
      },
      {
        type: 'paragraph',
        content: '<b>15.2</b> Assignment and subcontracting:'
      },
      {
        type: 'paragraph',
        content:
          '5.2.1 We may at any time assign, transfer, charge, subcontract or deal in any other manner with all or any of our rights under the Contract and may subcontract or delegate in any manner any or all of our obligations under the Contract to any third party or agent.'
      },
      {
        type: 'paragraph',
        content:
          '5.2.2 You shall not, without our prior written consent, assign, transfer, charge, subcontract or deal in any other manner with all or any of your rights or obligations under the Contract.'
      },
      {
        type: 'paragraph',
        content: '<b>15.3</b> Notices:'
      },
      {
        type: 'paragraph',
        content:
          '15.3.1 To Everpress: Notice will be sent to the following address: hello@everpress.com'
      },
      {
        type: 'paragraph',
        content:
          '15.3.2 To you: your email address as provided in our account information for you.'
      },
      {
        type: 'paragraph',
        content: '<b>15.4</b> Waiver:'
      },
      {
        type: 'paragraph',
        content:
          '15.4.1 A waiver of any right under the Contract is only effective if it is in writing and shall not be deemed to be a waiver of any subsequent breach or default. No failure or delay by a party in exercising any right or remedy under the Contract or by law shall constitute a waiver of that or any other right or remedy, nor preclude or restrict its further exercise. No single or partial exercise of such right or remedy shall preclude or restrict the further exercise of that or any other right or remedy.'
      },
      {
        type: 'paragraph',
        content:
          '15.4.2 Unless specifically provided otherwise, rights arising under the Contract are cumulative and do not exclude rights provided by law.'
      },
      {
        type: 'paragraph',
        content: '<b>15.5</b> Severance:'
      },
      {
        type: 'paragraph',
        content:
          '15.5.1 If a court or any other competent authority finds that any provision of the Contract (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision shall, to the extent required, be deemed deleted, and the validity and enforceability of the other provisions of the Contract shall not be affected.'
      },
      {
        type: 'paragraph',
        content:
          '15.5.2 If any invalid, unenforceable or illegal provision of the Contract would be valid, enforceable and legal if some part of it were deleted, the provision shall apply with the minimum modification necessary to make it legal, valid and enforceable.'
      },
      {
        type: 'paragraph',
        content:
          '<b>15.6</b> Nothing in the Contract is intended to, or shall be deemed to, constitute a partnership or joint venture of any kind between any of the parties, nor constitute any party the agent of another party for any purpose. No party shall have authority to act as agent for, or to bind, the other party in any way.'
      },
      {
        type: 'paragraph',
        content:
          '<b>15.7</b> A person who is not a party to the Contract shall not have any rights under or in connection with it.'
      },
      {
        type: 'paragraph',
        content:
          '<b>15.8</b> Except as set out in these Terms, any variation, including the introduction of any additional terms and Terms, to the Contract, shall only be binding when agreed in writing and signed by us.'
      },
      {
        type: 'paragraph',
        content:
          '<b>15.9</b> This Contract, and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims), shall be governed by, and construed in accordance with, English law, and the parties irrevocably submit to the exclusive jurisdiction of the courts of England and Wales.'
      }
    ]
  }
];

export default termsSellers;
