import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ReactSelect from 'react-select';

import './style/select-global.css';

import { theme } from '../../../../theme-config';

class Select extends React.Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    onCustomChange: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    isSmall: PropTypes.bool,
    isInline: PropTypes.bool,
    isInverted: PropTypes.bool,
    className: PropTypes.string
  };

  static defaultProps = {
    onChange: () => {},
    onBlur: () => {},
    isSmall: false
  };

  handleChange(option) {
    this.props.setFieldValue(this.props.name, option.value);
    this.props.onCustomChange && this.props.onCustomChange(option);
  }

  handleBlur() {
    this.props.setFieldTouched(this.props.name, true);
  }

  render() {
    const {
      onCustomChange,
      borderColor,
      standalone,
      readOnly,
      disabled,
      isSmall,
      isInline,
      isInverted,
      isError,
      className,
      options,
      value,
      ...props
    } = this.props;
    const selectedOption = value;

    const customStyles = {
      container: (provided, state) => {
        const errorStyles = isError
          ? { borderBottomColor: theme.extend.colors.red.primary }
          : undefined;
        const borderColorThemed =
          !state.isFocused && borderColor === 'grey'
            ? theme.colors['darker-grey']
            : theme.colors.black;
        const border =
          isInline || (isInverted && !state.isFocused)
            ? 'none'
            : `1px ${borderColorThemed} solid`;
        const mediaQuery = `@media (min-width: ${theme.screens.md})`;
        const smallStyles = isSmall
          ? {
              minWidth: '280px',
              [mediaQuery]: { minWidth: '320px' }
            }
          : { padding: '10px 0' };
        return {
          ...provided,
          ...smallStyles,
          border,
          ...errorStyles
        };
      },
      option: (provided, state) => {
        const invertedStyles = isInverted
          ? {
              ':hover': {
                backgroundColor: theme.colors.black,
                color: theme.colors.white
              },
              backgroundColor: state.isFocused
                ? theme.colors.black
                : theme.colors.white,
              color: state.isFocused ? theme.colors.white : theme.colors.black
            }
          : {
              backgroundColor: state.isFocused ? '#DEEBFF' : theme.colors.white,
              color: theme.colors.black
            };
        return {
          ...provided,
          ...invertedStyles,
          padding: isSmall ? '10px' : '20px'
        };
      },
      control: (provided) => ({
        ...provided,
        border: 'none',
        boxShadow: 'none !important',
        backgroundColor: theme.colors.white,
        paddingLeft: standalone ? '0' : '10px',
        ':hover': { border: 'none', boxShadow: 'none' },
        ':after': { border: 'none', boxShadow: 'none' }
      }),
      menu: (provided) => ({
        ...provided,
        border: `1px ${theme.colors.black} solid`,
        margin: '0',
        borderRadius: 0
      }),
      indicatorSeparator: () => ({ display: 'none' }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        color: theme.colors.black,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none'
      })
    };

    return (
      <div
        className={classNames({
          SelectIsSmall: isSmall && !isInline,
          SelectIsInline: isInline,
          SelectIsInverted: isInverted
        })}
      >
        {standalone ? (
          <ReactSelect
            styles={customStyles}
            options={options}
            value={options.filter(
              (option) =>
                (selectedOption &&
                  selectedOption.value &&
                  option.value === selectedOption.value) ||
                option.value === selectedOption
            )}
            searchable={false}
            clearable={false}
            isDisabled={disabled || readOnly}
            className={className}
            aria-label={props.placeholder || 'Select'}
            classNamePrefix="Select"
            {...props}
          />
        ) : (
          <ReactSelect
            styles={customStyles}
            options={options}
            value={options.filter(
              (option) =>
                (selectedOption &&
                  selectedOption.value &&
                  option.value === selectedOption.value) ||
                option.value === selectedOption
            )}
            searchable={false}
            clearable={false}
            isDisabled={disabled || readOnly}
            aria-label={props.placeholder || 'Select'}
            classNamePrefix="Select"
            {...props}
            onChange={this.handleChange.bind(this)}
            onBlur={this.handleBlur.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default Select;
