import React from 'react';

import Spacing from '../../../components/atoms/Spacing';
import Link from '../../../components/atoms/Link';
import Paragraph from '../../../components/atoms/Paragraph';

const EmptyCart = () => (
  <Spacing size={4} position="y">
    <Paragraph>There's nothing in your bag right now.</Paragraph>
    <Link to="/shop">Let's find something!</Link>
  </Spacing>
);

export default EmptyCart;
