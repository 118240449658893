import React, { Component } from 'react';
import classNames from 'classnames';
import throttle from 'lodash/throttle';

import { GridItem } from '../../../atoms/Grid';
import Heading from '../../../atoms/Heading';
import Spacing from '../../../atoms/Spacing';
import Icon from '../../../atoms/Icon';

import arrowIcon from '../../../../img/sprites/arrow-down.svg';

export default class FooterColumn extends Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
      isMobile: false
    };

    this.resize = throttle(this.resize.bind(this), 100);
    this.toggleColumn = this.toggleColumn.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize() {
    if (window.innerWidth < 576) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  toggleColumn() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { isOpen, isMobile } = this.state;
    const { title, children, isDark } = this.props;
    const containerClassName = classNames({
      hidden: !isOpen && isMobile,
      block: isOpen
    });
    const titleClassName = classNames(
      'flex',
      'justify-between',
      'items-center',
      {
        'cursor-pointer': isMobile
      }
    );
    const arrowClassName = classNames({
      hidden: !isMobile,
      block: isMobile
    });

    return (
      <GridItem columnSize={[12, 6, 4, 2, 2]}>
        <Spacing size={[1, 3, 3, 5]}>
          <div className={titleClassName} onClick={this.toggleColumn}>
            <Heading size="xs" color={isDark ? 'white' : 'black'}>
              {title}
            </Heading>
            <div className={arrowClassName}>
              <Icon glyph={arrowIcon} width={15} height={10} />
            </div>
          </div>
          <div className={containerClassName}>
            <Spacing size={2}>{children}</Spacing>
          </div>
        </Spacing>
      </GridItem>
    );
  }
}
