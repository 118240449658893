import React from 'react';
import PropTypes from 'prop-types';

import hexToRgb from '../../../../helpers/hexToRgb';
import getAdaptiveColour from '../../../../helpers/getAdaptiveColour';

import ColourCircle from '../../../atoms/ColourCircle';
import Icon from '../../../atoms/Icon';

import checkIcon from '../../../../img/sprites/check.svg';

import generalStyles from './style/general.module.css';

const iconSizes = {
  m: 16,
  l: 18
};

export default function ColourCircleRadioBtn({
  name,
  hex,
  size,
  id,
  children,
  ...domProps
}) {
  const hexCode = hex && hex.replace('#', '');
  const rgb = hexToRgb(hex);
  const tickColour = getAdaptiveColour(rgb);
  return (
    <label htmlFor={id} className={generalStyles.holder}>
      <input
        type="radio"
        name={name}
        id={id || name}
        className={generalStyles.input}
        {...domProps}
      />
      <div className={generalStyles.container}>
        <div className={generalStyles.circle}>
          <ColourCircle code={hexCode} size={size} />
          <div className={generalStyles.checkbox}>
            <Icon
              glyph={checkIcon}
              fill={tickColour}
              width={iconSizes[size]}
              height={iconSizes[size]}
            />
          </div>
        </div>
        {children}
      </div>
    </label>
  );
}

ColourCircleRadioBtn.propTypes = {
  name: PropTypes.string,
  hex: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node
};

ColourCircleRadioBtn.defaultProps = {
  size: 'l'
};
