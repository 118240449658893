import React from 'react';

import PageWrap from '../../../atoms/PageWrap';
import Spacing from '../../../atoms/Spacing';
import Heading from '../../../atoms/Heading';
import Paragraph from '../../../atoms/Paragraph';
import MaxWidth from '../../../atoms/MaxWidth';
import Link from '../../../atoms/Link';
import { Grid, GridItem } from '../../../atoms/Grid';

import generalStyles from './style/general.module.css';

const CaseStudies = ({ title, description, caseStudies }) => {
  return (
    <div className={generalStyles.page}>
      <PageWrap>
        <Spacing size={[2, 3, 4, 5, 6]} type="padding" position="t">
          <Spacing size={2} position="b">
            <Heading size={['l', 'xl', 'xxl']} center tag="h2">
              {title}
            </Heading>
          </Spacing>
          <MaxWidth center value={380}>
            <Spacing size={[2, 4]} position="b">
              <Paragraph center>{description}</Paragraph>
            </Spacing>
          </MaxWidth>
        </Spacing>
      </PageWrap>

      <Spacing size={[2, 3, 4, 5, 6]} position="b" type="padding">
        <Grid gap={0}>
          {caseStudies.map((item, index) => {
            return (
              <GridItem key={index} columnSize={[6, 6, 3]}>
                <Link
                  className={generalStyles.caseStudy}
                  style={{
                    backgroundImage: `url(${item.image.url})`
                  }}
                  to={{
                    type: 'CASE_STUDY',
                    payload: {
                      category: item.category,
                      slug: item.slug
                    }
                  }}
                >
                  <div className={generalStyles.caseStudyInner}>
                    <Heading
                      className="hyphens"
                      color="white"
                      size={['s', 'm', 'm', 'l']}
                    >
                      {item.title}
                    </Heading>
                  </div>
                </Link>
              </GridItem>
            );
          })}
        </Grid>
      </Spacing>
    </div>
  );
};

export default CaseStudies;
