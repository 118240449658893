export const campaignNamePropTypes = (props, propName, componentName) => {
  if (props.campaignName && typeof props.campaignName !== 'string') {
    return new Error(`${propName} must be of type string in ${componentName}`);
  }

  if (!props.campaignName && !props.creatorName) {
    return new Error(
      `One of props 'campaignName' or 'creatorName' was not specified in ${componentName}`
    );
  }
};

export const creatorNamePropTypes = (props, propName, componentName) => {
  if (props.creatorName && typeof props.creatorName !== 'string') {
    return new Error(`${propName} must be of type string in ${componentName}`);
  }

  if (!props.campaignName && !props.creatorName) {
    return new Error(
      `One of props 'campaignName' or 'creatorName' was not specified in ${componentName}`
    );
  }
};
