import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Spacing from '../../../atoms/Spacing';

import generalStyles from './style/general.module.css';

const sizeMap = {
  xs: {
    x: [1, 3],
    y: 1
  },
  s: {
    x: [1, 3],
    y: 3
  },
  m: {
    x: [1, 3, 5],
    y: [1, 3, 5]
  }
};

export default function Box({ children, size, className }) {
  const boxClassNames = classNames(generalStyles.box, className);
  const boxSize = typeof size == 'string' ? sizeMap[size] : size;
  return (
    <div className={boxClassNames}>
      <Spacing size={boxSize.y} position="y" type="padding">
        <Spacing size={boxSize.x} position="x" type="padding">
          {children}
        </Spacing>
      </Spacing>
    </div>
  );
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOfType([
    PropTypes.oneOf(['xs', 's', 'm']),
    PropTypes.shape({
      x: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.number
      ]),
      y: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.number
      ])
    })
  ])
};

Box.defaultProps = {
  size: 'm'
};
