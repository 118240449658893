import produce from 'immer';

const initialState = {
  bySlug: {}
};

export default (state = initialState, action) =>
  produce(state, newState => {
    const { type, payload, meta } = action;

    if (type === 'FETCH_SUPPORT_FULFILLED') {
      newState.bySlug[meta.category] = payload;
      return newState;
    }
  });
