import React from 'react';
import classNames from 'classnames';

import { Grid, GridItem } from '../../../components/atoms/Grid';
import Spacing from '../../../components/atoms/Spacing';
import Heading from '../../../components/atoms/Heading';
import Paragraph from '../../../components/atoms/Paragraph';
import Link from '../../../components/atoms/Link';

import styles from './styles/general.module.css';

const EmptyProfile = ({ profileName, isOwner }) => {
  return (
    <Grid gap={[0, 1]} align="start">
      <GridItem key={'left'} columnSize={[12, 12, 4, 4, 33, 4]}>
        <div
          className={classNames(
            styles.emptyCampaigns,
            styles.emptyCampaignsSide
          )}
        ></div>
      </GridItem>
      <GridItem key={'center'} columnSize={[12, 12, 4, 4, 33, 4]}>
        <div className={styles.emptyCampaigns}>
          <div className={styles.emptyCampaignsText}>
            <Spacing size={3} position="x">
              <Spacing size={1} position="b">
                <Heading size="xs">No Campaigns</Heading>
              </Spacing>
              {isOwner ? (
                <Paragraph size="xxs">
                  It looks like you haven't got any campaigns right now.{' '}
                  <Link to="/create">Launch a campaign</Link> and it will appear
                  here.
                </Paragraph>
              ) : (
                <Paragraph size="xxs">
                  It looks like {profileName} hasn’t got any live campaigns
                  right now.
                  {/* Follow them to be notified when they launch one. */}
                </Paragraph>
              )}
            </Spacing>
          </div>
        </div>
      </GridItem>
      <GridItem key={'right'} columnSize={[12, 12, 4, 4, 33, 4]}>
        <div
          className={classNames(
            styles.emptyCampaigns,
            styles.emptyCampaignsSide
          )}
        ></div>
      </GridItem>
    </Grid>
  );
};

export default EmptyProfile;
