import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import generalStyles from './style/general.module.css';
import sizeStyles from './style/size.module.css';

const cx = classNames.bind({ ...generalStyles, ...sizeStyles });

export default function ButtonCheckbox({
  name,
  id,
  children,
  value,
  kind,
  size,
  onChange,
  ...domProps
}) {
  return (
    <label htmlFor={name} className={generalStyles.holder}>
      <input
        type="checkbox"
        name={name}
        id={id || name}
        value={value}
        checked={value}
        onChange={onChange}
        className={generalStyles.input}
        {...domProps}
      />
      <div className={cx('checkbox', kind, size)}>{children}</div>
    </label>
  );
}

ButtonCheckbox.defaultProps = {
  size: 'm'
};

ButtonCheckbox.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  value: PropTypes.bool,
  kind: PropTypes.oneOf(['ghost']),
  size: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]).isRequired,
  onChange: PropTypes.func
};
