import isPromise from 'is-promise';

import * as userActions from '../actions/user';

import config from '../config';

const getFieldsError = (errors) => {
  return Object.keys(errors).reduce((acc, key) => {
    if (errors[key].errors) {
      return { ...acc, [key]: errors[key].errors.join('\n') };
    }
    return acc;
  }, {});
};

export default function errorMiddleware() {
  return (store) => (next) => (action) => {
    // If not a promise, continue on
    if (!isPromise(action.payload)) {
      return next(action);
    }

    // Dispatch initial pending promise, but catch any errors
    return next(action).catch((error) => {
      // We handle only Everpress API errors here
      if (error.config && error.config.baseURL !== config.baseApiUri) {
        throw error;
      }

      if (!error.response) {
        throw error;
      }

      // Custom API errors interpreter
      if (error.response.status < 500) {
        if (error.response.status === 422) {
          error.message = error.response.data.detail;
        }
        if (error.response.data.error) {
          error.message = error.response.data.error.message;
        }
        if (error.response.data.message) {
          error.message = error.response.data.message;
        }
        if (error.response.data.errors) {
          // handle nested errors
          if (error.response.data.errors.errors) {
            error.message = error.response.data.errors.errors.join(' \n');
          } else if (error.response.data.errors.children) {
            error.message = Object.values(
              getFieldsError(error.response.data.errors.children)
            ).join('\n');
          } else {
            error.message = error.response.data.errors.join(' \n');
          }
        }
      }

      if (error.response.status === 401) {
        // Middlewares are hard to debug, better be verbose here
        console.warn('errorMiddleware - 401 error, logout user');

        // remove token from the app state.
        // bypass the throw as we don't want to report this error to Sentry.
        store.dispatch(userActions.logout());

        return error;
      }

      throw error;
    });
  };
}
