import React from 'react';

import { ButtonNew as Button } from '../../../atoms/Button';
import Heading from '../../../atoms/Heading';
import Paragraph from '../../../atoms/Paragraph';
import Spacing from '../../../atoms/Spacing';

import generalStyles from './style/general.module.css';

const ResetPasswordSuccessForm = ({ onUserStageChange, email }) => {
  return (
    <>
      <Heading size="xs">Reset Password</Heading>

      <Spacing size={1} position="t">
        <Paragraph size="xxs">
          You should receive an email with a link to reset your email in a few
          minutes.
        </Paragraph>
      </Spacing>

      <Spacing size={15} position="y" type="padding">
        <div className={generalStyles.email}>
          <Spacing size={15} position="y" type="padding">
            <Paragraph size="xxs" color="grey-dark">
              {email}
            </Paragraph>
          </Spacing>
        </div>
      </Spacing>

      <Spacing size={4} position="b">
        <Button fullWidth onClick={() => onUserStageChange('signIn')}>
          Back to sign in
        </Button>
      </Spacing>
    </>
  );
};

export default ResetPasswordSuccessForm;
