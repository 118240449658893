import produce from 'immer';

const initialState = {
  isCategoryChipsOpen: true,
  featuredProfiles: [],
  featuredCampaigns: {
    byTag: {},
    status: 'idle'
  },
  categoryTags: {
    tags: [],
    status: 'idle'
  }
};

export default (state = initialState, action) =>
  produce(state, (newState) => {
    const { type, payload, meta } = action;
    switch (type) {
      case 'GET_FEATURED_PROFILES_FULFILLED':
        newState.featuredProfiles = payload;

        return newState;
      case 'GET_CATEGORY_TAGS_PENDING':
        newState.categoryTags.status = 'loading';
        return newState;
      case 'GET_CATEGORY_TAGS_FULFILLED':
        newState.categoryTags.tags = payload;
        newState.categoryTags.status = 'resolved';
        return newState;
      case 'CATEGORY_CHIPS_CLOSED': {
        newState.isCategoryChipsOpen = false;
        return newState;
      }
      case 'GET_FEATURED_CAMPAIGNS_PENDING': {
        newState.featuredCampaigns.status = 'pending';
        return newState;
      }
      case 'GET_FEATURED_CAMPAIGNS_FULFILLED': {
        (newState.featuredCampaigns.byTag[meta.tag] ??= {}).campaigns =
          payload.campaigns;
        newState.featuredCampaigns.status = 'resolved';
        return newState;
      }
      case 'GET_FEATURED_CAMPAIGNS_REJECTED': {
        newState.featuredCampaigns.status = 'rejected';
        return newState;
      }
      default:
        return state;
    }
  });
