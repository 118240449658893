export const convertToSubunit = amount => {
  return Math.round(amount * 100);
}

export const getResponseValue = request => {
  return request
    .then(data => ([data.value, undefined]))
    .catch(err => ([undefined, err]))
}

export const wait = ms => new Promise(resolve => {
  setTimeout(() => resolve(), ms);
});
