import get from 'lodash/get';
import * as prismicH from '@prismicio/helpers';

export const getText = (data) => {
  return get(data, '[0].text', null);
};

export const getRichText = (data, asText) => {
  if (asText) {
    return prismicH.asText(data);
  } else {
    return prismicH.asHTML(data);
  }
};
