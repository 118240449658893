import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import config from '../../../config';

import { theme } from '../../../theme-config';

import Image from '../../../components/atoms/Image';

import ProductThumbnail from '../../../components/molecules/Thumbnails/ProductThumbnail';
import { Rail, RailSlide } from '../../../components/molecules/Rail';

const RecommendedItems = ({ items = [] }) => {
  const dispatch = useDispatch();

  const handleRailItemClick = () => {
    dispatch({
      type: 'RAIL_CAMPAIGN_CLICK',
      payload: {
        type: 'Recommended Items'
      }
    });
  };

  if (!items || !items.length) {
    return null;
  }

  return (
    <Rail type="Recommended Items" title="You may also like">
      {items.map((item) => {
        return (
          <RailSlide key={item.campaignId}>
            <div className="py-15" onClick={handleRailItemClick}>
              <ProductThumbnail
                image={
                  <Image
                    src={item.imageUrl}
                    alt={item.imageAlt}
                    width="875"
                    height={875 / config.productShotsRatio}
                    placeholderColor={
                      item.greyBackground
                        ? theme.colors['dark-grey']
                        : '#FFFFFF'
                    }
                  />
                }
                title={item.title}
                subTitle={item.subTitle || ''}
                price={item.prices}
                timeLeft={item.endAt}
                slug={item.slug}
                isGreyBg={item.greyBackground}
              />
            </div>
          </RailSlide>
        );
      })}
    </Rail>
  );
};

export default RecommendedItems;

RecommendedItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      campaignId: PropTypes.number,
      slug: PropTypes.string,
      imageUrl: PropTypes.string,
      imageAlt: PropTypes.string,
      endAt: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string
      ]),
      prices: PropTypes.arrayOf(
        PropTypes.shape({
          iso: PropTypes.string,
          price: PropTypes.number,
          default: PropTypes.bool
        })
      ).isRequired,
      greyBackground: PropTypes.bool
    }).isRequired
  )
};
