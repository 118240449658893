import * as React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { GridContext } from '../';

import propToClassName from '../../../../helpers/propToClassName';

const gapMap = {
  '0': 'px-0',
  '05': 'px-05',
  '1': 'px-1',
  '15': 'px-15',
  '2': 'px-2',
  '25': 'px-25',
  '3': 'px-3',
  '4': 'px-4',
  '5': 'px-5'
};

// columnSize can be a number from 1 to 12
// we need to map it to correct width classNames
const columnSizeMap = {
  '1': 'w-8',
  '2': 'w-16',
  '25': 'w-20',
  '3': 'w-25',
  '4': 'w-33',
  '5': 'w-41',
  '6': 'w-50',
  '7': 'w-58',
  '8': 'w-66',
  '9': 'w-75',
  '10': 'w-83',
  '11': 'w-91',
  '12': 'w-100',
  auto: 'w-auto'
};

FlexboxGridItem.propTypes = {
  children: PropTypes.node,
  columnSize: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string
  ]),
  className: PropTypes.string
};

export default function FlexboxGridItem({ children, columnSize, className }) {
  const columnSizeClassName = propToClassName(columnSize, columnSizeMap);

  return (
    <GridContext.Consumer>
      {(value) => {
        const gapClassName = propToClassName(value.gap, gapMap);
        const gridItemClassName = classNames(
          columnSizeClassName,
          gapClassName,
          className
        );

        return <div className={gridItemClassName}>{children}</div>;
      }}
    </GridContext.Consumer>
  );
}
