import React, { Fragment } from 'react';

import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';
import Icon from '../../../components/atoms/Icon';

import { CtaBox } from '../../../components/molecules/Box';

import StripeContainer from '../Stripe/StripeContainer';

import paypalLogo from '../../../img/sprites/paypal.svg';

import StripeForm from './StripeForm';
import PayPalForm from './PayPalForm';
import FreeForm from './FreeForm';

const CheckoutPaymentDetails = ({
  onSave,
  onEdit,
  isSaved,
  currency,
  priceTotal,
  paymentProvider,
  initialValues,
  isGuest
}) => {
  const handleSubmit = (values, actions) => {
    onSave(values, actions);
  };

  const handleEditStep = () => {
    onEdit();
  };

  return (
    <Fragment>
      {isSaved ? (
        <Spacing size={1} position="t">
          <CtaBox
            title="4. Payment Details"
            cta="Edit"
            onClick={handleEditStep}
          >
            {paymentProvider === 'stripe' ? (
              <Paragraph size="xxs">
                Selected payment: Credit/Debit Card
              </Paragraph>
            ) : (
              <Icon
                className="-my-15 -mx-1"
                glyph={paypalLogo}
                width={110}
                height={65}
              />
            )}
          </CtaBox>
        </Spacing>
      ) : paymentProvider === 'stripe' ? (
        <StripeContainer>
          <Fragment>
            <Paragraph size="xxs">
              All transactions are encrypted and secure
            </Paragraph>
            <StripeForm
              initialValues={initialValues}
              handleSubmit={handleSubmit}
              priceTotal={priceTotal}
              currency={currency}
              isGuest={isGuest}
            />
          </Fragment>
        </StripeContainer>
      ) : paymentProvider === 'paypal' ? (
        <PayPalForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          priceTotal={priceTotal}
          currency={currency}
          isGuest={isGuest}
        />
      ) : (
        <FreeForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          isGuest={isGuest}
        />
      )}
    </Fragment>
  );
};

export default CheckoutPaymentDetails;
