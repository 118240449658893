import merge from 'lodash/merge';
import find from 'lodash/find';

const deepMergeByKey = (arr1, arr2, key) => {
  let criteria = {};
  criteria[key] = null;

  return arr1.map((item) => {
    criteria[key] = item[key];
    return merge(item, find(arr2, criteria));
  });
};

export default deepMergeByKey;
