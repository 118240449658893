import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonNew as Button } from '../../atoms/Button';
import Icon from '../../atoms/Icon';

import SearchBoxOld from './SearchBoxOld';

import crossIcon from '../../../img/sprites/round-cross.svg';
import searchIcon from '../../../img/sprites/search-new.svg';

import generalStyles from './style/general.module.css';

const Search = ({ navRef }) => {
  const formRef = useRef();
  const searchRef = useRef();
  const searchBoxRef = useRef();
  const [isSearchBoxOpen, setSearchBoxOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location);

  const openSearchBox = async () => {
    setSearchBoxOpen(true);
    searchBoxRef.current?.focus();

    dispatch({
      type: 'SET_GLOBAL_SEARCH_OPEN',
      payload: true
    });
  };

  const closeSearchBox = useCallback(() => {
    setSearchBoxOpen(false);
    searchBoxRef.current?.blur();
    formRef.current?.resetForm();

    dispatch({
      type: 'SET_GLOBAL_SEARCH_OPEN',
      payload: false
    });
  }, [dispatch]);

  useEffect(() => {
    closeSearchBox();
  }, [closeSearchBox, location.type]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isSearchBoxOpen &&
        navRef?.current?.contains(event.target) &&
        !searchRef?.current?.contains(event.target)
      ) {
        event.preventDefault();
        closeSearchBox();
      }
    };

    const handleKeyDown = (event) => {
      if (isSearchBoxOpen && event.key === 'Escape') {
        event.preventDefault();
        closeSearchBox();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeSearchBox, isSearchBoxOpen, navRef]);

  return (
    <>
      {isSearchBoxOpen ? (
        <>
          <Button
            size="small"
            kind="ghost"
            onClick={closeSearchBox}
            className="p-1"
            data-test-id="search-close-btn"
          >
            <Icon glyph={crossIcon} width="19" height="24" />
          </Button>

          <div
            id="overlay"
            onClick={closeSearchBox}
            className={generalStyles.overlay}
          />
        </>
      ) : (
        <Button
          size="small"
          kind="ghost"
          onClick={openSearchBox}
          className="p-1"
          data-test-id="search-open-btn"
        >
          <Icon glyph={searchIcon} width="19" height="19" />
        </Button>
      )}

      <SearchBoxOld
        ref={searchRef}
        formRef={formRef}
        searchBoxRef={searchBoxRef}
        isOpen={isSearchBoxOpen}
        handleClose={closeSearchBox}
      />
    </>
  );
};

export default Search;
