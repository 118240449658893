import image1 from '../../../img/pages/about/row-1.jpg';
import image2 from '../../../img/pages/about/row-2.jpg';
import image3 from '../../../img/pages/about/row-3.jpg';
import image4 from '../../../img/pages/about/row-4.jpg';
import image5 from '../../../img/pages/about/row-5.jpg';
import image6 from '../../../img/pages/about/row-6.jpg';
import image7 from '../../../img/pages/about/row-7.jpg';
import image8 from '../../../img/pages/about/row-8.jpg';

const images = [
  {
    id: 0,
    image: image1
  },
  {
    id: 1,
    image: image2
  },
  {
    id: 2,
    image: image3
  },
  {
    id: 3,
    image: image4
  },
  {
    id: 4,
    image: image5
  },
  {
    id: 5,
    image: image6
  },
  {
    id: 6,
    image: image7
  },
  {
    id: 7,
    image: image8
  }
];

export default images;
