const customerServiceLinks = [
  {
    link: '/creator-toolkit/ive-placed-my-order-on-a-campaign-what-next/',
    content: 'I’ve placed an order, what next?'
  },
  {
    link: '/creator-toolkit/when-will-my-order-arrive/',
    content: 'When will my order arrive?'
  },
  {
    link: '/creator-toolkit/how-is-my-package-sent/',
    content: 'How is my package sent?'
  },
  {
    link: '/creator-toolkit/make-changes-to-my-order/',
    content: 'How can I make changes to my order?'
  },
  {
    link: '/creator-toolkit/tracking-information-my-package/',
    content: 'Can I receive tracking information for my package?'
  },
  {
    link: '/creator-toolkit/request-exchange-refund-my-order/',
    content: 'Can I request an exchange or refund for my order?'
  },
  {
    link:
      '/creator-toolkit/what-happens-if-i-buy-from-a-campaign-that-is-unsuccessful/',
    content: 'What happens if I buy from a campaign that is unsuccessful?'
  }
];

export default customerServiceLinks;
