import React from 'react';
import { useDispatch } from 'react-redux';
import Link from 'redux-first-router-link';
import PropTypes from 'prop-types';

import WrapIf from '../../../utils/WrapIf';

import Paragraph from '../../../atoms/Paragraph';
import Spacing from '../../../atoms/Spacing';
import Image from '../../../atoms/Image';

import { Rail, RailSlide } from '..';

import generalStyles from '../style/general.module.css';

const UGCRail = ({ ugcRailData }) => {
  const dispatch = useDispatch();

  return (
    <Rail type="UGC" title={ugcRailData.title} subtitle={ugcRailData.subtitle}>
      {ugcRailData.campaigns.map((campaign) => {
        return (
          <RailSlide key={campaign.image.url}>
            <div
              className="py-15"
              onClick={() =>
                dispatch({
                  type: 'RAIL_CAMPAIGN_CLICK',
                  payload: {
                    type: 'UGC'
                  }
                })
              }
            >
              <div className={generalStyles.container}>
                <WrapIf
                  condition={campaign.slug}
                  wrapper={(children) => (
                    <Link to={campaign.slug} className={generalStyles.link}>
                      {children}
                    </Link>
                  )}
                >
                  <>
                    <Spacing position="b" size={1}>
                      <Image
                        src={campaign.image.url}
                        alt={campaign.title}
                        width="290"
                        height="290"
                      />
                    </Spacing>
                    <Paragraph color="grey-dark" size="xxs">
                      {campaign.caption}
                    </Paragraph>
                  </>
                </WrapIf>
              </div>
            </div>
          </RailSlide>
        );
      })}
    </Rail>
  );
};

UGCRail.propTypes = {
  ugcRailData: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    campaigns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        caption: PropTypes.string,
        slug: PropTypes.string,
        link: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string
        })
      })
    )
  }).isRequired
};

export default UGCRail;
