import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import some from 'lodash/some';
import useResizeAware from 'react-resize-aware';
import classNames from 'classnames';

import config from '../../../config';
import { theme } from '../../../theme-config';

import { ButtonNew as Button } from '../../../components/atoms/Button';
import Spacing from '../../../components/atoms/Spacing';
import Heading from '../../../components/atoms/Heading';
import Paragraph from '../../../components/atoms/Paragraph';
import { TabNew as Tab, TabsList } from '../../../components/atoms/Tabs';
import { TooltipNew } from '../../../components/atoms/Tooltip';

import arrowDownFull from '../../../img/sprites/arrow-down-full.svg';

import generalStyles from './style/general.module.css';

const MOBILE_MQ = `(max-width: calc(${theme.screens.mdlg} - 1px))`;

const mqReporter = (target) => ({
  width: target != null ? target.clientWidth : null,
  isMobile: target != null ? window.matchMedia(MOBILE_MQ).matches : null
});

const MoreCollectionsDropdownContent = ({
  content,
  handleCollectionChange,
  activeCollection
}) => {
  return (
    <TabsList
      id="collections-rail"
      direction="vertical"
      onChange={handleCollectionChange}
      selectedTabId={activeCollection}
      justify="start"
    >
      {content.map((item) => {
        return (
          <Tab
            key={item.slug}
            id={item.slug}
            data-test-id="collections-rail-item"
            buttonClassName={generalStyles.dropdownTab}
          >
            {item.name}
          </Tab>
        );
      })}
    </TabsList>
  );
};

const ShopCollections = ({
  collections,
  activeCollection,
  onCollectionChange
}) => {
  const dispatch = useDispatch();
  const collectionsRef = useRef(null);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const location = useSelector((state) => state.location);
  const chunkedCollections = chunk(collections, 6);
  const [resizeListener, sizes] = useResizeAware(mqReporter);
  const isMobile = sizes.isMobile;
  const visibleCollections = isMobile ? collections : chunkedCollections[0];

  const handleCollectionChange = (slug) => {
    onCollectionChange(slug);

    dispatch({
      type: 'COLLECTIONS_NAV_CLICKED',
      payload: {
        tag: slug
      }
    });
  };

  useEffect(() => {
    const locationTag = location.payload.tag;
    const selectedCollection = collections.find(
      (collection) => collection.slug === locationTag
    );
    const featuredCollection = collections.find(
      (collection) => collection.slug === config.shop.defaultTag.value
    );

    if (!selectedCollection && !locationTag) {
      setSelectedCollection(featuredCollection);
    } else {
      setSelectedCollection(selectedCollection || null);
    }
  }, [location, collections]);

  useEffect(() => {
    const activeElement = document.getElementById(activeCollection);

    if (!!collections.length && !!activeElement) {
      collectionsRef.current.scrollTo({
        left: activeElement.offsetLeft - collectionsRef.current.offsetLeft
      });
    }
  }, [activeCollection, collections]);

  if (collections.length < 2) {
    return <div />;
  }

  return (
    <>
      {resizeListener}
      <div className={generalStyles.tabsList}>
        <Spacing size={[0, 0, 0, 2]} position="t" type="margin">
          <Spacing size={[1, 1, 1, 0]} position="y" type="padding">
            <TabsList
              id="collections-rail"
              forwardedRef={collectionsRef}
              direction="horizontal"
              onChange={handleCollectionChange}
              selectedTabId={activeCollection}
              justify={['start', 'start', 'start', 'center']}
              data-test-id="collections-rail"
            >
              {visibleCollections.map((item, index) => {
                return (
                  <Tab
                    key={item.slug}
                    id={item.slug}
                    data-test-id="collections-rail-item"
                    className={classNames({
                      'z-10': index === visibleCollections.length - 1
                    })}
                  >
                    {item.name}
                  </Tab>
                );
              })}
              {isMobile || !chunkedCollections[1] ? null : (
                <Spacing size={1} position="l">
                  <TooltipNew
                    hasListContent
                    content={
                      <MoreCollectionsDropdownContent
                        content={chunkedCollections[1]}
                        handleCollectionChange={handleCollectionChange}
                        activeCollection={activeCollection}
                      />
                    }
                    placement="bottom"
                    offset={7}
                    align="start"
                    alignOffset={-10}
                    id="more-collections-dropdown"
                  >
                    <Button
                      type="button"
                      kind="ghost"
                      size="small"
                      color="black"
                      icon={arrowDownFull}
                      state={
                        some(chunkedCollections[1], {
                          slug: activeCollection
                        })
                          ? 'active'
                          : 'default'
                      }
                    >
                      More
                    </Button>
                  </TooltipNew>
                </Spacing>
              )}
            </TabsList>
          </Spacing>
        </Spacing>
      </div>
      <div data-test-id="collections-rail" className={generalStyles.container}>
        {selectedCollection && (
          <div className={generalStyles.container}>
            <Spacing size={4} position="t">
              <Heading size="s">{selectedCollection.name}</Heading>
              {selectedCollection.description && (
                <Spacing size={1} position="t">
                  <Paragraph size="xxs" color="grey-dark">
                    {selectedCollection.description}
                  </Paragraph>
                </Spacing>
              )}
            </Spacing>
          </div>
        )}
      </div>
    </>
  );
};

ShopCollections.defaultProps = {
  collections: []
};

ShopCollections.propTypes = {
  collections: PropTypes.array,
  activeCollection: PropTypes.string,
  defaultCollection: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  onCollectionChange: PropTypes.func.isRequired
};

export default ShopCollections;
