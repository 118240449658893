import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import config from '../../../config';

const TrustBox = ({ align }) => {
  const [isMouseOverCaptured, setMouseOverCaptured] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const trustpilotClient = window.Trustpilot;
  const isContained = ['left', 'right'].includes(align);
  const width = isContained ? '243px' : '100%';
  const marginLeft = isContained ? '-4px' : 0;

  useEffect(() => {
    if (
      trustpilotClient &&
      trustpilotClient.Modules?.WidgetManagement?.stats?.findAndApplyCalls < 2
    ) {
      try {
        trustpilotClient.loadFromElement(ref.current, true);
      } catch (err) {
        console.warn('Failed to load TrustBox');
      }
    }
  }, [trustpilotClient]);

  const handleWidgetMouseOver = () => {
    if (!isMouseOverCaptured) {
      dispatch({ type: 'TRUSTBOX_MOUSE_OVER' });

      setMouseOverCaptured(true);
    }
  };

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={config.defaultLocale}
      data-template-id={config.trustpilot.templateId}
      data-businessunit-id={config.trustpilot.businessUnitId}
      data-style-height="24px"
      data-style-width={width}
      data-theme="light"
      data-style-alignment={align}
      style={{ marginLeft }}
      onMouseOverCapture={handleWidgetMouseOver}
    />
  );
};

TrustBox.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right'])
};

TrustBox.defaultProps = {
  align: 'center'
};

export default TrustBox;
