import React from 'react';
import PropTypes from 'prop-types';

import propToClassName from '../../../helpers/propToClassName';

Spacing.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
    PropTypes.number,
    PropTypes.string
  ]),
  position: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(['all', 'y', 'x', 't', 'b', 'r', 'l'])),
    PropTypes.oneOf(['all', 'y', 'x', 't', 'b', 'r', 'l'])
  ]),
  type: PropTypes.oneOf(['margin', 'padding'])
};

export default function Spacing({ children, size, type, position }) {
  const newType = type === 'margin' ? 'm' : 'p';
  const mergedProps = mergeProps(newType, position, size);
  const spacingClassNames = propToClassName(mergedProps);

  return <div className={spacingClassNames}>{children}</div>;
}

function mergeProps(type, position, size) {
  if (Array.isArray(size)) {
    return size.map((item, i) => {
      let pos = Array.isArray(position) ? position[i] : position;
      pos = pos === 'all' ? '' : pos;
      return `${type}${pos}-${item}`;
    });
  } else {
    if (Array.isArray(position)) {
      return position.map(pos => {
        return `${type}${pos === 'all' ? '' : pos}-${size}`;
      });
    } else {
      return `${type}${position === 'all' ? '' : position}-${size}`;
    }
  }
}

Spacing.defaultProps = {
  size: 5,
  position: 'y',
  type: 'margin'
};
