const intellectualPropertyPolicy = [
  {
    sections: [
      {
        type: 'paragraph',
        content:
          'To see our policies that apply to you, we direct you to our <a href="/terms-of-use">Terms of Use</a>, <a href="/terms-for-sellers">Terms for Sellers</a> and <a href="/use-policy">Acceptable Use Policy</a>. This guidance does not supplement those policies but sets out guidance on the various types of IP rights that exist, the tests for infringement, and what you are agreeing to by uploading your content onto our website:'
      },
      {
        type: 'list',
        content: [
          'You have conducted all necessary due diligence checks on your content and are comfortable that you are taking all the risks;',
          'Your content does not use any sign that is identical to a registered trade mark;',
          'Your content does not use any sign that is similar to a registered trade mark, and where there could exist a possibility of confusion;',
          'Your content does not use any sign that another third party has goodwill in, and which your use could be a misrepresentation of;',
          'Your content does not use a registered design (in whole or part) in a way which could create the same overall impression on the informed user;',
          'Your content has not copied an unregistered design (in whole or part) in a way which could create the same overall impression on the informed user;',
          'Your content has not copied the copyright of another third party (in whole or part);',
          'Your content does not refer to an individual (whether alive or dead) or a company in a slanderous or libellous way, in any manner that would damage their reputation, or in a way which would imply a false endorsement or association.'
        ]
      },
      {
        type: 'paragraph',
        content:
          '<b>We reserve the right to remove content that breaches the above.</b>'
      },
      {
        type: 'paragraph',
        content:
          'If we are contacted by any rights holder in relation to the designs which you have uploaded onto our site, we have to provide them with your contact details and, by using our site, you have agreed to deal with the rights holder or owner directly – meaning you are fully liable for any infringements. In such circumstances, we would expect the designs to be removed from our site.'
      }
    ]
  },
  {
    title: 'Registered Trade Mark (TM)',
    sections: [
      {
        type: 'paragraph',
        content: '<b>What are they?</b>'
      },
      {
        type: 'paragraph',
        content:
          'Signs that are used to identify the origin of goods and/or services, often referred to as a “badge of origin”.'
      },
      {
        type: 'paragraph',
        content:
          'A TM is protected when it is registered at an intellectual property office in respect of a particular set of goods and/or services.'
      },
      {
        type: 'paragraph',
        content:
          'TM protection can last indefinitely if the owner continues to renew the TM every 10 years.'
      },
      {
        type: 'paragraph',
        content: '<b>Infringement Tests</b>'
      },
      {
        type: 'paragraph',
        content:
          'A trade mark is infringed by use of an identical trade mark, or use of a confusingly similar trade mark.'
      },
      {
        type: 'paragraph',
        content:
          'In the case of Adidas, an identical trade mark would be the use of the word ADIDAS or their logos for example:'
      },
      {
        type: 'image',
        content: 'adidas'
      },
      {
        type: 'paragraph',
        content:
          'By comparison, a confusingly similar trade mark to Adidas could be a logo like the below:'
      },
      { type: 'image', content: 'addicted' },
      {
        type: 'paragraph',
        content:
          'Similarly, in the case of Ralph Lauren, the following trade mark was found to be confusingly similar:'
      },
      { type: 'image', content: 'rcbPolo' }
    ]
  },
  {
    title: 'Unregistered Rights',
    sections: [
      {
        type: 'paragraph',
        content: '<b>What are they?</b>'
      },
      {
        type: 'paragraph',
        content:
          'A mark can still be protected even if it has not been officially registered. This is through the use of a passing off claim. This requires goodwill, misrepresentation and damage.'
      },
      {
        type: 'paragraph',
        content: '<b>Examples</b>'
      },
      {
        type: 'paragraph',
        content:
          'An unregistered trade mark right will be infringed when the brand has not been registered, but you are using something that misrepresents the brands goodwill (and causes damage). In relation to Nike, they were found to pass-off a company called Frank Industries&#39; rights in LNDR by using the below:'
      },
      { type: 'image', content: 'ldnr' },
      {
        type: 'paragraph',
        content:
          'As another example, Topshop passed off Rihanna&#39;s unregistered rights in the below by using this without her permission, as it implied a false endorsement:'
      },
      { type: 'image', content: 'rihanna' }
    ]
  },
  {
    title: 'Registered Designs (RD) and Unregistered Design Rights (UDR)',
    sections: [
      {
        type: 'paragraph',
        content: '<b>What are they?</b>'
      },
      {
        type: 'paragraph',
        content:
          'A design can be protected if it is registered at the relevant intellectual property office. An RD protects how the design looks (under English law “the lines, contours, colours, shape, texture or materials of the product or its ornamentation”).'
      },
      {
        type: 'paragraph',
        content: '<b>Infringement Tests</b>'
      },
      {
        type: 'paragraph',
        content:
          'An RD is infringed when the use of a design creates the same overall impression on the informed user as the protected design without the owner&#39;s consent.'
      },
      {
        type: 'paragraph',
        content:
          'In the case of Nike, the following could be deemed RD, UDR, copyright or passing-off infringements:'
      },
      { type: 'image', content: 'nike' },
      {
        type: 'paragraph',
        content:
          'As another example, Trunki&#39;s registered designs were infringed by the below:'
      },
      { type: 'image', content: 'trunki' },
      {
        type: 'paragraph',
        content:
          'It is possible to infringe a RD, by using the whole design, or simply part of it, provided it creates the same overall impression on the informed user. UDR&#39;s are the same as the above, but are not registered, and require copying to be shown.'
      }
    ]
  }
];

export default intellectualPropertyPolicy;
