import React from 'react';

import { Grid, GridItem } from '../../../atoms/Grid';
import { ButtonNew } from '../../../atoms/Button';
import Heading from '../../../atoms/Heading';
import Image from '../../../atoms/Image';
import MaxWidth from '../../../atoms/MaxWidth';
import PageWrap from '../../../atoms/PageWrap';
import Spacing from '../../../atoms/Spacing';

import generalStyles from './style/general.module.css';

const Benefit = ({
  isReverse,
  image,
  label,
  title,
  copy,
  buttonLabel,
  buttonSlug
}) => {
  return (
    <PageWrap className={generalStyles.container}>
      <Spacing size={[2, 3, 4, 5, 6]} type="padding">
        <MaxWidth value={876} center>
          <Grid
            gap={2}
            align="center"
            row={
              isReverse
                ? ['flex-row', 'flex-row-reverse']
                : ['flex-row', 'flex-row']
            }
          >
            <GridItem columnSize={[12, 6]}>
              <Spacing size={2} position="b">
                <Image
                  src={image.url}
                  alt={image.alt}
                  width="415"
                  height="415"
                />
              </Spacing>
            </GridItem>
            <GridItem columnSize={[12, 6]}>
              {label && (
                <Spacing size={[2, 3]} position="b">
                  <Heading tag="h5" size="xxs">
                    {label}
                  </Heading>
                </Spacing>
              )}
              {title && (
                <Spacing size={[1, 2]} position="b">
                  <Heading tag="h4" size={['m', 'l']}>
                    {title}
                  </Heading>
                </Spacing>
              )}

              {copy && <div className={generalStyles.description}>{copy}</div>}

              {buttonSlug && buttonLabel && (
                <Spacing size={[2, 4]} position="t">
                  <ButtonNew to={buttonSlug} kind="outline">
                    {buttonLabel}
                  </ButtonNew>
                </Spacing>
              )}
            </GridItem>
          </Grid>
        </MaxWidth>
      </Spacing>
    </PageWrap>
  );
};

export default Benefit;
