import React from 'react';

import Heading from '../../../atoms/Heading';
import MaxWidth from '../../../atoms/MaxWidth';
import PageWrap from '../../../atoms/PageWrap';
import Spacing from '../../../atoms/Spacing';
import LogoRow from '../../LogoRow';

import MissionAndSustainability from '../../../../pages/Home/MissionAndSustainability';

const UsedByMission = ({
  usedByTitle,
  usedByLogos,
  missionTitle,
  missionHugeImage,
  missionFeatureTitle,
  missionFeatureImage,
  missionCTA1,
  missionCTA2
}) => {
  return (
    <PageWrap>
      <Spacing size={[2, 3, 4, 5, 6]} type="padding">
        <MaxWidth value={760}>
          <Heading size={['l', 'xl', 'xxl']}>{usedByTitle}</Heading>
        </MaxWidth>

        <Spacing size={[2, 3, 4]} position="t">
          <LogoRow logos={usedByLogos} isPrismic />
        </Spacing>
      </Spacing>

      <Spacing size={[2, 3, 4, 5, 6]} position="b" type="padding">
        <MissionAndSustainability
          featureImage={missionFeatureImage.url}
          featureRatio={
            (1 / (missionFeatureImage.width / missionFeatureImage.height)) * 100
          }
          featureTitle={missionFeatureTitle}
          featureDirection="vertical"
          hugeTitle={missionTitle}
          missionImage={missionHugeImage.url}
          missionImageWidth={missionHugeImage.width}
          missionImageHeight={missionHugeImage.height}
          cta1={{
            title: missionCTA1.title,
            copy: missionCTA1.copy,
            to: missionCTA1.to
          }}
          cta2={{
            title: missionCTA2.title,
            copy: missionCTA2.copy,
            to: missionCTA2.to
          }}
        />
      </Spacing>
    </PageWrap>
  );
};

export default UsedByMission;
