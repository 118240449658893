import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import queryString from 'query-string';
import axios from 'axios';

import config from '../../../config';

import './style/paypal-overrides.css';

class PaypalButton extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      PaypalButton: null
    };
  }

  componentDidMount() {
    this.loadScript();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currency !== prevProps.currency) {
      this.setState({
        PaypalButton: null
      });
      // Reload the script using the updated currency
      this.loadScript();
    }
  }

  // Use this if the SelectCurrencies component ever makes it to the Checkout page.
  // componentDidUpdate(prevProps) {
  //   if (this.props.currency !== prevProps.currency) {
  //     this.loadScript();
  //   }
  // }

  loadScript() {
    if (this.isScriptInjected()) {
      return this.configureButton();
    }

    const source = this.getScriptSrc();
    const node = document.createElement('script');
    node.src = source;
    node.setAttribute('data-origin', 'paypal');
    node.onload = this.onScriptLoad.bind(this);
    node.onerror = this.onScriptLoadError.bind(this);

    document.body.appendChild(node);
    this.currentNode = node;
  }

  isScriptInjected() {
    const source = this.getScriptSrc();
    const node = document.querySelector(`script[src="${source}"]`);

    return node !== undefined && node !== null;
  }

  getScriptSrc() {
    const { currency } = this.props;

    const paypalSdkUrl = 'https://www.paypal.com/sdk/js';
    const clientID = config.appEnv === 'prod' ? config.paypalId : 'sb';

    const options = {
      'client-id': clientID,
      currency: currency,
      intent: 'capture',
      commit: false,
      'disable-funding': 'card,credit,paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo'
    };

    const optionsQuery = queryString.stringify(options);

    return `${paypalSdkUrl}?${optionsQuery}`;
  }

  configureButton() {
    if (window.paypal === undefined) {
      return;
    }

    this.setState({
      PaypalButton: window.paypal.Buttons.driver('react', { React, ReactDOM })
    });
  }

  onScriptLoad() {
    this.configureButton();
    this.props.onLoad?.();
    this.forceUpdate();
  }

  onScriptLoadError(evt) {
    this.props.onError(evt);
  }

  async onAuthorize(data) {
    this.props.log('[PayPal] - onAuthorize', data);

    try {
      await this.props.onAuthorize(data);
    } catch (error) {
      this.props.log('[PayPal] - Error /checkout/:token/paypal/refresh', error);

      return this.props.onError(error);
    }

    // Somehow unmounting the component directly outputs a "cleanup" error
    // so we delay the callback slightly...
    window && setTimeout(this.props.onAuthorized, 100);
  }

  onCreateOrder() {
    this.props.log('[PayPal] - Request', this.props.createOrderUrl);

    return axios
      .post(this.props.createOrderUrl)
      .then((res) => {
        return res.data.id;
      })
      .catch(this.props.onError);
  }

  render() {
    const { commit, onError, onClick, locale } = this.props;
    const { PaypalButton } = this.state;

    return (
      <div>
        {PaypalButton && (
          <PaypalButton
            style={{
              locale: locale,
              label: 'paypal',
              tagline: false,
              // Match medium size button dimension same as the GooglePay button
              size: 'large',
              height: 46,
              shape: 'rect',
              color: 'black',
              layout: 'horizontal'
            }}
            commit={commit}
            createOrder={this.onCreateOrder.bind(this)}
            onApprove={this.onAuthorize.bind(this)}
            onError={onError}
            onClick={onClick}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    currency: user.currency
  };
};

export default connect(mapStateToProps)(PaypalButton);
