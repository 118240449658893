import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import sizeStyles from './style/size.module.css';

const cx = classNames.bind({ ...sizeStyles });

const PageWrapOld = React.forwardRef(
  ({ children, size, className, ...props }, ref) => {
    return (
      <div className={cx(size, className)} ref={ref} {...props}>
        {children}
      </div>
    );
  }
);

export default PageWrapOld;

PageWrapOld.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.keys(sizeStyles))
};

PageWrapOld.defaultProps = {
  size: 'xl'
};
