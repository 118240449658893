import React from 'react';

import generalStyles from './style/general.module.css';

export default class ButtonRadioBtn extends React.PureComponent {
  render() {
    const { name, id, children, ...domProps } = this.props;

    return (
      <label htmlFor={id} className={generalStyles.holder}>
        <input
          type="radio"
          id={id || name}
          name={name}
          className={generalStyles.input}
          {...domProps}
        />
        {children}
        <div className={generalStyles.checkbox}>
          {/*<Icon glyph={checkIcon} width="19" height="19" />*/}
        </div>
      </label>
    );
  }
}
