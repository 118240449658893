import React from 'react';
import classNames from 'classnames';

import config from '../../config';

class Page extends React.Component {
  componentWillMount() {
    this.update(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.title !== nextProps.title) {
      this.update(nextProps);
    }
  }

  update(props) {
    document.title = props.title
      ? `${props.title} | ${config.appTitle}`
      : config.appTitle;
  }

  render() {
    const { children, className, style } = this.props;
    const pageProps = {
      className: classNames('min-h-screen', className),
      style
    };

    return <main {...pageProps}>{children}</main>;
  }
}

export default Page;
