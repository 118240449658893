import React from 'react';
import PropTypes from 'prop-types';

import Heading from '../../../../components/atoms/Heading';
import Link from '../../../../components/atoms/Link';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';

import { ModalNew as Modal } from '../../../../components/molecules/Modal';

const ErrorModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} handleClose={onClose}>
      <Heading tag="h4" size="xs">
        Unable to connect
      </Heading>
      <Spacing size={1} position="t">
        <Paragraph size="xxs">
          Please try again later or <Link to="/contact">contact us</Link> if the
          problem persists.
        </Paragraph>
      </Spacing>
    </Modal>
  );
};

ErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ErrorModal;
