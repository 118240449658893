import React from 'react';
import { motion } from 'framer-motion';

import { ButtonNew as Button } from '../../../../components/atoms/Button';
import Heading from '../../../../components/atoms/Heading';
import { InlineGrid } from '../../../../components/atoms/Grid';
import Icon from '../../../../components/atoms/Icon';
import Image from '../../../../components/atoms/Image';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';
import Link from '../../../../components/atoms/Link';

import generalStyles from './style/general.module.css';

import { containerVariants, overlayVariants } from '../variants';

import crossIcon from '../../../../img/sprites/cross.svg';

import dpiImg from '../../../../img/pages/create/01-300-dpi.jpg';
import transparentBgImg from '../../../../img/pages/create/02-transparent-bg.jpg';

const Guide = ({ onClose }) => {
  const handleClose = () => {
    onClose?.();
  };

  return (
    <>
      <motion.aside
        initial="closed"
        animate="open"
        exit="closed"
        variants={containerVariants}
        className={generalStyles.container}
      >
        <InlineGrid justify="end">
          <Button
            type="button"
            aria-label="close"
            onClick={handleClose}
            kind="ghost"
          >
            <Icon glyph={crossIcon} width="20" height="20" />
          </Button>
        </InlineGrid>
        <div style={{ marginTop: '-30px' }}>
          <Heading size="s">Artwork guidelines</Heading>
          <Spacing size={4}>
            <Spacing size={2}>
              <section className="flex flex-col gap-3">
                <Heading size="xs">Print areas & sizes</Heading>
                <Paragraph size="xxs">
                  The maximum print area is 396mm width x 490mm height.
                </Paragraph>
                <Paragraph size="xxs">
                  We don’t print over edges, seams, or pockets, so the available
                  print areas and sizes vary by product.
                </Paragraph>
                <Paragraph size="xxs">
                  If you add multiple products to a single campaign you’ll be
                  limited to the areas and sizes that work for all products.
                </Paragraph>
              </section>
            </Spacing>
            <hr className="border-grey-lighter" />
            <Spacing size={2}>
              <section className="flex flex-col gap-3">
                <Heading size="xs" className="leading-normal">
                  Artwork
                </Heading>
                <Paragraph size="xxs">
                  Your artwork should be in PNG format, 300 DPI resolution, and
                  max 10MB.
                </Paragraph>
                <Paragraph size="xxs">
                  This is important as anything lower than 300 DPI will not be
                  detailed enough. You can check the DPI on Photoshop by
                  selecting ‘image’, then ‘image size’. Bear in mind that when a
                  file is exported as a PNG (instead of being re-saved as a PNG)
                  it automatically saves at 72 DPI, so be sure to ‘save as’ (not
                  ‘export as’) when saving from Photoshop.
                </Paragraph>
                <Spacing size={1}>
                  <Image
                    src={dpiImg}
                    alt="Must be at least 300 DPI"
                    width={1000}
                    height={518}
                    withPlaceholder
                  />
                </Spacing>
                <Paragraph size="xxs">
                  Any colour included in your design (such as white backgrounds,
                  for example) will be printed onto your garments. If you need
                  help adding a transparent background, follow the{' '}
                  <Link href="/creator-toolkit/artwork-guidelines">
                    step-by-step
                  </Link>{' '}
                  guide on our Creator Toolkit.
                </Paragraph>
                <Spacing size={1}>
                  <Image
                    src={transparentBgImg}
                    alt="Transparent background"
                    width={1000}
                    height={520}
                    withPlaceholder
                  />
                </Spacing>
              </section>
            </Spacing>
            <hr className="border-grey-lighter" />
            <Spacing size={2}>
              <section className="flex flex-col gap-3">
                <Heading size="xs" className="leading-normal">
                  Print method
                </Heading>
                <Paragraph size="xxs">
                  Everpress offers several types of printing, including Screen,
                  Direct-to-Garment (DTG) and Giclee. The method used is
                  determined by the products you're offering, how complex your
                  design is, and how many items you sell.
                </Paragraph>
                <Paragraph size="xxs">
                  Some designs, such as photography or heavily detailed images,
                  will always be digitally printed to a high standard.{' '}
                  <Link href="/creator-toolkit/printing-techniques-faq">
                    Learn more
                  </Link>{' '}
                  about different print methods.
                </Paragraph>
              </section>
            </Spacing>
            <hr className="border-grey-lighter" />
            <Spacing size={2}>
              <section className="flex flex-col gap-3">
                <Heading size="xs" className="leading-normal">
                  Moderation
                </Heading>
                <Paragraph size="xxs">
                  We’ll give your design a once-over to check it meets our
                  community guidelines. These are available on our{' '}
                  <Link href="/creator-toolkit/our-content-review-policy">
                    Creator Toolkit
                  </Link>
                  .
                </Paragraph>
              </section>
            </Spacing>
            <hr className="border-grey-lighter" />
            <Spacing size={25}>
              <Button to="/creator-toolkit/artwork-guidelines" fullWidth isExternal>
                Read the full artwork guidelines
              </Button>
            </Spacing>
          </Spacing>
        </div>
      </motion.aside>
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={overlayVariants}
        className={generalStyles.overlay}
        onClick={handleClose}
      />
    </>
  );
};

export default Guide;
