import React from 'react';
import * as Sentry from "@sentry/react";
import omit from 'lodash/omit';
import PropTypes from 'prop-types';

import config from '../../../../config';

import './style/loqate-overrides.css';

const AddressLookUpHOC = (Component) => {
  const AddressLookUpWrapper = class extends React.Component {
    constructor(props) {
      super(props);
      this.onScriptLoaded = this.onScriptLoaded.bind(this);
      this.state = { control: null };
    }

    componentDidMount() {
      if (!window.pca) {
        const link = document.createElement('link');
        link.id = 'loqate-css';
        link.rel = 'stylesheet';
        link.href = config.loqate.css;
        document.body.appendChild(link);
        const script = document.createElement('script');
        script.async = true;
        script.id = 'loqate-js';
        script.src = config.loqate.script;
        script.addEventListener('load', this.onScriptLoaded);
        document.body.appendChild(script);
      } else {
        this.initAddressLookUp();
      }
    }
    componentDidUpdate(prevProps) {
      if (window.pca && prevProps.countryCode !== this.props.countryCode) {
        this.state.control.destroy();
        this.initAddressLookUp();
      }
    }

    onScriptLoaded() {
      this.initAddressLookUp();
    }

    initAddressLookUp() {
      const { countryCode, updateValues, name } = this.props;
      if (window.pca) {
        try {
          const fields = [
            {
              field: '',
              element: name,
              mode: window.pca.fieldMode.SEARCH
            }
          ];

          const options = {
            key: config.loqate.apiKey,
            suppressAutocomplete: false,
            countries: { codesList: countryCode }
          };

          this.setState(
            { control: new window.pca.Address(fields, options) },
            () => {
              this.state.control.listen('populate', (params) => {
                let newValues = {};
                newValues.line1 = params.Line1;
                newValues.line2 = params.Line2;
                newValues.city = params.City;
                newValues.county = params.Province;
                newValues.postcode = params.PostalCode;
                newValues.countryName = params.CountryName;
                newValues.country = params.CountryIso2;
                updateValues(newValues);
              });
            }
          );
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    }

    render() {
      return (
        <Component {...omit(this.props, ['countryCode', 'updateValues'])} />
      );
    }
  };
  AddressLookUpWrapper.propTypes = {
    countryCode: PropTypes.string.isRequired,
    updateValues: PropTypes.func.isRequired
  };
  return AddressLookUpWrapper;
};

export default AddressLookUpHOC;
