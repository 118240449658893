import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from '../../config';

import * as persistentState from '../../services/local-storage';

import { setImpersonatedUser, logout } from '../../actions/user';

import { ButtonNew as Button } from '../../components/atoms/Button';
import { InlineGrid } from '../../components/atoms/Grid';
import Heading from '../../components/atoms/Heading';
import Icon from '../../components/atoms/Icon';
import Spacing from '../../components/atoms/Spacing';

import spyIcon from '../../img/sprites/spy.svg';

const LOCAL_STORAGE_AUTH_KEY = config.localStorageAuthKey;
const ADMIN_ROLE = config.roles.ADMIN;

// Bypass router URLSearchParams parsing (`location.query`), as we want to preserve plus signs
function getQueryParam(search, name) {
  const q = search.match(new RegExp(name + '=([^&#]*)'));
  return q && q[1];
}

const UserImpersonator = () => {
  const [hidden, setHidden] = useState(true);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const location = useSelector((state) => state.location);

  const { extendedRoles = [], impersonatedUser } = user;

  useEffect(() => {
    if (extendedRoles.includes(ADMIN_ROLE)) {
      const targetUserFromQueryString = location.search
        ? getQueryParam(location.search, 'user')
        : null;
      const userFromLocalStorage = persistentState.get(LOCAL_STORAGE_AUTH_KEY);
      const targetUserFromLocalStorage = userFromLocalStorage?.impersonatedUser;

      if (
        targetUserFromQueryString &&
        targetUserFromQueryString !== targetUserFromLocalStorage
      ) {
        // Note that ls auth will get synced with user state at this point, see src/index.js
        dispatch(setImpersonatedUser(targetUserFromQueryString));
      }
    }
  }, [dispatch, extendedRoles, location]);

  useEffect(() => {
    setHidden(!impersonatedUser);
  }, [impersonatedUser]);

  const handleSignOut = () => {
    window.history.replaceState(null, null, window.location.pathname);

    dispatch(logout());
  };

  const handleCancel = () => {
    window.history.replaceState(null, null, window.location.pathname);

    dispatch(setImpersonatedUser(null));

    window.location.reload();
  };

  if (hidden) return null;

  return (
    <>
      <div className="fixed flex justify-center items-center w-full h-full top-0 opacity-30 text-center pointer-events-none">
        <Spacing size={2} position="all" type="padding">
          <Heading color="red" style={{ fontSize: '10rem' }}>
            Impersonating user
          </Heading>
        </Spacing>
      </div>
      <div className="px-2 py-1 w-full text-xs text-white bg-black">
        <InlineGrid justify={['space-between', 'space-between', 'center']}>
          <InlineGrid justify="start" align="center">
            <Icon
              className="mr-1 mt-px"
              glyph={spyIcon}
              width={16}
              height={16}
            />
            Impersonating user {impersonatedUser}
          </InlineGrid>
          <Spacing size={[0, 0, 3]} position="x">
            <InlineGrid justify="start" align={['end', 'end', 'start']}>
              <Button color="red" size="small" onClick={handleCancel}>
                Cancel
              </Button>
              <Spacing size={15} position="l">
                <Button
                  color="white"
                  size="small"
                  className="whitespace-no-wrap"
                  onClick={handleSignOut}
                >
                  Sign out
                </Button>
              </Spacing>
            </InlineGrid>
          </Spacing>
        </InlineGrid>
      </div>
    </>
  );
};

export default UserImpersonator;
