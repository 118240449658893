import React from 'react';

import { ButtonNew as Button } from '../../../../components/atoms/Button';
import Alert from '../../../../components/atoms/Alert';
import { Form } from '../../../../components/atoms/Form';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';

import { CtaBox } from '../../../../components/molecules/Box';
import TermsFormField from '../../../../components/molecules/TermsFormField';

const FreeForm = ({ initialValues, handleSubmit, isGuest }) => {
  return (
    <Form
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ isSubmitting, handleSubmit, status }) => {
        let statusMessage = null;
        const { error } = { ...status };
        if (error) {
          statusMessage = <Alert kind="error" message={status.error} />;
        }

        return (
          <form onSubmit={handleSubmit} data-test-id="checkout-form">
            <Spacing size={1} position="t">
              <CtaBox title="4. Payment Details">
                <Paragraph size="xxs">No payment required</Paragraph>
              </CtaBox>
            </Spacing>

            <div className="my-4 border-b-1 border-grey-lighter" />
            <Spacing size={2} position="y">
              {isGuest && (
                <Spacing size={1} position="t">
                  <TermsFormField name="acceptTerms" size="xxs" />
                </Spacing>
              )}
            </Spacing>
            <Spacing size={4} position="b">
              <Button
                type="submit"
                state={isSubmitting ? 'loading' : null}
                fullWidth
              >
                Place order
              </Button>
              <Spacing size={15} position="t">
                {statusMessage}
              </Spacing>
            </Spacing>
          </form>
        );
      }}
    />
  );
};

export default FreeForm;
