const termsBuyers = [
  {
    sections: [
      {
        type: 'paragraph',
        content:
          'Everpress is a marketplace where you can buy from us products bearing designs by independent creators.'
      },
      {
        type: 'paragraph',
        content:
          'These are the terms applicable to your use of Everpress to buy products from us. Please note that any acceptance by us of any order you place is conditional upon us receiving, by the end of the campaign period specified in the product listing, the required minimum number of orders specified in the product listing.'
      },
      {
        type: 'paragraph',
        content: 'These Terms sets out:'
      },
      {
        type: 'list',
        content: [
          'your legal rights and responsibilities;',
          'our legal rights and responsibilities; and',
          'certain key information required by law.'
        ]
      },
      {
        type: 'paragraph',
        content: 'In these Terms:'
      },
      {
        type: 'list',
        content: [
          '<b>‘We’, ‘us’ or ‘our’</b> means Everpress Limited;',
          '<b>‘You’ or ‘your’</b> means the person using our site to buy products;',
          '<b>‘Creator’</b> means the independent designer who has provided us with the design reproduced on the product;',
          '<b>‘product’</b> means an article of clothing or other product sold by us on the Everpress site;'
        ]
      },
      {
        type: 'paragraph',
        content:
          'If you don’t understand any of these Terms and want to talk to us about it, please contact us by email at <a href="mailto:hello@everpress.com">hello@everpress.com</a>.'
      },
      {
        type: 'paragraph',
        title: 'Who are we?',
        content:
          'We are Everpress Limited, a limited company registered in England and Wales. Our company registration number is 09419903 and our registered office and main trading address is at Unit 2, 55 Dalston Lane, London E8 2NG. Our registered UK VAT number is 2192548 04.'
      }
    ]
  },
  {
    title: '1. Introduction',
    sections: [
      {
        type: 'paragraph',
        content:
          '1.1 If you buy products on our site you agree to be legally bound by these Terms.'
      },
      {
        type: 'paragraph',
        content:
          '1.2 These Terms are only available in English. No other languages will apply to these Terms.'
      }
    ]
  },
  {
    title: '2. Your privacy and personal information',
    sections: [
      {
        type: 'paragraph',
        content:
          '2.1 Our Privacy Policy is available at <a href="https://everpress.com/privacy-policy">https://everpress.com/privacy-policy.</a>'
      },
      {
        type: 'paragraph',
        content:
          '2.2 Your privacy and personal information are important to us. Any personal information that you provide to us will be dealt with in line with our Privacy Policy, which explains what personal information we collect from you, how and why we collect, store, use and share such information, your rights in relation to your personal information and how to contact us and supervisory authorities if you have a query or complaint about the use of your personal information.'
      }
    ]
  },
  {
    title: '3. Ordering products from us',
    sections: [
      {
        type: 'paragraph',
        content:
          '3.1 Below, we set out how a legally binding contract between you and us is made.'
      },
      {
        type: 'paragraph',
        content:
          '3.2 Please read and check your order carefully before submitting it. However, if you need to correct any errors you can do so before submitting it to us.'
      },
      {
        type: 'paragraph',
        content:
          '3.3 When you place your order at the end of the checkout process, we will acknowledge it by email. This acknowledgement does not, however, mean that your order has been accepted by us.'
      },
      {
        type: 'paragraph',
        content:
          '3.4 We may contact you to say that we do not accept your order. This is typically for the following reasons:'
      },
      {
        type: 'list',
        content: [
          '3.4.1 we cannot authorise your payment;',
          '3.4.2 you have ordered too many products; or',
          '3.4.3 there has been a mistake on the pricing or description of the products.'
        ]
      },
      {
        type: 'paragraph',
        content:
          '3.5 We will only accept your order when we email you to confirm this (Confirmation Email). At this point a legally binding contract will be in place between you and us. The contract is conditional upon us receiving, by the end of the campaign period specified in the product listing, the required minimum number of orders specified in the product listing.'
      },
      {
        type: 'paragraph',
        content:
          '3.6 If we do not receive the required minimum number of orders for the product by the end of the specified campaign period, we will email you to let you know, and we will provide you with a full refund of the price you paid for the product within three days of the end of the campaign period in question.'
      },
      {
        type: 'paragraph',
        content:
          '3.7 If we have accepted your order, and we do receive the required minimum number of orders for the product by the end of the specified campaign period, we will dispatch the products to you.'
      },
      {
        type: 'paragraph',
        content:
          '3.8 If you are under the age of 18 you may not use our site to buy any products.'
      }
    ]
  },
  {
    title: '4. Right to cancel',
    sections: [
      {
        type: 'paragraph',
        content:
          '4.1 If you are in the UK or European Union, you have the right to cancel your order for products within 14 days without giving any reason.'
      },
      {
        type: 'paragraph',
        content:
          '4.2 The cancellation period will expire after 14 days from the day on which you (or someone indicated by you) acquire physical possession of the product.'
      },
      {
        type: 'paragraph',
        content: 'To exercise the right to cancel, you must inform us by:'
      },
      {
        type: 'list',
        content: [
          '4.3.1 Phone or email. Call customer services on +44 (0) 1223 320 578 or email us at hello@everpress.com. Please provide your name, home address, details of the order and, where available, your phone number and email address.',
          '4.3.2 Online. Complete the contact form on our website.',
          '4.3.3 By post. Simply write to us at Unit 2, 55 Dalston Lane, London E8 2NG.'
        ]
      },
      {
        type: 'paragraph',
        content:
          '4.4 To meet the cancellation deadline, it is sufficient for you to send your communication concerning your exercise of the right to cancel before the cancellation period has expired.'
      }
    ]
  },
  {
    title: '5. Effects of cancellation',
    sections: [
      {
        type: 'paragraph',
        content:
          '5.1 If you cancel your order, we will reimburse to you all payments received from you, including the costs of delivery (except for the supplementary costs arising if you chose a type of delivery other than the least expensive type of standard delivery offered by us).'
      },
      {
        type: 'paragraph',
        content:
          '5.2 We may make a deduction from the reimbursement for loss in value of any products supplied, if the loss is the result of unnecessary handling by you.'
      },
      {
        type: 'paragraph',
        content:
          '5.3 We will make the reimbursement without undue delay, and not later than:'
      },
      {
        type: 'list',
        content: [
          '5.3.1 14 days after the day we received from you any products supplied; or',
          '5.3.2 (if earlier) 14 days after the day you provide evidence that you have returned the products; or',
          '5.3.3 if there were no products supplied, 14 days after the day on which we are informed about your decision to cancel these Terms.'
        ]
      },
      {
        type: 'paragraph',
        content:
          '5.4 We will make the reimbursement using the same means of payment as you used for the initial transaction, unless you have expressly agreed otherwise; in any event, you will not incur any fees as a result of the reimbursement.'
      },
      {
        type: 'paragraph',
        content:
          '5.5 We may withhold reimbursement until we have received the products back or you have supplied evidence of having sent back the products, whichever is the earliest.'
      },
      {
        type: 'paragraph',
        content: 'If you cancel your order:'
      },
      {
        type: 'list',
        content: [
          '5.6.1 you must send the products, without undue delay and in any event not later than 14 days from the day on which you communicate your cancellation to us. The deadline is met if you send back the products before the period of 14 days has expired. Please call customer services on +44 (0) 1223 320 578 or email us at hello@everpress.com for a return label or to arrange collection;',
          '5.6.2 you will have to bear the direct cost of returning the products; and',
          '5.6.3 you are only liable for any diminished value of the products resulting from the handling other than what is necessary to establish the nature, characteristics and fit of the products.'
        ]
      }
    ]
  },
  {
    title: '6. Delivery',
    sections: [
      {
        type: 'paragraph',
        content:
          '6.1 We use third party delivery agents to deliver our products. You can check and select your delivery options before you place your order.'
      },
      {
        type: 'paragraph',
        content:
          '6.2 Whilst we may facilitate any import formalities on your behalf, you will remain legally responsible for any import VAT or customs duties payable in your country.'
      },
      {
        type: 'paragraph',
        content:
          '6.3 The estimated date for delivery of the products is set out in the Confirmation Email (see clause 4.5).'
      },
      {
        type: 'paragraph',
        content: '6.4 If something happens which:'
      },
      {
        type: 'list',
        content: [
          '6.4.1 is outside of our control in fulfilling the order; and',
          '6.4.2 affects the estimated date of delivery;'
        ]
      },
      {
        type: 'paragraph',
        content:
          'we will let you have a revised estimated date for delivery of the products.'
      },
      {
        type: 'paragraph',
        content:
          '6.5 We will arrange for the delivery of your products to the delivery address that you provided when placing your order.'
      },
      {
        type: 'paragraph',
        content:
          '6.6 We deliver to the countries listed on our FAQs page (International Delivery Destinations). However there are restrictions on some products for certain International Delivery Destinations, so please review the information on that page carefully before ordering products from us. Please also note the following:'
      },
      {
        type: 'list',
        content: [
          '6.6.1 If you order products from our site for delivery to one of the International Delivery Destinations, your order may be subject to import duties and taxes which are applied when the delivery reaches that destination. Please note that neither we nor the Creator has any control over these charges, and we cannot predict their amount.',
          '6.6.2 You, not us or the Creator, will be responsible for payment of any such import duties and taxes. Please contact your local customs office for further information before placing your order.',
          '6.6.3 You must comply with all applicable laws and regulations of the country for which the products are destined. We will not be liable or responsible if you break any such law.'
        ]
      },
      {
        type: 'paragraph',
        content:
          '6.7 You are responsible for the products when delivery has taken place. In other words, the risk in the products passes to you when you take possession of the products.'
      }
    ]
  },
  {
    title: '7. Payment',
    sections: [
      {
        type: 'paragraph',
        content:
          '7.1 We accept the following credit cards and debit cards: Paypal, Visa, Mastercard, Maestro, American Express, Apple Pay and Google Pay. We do not accept cash.'
      },
      {
        type: 'paragraph',
        content:
          '7.2 Your credit card or debit card will be charged at the time the order is placed.'
      },
      {
        type: 'paragraph',
        content:
          '7.3 All payments by credit card or debit card need to be authorised by the relevant card issuer. '
      },
      {
        type: 'paragraph',
        content:
          '7.4 The price of the products displayed to you during checkout includes any VAT at the applicable rate.'
      },
      {
        type: 'paragraph',
        content:
          '7.5 Depending on your location and the value of the order, your order may be inspected by customs authorities and may be subject to import duties and taxes. You will be responsible for the payment of any such import duties and taxes. Whilst we will do our best to facilitate the process for you, we do not have control over these charges and cannot predict their amount. We recommend contacting your local customs office for further information before placing your order.'
      }
    ]
  },
  {
    title: '8. Nature of the products',
    sections: [
      {
        type: 'paragraph',
        content:
          '8.1 Depending on where you live, mandatory local consumer laws will provide you with certain rights under your contract with us for supply of the products. The Consumer Rights Act 2015 in the UK gives you certain legal rights (also known as ‘statutory rights’) under your contract with us, for example, the products:'
      },
      {
        type: 'list',
        content: [
          '8.1.1 are of satisfactory quality;',
          '8.1.2 are fit for purpose;',
          '8.1.3 match the description on the Everpress site.'
        ]
      },
      {
        type: 'paragraph',
        content:
          '8.2 We must provide you with products that comply with your legal rights.'
      },
      {
        type: 'paragraph',
        content: '8.3 While we try to make sure that:'
      },
      {
        type: 'list',
        content: [
          '8.3.1 all weights, sizes and measurements set out on the Everpress site are as accurate as possible, there may be a small tolerance in such weights, sizes and measurements in clothing products; and',
          '8.3.2 the colours of the products are displayed accurately on the site, the actual colours that you see on your computer may vary depending on the monitor that you use.'
        ]
      },
      {
        type: 'paragraph',
        content:
          '8.4 For all Everpress campaigns, the creator behind the campaign will select a specific garment, or selection of garments, onto which to print their design. In the rare event that the chosen garment is out of stock when you place your preorder, we reserve the right to swap it for an equivalent alternative garment. This will always be like for like in terms of both colour and garment type; a white T-shirt for a white T-shirt, a red long sleeve for a red long sleeve etc. This will never affect the design you have preordered.'
      }
    ]
  },
  {
    title: '9. Faulty products',
    sections: [
      {
        type: 'paragraph',
        content:
          '9.1 Nothing in these Terms affects your legal rights under the Consumer Rights Act 2015 (also known as ‘statutory rights’). You may also have other rights in law.'
      },
      {
        type: 'paragraph',
        content:
          '9.2 Please call customer services on +44 (0) 1223 320 578 or email us at hello@everpress.com, if the product you ordered from us is faulty and you want us to replace the products, or to reject the products and get a refund from us.'
      }
    ]
  },
  {
    title: '10. Disputes',
    sections: [
      {
        type: 'paragraph',
        content:
          '10.1 We will try to resolve any disputes between you and us quickly and efficiently. If you are unhappy with the products please contact us as soon as possible and we will try and resolve the issue.'
      },
      {
        type: 'paragraph',
        content:
          '10.2 The laws of England and Wales will apply to these Terms. These laws will apply no matter where in the world you live, but you will be entitled to the protection of the mandatory consumer protection provisions of your local consumer protection laws if you live outside England and Wales. Any disputes are subject to the exclusive jurisdiction of the English courts.'
      }
    ]
  },
  {
    title: '11. Gift cards',
    sections: [
      {
        type: 'paragraph',
        content: '11.1  Everpress Gift Cards can be used in full or part payment for goods on www.everpress.com, including current promotional offers, at the prevailing prices listed.'
      },
      {
        type: 'paragraph',
        content: '11.2  If the entire balance of a Gift Card is not spent in one transaction, the remaining balance will remain available on the Gift Card for future use.'
      },
      {
        type: 'paragraph',
        content: '11.3  Gift Cards cannot be used for the purchase of other gift cards or vouchers.'
      },
      {
        type: 'paragraph',
        content: '11.4  All Gift Cards hold an expiry date of 18 months from the date of purchase. Any balance remaining on the Gift Card after 18 months will expire and be lost.'
      },
      {
        type: 'paragraph',
        content: '11.5 Everpress reserves the right to decline or withdraw Gift Cards, and the right to amend these terms and conditions of Gift Card at any time and to take appropriate action, including the cancellation of the Gift Card, if, in its discretion, it deems such action necessary.'
      },
      {
        type: 'paragraph',
        content: '11.6  Everpress cannot be held liable for lost or stolen Gift Cards and they will not be replaced.'
      },
      {
        type: 'paragraph',
        content: '11.7. The re-sale of Gift Cards sold via this website is strictly prohibited.'
      }
    ]
  }
];

export default termsBuyers;
