import React, { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames/bind';
import groupBy from 'lodash/groupBy';

import config from '../../../../../config';

import { logout } from '../../../../../actions/user';

import { ButtonNew as Button } from '../../../../atoms/Button';
import Icon from '../../../../atoms/Icon';
import Link from '../../../../atoms/Link';
import PageWrap from '../../../../atoms/PageWrap';
import Paragraph from '../../../../atoms/Paragraph';
import { TooltipNew } from '../../../../atoms/Tooltip';

import { MegaMenuDesktop, MegaMenuMobile } from '../../../MegaMenu';
import Search from '../../../Search';

import cartIcon from '../../../../../img/sprites/cart.svg';
import cartFilledIcon from '../../../../../img/sprites/cart-filled.svg';
import crossIcon from '../../../../../img/sprites/round-cross.svg';
import everpressLogo from '../../../../../img/everpress-logo.svg';
import favouriteIcon from '../../../../../img/sprites/favourite.svg';
import menuIcon from '../../../../../img/sprites/round-menu.svg';
import arrowDownFull from '../../../../../img/sprites/arrow-down-full.svg';

import { buildTags } from '../../../../../pages/Shop/tagsData';
import {
  headerItems,
  servicesItems,
  authenticatedMenuItems
} from '../menuItemsConfig';

import generalStyles from './style/general.module.css';
import linkStyles from './style/link.module.css';

const cx = classNames.bind({ ...generalStyles });

const groupedAuthenticatedMenuItems = groupBy(authenticatedMenuItems, 'group');

const Navigation = ({ isMenuOpen, setIsMenuOpen }) => {
  const dispatch = useDispatch();
  const navRef = useRef();
  const token = useSelector((state) => state.user.token);
  const extendedRoles = useSelector((state) => state.user.extendedRoles);
  const cartQuantity = useSelector((state) => state.cart.quantity);
  const { isMinimalNavigation } = useSelector(
    (state) => state.headerNew.navigation,
    shallowEqual
  );
  const theme = useSelector((state) => state.headerNew.theme);
  const shopTags = useSelector((state) => state.shop.tags);
  const isShopSearchOpen = useSelector((state) => state.shop.search.isOpen);

  const isUserCreator = extendedRoles.includes('creator');
  const formatedQuantity = cartQuantity > 9 ? '9+' : cartQuantity;
  const navigationItems = headerItems;
  const collections = buildTags(shopTags);

  const navClassName = cx('nav', {
    'bg-white': theme.color === 'white',
    'bg-pastel-grey': theme.color === 'pastel-grey'
  });

  const navWrapperClassName = cx('navWrapper', {
    'border-grey-lighter': theme.color === 'white',
    'border-grey-light': theme.color === 'pastel-grey'
  });

  useEffect(() => {
    if (isShopSearchOpen && isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [isMenuOpen, isShopSearchOpen, setIsMenuOpen]);

  const handleLogoutUser = () => {
    dispatch(logout());
    setIsMenuOpen(false);
  };

  const handleAccountItemClick = (itemClicked, slug) => {
    dispatch({
      type: 'ACCOUNT_ITEM_CLICK',
      payload: {
        itemClicked,
        slug
      }
    });
  };

  const accountDropdownContent = (
    <ul className="my-05">
      {Object.keys(groupedAuthenticatedMenuItems).map((key) => {
        const items = groupedAuthenticatedMenuItems[key];
        return (
          <Fragment key={key}>
            {items.map(
              (item, childIndex) =>
                (isUserCreator || (!isUserCreator && !item.isCreator)) && (
                  <Fragment key={item.title}>
                    <li
                      className="flex flex-1"
                      key={item.to}
                      onClick={() =>
                        handleAccountItemClick(item.title, item.to)
                      }
                    >
                      <Link
                        className={linkStyles.link}
                        isUnderlined={false}
                        noHover
                        to={item.to}
                      >
                        {item.title}
                      </Link>
                    </li>
                    {childIndex === items.length - 1 && (
                      <li className="border-b-1 border-grey-lighter my-15 w-85 mx-auto" />
                    )}
                  </Fragment>
                )
            )}
          </Fragment>
        );
      })}
      <li className="block w-full">
        <button className={linkStyles.link} onClick={handleLogoutUser}>
          Sign out
        </button>
      </li>
    </ul>
  );

  const servicesDropdownContent = (
    <ul className="my-05">
      {servicesItems.map((item) => {
        return (
          <li className="flex flex-1" key={item.title}>
            <Link
              className={linkStyles.link}
              isUnderlined={false}
              noHover
              to={item.to}
            >
              {item.title}
            </Link>
          </li>
        );
      })}
    </ul>
  );

  return (
    <>
      <nav ref={navRef} className={navClassName}>
        <div className={navWrapperClassName}>
          <PageWrap>
            <div className="flex h-6 mdlg:items-center">
              <Link to="/" className="mdlg:mr-15 my-auto">
                <img
                  src={everpressLogo}
                  alt="Everpress logo"
                  width="30"
                  className={generalStyles.navLogo}
                />
              </Link>
              {/*desktop menu*/}
              {!isMinimalNavigation && (
                <div className={generalStyles.menuContainer}>
                  <ul className="flex flex-row">
                    {navigationItems.map((item) => (
                      <li className="mx-05" key={item.to}>
                        {item.title === 'Shop' ? (
                          <MegaMenuDesktop collections={collections}>
                            <Button
                              className="rounded-md"
                              size="small"
                              kind="ghost"
                              to={item.to}
                              isExternal={item.isExternal}
                              icon={item.to === '/shop' ? arrowDownFull : null}
                            >
                              {item.title}
                            </Button>
                          </MegaMenuDesktop>
                        ) : (
                          <Button
                            className="rounded-md"
                            size="small"
                            kind="ghost"
                            to={item.to}
                            isExternal={item.isExternal}
                          >
                            {item.title}
                          </Button>
                        )}
                      </li>
                    ))}
                    <li className="mx-05">
                      <div className="inline-block">
                        <TooltipNew
                          hasListContent
                          content={servicesDropdownContent}
                          placement="bottom"
                          offset={7}
                          align="start"
                          alignOffset={-10}
                          width={180}
                          id="services-dropdown"
                        >
                          <Button
                            size="small"
                            kind="ghost"
                            className="rounded-md"
                            onClick={() => null}
                          >
                            Services
                          </Button>
                        </TooltipNew>
                      </div>
                    </li>
                  </ul>
                  {isShopSearchOpen ? null : token ? (
                    <div className="inline-block">
                      <TooltipNew
                        hasListContent
                        content={accountDropdownContent}
                        placement="bottom"
                        offset={7}
                        align="start"
                        alignOffset={-10}
                        width={180}
                        id="account-dropdown"
                      >
                        <Button
                          size="small"
                          kind="ghost"
                          className="rounded-md"
                          to="/dashboard"
                        >
                          Account
                        </Button>
                      </TooltipNew>
                    </div>
                  ) : (
                    <div className="inline-block">
                      <Button size="small" kind="ghost" to="/login">
                        Sign in
                      </Button>
                    </div>
                  )}
                </div>
              )}
              {/*end of desktop menu*/}
              {/*icon menu*/}
              <ul className="ml-auto flex items-center">
                {!isMinimalNavigation && (
                  <li className={generalStyles.navIconListItem}>
                    <Search navRef={navRef} />
                  </li>
                )}
                {!isMinimalNavigation && !isShopSearchOpen && (
                  <li className={generalStyles.navIconListItem}>
                    <Link
                      className={generalStyles.navIconLink}
                      to="/favourites"
                      title="Favourites"
                    >
                      <Icon glyph={favouriteIcon} width="24" height="24" />
                    </Link>
                  </li>
                )}
                {!isShopSearchOpen ? (
                  <li className={generalStyles.navIconListItem}>
                    <Link
                      className={generalStyles.navIconLink}
                      to="/cart"
                      aria-label={`${cartQuantity} cart items`}
                    >
                      {cartQuantity > 0 && (
                        <div className={generalStyles.cartTotal}>
                          {formatedQuantity}
                        </div>
                      )}
                      <Icon
                        glyph={cartQuantity > 0 ? cartFilledIcon : cartIcon}
                        width="24"
                        height="24"
                      />
                    </Link>
                  </li>
                ) : null}
                {!isMinimalNavigation && !isShopSearchOpen && (
                  <li
                    className={`${generalStyles.navIconListItem} mdlg:hidden`}
                  >
                    <button
                      onClick={() => setIsMenuOpen(!isMenuOpen)}
                      className="p-1 pr-0"
                    >
                      {isMenuOpen ? (
                        <Icon glyph={crossIcon} width="24" height="24" />
                      ) : (
                        <Icon glyph={menuIcon} width="24" height="24" />
                      )}
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </PageWrap>
        </div>
      </nav>
      {/*mobile nav*/}
      {!isMinimalNavigation && (
        <div
          className={classNames(generalStyles.menuContainerMobile, {
            hidden: !isMenuOpen
          })}
        >
          <ul className="flex flex-col mt-2">
            <li className={generalStyles.navListItemMobile}>
              <Link
                to={`/shop/${config.shop.defaultTag.value}`}
                className={generalStyles.navListItemMobileLink}
              >
                {config.shop.defaultTag.label}
              </Link>
            </li>
            {navigationItems.map((item) =>
              item.title === 'Shop' ? (
                <li key={item.to} className={generalStyles.megaMenuMobile}>
                  {isMenuOpen ? (
                    <MegaMenuMobile collections={collections} />
                  ) : null}
                </li>
              ) : (
                <li className={generalStyles.navListItemMobile} key={item.to}>
                  <Link
                    to={item.to}
                    className={generalStyles.navListItemMobileLink}
                  >
                    {item.title}
                  </Link>
                </li>
              )
            )}
          </ul>
          <hr className="border-grey-lighter mt-2 mb-3" />
          {token ? (
            <>
              <Paragraph
                size="xxs"
                color="grey-primary"
                className="mb-05 px-15"
              >
                Account
              </Paragraph>
              <ul className="flex flex-col mb-25">
                {authenticatedMenuItems.map(
                  (item) =>
                    (isUserCreator || !item.isCreator) && (
                      <li
                        className={generalStyles.navListItemMobile}
                        key={item.to}
                        onClick={() =>
                          handleAccountItemClick(item.title, item.to)
                        }
                      >
                        <Link
                          to={item.to}
                          className={generalStyles.navListItemMobileLink}
                        >
                          {item.title}
                        </Link>
                      </li>
                    )
                )}
              </ul>
              <div className="m-15 mt-auto">
                <Button kind="outline" onClick={handleLogoutUser} fullWidth>
                  Sign out
                </Button>
              </div>
            </>
          ) : (
            <div className="flex">
              <Button to="/login" fullWidth className="mx-15 my-1">
                Sign in
              </Button>
            </div>
          )}
        </div>
      )}
      {/*  mobile nav end*/}
    </>
  );
};

export default Navigation;
