import isEmpty from 'lodash/isEmpty';
import differenceInHours from 'date-fns/difference_in_hours';

export const timestampsExpired = (timestamps = [], expiryHours = 0) => {
  if (isEmpty(timestamps)) return true;

  return timestamps.some(
    (ts) => Math.abs(differenceInHours(ts, Date.now())) > expiryHours
  );
};
