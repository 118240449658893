import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../../../../atoms/IconButton';
import Paragraph from '../../../../atoms/Paragraph';

import crossIcon from '../../../../../img/sprites/white-cross.svg';

import generalStyles from './style/general.module.css';

const Banner = ({ announcementCopy, handleClose }) => {
  return (
    <div className="relative w-100 bg-black text-center py-1">
      <div className="w-100 mx-auto px-2 md:px-5 max-w-xl">
        <Paragraph size="xxs" color="white">
          {announcementCopy}
        </Paragraph>
        {!!handleClose ? (
          <div className={generalStyles.hideButton}>
            <IconButton
              title="Close"
              onClick={handleClose}
              kind="ghost"
              size="tiny"
              glyph={crossIcon}
              className="p-0"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

Banner.propTypes = {
  announcementCopy: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  handleClose: PropTypes.func
};

export default Banner;
