import React from 'react';
import PropTypes from 'prop-types';

import generalStyles from './style/general.module.css';

const ClearButton = ({ onClick, children }) => (
  <button type="button" className={generalStyles.clearButton} onClick={onClick}>
    {children}
  </button>
);

export default ClearButton;

ClearButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node
};
