import produce from 'immer';
import merge from "lodash/merge";

const initialState = {
  bySlug: {},
  campaignsBySlug: { isLoading: true },
  isLoading: false
};

export default (state = initialState, action) =>
  produce(state, (newState) => {
    const { type, payload, meta } = action;
    const { name: profileName, ...morePayload } = payload ? payload : {};
    const profileData = { profileName, ...morePayload };

    switch (type) {
      case 'SET_USER':
        // Clear the profile data on logout
        if (action.payload === null) {
          newState = {
            ...initialState,
            bySlug: state.bySlug,
            campaignsBySlug: state.campaignsBySlug
          };
        }
        return newState;
      case 'SAVE_USER_PROFILE_FULFILLED':
        const { name: newName, ...newProfile } = meta;
        newState = { ...state, ...newProfile, profileName: newName };
        return newState;
      case 'SAVE_USER_PROFILE_PATCH_PENDING':
        const { optimist, ...profileFields } = meta;
        newState = merge({}, state, profileFields);
        return newState;
      case 'SAVE_USER_PROFILE_PICTURE_FULFILLED':
        newState = { ...state, profilePicture: payload.url || meta };
        return newState;
      case 'SAVE_USER_PROFILE_BANNER_FULFILLED':
        newState = { ...state, profileBanner: payload.url || meta };
        return newState;
      case 'GET_USER_PROFILE_FULFILLED':
        newState = {
          ...state,
          ...profileData,
          bySlug: { ...state.bySlug, [payload.slug]: profileData }
        };
        return newState;
      case 'GET_PROFILE_FULFILLED':
        newState.bySlug[payload.slug] = profileData;
        return newState;
      case 'GET_PROFILE_CAMPAIGNS_FULFILLED':
        newState.campaignsBySlug = { [meta]: payload };
        newState.campaignsBySlug.isLoading = false;
        return newState;
      case 'GET_USER_PROFILE_CAMPAIGNS_FULFILLED':
        newState.campaignsBySlug = { [state.slug]: payload };
        newState.campaignsBySlug.isLoading = false;
        return newState;
      case 'HIDE_PROFILE_CAMPAIGN_FULFILLED':
        const hiddenCampaign = newState.campaignsBySlug[state.slug].find(
          (item) => item.campaignId === meta.campaignId
        );
        hiddenCampaign.hiddenOnProfile = meta.hiddenOnProfile;
        return newState;
      case 'PROFILE_PENDING':
        newState.isLoading = true;
        return newState;
      case 'PROFILE_READY':
        newState.isLoading = false;
        return newState;
      default:
        return state;
    }
  });
