import { BEGIN, COMMIT, REVERT } from 'redux-optimist';
import cond from 'lodash/cond';
import curryRight from 'lodash/curryRight';
import stubTrue from 'lodash/stubTrue';

import { setCartCurrency } from '../actions/cart';
import { setUserCurrency } from '../actions/user';

const makeOptimisticAction = curryRight((action, transactionType) => {
  const optimist = { ...action.meta.optimist, type: transactionType };

  if (!optimist.id) {
    console.warn(
      `Warning: Invalid ${action.type} transaction id, the optimistic update will be skipped.`,
      `Failure to commit or revert previously applied actions will result in a memory leak.`
    );

    return action;
  }

  return {
    ...action,
    optimist
  };
});

const matchActionState = curryRight((action, actionStates) => {
  return actionStates.some((state) => action.type.endsWith(state));
});

export default function optimistMiddleware() {
  return ({ dispatch, getState }) => (next) => (action) => {
    const isOptimistic = action.meta?.optimist;

    // Update cart currency on user currency change
    if (action.type === 'SET_USER_CURRENCY') {
      const { currency: cartCurrency, token: cartToken } = getState().cart;

      if (cartToken) {
        const targetCurrency = action.payload || cartCurrency;

        if (targetCurrency !== cartCurrency) {
          dispatch(setCartCurrency(targetCurrency));
        }
      }
    }

    // Update user currency on cart load
    if (action.type === 'GET_CART_FULFILLED') {
      const { currency: userCurrency } = getState().user;
      const cartCurrency = action.payload.currency;

      if (userCurrency !== cartCurrency) {
        dispatch(setUserCurrency(cartCurrency));
      }
    }

    if (isOptimistic) {
      const getOptimisticAction = cond([
        [matchActionState(['PENDING']), makeOptimisticAction(BEGIN)],
        [matchActionState(['FULFILLED']), makeOptimisticAction(COMMIT)],
        [matchActionState(['REJECTED']), makeOptimisticAction(REVERT)],
        [stubTrue, (action) => action]
      ]);

      return next(getOptimisticAction(action));
    }

    return next(action);
  };
}
