import pick from 'lodash/pick';

import { log } from './log';

// Helper to filter out UI-only values
function filterFormData(formData) {
  const {
    isBillingAddressDifferent,
    billingAddress,
    vatRegistered,
    ...otherValues
  } = formData;
  return isBillingAddressDifferent
    ? { ...otherValues, billingAddress }
    : { ...otherValues, billingAddress: null };
}

export const saveCheckoutStep = (step) => (dispatch, getState) => {
  const { checkout, cart } = getState();
  const { details } = checkout;

  const meta = pick(details, [
    'email',
    'deliveryAddress.country',
    'tracked',
    'discount'
  ]);

  dispatch({
    type: 'SAVE_CHECKOUT_STEP',
    payload: step,
    meta: { ...meta, token: cart.token }
  });
};

export const applyDiscountCode = (discountCode) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { token: cartToken } = getState().cart;

  return dispatch({
    type: 'APPLY_DISCOUNT_CODE',
    payload: baseApi.post(`/checkout/${cartToken}/discount`, {
      discountCode
    })
  });
};

export const applyVoucherCode = (voucherCode) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { token: cartToken } = getState().cart;

  return dispatch({
    type: 'APPLY_VOUCHER_CODE',
    payload: baseApi.post(`/checkout/${cartToken}/voucher`, {
      code: voucherCode
    })
  });
};

export const deleteVoucherCode = (voucherRedemptionId) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { token: cartToken } = getState().cart;

  return dispatch({
    type: 'DELETE_VOUCHER_CODE',
    payload: baseApi.delete(
      `/checkout/${cartToken}/voucher/${voucherRedemptionId}`
    ),
    meta: { voucherRedemptionId }
  });
};

export const editCheckoutDeliveryCountry = (country) => (dispatch) => {
  dispatch({
    type: 'EDIT_CHECKOUT_DELIVERY_COUNTRY',
    payload: country
  });
};

export const editCheckoutDetails = (formData) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { token: userToken } = getState().user;
  const { token: cartToken } = getState().cart;

  return dispatch({
    type: 'EDIT_CHECKOUT_DETAILS',
    payload: baseApi.put(`/checkout/${cartToken}`, filterFormData(formData)),
    meta: { token: userToken, formData }
  });
};

export const getCheckoutDetails = (token) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { user } = getState();

  return dispatch({
    type: 'GET_CHECKOUT_DETAILS',
    payload: baseApi.get(`/checkout/${token}`),
    meta: user
  });
};

export const clearCheckoutDetails = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_CHECKOUT_DETAILS'
  });
};

export const checkoutPaypal = ({ isGift = false }) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { cart, checkout, campaigns } = getState();
  const { paypal } = checkout;
  const { payerId, paymentId } = paypal;

  dispatch(
    log(`[PayPal] - /checkout/${cart.token}/paypal/execute`, {
      payerId,
      paymentId,
      total: cart.total,
      currency: cart.currency,
      isGift
    })
  );

  return dispatch({
    type: 'CHECKOUT_PAYPAL',
    payload: baseApi
      .post(`/checkout/${cart.token}/paypal/execute`, {
        payerId,
        paymentId,
        total: cart.total,
        currency: cart.currency,
        isGift
      })
      .then((res) => ({
        token: cart.token,
        items: cart.items,
        total: cart.total,
        totalsGBP: cart.totalsGBP,
        campaigns
      }))
  });
};

export const checkoutApp = (formData) => (dispatch, getState, { baseApi }) => {
  const { cart } = getState();

  return dispatch({
    type: 'CHECKOUT',
    payload: baseApi.post(`/checkout/${cart.token}`, {
      ...filterFormData(formData),
      total: cart.total,
      totalsGBP: cart.totalsGBP,
      currency: cart.currency
    }),
    meta: formData
  });
};

export const checkoutStripe = (stripeToken, formData) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { cart, campaigns } = getState();

  return dispatch({
    type: 'CHECKOUT_STRIPE',
    payload: baseApi
      .post(`/checkout/${cart.token}/stripe`, {
        ...filterFormData(formData),
        stripeToken,
        total: cart.total,
        currency: cart.currency
      })
      .then((res) => ({
        token: cart.token,
        items: cart.items,
        total: cart.total,
        totalsGBP: cart.totalsGBP,
        campaigns
      }))
  });
};

export const createStripePaymentIntent = (paymentData) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { cart } = getState();

  const payload = {
    ...paymentData,
    currency: cart.currency,
    total: cart.total
  };

  return dispatch({
    type: 'CREATE_STRIPE_PAYMENT_INTENT',
    payload: baseApi
      .post(`/checkout/${cart.token}/stripe/intent`, payload)
      .then((res) => ({
        clientSecret: res.clientSecret,
        id: res.id
      }))
  });
};

export const confirmStripePaymentIntent = (paymentIntentData) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { cart, campaigns } = getState();

  return dispatch({
    type: 'CONFIRM_STRIPE_PAYMENT_INTENT',
    payload: baseApi
      .post(`/checkout/${cart.token}/stripe/intent/confirm`, paymentIntentData)
      .then((res) => ({
        paymentIntent: res.paymentIntent,
        token: cart.token,
        items: cart.items,
        total: cart.total,
        totalsGBP: cart.totalsGBP,
        campaigns
      }))
  });
};

export const logStripePaymentRequestError = (message, error) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { cart } = getState();
  const errorMessage = {
    message: `${message}${error && `: ${error.message}`}`
  };

  dispatch({
    type: 'LOG_STRIPE_PAYMENT_REQUEST_ERROR',
    payload: baseApi.post(
      `/checkout/${cart.token}/stripe/intent/error`,
      errorMessage
    )
  });
};

export const getPaypalCheckoutDetails = (paypalData) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const { cart, user } = getState();

  dispatch(
    log(`[PayPal] - /checkout/${cart.token}/paypal/refresh`, {
      paymentId: paypalData.paymentID
    })
  );
  return dispatch({
    type: 'GET_PAYPAL_CHECKOUT_DETAILS',
    payload: baseApi.post(`/checkout/${cart.token}/paypal/refresh`, {
      paymentId: paypalData.paymentID
    }),
    meta: { token: user.token, paypalData }
  });
};
