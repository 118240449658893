import React from 'react';
import { connect, useDispatch } from 'react-redux';

import * as userActions from '../../actions/user';

import Page from '../../components/utils/Page';
import MaxWidth from '../../components/atoms/MaxWidth';
import PageWrap from '../../components/atoms/PageWrap';
import LiveChat from '../../components/atoms/LiveChat';

import Spacing from '../../components/atoms/Spacing';

import everpressLogo from '../../img/everpress-logo.svg';
import SignUpForm from '../../components/molecules/AuthenticationContainer/SignUpForm';

const Register = () => {
  const dispatch = useDispatch();

  const handleUserStageChange = (stage) => {
    if (stage === 'signIn') {
      dispatch({
        type: 'LOGIN'
      });
    }
  };

  const handleSignUp = async ({ email, password }) => {
    const res = await dispatch(userActions.lookupUser(email));
    if ((await res.value.exists) === true) {
      await dispatch({ type: 'SIGN_IN_EXISTING_USER' });

      return dispatch({
        type: 'LOGIN'
      });
    } else {
      await dispatch(userActions.signUp({ email, password }));
    }
  };

  return (
    <Page title="Register">
      <PageWrap className="text-center">
        <MaxWidth center value={415}>
          <Spacing size={15} position="y">
            <img
              src={everpressLogo}
              alt="Everpress logo"
              width="40"
              height="35"
              className="m-auto"
            />
            <Spacing size={15} position="y">
              <SignUpForm
                onSignUp={handleSignUp}
                onUserStageChange={handleUserStageChange}
              />
            </Spacing>
          </Spacing>
        </MaxWidth>
      </PageWrap>
      <LiveChat />
    </Page>
  );
};

export default connect(null, { ...userActions })(Register);
