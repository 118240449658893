import React from 'react';
import classnames from 'classnames';

import Page from '../../../components/utils/Page';
import PageWrap from '../../../components/atoms/PageWrap';
import { Grid, GridItem } from '../../../components/atoms/Grid';
import { ButtonNew as Button } from '../../../components/atoms/Button';
import Heading from '../../../components/atoms/Heading';
import Image from '../../../components/atoms/Image';
import MaxWidth from '../../../components/atoms/MaxWidth';
import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';

import generalStyles from './style/general.module.css';
import Link from '../../atoms/Link';

const sizeMap = {
  s: {
    title: ['l', 'xl', 'xl'],
    spacing: 2
  },
  m: {
    title: ['l', 'xl', 'xxl'],
    spacing: 0
  }
};

function Thanks({ title, description, items, size = 'm' }) {
  return (
    <Page title="Thank You">
      <PageWrap>
        <Spacing position="t" size={[3, 6, 9]}>
          <Spacing position="b" size={[2, 3, 4]}>
            <MaxWidth value={992}>
              <Spacing size={sizeMap[size].spacing} position="b">
                <Heading size={sizeMap[size].title}>{title}</Heading>
              </Spacing>
              <Paragraph>{description}</Paragraph>
            </MaxWidth>
          </Spacing>
        </Spacing>
        <Grid align="start" gap={[0, 0, 3]}>
          {items &&
            items.map((item, i) => {
              return (
                <GridItem
                  key={i}
                  columnSize={[12, 12, 4]}
                  className={classnames({
                    [generalStyles.hidden_mobile]:
                      item.hiddenOnMobile === 'true'
                  })}
                >
                  <Spacing size={[4, 4, 0]} position="b">
                    <Spacing size={[2, 2, 2, 3]} position="b">
                      <div className={generalStyles.thumbnail}>
                        <Link to={item.buttonSlug}>
                          <Image
                            src={item.image}
                            alt={item.buttonLabel}
                            width="592"
                            height="592"
                          />
                        </Link>
                      </div>
                    </Spacing>
                    <Spacing size={1} position="b">
                      <Heading size={'xs'}>{item.title}</Heading>
                    </Spacing>
                    <Button
                      kind="outline"
                      size="tiny"
                      to={item.buttonSlug}
                      isExternal={item.buttonSlug === '/create'}
                    >
                      {item.buttonLabel}
                    </Button>
                  </Spacing>
                </GridItem>
              );
            })}
        </Grid>
      </PageWrap>
    </Page>
  );
}

export default Thanks;
