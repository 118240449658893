import React from 'react';

import Icon from '../../../atoms/Icon';

import checkIcon from '../../../../img/sprites/check.svg';

import generalStyles from '../TickCheckbox/style/general.module.css';

export default class RadioBtn extends React.PureComponent {
  render() {
    const { name, id, children, ...domProps } = this.props;

    return (
      <label htmlFor={id} className={generalStyles.holder}>
        <input
          type="radio"
          id={id || name}
          name={name}
          className={generalStyles.input}
          {...domProps}
        />
        {children}
        <div className={generalStyles.checkbox}>
          <Icon glyph={checkIcon} width="19" height="19" />
        </div>
      </label>
    );
  }
}
