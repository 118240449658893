import { Component } from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'react-fast-compare';
import PropTypes from 'prop-types';

export default class extends Component {
  static defaultProps = {
    debounce: 16,
    onChange: () => null
  };

  static contextTypes = {
    formik: PropTypes.object
  };

  onChange = debounce(
    (values, formikBag) => this.props.onChange(values, formikBag),
    this.props.debounce
  );

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (!isEqual(nextContext.formik.values, this.context.formik.values)) {
      this.onChange(nextContext.formik.values, nextContext.formik);
    }
  }

  render() {
    return null;
  }
}
