import React from 'react';

import Spacing from '../../../../components/atoms/Spacing';
import Heading from '../../../../components/atoms/Heading';
import Paragraph from '../../../../components/atoms/Paragraph';

import {
  AccordionManager,
  AccordionElement
} from '../../../../components/molecules/Accordion';

import Main from '../../Main';
import Title from '../../Title';

import { faq } from '../../config';

import cultureImage from '../../../../img/pages/people/culture.jpg';

const Culture = () => {
  return (
    <Main title="Culture" image={cultureImage}>
      <Spacing size={7} position="b">
        <Title titleCopy="Where we were" />
        <Paragraph className="mb-3">
          We’ve grown quickly since 2016, and though we were proud of a lively,
          social workplace culture, we realised that while for some of our team
          Everpress was a great place to work, this wasn’t necessarily the case
          for everyone.
        </Paragraph>
        <Paragraph className="mb-3">
          Since 2020, we’ve overhauled our company culture. This is twofold: we
          want to provide as much support for our staff as possible, to ensure
          the wellbeing of everybody who works at Everpress. This culture
          prioritises the wellbeing and representation of everyone. From our
          Black and Brown employees, to those from the LGBTQIA+ community, to
          those with disabilities.
        </Paragraph>
        <Paragraph className="mb-3">
          But we also understand that part of this is giving our team the tools
          to create an inclusive workplace, and this means education and
          training.
        </Paragraph>
      </Spacing>
      <Spacing size={7} position="b">
        <Title titleCopy="What we’ve done" />
        <Paragraph className="mb-3">
          We’ve created more support for the team and made learning part of the
          culture by introducing:
        </Paragraph>
        <Spacing size={4}>
          <AccordionManager multiple>
            {faq.map((item) => {
              return (
                <AccordionElement
                  key={item.title}
                  labelTitle={item.title}
                  className="w-100 block border-b border-grey-lighter"
                  size="m"
                  value=" "
                  arrows
                  label={
                    <Heading tag="h4" size="xs">
                      {item.title}
                    </Heading>
                  }
                >
                  <Paragraph
                    size="xxs"
                    className="border-b border-grey-lighter pt-2 pb-4"
                  >
                    {item.content}
                  </Paragraph>
                </AccordionElement>
              );
            })}
          </AccordionManager>
        </Spacing>
      </Spacing>
      <Spacing size={7} position="b">
        <Title titleCopy="What we’re doing next" />
        <Paragraph className="mt-3 mb-1 uppercase">
          Matching Donations
        </Paragraph>
        <Paragraph className="mb-3">
          Alongside our activism days initiative, we want to match any donation
          to any charity made by any of our team through payroll
        </Paragraph>
        <Paragraph className="mb-1 uppercase">Look To Others</Paragraph>
        <Paragraph className="mb-3">
          We know we won’t get better by only looking inward. So we’ll keep
          researching the most progressive company cultures from around the
          world, and we’ll hold ourselves to their standards too.
        </Paragraph>
        <Paragraph className="mb-1 uppercase">No Jargon</Paragraph>
        <Paragraph className="mb-3">
          As well as constantly refining our core values and policies through
          feedback and research, we will make sure all our commitments and
          standards are kept as jargon-free as possible, so that they are clear
          and accessible. We know that it’s not just about having policies, it’s
          about making sure everyone can access and understand them
        </Paragraph>
      </Spacing>
    </Main>
  );
};

export default Culture;
