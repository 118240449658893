import React from 'react';
import PropTypes from 'prop-types';
import { Slider } from 'radix-ui';
import isEmpty from 'lodash/isEmpty';
import { nanoid } from 'nanoid';
import classnames from 'classnames';

import { ButtonNew as Button } from '../../../components/atoms/Button';

import generalStyles from './style/general.module.css';

const SliderMark = ({ value, label, onClick }) => {
  const handleClick = () => {
    onClick?.(value);
  };

  return (
    <span className={generalStyles.mark}>
      <Button kind="ghost" size="tiny" onClick={handleClick}>
        {label || value}
      </Button>
    </span>
  );
};

const SliderComponent = ({ marks, className, onMarkClick, ...props }) => {
  const handleMarkClick = (value) => {
    onMarkClick?.(value);
  };

  return (
    <div>
      <Slider.Root
        className={classnames(generalStyles.root, className)}
        {...props}
      >
        <Slider.Track className={generalStyles.track}>
          <Slider.Range className={generalStyles.range} />
        </Slider.Track>
        <Slider.Thumb className={generalStyles.thumb} />
      </Slider.Root>

      {!isEmpty(marks) && (
        <div className={generalStyles.markContainer}>
          {marks.map((mark) => {
            if (typeof mark === 'object') {
              return (
                <SliderMark
                  value={mark.value}
                  label={mark.label}
                  key={mark.value}
                  onClick={handleMarkClick}
                />
              );
            }

            // Empty label hack for the price calculator
            return (
              <span key={mark ?? nanoid()} className={generalStyles.mark} />
            );
          })}
        </div>
      )}
    </div>
  );
};

SliderComponent.propTypes = {
  asChild: PropTypes.bool,
  defaultValue: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.arrayOf(PropTypes.number),
  onValueChange: PropTypes.func,
  onValueCommit: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  dir: PropTypes.string,
  inverted: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  minStepsBetweenThumbs: PropTypes.number,
  form: PropTypes.string,
  marks: PropTypes.array
};

export default SliderComponent;
