import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import RouterLink from 'redux-first-router-link';

import urlRegex from '../../../../helpers/urlRegex';
import scrollToAnchor from '../../../../helpers/scrollToAnchor';

import generalStyles from './style/general.module.css';
import kindStyles from './style/kind.module.css';
import sizeStyles from './style/size.module.css';

const cx = classNames.bind({ ...generalStyles, ...kindStyles, ...sizeStyles });

Button.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  kind: PropTypes.oneOf(Object.keys(kindStyles)),
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  isActive: PropTypes.bool,
  isToggle: PropTypes.bool
};

export default function Button({
  children,
  isLoading,
  to,
  href,
  kind,
  size,
  fullWidth,
  className,
  isActive,
  isToggle,
  type,
  ...props
}) {
  const isWeird = kind === 'weird';
  const isExternal = typeof to === 'string' && to.match(urlRegex);
  const isDisabled = isLoading || props.disabled;
  let CustomTag = to ? RouterLink : isWeird ? 'div' : 'button';

  // TODO: Remove this hack for /create
  if (isExternal || href || to === '/create') {
    CustomTag = 'a';
  }

  // TODO: This is getting big and complex. Ideally we would have
  // one sub-component per button kind
  const buttonClassName = cx('button', className, size, {
    fullWidth,
    disabled: isDisabled,
    [kind]: !isToggle && !isDisabled,
    [`${kind}Active`]: isActive,
    [`${kind}Toggle`]: isToggle,
    [`${kind}Disabled`]: isDisabled
  });

  const innerClassName = cx('inner', {
    innerWeird: isWeird
  });

  let buttonProps = {
    to,
    type,
    disabled: isLoading,
    ...props
  };

  if (isExternal) {
    buttonProps = {
      href: to,
      target: '_blank',
      rel: 'noopener noreferrer',
      ...props
    };
  }

  // TODO: Remove this hack for /create
  if (href || to === '/create') {
    buttonProps = {
      href: to === '/create' ? to : href, // TODO: Remove this hack for /create
      ...props
    };
  }

  // Anchor links
  if (typeof to === 'string' && to.charAt(0) === '#') {
    buttonProps = {
      to,
      onClick: scrollToAnchor,
      ...props
    };
  }

  return (
    <CustomTag className={buttonClassName} {...buttonProps}>
      <span className={innerClassName}>{children}</span>
      {kind === 'weird' && <span className={cx('weirdBg')} />}
    </CustomTag>
  );
}

Button.defaultProps = {
  kind: 'default',
  size: 's',
  type: 'button',
  isActive: false,
  isToggle: false
};
