import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { redirect } from 'redux-first-router';
import { nanoid } from 'nanoid';
import partition from 'lodash/partition';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';

import {
  getCampaignIds,
  getEndedLaunchedCampaigns
} from '../../helpers/selectors/cart';

import usePrevious from '../../helpers/hooks/usePrevious';

import * as cartActions from '../../actions/cart';
import { getGiftCards } from '../../actions/shop';

import Page from '../../components/utils/Page';

import Alert from '../../components/atoms/Alert';
import { ButtonNew as Button } from '../../components/atoms/Button';
import { Grid, GridItem } from '../../components/atoms/Grid';
import Heading from '../../components/atoms/Heading';
import Link from '../../components/atoms/Link';
import { PageWrapNew as PageWrap } from '../../components/atoms/PageWrap';
import Spacing from '../../components/atoms/Spacing';

import Loading from '../../components/utils/Loading';

import StripeContainer from './Stripe/StripeContainer';
import StripeExpressCheckout from './Stripe/StripeExpressCheckout';

import CartItem from './CartItem';
import CartSummary from './CartSummary';
import EmptyCart from './EmptyCart';
import RecommendedItems from './RecommendedItems';
import GiftCards from './GiftCards';

import generalStyles from './style/general.module.css';
import { addCartItem } from '../../actions/cart';

const Cart = () => {
  const {
    token,
    items,
    recommended,
    campaigns,
    subtotal,
    voucherRedemptions
  } = useSelector((state) => state.cart, shallowEqual);
  const status = useSelector((state) => state.cart.status);
  const prevLocation = useSelector((state) => state.location.prev);
  const currency = useSelector((state) => state.user.currency);
  const shopGiftCards = useSelector((state) => state.shop.giftCards?.items);
  const prevItems = usePrevious(items);
  const recommendedItems = recommended.items;
  const dispatch = useDispatch();
  const [endedCampaigns, setEndedCampaigns] = useState([]);
  const [subtotalOverride, setSubtotalOverride] = useState(null);
  const [giftCards, products] = partition(items, 'giftCardId');

  useEffect(() => {
    const endedCampaigns = getEndedLaunchedCampaigns(campaigns);

    setEndedCampaigns(endedCampaigns);
  }, [campaigns]);

  useEffect(() => {
    if (endedCampaigns.length > 0) {
      const endedCampaignIds = getCampaignIds(endedCampaigns);
      const tempSubtotal = items
        .filter((item) => !endedCampaignIds.includes(item.campaignId))
        .reduce((acc, curr) => acc + curr.price * curr.quantity, 0);

      setSubtotalOverride(tempSubtotal);
      return;
    }

    setSubtotalOverride(null);
  }, [endedCampaigns, items]);

  useEffect(() => {
    if (
      recommendedItems.length > 0 &&
      prevLocation.type === 'CAMPAIGN_OR_STORE'
    ) {
      return;
    }

    if (items.length > (prevItems || []).length) {
      dispatch(cartActions.getRecommendedItems());
    }
  }, [items, prevItems, recommendedItems, prevLocation, dispatch]);

  useEffect(() => {
    if (shopGiftCards.length > 0) {
      return;
    }

    if (items.length > 0) {
      dispatch(getGiftCards());
    }
  }, [items, shopGiftCards, dispatch]);

  const redirectToCheckout = async () => {
    await dispatch(
      redirect({
        type: 'CHECKOUT'
      })
    );
  };

  const handleFindMoreItemsClick = async () => {
    await dispatch(cartActions.findMoreItems());
    await dispatch(
      redirect({
        type: 'SHOP'
      })
    );
  };

  const handleGiftCardAddToCart = async (item) => {
    await dispatch(addCartItem({ quantity: 1, giftCardId: item.giftCardId }));
  };

  const isEmptyCart = isEmpty(items);
  const isLoading = status === 'pending';
  const hasErrored = status === 'rejected';
  const hasEndedCampaigns = !isEmpty(endedCampaigns);
  const hideExpressPayOption = !isEmpty(voucherRedemptions);
  const hidePayOption =
    isLoading || hasErrored || hasEndedCampaigns || isEmptyCart;
  const groupedShopGiftCards = isEmpty(shopGiftCards)
    ? []
    : groupBy(shopGiftCards, 'currencyIso');

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page title="Shopping bag">
      <PageWrap>
        <Grid gap={[0, 0, 1, 25]} align="start">
          <GridItem columnSize={[12, 12, 12, 6]}>
            <div className={generalStyles.cartDivider}>
              <Spacing size={2} position="t">
                <Spacing size={[1]} position="b">
                  <Heading size="xs">Shopping bag</Heading>
                </Spacing>
              </Spacing>
            </div>
            {isEmptyCart ? (
              <Spacing size="05" position="b" type="padding">
                <EmptyCart />
              </Spacing>
            ) : (
              <Spacing size={15} position="t">
                <Spacing size={1} position="b">
                  {hasErrored && (
                    <Alert kind="error">
                      Something isn't quite right here. Try refreshing the page.
                    </Alert>
                  )}
                  {giftCards.map((giftCard, index) => (
                    <Fragment key={nanoid()}>
                      <CartItem
                        item={giftCard}
                        currency={currency}
                        campaign={null}
                        onEdit={(item) => {
                          dispatch(cartActions.editCartItem(item));
                        }}
                        onDelete={(orderItemId) => {
                          dispatch(cartActions.deleteCartItem(orderItemId));
                        }}
                      />
                      {!products.length && index < giftCards.length - 1 && (
                        <div className="border-b-1 border-grey-lighter mt-1" />
                      )}
                    </Fragment>
                  ))}
                  {products.map((product, index) => (
                    <Fragment key={nanoid()}>
                      <CartItem
                        item={product}
                        currency={currency}
                        campaign={
                          campaigns.filter(
                            (campaign) =>
                              campaign.campaignId === product.campaignId
                          )[0]
                        }
                        onEdit={(item) => {
                          dispatch(cartActions.editCartItem(item));
                        }}
                        onDelete={(orderItemId) => {
                          dispatch(cartActions.deleteCartItem(orderItemId));
                        }}
                      />
                      {index < products.length - 1 && (
                        <div className="border-b-1 border-grey-lighter mt-1" />
                      )}
                    </Fragment>
                  ))}
                </Spacing>
              </Spacing>
            )}
            <div className="mdlg:border-b-1 border-grey-lighter mdlg:border-black" />
          </GridItem>
          <GridItem
            columnSize={[12, 12, 12, 6]}
            className={generalStyles.sticky}
          >
            <div className="border-b-1 border-grey-lighter mdlg:border-none" />
            <CartSummary subtotal={subtotalOverride ?? subtotal} />
            <Spacing size={2} position="t">
              <Spacing size={15} position="b">
                <Button
                  onClick={redirectToCheckout}
                  state={hidePayOption ? 'disabled' : null}
                  fullWidth
                  data-test-id="cart-submit"
                >
                  Continue to checkout
                </Button>
              </Spacing>
            </Spacing>
            {!hideExpressPayOption && !hasEndedCampaigns && subtotal ? (
              <Spacing size={15} position="b">
                <StripeContainer amount={subtotal} currency={currency}>
                  <StripeExpressCheckout
                    checkoutToken={token}
                    amount={subtotal}
                  />
                </StripeContainer>
              </Spacing>
            ) : null}
            {hasEndedCampaigns && (
              <Spacing size={15} position="b">
                <Alert type="error">
                  Please remove ended campaigns before continuing
                </Alert>
              </Spacing>
            )}
            {!isEmptyCart && (
              <div className="text-center mb-15 md:hidden">
                <Link className="text-xxs" onClick={handleFindMoreItemsClick}>
                  Find more items
                </Link>
              </div>
            )}
          </GridItem>
        </Grid>
        {isEmpty(shopGiftCards) ? null : (
          <>
            <Spacing size={[3, 3, 6]} type="padding">
              <div className="absolute w-full left-0 border-b-1 border-grey-lighter md:hidden" />
            </Spacing>
            <GiftCards
              items={groupedShopGiftCards[currency]}
              onAddToCart={handleGiftCardAddToCart}
            />
          </>
        )}
        {!isEmptyCart && !isEmpty(recommendedItems) ? (
          <>
            <Spacing size={[3, 3, 3]} type="padding" />
            <RecommendedItems items={recommendedItems} />
          </>
        ) : null}
      </PageWrap>
    </Page>
  );
};

export default Cart;
