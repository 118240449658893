import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import * as ordersActions from '../../actions/orders';

import Page from '../../components/utils/Page';

import Heading from '../../components/atoms/Heading';
import PageWrap from '../../components/atoms/PageWrap';
import MaxWidth from '../../components/atoms/MaxWidth';
import { ButtonNew as Button } from '../../components/atoms/Button';
import Paragraph from '../../components/atoms/Paragraph';
import Spacing from '../../components/atoms/Spacing';

class EmailOptOut extends PureComponent {
  state = {
    isSubmitting: false,
    isUnsubscribed: false,
    error: null
  };

  onUnsubscribeClick() {
    this.setState({ isSubmitting: true });

    this.props
      .editOrder(this.props.token, { emailOptIn: false })
      .then(() => {
        this.setState({ isUnsubscribed: true });
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }

  render() {
    const { isSubmitting, isUnsubscribed, error } = this.state;

    return (
      <Page title="Email opt-out">
        <PageWrap>
          <MaxWidth value={500} center>
            <Heading>Email options</Heading>
            <Spacing size={2}>
              <Paragraph>
                Remove yourself from all future marketing emails.
              </Paragraph>
            </Spacing>
            {!isUnsubscribed ? (
              <Button
                onClick={this.onUnsubscribeClick.bind(this)}
                state={isSubmitting && 'disabled'}
              >
                Unsubscribe
              </Button>
            ) : (
              <Paragraph>Unsubscribed successfully.</Paragraph>
            )}
            {error && (
              <Paragraph>
                An error happened while unsubscribing you, please try again.
              </Paragraph>
            )}
          </MaxWidth>
        </PageWrap>
      </Page>
    );
  }
}

const mapStateToProps = ({ location }) => {
  const { token } = location.payload;

  return { token };
};

export default connect(mapStateToProps, { ...ordersActions })(EmailOptOut);
