import React from 'react';
import PropTypes from 'prop-types';

export default function ListWrapper({ type, children }) {
  const CustomTag = type;

  return (
    <CustomTag>{children}</CustomTag>
  );
}

ListWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['ul', 'ol'])
};

ListWrapper.defaultProps = {
  type: 'ul'
};
