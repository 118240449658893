import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { getText } from '../../helpers/prismic';

import Page from '../../components/utils/Page';
import SingleFeature from '../../pages/Home/SingleFeature';
import Spacing from '../../components/atoms/Spacing';
import LiveChat from '../../components/atoms/LiveChat';

import Slices from '../../components/molecules/Slices';

function Landing({ seoTitle, title, slides, slices }) {
  return (
    <Page title={seoTitle}>
      <Spacing size={[2, 3, 4, 5, 6]} type="padding" position="b">
        <SingleFeature slides={slides} heading={title} isCustomLanding />
      </Spacing>

      <Slices slices={slices} />
      <LiveChat />
    </Page>
  );
}

const mapStateToProps = ({ pages, location }) => {
  const data = get(pages.landing.bySlug[location.payload.slug], 'data', {});

  return {
    seoTitle: data.seo_title,
    title: getText(data.page_title),
    slides: data.hero_slides || [],
    slices: data.body || []
  };
};

export default connect(mapStateToProps)(Landing);
