import React from 'react';
import classNames from 'classnames';

import generalStyles from './style/general.module.css';

export default class RadioBtn extends React.PureComponent {
  render() {
    const { name, id, children, ...domProps } = this.props;

    return (
      <label htmlFor={id} className={generalStyles.holder}>
        <input
          type="radio"
          id={id || name}
          name={name}
          className={generalStyles.input}
          {...domProps}
        />
        <div className={classNames(generalStyles.radio, 'rounded-round')} />
        {children}
      </label>
    );
  }
}
