const transition = {
  duration: 0.35,
  ease: [0.2, 0, 0.2, 1],
  when: 'beforeChildren'
};

export const overlayVariants = {
  visible: {
    opacity: 1,
    transition
  },
  hidden: {
    opacity: 0,
    transition
  }
};
