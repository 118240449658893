import produce from 'immer';
import find from 'lodash/find';
import merge from 'lodash/merge';
import pick from 'lodash/pick';

import config from '../config';

import * as persistentState from '../services/local-storage';

const initialState = {
  token: null,
  quantity: 0,
  campaigns: [],
  items: [],
  recommended: {
    items: [],
    status: 'idle'
  },
  currency: null,
  delivery: null,
  subtotal: null,
  total: null,
  totalsGBP: null,
  voucherRedemptions: [],
  status: 'idle'
};

export default (
  state = {
    ...initialState,
    ...persistentState.get(config.localStorageCartKey)
  },
  { type, payload, meta }
) =>
  produce(state, (newState) => {
    let campaign;
    switch (type) {
      case 'CREATE_CART_PENDING':
        newState.status = 'pending';
        return newState;
      case 'CREATE_CART_FULFILLED':
        newState = merge({}, state, payload);
        newState.status = 'resolved';
        return newState;
      case 'GET_CART_PENDING':
        newState.status = 'pending';
        return newState;
      case 'ADD_CART_ITEM_PENDING':
        if (meta?.item) {
          const { campaignProductId, size, colourId } = meta.item;
          const item = find(newState.items, {
            campaignProductId,
            size,
            colourId
          });
          if (item) {
            item.quantity++;
          } else {
            newState.items.push(meta.item);
          }

          if (!item?.giftCardId) {
            newState.status = 'pending';
          }
        }
        return newState;
      case 'ADD_CART_ITEM_FULFILLED':
        newState = merge({}, state, payload);
        newState.status = 'resolved';
        return newState;
      case 'DELETE_CART_ITEM_PENDING':
        if (meta?.items) {
          newState.items = meta.items;
        }
        return newState;
      case 'GET_CART_FULFILLED':
      case 'EDIT_CART_ITEM_FULFILLED':
      case 'DELETE_CART_ITEM_FULFILLED':
      case 'SET_CART_CURRENCY_FULFILLED':
        newState = payload;
        newState.recommended = state.recommended;
        newState.status = 'resolved';
        return newState;
      case 'CREATE_CART_REJECTED':
      case 'EDIT_CART_ITEM_REJECTED':
      case 'DELETE_CART_ITEM_REJECTED':
      case 'SET_CART_CURRENCY_REJECTED':
        newState.status = 'rejected';
        return newState;
      case 'GET_CART_REJECTED':
      case 'ADD_CART_ITEM_REJECTED':
        if (payload.response?.status === 404) {
          newState = initialState;
          newState.currency = state.currency;
        }
        newState.status = 'rejected';
        return newState;
      case 'GET_CAMPAIGN_ARTWORKS_POSITIONS_FULFILLED':
        campaign = find(newState.campaigns, ['campaignId', meta.id]);
        if (campaign) campaign.artworks = payload;
        return newState;
      case 'GET_CAMPAIGN_GARMENTS_FULFILLED':
        campaign = find(newState.campaigns, ['campaignId', meta.id]);
        if (campaign) campaign.catalogue = payload;
        return newState;
      case 'GET_RECOMMENDED_ITEMS_PENDING':
        newState.recommended.status = 'pending';
        return newState;
      case 'GET_RECOMMENDED_ITEMS_FULFILLED':
        newState.recommended.items = payload.results;
        newState.recommended.status = 'resolved';
        return newState;
      case 'GET_RECOMMENDED_ITEMS_REJECTED':
        newState.recommended.status = 'rejected';
        return newState;
      case 'EDIT_CHECKOUT_DETAILS_FULFILLED':
        newState = merge(
          {},
          state,
          pick(payload, 'items', 'delivery', 'subtotal', 'total', 'totalsGBP')
        );
        return newState;
      case 'APPLY_DISCOUNT_CODE_FULFILLED':
        newState.total = payload.total;
        return newState;
      case 'CHECKOUT_PAYPAL_FULFILLED':
      case 'CHECKOUT_STRIPE_FULFILLED':
      case 'CONFIRM_STRIPE_PAYMENT_INTENT_FULFILLED':
        newState = initialState;
        newState.currency = state.currency;
        return newState;
      default:
        return state;
    }
  });

/**
 * This is a complex function to do a simple thing: keep user choices across
 * products type changes: if user changes the product type, we want to keep his
 * size and colour choices.
 *
 * @param {string} key
 * @param {object} newItem
 * @param {object} cartItemValues
 * @param {object} campaign
 *
 * Returns the `size` or `colourId` value of the basket item
 */
export function getNewItemValue(key, newItem, cartItemValues, campaign) {
  if (key === 'size') {
    // If size exists in new product
    if (
      newItem.product.sizes.find((size) => size.code === cartItemValues.size)
    ) {
      return cartItemValues.size;
    } else {
      // fallback to default value
      return newItem.product.sizes[0].code;
    }
  }

  if (key === 'colourId') {
    const basketItem = campaign.products.find(
      (product) =>
        product.campaignProductId === cartItemValues.campaignProductId
    );
    const basketItemColour = basketItem.colours.find(
      (colour) => colour.colourId === cartItemValues.colourId
    );
    const existingColour = newItem.colours.find(
      (colour) => colour.name === basketItemColour.name
    );

    // If colour exists in new product
    if (existingColour) {
      return existingColour.colourId;
    } else {
      // fallback to default value
      return newItem.colours.find(
        (colour) => colour.colourId === newItem.featuredColourId
      ).colourId;
    }
  }
}
