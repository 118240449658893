import React from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';

import { Grid, GridItem } from '../../../atoms/Grid';
import Heading from '../../../atoms/Heading';
import Paragraph from '../../../atoms/Paragraph';
import Spacing from '../../../atoms/Spacing';

import generalStyles from './style/general.module.css';

export default function FeatureThumbnail({
  image,
  title,
  tag,
  direction,
  to,
  ratio
}) {
  const isHorizontal = direction === 'horizontal' || false;
  const leftColSize = isHorizontal ? 4 : 12;
  const rightColSize = isHorizontal ? 8 : 12;
  const spacingSize = isHorizontal ? 0 : 1;
  const gap = isHorizontal ? 1 : 0;
  const ratioStyle = {
    backgroundImage: `url(${image})`,
    paddingBottom: `${ratio}%`,
    height: 0
  };
  const ratioProps = {
    className: 'bg-cover bg-center',
    style: ratioStyle
  };

  const LinkTag = to ? Link : 'div';

  return (
    <LinkTag to={to}>
      <Grid gap={gap} align="space-between">
        <GridItem columnSize={leftColSize}>
          <Spacing size={spacingSize} position="b">
            {ratio > 0 ? (
              <div {...ratioProps} />
            ) : (
              <img src={image} alt={title} />
            )}
          </Spacing>
        </GridItem>
        <GridItem columnSize={rightColSize}>
          <div className={generalStyles.details}>
            <Heading size="xs">
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Heading>
            <Paragraph size="xxxs">{tag}</Paragraph>
          </div>
        </GridItem>
      </Grid>
    </LinkTag>
  );
}

FeatureThumbnail.propTypes = {
  image: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  ratio: PropTypes.number
};

FeatureThumbnail.defaultProps = {
  direction: 'horizontal',
  ratio: 0,
  to: '',
  tag: ''
};
