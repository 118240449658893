import React from 'react';

import { InlineGrid } from '../../atoms/Grid';
import Heading from '../../atoms/Heading';
import Spacing from '../../atoms/Spacing';
import Icon from '../../atoms/Icon';
import Paragraph from '../../atoms/Paragraph';

import zapIcon from '../../../img/sprites/zap.svg';

const ShopPODCampaign = ({ title }) => {
  return (
    <InlineGrid>
      <Heading
        className="ellipsis leading-relaxed"
        size={['xxs', 'xs']}
        tag="h2"
      >
        {title}
      </Heading>
      <InlineGrid justify="end">
        <Spacing size="05" position="r">
          <Icon glyph={zapIcon} width={20} height={20} />
        </Spacing>
        <Paragraph size="xxs" className="text-blue-primary">
          Fast Shipping
        </Paragraph>
      </InlineGrid>
    </InlineGrid>
  );
};

export default ShopPODCampaign;
