import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from "@sentry/react";
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import pickBy from 'lodash/pickBy';
import debounce from 'lodash/debounce';
import xorWith from 'lodash/xorWith';
import isEqual from 'lodash/isEqual';

import {
  sortColours,
  concatColourToEnd,
  transformInitial,
  getInitialProducts,
  objectDeepDiff,
  getSelectedColoursLength
} from '../../../helpers/campaignBuilder';

import useTouchDetect from '../../../helpers/hooks/useTouchDetect';

import {
  addCampaignProduct,
  deleteCampaignProduct,
  updateCampaignProduct
} from '../../../actions/campaign-builder';

import Alert from '../../../components/atoms/Alert';
import Heading from '../../../components/atoms/Heading';
import Paragraph from '../../../components/atoms/Paragraph';
import Link from '../../../components/atoms/Link';
import { ButtonNew as Button } from '../../../components/atoms/Button';
import Spacing from '../../../components/atoms/Spacing';
import { CSSGrid, CSSGridItem } from '../../../components/atoms/Grid';
import { TabsList } from '../../../components/atoms/Tabs';
import { Field, Form, Label } from '../../../components/atoms/Form';
import Image from '../../../components/atoms/Image';
import ColourCircle from '../../../components/atoms/ColourCircle';

import IconButton from '../../../components/atoms/IconButton';

import GarmentThumbnail from './GarmentThumbnail';

import uploaderStyles from '../ArtworkUploader/style/general.module.css';

import crossIcon from '../../../img/sprites/cross.svg';

const DEFAULT_COLOUR_CODE = 'ffffff';

const Catalogue = ({ products, onSave }) => {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [productError, setProductError] = useState(null);
  const status = useSelector((state) => state.campaignBuilder.status);
  const { isTouch } = useTouchDetect();

  const activeProducts = products.filter((product) => product.active);

  const productCategories = Object.keys(
    groupBy(activeProducts, (product) => product.categoryName.toLowerCase())
  );
  const productGenders = Object.keys(
    groupBy(activeProducts, (product) => product.gender.toLowerCase())
  );
  const productFits = Object.keys(
    groupBy(activeProducts, (product) => product.productFit.reference)
  );
  const campaignProducts = useSelector(
    (state) => state.campaignBuilder.campaignProducts
  );

  const formRef = useRef(null);

  const visibleProducts = selectedCategory
    ? activeProducts.filter(
        (product) => product.categoryName.toLowerCase() === selectedCategory
      )
    : activeProducts;

  const filteredVisibleProducts = visibleProducts
    .filter((product) => {
      const selectedProductGenders = Object.keys(
        pickBy(selectedFilters.productGenders)
      );

      if (isEmpty(selectedProductGenders)) return true;

      return selectedProductGenders.includes(product.gender);
    })
    .filter((product) => {
      const selectedProductFits = Object.keys(
        pickBy(selectedFilters.productFits)
      );

      if (isEmpty(selectedProductFits)) return true;

      return selectedProductFits.includes(product.productFit?.reference);
    });

  const initialFilters = {
    productGenders: transformInitial(productGenders),
    productFits: transformInitial(productFits)
  };
  const initialProducts = getInitialProducts(activeProducts, campaignProducts);

  const handleProductSelect = useCallback(
    (values) => {
      setProductError(null);

      const target = objectDeepDiff(values, initialProducts);

      const targetSku = last(Object.keys(target));

      const productsArr = Object.keys(values)
        .map((key) => {
          const colourCodes = Object.keys(pickBy(values[key].colours));

          if (isEmpty(colourCodes)) return null;

          const targetProduct = products.find((product) => product.sku === key);
          const targetCampaignProduct = campaignProducts.find(
            (campaignProduct) => campaignProduct.product.sku === key
          );

          const colours = colourCodes.map((code) => {
            const productColour = targetProduct.colours.find(
              (colour) => colour.code === code
            );

            return {
              ...productColour
            };
          });

          const prevProduct = campaignProducts.find(
            (campaignProduct) => campaignProduct.product.sku === key
          );

          const selectedColour = xorWith(
            colours,
            prevProduct?.colours || [],
            isEqual
          )[0];

          const isRemovedColour = !colours.find(
            (colour) => colour.colourId === selectedColour?.colourId
          );

          const sortedColours = sortColours(colours);

          const selectedColours = isRemovedColour
            ? sortedColours
            : concatColourToEnd(sortedColours, selectedColour);

          const featuredColour = isRemovedColour
            ? last(sortedColours)
            : selectedColour;

          return {
            colours: selectedColours,
            colourFeatured: featuredColour,
            ...(targetCampaignProduct
              ? { campaignProductId: targetCampaignProduct.campaignProductId }
              : {}),
            product: {
              sku: targetProduct.sku,
              productId: targetProduct.productId,
              brandName: targetProduct.brandName,
              name: targetProduct.name,
              areas: targetProduct.areas
            }
          };
        })
        .filter(Boolean);

      const added = productsArr.length > campaignProducts.length;
      const updated = productsArr.length === campaignProducts.length;
      const removed = productsArr.length < campaignProducts.length;

      const selectedProduct = productsArr.find(
        (product) => product.product.sku === targetSku
      );

      (async () => {
        try {
          if (added) await dispatch(addCampaignProduct(selectedProduct));

          if (updated)
            selectedProduct?.campaignProductId &&
              (await dispatch(updateCampaignProduct(selectedProduct)));

          if (removed) {
            const removedCampaignProduct = campaignProducts.find(
              (campaignProduct) => campaignProduct.product.sku === targetSku
            );

            const {
              campaignProductId: removedCampaignProductId,
              featured
            } = removedCampaignProduct;

            if (campaignProducts.length < 2) return;

            if (featured) {
              const newFeaturedProduct = campaignProducts.filter(
                (product) =>
                  product.campaignProductId !== removedCampaignProductId
              )[0];

              // Swap featured product before deleting
              await dispatch(
                updateCampaignProduct({
                  ...newFeaturedProduct,
                  featured: true
                })
              );
            }

            removedCampaignProductId &&
              (await dispatch(deleteCampaignProduct(removedCampaignProductId)));
          }
        } catch (err) {
          setProductError(
            err?.message || 'Unable to update products, please try again later.'
          );

          formRef.current?.resetForm(initialProducts);

          Sentry.captureException(err);
        }
      })();
    },
    [products, initialProducts, campaignProducts, dispatch]
  );

  const debouncedProductSelect = useMemo(
    () => debounce(handleProductSelect, 1000),
    [handleProductSelect]
  );

  const handleProductClick = (sku, colourCode) => {
    formRef.current?.setFieldValue(`${sku}.colours.${colourCode}`, true);
  };

  const handleProductDelete = async (product) => {
    const { campaignProductId, featured } = product;

    if (campaignProducts.length < 2) return;

    setProductError(null);

    try {
      if (featured) {
        const newFeaturedProduct = campaignProducts.filter(
          (product) => product.campaignProductId !== campaignProductId
        )[0];

        // Swap featured product before deleting
        await dispatch(
          updateCampaignProduct({
            ...newFeaturedProduct,
            featured: true
          })
        );
      }

      await dispatch(deleteCampaignProduct(campaignProductId));
    } catch (err) {
      setProductError(
        err?.message || 'Unable to update products, please try again later.'
      );

      formRef.current?.resetForm(initialProducts);

      Sentry.captureException(err);
    }
  };

  const handleSave = () => {
    onSave?.();
  };

  const isLoading = status === 'pending';
  const isUpdating = status === 'updating';
  const isDeleting = status === 'deleting';

  const isDeleteAllowed = campaignProducts.length > 1;

  return (
    <>
      <div className="fixed w-screen h-screen bg-white z-0" />
      <div className="px-2 py-3 mdlg:px-5 max-w-xl z-10">
        <CSSGrid
          template="auto 1fr / auto minmax(600px, 950px) 300px"
          columnGap={[5, 5, 5, 5, 5, 6]}
          rowGap={4}
        >
          <CSSGridItem
            column={{ start: 1, end: 3 }}
            row={{ start: 1, end: 2 }}
            height="auto"
          >
            <Heading tag="h1" size="m">
              Add products
            </Heading>
            <Spacing size={1} position="t">
              <Paragraph size="xxs">
                To view and select available colours, hover over a garment.{' '}
                <Link to="/garments">What garments should I choose?</Link>
              </Paragraph>
            </Spacing>
          </CSSGridItem>

          <CSSGridItem column={{ start: 1, end: 2 }} row={{ start: 2, end: 3 }}>
            <Heading tag="p" size="xxs">
              Product
            </Heading>
            <Spacing size={1} position="t">
              <TabsList direction="vertical">
                <div style={{ margin: '0 0 3px 0' }}>
                  <Button
                    type="button"
                    kind="ghost"
                    size="small"
                    color="black"
                    onClick={() => setSelectedCategory(null)}
                    state={!selectedCategory ? 'active' : 'default'}
                  >
                    All
                  </Button>
                </div>
                {productCategories.map((category) => (
                  <div style={{ margin: '3px 0 3px 0' }} key={category}>
                    <Button
                      type="button"
                      kind="ghost"
                      size="small"
                      color="black"
                      onClick={() => setSelectedCategory(category)}
                      state={
                        selectedCategory === category ? 'active' : 'default'
                      }
                    >
                      {category}
                    </Button>
                  </div>
                ))}
              </TabsList>
            </Spacing>

            <Form
              enableReinitialize
              initialValues={initialFilters}
              onSubmit={() => null}
              onChange={(values) => setSelectedFilters(values)}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Spacing size={2} position="t">
                    <Heading tag="p" size="xxs">
                      Gender
                    </Heading>
                    <Spacing size={1} position="t">
                      <div className="flex flex-col justify-start">
                        {productGenders.map((gender, index) => (
                          <div
                            style={{
                              margin: index ? '3px 0 3px 0' : '0 0 3px 0',
                              width: 'fit-content'
                            }}
                            key={gender}
                          >
                            <Field
                              key={gender}
                              type="buttonCheckbox"
                              name={`productGenders.${gender}`}
                              size="s"
                              kind="ghost"
                            >
                              <Label
                                htmlFor={`productGenders.${gender}`}
                                noPad
                                type="paragraph"
                                size="xxxs"
                              >
                                {gender}
                              </Label>
                            </Field>
                          </div>
                        ))}
                      </div>
                    </Spacing>
                  </Spacing>

                  <Spacing size={2} position="t">
                    <Heading tag="p" size="xxs">
                      Fit
                    </Heading>
                    <Spacing size={1} position="t">
                      <div className="flex flex-col justify-start">
                        {productFits.map((fit, index) => (
                          <div
                            style={{
                              margin: index ? '3px 0 3px 0' : '0 0 3px 0',
                              width: 'fit-content'
                            }}
                            key={fit}
                          >
                            <Field
                              key={fit}
                              type="buttonCheckbox"
                              name={`productFits.${fit}`}
                              size="s"
                              kind="ghost"
                            >
                              <Label
                                htmlFor={`productFits.${fit}`}
                                noPad
                                type="paragraph"
                                size="xxxs"
                              >
                                {fit}
                              </Label>
                            </Field>
                          </div>
                        ))}
                      </div>
                    </Spacing>
                  </Spacing>
                </form>
              )}
            />
          </CSSGridItem>

          <CSSGridItem column={{ start: 2, end: 3 }} row={{ start: 2, end: 3 }}>
            <Spacing size={4} position="b">
              <Form
                ref={formRef}
                enableReinitialize
                initialValues={initialProducts}
                onSubmit={() => null}
                onChange={debouncedProductSelect}
                render={({ handleSubmit, values }) => {
                  // Check form values for instant disabledColour validation - skip waiting for the debounced request
                  const selectedCatalogueColoursLength = getSelectedColoursLength(
                    values
                  );

                  return (
                    <form onSubmit={handleSubmit}>
                      <div
                        className="grid gap-x-12 xl:gap-x-20 gap-y-16"
                        style={{
                          gridTemplateColumns:
                            'repeat(auto-fill, minmax(200px, 1fr))' // Match thumbnail image width
                        }}
                      >
                        {filteredVisibleProducts.map((product) => {
                          const campaignProduct = campaignProducts.find(
                            (cp) => cp.product.sku === product.sku
                          );
                          const selectedColours =
                            campaignProduct?.colours || [];

                          const featuredColour =
                            campaignProduct?.colourFeatured || null;

                          const selectedProductColourCodes = Object.keys(
                            pickBy(values[product.sku].colours)
                          );

                          // Only one colour selected overall and it belongs to this product
                          const disabledColourCode =
                            selectedCatalogueColoursLength < 2 &&
                            !isEmpty(selectedProductColourCodes)
                              ? selectedProductColourCodes[0]
                              : undefined;

                          return (
                            <div role="button" key={product.productId}>
                              <GarmentThumbnail
                                product={product}
                                defaultColourCode={DEFAULT_COLOUR_CODE}
                                selectedColours={selectedColours}
                                featuredColour={featuredColour}
                                onClick={() =>
                                  handleProductClick(
                                    product.sku,
                                    product.colours.find(
                                      (colour) => colour.name === 'White'
                                    )?.code || product.colours[0].code
                                  )
                                }
                                disabled={isLoading || isDeleting}
                                disabledColourCode={disabledColourCode}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </form>
                  );
                }}
              />
            </Spacing>
          </CSSGridItem>

          <CSSGridItem column={{ start: 3, end: 4 }} row={{ start: 1, end: 3 }}>
            <aside>
              <Heading size="s" className="leading-normal">
                Your product selection
              </Heading>
              <Spacing size={2} position="t">
                {campaignProducts.map((product) => {
                  const { product: productData, ...campaignProduct } = product;

                  const activeBgColour =
                    campaignProduct.colourFeatured?.code || DEFAULT_COLOUR_CODE;

                  const sortedColours = sortColours(campaignProduct.colours);
                  const sortedConcatColours = concatColourToEnd(
                    sortedColours,
                    campaignProduct.colourFeatured
                  );

                  return (
                    <div
                      className="relative group mb-2 inline-flex items-center gap-8"
                      key={campaignProduct.campaignProductId}
                    >
                      <span className="p-05">
                        <Image
                          style={{
                            width: '64px',
                            height: '64px',
                            backgroundColor: `#${activeBgColour}`
                          }}
                          src={productData.areas[0].whiteUrl}
                          alt={productData.name}
                          width="64"
                          height="64"
                          placeholderColor={DEFAULT_COLOUR_CODE}
                        />
                      </span>
                      <div>
                        <Heading size="xxs" tag="p">
                          {productData.brandName}
                        </Heading>
                        <Paragraph size="xxs" className="leading-loose">
                          {productData.name}
                        </Paragraph>
                        <div style={{ marginTop: '2px' }}>
                          <div className="inline-flex flex-wrap items-center gap-2">
                            {sortedConcatColours.map((colour) => {
                              return (
                                <div key={colour.colourId}>
                                  <ColourCircle code={colour.code} size="m" />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {isDeleteAllowed && (
                          <span
                            className={
                              isTouch
                                ? uploaderStyles.deleteButtonTouch
                                : uploaderStyles.deleteButton
                            }
                          >
                            <IconButton
                              glyph={crossIcon}
                              size="tiny"
                              kind="secondary"
                              title="Remove"
                              state={
                                isLoading || isUpdating || isDeleting
                                  ? 'disabled'
                                  : undefined
                              }
                              onClick={() => handleProductDelete(product)}
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Spacing>
              <Spacing size={2} position="t">
                <Button
                  fullWidth
                  onClick={handleSave}
                  state={
                    isLoading || isUpdating || isDeleting
                      ? 'loading'
                      : 'default'
                  }
                >
                  Done
                </Button>
              </Spacing>
              {productError && (
                <Spacing size={15} position="t">
                  <Alert kind="error">{productError}</Alert>
                </Spacing>
              )}
            </aside>
          </CSSGridItem>
        </CSSGrid>
      </div>
    </>
  );
};

export default Catalogue;
