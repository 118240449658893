import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { theme } from '../../../../theme-config';

import { ButtonNew as Button } from '../../../../components/atoms/Button';
import IconButton from '../../../../components/atoms/IconButton';
import {
  CSSGrid,
  CSSGridItem,
  InlineGrid
} from '../../../../components/atoms/Grid';
import Heading from '../../../../components/atoms/Heading';
import Icon from '../../../../components/atoms/Icon';
import Spacing from '../../../../components/atoms/Spacing';

import { toast } from '../../../../components/molecules/Toaster';

import checkIcon from '../../../../img/sprites/round-check.svg';
import crossIcon from '../../../../img/sprites/round-cross.svg';

const CartToast = ({ onDismiss }) => {
  const dispatch = useDispatch();

  const handleCartRedirect = () => {
    onDismiss?.();
    dispatch({
      type: 'INTERSTITIAL_CART_REDIRECT',
      payload: '/cart'
    });
    dispatch({ type: 'CART' });
  };

  return (
    <div className="w-full" data-test-id="cart-toast">
      <Spacing size={15} position="b">
        <InlineGrid align="center" justify="space-between">
          <InlineGrid align="center">
            <Spacing size={1} position="r">
              <Icon
                glyph={checkIcon}
                width={18}
                height={18}
                fill={theme.extend.colors.green.primary}
              />
            </Spacing>
            <Heading size="xs" tag="h4">
              Added to bag
            </Heading>
          </InlineGrid>
          <span>
            <IconButton
              glyph={crossIcon}
              title="Close"
              size="small"
              kind="ghost"
              onClick={onDismiss}
            />
          </span>
        </InlineGrid>
      </Spacing>
      <CSSGrid
        template={['repeat(2, 1fr) / 1fr', '1fr / repeat(2, 1fr)']}
        gap={1}
        className="w-full"
      >
        <CSSGridItem row={{ span: [1] }} column={{ span: ['full', 1] }}>
          <Button
            kind="outline"
            fullWidth
            onClick={onDismiss}
            data-test-id="cart-toast-close-btn"
          >
            Keep shopping
          </Button>
        </CSSGridItem>
        <CSSGridItem row={{ span: [1] }} column={{ span: ['full', 1] }}>
          <Button
            fullWidth
            onClick={handleCartRedirect}
            data-test-id="cart-toast-open-btn"
          >
            Go to bag
          </Button>
        </CSSGridItem>
      </CSSGrid>
    </div>
  );
};

export default CartToast;

CartToast.propTypes = {
  onDismiss: PropTypes.func.isRequired
};

const createCartToast = () =>
  toast((t) => <CartToast onDismiss={() => toast.dismiss(t.id)} />, {
    id: 'cart-toast'
  });

export { createCartToast };
