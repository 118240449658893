import React from 'react';

import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';

import generalStyles from './style/general.module.css';

const Timeline = () => {
  return (
    <div className={generalStyles.container}>
      <div className={generalStyles.item}>
        <div className={generalStyles.icon}>
          <div className={generalStyles.glyph}>
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
            >
              <text
                color="#0B7C44"
                x="6"
                y="11"
                style={{ textAnchor: 'middle' }}
              >
                1
              </text>
            </svg>
          </div>
        </div>
        <div className={generalStyles.card}>
          <Spacing size={1} position="b">
            <Paragraph size="xs" className="uppercase">
              Create merch
            </Paragraph>
          </Spacing>
          <Paragraph size="xs">Make some products on Everpress and launch your campaign</Paragraph>
        </div>
      </div>

      <div className={generalStyles.item}>
        <div className={generalStyles.icon}>
          <div className={generalStyles.glyph}>
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
            >
              <text
                color="#0B7C44"
                x="6"
                y="11"
                style={{ textAnchor: 'middle' }}
              >
                2
              </text>
            </svg>
          </div>
        </div>
        <div className={generalStyles.card}>
          <Spacing size={1} position="b">
            <Paragraph size="xs" className="uppercase">
              Link your account
            </Paragraph>
          </Spacing>
          <Paragraph size="xs">
            Easily integrate your Everpress account and products with your ecommerce store
          </Paragraph>
        </div>
      </div>

      <div className={generalStyles.item}>
        <div className={generalStyles.icon}>
          <div className={generalStyles.glyph}>
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
            >
              <text
                color="#0B7C44"
                x="6"
                y="11"
                style={{ textAnchor: 'middle' }}
              >
                3
              </text>
            </svg>
          </div>
        </div>
        <div className={generalStyles.card}>
          <Spacing size={1} position="b">
            <Paragraph size="xs" className="uppercase">
              Track your sales
            </Paragraph>
          </Spacing>
          <Paragraph size="xs">
            Your sales will be automatically tracked in your Everpress dashboard
            and fulfilled to your customer
          </Paragraph>
        </div>
      </div>
      <div className={generalStyles.item}>
        <div className={generalStyles.icon}>
          <div className={generalStyles.glyph}>
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
            >
              <text
                color="#0B7C44"
                x="6"
                y="11"
                style={{ textAnchor: 'middle' }}
              >
                4
              </text>
            </svg>
          </div>
        </div>
        <div className={generalStyles.card}>
          <Spacing size={1} position="b">
            <Paragraph size="xs" className="uppercase">
              Profit payout
            </Paragraph>
          </Spacing>
          <Paragraph size="xs">
            We’ll pay you your share from sales on Everpress; you keep the
            profits from your off-platform orders
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
