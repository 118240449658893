import React from 'react';
import { connect } from 'react-redux';
import Link from 'redux-first-router-link';

import getOptimImgUrl from '../../../helpers/getOptimImgUrl';

import everpressLogo from '../../../img/everpress-logo.svg';
import everpressLogoWhite from '../../../img/everpress-logo-white.svg';
import generalStyles from './style/general.module.css';

const logoHeight = 40;

function HeaderLogo({ image, to }) {
  if (!image) {
    return null;
  }

  return (
    <h2 title="Everpress" className={generalStyles.logo}>
      {to ? (
        <Link to={to}>
          <img
            className={generalStyles.logo}
            src={image}
            alt="Everpress logo"
            width={logoHeight}
            height={logoHeight}
          />
        </Link>
      ) : (
        <img
          className={generalStyles.logoImg}
          src={image}
          alt="Store logo"
          height={logoHeight}
        />
      )}
    </h2>
  );
}

const mapStateToProps = ({ location, user, header, stores }, ownProps) => {
  const isLight = ownProps.isLight || header.theme.isLight;
  let image;

  if (header.theme.logo === 'default') {
    if (isLight) {
      image = everpressLogoWhite;
    } else {
      image = everpressLogo;
    }
  }

  if (header.theme.logo === 'store') {
    const logoUrl = stores.bySlug[location.payload.slug_2].logoUrl;
    image = logoUrl && getOptimImgUrl(logoUrl, 1, logoHeight * 2);
  }

  return {
    image,
    to: header.theme.logo === 'default' ? '/' : null
  };
};

export default connect(mapStateToProps)(HeaderLogo);
