import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getText } from '../../../../helpers/prismic';

import Heading from '../../../atoms/Heading';
import Spacing from '../../../atoms/Spacing';
import Paragraph from '../../../atoms/Paragraph';
import { CSSGrid, CSSGridItem } from '../../../atoms/Grid';

import ContentCard from './ContentCard';

import generalStyles from './style/general.module.css';

export default function ContentCardsGrid({
  title,
  subtitle,
  cards,
  noBorderRadius
}) {
  return (
    <>
      {!!title ? (
        <Spacing size="2" position="b">
          <Spacing size="05" position="b">
            <Heading size="m">{title}</Heading>
          </Spacing>
          <Paragraph size="xs" color="grey-dark">
            {subtitle}
          </Paragraph>
        </Spacing>
      ) : null}
      <CSSGrid
        gap={[15, 15, 15, 2]}
        nowrap
        align="start"
        columns={['repeat(3, 80vw)', 'repeat(3, 80vw)', 'repeat(3, 1fr)']}
        className={classNames('overflow-x-auto', generalStyles.removeScrollbar)}
      >
        {cards?.map((card) => (
          <CSSGridItem
            key={card.card_title}
            row={{ start: 1, end: 1 }}
            column={{ start: 'auto', end: 'auto' }}
          >
            <ContentCard
              title={card.card_title}
              copy={getText(card.copy)}
              slug={card.slug}
              image={card.image}
              noBorderRadius={noBorderRadius}
            />
          </CSSGridItem>
        ))}
      </CSSGrid>
    </>
  );
}

ContentCardsGrid.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      card_title: PropTypes.string,
      card_subtitle: PropTypes.string,
      cta: PropTypes.string,
      slug: PropTypes.string
    })
  ).isRequired,
  noBorderRadius: PropTypes.bool
};
