import PropTypes from 'prop-types';
import React from 'react';

import { Grid, GridItem } from '../../atoms/Grid';

import generalStyles from './style/general.module.css';

const IntegrationsForm = ({ imgProps, children }) => {
  return (
    <Grid
      className={generalStyles.grid}
      gap={[15, 15, 2]}
      row={['flex-col-reverse', 'flex-col-reverse', 'flex-row']}
      justify="center"
      align="start"
      nowrap
    >
      <GridItem className={generalStyles.gridItem} columnSize={[12, 12, 6]}>
        {children}
      </GridItem>
      <GridItem className={generalStyles.gridItem} columnSize={[12, 12, 6]}>
        <div>
          <img src={imgProps.src} alt={imgProps.alt} {...imgProps} />
        </div>
      </GridItem>
    </Grid>
  );
};

IntegrationsForm.propTypes = {
  imgProps: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string
  }).isRequired,
  children: PropTypes.node.isRequired
};

export default IntegrationsForm;
