const initialState = {
  items: []
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'GET_BLOG_POSTS_FULFILLED':
      return { ...state, items: action.payload };
    default:
      return state;
  }
};
