import React from 'react';

import Heading from '../../../components/atoms/Heading';
import Paragraph from '../../../components/atoms/Paragraph';
import { ButtonNew as Button } from '../../../components/atoms/Button';
import Icon from '../../../components/atoms/Icon';

import phoneDesktop from '../../../img/sprites/phone-desktop.svg';
import './style/phone-desktop-animation.css';

const MobileOverlay = () => {
  return (
    <div
      className="p-2 -mt-4 w-full h-screen flex flex-col justify-center content-center flex-wrap gap-3"
    >
      <div className="inline-flex justify-center">
        <Icon glyph={phoneDesktop} width={140} height={110} />
      </div>
      <Heading tag="h1" size={['xs', 'xs', 's']} center>
        Sorry it's a little cramped in here
      </Heading>
      <Paragraph size={['xxs', 'xxs', 'xs']} center>
        Our campaign builder is currently for larger screens only.
      </Paragraph>
      <div className="mt-3 inline-flex justify-center">
        <Button to="/">Home</Button>
      </div>
    </div>
  );
};

export default MobileOverlay;
