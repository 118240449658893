import React from 'react';
import { connect } from 'react-redux';

import { getText } from '../../../helpers/prismic';

import Thanks from '../../../components/molecules/Thanks';

function SubmitYourDesignThanks({ title, description, items }) {
  return <Thanks title={title} description={description} items={items} />;
}

const mapStateToProps = ({ pages }) => {
  const data = pages.submit_your_design.data || {};

  return {
    title: getText(data.thankyou_title),
    description: getText(data.thankyou_description),
    items:
      data.thankyou_items &&
      data.thankyou_items.map(item => ({
        title: getText(item.item_title),
        buttonLabel: getText(item.item_button_label),
        buttonSlug: item.item_button_slug,
        hiddenOnMobile: item.item_hidden_on_mobile,
        image: item.image.url
      }))
  };
};

export default connect(mapStateToProps)(SubmitYourDesignThanks);
