import React from 'react';

import Spacing from '../../../../components/atoms/Spacing';
import Paragraph from '../../../../components/atoms/Paragraph';

import Main from '../../Main';
import Title from '../../Title';
import Card from '../../Card';

import givingBackImage from '../../../../img/pages/people/giving-back.jpg';

const GivingBack = () => {
  return (
    <Main title="Giving Back" image={givingBackImage} isReversedGrid>
      <Spacing size={7} position="b">
        <Title titleCopy="WHERE WE WERE" />
        <Paragraph className="mb-3">
          We’ve been committed to giving back since we started, which is why we
          launched 50:50 in 2016. Our annual drive to raise funds and awareness
          for causes that need it, we ask 50 designers to create 50 T-shirts
          around one theme, with the profits going to one organisation or
          charity; past years have seen us work with Amnesty International and
          Justice4Grenfell.
        </Paragraph>
        <Paragraph className="mb-3">
          We’ve seen a lot of charity and fundraiser T-shirts over the past four
          years, and since the start we’ve also informally matched donations and
          helped platform fundraisers too. But we realised we needed to make our
          support more tangible.
        </Paragraph>
      </Spacing>
      <Spacing size={7} position="b">
        <Title titleCopy="What we've done" />
        <Paragraph className="mb-3">
          Between January 2020 and December 2020 we donated:
        </Paragraph>
        <Card title="£87,000 to charities" subtitle="In 2020" size="s" />
        <Paragraph className="mb-3 mt-2">
          These donations went towards BLM causes, the NHS, support after the
          Australian wildfires and aid following the Beirut explosion.
        </Paragraph>
        <Paragraph className="mb-3">
          In 2021 we created our Community Pot. Refreshing every quarter, this
          is a special pot of funds earmarked to help us support urgent
          campaigns as they arise, by matching donations raised, platforming
          causes and more.
        </Paragraph>
        <Paragraph className="mb-3">
          In 2021, we launched Balance. A project and collection conceptualised,
          produced and designed entirely by trans creatives from London’s tight
          knit community. The capsule collection and the accompanying
          video-series, the Mya Show, was launched to support, platform and
          fundraise for trans causes.
        </Paragraph>
      </Spacing>
      <Spacing size={7} position="b">
        <Title titleCopy="What we’re doing next" />
        <Paragraph className="mb-3 mt-2">
          And we won't stop there, each year we intend to increase this donation
          to make sure continue to give back as we grow.
        </Paragraph>
      </Spacing>
    </Main>
  );
};

export default GivingBack;
