import { useState, useEffect, useMemo } from 'react';
import differenceInSeconds from 'date-fns/difference_in_seconds';

const ONE_HOUR = 60 * 60;
const ONE_MINUTE = 60;

const useCountdown = (deadline = new Date()) => {
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const diffInSeconds = differenceInSeconds(deadline, currentTime);

  const getCountdown = () => {
    if (diffInSeconds <= 1) {
      return {
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }

    const hours = Math.floor(diffInSeconds / ONE_HOUR);
    const minutes = Math.floor((diffInSeconds - hours * ONE_HOUR) / ONE_MINUTE);
    const seconds = diffInSeconds - hours * ONE_HOUR - minutes * ONE_MINUTE;

    return {
      hours,
      minutes,
      seconds
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const countdown = useMemo(getCountdown, [currentTime]);

  useEffect(() => {
    const secondsInterval = setInterval(() => {
      const now = new Date().getTime();
      setCurrentTime(now);
    }, 1000);

    return () => clearInterval(secondsInterval);
  }, []);

  return countdown;
};

export default useCountdown;
