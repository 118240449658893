import React from 'react';
import * as prismicH from '@prismicio/helpers';

const richTextSerializer = (richTextData) => {
  return richTextData?.map((item) => (
    <>
      {prismicH.asText([item])}
      <br />
    </>
  ));
};

export default richTextSerializer;
