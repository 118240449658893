import { validateYupSchema, yupToFormErrors } from 'formik';
import * as yup from 'yup';

const hasFieldsError = (error) => {
  return (
    error.children &&
    Object.keys(error.children).find((key) => error.children[key].errors)
  );
};

const getFieldsError = (error) => {
  return (
    error.children &&
    Object.keys(error.children).reduce((acc, key) => {
      if (error.children[key].errors) {
        return { ...acc, [key]: error.children[key].errors.join('\n') };
      }
      return acc;
    }, {})
  );
};

export const handleFormError = (error, actions) => {
  if (error.response && hasFieldsError(error.response.data)) {
    actions.setErrors(getFieldsError(error.response.data));
    actions.setStatus({
      error: Object.values(getFieldsError(error.response.data)).join('\n')
    });
  } else {
    actions.setStatus({ error: error.message });
  }
};

export const handleFormSuccess = (message, actions) => {
  actions.setStatus({ success: message });
};

export const basicValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required()
});

export const validateFormWithSchema = (validationSchema, values) => {
  try {
    validateYupSchema(values, validationSchema, true);
  } catch (err) {
    return yupToFormErrors(err);
  }
  return {};
};

export const getFirstErrorPath = (obj, path = '') => {
  if (typeof obj === 'object') {
    path += path ? `.${Object.keys(obj)[0]}` : Object.keys(obj)[0];
    return getFirstErrorPath(obj[Object.keys(obj)[0]], path);
  } else {
    return path;
  }
};
