import React from 'react';
import * as Sentry from "@sentry/react";

import { sendAmplitudeEvent } from '../../services/amplitude';

import NotFound from '../../pages/NotFound';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    this.onButtonClick = this.onButtonClick.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });
    Sentry.captureException(error, { extra: errorInfo });
    sendAmplitudeEvent('Error fallback UI');
  }

  onButtonClick() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      // Silently (visually) fail if an error happen
      return <NotFound isError onButtonClick={this.onButtonClick} />;
    }
    return this.props.children;
  }
}
