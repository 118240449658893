import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DisclosureContext from './DisclosureContext';
import DisclosureButton from './DisclosureButton';
import DisclosurePanel from './DisclosurePanel';

const Disclosure = ({
  id,
  defaultOpen = false,
  open: openProp,
  onChange,
  children
}) => {
  const wasControlled = openProp != null;
  const { current: isControlled } = useRef(wasControlled);

  const [open, setOpen] = useState(isControlled ? openProp : false);

  const disclosureId = (id ? String(id) : undefined) || 'disclosure';
  const panelId = `${disclosureId}-panel`;

  useEffect(() => {
    if (!isControlled) {
      setOpen(defaultOpen);
    }
    // Explicitly only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isControlled && openProp !== open) {
    // If component is controlled, sync internal state with the controlled state
    setOpen(openProp);
  }

  const onSelect = () => {
    onChange?.();
    if (!isControlled) {
      setOpen((open) => !open);
    }
  };

  const context = {
    disclosureId,
    panelId,
    open,
    onSelect
  };

  return (
    <DisclosureContext.Provider value={context}>
      {children}
    </DisclosureContext.Provider>
  );
};

Disclosure.propTypes = {
  id: PropTypes.string,
  defaultOpen: PropTypes.bool,
  open: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired
};

export { Disclosure, DisclosureButton, DisclosurePanel };
