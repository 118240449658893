import produce from 'immer';
import merge from 'lodash/merge';

const initialState = {
  theme: {
    color: 'light',
    isVisible: true,
    isMinimal: false,
    isCurrencyDropdownHidden: false,
    isMinimalCurrencyDropdownHidden: false,
    isMatchingCurrencyDropdownColor: false
  }
};

export default (state = initialState, action) =>
  produce(state, (newState) => {
    // If it's a route change
    if (action.meta && action.meta.location) {
      // Reset to initial state
      merge(newState, initialState);

      if (action.type === 'ABOUT') {
        newState.theme.color = 'dark';
      }

      if (action.type === 'CHECKOUT') {
        newState.theme.isVisible = false;
      }

      if (
        action.type === 'CAMPAIGN_OR_STORE' ||
        action.type === 'ORDER' ||
        action.type.match(/CAMPAIGN_BUILDER/)
      ) {
        newState.theme.isMinimal = true;
      }

      if (action.type === 'ORDER' || action.type.match(/CAMPAIGN_BUILDER/)) {
        newState.theme.isMinimalCurrencyDropdownHidden = true;
      }

      if (
        ['SUSTAINABILITY', 'BCORP', 'SAVE_OUR_SPACES'].includes(action.type)
      ) {
        newState.theme.isCurrencyDropdownHidden = true;
      }

      return newState;
    }

    return state;
  });
