import React from 'react';
import PropTypes from 'prop-types';

import Spacing from '../../Spacing';

import generalStyles from './style/general.module.css';

export default function ListItem({ children }) {
  return (
    <li className={generalStyles.listItem}>
      <Spacing size={1}>{children}</Spacing>
    </li>
  );
}

ListItem.propTypes = {
  children: PropTypes.node.isRequired
};
