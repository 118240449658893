import React from 'react';

import AltCta from '../AltCta';
import Benefit from './Benefit';
import CaseStudies from './CaseStudies';
import Features from './Features';
import HowItWorks from './HowItWorks';
import Numbers from './Numbers';
import PreviousNext from './PreviousNext';
import Quote from './Quote';
import Intro from './Intro';
import TextBlock from './TextBlock';
import UsedByMission from './UsedByMission';
import Hero from './Hero';
import ImageTextBlock from './ImageTextBlock';
import SimpleTextBlock from './SimpleTextBlock';
import Cta from './Cta';
import { ContentCardsGrid as ContentCards } from './ContentCards';
import { FeatureCardGrid as FeatureCards } from './FeatureCards';
import ExtraImages from './ExtraImages';

import { getText, getRichText } from '../../../helpers/prismic';
import htmlParser from '../../../helpers/htmlParser';

const Slices = ({ slices = [], urlBuilder = () => {} }) => {
  return (
    <div className="slices">
      {slices.map((slice) => {
        switch (slice.slice_type) {
          case 'quote':
            return (
              <Quote
                key={slice.id}
                isReverse={slice.primary.direction === 'reverse'}
                isLargeCenter={slice.primary.kind === 'largeCenter'}
                quote={getText(slice.primary.quotation)}
                author={getText(slice.primary.author)}
                subtitle={getText(slice.primary.subtitle)}
                image={{
                  url: slice.primary.thumbnail.url,
                  alt: slice.primary.thumbnail.alt
                }}
              />
            );
          case 'numbers':
            return (
              <Numbers
                key={slice.id}
                title={getText(slice.primary.numbers_title)}
                numbers={slice.items.map((item) => ({
                  digits: getText(item.number_digits),
                  label: getText(item.number_label)
                }))}
              />
            );
          case 'call_to_action':
            return (
              <AltCta
                key={slice.id}
                title={getText(slice.primary.cta_title)}
                copy={getText(slice.primary.cta_copy)}
                buttonLabel={getText(slice.primary.cta_button_label)}
                buttonSlug={slice.primary.cta_button_slug}
                hasBorderTop={slice.primary.top_border === 'yes'}
              />
            );
          case 'text_block':
            return (
              <TextBlock
                key={slice.id}
                label={getText(slice.primary.block_label)}
                title={getText(slice.primary.block_title)}
                copy={htmlParser(getRichText(slice.primary.block_copy))}
              />
            );
          case 'intro':
            return (
              <Intro
                key={slice.id}
                title={getText(slice.primary.title)}
                copy={htmlParser(getRichText(slice.primary.copy))}
              />
            );
          case 'previous_next':
            if (slice.primary.previous.data && slice.primary.next.data) {
              return (
                <PreviousNext
                  key={slice.id}
                  prev={{
                    to: urlBuilder(slice.primary.previous),
                    title: getText(slice.primary.previous.data.title),
                    headline: getText(slice.primary.previous.data.headline),
                    image: {
                      url: slice.primary.previous.data.image.small.url,
                      alt: slice.primary.previous.data.image.small.alt
                    }
                  }}
                  next={{
                    to: urlBuilder(slice.primary.next),
                    title: getText(slice.primary.next.data.title),
                    headline: getText(slice.primary.next.data.headline),
                    image: {
                      url: slice.primary.next.data.image.small.url,
                      alt: slice.primary.next.data.image.small.alt
                    }
                  }}
                />
              );
            } else {
              return null;
            }
          case 'benefit':
            return (
              <Benefit
                key={slice.id}
                isReverse={slice.primary.benefit_direction === 'reverse'}
                label={getText(slice.primary.benefit_label)}
                title={getText(slice.primary.benefit_title)}
                copy={htmlParser(getRichText(slice.primary.benefit_copy))}
                buttonLabel={getText(slice.primary.benefit_button_label)}
                buttonSlug={slice.primary.benefit_button_slug}
                image={slice.primary.benefit_image}
              />
            );
          case 'case_studies':
            return (
              <CaseStudies
                key={slice.id}
                title={getText(slice.primary.case_studies_title)}
                description={getText(slice.primary.case_studies_description)}
                caseStudies={slice.items.map((item) => ({
                  title: getText(item.case_study.data.title),
                  category: item.case_study.data.category.slug,
                  slug: item.case_study.slug,
                  image: {
                    url: item.case_study.data.image.medium.url
                  }
                }))}
              />
            );
          case 'how_it_works':
            return (
              <HowItWorks
                key={slice.id}
                title={getText(slice.primary.title)}
                copy={getText(slice.primary.copy)}
                steps={slice.items.map((item) => ({
                  index: getText(item.index),
                  title: getText(item.title),
                  description: getText(item.description),
                  image: {
                    url: item.image.url,
                    alt: item.image.alt,
                    width: item.image.dimensions.width,
                    height: item.image.dimensions.height
                  }
                }))}
              />
            );
          case 'features':
            return (
              <Features
                key={slice.id}
                title={getText(slice.primary.title)}
                features={slice.items.map((item) => ({
                  icon: item.icon.url,
                  title: getText(item.title),
                  description: htmlParser(getRichText(item.description))
                }))}
              />
            );
          case 'used_by_and_mission':
            return (
              <UsedByMission
                key={slice.id}
                usedByTitle={htmlParser(
                  getRichText(slice.primary.used_by_title)
                )}
                usedByLogos={slice.items.map((item) => ({
                  url: item.used_by_logo.url,
                  alt: item.used_by_logo.alt,
                  width: item.used_by_logo.dimensions.width
                }))}
                missionTitle={getText(slice.primary.mission_title)}
                missionHugeImage={{
                  url: slice.primary.mission_huge_thumbnail.url,
                  width: slice.primary.mission_huge_thumbnail.dimensions.width,
                  height: slice.primary.mission_huge_thumbnail.dimensions.height
                }}
                missionFeatureTitle={getText(
                  slice.primary.mission_feature_title
                )}
                missionFeatureImage={{
                  url: slice.primary.mission_feature_thumbnail.url,
                  width:
                    slice.primary.mission_feature_thumbnail.dimensions.width,
                  height:
                    slice.primary.mission_feature_thumbnail.dimensions.height
                }}
                missionCTA1={{
                  title: getText(slice.primary.mission_cta_1_title),
                  copy: getText(slice.primary.mission_cta_1_description),
                  to: slice.primary.mission_cta_1_link
                }}
                missionCTA2={{
                  title: getText(slice.primary.mission_cta_2_title),
                  copy: getText(slice.primary.mission_cta_2_description),
                  to: slice.primary.mission_cta_2_link
                }}
              />
            );
          case 'hero':
            return (
              <Hero
                key={slice.id}
                image={slice.primary.image}
                imagePosition={slice.primary.image_position}
                title={slice.primary.title}
                copy={getText(slice.primary.copy)}
                buttonLabel={slice.primary.cta_button_label}
                buttonSlug={slice.primary.cta_button_slug}
              />
            );
          case 'image_text_block':
            return (
              <ImageTextBlock
                key={slice.id}
                image={slice.primary.image}
                imagePosition={slice.primary.image_position}
                title={slice.primary.title}
                subtitle={slice.primary.subtitle}
                copy={htmlParser(getRichText(slice.primary.copy))}
              />
            );
          case 'simple_text_block':
            return (
              <SimpleTextBlock
                key={slice.id}
                title={slice.primary.title}
                subtitle={slice.primary.subtitle}
                copy={getText(slice.primary.copy)}
              />
            );
          case 'cta':
            return (
              <Cta
                key={slice.id}
                title={slice.primary.title}
                copy={getText(slice.primary.copy)}
                button1={{
                  label: slice.primary.button1_label,
                  slug: slice.primary.button1_slug
                }}
                button2={{
                  label: slice.primary.button2_label,
                  slug: slice.primary.button2_slug
                }}
              />
            );
          case 'content_cards':
            return (
              <ContentCards
                key={slice.id}
                title={slice.primary.title}
                subtitle={slice.primary.subtitle}
                cards={slice.items}
              />
            );
          case 'feature_cards':
            return (
              <FeatureCards
                key={slice.id}
                title={slice.primary.title}
                subtitle={slice.primary.subtitle}
                cards={slice.items}
              />
            );
          case 'extra_images':
            return (
              <ExtraImages
                key={slice.id}
                images={slice.items}
              />
            );
          default:
            return slice.slice_type;
        }
      })}
    </div>
  );
};

export default Slices;
