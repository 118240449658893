import React from 'react';

import { Grid, GridItem } from '../../../atoms/Grid';
import Heading from '../../../atoms/Heading';
import Image from '../../../atoms/Image';
import MaxWidth from '../../../atoms/MaxWidth';
import PageWrap from '../../../atoms/PageWrap';
import Paragraph from '../../../atoms/Paragraph';
import Spacing from '../../../atoms/Spacing';

import generalStyles from './style/general.module.css';

const Quote = ({
  image,
  isReverse,
  isLargeCenter,
  quote,
  author,
  subtitle
}) => {
  return (
    <PageWrap>
      <Spacing size={[2, 4, 6]} type="padding">
        {isLargeCenter ? (
          <MaxWidth center value={992}>
            <Heading
              className={generalStyles.quote_apostrophe}
              tag="div"
              center
              size={['m', 'l', 'xl', 'xxl']}
            >
              “
            </Heading>
            <Spacing size={[1, 2, 4]} position="b" type="padding">
              <Heading tag="div" center size={['m', 'l', 'xl']}>
                {quote}
              </Heading>
            </Spacing>
            <Heading tag="div" center size="xs">
              {author}
            </Heading>
            <Paragraph center size="xxxs">
              {subtitle}
            </Paragraph>
          </MaxWidth>
        ) : (
          <MaxWidth value={876} center>
            <Grid
              gap={2}
              align="center"
              row={
                isReverse
                  ? ['flex-col-reverse', 'flex-row-reverse']
                  : ['flex-row']
              }
            >
              <GridItem columnSize={[12, 6]}>
                <Spacing size={[2, 0]} position={isReverse ? 't' : 'b'}>
                  <Image
                    className={generalStyles.quoteImg}
                    src={image.url}
                    alt={image.alt}
                    width="415"
                    height="415"
                  />
                </Spacing>
              </GridItem>
              <GridItem columnSize={[12, 6]}>
                <Heading
                  className={generalStyles.quote_apostrophe}
                  tag="h2"
                  size={['m', 'l', 'xl', 'xxl']}
                >
                  “
                </Heading>
                <Spacing size={[2, 3]} position="b">
                  <Heading tag="h4" size={['m', 'l']}>
                    {quote}
                  </Heading>
                </Spacing>
                <Heading size="xs" tag="h5">
                  {author}
                </Heading>
                <Paragraph size="xxxs">{subtitle}</Paragraph>
              </GridItem>
            </Grid>
          </MaxWidth>
        )}
      </Spacing>
    </PageWrap>
  );
};

export default Quote;
