import React from 'react';
import classNames from 'classnames/bind';

import generalStyles from './style/general.module.css';
import directionStyles from './style/direction.module.css';

const cx = classNames.bind({ ...generalStyles, ...directionStyles });

export default function Badge({ children, direction }) {
  return <span className={cx(generalStyles.badge, direction)}>{children}</span>;
}

Badge.defaultProps = {
  direction: 'left'
};
