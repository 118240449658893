import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Heading from '../../../../components/atoms/Heading';
import Paragraph from '../../../../components/atoms/Paragraph';

import generalStyles from './style/general.module.css';

const SimpleTextBlock = ({ title, subtitle, copy }) => {
  return (
    <div className={generalStyles.container}>
      {isEmpty(subtitle) ? null : (
        <Paragraph className="uppercase" size="xs" tag="span">
          {subtitle}
        </Paragraph>
      )}
      <Heading size={['m', 'm', 'l']} tag="h2">
        {title}
      </Heading>
      <div className={generalStyles.copy}>
        <Paragraph size="xs">{copy}</Paragraph>
      </div>
    </div>
  );
};

SimpleTextBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  copy: PropTypes.string.isRequired
};

export default SimpleTextBlock;
