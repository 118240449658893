import merge from 'lodash/merge';
import pick from 'lodash/pick';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';

import { garmentTypes } from '../components/molecules/MegaMenu/garmentTypes';

import config from '../config';

import { roundUpPrices } from '../helpers/campaign';
import { getSearchHitsProductPrices } from '../helpers/shop';

const defaultOptions = {
  offset: 0,
  limit: config.shop.campaigns.limit,
  direction: 'ASC',
  tag: config.shop.defaultTag.value,
  sort: config.shop.sortings.find((item) => item.default).value,
  productCategories: [],
  productFits: [],
  productPrintPositions: [],
  productSizes: [],
  productColours: []
  /**
   * NOTE: including onlyCount: Boolean will cause the API to return a product count
   * regardless of the value, so we don't include it in default props
   */
};

export const getShopItems = (
  options,
  reducerOptions = { mergeExisting: true }
) => (dispatch, _, { baseApi }) => {
  const params = merge({}, defaultOptions, options);
  const { tag, printOnDemand, isGarmentPage } = params;

  return dispatch({
    type: 'GET_SHOP_ITEMS',
    payload: baseApi.get('/shop/v2', { params }),
    meta: {
      tag,
      printOnDemand,
      isGarmentPage,
      reducerOptions
    }
  });
};

export const getMoreShopItems = (options) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const params = merge({}, defaultOptions, options);
  const offset = params.isGarmentPage
    ? getState().shop.list.byTag[config.shop.garmentPageTag.value].items.length
    : params.printOnDemand
    ? getState().shop.list.byTag[config.shop.podTag.value].items.length
    : params.tag
    ? getState().shop.list.byTag[params.tag].items.length
    : getState().shop.list.items.length;

  return dispatch({
    type: 'GET_MORE_SHOP_ITEMS',
    payload: baseApi.get('/shop/v2', {
      params: {
        ...params,
        offset
      }
    }),
    meta: {
      tag: params.tag,
      pageNumber: offset / params.limit,
      printOnDemand: params.printOnDemand,
      isGarmentPage: params.isGarmentPage
    }
  });
};

export const getShopCollections = () => (dispatch, _, { baseApi }) => {
  return dispatch({
    type: 'GET_SHOP_COLLECTIONS',
    payload: baseApi.get('/shop/collections')
  });
};

export const getShopAvailableItemsCount = (options) => (
  dispatch,
  getState,
  { baseApi }
) => {
  const location = getState().location.payload;
  const isGarmentPage = some(garmentTypes, ['slug', location.tag]);
  const tag = isGarmentPage ? config.shop.defaultTag.value : location.tag;

  const params = merge(
    {},
    pick(defaultOptions, [
      'tag',
      'productCategories',
      'productFits',
      'productPrintPositions',
      'productSizes',
      'productColours'
    ]),
    {
      ...options,
      tag,
      onlyCount: true
    }
  );

  return dispatch({
    type: 'GET_SHOP_AVAILABLE_ITEMS_COUNT',
    payload: baseApi.get('/shop/v2', { params }).then((count) => count.total)
  });
};

export const getShopSearchHitsPrices = (hits = []) => (
  dispatch,
  _,
  { baseApi }
) => {
  const hitsPrices = getSearchHitsProductPrices(hits);

  dispatch({
    type: 'GET_SHOP_SEARCH_RESULTS_PRICES',
    async payload() {
      const convertedHitsPrices = await baseApi.post(
        '/currencies/convert',
        hitsPrices
      );

      const roundedConvertedHitsPrices = convertedHitsPrices.map(
        ({ id, prices, ...priceData }) => ({
          ...priceData,
          objectID: id,
          prices: roundUpPrices(prices)
        })
      );

      return roundedConvertedHitsPrices;
    }
  });
};

export const saveShopActiveFilters = (filters) => (dispatch) => {
  dispatch({
    type: 'SAVE_SHOP_ACTIVE_FILTERS',
    payload: filters
  });
};

export const saveScrollPosition = (scroll) => (dispatch) => {
  dispatch({
    type: 'SAVE_SCROLL_POSITION',
    payload: scroll
  });
};

export const setShopSearchQuery = (searchQuery) => (dispatch) => {
  dispatch({
    type: 'SET_SHOP_SEARCH_QUERY',
    payload: searchQuery
  });
};

export const setShopSearchResults = (
  searchResults = {},
  options = { generatePrices: false }
) => (dispatch) => {
  dispatch({
    type: 'SET_SHOP_SEARCH_RESULTS',
    payload: searchResults
  });

  if (options.generatePrices) {
    const { hits } = searchResults;

    if (isEmpty(hits)) return;

    dispatch(getShopSearchHitsPrices(hits));
  }
};

export const getGiftCards = () => (dispatch, _, { baseApi }) => {
  return dispatch({
    type: 'GET_GIFT_CARDS',
    payload: baseApi.get('/v1/gift-cards')
  });
};
