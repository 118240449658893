import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Link from '../../../atoms/Link';
import Heading from '../../../atoms/Heading';
import Spacing from '../../../atoms/Spacing';
import Paragraph from '../../../atoms/Paragraph';
import { Grid, GridItem } from '../../../atoms/Grid';
import { ButtonNew as Button } from '../../../atoms/Button';

import WrapIf from '../../../utils/WrapIf';

import generalStyles from './style/general.module.css';

export default function FeatureCard({
  title,
  subtitle,
  additionalTitle,
  image,
  slug,
  cta,
  fullWidth,
  noBorderRadius
}) {
  const dispatch = useDispatch();
  const imgSrc = fullWidth ? image.SingleCard.url : image.url;

  const handleCardClick = () => {
    dispatch({ type: 'HERO_FEATURE_CARD_CLICKED', payload: { slug } });
  };

  return (
    <WrapIf
      condition={!!slug}
      wrapper={(children) => <Link to={slug}>{children}</Link>}
    >
      <div
        className={classNames(generalStyles.featureCard, {
          [generalStyles.fullWidth]: fullWidth,
          [generalStyles.defaultWidth]: !fullWidth,
          'rounded-md': !noBorderRadius
        })}
        style={{ backgroundImage: `url("${imgSrc}")` }}
        onClick={handleCardClick}
      >
        <div
          className={classNames(generalStyles.gradientBackground, {
            'rounded-md': !noBorderRadius
          })}
        >
          <div className={generalStyles.copy}>
            <Spacing size={[2, 2, 2, 2, 3]} position="all" type="padding">
              <Grid gap={0} align="end" justify="space-between">
                {!!title || !!subtitle ? (
                  <GridItem columnSize={[12, 12, 12, 6, 8]}>
                    <Spacing size={15} position="b">
                      <Heading size="l" tag="h3" color="white">
                        {title}
                      </Heading>
                    </Spacing>
                    <Spacing
                      size={[15, 15, 15, additionalTitle ? 15 : 0]}
                      position="b"
                    >
                      <Paragraph size="xxs" color="white">
                        {subtitle}
                      </Paragraph>
                    </Spacing>
                    <Spacing size={[15, 15, 15, 0]} position="b">
                      <Paragraph size="xxs" color="white" className="font-bold">
                        {additionalTitle}
                      </Paragraph>
                    </Spacing>
                  </GridItem>
                ) : null}
                {cta ? (
                  <GridItem
                    columnSize={['12', '12', '12', 'auto']}
                    className={classNames({
                      [generalStyles.ctaGridItem]: !!title || !!subtitle
                    })}
                  >
                    <Button
                      kind="solid"
                      color="white"
                      className={generalStyles.ctaButton}
                    >
                      {cta}
                    </Button>
                  </GridItem>
                ) : null}
              </Grid>
            </Spacing>
          </div>
        </div>
      </div>
    </WrapIf>
  );
}

FeatureCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  additional_title: PropTypes.string,
  cta: PropTypes.string,
  slug: PropTypes.string,
  img: PropTypes.shape({
    url: PropTypes.string
  })
};
