import React from 'react';

import Link from '../../atoms/Link';
import Heading from '../../atoms/Heading';
import { ButtonNew } from '../../atoms/Button';
import Paragraph from '../../atoms/Paragraph';
import Spacing from '../../atoms/Spacing';
import PageWrap from '../../atoms/PageWrap';
import Icon from '../../atoms/Icon';
import { Grid, GridItem, InlineGrid } from '../../atoms/Grid';

import generalStyles from './style/general.module.css';

import everpressLogoWhite from '../../../img/everpress-logo-white.svg';
import arrowLeft from '../../../img/sprites/arrow-left.svg';

export default function PreviewHeader({
  campaignId,
  isEditable,
  onLaunchClick,
  approvalFlag
}) {
  return (
    <div className={generalStyles.container}>
      <PageWrap>
        <Spacing size={2} type="padding">
          <Grid gap={0}>
            <GridItem columnSize={[12, 12, 7, 8]}>
              <InlineGrid justify="start">
                <Link to="/">
                  <img
                    className={generalStyles.logo}
                    src={everpressLogoWhite}
                    alt="logo"
                    width="40"
                    height="35"
                  />
                </Link>
                <Spacing position="l" size={[1, 2, 2, 5]}>
                  <Heading tag="h2" color="white" size={['xxs']}>
                    Preview mode
                  </Heading>
                  <Spacing position="t" size={'05'}>
                    <Paragraph color="white" size={['xxxs']}>
                      This is how your campaign will look when it's launched.
                      This URL is sharable, feel free to get feedbacks from
                      friends. You can also request a sample by selecting a size
                      below and completing an order. Please note: all samples
                      are digitally printed.
                    </Paragraph>
                  </Spacing>
                </Spacing>
              </InlineGrid>
            </GridItem>
            {isEditable && (
              <GridItem columnSize={[12, 12, 5, 4]}>
                <Spacing position="t" size={[2, 2, 0]}>
                  <InlineGrid>
                    <ButtonNew
                      to={`/campaign/edit/${campaignId}`}
                      kind="outline"
                      color="white"
                      className="ml-auto"
                      isExternal
                    >
                      <div className="flex items-center">
                        <Spacing position="r" size={1}>
                          <Icon glyph={arrowLeft} width="14" height="12" />
                        </Spacing>
                        Edit
                      </div>
                    </ButtonNew>
                    <ButtonNew
                      onClick={onLaunchClick}
                      color="white"
                      className="ml-1"
                    >
                      {approvalFlag ? 'Submit campaign' : 'Launch campaign'}
                    </ButtonNew>
                  </InlineGrid>
                </Spacing>
              </GridItem>
            )}
          </Grid>
        </Spacing>
      </PageWrap>
    </div>
  );
}

PreviewHeader.propTypes = {};

PreviewHeader.defaultTypes = {};
