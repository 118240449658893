import React, { useEffect } from 'react';

import Paragraph from '../../../../components/atoms/Paragraph';

import Marquee from '../../../../components/utils/Marquee';

import generalStyles from './style/general.module.css';

const MarqueeSection = () => {
    useEffect(() => {
      Marquee.init({
        selector: 'marquee'
      });
    }, []);

  return (
    <div className="border-t border-b border-grey-lighter">
      <div
        className={`${generalStyles.marquee} marquee`}
        data-speed="0.35"
        data-reverse={false}
        data-pauseable={false}
      >
        <Paragraph className="uppercase" size="xxs">
          <span className="px-2">&#9679;</span>
          <span>A constant work in progress</span>
        </Paragraph>
      </div>
    </div>
  );
}

export default MarqueeSection