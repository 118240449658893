import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import * as userActions from '../../actions/user';

import Page from '../../components/utils/Page';
import MaxWidth from '../../components/atoms/MaxWidth';
import PageWrap from '../../components/atoms/PageWrap';
import LiveChat from '../../components/atoms/LiveChat';

import SignInForm from '../../components/molecules/AuthenticationContainer/SignInForm';
import Spacing from '../../components/atoms/Spacing';

import everpressLogo from '../../img/everpress-logo.svg';

const Login = () => {
  const dispatch = useDispatch();
  const [existingUserDetails, setExistingUserDetailsChange] = useState({
    email: '',
    password: ''
  });

  const handleSignIn = async ({ email, password }) => {
    await dispatch(userActions.lookupUser(email));
    await dispatch(userActions.login(email, password));
  };

  const handleUserStageChange = (stage) => {
    switch (stage) {
      case 'signUp':
        return dispatch({
          type: 'REGISTER'
        });
      case 'resetPassword':
        return dispatch({
          type: 'FORGOTTEN_PASSWORD'
        });
      default:
        return null;
    }
  };

  return (
    <Page title="Login">
      <PageWrap className="text-center">
        <MaxWidth center value={415}>
          <Spacing size={15} position="y">
            <div>
              <img
                src={everpressLogo}
                alt="Everpress logo"
                width="40"
                height="35"
                className="m-auto"
              />
            </div>
            <Spacing size={15} position="y">
              <SignInForm
                onSignIn={handleSignIn}
                onUserStageChange={handleUserStageChange}
                existingUserDetails={existingUserDetails}
                onExistingUserDetailsChange={setExistingUserDetailsChange}
              />
            </Spacing>
          </Spacing>
        </MaxWidth>
      </PageWrap>
      <LiveChat />
    </Page>
  );
};

export default connect(null, { ...userActions })(Login);
