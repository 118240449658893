import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { fontSizeMap, fontColorMap } from '../../../helpers/themeMap';
import propToClassName from '../../../helpers/propToClassName';

Paragraph.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(Object.keys(fontSizeMap))),
    PropTypes.oneOf(Object.keys(fontSizeMap))
  ]),
  color: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(Object.keys(fontColorMap))),
    PropTypes.oneOf(Object.keys(fontColorMap))
  ]),
  inline: PropTypes.bool,
  tag: PropTypes.string
};

export default function Paragraph({
  size,
  children,
  tag,
  color,
  inline,
  center,
  className,
  ...props
}) {
  const sizeClassName = propToClassName(size, fontSizeMap);
  const colorClassName = propToClassName(color, fontColorMap);
  // Only apply certain classes if they are different from default, to save bytes
  const paragraphClassName = classNames(className, {
    [sizeClassName]: size !== Paragraph.defaultProps.size,
    [colorClassName]: color !== Paragraph.defaultProps.color,
    'font-normal': tag !== Paragraph.defaultProps.tag,
    'text-center': center,
    inline: inline,
    'pr-1': inline
  });
  const Tag = tag;

  return (
    <Tag className={paragraphClassName} {...props}>
      {children}
    </Tag>
  );
}

Paragraph.defaultProps = {
  tag: 'p',
  size: 'base',
  color: 'black',
  inline: false,
  center: false
};
