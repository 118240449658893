import { isLocationAction } from 'redux-first-router';
import * as Sentry from "@sentry/react";
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import isPast from 'date-fns/is_past';
import differenceInDays from 'date-fns/difference_in_days';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import sumBy from 'lodash/sumBy';

import fbq from './facebookPixel';

import {
  regenerateAmplitudeDeviceId,
  sendAmplitudeEvent,
  setAmplitudeUserId,
  setAmplitudeUserProperties
} from './amplitude';

import {
  getCampaignById,
  getCampaignBySlug,
  getCampaignItem,
  getCampaignTagsByReference,
  getCampaignCollections,
  getCampaignsCollections,
  getCartCampaigns,
  getCampaignsTagsByReference
} from '../helpers/selectors/campaigns';

import { getCampaignProductImage } from '../helpers/campaign';

function getTransactionProducts(items, campaigns, user) {
  return items.map((cartItem) => {
    const { colourId, campaignProductId } = cartItem;
    const campaign = getCampaignById(cartItem.campaignId, campaigns);
    const item = getCampaignItem(cartItem.campaignProductId, campaign);
    const colour = item.colours.find(
      (colour) => colour.colourId === cartItem.colourId
    );
    const endDate = parse(campaign.endAt);
    const launchDate = parse(campaign.launchedAt);
    const productImage = getCampaignProductImage(campaign, {
      colourId,
      campaignProductId
    });
    const collections = getCampaignsCollections([campaign]);
    const creatorUserId = campaign.user?.userId;

    return {
      campaignId: cartItem.campaignId,
      campaignLength: campaign.length,
      brand: item.product.brand,
      minutesFromStart: campaign.draft
        ? 1
        : differenceInMinutes(new Date(), launchDate),
      minutesRemaining: campaign.draft
        ? 1
        : differenceInMinutes(endDate, new Date()),
      campaignLaunchDate: campaign.draft
        ? format(new Date(), 'YYYY-MM-DD')
        : format(launchDate, 'YYYY-MM-DD'),
      campaignEndDate: campaign.draft
        ? format(new Date(), 'YYYY-MM-DD')
        : format(endDate, 'YYYY-MM-DD'),
      campaignType: campaign.type, // 'fundraising' or 'merchandise'
      sellerUserId: campaign.user.id,
      sku: `PF-${cartItem.campaignProductId}-${item.featuredColourId}`,
      name: `${item.product.category}, ${campaign.title} in ${colour.name}`, // eg T-shirt, nicks campaign in red
      category: 'Apparel',
      variant: colour.name,
      quantity: cartItem.quantity,
      subtotal: cartItem.total * cartItem.quantity,
      vat: 0,
      price: cartItem.total,
      priceCurrency: user.currency,
      size: cartItem.size,
      featuredItem: item.featured, // is this the featured campaign product?
      featuredColour: colour.colourId === item.featuredColourId, // is this the featured colour the the product?
      // Data used by Klaviyo
      productImage: productImage?.url,
      collections,
      creatorUserId
    };
  });
}

function getItemIds(items, isFeatured = false) {
  const itemIds = [];

  items.forEach(({ campaignProductId, colourId, featuredColourId }) => {
    itemIds.push(
      `PF-${campaignProductId}-${isFeatured ? featuredColourId : colourId}`
    );
  });

  return itemIds;
}

function getAmplitudeCampaignDetails(isEnded, endAt) {
  return {
    State: isEnded ? 'Ended' : 'Live',
    'Days remaining': isEnded ? 0 : differenceInDays(endAt, new Date())
  };
}

const trackingEmitterMiddleware = (store) => (next) => (action) => {
  // We execute reducers straight away so this middleware is executed
  // after the store has been reduced by the action. It makes it easier
  // to retrieve the data.
  const nextAction = next(action);

  const {
    location,
    campaigns,
    user,
    cart,
    tracking,
    orders,
    profile,
    checkout
  } = store.getState();

  const setUserRolesAmplitudeUserProperties = () => {
    const authRoles = user.roles;
    const extendedUserRoles = user.extendedRoles;
    const userRole = [...authRoles, ...extendedUserRoles];
    const userProperties = {
      'User Role': userRole
    };
    setAmplitudeUserProperties(userProperties);
  };

  if (action.type === 'SET_INITIAL_LOAD') {
    setUserRolesAmplitudeUserProperties();
    setAmplitudeUserId(user.userId);
  }

  if (action.type === 'SET_USER') {
    setUserRolesAmplitudeUserProperties();
    setAmplitudeUserId(user.userId);

    // If logging out, regenerate device id
    // https://developers.amplitude.com/docs/javascript#log-out-and-anonymous-users
    if (user.userId === null) {
      regenerateAmplitudeDeviceId();
    }
  }

  if (action.type === 'SAVE_USER_PROFILE_FULFILLED') {
    const { profileName, slug, bio } = action.meta;
    sendAmplitudeEvent('Profile Save', {
      'Profile name': profileName,
      Slug: slug,
      Bio: bio
    });
  }

  if (action.type === 'SAVE_USER_PROFILE_PICTURE_FULFILLED') {
    sendAmplitudeEvent('Profile Save', {
      'Profile picture': true
    });
  }

  if (action.type === 'SAVE_USER_PROFILE_BANNER_FULFILLED') {
    sendAmplitudeEvent('Profile Save', {
      'Profile banner': true
    });
  }

  try {
    // User launched a campaign
    if (action.type === 'LAUNCH_CAMPAIGN_FULFILLED') {
      fbq('track', 'CompleteRegistration');

      window.dataLayer.push({
        event: 'campaignLaunched'
      });

      sendAmplitudeEvent('Campaign Launch', {});
    }

    // User visited a campaign page
    if (action.type === 'CAMPAIGN_RECEIVED') {
      const {
        campaignId,
        title,
        draft,
        products,
        user,
        endAt,
        tags,
        printOnDemand
      } = action.payload;

      const featuredProduct = products.find((product) => product.featured);
      const {
        campaignProductId,
        featuredColourId,
        product,
        colours,
        prices
      } = featuredProduct;
      const defaultPrice = prices.find((price) => price.default);
      const endDate = parse(endAt);
      const hasEnded = isPast(endDate);

      const accountManagerName = (tags) => {
        const owner = tags.find((tag) => tag.typeReference === 'owner');
        if (owner !== undefined) {
          return tags.find((tag) => tag.typeReference === 'owner').name;
        }
      };

      const isAccountManaged = (tags) => {
        const owner = tags.find((tag) => tag.typeReference === 'owner');
        if (owner !== undefined) {
          const containsOwnerNoneOrSelfServe = tags
            .find((tag) => tag.typeReference === 'owner')
            .name.match(/None|Self-serve/gi);

          return accountManagerName(tags) === undefined ||
            containsOwnerNoneOrSelfServe !== null
            ? 'False'
            : 'True';
        } else {
          return 'False';
        }
      };

      const isEnded = isPast(endDate) && !draft && endAt !== null;

      if (draft) {
        fbq('track', 'PreviewPage');

        sendAmplitudeEvent('Campaign Preview');
      } else {
        fbq('track', 'ViewContent', {
          content_type: 'product',
          content_ids: [`PF-${campaignProductId}-${featuredColourId}`]
        });

        if (user.facebookPixelId) {
          fbq('init', user.facebookPixelId);
          fbq('trackCustom', 'EP_CampaignView');
        }

        sendAmplitudeEvent('View Campaign', {
          ...getAmplitudeCampaignDetails(isEnded, endAt),
          'Campaign ID': campaignId,
          'Campaign Categories': getCampaignTagsByReference('category', tags),
          'Campaign Type': printOnDemand ? 'PoD' : 'Preorder',
          'Campaign Collections': getCampaignCollections(tags),
          'Is Account Managed': isAccountManaged(tags),
          'Account Manager': accountManagerName(tags)
            ? accountManagerName(tags)
            : 'Self Serve'
        });
      }

      window.dataLayer.push({
        campaignId: campaignId,
        campaignName: title,
        productName: `${product.name} ${
          colours.find((colour) => colour.colourId === featuredColourId)?.name
        }`,
        price: defaultPrice.price,
        currencyCode: defaultPrice.iso,
        sellerUserId: user.userId,
        leadSku: `PF-${campaignProductId}-${featuredColourId}`,
        campaignIsLive: !draft && hasEnded ? 'no' : 'yes'
      });

      window.dataLayer.push({
        event: 'pageview',
        transactionEmail: user && user.details && user.details.email,
        item_ids: getItemIds(action.payload.products, true)
      });
    }

    // User visited a store page
    if (action.type === 'STORE_RECEIVED') {
      [
        ...new Set(action.payload.tiles.map((tile) => tile.facebookPixelId))
      ].forEach((id) => {
        if (id) {
          fbq('init', id);
          fbq('trackCustom', 'EP_StoreView');
        }
      });
    }

    // User visited the shop
    if (action.type === 'SHOP') {
      fbq('track', 'ViewShop');

      if (!!action.meta.isGlobalSearch) {
        return sendAmplitudeEvent('Global search submitted', {
          Query: action.query?.q,
          Location: action.meta.pathname
        });
      }
    }

    // User has viewed "shop items per load" experiment
    if (action.type === 'SHOP_ITEMS_PER_LOAD_EXPERIMENT') {
      sendAmplitudeEvent('Shop items Per Load experiment viewed', {
        'Items per load': action.itemsPerLoad
      });
    }

    if (action.type === 'SHOP_SEARCH_EXPERIMENT_LOADED') {
      sendAmplitudeEvent('Shop Search Experiment Loaded', {
        Variant: action.payload.variant,
        'Variant Name': action.payload.variant === 0 ? 'Original' : 'New Search'
      });
    }

    if (action.type === 'UPDATE_SHOP_SEARCH_QUERY') {
      sendAmplitudeEvent('Set Shop Search Query', {
        Query: action.payload
      });
    }

    if (action.type === 'SHOP_SEARCH_LOAD_MORE') {
      sendAmplitudeEvent('Shop Search Load More', {
        'Current Page': action.payload.searchPage + 1,
        'Page Count': action.payload.searchPageCount
      });
    }

    if (action.type === 'GET_MORE_SHOP_ITEMS_FULFILLED') {
      // User clicked load more in shop page
      const { pageNumber } = action.meta;

      sendAmplitudeEvent('Shop Load More', {
        'Page number': pageNumber
      });
    }

    if (action.type === 'SHOP_CAMPAIGN_CLICKED') {
      sendAmplitudeEvent('Shop campaign clicked', {
        'Shop collection': action.payload.tag,
        'Shop search': action.payload.isSearchResult,
        'Shop featured': action.payload.isFeatured
      });
    }

    // User signed up
    if (action.type === 'SIGNUP_USER_FULFILLED') {
      fbq('track', 'Lead');

      window.dataLayer.push({
        event: 'registration',
        userId: user.userId
      });

      sendAmplitudeEvent('Sign Up', { 'Page URL': location.pathname });
    }

    //User used sign up on existing account so auto switch to SIGN_IN
    if (action.type === 'SIGN_IN_EXISTING_USER') {
      sendAmplitudeEvent('Sign In Existing User', {
        'Page URL': location.pathname
      });
    }

    // User logged in
    if (action.type === 'LOGIN_USER_FULFILLED') {
      sendAmplitudeEvent('Login', { 'Page URL': location.pathname });
    }

    // User requested ended campaign
    if (action.type === 'REQUEST_ENDED_CAMPAIGN_FULFILLED') {
      const slug = action.meta.slug || action.meta.id.slug;
      const campaign = getCampaignBySlug(slug, campaigns);
      const featuredProduct = campaign.products.find(
        (product) => product.featured
      );
      const {
        category,
        prices,
        campaignProductId,
        featuredColourId
      } = featuredProduct;

      fbq('track', 'AddToWishlist', {
        content_name: campaign.title,
        content_category: category,
        content_type: 'product',
        content_ids: [`PF-${campaignProductId}-${featuredColourId}`],
        value: prices.find((price) => price.iso === user.currency).price,
        currency: user.currency
      });
    }

    if (action.type === 'ADD_CART_ITEM_FULFILLED') {
      const {
        campaignId,
        campaignProductId,
        colourId,
        utmParams
      } = action.meta;
      const campaign = getCampaignById(campaignId, campaigns);

      if (campaign && campaign?.products.length > 0) {
        const item = campaign.products.find(
          (product) => product.campaignProductId === campaignProductId
        );
        const prefix = campaign.draft ? 'SF' : 'PF';
        const endDate = parse(campaign.endAt);
        const isEnded =
          isPast(endDate) && !campaign.draft && campaign.endAt !== null;

        fbq('track', 'AddToCart', {
          content_type: 'product',
          content_name: campaign.title,
          content_ids: [`${prefix}-${campaignProductId}-${colourId}`],
          value: item.prices.find((price) => price.iso === cart.currency).price,
          currency: cart.currency
        });

        window.dataLayer.push({
          event: 'addToCart',
          content_type: 'product',
          content_name: campaign.title,
          content_ids: [`${prefix}-${campaignProductId}-${colourId}`],
          value: item.prices.find((price) => price.iso === cart.currency).price,
          currency: cart.currency
        });

        sendAmplitudeEvent('Add to Cart', {
          ...getAmplitudeCampaignDetails(isEnded, campaign.endAt),
          'Campaign ID': campaignId,
          Sample: campaign.draft,
          'Campaign Categories': getCampaignTagsByReference(
            'category',
            campaign.tags
          ),
          'Campaign Collections': getCampaignCollections(campaign.tags),
          'UTM Params': utmParams
        });
      }
    }

    // User reached checkout page
    if (action.type === 'CHECKOUT') {
      if (!tracking.checkout.visited) {
        fbq('track', 'InitiateCheckout', {
          value: cart.total,
          currency: user.currency
        });
      }
    }

    if (action.type === 'CHECKOUT_LOAD_WITHOUT_PAYPAL') {
      sendAmplitudeEvent('Checkout loaded without paypal');
    }

    if (action.type === 'CHECKOUT_LOAD_WITH_PAYPAL') {
      sendAmplitudeEvent('Checkout loaded with paypal');
    }

    if (action.type === 'BUYER_LOGIN_CHECKOUT_AS_GUEST') {
      window.dataLayer.push({ event: 'buyerLoginCheckoutAsGuest' });
    }

    if (action.type === 'BUYER_LOGIN_SIGN_IN_FULFILLED') {
      window.dataLayer.push({ event: 'buyerLoginSignIn' });
    }

    if (action.type === 'BUYER_LOGIN_SIGN_UP') {
      window.dataLayer.push({ event: 'buyerLoginSignUp' });
    }

    if (action.type === 'SAVE_CHECKOUT_STEP') {
      const { payload: step, meta } = action;

      const stepNamesMap = {
        STEP_ONE: 'Your details',
        STEP_TWO: 'Address',
        STEP_THREE: 'Delivery Method',
        STEP_FOUR: 'Pay'
      };

      if (!isEmpty(meta)) {
        window.dataLayer.push({
          event: 'saveCheckoutStep',
          formLocation: stepNamesMap[step],
          transactionId: meta.token,
          email: meta.email,
          deliveryCountry: meta.deliveryAddress?.country
        });
      }

      switch (step) {
        case 'STEP_ONE':
          sendAmplitudeEvent(stepNamesMap[step], {
            'Sign in status': user.userId ? 'Signed in' : 'Guest'
          });
          break;
        case 'STEP_TWO':
          sendAmplitudeEvent(stepNamesMap[step]);
          break;
        case 'STEP_THREE':
          sendAmplitudeEvent(stepNamesMap[step]);
          break;
        case 'STEP_FOUR':
          sendAmplitudeEvent(stepNamesMap[step], {
            'Shipping Value': cart.totalsGBP.delivery
          });
          break;
        default:
          break;
      }
    }

    if (
      (location.type === 'CHECKOUT' && action.type === 'GET_CART_FULFILLED') ||
      (action.type === 'CHECKOUT' && cart.status === 'resolved')
    ) {
      const quantity = cart.items
        .map((item) => item.quantity)
        .reduce((a, b) => a + b);

      const [giftCards, products] = partition(cart.items, 'giftCardId');

      const productsTotal = sumBy(products, 'total') + cart.delivery;
      const productsSubtotal = sumBy(products, 'total');
      const productsQuantity = sumBy(products, 'quantity');

      const transactionalProducts = getTransactionProducts(
        products,
        campaigns,
        user
      );

      if (!isEmpty(transactionalProducts)) {
        const campaignIds = [
          ...new Set(transactionalProducts.map((row) => row.campaignId))
        ];

        window.dataLayer.push({
          event: 'proceedToCheckout',
          type: 'checkout',
          quantity: productsQuantity,
          subtotal: productsSubtotal,
          currency: user.currency,
          email: user.details.email,
          transactionShipping: cart.delivery,
          transactionTotal: productsTotal,
          transactionalProducts: transactionalProducts,
          item_ids: getItemIds(products)
        });

        sendAmplitudeEvent('View Checkout', {
          Quantity: quantity,
          'Number of Distinct Campaigns': campaignIds.length,
          'Total Basket Value': cart.totalsGBP.total,
          'Basket Product Value': cart.totalsGBP.subtotal,
          'Shipping Value': cart.totalsGBP.delivery,
          'Campaign IDs': campaignIds,
          'Campaign Categories': getCampaignsTagsByReference(
            'category',
            getCartCampaigns({ items: products }, campaigns)
          ),
          'Campaign Collections': getCampaignsCollections(
            getCartCampaigns({ items: products }, campaigns)
          ),
          'Gift Cards': giftCards
        });
      }

      if (isEmpty(transactionalProducts) && !isEmpty(giftCards)) {
        window.dataLayer.push({
          event: 'proceedToCheckout',
          type: 'checkout',
          quantity: quantity,
          subtotal: cart.subtotal,
          currency: user.currency,
          email: user.details.email,
          transactionShipping: cart.delivery,
          transactionTotal: cart.total,
          transactionalProducts: giftCards,
          item_ids: []
        });

        sendAmplitudeEvent('View Checkout', {
          Quantity: quantity,
          'Total Basket Value': cart.totalsGBP.total,
          'Basket Product Value': cart.totalsGBP.subtotal,
          'Shipping Value': cart.totalsGBP.delivery,
          'Gift Cards': giftCards
        });
      }
    }

    // User started to fill checkout
    if (action.type === 'EDIT_CHECKOUT_DETAILS_PENDING') {
      if (!tracking.checkout.touched) {
        fbq('track', 'AddPaymentInfo');
      }
    }

    // User paid
    if (
      action.type === 'CHECKOUT_PAYPAL_FULFILLED' ||
      action.type === 'CHECKOUT_STRIPE_FULFILLED' ||
      action.type === 'CONFIRM_STRIPE_PAYMENT_INTENT_FULFILLED' ||
      action.type === 'CHECKOUT_FULFILLED'
    ) {
      // Store has been emptied of the cart, so we retrieve order data from the payload instead
      const { campaigns, totalsGBP, items } = action.payload;

      const { deliveries } = checkout;

      const [giftCards, products] = partition(items, 'giftCardId');

      const deliveriesTotal = sumBy(deliveries, 'total');
      const productsTotal = sumBy(products, 'total') + deliveriesTotal;

      const cartCampaigns = products.map((item) =>
        getCampaignById(item.campaignId, campaigns)
      );

      const cartQuantity = items
        .map((item) => item.quantity)
        .reduce((a, b) => a + b);

      cartCampaigns.forEach((campaign) => {
        if (campaign.user.facebookPixelId) {
          fbq('init', campaign.user.facebookPixelId);
          fbq('trackCustom', 'EP_CheckoutComplete');
        }
      });

      if (!isEmpty(cartCampaigns)) {
        fbq('track', 'Purchase', {
          content_type: 'product',
          content_ids: cartCampaigns
            .map((item) => {
              //TODO: EX-3105 - prefix should support sample purchases
              return `PF-${item.campaignProductId}-${item.colourId}`;
            })
            .join(','),
          content_name: cartCampaigns
            .map((campaign) => campaign.campaignId)
            .join(','),
          value: productsTotal,
          currency: user.currency,
          num_items: cartCampaigns.length
        });

        const campaignIds = [
          ...new Set(cartCampaigns.map((row) => row.campaignId))
        ];

        const campaignTypes = cartCampaigns.map(({ printOnDemand }) =>
          printOnDemand ? 'PoD' : 'Preorder'
        );

        sendAmplitudeEvent('Checkout Complete', {
          Quantity: cartQuantity,
          'Number of Distinct Campaigns': campaignIds.length,
          'Total Basket Value': totalsGBP.total,
          'Basket Product Value': totalsGBP.subtotal,
          'Shipping Value': totalsGBP.delivery,
          'Campaign IDs': campaignIds,
          'Campaign Types': campaignTypes,
          'Campaign Categories': getCampaignsTagsByReference(
            'category',
            cartCampaigns
          ),
          'Campaign Collections': getCampaignsCollections(
            getCartCampaigns({ items: products }, campaigns)
          ),
          'Gift Cards': giftCards
        });
      }

      if (isEmpty(cartCampaigns) && !isEmpty(giftCards)) {
        sendAmplitudeEvent('Checkout Complete', {
          Quantity: cartQuantity,
          'Total Basket Value': totalsGBP.total,
          'Basket Product Value': totalsGBP.subtotal,
          'Shipping Value': totalsGBP.delivery,
          'Gift Cards': giftCards
        });
      }
    }

    if (location.type === 'ORDER' && action.type === 'GET_ORDER_FULFILLED') {
      const { token } = location.payload;
      const order = orders.bySlug[token] && orders.bySlug[token].data;
      const checkoutDate = parse(order.checkoutAt);

      const [giftCards, products] = partition(order.items, 'giftCardId');

      const productsTotal = sumBy(products, 'total') + order.delivery;
      const productsSubtotal = sumBy(products, 'total');

      const transactionProducts = getTransactionProducts(
        products,
        campaigns,
        user
      );

      if (!isEmpty(transactionProducts)) {
        window.dataLayer.push({
          event: 'awin.dl.ready',
          transactionTotal: productsTotal,
          transactionCurrency: order.currency,
          transactionID: order.orderId,
          transactionPromoCode: order.discount?.discountCode
        });

        window.dataLayer.push({
          event: 'checkoutComplete',
          type: 'purchase',
          currency: user.currency,
          transactionId: token,
          transactionTotal: productsTotal,
          transactionEmail: order.email,
          item_ids: getItemIds(products)
        });

        window.dataLayer.push({
          type: 'order',
          quantity: transactionProducts.length,
          subtotal: productsSubtotal,
          currency: user.currency,
          email: order.email,
          transactionShipping: order.delivery,
          transactionTotal: productsTotal,
          transactionProducts: transactionProducts,
          transactionId: token,
          transactionDate: format(checkoutDate, 'YYYY-MM-DD'),
          transactionTime: format(checkoutDate, 'HH:MM:SS'),
          deliveryCountry: order.deliveryAddress.country,
          deliveryCity: order.deliveryAddress.city
        });
      }

      if (isEmpty(transactionProducts) && !isEmpty(giftCards)) {
        window.dataLayer.push({
          event: 'checkoutComplete',
          type: 'purchase',
          currency: user.currency,
          transactionId: token,
          transactionTotal: 0,
          transactionEmail: order.email,
          item_ids: []
        });

        window.dataLayer.push({
          type: 'order',
          quantity: order.items.length,
          subtotal: 0,
          currency: user.currency,
          email: order.email,
          transactionShipping: order.delivery,
          transactionTotal: 0,
          transactionProducts: giftCards,
          transactionId: token,
          transactionDate: format(checkoutDate, 'YYYY-MM-DD'),
          transactionTime: format(checkoutDate, 'HH:MM:SS'),
          deliveryCountry: order.deliveryAddress.country,
          deliveryCity: order.deliveryAddress.city
        });
      }
    }

    if (action.type === 'CHECKOUT_CODE_INITIALIZATION') {
      const { variantId } = action.payload;

      sendAmplitudeEvent('Checkout Code Initialization', {
        'Variant ID': variantId,
        'Variant Type': variantId === 1 ? 'Discount Code' : 'Original Variant'
      });
    }

    if (action.type === 'CHECKOUT_CODE_ATTEMPT') {
      const { discountCode } = action.payload;

      sendAmplitudeEvent('Checkout Code Attempt', {
        'Discount Code': discountCode
      });
    }

    if (action.type === 'LANDING') {
      fbq('trackCustom', 'LandingPageView');
    }

    /**
     * * Lead Capture
     */
    if (action.type === 'LEAD_CAPTURE') {
      fbq('trackCustom', 'LeadFormView');
    }

    if (action.type === 'CAPTURE_LEAD_FULFILLED') {
      fbq('track', 'Lead');
    }

    /**
     * * Submit your design
     */
    if (action.type === 'SUBMIT_YOUR_DESIGN') {
      fbq('trackCustom', 'SubmitYourDesignView');
    }

    if (action.type === 'SUBMIT_YOUR_DESIGN_FULFILLED') {
      fbq('track', 'SubmitYourDesign');
    }

    /**
     * * Campaign relaunch
     */
    if (action.type === 'QUICK_RELAUNCH_CAMPAIGN') {
      window.dataLayer.push({ event: 'quickRelaunchCampaign' });

      fbq('trackCustom', 'QuickRelaunchCampaign');
    }

    if (
      isLocationAction(action) &&
      action.type !== 'CAMPAIGN_RECEIVED' &&
      action.type !== 'CAMPAIGN_OR_STORE'
    ) {
      window.dataLayer.push({
        event: 'pageview',
        transactionEmail: user && user.details && user.details.email
      });
    }

    if (isLocationAction(action)) {
      let pageGroup = '';

      switch (action.type) {
        case 'SHOP':
          pageGroup = 'Shop';
          break;

        case 'CAMPAIGN_OR_STORE':
          pageGroup = 'Campaign';
          break;

        case 'PROFILE':
          pageGroup = 'Profile';
          break;

        case 'DASHBOARD_CAMPAIGNS':
        case 'DASHBOARD_PAYOUTS':
        case 'DASHBOARD_ACCOUNT_SETTINGS':
        case 'DASHBOARD_ORDERS':
          pageGroup = 'Dashboard';
          break;

        default:
          pageGroup = '';
          break;
      }

      sendAmplitudeEvent('View Page', {
        'Page group': pageGroup,
        'Page name': action.type,
        'Page URL': action.meta.location.current.pathname
      });
    }

    if (action.type === 'PROFILE_READY') {
      if (action.meta.location === 'settings') {
        sendAmplitudeEvent('View Profile Settings', {
          'View as': 'Owner',
          'Profile Name': profile.profileName,
          'Profile Bio': profile.bio,
          'Profile Slug': profile.slug
        });
      } else {
        sendAmplitudeEvent('View Profile', {
          'View as': action.meta.isOwner ? 'Owner' : 'Visitor',
          'Profile Name': action.meta.name,
          'Profile Bio': action.meta.bio,
          'Profile Slug': action.meta.slug,
          ...(!action.meta.isOwner && {
            'Following status': action.meta.isFollowing ? 'true' : 'false'
          })
        });
      }
    }

    if (
      action.type === 'FOLLOW_USER_FULFILLED' ||
      action.type === 'UNFOLLOW_USER_FULFILLED'
    ) {
      const followPath = location.pathname;
      let pageType = 'campaign';

      if (followPath === '/dashboard/following') {
        pageType = 'dashboard';
      } else if (followPath.includes('/order/confirmation')) {
        pageType = 'order';
      } else if (followPath.includes('/profile')) {
        pageType = 'profile';
      }

      const eventData = {
        'Page URL': followPath,
        'Page type': pageType
      };

      if (action.type === 'FOLLOW_USER_FULFILLED') {
        sendAmplitudeEvent('Follow', eventData);
      } else {
        sendAmplitudeEvent('Unfollow', eventData);
      }
    }

    if (action.type === 'HIDE_PROFILE_CAMPAIGN_FULFILLED') {
      const campaigns = profile.campaignsBySlug[location.payload.slug];
      const visibleCampaigns = campaigns.filter(
        (campaign) => !campaign.hiddenOnProfile
      ).length;
      const hiddenCampaigns = campaigns.filter(
        (campaign) => campaign.hiddenOnProfile
      ).length;

      sendAmplitudeEvent('Campaign Hidden On Profile', {
        Campaign: action.meta.campaign,
        Hidden: action.meta.hiddenOnProfile,
        'Profile Name': profile.profileName,
        'Profile Bio': profile.bio,
        'Profile Slug': profile.slug,
        'Visible Campaigns': visibleCampaigns,
        'Hidden Campaigns': hiddenCampaigns
      });
    }

    if (action.type === 'FIND_MORE_ITEMS') {
      const eventData = {
        Quantity: cart.quantity,
        'Total Basket Value': cart.totalsGBP.total
      };

      sendAmplitudeEvent('Cart Find More Items', eventData);
    }

    if (action.type === 'SAVE_SHOP_ACTIVE_FILTERS') {
      const {
        sort,
        productCategories,
        productPrintPositions,
        productColours,
        productSizes
      } = action.payload;

      const eventData = {
        'Sort by': sort,
        'Product Categories': keys(pickBy(productCategories)),
        'Product Print Positions': keys(pickBy(productPrintPositions)),
        'Product Colours': keys(pickBy(productColours)),
        'Product Sizes': keys(pickBy(productSizes))
      };

      sendAmplitudeEvent('Filter', eventData);
    }

    if (action.type === 'RAIL_CAMPAIGN_CLICK') {
      switch (action.payload.type) {
        case 'Recently Viewed':
          return sendAmplitudeEvent('Open Campaign from Recently Viewed Rail');
        case 'UGC':
          return sendAmplitudeEvent('Open Campaign from UGC Rail');
        case 'Community Favourites':
          return sendAmplitudeEvent(
            'Open Campaign from Community Favourites Rail'
          );
        case 'Recommended Items':
          return sendAmplitudeEvent(
            'Open Campaign from Recommended Items Rail'
          );
        case 'Featured Profile':
          return sendAmplitudeEvent('Open Profile from Featured Profiles Rail');
        case 'Featured Campaigns':
          return sendAmplitudeEvent(
            `Open Campaign from ${action.payload.title} Rail`
          );
        default:
          return null;
      }
    }

    if (action.type === 'RAIL_SCROLL') {
      switch (action.payload.type) {
        case 'Recently Viewed':
          return sendAmplitudeEvent('Recently Viewed Rail Scroll');
        case 'UGC':
          return sendAmplitudeEvent('UGC Rail Scroll');
        case 'Community Favourites':
          return sendAmplitudeEvent('Community Favourites Rail Scroll');
        case 'Recommended Items':
          return sendAmplitudeEvent('Recommended Items Rail Scroll');
        case 'Featured Profile':
          return sendAmplitudeEvent('Featured Profiles Rail Scroll');
        case 'Featured Campaigns':
          return sendAmplitudeEvent(`${action.payload.title} Rail Scroll`);
        default:
          return null;
      }
    }

    if (action.type === 'FAVOURITE_CAMPAIGN_FULFILLED') {
      const { campaignId } = action.payload.campaign;

      let pageType = 'campaign';

      if (location.pathname === '/shop') {
        pageType = 'shop';
      }

      const eventData = {
        campaignId,
        'Page URL': location.pathname,
        'Page type': pageType
      };

      sendAmplitudeEvent('Add Campaign Favourite', { eventData });
    }

    if (action.type === 'DELETE_USER_FAVOURITE_FULFILLED') {
      const { campaignId } = action.meta;

      let pageType = 'shop';

      if (location.pathname === '/favourites') {
        pageType = 'favourites';
      }

      const eventData = {
        campaignId,
        'Page URL': location.pathname,
        'Page type': pageType
      };

      sendAmplitudeEvent('Delete Campaign Favourite', { eventData });
    }

    if (action.type === 'FAVOURITE_CAMPAIGN_ATTEMPT') {
      const { campaignId } = action.meta;

      sendAmplitudeEvent('Add Campaign Favourite Attempt', { campaignId });
    }

    if (action.type === 'VIEW_SIZE_CHART') {
      const { modalToggleTrigger } = action.meta;

      sendAmplitudeEvent('Toggle Size Modal', { Trigger: modalToggleTrigger });
    }

    if (action.type === 'EDIT_EMAIL_USER_PREFERENCES_FULFILLED') {
      const data = {
        emailType: 'favourite',
        optIn: user.emailPreferences.favourites
      };
      sendAmplitudeEvent('Edit User Email Preferences', data);
    }

    if (action.type === 'REFERRAL_LANDING') {
      window.dataLayer.push({
        event: 'referralLanding'
      });
    }

    if (action.type === 'ACTIVATE_CONCIERGE') {
      sendAmplitudeEvent('Activate Concierge');
    }

    if (action.type === 'DISMISS_CONCIERGE') {
      sendAmplitudeEvent('Dismiss Concierge');
    }
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
  }

  if (action.type === 'CAREERS_REDIRECT') {
    sendAmplitudeEvent('View Bamboo Job Post', {
      Role: action.payload
    });
  }

  if (action.type === 'CAREERS_VIEW_JOBS') {
    sendAmplitudeEvent('View Open Positions');
  }

  if (action.type === 'SET_USER_CURRENCY' && action.meta.previousCurrency) {
    sendAmplitudeEvent('Currency Changed', {
      From: action.meta.previousCurrency,
      To: action.payload
    });
  }

  if (action.type === 'PROFILE_LINK_CLICKED') {
    sendAmplitudeEvent('Profile Link Clicked', {
      profileName: action.payload.profileName,
      campaign: action.payload.campaign
    });
  }

  if (action.type === 'SAVE_USER_PROFILE_PATCH_FULFILLED') {
    const { optimist, slug, ...props } = action.meta;
    const propNames = Object.getOwnPropertyNames(props) || [];

    sendAmplitudeEvent('Profile Edit - Profile Page', {
      ...(propNames.length > 1
        ? { fields: propNames }
        : { field: propNames[0] })
    });
  }

  if (action.type === 'DOWNLOAD_IMAGES_BUTTON_CLICKED') {
    sendAmplitudeEvent('Download Images Click');
  }

  if (action.type === 'DOWNLOAD_IMAGES_MODAL_CLICKED') {
    const imageIndex = action.payload.currentImageIndex;
    const imageBackground = imageIndex === 1 ? 'Grey' : 'White';

    sendAmplitudeEvent('Download Images Modal Click', {
      'Image Background': imageBackground
    });
  }

  if (action.type === 'PROFILE_NOTIFICATION_BANNER_CLICK') {
    sendAmplitudeEvent('Profile notification banner clicked');
  }

  if (action.type === 'ACCOUNT_ITEM_CLICK') {
    return sendAmplitudeEvent('Account dropdown item clicked', {
      'Item clicked': action.payload.itemClicked,
      Slug: action.payload.slug
    });
  }

  if (action.type === 'INTERSTITIAL_CART_REDIRECT') {
    sendAmplitudeEvent('Interstitial Cart Redirect', {
      Target: action.payload
    });
  }

  if (action.type === 'LEAD_SIGN_UP_FULFILLED') {
    sendAmplitudeEvent('Lead sign up', {
      source: action.meta.source
    });
  }

  if (action.type === 'HERO_FEATURE_CARD_CLICKED') {
    return sendAmplitudeEvent('Hero feature card clicked', {
      Slug: action.payload.slug
    });
  }

  if (action.type === 'CONTENT_CARD_CLICKED') {
    return sendAmplitudeEvent('Content card clicked', {
      Slug: action.payload.slug
    });
  }

  if (action.type === 'MEGA_MENU_CLICKED') {
    return sendAmplitudeEvent('Mega menu clicked', {
      Type: action.payload.type,
      Name: action.payload.name,
      Slug: action.payload.slug
    });
  }

  if (action.type === 'COLLECTIONS_NAV_CLICKED') {
    sendAmplitudeEvent('Shop Collections Nav Clicked', {
      Tag: action.payload.tag,
      Type: action.payload.type
    });
  }

  if (action.type === 'WELCOME_OFFER_MODAL_VIEWED') {
    return sendAmplitudeEvent('Welcome offer modal viewed');
  }

  if (action.type === 'WELCOME_OFFER_MODAL_FORM_COMPLETE') {
    return sendAmplitudeEvent('Welcome offer modal form complete', {
      Email: action.payload.email
    });
  }

  if (action.type === 'FEATURED_BLOG_POSTS_CLICK') {
    return sendAmplitudeEvent('Featured blog posts click', {
      'Page URL': action.payload?.pathname
    });
  }

  if (action.type === 'SET_GLOBAL_SEARCH_OPEN') {
    if (action.payload === true) {
      return sendAmplitudeEvent('Global search opened');
    } else {
      if (action.meta?.type !== 'submit') {
        return sendAmplitudeEvent('Global search closed');
      }
    }
  }

  if (action.type === 'CATEGORY_CHIPS_CLICKED') {
    sendAmplitudeEvent('Category chips clicked', {
      Tag: action.payload.tag,
      Selected: action.payload.isSelected
    });
  }

  if (action.type === 'CATEGORY_CHIPS_CLOSED' && !action.meta?.isSubmitted) {
    sendAmplitudeEvent('Category chips closed');
  }

  if (action.type === 'CATEGORY_CHIPS_SUBMITTED') {
    sendAmplitudeEvent('Category chips submitted', {
      User: user.userId,
      tags: action.payload
    });
  }

  if (action.type === 'USER_FAVOURITE_CATEGORIES_CLICKED') {
    sendAmplitudeEvent('Favourite categories clicked', {
      Tag: action.payload.tag,
      Selected: action.payload.isSelected
    });
  }

  if (action.type === 'SAVE_USER_FAVOURITE_CATEGORIES_FULFILLED') {
    sendAmplitudeEvent('Favourite categories saved', {
      User: user.userId,
      Location: location.pathname,
      Tags: action.meta.tags
    });
  }

  if (action.type === 'CATEGORY_CHIPS_EXPERIMENT_LOADED') {
    sendAmplitudeEvent('Category chips experiment loaded', {
      Variant: action.payload.variant
    });
  }

  if (action.type === 'TRUSTBOX_MOUSE_OVER') {
    sendAmplitudeEvent('Trustbox mouse over');
  }

  if (action.type === 'EDIT_CAMPAIGN_SETTINGS_FULFILLED') {
    sendAmplitudeEvent('Campaign settings changed', {
      Settings: action.payload
    });
  }

  if (action.type === 'CREATE_USER_BANDCAMP_PRODUCT_FULFILLED') {
    sendAmplitudeEvent('Bandcamp campaign product connected', {
      Product: action.meta
    });
  }

  if (action.type === 'CREATE_USER_BANDCAMP_PRODUCT_REJECTED') {
    sendAmplitudeEvent('Bandcamp campaign product error', {
      Error: action.payload.response.data?.message,
      Product: action.meta
    });
  }

  if (action.type === 'DOWNLOAD_ORDERS_CSV') {
    sendAmplitudeEvent('Download orders CSV', {
      'Campaign ID': action.meta?.campaignId
    });
  }

  if (action.type === 'KLAVIYO_IDENTIFY') {
    window.dataLayer.push({
      event: 'klaviyoIdentify',
      email: action.payload?.email
    });
  }

  if (action.type === 'START_CHECKOUT') {
    const { utmParams } = tracking;

    const quantity = cart.items
      .map((item) => item.quantity)
      .reduce((a, b) => a + b);

    const [giftCards, products] = partition(cart.items, 'giftCardId');

    const productsTotal = sumBy(products, 'total') + cart.delivery;
    const productsSubtotal = sumBy(products, 'total');
    const productsQuantity = sumBy(products, 'quantity');

    const transactionProducts = getTransactionProducts(
      products,
      campaigns,
      user
    );

    if (!isEmpty(transactionProducts)) {
      window.dataLayer.push({
        event: 'startCheckout',
        quantity: productsQuantity,
        subtotal: productsSubtotal,
        currency: user.currency,
        email: user.details.email,
        transactionShipping: cart.delivery,
        transactionTotal: productsTotal,
        transactionProducts: transactionProducts,
        item_ids: getItemIds(products),
        utmParams
      });
    }

    if (isEmpty(transactionProducts) && !isEmpty(giftCards)) {
      window.dataLayer.push({
        event: 'startCheckout',
        quantity: quantity,
        subtotal: 0,
        currency: user.currency,
        email: user.details.email,
        transactionShipping: cart.delivery,
        transactionTotal: 0,
        transactionProducts: giftCards,
        item_ids: [],
        utmParams
      });
    }
  }

  if (action.type === 'BUILDER_ARTWORK_UPLOADED') {
    sendAmplitudeEvent('Builder artwork uploaded', {
      'Campaign ID': action.payload?.campaignId
    });
  }

  if (action.type === 'BUILDER_CATALOGUE_OPENED') {
    sendAmplitudeEvent('Builder catalogue opened');
  }

  if (action.type === 'ADD_BUILDER_CAMPAIGN_PRODUCT_FULFILLED') {
    sendAmplitudeEvent('Builder product added', {
      'Campaign ID': action.meta?.campaignId,
      Product: action.meta?.productData
    });
  }

  return nextAction;
};

export default trackingEmitterMiddleware;
