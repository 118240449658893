import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import fitty from 'fitty';
import { produce } from 'immer';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import { getText } from '../../helpers/prismic';

import Page from '../../components/utils/Page';
import Marquee from '../../components/utils/Marquee';

import { InlineGrid } from '../../components/atoms/Grid';
import { ButtonNew as Button } from '../../components/atoms/Button';
import Heading from '../../components/atoms/Heading';
import PageWrap from '../../components/atoms/PageWrap';
import Paragraph from '../../components/atoms/Paragraph';
import Spacing from '../../components/atoms/Spacing';

import { Box, BoxItem } from '../../components/molecules/Box';

import generalStyles from './style/general.module.css';

const Sustainability = ({
  seoTitle,
  hero,
  milestones: initialMilestones,
  cards,
  image
}) => {
  const [milestones, setMilestones] = useState([]);
  const fittyInstance = useRef(null);
  useEffect(() => {
    Marquee.init({
      selector: 'marquee'
    });
    if (!fittyInstance.current) {
      fittyInstance.current = fitty('.fit');
    }
    return () => fittyInstance.current[0].unsubscribe();
  }, []);
  useEffect(() => {
    initialMilestones && setMilestones(initialMilestones);
  }, [initialMilestones]);
  const handleMilestoneVisibilityChange = (isVisible, index) => {
    if (isVisible) {
      const nextMilestones = produce((draft) => {
        draft[index].filled = true;
      });
      setMilestones(nextMilestones);
    }
  };
  return (
    <Page title={seoTitle} className="bg-pastel-grey">
      <PageWrap>
        <div className="border-t border-b">
          <div
            className={`${generalStyles.marquee} marquee`}
            data-speed="0.35"
            data-reverse={false}
            data-pauseable={false}
          >
            <Paragraph className="uppercase" size="xs">
              <span className="px-2">&#9679;</span>
              <span>A constant work in progress</span>
            </Paragraph>
          </div>
        </div>
        <Spacing size={1} position="t">
          <h1 className={`${generalStyles.title} fit`}>{hero.title}</h1>
        </Spacing>
        <div className={generalStyles.offset}>
          <Spacing size={[3, 4]}>
            <Paragraph size={['xs', 'xs', 's']}>{hero.description}</Paragraph>
          </Spacing>
        </div>
        <div className="py-4 lg:pt-6 lg:pb-4 border-t border-b">
          <div className={generalStyles.roadmapGrid}>
            <img
              src={image}
              alt="Poster"
              className={`${generalStyles.sticky} hidden lg:block mb-2 border-1 border-solid`}
            />
            <div className={generalStyles.milestones}>
              {milestones &&
                milestones.map((milestone, index) => (
                  <VisibilitySensor
                    active={!milestones[index].filled}
                    delayedCall
                    onChange={(isVisible) =>
                      handleMilestoneVisibilityChange(isVisible, index)
                    }
                    key={milestone.title}
                  >
                    <div className={generalStyles.milestone}>
                      <div className={generalStyles.ellipse}>
                        <svg
                          viewBox="0 0 400 200"
                          className={classNames({
                            [generalStyles.filled]: milestone.filled
                          })}
                        >
                          <g>
                            <ellipse
                              cx="200"
                              cy="100"
                              rx="170"
                              ry="90"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              shapeRendering="geometricPrecision"
                            />
                            <text
                              textAnchor="middle"
                              dy=".3em"
                              textRendering="geometricPrecision"
                            >
                              <tspan x="50%" y="42%">
                                {milestone.month}
                              </tspan>
                              <tspan x="50%" y="66%">
                                {milestone.year}
                              </tspan>
                            </text>
                          </g>
                        </svg>
                        <svg
                          className={classNames(generalStyles.line, {
                            [generalStyles.hidden]:
                              index + 1 === milestones.length,
                            [generalStyles.filled]:
                              milestones[index + 1] &&
                              milestones[index + 1].filled
                          })}
                        >
                          <line
                            x1="50%"
                            y1="0"
                            x2="50%"
                            y2="100%"
                            shapeRendering="geometricPrecision"
                          />
                          <rect x="50%" y="0%" height="100%" width="1px" />
                        </svg>
                      </div>
                      <Spacing size={[1, 2]} type="padding">
                        <Heading tag="h4" size={['s', 'm']}>
                          {milestone.title}
                        </Heading>
                        <Spacing size={1}>
                          <div className={generalStyles.milestoneGrid}>
                            {!isEmpty(milestone.image) && (
                              <img
                                src={milestone.image.url}
                                alt={milestone.image.alt}
                                className="hidden lg:block"
                              />
                            )}
                            <Paragraph size="xs">
                              {milestone.description}
                            </Paragraph>
                          </div>
                        </Spacing>
                      </Spacing>
                    </div>
                  </VisibilitySensor>
                ))}
            </div>
          </div>
        </div>
        <Spacing size={[4, 4, 6]} type="padding">
          <Heading tag="h3" size={['l', 'xl']} center>
            Our focus
          </Heading>
        </Spacing>
        <div className={generalStyles.focusGrid}>
          {cards &&
            cards.map((card) => (
              <Box key={card.title}>
                <BoxItem size="s">
                  <Heading tag="h2" size={['l', 'l', 'xxl']}>
                    {card.title}
                  </Heading>
                  <Spacing size={1} position="y">
                    <Paragraph size={['xs', 'xs', 's']}>
                      {card.description}
                    </Paragraph>
                  </Spacing>
                </BoxItem>
              </Box>
            ))}
        </div>
        <Spacing size={[0, 4]} position="t">
          <Spacing size={8} position="t" type="padding">
            <Heading tag="h4" size={['l', 'xl']} center>
              Find out more
            </Heading>
            <Spacing size={4} type="padding">
              <div className="flex justify-center">
                <div className="sm:w-full md:w-80 lg:w-50 text-center">
                  <Paragraph size={['xs', 'xs', 's']}>
                    We try to be as transparent as possible in everything we do.
                    If you’re looking for more info, then check out our How it
                    works section or browse our FAQs
                  </Paragraph>
                </div>
              </div>
              <Spacing size={4} type="padding">
                <InlineGrid justify="center">
                  <Button to="/about" className="m-1">
                    About us
                  </Button>
                  <Button to="/how-it-works" className="m-1">
                    How it works
                  </Button>
                </InlineGrid>
              </Spacing>
            </Spacing>
          </Spacing>
        </Spacing>
      </PageWrap>
    </Page>
  );
};

const mapStateToProps = ({ pages }) => {
  const data = get(pages, 'sustainability.data', {});
  const { milestones, cards } = data;
  return {
    seoTitle: get(data, 'seo_title', ''),
    hero: {
      title: get(data, 'page_title', ''),
      description: getText(data.description)
    },
    image: get(data.image, 'url', ''),
    milestones:
      milestones &&
      milestones.map((item) => {
        return {
          title: getText(item.milestone_title),
          month: get(item, 'milestone_month', ''),
          year: get(item, 'milestone_year', ''),
          description: getText(item.milestone_description),
          image: get(item, 'milestone_image', null)
        };
      }),
    cards:
      cards &&
      cards.map((item) => {
        return {
          title: getText(item.card_title),
          description: getText(item.card_description)
        };
      })
  };
};

export default connect(mapStateToProps)(Sustainability);
