import React from 'react';

import Page from '../../components/utils/Page';

import PageWrap from '../../components/atoms/PageWrap';

import Hero from './Sections/Hero';
import Team from './Sections/Team';
import Marquee from './Sections/Marquee';
import Culture from './Sections/Culture';
import GivingBack from './Sections/GivingBack';
import Community from './Sections/Community';
import ContactUs from './Sections/ContactUs';

const People = ({ seoTitle }) => {
  return (
    <Page title={seoTitle}>
      <PageWrap>
        <Hero />
        <Marquee />
        <Team />
        <Marquee />
        <Culture />
        <Marquee />
        <GivingBack />
        <Marquee />
        <Community />
        <ContactUs />
      </PageWrap>
    </Page>
  );
};

export default People;
