import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { redirect } from 'redux-first-router';
import * as Sentry from "@sentry/react";
import get from 'lodash/get';

import config from '../../config';

import { timestampsExpired } from '../../helpers/tracking';

import {
  setUserRutterToken,
  createUserRutterConnection
} from '../../actions/user';

import { ButtonNew as Button } from '../../components/atoms/Button';
import { CSSGrid } from '../../components/atoms/Grid';
import Heading from '../../components/atoms/Heading';
import Paragraph from '../../components/atoms/Paragraph';
import Spacing from '../../components/atoms/Spacing';

import Slices from '../../components/molecules/Slices';

import Page from '../../components/utils/Page';

import AuthModal from './Modals/AuthModal';
import ErrorModal from './Modals/ErrorModal';

import Hero from './Hero';
import Timeline from './Timeline';

import generalStyles from './style/general.module.css';

const SESSION_EXPIRY_HOURS = 12;

const Connect = () => {
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isAuthModalVisible, setAuthModalVisible] = useState(false);
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location);
  const user = useSelector((state) => state.user);
  const {
    token: prevToken,
    timestamp: prevTokenTimestamp,
    connection
  } = useSelector((state) => state.user.rutter, shallowEqual);
  const pageData = useSelector((state) =>
    get(state.pages.bySlug['connect'], 'data', {})
  );
  const slices = pageData.body || [];

  const isConnected = connection.status === 'resolved';
  const isAuthenticated = !!user.token;
  const isIntegrator =
    isAuthenticated && user.extendedRoles?.includes(config.roles.INTEGRATOR);
  const isActiveSession = prevTokenTimestamp
    ? !timestampsExpired([prevTokenTimestamp], SESSION_EXPIRY_HOURS)
    : false;

  const token = get(location, 'query.public_token', null) || prevToken;

  const connectUser = useCallback(
    async (token) => {
      try {
        setErrorModalVisible(false);
        setAuthModalVisible(false);

        await dispatch(createUserRutterConnection(token));
      } catch (err) {
        setErrorModalVisible(true);

        Sentry.captureException(err);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    // Already connected, do nothing until the user has logged out
    if (isConnected) {
      return;
    }

    // New token received
    if (token && !prevToken) {
      dispatch(setUserRutterToken(token));
    }

    // Stored token has expired
    if (prevToken && !isActiveSession) {
      dispatch(setUserRutterToken(null));
    }
  }, [dispatch, token, prevToken, isActiveSession, isConnected]);

  return (
    <Page title="Connect">
      <div className="max-w-wrapped mdlg:mx-auto mdlg:pt-2">
        <div className={generalStyles.container}>
          <ErrorModal
            isOpen={isErrorModalVisible}
            onClose={() => setErrorModalVisible(false)}
          />
          <AuthModal
            isOpen={isAuthModalVisible}
            onClose={() => setAuthModalVisible(false)}
            onSuccess={
              token
                ? () => connectUser(token)
                : () => setAuthModalVisible(false)
            }
          />
          <Spacing size={[1, 1, 1, 1, 2]} position="t">
            <Hero />
          </Spacing>
          <Spacing size={[4, 4, 4, 4, 8]}>
            <CSSGrid
              className="w-full h-full"
              template="auto / 1fr"
              rowGap={[2, 2, 3]}
              justify="center"
              justifyContent="center"
              align="center"
              alignContent="center"
            >
              <div>
                <Heading tag="h2" size={['s', 's', 'm']} center>
                  {isConnected ? 'Connected' : 'Connect your store'}
                </Heading>
                <Paragraph
                  size={['xxs', 'xxs', 'xs']}
                  center
                  className="mt-1 uppercase"
                >
                  Sell your Everpress products through your ecommerce store
                </Paragraph>
              </div>
              {isConnected ? (
                <Button
                  kind="solid"
                  onClick={() =>
                    dispatch(
                      redirect({
                        type: isIntegrator
                          ? 'DASHBOARD_CONNECT'
                          : 'DASHBOARD_CONNECTIONS'
                      })
                    )
                  }
                >
                  Go to dashboard
                </Button>
              ) : (
                <Button
                  kind="solid"
                  onClick={
                    isAuthenticated
                      ? token
                        ? () => connectUser(token)
                        : () =>
                            dispatch(
                              redirect({ type: 'DASHBOARD_CONNECTIONS' })
                            )
                      : () => setAuthModalVisible(true)
                  }
                >
                  {isAuthenticated && !token ? 'Go to dashboard' : 'Connect'}
                </Button>
              )}
              <CSSGrid
                template={[
                  'auto / 1fr',
                  'auto / 1fr',
                  'auto / 65%',
                  'auto / 60%',
                  'auto / 55%'
                ]}
                gap={[1, 1, 15]}
                justify="center"
                justifyContent="center"
              >
                <Paragraph size="xs" center>
                  To get started, select Connect above. Once you’re linked up,
                  hit ‘Products’ in your Connection Management Dashboard to link
                  up your Everpress campaigns to your store.
                </Paragraph>
              </CSSGrid>
            </CSSGrid>
          </Spacing>

          <Spacing size={[6, 6, 6, 6, 10]}>
            <CSSGrid
              template={[
                'auto / 1fr',
                'auto / 1fr',
                'auto / 65%',
                'auto / 60%',
                'auto / 55%'
              ]}
              rowGap={[2, 2, 3]}
              justify="center"
              justifyContent="center"
            >
              <Heading tag="h3" size={['s', 's', 'm']} center>
                New here? Welcome to Everpress
              </Heading>
              <Paragraph size="xs" center>
                Everpress enables independent creators to effortlessly drop-ship
                merchandise, including t-shirts, hoodies, sweatshirts, tote
                bags, caps, art prints and more.{' '}
              </Paragraph>
              <Paragraph size="xs" center>
                Everpress handles everything behind-the-scenes, including
                sourcing stock, printing and embroidery, fulfillment to the
                buyer, and customer service. We can print each item as you sell
                it, or run kickstarter style pre-order campaigns.
              </Paragraph>
              <Paragraph size="xs" center>
                But we only make what you sell, meaning zero waste, zero risk,
                and a sustainable method of making merchandise.
              </Paragraph>
            </CSSGrid>
          </Spacing>

          <Spacing size={[6, 6, 6, 6, 10]}>
            <Spacing size={4}>
              <Heading tag="h3" size={['s', 's', 'm']} center>
                How it works
              </Heading>
            </Spacing>
            <Timeline />
          </Spacing>

          <Spacing size={[6, 6, 6, 6, 10]}>
            <div className={generalStyles.slices}>
              <Slices slices={slices} />
            </div>
          </Spacing>
        </div>
      </div>
    </Page>
  );
};

export default Connect;
