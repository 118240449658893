import { useState, useEffect } from 'react';

const useTouchDetect = () => {
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => {
    if ('maxTouchPoints' in navigator) {
      setIsTouch(navigator.maxTouchPoints > 0);
    } else if ('msMaxTouchPoints' in navigator) {
      setIsTouch(navigator.msMaxTouchPoints > 0);
    } else {
      const mediaQuery = window.matchMedia && matchMedia('(pointer:coarse)');
      if (mediaQuery && mediaQuery.media === '(pointer:coarse)') {
        setIsTouch(!!mediaQuery.matches);
      } else if ('orientation' in window) {
        setIsTouch(true); // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        const userAgent =
          typeof navigator === 'undefined' ? '' : navigator.userAgent;
        const hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgent) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(userAgent);
        setIsTouch(hasTouchScreen);
      }
    }
  }, []);

  return { isTouch };
};

export default useTouchDetect;
