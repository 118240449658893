import React from 'react';
import PropTypes from 'prop-types';
import addDays from 'date-fns/add_days';
import format from 'date-fns/format';

import { InlineGrid } from '../../../components/atoms/Grid';
import Icon from '../../../components/atoms/Icon';
import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';

import deliveryIcon from '../../../img/sprites/delivery.svg';

const CampaignShippingOnInfo = ({ campaign, ...props }) => {
  const { shipOn, length } = campaign;

  return (
    <InlineGrid justify="start" align="center" {...props}>
      <Spacing size="05" position="r">
        <Icon glyph={deliveryIcon} width={22} height={22} />
      </Spacing>
      <Paragraph color="darkest-dark-grey" size="xxs">
        <span className="text-black">
          Ships by{' '}
          {shipOn
            ? format(shipOn, 'D MMMM')
            : format(addDays(Date.now(), length + 12), 'D MMMM')}
        </span>{' '}
        to help reduce waste
      </Paragraph>
    </InlineGrid>
  );
};

export default CampaignShippingOnInfo;

CampaignShippingOnInfo.propTypes = {
  campaign: PropTypes.shape({
    shipOn: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    length: PropTypes.number
  }).isRequired
};
