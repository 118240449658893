import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {
  getCampaign,
  incrementStep2TimesViewed
} from '../../../actions/campaign-builder';

import Heading from '../../../components/atoms/Heading';
import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';

import Loading from '../../../components/utils/Loading';

import ProductPrices from './ProductPrices';
import SalesDuration from './SalesDuration';
import SalesGoal from './SalesGoal';
import ProfitEstimate from './ProfitEstimate';
import AdsNetwork from './AdsNetwork';

import generalStyles from './style/general.module.css';

const PriceCalculator = () => {
  const dispatch = useDispatch();
  const { campaignProducts, status } = useSelector(
    (state) => state.campaignBuilder,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getCampaign());
    dispatch(incrementStep2TimesViewed());
  }, [dispatch]);

  const isLoading = status === 'pending';

  if (isLoading || isEmpty(campaignProducts)) return <Loading />;

  return (
    <div className={generalStyles.wrapper}>
      <div className={generalStyles.container}>
        <Heading tag="h1" size="xl">
          Set prices and duration
        </Heading>
        <Spacing size={1} position="t">
          <Paragraph size="xxs">
            Choose prices for each of the garments, set a sales target, and edit
            the sales duration for the campaign.
          </Paragraph>
          <Paragraph size="xxs">
            Profit per item increases with the amount of garments sold.
          </Paragraph>
        </Spacing>

        <Spacing size={6}>
          <div className={generalStyles.grid}>
            <div className={generalStyles.col}>
              <div className={generalStyles.card}>
                <ProductPrices />
              </div>
              <div className={generalStyles.card}>
                <SalesDuration />
              </div>
              <div className={generalStyles.card}>
                <SalesGoal />
              </div>
            </div>

            <div className={generalStyles.col}>
              <div className={generalStyles.sticky}>
                <div className={generalStyles.card}>
                  <ProfitEstimate />
                </div>
                <div className={generalStyles.card}>
                  <AdsNetwork />
                </div>
              </div>
            </div>
          </div>
        </Spacing>
      </div>
    </div>
  );
};

export default PriceCalculator;
