import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import crossIcon from '../../../../img/sprites/round-cross.svg';

import Icon from '../../../atoms/Icon';
import Image from '../../../atoms/Image';

import generalStyles from './style/general.module.css';

const ModalNew = ({
  isOpen,
  children,
  handleClose,
  image,
  isCloseInverted
}) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '405px',
      maxHeight: '100vh',
      border: 'none',
      padding: '25px',
      borderRadius: '10px'
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.6)',
      zIndex: '1001'
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Modal"
      style={customStyles}
      shouldCloseOnOverlayClick
      onRequestClose={handleClose}
    >
      {image && (
        <div className={generalStyles.modalImage}>
          <Image
            src={image.url}
            alt={image.alt}
            width="850"
            height="522"
            withPlaceholder
          />
        </div>
      )}
      {handleClose && (
        <div>
          <button
            type="button"
            onClick={handleClose}
            className={classnames(generalStyles.closeContainer, {
              invert: isCloseInverted
            })}
          >
            <Icon glyph={crossIcon} width="14" height="14" />
          </button>
        </div>
      )}
      {children}
    </ReactModal>
  );
};

export default ModalNew;

ModalNew.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  children: PropTypes.node,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }),
  isInvertClose: PropTypes.bool
};
