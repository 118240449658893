import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { redirect } from 'redux-first-router';
import {
  useStripe,
  useElements,
  ExpressCheckoutElement
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import { convertToSubunit, getResponseValue } from '../../../../helpers/stripe';

import {
  getCheckoutDetails,
  editCheckoutDetails,
  createStripePaymentIntent,
  confirmStripePaymentIntent,
  logStripePaymentRequestError
} from '../../../../actions/checkout';

import Alert from '../../../../components/atoms/Alert';
import Spacing from '../../../../components/atoms/Spacing';

const options = {
  wallets: {
    applePay: 'auto',
    googlePay: 'always'
  }
};

const StripeExpressCheckout = ({
  amount,
  checkoutToken,
  onShippingAddressChange,
  onShippingRateChange,
  onConfirm
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [shippingCountry, setShippingCountry] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();

  const handleClick = async ({ resolve }) => {
    setErrorMessage(null);

    const options = {
      emailRequired: true,
      shippingAddressRequired: true,
      shippingRates: [
        {
          id: 'untracked',
          displayName: 'Untracked',
          amount: 0
        },
        {
          id: 'tracked',
          displayName: 'Tracked',
          amount: 0
        }
      ]
    };

    // Reset any shipping charges added in the previous session
    elements.update({
      amount: convertToSubunit(amount)
    });

    // resolve() must be called within 1 second
    resolve(options);
  };

  const handleShippingAddressChange = async ({ resolve, address }) => {
    if (!shippingCountry) {
      // eslint-disable-next-line
      const [_, checkoutErr] = await getResponseValue(
        dispatch(getCheckoutDetails(checkoutToken))
      );

      setShippingCountry(address.country);

      if (checkoutErr) {
        logStripePaymentRequestError(
          'Error fetching checkout details',
          checkoutErr
        );

        return;
      }
    }

    const payload = {
      deliveryAddress: {
        country: address.country
      }
    };

    const [checkout, checkoutErr] = await getResponseValue(
      dispatch(editCheckoutDetails(payload))
    );

    if (checkoutErr) {
      logStripePaymentRequestError(
        'Error updating checkout details',
        checkoutErr
      );

      return;
    }

    const { deliveryEstimates } = checkout;

    const options = {
      shippingRates: [
        {
          id: 'untracked',
          displayName: 'Untracked',
          amount: convertToSubunit(deliveryEstimates?.untracked)
        },
        {
          id: 'tracked',
          displayName: 'Tracked',
          amount: convertToSubunit(deliveryEstimates?.tracked)
        }
      ]
    };

    setShippingCountry(address.country);

    elements.update({
      amount:
        convertToSubunit(amount) +
        convertToSubunit(deliveryEstimates?.untracked)
    });

    onShippingAddressChange?.(address);

    resolve(options);
  };

  const handleShippingRateChange = async ({ resolve, shippingRate }) => {
    const { id, amount: shippingAmount } = shippingRate;

    const payload = {
      tracked: id === 'tracked'
    };

    // eslint-disable-next-line
    const [_, checkoutErr] = await getResponseValue(
      dispatch(editCheckoutDetails(payload))
    );

    if (checkoutErr) {
      logStripePaymentRequestError(
        'Error updating checkout details',
        checkoutErr
      );

      return;
    }

    elements.update({
      amount: convertToSubunit(amount) + shippingAmount
    });

    onShippingRateChange?.(shippingRate);

    resolve();
  };

  const handlePaymentConfirm = async (event) => {
    // Stripe.js hasn't loaded yet
    if (!stripe) return;

    const { shippingRate, billingDetails } = event;
    const { name, address } = event.shippingAddress;

    // User clicked pay before we've finished fetching shipping prices
    if (!shippingRate.amount) {
      setErrorMessage(
        'Something is not right, please use a different payment method'
      );

      return;
    }

    const { error: submitError } = await elements.submit();

    if (submitError) {
      setErrorMessage(submitError.message);

      return;
    }

    const {
      error: createPaymentMenthodError,
      paymentMethod
    } = await stripe.createPaymentMethod({
      elements
    });

    if (createPaymentMenthodError) {
      setErrorMessage(createPaymentMenthodError.message);

      logStripePaymentRequestError(
        'Error creating payment method',
        createPaymentMenthodError
      );

      return;
    }

    const splitName = name.split(/(\s+)/);
    const paymentData = {
      email: billingDetails.email,
      paymentMethodId: paymentMethod.id,
      deliveryAddress: {
        firstName: splitName[0],
        lastName: splitName[2] || 'null', // Get around our api validation
        line1: address.line1,
        line2: address.line2 || '',
        city: address.city,
        county: address.state,
        country: address.country,
        postcode: address.postal_code
      },
      tracked: shippingRate.id === 'tracked'
    };

    const [paymentIntent, paymentIntentErr] = await getResponseValue(
      dispatch(createStripePaymentIntent(paymentData))
    );

    if (paymentIntentErr) return;

    const { clientSecret, id: paymentIntentId } = paymentIntent;

    const { error: confirmPaymentError } = await stripe.confirmPayment({
      clientSecret,
      redirect: 'if_required'
    });

    if (confirmPaymentError) {
      setErrorMessage(confirmPaymentError.message);

      logStripePaymentRequestError(
        'Error confirming payment',
        confirmPaymentError
      );

      return;
    }

    // Payment UI closes

    let [order, orderErr] = await getResponseValue(
      dispatch(confirmStripePaymentIntent({ paymentIntentId }))
    );

    if (orderErr) return;

    onConfirm?.(order);

    const { token } = order;

    dispatch(
      redirect({
        type: 'ORDER',
        payload: { token }
      })
    );
  };

  return (
    <>
      <ExpressCheckoutElement
        options={options}
        onClick={handleClick}
        onShippingAddressChange={handleShippingAddressChange}
        onShippingRateChange={handleShippingRateChange}
        onConfirm={handlePaymentConfirm}
      />
      {errorMessage && (
        <Spacing size={1}>
          <Alert kind="error">{errorMessage}</Alert>
        </Spacing>
      )}
    </>
  );
};

StripeExpressCheckout.propTypes = {
  amount: PropTypes.number.isRequired,
  checkoutToken: PropTypes.string.isRequired,
  onShippingAddressChange: PropTypes.func,
  onShippingRateChange: PropTypes.func,
  onConfirm: PropTypes.func
};

export default StripeExpressCheckout;
