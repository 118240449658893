import React, { Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import groupBy from 'lodash/groupBy';

import { integratorMenuItems } from '../menuItemsConfig';

import { logout } from '../../../../../actions/user';

import { ButtonNew as Button } from '../../../../atoms/Button';
import Icon from '../../../../atoms/Icon';
import Link from '../../../../atoms/Link';
import { TooltipNew } from '../../../../atoms/Tooltip';
import PageWrap from '../../../../atoms/PageWrap/PageWrapOld';
import Paragraph from '../../../../atoms/Paragraph';

import linkStyles from '../Navigation/style/link.module.css';
import generalStyles from '../Navigation/style/general.module.css';

import everpressLogo from '../../../../../img/everpress-logo.svg';
import crossIcon from '../../../../../img/sprites/round-cross.svg';
import menuIcon from '../../../../../img/sprites/round-menu.svg';

const groupedIntegratorMenuItems = groupBy(integratorMenuItems, 'group');

const NavigationMinimal = ({ isMenuOpen, setIsMenuOpen }) => {
  const dispatch = useDispatch();
  const navRef = useRef();
  const token = useSelector((state) => state.user.token);
  const extendedRoles = useSelector((state) => state.user.extendedRoles);
  const isMinimalNavigation = useSelector(
    (state) => state.headerNew.navigation.isMinimalNavigation
  );

  const isUserCreator = extendedRoles.includes('creator');

  const handleLogoutUser = () => {
    dispatch(logout());
    setIsMenuOpen(false);
  };

  const handleAccountItemClick = (itemClicked, slug) => {
    dispatch({
      type: 'ACCOUNT_ITEM_CLICK',
      payload: {
        itemClicked,
        slug
      }
    });
  };

  const accountDropdownContent = (
    <ul className="my-05">
      {Object.keys(groupedIntegratorMenuItems).map((key) => {
        const items = groupedIntegratorMenuItems[key];
        return (
          <Fragment key={key}>
            {items.map(
              (item, childIndex) =>
                (isUserCreator || (!isUserCreator && !item.isCreator)) && (
                  <Fragment key={item.title}>
                    <li
                      className="flex flex-1"
                      key={item.to}
                      onClick={() =>
                        handleAccountItemClick(item.title, item.to)
                      }
                    >
                      <Link
                        className={linkStyles.link}
                        isUnderlined={false}
                        noHover
                        to={item.to}
                      >
                        {item.title}
                      </Link>
                    </li>
                    {childIndex === items.length - 1 && (
                      <li className="border-b-1 border-grey-lighter my-15 w-85 mx-auto" />
                    )}
                  </Fragment>
                )
            )}
          </Fragment>
        );
      })}
      <li className="block w-full">
        <button className={linkStyles.link} onClick={handleLogoutUser}>
          Sign out
        </button>
      </li>
    </ul>
  );

  return (
    <>
      <nav ref={navRef} className={generalStyles.nav}>
        <div className={generalStyles.navWrapper}>
          <PageWrap>
            <div className="flex h-6 mdlg:items-center">
              <Link to="/dashboard/connect" className="mdlg:mr-15 my-auto">
                <img
                  src={everpressLogo}
                  alt="Everpress logo"
                  width="30"
                  className={generalStyles.navLogo}
                />
              </Link>
              {/*desktop menu*/}
              {!isMinimalNavigation && (
                <div className={generalStyles.menuContainer}>
                  <div />
                  {token ? (
                    <div className="inline-block">
                      <TooltipNew
                        content={accountDropdownContent}
                        placement="bottom"
                        offset={7}
                        align="start"
                        alignOffset={-10}
                        width={180}
                        id="account-dropdown"
                        hasListContent
                      >
                        <Button
                          size="small"
                          kind="ghost"
                          className="rounded-md"
                          to="/dashboard"
                        >
                          Account
                        </Button>
                      </TooltipNew>
                    </div>
                  ) : (
                    <div className="inline-block">
                      <Button size="small" kind="ghost" to="/login">
                        Sign in
                      </Button>
                    </div>
                  )}
                </div>
              )}
              {/*end of desktop menu*/}
              {/*icon menu*/}
              <ul className="ml-auto flex items-center">
                {!isMinimalNavigation && (
                  <li
                    className={`${generalStyles.navIconListItem} mdlg:hidden`}
                  >
                    <button
                      onClick={() => setIsMenuOpen(!isMenuOpen)}
                      className="p-1 pr-0"
                    >
                      {isMenuOpen ? (
                        <Icon glyph={crossIcon} width="24" height="24" />
                      ) : (
                        <Icon glyph={menuIcon} width="24" height="24" />
                      )}
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </PageWrap>
        </div>
      </nav>
      {/*mobile nav*/}
      {!isMinimalNavigation && (
        <div
          className={classNames(generalStyles.menuContainerMobile, {
            hidden: !isMenuOpen
          })}
        >
          <div className="mt-2" />
          {token ? (
            <>
              <Paragraph
                size="xxs"
                color="grey-primary"
                className="mb-05 px-15"
              >
                Account
              </Paragraph>
              <ul className="flex flex-col mb-25">
                {integratorMenuItems.map((item) => (
                  <li
                    className={generalStyles.navListItemMobile}
                    key={item.to}
                    onClick={() => handleAccountItemClick(item.title, item.to)}
                  >
                    <Link
                      to={item.to}
                      className={generalStyles.navListItemMobileLink}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
                <div className="m-15">
                  <Button kind="outline" onClick={handleLogoutUser} fullWidth>
                    Sign out
                  </Button>
                </div>
              </ul>
            </>
          ) : (
            <div className="flex">
              <Button to="/login" fullWidth className="mx-15 my-1">
                Sign in
              </Button>
            </div>
          )}
        </div>
      )}
      {/*  mobile nav end*/}
    </>
  );
};

export default NavigationMinimal;
