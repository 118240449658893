import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { claimCreatorReward } from '../../actions/user';

import Alert from '../../components/atoms/Alert';
import { ButtonNew as Button } from '../../components/atoms/Button';
import Heading from '../../components/atoms/Heading';
import {
  DefaultField as Field,
  Form,
  handleFormError
} from '../../components/atoms/Form';
import { Grid, GridItem } from '../../components/atoms/Grid';
import MaxWidth from '../../components/atoms/MaxWidth';
import Page from '../../components/utils/Page';
import PageWrap from '../../components/atoms/PageWrap';
import Spacing from '../../components/atoms/Spacing';

import { ModalNew as Modal } from '../../components/molecules/Modal';
import AuthenticationContainer from '../../components/molecules/AuthenticationContainer';

const CreatorRewards = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => !!state.user.token);
  const [isModalVisible, setIsModalVisible] = useState(() => !isAuthenticated);

  const handleAuthenticationSuccess = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async (formData, actions) => {
    if (!isAuthenticated) {
      handleFormError({ message: 'Please sign in to continue' }, actions);
      return actions.setSubmitting(false);
    }

    try {
      await dispatch(claimCreatorReward(formData));
    } catch (error) {
      handleFormError(
        { message: 'Unable to redeem the offer, please try again later' },
        actions
      );
      return actions.setSubmitting(false);
    }

    dispatch({ type: 'CREATOR_REWARDS_THANKS' });
  };

  return (
    <Page title="Creator Rewards">
      <PageWrap>
        <Modal isOpen={isModalVisible}>
          <AuthenticationContainer
            initialUserStage="signIn"
            onSuccess={handleAuthenticationSuccess}
          />
        </Modal>
        <MaxWidth value={415} center>
          <Spacing position="t" type="padding">
            <Heading size={['s', 'm', 'm']} tag="h2">
              Enter your code below to redeem your bonus profit offer
            </Heading>
          </Spacing>
          <Spacing size={2} position="t" type="padding">
            <Form
              enableReinitialize
              initialValues={{
                firstName: '',
                lastName: '',
                code: ''
              }}
              validationSchema={yup.object().shape({
                firstName: yup.string().required().max(150),
                lastName: yup.string().required().max(150),
                code: yup.string().required().max(150)
              })}
              onSubmit={handleSubmit}
              render={({ isSubmitting, handleSubmit, status }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    {status?.error && (
                      <Spacing size={2} position="b">
                        <Alert>{status.error}</Alert>
                      </Spacing>
                    )}
                    <Spacing size={1}>
                      <Field name="firstName" placeholder="First name" />
                    </Spacing>
                    <Spacing size={1}>
                      <Field name="lastName" placeholder="Last name" />
                    </Spacing>
                    <Spacing size={1}>
                      <Field name="code" placeholder="Code" />
                    </Spacing>
                    <Spacing size={2} position="t">
                      <Grid gap={0} row="flex-col" align="end">
                        <GridItem columnSize={[12, 4, 4]}>
                          <Button
                            fullWidth
                            type="submit"
                            state={
                              !isAuthenticated
                                ? 'disabled'
                                : isSubmitting
                                ? 'loading'
                                : undefined
                            }
                          >
                            Next
                          </Button>
                        </GridItem>
                      </Grid>
                    </Spacing>
                  </form>
                );
              }}
            />
          </Spacing>
        </MaxWidth>
      </PageWrap>
    </Page>
  );
};

export default CreatorRewards;
