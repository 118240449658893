import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import groupBy from 'lodash/groupBy';
import { redirect } from 'redux-first-router';

import Page from '../../components/utils/Page';

import Heading from '../../components/atoms/Heading';
import PageWrap from '../../components/atoms/PageWrap';
import Spacing from '../../components/atoms/Spacing';
import { TabsList, Tab } from '../../components/atoms/Tabs';
import { Grid, GridItem } from '../../components/atoms/Grid';

import GarmentThumbnail from '../../components/molecules/Thumbnails/GarmentThumbnail';
import GarmentModal from './GarmentModal';

class Garments extends PureComponent {
  onModalClose() {
    this.props.dispatch(
      redirect({
        type: this.props.location.type,
        payload: { category: this.props.location.payload.category }
      })
    );
  }

  render() {
    const { location, garments, category, garmentId } = this.props;
    const categories = Object.keys(
      groupBy(garments, garment => garment.category.toLowerCase())
    );
    const currentGarments =
      category === 'all'
        ? garments
        : garments.filter(
            garment => garment.category.toLowerCase() === category
          );
    // Allow type coercion to handle numeric garment SKUs
    // eslint-disable-next-line
    const selectedGarment = garments.find(garment => garment.sku == garmentId);

    if (
      garments.length &&
      ((category !== 'all' &&
        !categories.find(_category => _category === category)) ||
        (garmentId && !selectedGarment))
    ) {
      return 'not found!';
    }

    return (
      <Page title="Our Garments">
        <PageWrap>
          <GarmentModal
            isOpen={!!garmentId}
            item={selectedGarment}
            onClose={this.onModalClose.bind(this)}
          />
          <Spacing type="padding" size={7}>
            <Heading size={['m', 'l', 'xl', 'xxl']} tag="h1">
              Our range of garments
            </Heading>
          </Spacing>

          <Grid gap={2} align="start">
            <GridItem columnSize={[12, 2]}>
              <TabsList direction="vertical">
                <Spacing size={'05'}>
                  <Tab to={{ type: location.type }} key="all" exact>
                    All
                  </Tab>
                </Spacing>
                {categories.map(category => (
                  <div style={{ margin: '3px 0 3px 0' }} key={category}>
                    <Tab
                      to={{
                        type: location.type,
                        payload: { category: category.toLowerCase() }
                      }}
                    >
                      {category}
                    </Tab>
                  </div>
                ))}
              </TabsList>
            </GridItem>
            <GridItem columnSize={[12, 10]}>
              <Grid gap={2}>
                {currentGarments.map(item => (
                  <GridItem key={item.sku} columnSize={[12, 6, 4]}>
                    <Spacing position="b">
                      <GarmentThumbnail
                        to={{
                          type: location.type,
                          payload: {
                            category: category.toLowerCase(),
                            garmentId: item.sku
                          }
                        }}
                        title={item.name}
                        tag={item.sku}
                        image={item.imageUrl}
                      />
                    </Spacing>
                  </GridItem>
                ))}
              </Grid>
            </GridItem>
          </Grid>
        </PageWrap>
      </Page>
    );
  }
}

const mapStateToProps = ({ location, garments }) => ({
  location,
  garments: garments.items,
  category: location.payload.category || 'all',
  garmentId: location.payload.garmentId
});

export default connect(mapStateToProps)(Garments);
