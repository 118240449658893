import React from 'react';
import PropTypes from 'prop-types';

import urlRegex from '../../../../helpers/urlRegex';

import { ButtonNew as Button } from '../../../../components/atoms/Button';
import Heading from '../../../../components/atoms/Heading';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';

import WrapIf from '../../../../components/utils/WrapIf';

import generalStyles from './style/general.module.css';

const getIsValidButton = (button) =>
  Object.values(button).every((val) => !!val);

const getIsExternalButton = (button) => {
  const { slug } = button;

  return (
    typeof slug === 'string' &&
    (slug.match(urlRegex) ||
      ['/create', '/blog'].some((substr) => slug.includes(substr)))
  );
};

const Cta = ({ title, copy, button1, button2 }) => {
  const isValidButton1 = getIsValidButton(button1);
  const isValidButton2 = getIsValidButton(button2);

  return (
    <div className={generalStyles.container}>
      <Heading size={['m', 'm', 'l']} tag="h2">
        {title}
      </Heading>
      <Spacing size={15} position="t">
        <Paragraph size="xs">{copy}</Paragraph>
      </Spacing>
      <WrapIf
        condition={isValidButton1 || isValidButton2}
        wrapper={(children) => (
          <Spacing size={[4, 4, 6]} position="t">
            <div className={generalStyles.cta}>{children}</div>
          </Spacing>
        )}
      >
        {isValidButton1 && (
          <Button to={button1.slug} isExternal={getIsExternalButton(button1)}>
            {button1.label}
          </Button>
        )}
        {isValidButton2 && (
          <Button to={button2.slug} isExternal={getIsExternalButton(button2)}>
            {button2.label}
          </Button>
        )}
      </WrapIf>
    </div>
  );
};

Cta.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  button1: PropTypes.shape({
    label: PropTypes.string,
    slug: PropTypes.string
  }),
  button2: PropTypes.shape({
    label: PropTypes.string,
    slug: PropTypes.string
  })
};

export default Cta;
