import React, { useState, useCallback } from 'react';

import { ButtonNew as Button } from '../../../components/atoms/Button';
import Spacing from '../../../components/atoms/Spacing';

const ReadMoreContent = ({ children, textClassName, textStyle }) => {
  return (
    <p className={textClassName} style={{ display: 'inline', ...textStyle }}>
      {children}
    </p>
  );
};

const ReadMoreArea = ({
  children,
  className,
  style,
  expandLabel = 'Read more',
  collapseLabel = 'Read less',
  textClassName,
  textStyle,
  lettersLimit,
  wordsLimit = 52
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleShowMore = useCallback(() => setExpanded(true), []);
  const handleShowLess = useCallback(() => setExpanded(false), []);

  const text = String(children);
  const splitText = text.split(' ');

  const isExpandable =
    splitText.length > wordsLimit || text.length > lettersLimit;

  const textExpander = expanded
    ? children
    : wordsLimit
    ? splitText.slice(0, wordsLimit - 1).join(' ')
    : lettersLimit
    ? text.substring(0, lettersLimit)
    : text;

  return (
    <div className={className} style={{ display: 'block', ...style }}>
      <ReadMoreContent textClassName={textClassName} textStyle={textStyle}>
        {textExpander}{(isExpandable && !expanded) && '...'}
      </ReadMoreContent>

      {isExpandable && (
        <Spacing size={1} position="t">
          <Button
            kind="outline"
            size="tiny"
            onClick={expanded ? handleShowLess : handleShowMore}
          >
            {expanded ? collapseLabel : expandLabel}
          </Button>
        </Spacing>
      )}
    </div>
  );
};

export default ReadMoreArea;
