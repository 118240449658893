export const getFirstErrorWithCode = (errorRequest) => {
  let err = {
    code: null,
    message: 'There was an error, please try again later.'
  };

  if (errorRequest?.response?.data?.length) {
    const errorResponse = errorRequest.response.data[0];
    if (errorResponse.hasOwnProperty('message')) {
      err.message = errorResponse.message;
    }
    if (errorResponse.hasOwnProperty('code')) {
      err.code = errorResponse.code;
    }
  }

  return err;
};

export const getCustomErrors = (errorRequest, errorCodes = []) => {
  const errors =
    // eslint-disable-next-line
    errorRequest?.response?.data?.map((err) => {
      if (errorCodes.includes(err.code)) {
        return {
          code: err.code,
          message: err.message
        };
      }
    }) || [];

  return errors;
};

export const getResponseValue = (request) => {
  return request
    .then((data) => [data.value, undefined])
    .catch((err) => [undefined, err]);
};
