import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';

import { getDistanceToNowInWords } from '../../../../helpers/dates';

import { ButtonNew as Button } from '../../../../components/atoms/Button';
import Heading from '../../../../components/atoms/Heading';
import Image from '../../../../components/atoms/Image';
import {
  InlineGrid,
  CSSGrid,
  CSSGridItem
} from '../../../../components/atoms/Grid';
import Link from '../../../../components/atoms/Link';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';

import WrapIf from '../../../../components/utils/WrapIf';

import generalStyles from './style/general.module.css';

const POST_LIMIT = 4;

const gridTemplate = {
  rows: `repeat(${POST_LIMIT}, 1fr), 1fr`,
  grid: `auto / repeat(2, 1fr)`,
  cols: `1fr / repeat(auto-fit, minmax(125px, 1fr))`
};

const FeaturedBlogPosts = ({ data = {} }) => {
  const dispatch = useDispatch();
  const { title, subtitle, blog_posts } = data;
  const truncatedPosts = compact(blog_posts).slice(0, POST_LIMIT);

  if (isEmpty(data)) return null;

  return (
    <>
      <InlineGrid justify="space-between">
        <Spacing size={2} position="r">
          {title ? (
            <Heading size="s" tag="h2">
              {title}
            </Heading>
          ) : null}
          {subtitle ? (
            <Spacing size={1} position="t">
              <Paragraph size="xxs" color="grey-dark">
                {subtitle}
              </Paragraph>
            </Spacing>
          ) : null}
        </Spacing>
        <div className="hidden md:block">
          <Button
            to="https://everpress.com/blog"
            kind="outline"
            size="tiny"
            onClick={() => {
              dispatch({
                type: 'FEATURED_BLOG_POSTS_CLICK',
                payload: {
                  pathname: '/blog'
                }
              });
            }}
            isExternal
          >
            View more
          </Button>
        </div>
      </InlineGrid>
      <Spacing size={25} position="t">
        <CSSGrid
          gap={2}
          template={[
            gridTemplate.rows,
            gridTemplate.rows,
            gridTemplate.grid,
            gridTemplate.grid,
            gridTemplate.cols
          ]}
          className={generalStyles.blogPostGrid}
        >
          {truncatedPosts?.map((item) => {
            const distanceToNowInWords = item.blog_post_date
              ? getDistanceToNowInWords(item.blog_post_date)
              : null;

            return (
              <div key={item.blog_post_title}>
                <WrapIf
                  condition={!!item.slug}
                  wrapper={(children) => (
                    <div
                      onClick={() =>
                        dispatch({
                          type: 'FEATURED_BLOG_POSTS_CLICK',
                          payload: {
                            pathname: `/blog/${item.slug}`
                          }
                        })
                      }
                    >
                      <Link
                        to={`https://everpress.com/blog/${item.slug}`}
                        className={generalStyles.link}
                        target="_self"
                      >
                        {children}
                      </Link>
                    </div>
                  )}
                >
                  <CSSGrid gap={15} template="1fr / 30% 1fr">
                    <CSSGridItem
                      row={{ start: 1, end: 2 }}
                      column={{ start: 1, end: 2 }}
                    >
                      <Image
                        src={item.image?.url}
                        alt={item.image?.alt}
                        width="100%"
                        className="contents"
                        imgClassName="rounded-md"
                      />
                    </CSSGridItem>
                    <CSSGridItem
                      row={{ start: 1, end: 2 }}
                      column={{ start: 2, end: 3 }}
                      className="flex flex-col justify-between"
                    >
                      <div>
                        <Spacing size={1} type="padding">
                          <Heading tag="h4" size="xs">
                            {item.blog_post_title}
                          </Heading>
                        </Spacing>
                        {item.blog_post_description ? (
                          <Paragraph
                            size="xxs"
                            className={generalStyles.lineClamp}
                          >
                            {item.blog_post_description}
                          </Paragraph>
                        ) : null}
                      </div>
                      <Spacing size={1} type="padding">
                        <Paragraph color="grey-dark" size="xxs">
                          {item.blog_post_category}
                          {distanceToNowInWords
                            ? ` — ${distanceToNowInWords}`
                            : null}
                        </Paragraph>
                      </Spacing>
                    </CSSGridItem>
                  </CSSGrid>
                </WrapIf>
              </div>
            );
          })}
        </CSSGrid>
      </Spacing>
    </>
  );
};

export default FeaturedBlogPosts;

FeaturedBlogPosts.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    blog_posts: PropTypes.arrayOf(
      PropTypes.shape({
        blog_post_category: PropTypes.string,
        blog_post_date: PropTypes.string,
        blog_post_title: PropTypes.string,
        blog_post_description: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string
        }),
        slug: PropTypes.string
      })
    )
  }).isRequired
};
