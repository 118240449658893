import React, { useState, Children } from 'react';
import PropTypes from 'prop-types';

import { AccordionContext, AccordionElement } from '../index';

const AccordionManager = ({ multiple, children }) => {
  const [activeIndexes, setActiveIndexes] = useState([]);

  const toggleActive = (index) => {
    let newActiveIndexes = [...activeIndexes];
    const indexPosition = activeIndexes.indexOf(index);
    if (indexPosition >= 0) {
      newActiveIndexes.splice(indexPosition, 1);
    } else if (multiple) {
      newActiveIndexes.push(index);
    } else {
      newActiveIndexes = [index];
    }
    setActiveIndexes(newActiveIndexes);
  };

  return Children.map(children, (child, index) => {
    if (child.type === AccordionElement)
      return (
        <AccordionContext.Provider
          value={{ activeIndexes, index, toggleActive }}
        >
          {child}
        </AccordionContext.Provider>
      );
    else return child;
  });
};

AccordionManager.propTypes = {
  multiple: PropTypes.bool,
  children: PropTypes.node
};

export default AccordionManager;
