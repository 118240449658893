import image1 from '../../img/pages/how-it-works/buyers-1.jpg';
import image2 from '../../img/pages/how-it-works/buyers-2.jpg';
import image3 from '../../img/pages/how-it-works/buyers-3.jpg';

const buyersItems = [
  {
    id: 0,
    title: 'Choose your product',
    copy: 'Browse our marketplace to find your favourite designs by independent creators.',
    image: image1
  },
  {
    id: 1,
    title: 'We make the product when the campaign ends',
    copy:
      'We support creators in the production of garments. Once the campaign ends, we’ll get to work. This way we’re only printing what’s needed.',
    image: image2
  },
  {
    id: 2,
    title: 'We ship direct to you',
    copy:
      'Keep your eyes on the post, as soon as we’re done printing we’ll ship your item direct to you.',
    image: image3
  }
];

export default buyersItems;
