import PropTypes from 'prop-types';

import useCountdown from '../../../../helpers/hooks/useCountdown';

const Timer = ({ deadline }) => {
  const { hours, minutes, seconds } = useCountdown(deadline);

  return `${hours}h ${minutes}m ${seconds}s`;
};

export default Timer;

Timer.propTypes = {
  deadline: PropTypes.instanceOf(Date).isRequired
};
