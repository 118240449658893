import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import generalStyles from './style/general.module.css';
import kindStyles from './style/kind.module.css';
import sizeStyles from './style/size.module.css';

const cx = classNames.bind({ ...generalStyles, ...kindStyles, ...sizeStyles });

const LoadingSpinner = ({ kind, size, color, className, ...props }) => {
  return (
    <svg
      className={cx('loading-spinner', size, className)}
      width="21px"
      height="21px"
      viewBox="0 0 21 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>loading</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-146.000000, -613.000000)" strokeWidth="3">
          <g transform="translate(50.000000, 142.000000)">
            <g transform="translate(50.000000, 98.000000)">
              <g transform="translate(0.000000, 360.000000)">
                <g transform="translate(48.000000, 15.000000)">
                  <circle
                    className={cx('circle', kind, color)}
                    cx="8.5"
                    cy="8.5"
                    r="8.5"
                  />
                  <path
                    className={cx('path', kind, color)}
                    d="M5.73750542,0.458982079 C2.39917587,1.6056157 0,4.77262908 0,8.5 C0,13.1944204 3.80557963,17 8.5,17 C12.5109551,17 15.8730434,14.2218706 16.7670037,10.4848729"
                    strokeLinecap="round"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

LoadingSpinner.propTypes = {
  kind: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default LoadingSpinner;
