import * as React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

MaxWidth.propTypes = {
  children: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  center: PropTypes.bool
};

export default function MaxWidth({ children, value, center }) {
  const maxWidthStyles = {
    maxWidth: typeof value === 'number' ? `${value}px` : value
  };
  const maxWidthClassName = classNames('w-100', { 'mx-auto': center });

  const maxWidthProps = {
    style: maxWidthStyles,
    className: maxWidthClassName
  };

  return <div {...maxWidthProps}>{children}</div>;
}

MaxWidth.defaultProps = {
  center: false
};
