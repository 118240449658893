import React from 'react';
import classNames from 'classnames';

export default function Icon({ glyph, className, ...props }) {
  return (
    <svg
      className={classNames(className, {
        'fill-current': !props.fill
      })}
      {...props}
    >
      <use xlinkHref={`#${glyph.id}`} />
    </svg>
  );
}
