import * as React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import RouterLink, { NavLink } from 'redux-first-router-link';

import urlRegex from '../../../helpers/urlRegex';

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  href: PropTypes.string,
  isNav: PropTypes.bool,
  isLight: PropTypes.bool,
  isUnderlined: PropTypes.bool,
  noHover: PropTypes.bool,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool
};

const externalEverpressLinks = ['/blog', '/create'];

export default function Link({
  children,
  to,
  href,
  isNav,
  isUnderlined,
  onClick,
  className,
  isLight,
  noHover,
  isDisabled,
  ...props
}) {
  const isExternal =
    typeof to === 'string' &&
    (to.match(urlRegex) || externalEverpressLinks.includes(to));
  const linkClassName = classNames(className, {
    underline: !isNav && isUnderlined,
    uppercase: isNav,
    'text-white': isLight,
    'hover:underline focus:underline': !noHover && (isNav || !isUnderlined),
    'hover:no-underline focus:underline': !noHover && !isNav && isUnderlined,
    'focus-visible:border-blue-primary': !isNav && isUnderlined,
    'focus-visible:border-2': !isNav && isUnderlined,
    'focus-visible:rounded-sm': !isNav && isUnderlined,
    'opacity-50': isDisabled
  });
  let LinkTag = isNav ? NavLink : RouterLink;
  let linkProps = { className: linkClassName };
  // TODO: Remove this hack for builder
  const builderLink = /\/create|\/campaign\/edit/;
  if (isExternal || href || builderLink.test(to)) {
    LinkTag = 'a';
  }

  if (onClick && !to) {
    LinkTag = 'button';
  }

  const handleClick = () => {
    onClick();
  };

  if (isExternal) {
    linkProps = {
      ...linkProps,
      href: to,
      ...(!externalEverpressLinks.includes(to) && {
        target: '_blank',
        rel: 'noopener noreferrer'
      })
    };
  } else if (onClick) {
    linkProps = {
      ...linkProps,
      type: 'button',
      onClick: handleClick,
      disabled: isDisabled
    };
  } else if (href || builderLink.test(to)) {
    // TODO: Remove this hack for /create
    linkProps = {
      ...linkProps,
      href: builderLink.test(to) ? to : href // TODO: Remove this hack for /create
    };
  } else {
    linkProps = {
      ...linkProps,
      to: to
    };
  }

  return (
    <LinkTag {...linkProps} {...props}>
      {children}
    </LinkTag>
  );
}

Link.defaultProps = {
  isNav: false,
  isUnderlined: true,
  isLight: false,
  noHover: false,
  onClick: null
};
