import React from 'react';

import { Grid, GridItem } from '../../../atoms/Grid';
import Heading from '../../../atoms/Heading';
import MaxWidth from '../../../atoms/MaxWidth';
import PageWrap from '../../../atoms/PageWrap';
import Spacing from '../../../atoms/Spacing';

import generalStyles from './style/general.module.css';

const Numbers = ({ title, numbers }) => {
  return (
    <PageWrap>
      <Spacing size={[2, 4, 6]} type="padding">
        <MaxWidth center value={876}>
          <div className={generalStyles.numbers}>
            <Grid gap={2} align="stretch">
              <GridItem columnSize={[12, 6]}>
                <MaxWidth value={140}>
                  <Spacing size={[2, 0, 0]} position="b" type="padding">
                    <Heading tag="h3" size="m" color="white">
                      {title}
                    </Heading>
                  </Spacing>
                </MaxWidth>
              </GridItem>
              <GridItem columnSize={[12, 6]}>
                {numbers.map((item, i) => (
                  <div className={generalStyles.number} key={i}>
                    <Heading
                      tag="h2"
                      size={['m', 'l', 'xl', 'xxl']}
                      color="white"
                    >
                      {item.digits}
                    </Heading>
                    <Heading tag="h5" size={'xxs'} color="white">
                      {item.label}
                    </Heading>
                  </div>
                ))}
              </GridItem>
            </Grid>
          </div>
        </MaxWidth>
      </Spacing>
    </PageWrap>
  );
};

export default Numbers;
