import React from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';

import Heading from '../../../atoms/Heading';
import Icon from '../../../atoms/Icon';
import Paragraph from '../../../atoms/Paragraph';
import Spacing from '../../../atoms/Spacing';
import Price from '../../../atoms/Price';
import CampaignTimeLeft from '../../../atoms/CampaignTimeLeft';
import Image from '../../../atoms/Image';

import config from '../../../../config';
import { theme } from '../../../../theme-config';

import generalStyles from './style/general.module.css';

import crossIcon from '../../../../img/sprites/round-cross.svg';

const FavouriteThumbnail = ({
  image,
  title,
  profileName,
  endAt,
  slug,
  alt = title,
  prices,
  onDelete
}) => {
  return (
    <div className={generalStyles.container}>
      <Link
        to={`/${slug}`}
        className={generalStyles.link}
        data-focus-visible-added
        title={alt}
      >
        <Spacing position="b" size={15}>
          <Image
            src={image}
            alt={alt}
            width="875"
            height={875 / config.productShotsRatio}
            placeholderColor={theme.colors['dark-grey']}
          />
        </Spacing>
        <Spacing size="05">
          <Heading className="ellipsis leading-normal" size="xxs" tag="h2">
            {title}
          </Heading>
        </Spacing>
        {profileName && (
          <Spacing size="05">
            <Paragraph size="xxs" className="leading-normal">
              {profileName}
            </Paragraph>
          </Spacing>
        )}
        <Spacing size="05">
          <Paragraph size="xxs" className="leading-normal">
            <CampaignTimeLeft endAt={endAt} endedColor="text-red-primary" />
          </Paragraph>
        </Spacing>
        <Spacing size="05">
          <Paragraph size="xxs" className="leading-normal">
            <Price value={prices} />
          </Paragraph>
        </Spacing>
      </Link>
      {onDelete && (
        <button
          onClick={onDelete}
          className={generalStyles.delete}
          data-test-id="favourite-delete"
        >
          <Icon glyph={crossIcon} width={20} height={20} />
        </button>
      )}
    </div>
  );
};

export default FavouriteThumbnail;

FavouriteThumbnail.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      iso: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      default: PropTypes.bool
    })
  ).isRequired,
  endAt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  profileName: PropTypes.string,
  alt: PropTypes.string,
  onDelete: PropTypes.func
};
