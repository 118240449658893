import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import inRange from 'lodash/inRange';
import debounce from 'lodash/debounce';

import config from '../../../../config';

import {
  getCampaignProducts,
  updateCampaign,
  updateCampaignProduct,
  updateProfitEstimate,
  updateDarkColoursToastDismissed
} from '../../../../actions/campaign-builder';

import { hasDarkColours } from '../../../../helpers/campaignBuilder';

import Alert from '../../../../components/atoms/Alert';
import Heading from '../../../../components/atoms/Heading';
import Image from '../../../../components/atoms/Image';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';
import SelectCurrencies from '../../../../components/atoms/Select/SelectCurrencies';
import { Form, DefaultField as Field } from '../../../../components/atoms/Form';
import Price from '../../../../components/atoms/Price';
import { TooltipNew as Tooltip } from '../../../../components/atoms/Tooltip';
import Icon from '../../../../components/atoms/Icon';

import Toaster, { toast } from '../../../../components/molecules/Toaster';

import questionMarkIcon from '../../../../img/sprites/question-mark-circle.svg';

import generalStyles from './style/general.module.css';
import IconButton from '../../../../components/atoms/IconButton';
import crossIcon from '../../../../img/sprites/round-cross.svg';

const DarkColoursToast = ({ onDismiss }) => (
  <div className="w-full flex justify-between items-center gap-6">
    <Paragraph size="xs">
      We’ve had to reset your sales prices because you’ve selected a dark
      garment.
    </Paragraph>
    <IconButton
      glyph={crossIcon}
      title="Close"
      size="small"
      kind="ghost"
      onClick={onDismiss}
    />
  </div>
);

const tooltipContent = (
  <div className="flex flex-col gap-3">
    <Heading tag="h4" size="xxs">
      Estimated profit per sale
    </Heading>
    <Paragraph size="xxs">
      Sales profits can be affected by quantity sold, currency conversion and
      other variables. The sales price that the buyer pays contains sales tax.
    </Paragraph>
  </div>
);

const ProductPrices = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const userCurrency = useSelector((state) => state.user.currency);
  const {
    campaignProducts,
    profitEstimate,
    currencyIso,
    step2TimesViewed,
    darkColoursToastDismissed
  } = useSelector((state) => state.campaignBuilder, shallowEqual);

  const { products: productsProfit } = profitEstimate;

  const campaignCurrency = currencyIso ?? userCurrency;

  useEffect(() => {
    if (
      !darkColoursToastDismissed &&
      step2TimesViewed > 1 &&
      !isEmpty(campaignProducts) &&
      hasDarkColours(campaignProducts)
    ) {
      toast(
        (t) => (
          <DarkColoursToast
            onDismiss={() => {
              dispatch(updateDarkColoursToastDismissed(true));

              toast.dismiss(t.id);
            }}
          />
        ),
        {
          id: 'toast-dark-colours'
        }
      );
    }
  }, [dispatch, step2TimesViewed, darkColoursToastDismissed, campaignProducts]);

  const handlePriceChange = useCallback(
    async (campaignProduct, price) => {
      try {
        setError(null);

        await dispatch(
          updateCampaignProduct({ ...campaignProduct, ...{ price } })
        );

        await dispatch(updateProfitEstimate());
      } catch (err) {
        setError(
          err?.message || 'Something is not right, please try again later.'
        );
      }
    },
    [dispatch]
  );

  const handleCurrencyChange = async (currencyIso) => {
    try {
      setError(null);

      await dispatch(updateCampaign({ currencyIso }));

      // Update products minimum price
      await dispatch(getCampaignProducts());
      await dispatch(updateProfitEstimate());
    } catch (err) {
      setError(
        err?.message || 'Something is not right, please try again later.'
      );
    }
  };

  const debouncedHandlePriceChange = useMemo(
    () => debounce(handlePriceChange, 500),
    [handlePriceChange]
  );

  return (
    <div>
      <div>
        <Heading tag="h4" size="xs">
          Set product prices
        </Heading>
        <Spacing size="05" position="t">
          <Paragraph size="xxs">
            You can set how much the garments are sold for as long as they make
            profit.
          </Paragraph>
        </Spacing>
        <Spacing size={3} position="t">
          <div className={generalStyles.productsGrid}>
            {!isEmpty(productsProfit) &&
              campaignProducts.map((campaignProduct) => {
                // TODO: pick the right image based on artwork positions, set a default if both front and back?
                const imageUrl = campaignProduct.product.areas[0].greyUrl;

                const {
                  product,
                  price: currPrice,
                  minimumPrice,
                  recommendedPrice
                } = campaignProduct;

                const productProfit = productsProfit.find(
                  (item) => item.productId === product.productId
                );
                const { amount: profitPerSale } = productProfit || {};

                const minimumPriceLocaleStr = minimumPrice.toLocaleString(
                  'en',
                  {
                    style: 'currency',
                    currency: currencyIso
                  }
                );

                return (
                  <div
                    className={generalStyles.product}
                    key={campaignProduct.campaignProductId}
                  >
                    <div className="relative">
                      <Image
                        src={imageUrl}
                        alt={product.name}
                        width={90}
                        height={70 / config.productShotsRatio}
                        withPlaceholder={true}
                        lazyLoad={false}
                      />
                    </div>
                    <div>
                      <Paragraph size="xxs" className="uppercase">
                        {product.brandName}
                      </Paragraph>
                      <Paragraph size="xxs">{product.name}</Paragraph>
                    </div>
                    <div className="justify-self-end">
                      <Form
                        onSubmit={() => null}
                        onChange={async ({ price }) => {
                          // Form has been reinitialized with new values after a currency change, do nothing
                          if (price === currPrice) return false;

                          if (inRange(price, minimumPrice, 1000)) {
                            await debouncedHandlePriceChange(
                              campaignProduct,
                              price
                            );
                          }

                          return true;
                        }}
                        initialValues={{ price: currPrice ?? recommendedPrice }}
                        validationSchema={yup.object().shape({
                          price: yup
                            .number()
                            .min(
                              minimumPrice,
                              `Price is below minimum ${minimumPriceLocaleStr}.`
                            )
                            .max(999, 'Price is above maximum.')
                            .required('Price is required.')
                        })}
                        validateOnChange={true}
                        enableReinitialize
                        render={({ handleSubmit, errors }) => {
                          return (
                            <form onSubmit={handleSubmit}>
                              <div className="flex justify-end items-center gap-4">
                                <div style={{ width: '70px' }}>
                                  <Field
                                    type="number"
                                    name="price"
                                    placeholder="Price"
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                                <div className="flex items-center gap-4">
                                  <Paragraph size="xxs">
                                    <Price
                                      value={profitPerSale}
                                      currency={campaignCurrency}
                                      className="block"
                                    />{' '}
                                    profit/sale
                                  </Paragraph>
                                  <Tooltip
                                    id="tooltip-price-estimate"
                                    content={tooltipContent}
                                    placement="bottom"
                                    offset={7}
                                    align="start"
                                    alignOffset={-10}
                                    width={320}
                                  >
                                    <Icon
                                      className="mb-px opacity-25 hover:opacity-100 transition-opacity duration-100"
                                      glyph={questionMarkIcon}
                                      width={14}
                                      height={14}
                                    />
                                  </Tooltip>
                                </div>
                              </div>

                              {errors?.price && (
                                <div
                                  className="mt-1"
                                  style={{ width: 'max-content' }}
                                >
                                  <Alert>{errors.price}</Alert>
                                </div>
                              )}
                            </form>
                          );
                        }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </Spacing>
      </div>

      <div className="relative">
        <Spacing size={3}>
          <hr className="border-grey-lighter" />
        </Spacing>
      </div>

      <div className={generalStyles.currency}>
        <div>
          <Heading tag="h4" size="xs">
            Campaign currency
          </Heading>
          <Spacing size="05" position="t">
            <Paragraph size="xxs">This is reflected in your reports.</Paragraph>
          </Spacing>
        </div>

        <div className="justify-self-end">
          <Form
            onSubmit={() => null}
            onChange={({ currency }) => {
              if (currency) return handleCurrencyChange(currency);

              return true;
            }}
            initialValues={{ currency: campaignCurrency }}
            validationSchema={yup.object().shape({
              currency: yup.string().required()
            })}
            render={({ handleSubmit, errors }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="w-20" style={{ minWidth: '167px' }}>
                    <SelectCurrencies
                      name="currency"
                      placeholder="Currency"
                      useNewStyles
                    />
                  </div>

                  {errors?.currency && (
                    <Spacing size={1} position="t">
                      <Alert>{errors.currency}</Alert>
                    </Spacing>
                  )}
                </form>
              );
            }}
          />
        </div>
      </div>

      {error && (
        <Spacing size={2} position="t">
          <Alert>{error}</Alert>
        </Spacing>
      )}

      <Toaster />
    </div>
  );
};

export default ProductPrices;
