import React, { useState } from 'react';

import IntegrationsSignUpForm from './IntegrationsLeadForm';
import IntegrationsLeadConfirmation from './IntegrationsConfirmation';

import Spacing from '../../components/atoms/Spacing';
import { PageWrapNew as PageWrap } from '../../components/atoms/PageWrap';

import IntegrationsForm from '../../components/molecules/IntegrationsForm';

import Page from '../../components/utils/Page';

import integrationsImg from '../../img/pages/integrations/integrations.jpg';

import generalStyles from './style/general.module.css';

const Integrations = () => {
  const [hasLeadSubmitted, setLeadSubmitted] = useState(false);

  const handleSuccessfulSubmission = () => {
    setLeadSubmitted(true);
    window.scrollTo(0, 0);
  };

  return (
    <Page title="Integrations">
      <PageWrap>
        <Spacing size={2} position="t">
          <IntegrationsForm
            imgProps={{
              src: integrationsImg,
              alt: 'Integrations sign-up confirmation',
              className: generalStyles.image
            }}
          >
            <Spacing size={[2, 2, 0]} position="t">
              {hasLeadSubmitted ? (
                <IntegrationsLeadConfirmation />
              ) : (
                <IntegrationsSignUpForm
                  onSuccess={handleSuccessfulSubmission}
                />
              )}
            </Spacing>
          </IntegrationsForm>
        </Spacing>
      </PageWrap>
    </Page>
  );
};

export default Integrations;
