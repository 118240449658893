import flow from 'lodash/flow';
import pick from 'lodash/pick';
import values from 'lodash/values';
import flatten from 'lodash/flatten';
import compact from 'lodash/compact';

import config from '../config';

// Taken from https://gist.github.com/leipert/9586b796281faa5e27ed

// These window.navigator contain language information
// 1. languages -> Array of preferred languages (eg ["en-US", "zh-CN", "ja-JP"]) Firefox^32, Chrome^32
// 2. language  -> Preferred language as String (eg "en-US") Firefox^5, IE^11, Safari,
//                 Chrome sends Browser UI language
// 3. browserLanguage -> UI Language of IE
// 4. userLanguage    -> Language of Windows Regional Options
// 5. systemLanguage  -> UI Language of Windows
const browserLanguagePropertyKeys = [
  'languages',
  'language',
  'browserLanguage',
  'userLanguage',
  'systemLanguage'
];

const detectLocale = (currencies, geoIpLocation) => {
  let country;
  let currency;

  const defaultCountry = new Intl.Locale(config.defaultLocale).region;
  const defaultCurrency = currencies.find(currency => currency.reference).iso;

  if (geoIpLocation) {
    country = geoIpLocation.country_code;
    currency = currencies.find(
      currency => currency.iso === geoIpLocation.currency.code
    );
  } else {
    const locales = flow(
      x => pick(x, browserLanguagePropertyKeys), // Get only language properties
      values, // Get values of the properties
      flatten, // Flatten all arrays
      compact // Remove undefined values
    )(window.navigator);

    locales.forEach(locale => {
      const result = locale.match(/^[a-z]{2}-([A-Z]{2}?)$/);
      country = result && result[1];
      currency =
        country &&
        currencies.find(currency =>
          currency.countries.map(country => country.iso).includes(country)
        );
    });
  }

  return {
    country: country ? country : defaultCountry,
    currency: currency ? currency.iso : defaultCurrency
  };
};

export default detectLocale;
