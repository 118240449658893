import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Grid } from '../../../atoms/Grid';

import { TableContext } from '../';

import generalStyles from './style/general.module.css';

const TableRow = ({
  children,
  align,
  justify,
  as = 'div',
  className,
  borderColor,
  borderWidth,
  ...otherProps
}) => {
  const Component = as;
  return (
    <Component
      className={classNames(
        className,
        generalStyles.row,
        `border-${borderColor}`,
        `border-t-${borderWidth.y} border-b-${borderWidth.y}`,
        `border-l-${borderWidth.x} border-r-${borderWidth.x}`
      )}
      {...otherProps}
    >
      <Grid gap={0} align={align} justify={justify}>
        {children}
      </Grid>
    </Component>
  );
};

export default props => (
  <TableContext.Consumer>
    {context =>
      context ? (
        <TableRow {...context.RowProps} {...props} />
      ) : (
        <TableRow {...props} />
      )
    }
  </TableContext.Consumer>
);

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  borderColor: PropTypes.string
};

TableRow.defaultProps = {
  justify: 'start',
  borderColor: 'black',
  borderWidth: {
    x: 1,
    y: 1
  }
};
