import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import generalStyle from './style/general.module.css';
import sizeStyle from './style/size.module.css';

const cx = classNames.bind({ ...generalStyle, ...sizeStyle });

export default function ColourCircle({ code, size }) {
  const circleProps = code && {
    style: {
      backgroundColor: `#${code}`
    }
  };
  const circleClassName = classNames(cx('circle', size, { multi: !code }));

  return <div className={circleClassName} {...circleProps} />;
}

ColourCircle.propTypes = {
  code: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizeStyle))
};

ColourCircle.defaultProps = {
  size: 's'
};
