import React from 'react';
import { connect } from 'react-redux';

import * as userActions from '../../actions/user';

import Page from '../../components/utils/Page';
import {
  Form,
  DefaultField as Field,
  handleFormError
} from '../../components/atoms/Form';
import { ButtonNew as Button } from '../../components/atoms/Button';
import Heading from '../../components/atoms/Heading';
import MaxWidth from '../../components/atoms/MaxWidth';
import Spacing from '../../components/atoms/Spacing';
import PageWrap from '../../components/atoms/PageWrap';

import everpressLogo from '../../img/everpress-logo.svg';

const ResetPassword = ({ goToLogin, token, resetPassword }) => {
  const handleResetPassword = async ({ password }, actions) => {
    try {
      await resetPassword(token, password);
    } catch (error) {
      handleFormError(error, actions);
      return actions.setSubmitting(false);
    }
    goToLogin();
  };

  return (
    <Page title="Reset Password">
      <PageWrap className="text-center">
        <MaxWidth center value={415}>
          <Spacing size={15} position="y">
            <img
              src={everpressLogo}
              alt="Everpress logo"
              width="40"
              height="35"
              className="m-auto"
            />
            <Spacing size={15} position="y">
              <Heading size="xs">Reset Password</Heading>
              <Form
                initialValues={{ password: '' }}
                onSubmit={handleResetPassword}
                render={({ isSubmitting, handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="text-left">
                        <Spacing size={2} position="t">
                          <Spacing size={1}>
                            <Field
                              type="password"
                              name="password"
                              placeholder="New password"
                            />
                          </Spacing>
                        </Spacing>
                      </div>
                      <Button
                        type="submit"
                        state={isSubmitting ? 'loading' : null}
                        fullWidth
                      >
                        Reset
                      </Button>
                    </form>
                  );
                }}
              />
            </Spacing>
          </Spacing>
        </MaxWidth>
      </PageWrap>
    </Page>
  );
};

const mapStateToProps = ({ location }) => ({
  token: location.payload.token
});

export default connect(mapStateToProps, {
  ...userActions,
  goToLogin: () => ({ type: 'LOGIN' })
})(ResetPassword);
