import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { getText } from '../../helpers/prismic';

import Page from '../../components/utils/Page';
import PageWrap from '../../components/atoms/PageWrap';
import Heading from '../../components/atoms/Heading';
import Spacing from '../../components/atoms/Spacing';
import MaxWidth from '../../components/atoms/MaxWidth';
import LiveChat from '../../components/atoms/LiveChat';

import Slices from '../../components/molecules/Slices';

import styles from './style/general.module.css';

function CaseStudy({ seoTitle, headline, image, overlayOpacity, slices }) {
  return (
    <Page title={seoTitle}>
      <Spacing size={[2, 4, 6]} position="b" type="padding">
        <div
          className={styles.hero}
          style={{
            '--overlayOpacity': overlayOpacity,
            backgroundImage: `url(${image.url})`
          }}
        >
          <div className={styles.heroContent}>
            <PageWrap>
              <MaxWidth value={876} center>
                <Heading
                  tag="h2"
                  size={['m', 'l', 'xl', 'xxl']}
                  color="white"
                  center
                >
                  {headline}
                </Heading>
              </MaxWidth>
            </PageWrap>
          </div>
        </div>
      </Spacing>
      <Slices
        slices={slices}
        urlBuilder={(slice) => ({
          type: 'CASE_STUDY',
          payload: {
            category: slice.data.category.slug,
            slug: slice.slug
          }
        })}
      />
      <LiveChat />
    </Page>
  );
}

const mapStateToProps = ({ caseStudies, location }) => {
  const caseStudy = caseStudies.bySlug[location.payload.slug];
  const data = caseStudy ? caseStudy.data : {};

  return {
    seoTitle: data.seo_title,
    headline: getText(data.headline),
    title: getText(data.title),
    overlayOpacity: get(data, 'overlay_opacity') || 0,
    image: get(data.image, 'large') || {},
    slices: data.body || []
  };
};

export default connect(mapStateToProps)(CaseStudy);
