import React from 'react';

import { Grid, GridItem } from '../../../components/atoms/Grid';

import HugeThumbnail from '../../../components/molecules/Thumbnails/HugeThumbnail';
import FeatureThumbnail from '../../../components/molecules/Thumbnails/FeatureThumbnail';

export default function MissionAndSustainability({
  featureImage,
  featureTitle,
  featureDirection,
  featureRatio,
  hugeTitle,
  cta1,
  cta2,
  missionImage,
  missionImageWidth,
  missionImageHeight
}) {
  return (
    <Grid gap={2} align="stretch">
      <GridItem columnSize={[0, 0, 0, 0, 2]}>
        <div className="hidden lg:block">
          <FeatureThumbnail
            ratio={featureRatio}
            direction={featureDirection}
            title={featureTitle}
            image={featureImage}
          />
        </div>
      </GridItem>
      <GridItem columnSize={[12, 12, 12, 12, 10]}>
        <HugeThumbnail
          image={missionImage}
          width={missionImageWidth}
          height={missionImageHeight}
          title={hugeTitle}
          cta1={cta1}
          cta2={cta2}
        />
      </GridItem>
    </Grid>
  );
}
