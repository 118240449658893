import React from 'react';
import { connect } from 'react-redux';
import chunk from 'lodash/chunk';

import { getText } from '../../helpers/prismic';
import { getCaseStudiesByCategory } from '../../helpers/selectors/caseStudies';

import Page from '../../components/utils/Page';

import { Grid, GridItem } from '../../components/atoms/Grid';
import Heading from '../../components/atoms/Heading';
import PageWrap from '../../components/atoms/PageWrap';
import Paragraph from '../../components/atoms/Paragraph';
import Spacing from '../../components/atoms/Spacing';

import CaseStudiesTile from '../../components/molecules/CaseStudiesTile';
import AltCta from '../../components/molecules/AltCta';

import generalStyles from './style/general.module.css';

function CaseStudiesCategory({ seoTitle, hero, caseStudies }) {
  return (
    <Page title={seoTitle}>
      <PageWrap>
        <Spacing size={[2, 4, 5]}>
          <Heading tag="h2" size="" className={generalStyles.title}>
            {hero.title}
          </Heading>
        </Spacing>
        <Grid gap={0}>
          <GridItem columnSize={[12, 12, 6]}>
            <Spacing size={[0, 0, 2]} position="r">
              <Paragraph size={['xs', 's']}>{hero.description}</Paragraph>
            </Spacing>
          </GridItem>
        </Grid>

        <Spacing size={[2, 4, 8]}>
          {chunk(caseStudies, 2).map((groupCaseStudies, index) => (
            <Spacing key={index} size={[0, 0, 2]} position="y" type="padding">
              <Grid gap={2}>
                {groupCaseStudies.map((caseStudy, i) => (
                  <CaseStudiesTile
                    key={i}
                    background={caseStudy.image.url}
                    title={caseStudy.title}
                    isCategory
                    to={{
                      type: 'CASE_STUDY',
                      payload: {
                        category: caseStudy.category,
                        slug: caseStudy.slug
                      }
                    }}
                  />
                ))}
              </Grid>
            </Spacing>
          ))}
        </Spacing>
        <AltCta />
      </PageWrap>
    </Page>
  );
}

const mapStateToProps = ({ caseStudies, location }) => {
  const category = caseStudies.categories.bySlug[location.payload.slug];
  const filteredCaseStudies =
    getCaseStudiesByCategory(location.payload.slug, caseStudies) || [];

  return {
    seoTitle: category && category.data.seo_title,
    hero: {
      title: category && getText(category.data.title),
      description: category && getText(category.data.description)
    },
    caseStudies: filteredCaseStudies.map(item => {
      return {
        slug: item.uid,
        title: getText(item.data.title),
        image: item.data.image,
        category: item.data.category.slug
      };
    })
  };
};

export default connect(mapStateToProps)(CaseStudiesCategory);
