import React, { PureComponent } from 'react';
import throttle from 'lodash/throttle';

import Heading from '../../../components/atoms/Heading';
// import { Grid, GridItem } from '../../../components/atoms/Grid';
import MaxWidth from '../../../components/atoms/MaxWidth';
import Spacing from '../../../components/atoms/Spacing';
// import Link from '../../../components/atoms/Link';

// import QuoteThumbnail from '../../../components/molecules/Thumbnails/QuoteThumbnail';
// import FeatureThumbnail from '../../../components/molecules/Thumbnails/FeatureThumbnail';
import LogoRow from '../../../components/molecules/LogoRow';

// import generalStyles from './style/general.module.css';

import logos from './logos.js';

// import {
//   getPostFirstTag,
//   getPostLink,
//   getPostAuthor,
//   getPostTitle,
//   getPostImage,
//   getPostExcerpt
// } from '../../../helpers/wordpress';
import { theme } from '../../../theme-config';
import pxToNumber from '../../../helpers/pxToNumber';
// import cropString from '../../../helpers/cropString';

export default class TrustedByCommunity extends PureComponent {
  constructor() {
    super();

    this.state = {
      featureDirection: 'horizontal'
    };

    this.handleResize = throttle(this.handleResize.bind(this), 200);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    const windowWidth = window.innerWidth;
    const screens = theme.screens;

    if (windowWidth <= pxToNumber(screens.md)) {
      this.setState({
        featureDirection: 'vertical'
      });
    } else {
      this.setState({
        featureDirection: 'horizontal'
      });
    }
  }

  render() {
    // const { blogPosts } = this.props;
    // const { featureDirection } = this.state;
    // const firstPost = blogPosts[0];
    // const postList = blogPosts.slice(1, 5);

    return (
      <Spacing>
        <MaxWidth value={760}>
          <Heading size={['l', 'xl', 'xxl']}>
            USED AND<br />FEATURED BY
          </Heading>
        </MaxWidth>

        <Spacing>
          <LogoRow logos={logos} />
        </Spacing>
        {/*}
        <Spacing>
          <Grid gap={2} align="stretch">
            <GridItem columnSize={[12, 12, 9]}>
              {firstPost && (
                <Spacing size={[2, 2, 0]}>
                  <Link to={getPostLink(firstPost)} isUnderlined={false}>
                    <QuoteThumbnail
                      ratio={110}
                      quote={cropString(
                        `${getPostExcerpt(firstPost)}</p>`,
                        150
                      )}
                      isNaked
                      author={getPostAuthor(firstPost)}
                      title={getPostFirstTag(firstPost)}
                      image={getPostImage(firstPost)}
                    />
                  </Link>
                </Spacing>
              )}
            </GridItem>
            <GridItem columnSize={[12, 12, 3]}>
              <div className={generalStyles.features}>
                {postList.map(item => {
                  return (
                    <Spacing key={item.link} size={[2, 2, 0]} position="x">
                      <FeatureThumbnail
                        title={getPostTitle(item)}
                        tag={getPostFirstTag(item)}
                        image={getPostImage(item)}
                        to={getPostLink(item)}
                        direction={featureDirection}
                        ratio={120}
                      />
                    </Spacing>
                  );
                })}
              </div>
            </GridItem>
          </Grid>
        </Spacing>*/}
      </Spacing>
    );
  }
}
