import React from 'react';
import classNames from 'classnames/bind';
import RouterLink from 'redux-first-router-link';
import PropTypes from 'prop-types';

import Icon from '../../Icon';
import LoadingSpinner from './LoadingSpinner';

import generalStyles from './style/general.module.css';
import kindStyles from './style/kind.module.css';
import sizeStyles from './style/size.module.css';

const cx = classNames.bind({
  ...generalStyles,
  ...kindStyles,
  ...sizeStyles
});

const Button = ({
  children,
  kind,
  size,
  state,
  color,
  to,
  isExternal,
  fullWidth,
  className,
  icon,
  ...props
}) => {
  const toTag = isExternal ? 'a' : RouterLink;
  const CustomTag = to ? toTag : 'button';
  const isLoading = state === 'loading';
  const isDisabled = state === 'disabled' || isLoading;

  const buttonProps = {
    ...(isExternal ? { href: to } : { to }),
    className: cx(
      'button',
      kind,
      size,
      state,
      color,
      { fullWidth, isLink: !!to, [generalStyles.withIcon]: !!icon },
      className
    ),
    disabled: isDisabled || isLoading
  };

  return (
    <CustomTag
      /* Adding on mouse down so we don't affect functionality of relocation but to remove focus on click */
      onMouseDown={(e) => e.preventDefault()}
      {...buttonProps}
      {...props}
    >
      {children}
      {!!icon ? (
        <Icon
          glyph={icon}
          width={5}
          height={5}
          className={generalStyles.icon}
        />
      ) : null}
      {isLoading && <LoadingSpinner kind={kind} size={size} color={color} />}
    </CustomTag>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  kind: PropTypes.oneOf(['solid', 'outline', 'ghost']),
  size: PropTypes.oneOf(['normal', 'small', 'tiny']),
  state: PropTypes.oneOf([
    'default',
    'hover',
    'active',
    'focus',
    'disabled',
    'loading'
  ]),
  color: PropTypes.oneOf(['black', 'white', 'red']),
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isExternal: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.object
};

Button.defaultProps = {
  kind: 'solid',
  size: 'normal',
  state: 'default',
  color: 'black',
  fullWidth: false,
  isExternal: false
};

export default Button;
