import React from 'react';

import Thanks from '../../../components/molecules/Thanks';
import getStartedItems from './getStartedItems';

function LeadCaptureThanks() {
  return (
    <Thanks
      title="Thanks, we'll be in touch shortly to help you!"
      description="In the meantime, feel free to get started with Everpress."
      items={getStartedItems}
    />
  );
}

export default LeadCaptureThanks;
