import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Image from '../../atoms/Image';

import generalStyles from './style/general.module.css';

export default function ProductShot({
  garmentImageUrl,
  bgColor,
  color,
  artwork,
  garmentArea,
  width,
  height,
  overrideImageUrl,
  alt,
  hasContainerPadding
}) {
  return (
    <div
      className={hasContainerPadding ? generalStyles.container : undefined}
      style={{ backgroundColor: bgColor }}
    >
      <div className={generalStyles.inner}>
        <Fragment>
          {artwork && garmentArea && (
            <Image
              src={artwork.artwork.url}
              alt="product artwork"
              width={width * 0.75}
              height={height * 0.75}
              style={{
                position: 'absolute',
                width: `${artwork.width / 5}%`,
                height: `${artwork.height / 5}%`,
                top: `${garmentArea.y / 5 + artwork.y / 5}%`,
                left: `${garmentArea.x / 5 + artwork.x / 5}%`
              }}
              withPlaceholder={false}
              lazyLoad={false}
            />
          )}
          <Image
            src={overrideImageUrl || garmentImageUrl}
            alt={alt}
            width={width}
            height={height}
            placeholderColor={color}
            lazyLoad={false}
          />
        </Fragment>
      </div>
    </div>
  );
}

ProductShot.propTypes = {
  garmentImageUrl: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  artwork: PropTypes.object,
  garmentArea: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  overrideImageUrl: PropTypes.string,
  alt: PropTypes.string
};
