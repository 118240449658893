import React from 'react';

import Icon from '../../../components/atoms/Icon';
import Spacing from '../../../components/atoms/Spacing';
import Heading from '../../../components/atoms/Heading';
import Paragraph from '../../../components/atoms/Paragraph';

export default function Feature({ title, children, icon }) {
  return (
    <div className="border-t-1 border-solid pt-3">
      <Spacing size={6} position="b">
        <Icon glyph={icon} width={45} height={45} />
        <Spacing size={2}>
          <Heading tag="h4" size="s">
            {title}
          </Heading>
        </Spacing>
        <Paragraph size="xs">
          <span dangerouslySetInnerHTML={{ __html: children }} />
        </Paragraph>
      </Spacing>
    </div>
  );
}
