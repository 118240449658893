import React from 'react';

import { Grid, GridItem, InlineGrid } from '../../../atoms/Grid';
import Heading from '../../../atoms/Heading';
import Image from '../../../atoms/Image';
import Link from '../../../atoms/Link';
import MaxWidth from '../../../atoms/MaxWidth';
import PageWrap from '../../../atoms/PageWrap';
import Paragraph from '../../../atoms/Paragraph';
import Spacing from '../../../atoms/Spacing';

import styles from './style/general.module.css';

const PreviousNext = ({ prev, next }) => {
  return (
    <PageWrap>
      <Spacing size={[2, 4, 6]} type="padding">
        <Grid gap={2} align="stretch">
          <GridItem columnSize={[12, 12, 6]}>
            <div className={styles.prevnext_first}>
              <Spacing size={1}>
                <Link to={prev.to} isUnderlined={false}>
                  <InlineGrid
                    align="start"
                    justify="start"
                    direction={['col', 'col', 'row']}
                  >
                    <Spacing size={[1, 1, 0]} position="b">
                      <Paragraph className={styles.prevnextNav} size="xxxs">
                        Previous
                      </Paragraph>
                    </Spacing>
                    <Spacing size={[0, 0, 2, 4]} position="l">
                      <InlineGrid align="center" justify="start">
                        <Spacing size={2} position="r">
                          <Image
                            className={styles.prevnextImg}
                            width="80"
                            height="80"
                            src={prev.image.url}
                            alt={prev.image.alt}
                          />
                        </Spacing>
                        <MaxWidth value={350}>
                          <Heading size="s" className={styles.breakWord}>
                            {prev.title}
                            {prev.headline && `— ${prev.headline}`}
                          </Heading>
                        </MaxWidth>
                      </InlineGrid>
                    </Spacing>
                  </InlineGrid>
                </Link>
              </Spacing>
            </div>
          </GridItem>
          <GridItem columnSize={[12, 12, 6]}>
            <div className={styles.prevnext}>
              <Spacing size={1}>
                <Link to={next.to} isUnderlined={false}>
                  <InlineGrid
                    align="start"
                    justify="start"
                    direction={['col', 'col', 'row']}
                  >
                    <Spacing size={[1, 1, 0]} position="b">
                      <Paragraph className={styles.prevnextNav} size="xxxs">
                        Next
                      </Paragraph>
                    </Spacing>
                    <Spacing size={[0, 0, 2, 4]} position="l">
                      <InlineGrid align="center" justify="start">
                        <Spacing size={2} position="r">
                          <Image
                            className={styles.prevnextImg}
                            width="80"
                            height="80"
                            src={next.image.url}
                            alt={next.image.alt}
                          />
                        </Spacing>
                        <MaxWidth value={350}>
                          <Heading size="s" className={styles.breakWord}>
                            {next.title}
                            {next.headline && `— ${next.headline}`}
                          </Heading>
                        </MaxWidth>
                      </InlineGrid>
                    </Spacing>
                  </InlineGrid>
                </Link>
              </Spacing>
            </div>
          </GridItem>
        </Grid>
      </Spacing>
    </PageWrap>
  );
};

export default PreviousNext;
