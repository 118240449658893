import React from 'react';
import PropTypes from 'prop-types';

import Heading from '../../components/atoms/Heading';
import MaxWidth from '../../components/atoms/MaxWidth';
import Spacing from '../../components/atoms/Spacing';
import Paragraph from '../../components/atoms/Paragraph';
import { ButtonNew as Button } from '../../components/atoms/Button';
import PageWrap from '../../components/atoms/PageWrap';
import LiveChat from '../../components/atoms/LiveChat';

import Page from '../../components/utils/Page';

import generalStyles from './style/general.module.css';

export default class NotFound extends React.Component {
  render() {
    const { isError, onButtonClick } = this.props;
    return (
      <Page title="Not Found" className={generalStyles.page}>
        <PageWrap>
          <MaxWidth center value={340}>
            <Heading size={['l', 'xl', 'xxl']}>
              <span>Hmmm…</span>
            </Heading>
            <Spacing size={3}>
              <Paragraph>
                {isError ? (
                  <span>
                    Sorry, for some reason this isn't working. If it keeps
                    happening please get in touch and we'll do our best to help.
                  </span>
                ) : (
                  <span>
                    The page you're looking for either isn't here or doesn't
                    exist (yet?).
                  </span>
                )}
              </Paragraph>
            </Spacing>
            <Button
              fullWidth
              onClick={() => onButtonClick()}
              to={isError ? '/contact' : '/shop'}
            >
              {isError ? 'Contact us' : 'Looking for our shop?'}
            </Button>
          </MaxWidth>
        </PageWrap>
        <LiveChat />
      </Page>
    );
  }
}

NotFound.propTypes = {
  isError: PropTypes.bool
};

NotFound.defaultProps = {
  isError: false
};
