import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Grid, GridItem, InlineGrid } from '../../atoms/Grid';
import Paragraph from '../../atoms/Paragraph';
import Spacing from '../../atoms/Spacing';

import generalStyles from './style/general.module.css';

const cx = classNames.bind({ ...generalStyles });

export default class Toggle extends PureComponent {
  handleClick() {
    if (this.props.disabled) return;

    this.props.onChange(!this.props.value);
  }

  render() {
    const {
      name,
      children,
      leftTitle,
      rightTitle,
      value,
      inlineGridClassName,
      activeColour,
      disabled
    } = this.props;
    const isChecked = value;
    const hasTitle = !!rightTitle || !!leftTitle;
    const toggleClassName = cx('toggle', {
      toggleActive: isChecked,
      green: isChecked && activeColour === 'green',
      grey: disabled,
      'cursor-not-allowed': disabled
    });

    return (
      <div onClick={this.handleClick.bind(this)}>
        <Grid gap={0}>
          <GridItem columnSize={children ? 4 : 0}>{children}</GridItem>
          <GridItem columnSize={children ? 8 : 12}>
            <InlineGrid justify="start" className={inlineGridClassName}>
              {!!leftTitle ? (
                <Paragraph size="xxs">{leftTitle}</Paragraph>
              ) : null}
              <Spacing
                size={hasTitle ? 1 : 0}
                position={hasTitle ? 'x' : !!leftTitle ? 'l' : 'r'}
              >
                <div
                  className={toggleClassName}
                  tabIndex={0}
                  title={`toggle ${leftTitle || 'off'} or ${rightTitle ||
                    'on'}`}
                />
              </Spacing>
              {!!rightTitle ? (
                <Paragraph size="xxs">{rightTitle}</Paragraph>
              ) : null}
            </InlineGrid>
            <input
              className={cx('input')}
              type="checkbox"
              checked={isChecked}
              name={name}
              readOnly
              disabled={disabled}
            />
          </GridItem>
        </Grid>
      </div>
    );
  }
}

Toggle.propTypes = {
  value: PropTypes.bool,
  activeColour: PropTypes.oneOf(['default', 'green']),
  name: PropTypes.string,
  leftTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  inlineGridClassName: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node
};

Toggle.defaultProps = {
  value: false,
  activeColour: 'default',
  disabled: false
};
