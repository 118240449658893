import React from 'react';
import * as yup from 'yup';

import { ButtonNew } from '../../../atoms/Button';
import Heading from '../../../atoms/Heading';
import {
  DefaultField as Field,
  Form,
  handleFormError
} from '../../../atoms/Form';
import Link from '../../../atoms/Link';
import Spacing from '../../../atoms/Spacing';
import Paragraph from '../../../atoms/Paragraph';

const SignUpForm = ({ onSignUp, onUserStageChange, isCreator, isBuilder }) => {
  const handleSignUp = async ({ email, password }, actions) => {
    actions.setSubmitting(true);

    try {
      await onSignUp({ email, password });
    } catch (error) {
      handleFormError(error, actions);
    }
    actions.setSubmitting(false);
  };

  return (
    <>
      <Heading size="xs">
        {isBuilder ? "While it's uploading" : 'Create a free account'}
      </Heading>
      <Spacing size={1} position="t">
        <Paragraph size="xxs">
          {isBuilder
            ? "Create an account and we'll save your progress."
            : isCreator
            ? 'Sign up to create your own campaigns and start selling your designs on Everpress.'
            : 'Sign up to follow artists, set reminders or create your own campaigns.'}
        </Paragraph>
      </Spacing>
      <Form
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={yup.object().shape({
          email: yup.string().required(),
          password: yup
            .string()
            .required()
            .min(8)
        })}
        onSubmit={handleSignUp}
        render={({ isSubmitting, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="text-left">
                <Spacing size={2} position="t">
                  <Spacing size={1}>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email address"
                      data-test-id="email"
                    />
                  </Spacing>
                  <Spacing size={1} position="t">
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                  </Spacing>
                </Spacing>
              </div>
              <Spacing size={2}>
                <Paragraph color="grey-dark" size="xxxs">
                  You agree to our{' '}
                  <Link to={{ type: 'TERMS' }} target="_blank">
                    Terms of Use
                  </Link>{' '}
                  when creating an account.
                </Paragraph>
              </Spacing>
              <ButtonNew
                type="submit"
                state={isSubmitting ? 'loading' : null}
                fullWidth
              >
                Get started
              </ButtonNew>
            </form>
          );
        }}
      />
      <Spacing size={2} position="t">
        <Paragraph color="grey-dark" size="xxs">
          Already have an account?{' '}
          <Link
            isUnderlined
            onClick={() => onUserStageChange('signIn')}
            className="text-xxs text-black"
            data-test-id="auth-sign-in"
          >
            Sign in
          </Link>
        </Paragraph>
      </Spacing>
    </>
  );
};

export default SignUpForm;
