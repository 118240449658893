import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import Page from '../../components/utils/Page';

import { PageWrapNew as PageWrap } from '../../components/atoms/PageWrap';
import { ButtonNew as Button } from '../../components/atoms/Button';
import Paragraph from '../../components/atoms/Paragraph';
import Heading from '../../components/atoms/Heading';
import Spacing from '../../components/atoms/Spacing';
import Icon from '../../components/atoms/Icon';
import Alert from '../../components/atoms/Alert';

import AuthenticationContainer from '../../components/molecules/AuthenticationContainer';

import phoneDesktop from '../../img/sprites/phone-desktop.svg';

import styles from './style/general.module.css';
import './style/phone-desktop-animation.css';

const CreatorMobile = () => {
  const { token } = useSelector((state) => state.user, shallowEqual);
  if (token && window.innerWidth >= 900) {
    window.location = '/create';
  }
  const [authenticated, setAuthenticated] = useState();
  useEffect(() => {
    if (authenticated) {
      const timer = setTimeout(() => {
        setAuthenticated(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [authenticated]);

  return (
    <Page title="Create">
      <PageWrap>
        {token ? (
          <Spacing>
            {authenticated && (
              <div className={styles.authSuccess}>
                <Alert
                  kind="success"
                  message={`Sign ${
                    authenticated === 'signUp' ? 'up' : 'in'
                  } successful`}
                />
              </div>
            )}
            <div className={styles.containerWrapper}>
              <div className={styles.container}>
                <div className={styles.phoneDesktopContainer}>
                  <Icon
                    className={styles.phoneDesktop}
                    glyph={phoneDesktop}
                    width={316}
                    height={198}
                  />
                </div>
                <Spacing size={0} position="t">
                  <Heading size="xs">
                    Sorry it's a little cramped in here
                  </Heading>
                </Spacing>
                <Spacing size={1} position="t">
                  <Paragraph size="xxs">
                    Our campaign builder is currently for desktop computers
                    only.
                    {/* We can send you an email so you can quickly get
                    started from a computer later on. */}
                  </Paragraph>
                </Spacing>
                <Spacing size={3} position="t">
                  <Button to="/">Home</Button>
                </Spacing>
              </div>
            </div>
          </Spacing>
        ) : (
          <div className={styles.containerWrapper}>
            <div className={styles.container}>
              <AuthenticationContainer
                initialUserStage="signUp"
                onSuccess={(stage) => {
                  setAuthenticated(stage);
                  if (window.innerWidth >= 900) {
                    window.location = '/create';
                  }
                }}
                isCreator
                noLogo
              />
            </div>
          </div>
        )}
      </PageWrap>
    </Page>
  );
};

export default CreatorMobile;
