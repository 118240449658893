import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import * as shopActions from '../../actions/shop';

import { getText } from '../../helpers/prismic';

import config from '../../config';

import Page from '../../components/utils/Page';
import Loading from '../../components/utils/Loading';

import PageWrap from '../../components/atoms/PageWrap';
import Spacing from '../../components/atoms/Spacing';
import { ButtonNew as Button } from '../../components/atoms/Button';
import Icon from '../../components/atoms/Icon';
import { Grid, GridItem, InlineGrid } from '../../components/atoms/Grid';

import Cta from '../../components/molecules/Cta';
import ProductThumbnail from '../../components/molecules/Thumbnails/ProductThumbnail';

import ShopHero from '../Shop/ShopHero';

import emailIcon from '../../img/sprites/email.svg';
import loadingIcon from '../../img/sprites/loading.svg';

class FiftyFifty extends PureComponent {
  handleLoadMore() {
    this.props.getMoreShopItems(config.fiftyFiftyShop.defaultParams);
  }

  render() {
    const {
      seoTitle,
      hero,
      items,
      itemsCount,
      isLoading,
      isLoadingMore
    } = this.props;

    return (
      <Page title={seoTitle}>
        <ShopHero
          image={hero.image}
          title={hero.title}
          subtitle={hero.description}
          to={hero.slug}
        />
        <PageWrap>
          <Spacing size={4}>
            {isLoading ? (
              <Loading />
            ) : (
              <Grid gap={2}>
                {items.map((item) => (
                  <GridItem key={item.slug} columnSize={[12, 6, 4]}>
                    <Spacing size={2}>
                      <ProductThumbnail
                        slug={item.slug}
                        title={item.title}
                        subTitle={item.subTitle}
                        image={item.imageUrl}
                        price={item.prices}
                        timeLeft={!item.printOnDemand && item.endAt}
                        isGreyBg={item.greyBackground}
                      />
                    </Spacing>
                  </GridItem>
                ))}
              </Grid>
            )}
          </Spacing>
          {items.length <= itemsCount - 1 && (
            <Spacing size={2}>
              <InlineGrid justify="center">
                <Button
                  onClick={this.handleLoadMore.bind(this)}
                  state={isLoadingMore && 'loading'}
                  className="flex items-center"
                >
                  Load more
                  <Spacing size={8} position="l">
                    <Icon glyph={loadingIcon} width={30} height={7} />
                  </Spacing>
                </Button>
              </InlineGrid>
            </Spacing>
          )}

          <Spacing size={8}>
            <Cta icon={emailIcon} kind="newsletter">
              Stay updated: new drops, recommendations, long reads and more
            </Cta>
          </Spacing>
        </PageWrap>
      </Page>
    );
  }
}

const mapStateToProps = ({ location, pages, shop }) => {
  const data = get(pages, '50-50.data', {});

  return {
    seoTitle: get(pages, '50-50.data.seo_title'),
    hero: {
      title: get(data, 'page_title'),
      description: getText(data.description),
      image: get(data.image, 'url'),
      slug: getText(data.slug)
    },
    items: shop.list.byTag['50-50'].items,
    itemsCount: shop.list.byTag['50-50'].itemsCount,
    isLoading: shop.list.isLoading,
    isLoadingMore: shop.list.isLoadingMore
  };
};

export default connect(mapStateToProps, { ...shopActions })(FiftyFifty);
