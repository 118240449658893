import React from 'react';
import scriptLoader from 'react-async-script-loader';
import { StripeProvider, Elements } from 'react-stripe-elements';

import ttfFont from '../../../../fonts/1db703cb-412b-4f13-9991-c4ad09f109b3.ttf';
import woffFont from '../../../../fonts/fc832a5f-8d9c-414d-86a5-24d1932e184d.woff';
import woff2Font from '../../../../fonts/fdf81c22-8f80-4f1a-901c-7edd6ff65831.woff2';

import config from '../../../../config';

export class StripeContainer extends React.Component {
  state = {
    stripe: null
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    if (isScriptLoaded && isScriptLoadSucceed && !prevState.stripe) {
      return {
        stripe: window.Stripe(config.stripeKey)
      };
    } else {
      return null;
    }
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({
        stripe: window.Stripe(config.stripeKey)
      });
    }
  }

  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements
          fonts={
            process.env.NODE_ENV === 'development'
              ? []
              : [
                  {
                    family: '"Helvetica Neue LT Std 53 Extended"',
                    src: `url("${ttfFont}")`
                  },
                  {
                    family: '"Helvetica Neue LT Std 53 Extended"',
                    src: `url("${woffFont}")`
                  },
                  {
                    family: '"Helvetica Neue LT Std 53 Extended"',
                    src: `url("${woff2Font}")`
                  }
                ]
          }
        >
          {this.props.children}
        </Elements>
      </StripeProvider>
    );
  }
}

export default scriptLoader('https://js.stripe.com/v3/')(StripeContainer);
