import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ButtonNew as Button } from '../../atoms/Button';
import { DefaultField as Field, Form } from '../../atoms/Form';
import Icon from '../../atoms/Icon';

import Spacing from '../../atoms/Spacing';
import PageWrap from '../../atoms/PageWrap';

import searchIcon from '../../../img/sprites/search-new.svg';

import generalStyles from './style/general.module.css';

const SearchBox = forwardRef(
  ({ isOpen, searchBoxRef, handleClose, formRef }, ref) => {
    const dispatch = useDispatch();
    const pathname = useSelector((state) => state.location.pathname);
    const isBannerActive = useSelector(
      (state) => state.headerNew.banner.isBannerActive
    );

    const submitSearch = (values, actions) => {
      if (!!values.query.length) {
        dispatch({
          type: 'SHOP',
          query: { q: values.query },
          meta: {
            isGlobalSearch: true,
            pathname
          }
        });

        handleClose('submit');
        actions.resetForm();
        window.scrollTo(0, 0);
      }
    };

    return (
      <PageWrap
        ref={ref}
        size="base"
        className={classNames(generalStyles.searchBox, {
          [generalStyles.searchBoxClosed]: !isOpen,
          [generalStyles.bannerActive]: isBannerActive,
          [generalStyles.bannerInactive]: !isBannerActive
        })}
      >
        <Spacing size="05" position="y" type="padding">
          <Form
            ref={formRef}
            initialValues={{
              query: ''
            }}
            onSubmit={submitSearch}
            render={({ handleSubmit }) => {
              return (
                <form
                  role="search"
                  onSubmit={handleSubmit}
                  className="flex items-center"
                  data-test-id="global-search-form"
                  autoComplete="off"
                >
                  <Field
                    forwardedRef={searchBoxRef}
                    type="search"
                    name="query"
                    placeholder="Search..."
                    noLabel
                    border={false}
                    className={generalStyles.searchInputField}
                    tabIndex={isOpen ? '0' : '-1'}
                  />
                  <Button
                    type="submit"
                    size="small"
                    kind="ghost"
                    tabIndex={isOpen ? '0' : '-1'}
                  >
                    <Icon glyph={searchIcon} width="19" height="19" />
                  </Button>
                </form>
              );
            }}
          />
        </Spacing>
      </PageWrap>
    );
  }
);

SearchBox.propTypes = {
  isOpen: PropTypes.bool,
  searchBoxRef: PropTypes.shape({ current: PropTypes.any }),
  handleClose: PropTypes.func
};

export default SearchBox;
