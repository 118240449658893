import React from 'react';

import { ButtonNew as Button } from '../../../components/atoms/Button';
import Spacing from '../../../components/atoms/Spacing';
import Heading from '../../../components/atoms/Heading';
import Paragraph from '../../../components/atoms/Paragraph';
import PageWrap from '../../../components/atoms/PageWrap';
import MaxWidth from '../../../components/atoms/MaxWidth';

import generalStyles from './style/general.module.css';

export default function ShopHero({ image, title, subtitle, to }) {
  const showButton = window.location.pathname !== to;
  const bgImageProps = {
    className: generalStyles.bgImage,
    style: {
      backgroundImage: `url(${image})`,
      backgroundPosition: 'center'
    }
  };

  return (
    <div className={generalStyles.hero}>
      <PageWrap>
        <div {...bgImageProps} />
        <div className={generalStyles.inner}>
          <Heading color="white" size={['m', 'l', 'xl']} tag="h1">
            {title}
          </Heading>
          <Spacing size="1">
            <MaxWidth value={460}>
              <Paragraph color="white" size="xs" inline>
                {subtitle}
              </Paragraph>
              {showButton && (
                <Button size="tiny" kind="outline" color="white" to={to}>
                  Shop now
                </Button>
              )}
            </MaxWidth>
          </Spacing>
        </div>
      </PageWrap>
    </div>
  );
}
