import isPast from 'date-fns/is_past';
import parse from 'date-fns/parse';
import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import toNumber from 'lodash/toNumber';
import find from 'lodash/find';

const printMethodNameMap = {
  dtg: 'Direct to garment',
  screen: 'Screen',
  default: 'N/A'
};

export const getCampaignPrintMethodName = (campaign) => {
  return campaign.printOnDemand
    ? printMethodNameMap.dtg
    : printMethodNameMap[campaign.printMethod] || printMethodNameMap.default;
};

export const getActiveCampaigns = (campaigns = []) => {
  return campaigns.filter(
    (campaign) =>
      !isPast(parse(campaign.endAt)) &&
      !campaign.draft &&
      campaign.endAt !== null
  );
};

export const getCampaignDefaultProduct = (campaign) => {
  const { featuredColourId, product, colours } = campaign.products[0];

  const featuredColour = colours?.find(
    (colour) => colour.colourId === featuredColourId
  );

  return { sku: product.sku, colourCode: featuredColour?.code };
};

export const getFeaturedProduct = (products = []) => {
  return products.find((product) => product.featured);
};

export const listParentColours = (colours = []) => {
  return colours.flatMap((colour) => colour.parentColours);
};

export const listColourRefs = (colours = []) => {
  return colours.flatMap((colour) => colour.reference);
};

export const listParentColourRefs = (colours) => {
  return flow(listParentColours, listColourRefs)(colours);
};

export const sortByCategory = (items, categories) => {
  return sortBy(
    items,
    (item) =>
      categories.indexOf(item.category) !== -1
        ? categories.indexOf(item.category)
        : items.length // Place unmatched items at the end of the sorted array
  );
};

export const roundUpPrices = (prices = []) => {
  return prices.map(({ iso, price, ...data }) => ({
    ...data,
    iso,
    price: Math.ceil(price)
  }));
};

export const getNumber = (value) => toNumber(value) || 0;

export const getCampaignPlatformConnection = (campaign) => {
  const { platformConnections } = campaign;

  return isEmpty(platformConnections) ? null : platformConnections[0];
};

export const getOffPlatformTotalOrders = (platformBreakdown) => {
  if (isEmpty(platformBreakdown)) return 0;

  const offPlatform = platformBreakdown.filter(
    (item) => item.platformName !== 'Everpress'
  );

  return sumBy(offPlatform, 'orders');
};

export const getCampaignProductImage = (campaign, product) => {
  const { images, defaultSide } = campaign;
  const { colourId, campaignProductId } = product;

  const image = find(images, {
    colourId,
    campaignProductId,
    type: 'grey',
    area: defaultSide
  });

  return image;
};
