import React from 'react';
import Siema from 'siema';
import isEqual from 'react-fast-compare';
import classNames from 'classnames';

import IconButton from '../../IconButton';
import Spacing from '../../Spacing';

import CarouselContext from '../CarouselContext';

import generalStyles from './style/general.module.css';
import controlsStyles from './style/controls.module.css';
import bulletStyles from './style/bullet.module.css';

import arrowRight from '../../../../img/sprites/arrow-right.svg';
import arrowLeft from '../../../../img/sprites/arrow-left.svg';

export default class Carousel extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      currentSlide: 0
    };

    this.triggerPrev = this.triggerPrev.bind(this);
    this.triggerNext = this.triggerNext.bind(this);
    this.setCurrentSlide = this.setCurrentSlide.bind(this);
  }

  componentDidMount() {
    if (this.props.children.length) {
      this.carousel = new Siema({
        selector: this.El,
        onChange: this.setCurrentSlide,
        ...this.props.settings
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { children, ...options } = this.props;

    // We do a map of the children keys to compare then faster
    const prevChildrenIndex = prevProps.children.map((child) => child.key);
    const childrenIndex = this.props.children.map((child) => child.key);

    // If children changed, update the carousel
    if (
      !isEqual(prevChildrenIndex, childrenIndex) &&
      this.props.children.length
    ) {
      if (!this.carousel) {
        this.carousel = new Siema({ selector: this.El, ...options });
      } else {
        // Do something more complex like append, preprend, reinit carousel...
        // we don't need this at the moment as we have simple carousel usages
      }
    }
  }

  setCurrentSlide() {
    this.setState({ currentSlide: this.carousel.currentSlide });
  }

  goTo(index) {
    this.carousel.goTo(index);
  }

  triggerPrev() {
    this.carousel.prev();
  }

  triggerNext() {
    this.carousel.next();
  }

  render() {
    const { children, gap, controls, bullets } = this.props;
    const { currentSlide } = this.state;
    const wrapperClassName = `-mx-${gap}`;
    const Fragment = React.Fragment;

    return (
      <div className={generalStyles.container}>
        {controls && (
          <Fragment>
            <IconButton
              onClick={this.triggerPrev}
              className={controlsStyles.prev}
              size="small"
              glyph={arrowLeft}
              title="Left Arrow"
              kind="secondary"
            />
            <IconButton
              onClick={this.triggerNext}
              className={controlsStyles.next}
              size="small"
              glyph={arrowRight}
              title="Right Arrow"
              kind="secondary"
            />
          </Fragment>
        )}
        <div className={wrapperClassName} ref={(ref) => (this.El = ref)}>
          <CarouselContext.Provider value={{ gap }}>
            {children}
          </CarouselContext.Provider>
        </div>
        {bullets && (
          <Spacing size={2}>
            <div className={bulletStyles.container}>
              {children.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      this.goTo(index);
                    }}
                    className={classNames(bulletStyles.bullet, {
                      [bulletStyles.bulletActive]: currentSlide === index
                    })}
                  />
                );
              })}
            </div>
          </Spacing>
        )}
      </div>
    );
  }
}

Carousel.defaultProps = {
  gap: 0,
  controls: true,
  bullets: false
};
