import parse from 'date-fns/parse';
import isPast from 'date-fns/is_past';
import flow from 'lodash/flow';

export const getCampaignIds = (campaigns) => {
  return campaigns.map((campaign) => campaign.campaignId);
};

export const getLaunchedCampaigns = (campaigns) => {
  return campaigns.filter((campaign) => !campaign.draft);
};

export const getLiveCampaigns = (campaigns) => {
  return campaigns.filter((campaign) => !isPast(parse(campaign.endAt)));
};

export const getEndedCampaigns = (campaigns) => {
  return campaigns.filter((campaign) => isPast(parse(campaign.endAt)));
};

export const getEndedLaunchedCampaigns = (campaigns) => {
  return flow([getLaunchedCampaigns, getEndedCampaigns])(campaigns);
};
