import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from 'react-stripe-elements';
import classNames from 'classnames';
import upperFirst from 'lodash/upperFirst';
import lowerCase from 'lodash/lowerCase';

import { theme } from '../../../../../theme-config';

import securityLock from '../../../../../img/sprites/icon-lock.svg';

import { DefaultError as Error } from '../../DefaultError';

import Icon from '../../../Icon';

import generalStyles from './style/general.module.css';

export class NewFieldStripe extends React.Component {
  state = { error: null, focused: false, hasValue: false };

  handleChange({ error, empty }) {
    this.setState({ error: error, hasValue: !empty });
  }

  render() {
    const { type, disabled, label } = this.props;
    const { error, hasValue } = this.state;
    const StripeElement = type;

    const fieldClassName = classNames(
      generalStyles.container,
      generalStyles.border,
      error
        ? generalStyles.isNotValid
        : {
            'border-blue-primary': this.state.focused,
            'border-grey-lighter': !this.state.focused
          }
    );

    return (
      <Fragment>
        <div className={fieldClassName}>
          <div className={generalStyles.flexInput}>
            {hasValue && (
              <label
                className={classNames(generalStyles.label, {
                  'text-grey-primary': disabled,
                  'text-grey-dark': !disabled
                })}
              >
                {label}
              </label>
            )}
            <StripeElement
              className={classNames(
                generalStyles.fieldStripe,
                hasValue ? generalStyles.fieldStripeValue : 'py-15'
              )}
              onChange={this.handleChange.bind(this)}
              placeholder={label}
              onFocus={() => {
                this.setState({ focused: true });
              }}
              onBlur={() => {
                this.setState({ focused: false });
              }}
              style={{
                base: {
                  fontFamily: theme.fontFamily.sans.toString(),
                  fontSize: '12px'
                }
              }}
            />
          </div>
          {type === CardNumberElement && (
            <Icon
              className={generalStyles.icon}
              glyph={securityLock}
              fill={theme.colors.black}
              width={14}
              height={18}
            />
          )}
        </div>
        {error && <Error>{upperFirst(lowerCase(error.message))}</Error>}
      </Fragment>
    );
  }
}

NewFieldStripe.propTypes = {
  type: PropTypes.oneOf([CardNumberElement, CardExpiryElement, CardCVCElement])
};
