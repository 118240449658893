import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import * as yup from 'yup';

import * as priceCalculatorActions from '../../actions/price-calculator';

import Loading from '../../components/utils/Loading';
import Page from '../../components/utils/Page';
import PageWrap from '../../components/atoms/PageWrap';
import { Form, validateFormWithSchema } from '../../components/atoms/Form';
import Spacing from '../../components/atoms/Spacing';
import Heading from '../../components/atoms/Heading';
import MaxWidth from '../../components/atoms/MaxWidth';
import Paragraph from '../../components/atoms/Paragraph';
import LiveChat from '../../components/atoms/LiveChat';

import PriceCalculatorForm from './PriceCalculatorForm';

import accessibleStyles from './PriceCalculatorForm/style/accessible.module.css';

class PriceCalculator extends PureComponent {
  constructor(props) {
    super();
    this.getPrice = debounce(this.getPrice, 300);
    this.state = {
      initialFormValues: {
        product: null,
        price: '',
        darkGarment: false,
        coloursFront: 1,
        coloursBack: 0,
        quantity: 50
      }
    };
    this.currentFormData = this.state.initialFormValues;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = { ...prevState };

    if (!prevState.product && nextProps.isReady) {
      newState.initialFormValues.product = nextProps.garments.find(
        (item) => item.featured === true
      ).sku;
      newState.initialFormValues.currency = nextProps.defaultCurrency;
    }

    return newState;
  }

  componentDidMount() {
    if (this.props.isReady) {
      this.init();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Once data is ready, fetch prices
    if (!prevProps.isReady && this.props.isReady) {
      this.init();
    }
  }

  init() {
    this.getPrice(this.state.initialFormValues);
  }

  getPrice(formData) {
    const changedValue = Object.keys(formData).find((key) => {
      return formData[key] !== this.currentFormData[key];
    });

    /* Always include price when price and quantity change
     * Do not include price when product or currency change
     * Other changes, include price when it's different than recommendedPrice
     */
    const shouldIncludePrice =
      ['price', 'quantity'].includes(changedValue) ||
      (!['product', 'currency'].includes(changedValue) &&
        this.props.payout.requested.products &&
        formData.price !==
          this.props.payout.requested.products[0].recommendedPrice);

    if (shouldIncludePrice) {
      this.props.getPayout(formData);
    } else {
      const { price, ...filteredFormData } = formData;
      this.props.getPayout(filteredFormData);
    }

    this.currentFormData = formData;
  }

  handleChange(formData, { isValid }) {
    if (isValid) {
      this.getPrice(formData);
    }
  }

  handleValidate(values) {
    const schema = {
      quantity: yup
        .number()
        .min(1)
        .max(3000)
    };

    // if there is a price set, validate it, otherwise ignore it
    if (this.props.payout.requested.products) {
      schema.price = yup
        .number()
        .min(this.props.payout.requested.products[0].minimumPrice)
        .required();
    }

    return validateFormWithSchema(yup.object().shape(schema), values);
  }

  render() {
    const { garments, payout, isReady } = this.props;

    return (
      <Page title="Price Calculator">
        <PageWrap>
          <header className={accessibleStyles.readerContainer} tabIndex={0}>
            <Spacing size={4}>
              <Heading size={['l', 'xl', 'xxl']} tag="h1">
                Calculate your earnings
              </Heading>
              <MaxWidth value={645}>
                <Paragraph>
                  Use this calculator to estimate your payout based on the
                  number of items sold. <br />
                  Costs and charges are estimates and not finalised until your
                  campaign has ended. <br />
                  Your profits increase as more items are sold and you benefit
                  from the same economies of scale as traditional printing.
                </Paragraph>
              </MaxWidth>
            </Spacing>
          </header>
          {payout.requested.products ? (
            <Form
              initialValues={this.state.initialFormValues}
              enableReinitialize={!isReady}
              onSubmit={this.getPrice.bind(this)}
              onChange={this.handleChange.bind(this)}
              validate={this.handleValidate.bind(this)}
              render={(formikProps) => (
                <PriceCalculatorForm
                  {...formikProps}
                  garments={garments}
                  payout={payout}
                />
              )}
            />
          ) : (
            <Loading />
          )}
        </PageWrap>
        <LiveChat />
      </Page>
    );
  }
}

const mapStateToProps = ({ location, user, garments, priceCalculator }) => {
  return {
    garments: garments.items,
    defaultCurrency: user.currency,
    payout: priceCalculator,
    isReady: !!garments.items.length && !!user.currency
  };
};

export default connect(mapStateToProps, { ...priceCalculatorActions })(
  PriceCalculator
);
