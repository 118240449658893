import React, { Fragment, useState } from 'react';
import * as yup from 'yup';
import omit from 'lodash/omit';

import { ButtonNew as Button } from '../../../components/atoms/Button';
import Spacing from '../../../components/atoms/Spacing';
import {
  DefaultField as Field,
  Form,
  Error
} from '../../../components/atoms/Form';
import Paragraph from '../../../components/atoms/Paragraph';
import SelectCountries from '../../../components/atoms/Select/SelectCountries';

import { CtaBox } from '../../../components/molecules/Box';

import AddressSwitch from './AddressSwitch';

import getFullCountryName from '../../../helpers/getFullCountryName';

import { AddressSwitchContext } from './AddressSwitchContext';

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .nullable()
    .required(),
  lastName: yup
    .string()
    .nullable()
    .required(),
  line1: yup
    .string()
    .nullable()
    .max(45)
    .required(),
  line2: yup
    .string()
    .nullable()
    .max(45),
  city: yup
    .string()
    .nullable()
    .required(),
  postcode: yup
    .string()
    .nullable()
    .required(),
  country: yup
    .string()
    .nullable()
    .required()
});

const CheckoutShippingAddress = ({
  onSave,
  onEdit,
  isSaved,
  deliveryAddress,
  user = { country: 'GB' },
  paymentProvider
}) => {
  const country = deliveryAddress.country || user.country;
  const initialValues = { ...deliveryAddress, country: country };
  const countryName = getFullCountryName(country) || country;

  const [saveEnabled, setSaveEnabled] = useState(!!deliveryAddress.postcode);

  const handleSubmit = async (values, actions) => {
    await onSave(omit(values, ['countryName', 'search', 'fullCountry']));

    actions.setSubmitting(false);
  };

  const handleEditStep = () => {
    onEdit();
  };

  return (
    <Fragment>
      {isSaved || paymentProvider === 'paypal' ? (
        <Spacing size={1} position="t">
          <CtaBox
            title="2. Shipping Address"
            cta={paymentProvider === 'paypal' ? null : 'Edit'}
            onClick={handleEditStep}
          >
            <Paragraph size="xxs">
              {deliveryAddress.firstName} {deliveryAddress.lastName}
              <br />
              {deliveryAddress.line1}
              {deliveryAddress.line2 && ', '}
              {deliveryAddress.line2}
              ,&nbsp;
              {deliveryAddress.city}
              <br />
              {deliveryAddress.county}
              {deliveryAddress.county && ', '}
              {deliveryAddress.postcode}
              ,&nbsp;
              {countryName}
            </Paragraph>
          </CtaBox>
        </Spacing>
      ) : (
        <Form
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          isInitialValid={validationSchema.isValidSync(initialValues)}
          onSubmit={handleSubmit}
          render={({
            isSubmitting,
            handleSubmit,
            status,
            values,
            setValues,
            isValid,
            submitCount
          }) => {
            let statusMessage = null;
            const { error } = { ...status };
            if (error) {
              statusMessage = (
                <Error>
                  <span className="text-xxxs">{status.error}</span>
                </Error>
              );
            }

            const handleAddressLookupUpdate = (addressFields) => {
              setValues(addressFields, false);
            };

            return (
              <form onSubmit={handleSubmit}>
                <Spacing size={3} position="b">
                  <Spacing size={1}>
                    <Field
                      type="text"
                      name="firstName"
                      placeholder="First name"
                    />
                  </Spacing>
                  <Spacing size={1}>
                    <Field
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                    />
                  </Spacing>
                </Spacing>

                <Spacing size={1}>
                  <SelectCountries
                    name="country"
                    placeholder="Country"
                    id="country-select"
                    useNewStyles
                  />
                </Spacing>

                <AddressSwitchContext.Provider
                  value={{
                    manualStep: saveEnabled,
                    setManualStep: setSaveEnabled
                  }}
                >
                  <AddressSwitch
                    values={values}
                    onAddressLookupUpdate={handleAddressLookupUpdate}
                    validationProps={{
                      isSubmitting,
                      isValid,
                      submitCount
                    }}
                  />
                </AddressSwitchContext.Provider>

                {saveEnabled && (
                  <Spacing size={4} position="b">
                    <Button
                      type="submit"
                      state={isSubmitting ? 'loading' : null}
                      fullWidth
                      data-test-id="step-2-submit"
                    >
                      Save and Continue
                    </Button>
                    <Spacing size={15} position="t">
                      {statusMessage}
                    </Spacing>
                  </Spacing>
                )}
              </form>
            );
          }}
        />
      )}
    </Fragment>
  );
};

export default CheckoutShippingAddress;
