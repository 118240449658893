const initialState = [
  {
    iso: 'GBP',
    name: 'British Pound',
    symbol: '£',
    precision: 2,
    rate: 1,
    reference: true
  }
];

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FETCH_CURRENCIES_FULFILLED':
      return action.payload;
    default:
      return state;
  }
};
