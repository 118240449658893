import React from 'react';

import { Grid } from '../../../atoms/Grid';

export default function TableHeader({ className, children }) {
  return (
    <Grid className={className} gap={0}>
      {children}
    </Grid>
  );
}
