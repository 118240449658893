import React from 'react';

import { InlineGrid } from '../../../components/atoms/Grid';
import Icon from '../../../components/atoms/Icon';
import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';
import MaxWidth from '../../../components/atoms/MaxWidth';
import Tooltip from '../../../components/atoms/Tooltip';

import zapIcon from '../../../img/sprites/zap.svg';

const CampaignPODShippingInfo = (props) => {
  const tooltipContent = (
    <MaxWidth value={300}>
      We’ll print this design as soon as you order it so we can send it to you
      quickly. We’ll still only print what we sell so there’s still no waste.
    </MaxWidth>
  );

  return (
    <InlineGrid justify="start" align="center" {...props}>
      <Spacing size="05" position="r">
        <Icon glyph={zapIcon} width={22} height={22} />
      </Spacing>
      <Paragraph color="darkest-dark-grey" size="xxs">
        <Tooltip placement="top" content={tooltipContent}>
          <span className="text-blue-primary border-b border-dotted border-blue-primary">
            Fast shipping
          </span>
        </Tooltip>{' '}
        so no waiting for the campaign to end
      </Paragraph>
    </InlineGrid>
  );
};

export default CampaignPODShippingInfo;
