import React from 'react';
import orderBy from 'lodash/orderBy';

import Heading from '../../../../components/atoms/Heading';
import Spacing from '../../../../components/atoms/Spacing';
import Paragraph from '../../../../components/atoms/Paragraph';
import MaxWidth from '../../../../components/atoms/MaxWidth';
import Iframely from '../../../../components/atoms/Iframely';
import Image from '../../../../components/atoms/Image';

import generalStyles from './style/general.module.css';

const CampaignInfo = ({ campaign }) => {
  const content = campaign.content;
  const orderContent = orderBy(content, 'position', 'asc');

  return (
    <div className={generalStyles.moreInfoContent}>
      <Spacing size={8}>
        <MaxWidth value={645} center>
          {orderContent.map(item => {
            switch (item.typeReference) {
              case 'heading':
                return (
                  <Spacing size={3} key={item.body}>
                    <Heading>{item.body}</Heading>
                  </Spacing>
                );
              case 'text':
                return (
                  <Spacing size={3} key={item.body}>
                    <Paragraph
                      className={generalStyles.moreInfoText}
                      dangerouslySetInnerHTML={{ __html: item.body }}
                    />
                  </Spacing>
                );
              case 'image':
                return (
                  <Spacing size={3} key={item.body}>
                    <Image
                      className="mx-auto block"
                      src={item.body}
                      alt={campaign.title}
                      withPlaceholder={false}
                    />
                  </Spacing>
                );
              case 'iframely':
                return (
                  <Spacing size={3} key={item.body}>
                    <Iframely url={item.body} />
                  </Spacing>
                );
              default:
                return false;
            }
          })}
        </MaxWidth>
      </Spacing>
    </div>
  );
};

export default CampaignInfo;
