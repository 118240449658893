import React from 'react';
import PropTypes from 'prop-types';

import { ButtonNew as Button } from '../../../../components/atoms/Button';
import { Error } from '../../../../components/atoms/Form';
import { InlineGrid } from '../../../../components/atoms/Grid';
import Spacing from '../../../../components/atoms/Spacing';

import {
  Modal,
  ModalContent,
  ModalHeader
} from '../../../../components/molecules/Modal';

const RelaunchErrorModal = ({ isOpen, onClose, errorBody }) => {
  return (
    <Modal isTransparent isOpen={isOpen} onRequestClose={onClose}>
      <Spacing size={[2, 4, 4, 5]} position="all">
        <ModalHeader>Unable to relaunch campaign.</ModalHeader>
        <ModalContent>
          <Error>{errorBody}</Error>
          <Spacing position="t">
            <InlineGrid direction={['col', 'row']} align="start">
              <Button
                kind="outline"
                color="black"
                className="w-full"
                onClick={onClose}
              >
                Close
              </Button>
            </InlineGrid>
          </Spacing>
        </ModalContent>
      </Spacing>
    </Modal>
  );
};

RelaunchErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  errorBody: PropTypes.string.isRequired
};

export default RelaunchErrorModal;
