import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import config from '../../../../config';

import { CSSGrid, CSSGridItem, InlineGrid } from '../../../atoms/Grid';
import Heading from '../../../atoms/Heading';
import Image from '../../../atoms/Image';
import Price from '../../../atoms/Price';
import Spacing from '../../../atoms/Spacing';

import generalStyles from './style/general.module.css';

const imageContainerStyles = {
  minHeight: `${54 / config.productShotsRatio}px`
};

const ProductOverview = ({ children, ...props }) => {
  const { name, image, price, colour, currency, quantity, size } = props;
  const isCustomImageFn = isFunction(image);
  return (
    <CSSGrid template="repeat(2, auto) / auto 1fr" columnGap={15}>
      <CSSGridItem row={{ start: 1, end: 2 }} column={{ start: 1, end: 2 }}>
        <div className={generalStyles.container} style={imageContainerStyles}>
          {isCustomImageFn ? (
            image()
          ) : (
            <Image
              src={image}
              alt={name}
              width={64}
              height={54 / config.productShotsRatio}
            />
          )}
        </div>
      </CSSGridItem>
      <CSSGridItem row={{ start: 1, end: 2 }} column={{ start: 2, span: 2 }}>
        <InlineGrid align="start">
          <Heading size="xxs" className="leading-normal">
            {name}
          </Heading>
          {price != null && (
            <>
              <Spacing size={1} position="l" />
              <Price currency={currency} value={price} size="xxs" />
            </>
          )}
        </InlineGrid>
        <div className={generalStyles.options}>
          {colour && (
            <span className={generalStyles.option}>{colour.name}</span>
          )}
          {size && <span className={generalStyles.option}>{size}</span>}
          <span className={generalStyles.option}>Qty: {quantity}</span>
        </div>
      </CSSGridItem>
      {children && (
        <CSSGridItem row={{ start: 2, end: 3 }} column={{ start: 2, span: 2 }}>
          {children}
        </CSSGridItem>
      )}
    </CSSGrid>
  );
};

export default ProductOverview;

ProductOverview.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  price: PropTypes.number,
  colour: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  currency: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  quantity: PropTypes.number.isRequired,
  size: PropTypes.string
};
