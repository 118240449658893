import React, { useRef, useEffect } from 'react';
import * as yup from 'yup';

import Spacing from '../../../atoms/Spacing';
import Heading from '../../../atoms/Heading';
import {
  DefaultField as Field,
  Form,
  handleFormError
} from '../../../atoms/Form';
import Link from '../../../atoms/Link';
import Paragraph from '../../../atoms/Paragraph';
import { ButtonNew as Button } from '../../../atoms/Button';
import Alert from '../../../atoms/Alert';

const SignInForm = ({
  onSignIn,
  onUserStageChange,
  existingUserDetails = {
    email: '',
    password: ''
  },
  onExistingUserDetailsChange
}) => {
  const { email, password } = existingUserDetails;
  const formRef = useRef();

  useEffect(() => {
    if (email !== '' && password !== '' && formRef.current) {
      onExistingUserDetailsChange({
        email: '',
        password: ''
      });
      formRef.current.handleSubmit({ email, password });
    }
  }, [
    formRef,
    email,
    password,
    onExistingUserDetailsChange,
    existingUserDetails
  ]);

  const handleSignIn = async ({ email, password }, actions) => {
    actions.setSubmitting(true);

    try {
      await onSignIn({ email, password });
    } catch (error) {
      handleFormError(error, actions);
    }
    actions.setSubmitting(false);
  };

  return (
    <>
      <Heading size="xs">Sign in to your account</Heading>
      <Form
        initialValues={{
          email: email || '',
          password: password || ''
        }}
        ref={formRef}
        validationSchema={yup.object().shape({
          email: yup.string().required(),
          password: yup.string().required()
        })}
        onSubmit={handleSignIn}
        render={({ isSubmitting, handleSubmit, status }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="text-left">
                <Spacing size={2} position="t">
                  <Spacing size={1}>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email address"
                      data-test-id="email"
                    />
                  </Spacing>
                  <Spacing size={1} position="t">
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                  </Spacing>
                </Spacing>
              </div>
              <Spacing size={2}>
                <Paragraph color="grey-dark" size="xxxs">
                  <Link onClick={() => onUserStageChange('resetPassword')}>
                    Forgot password
                  </Link>
                </Paragraph>
              </Spacing>
              <Spacing size={2} position="b">
                <Button
                  type="submit"
                  state={isSubmitting ? 'loading' : null}
                  fullWidth
                >
                  Sign in
                </Button>
              </Spacing>
              {status?.error && (
                <Spacing size={1} position="t">
                  <div className="text-left">
                    <Alert message={status.error} />
                  </div>
                </Spacing>
              )}
            </form>
          );
        }}
      />

      <Spacing size={2} position="t">
        <Paragraph color="grey-dark" size="xxs">
          Don’t have an account?{' '}
          <Link
            isUnderlined
            onClick={() => onUserStageChange('signUp')}
            className="text-xxs text-black"
            data-test-id="auth-sign-up"
          >
            Sign up
          </Link>
        </Paragraph>
      </Spacing>
    </>
  );
};

export default SignInForm;
