import React, { useRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Swiper } from 'swiper/react';
import SwiperCore, { A11y } from 'swiper';
import PropTypes from 'prop-types';

import 'swiper/swiper.min.css';
import 'swiper/components/a11y/a11y.min.css';

import { Grid, GridItem } from '../../../atoms/Grid';
import Heading from '../../../atoms/Heading';
import IconButton from '../../../atoms/IconButton';
import Paragraph from '../../../atoms/Paragraph';
import Spacing from '../../../atoms/Spacing';

import rightArrowIcon from '../../../../img/sprites/chevron-right.svg';
import leftArrowIcon from '../../../../img/sprites/chevron-left.svg';
import { theme } from '../../../../theme-config';

SwiperCore.use([A11y]);

const Rail = ({ type, title, subtitle, breakpoints, children, withControls, ...props }) => {
  const dispatch = useDispatch();
  const swiperRef = useRef(null);
  const [hasUserScrolled, setHasUserScrolled] = useState(false);

  const prevSlide = useCallback(() => {
    swiperRef.current?.swiper.slidePrev();
  }, [swiperRef]);

  const nextSlide = useCallback(() => {
    swiperRef.current?.swiper.slideNext();
  }, [swiperRef]);

  const handleRailScroll = () => {
    if (!hasUserScrolled) {
      dispatch({
        type: 'RAIL_SCROLL',
        payload: {
          type,
          title
        }
      });
      setHasUserScrolled(true);
    }
  };

  return (
    <>
      <Grid gap={2} justify="space-between">
        <GridItem columnSize="auto">
          {title ? (
            <Heading size="s" tag="h2">
              {title}
            </Heading>
          ) : null}
          {subtitle ? (
            <Spacing size={1} position="t">
              <Paragraph size="xxs" color="grey-dark">
                {subtitle}
              </Paragraph>
            </Spacing>
          ) : null}
        </GridItem>

        {withControls && (
          <GridItem columnSize="auto" className="hidden md:block">
            <IconButton
              kind="secondary"
              glyph={leftArrowIcon}
              title="Left"
              onClick={prevSlide}
            />
            <IconButton
              className="ml-1"
              kind="secondary"
              glyph={rightArrowIcon}
              title="Right"
              onClick={nextSlide}
            />
          </GridItem>
        )}
      </Grid>
      <Spacing size={2} position="t">
        <Swiper
          ref={swiperRef}
          threshold={5}
          resizeObserver={false}
          breakpoints={breakpoints}
          a11y={{ slideLabelMessage: 'campaign {{index}} / {{slidesLength}}' }}
          onSlideChange={handleRailScroll}
          {...props}
        >
          {children}
        </Swiper>
      </Spacing>
    </>
  );
};

Rail.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  spaceBetweenSlides: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape(PropTypes.number)
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

Rail.defaultProps = {
  withControls: true,
  breakpoints: {
    0: {
      slidesPerView: 1.25,
      spaceBetween: 10
    },
    [parseInt(theme.screens.sm)]: {
      slidesPerView: 3.25,
      spaceBetween: 10
    },
    [parseInt(theme.screens.md)]: {
      slidesPerView: 4.25,
      spaceBetween: 20
    },
    [parseInt(theme.screens.lg)]: {
      slidesPerView: 5.25,
      spaceBetween: 20
    }
  }
};

export default Rail;
