import React from 'react';

import Thanks from '../../../components/molecules/Thanks';

import getStartedItems from './getStartedItems';

const CreatorRewardsThanks = () => {
  return (
    <Thanks
      title="Great, once you launch a campaign and it reaches the minimum sales we'll top up your profits!"
      description="Build a campaign now or get some inspiration from the Everpress creator community."
      items={getStartedItems}
      size="s"
    />
  );
};

export default CreatorRewardsThanks;
