import React, { useState, useEffect } from 'react';
import { Wizard } from 'react-use-wizard';

import config from '../../config';

import useLocalStorage from "../../helpers/hooks/useLocalStorage";

import Page from '../../components/utils/Page';

import PageWrap from '../../components/atoms/PageWrap';

import Navigation from './Navigation';
import ArtworkUploader from './ArtworkUploader';
import PriceCalculator from './PriceCalculator';
import MobileOverlay from './MobileOverlay';

import generalStyles from './style/general.module.css';

const CampaignBuilder = () => {
  const [isMobile, setMobile] = useState(false);
  const [builderLsState] = useLocalStorage(config.localStorageBuilderKey);

  useEffect(() => {
    const mql = window.matchMedia(`(min-width: 1200px)`);

    const handleViewportChange = () => {
      setMobile(!mql.matches);
    };

    handleViewportChange();

    mql.addEventListener('change', handleViewportChange);

    return () => mql.removeEventListener('change', handleViewportChange);
  }, []);

  const step = builderLsState?.step ?? 0;

  if (isMobile) return <MobileOverlay />;

  return (
    <Page title="Create Campaign">
      <PageWrap className="no-overflow-anchor">
        <div className={generalStyles.container}>
          <Wizard startIndex={step} header={<Navigation />}>
            <ArtworkUploader />
            <PriceCalculator />
          </Wizard>
        </div>
      </PageWrap>
    </Page>
  );
};

export default CampaignBuilder;
