import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import { theme } from '../../../../theme-config';

import './style/select-global.css';

class Select extends React.Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    onCustomChange: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    className: PropTypes.string
  };

  static defaultProps = {
    onChange: () => {},
    onBlur: () => {}
  };

  handleChange(option) {
    this.props.setFieldValue(this.props.name, option.value);
    this.props.onCustomChange && this.props.onCustomChange(option);
  }

  handleBlur() {
    this.props.setFieldTouched(this.props.name, true);
  }

  render() {
    const {
      onCustomChange,
      standalone,
      disabled,
      className,
      options,
      value,
      ...props
    } = this.props;
    const selectedOption = value;

    const customStyles = {
      container: (provided, state) => {
        const borderColorThemed = state.isFocused
          ? theme.extend.colors.blue.primary
          : theme.extend.colors.grey.lighter;
        const borderRadius = state.selectProps.menuIsOpen
          ? `${theme.extend.borderRadius.md} ${theme.extend.borderRadius.md} 0 0`
          : theme.extend.borderRadius.md;
        return {
          ...provided,
          borderRadius,
          border: `1px ${borderColorThemed} solid`
        };
      },
      option: (provided, state) => {
        return {
          ...provided,
          backgroundColor: state.isFocused
            ? theme.extend.colors.grey.lightest
            : theme.colors.white,
          color: theme.colors.black,
          fontSize: theme.fontSize.xxs,
          padding: '15px',
          margin: 0,
          borderBottom: `1px ${theme.extend.colors.grey.light} solid`
        };
      },
      control: (provided) => ({
        ...provided,
        border: 'none',
        boxShadow: 'none !important',
        ':hover': { border: 'none', boxShadow: 'none' },
        ':after': { border: 'none', boxShadow: 'none' }
      }),
      menu: (provided) => ({
        ...provided,
        border: `1px ${theme.colors.black} solid`,
        margin: 0,
        zIndex: 90,
        borderRadius: `0 0 ${theme.extend.borderRadius.md} ${theme.extend.borderRadius.md}`
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: theme.extend.colors.grey.lighter
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        width: '15px',
        padding: 0,
        margin: '13px 16px',
        color: theme.colors.black,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none'
      }),
      placeholder: (provided) => ({
        ...provided,
        color: theme.extend.colors.grey.dark
      }),
      valueContainer: (provided) => ({
        ...provided,
        paddingLeft: '14px',
        top: value ? '7px' : 0,
        fontSize: theme.fontSize.xxs
      }),
      singleValue: (provided) => ({
        ...provided,
        margin: 0,
        color: theme.colors.black,
        lineHeight: theme.lineHeight.snug
      })
    };

    return (
      <div>
        {standalone ? (
          <ReactSelect
            styles={customStyles}
            options={options}
            value={options.filter(
              (option) =>
                (selectedOption &&
                  selectedOption.value &&
                  option.value === selectedOption.value) ||
                option.value === selectedOption
            )}
            isSearchable={false}
            clearable={false}
            isDisabled={disabled}
            className={className}
            aria-label={props.placeholder || 'Select'}
            classNamePrefix="Select"
            {...props}
          />
        ) : (
          <ReactSelect
            styles={customStyles}
            options={options}
            value={options.filter(
              (option) =>
                (selectedOption &&
                  selectedOption.value &&
                  option.value === selectedOption.value) ||
                option.value === selectedOption
            )}
            isSearchable={false}
            clearable={false}
            isDisabled={disabled}
            aria-label={props.placeholder || 'Select'}
            classNamePrefix="Select"
            {...props}
            onChange={this.handleChange.bind(this)}
            onBlur={this.handleBlur.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default Select;
