import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import IconButton from '../../../atoms/IconButton';

import generalStyles from './style/general.module.css';
import controlsStyles from './style/controls.module.css';

import crossIcon from '../../../../img/sprites/round-cross.svg';

const cx = classNames.bind({ ...generalStyles, ...controlsStyles });

class Modal extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool,
    isTransparent: PropTypes.bool,
    isCloseable: PropTypes.bool,
    isWide: PropTypes.bool,
    onRequestClose: PropTypes.func
  };

  static defaultProps = {
    isOpen: false,
    isTransparent: false,
    isCloseable: true,
    isWide: false,
    onRequestClose: null
  };

  state = {
    forceClose: false
  };

  handleCloseClick() {
    if (!this.props.isCloseable) {
      return;
    }

    if (this.props.onRequestClose === null) {
      this.setState({ forceClose: true });
    } else {
      this.props.onRequestClose();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      isOpen: !prevState.forceClose && nextProps.isOpen
    };
  }

  render() {
    const { children, isTransparent, isCloseable, isWide } = this.props;
    const { isOpen } = this.state;

    return (
      <ReactModal
        isOpen={isOpen}
        contentLabel="Modal"
        className={{
          base: cx('modal', {
            'modal-transparent': isTransparent,
            'modal-wide': isWide,
            'inset-0': !isTransparent
          }),
          afterOpen: 'modal--after-open',
          beforeClose: 'modal--before-close'
        }}
        overlayClassName={{
          base: cx('modalOverlay', {
            'modalOverlay-transparent': isTransparent,
            'bg-white': !isTransparent,
            'bg-transparent': isTransparent
          }),
          afterOpen: 'modal__overlay--after-open',
          beforeClose: 'modal__overlay--before-close'
        }}
        onRequestClose={this.handleCloseClick.bind(this)}
      >
        {children}

        {isCloseable && (
          <div
            className={cx('closeHolder', {
              'closeHolder-floating': !isTransparent
            })}
          >
            <IconButton
              title="Close"
              className={cx('close')}
              onClick={this.handleCloseClick.bind(this)}
              kind="ghost"
              glyph={crossIcon}
            />
          </div>
        )}
      </ReactModal>
    );
  }
}

export default Modal;
