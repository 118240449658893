import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { logout } from '../../../actions/user';

import SuccessFeedback from '../../atoms/SuccessFeedback';
import { InlineGrid } from '../../atoms/Grid';
import Link from '../../atoms/Link';

import generalStyles from './style/general.module.css';

const BoxInputFeedback = ({ email, onClick, logout }) => {
  const handleSignOut = () => {
    logout();

    if (onClick) onClick();
  };

  return (
    <div className={classNames(generalStyles.container)}>
      <InlineGrid>
        <SuccessFeedback message={`Signed in as ${email}`} />
        <Link onClick={handleSignOut} isUnderlined={false} className="text-xxs">
          Sign out
        </Link>
      </InlineGrid>
    </div>
  );
};

BoxInputFeedback.propTypes = {
  email: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

BoxInputFeedback.defaultProps = {
  onClick: null
};

export default connect(null, {
  logout
})(BoxInputFeedback);
