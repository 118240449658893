import React from 'react';
import classnames from 'classnames';

import { GridItem } from '../../atoms/Grid';
import Link from '../../atoms/Link';
import Heading from '../../atoms/Heading';
import Paragraph from '../../atoms/Paragraph';

import generalStyles from './style/general.module.css';

export default function CaseStudiesTile({
  hover,
  background,
  to,
  title,
  subtitle,
  isCategory
}) {
  return (
    <GridItem columnSize={[12, 12, 6]}>
      <Link
        style={{ backgroundImage: `url(${background})` }}
        className={classnames(generalStyles.tile, {
          [generalStyles.tile_hover]: hover
        })}
        to={to}
      >
        <div
          className={classnames(generalStyles.tileInner, {
            [generalStyles.tileInner_isCategory]: isCategory
          })}
        >
          <Heading
            color={hover ? 'black' : 'white'}
            size={['l', 'l', 'l', 'xl', 'xxl']}
            className={generalStyles.tileTitle}
          >
            {title}
          </Heading>
          {subtitle && (
            <Paragraph
              color="black"
              size="s"
              className={generalStyles.tileSubtitle}
            >
              {subtitle}
            </Paragraph>
          )}
        </div>
      </Link>
    </GridItem>
  );
}
