export const faq = [
  {
    title: 'B Corp Accreditation',
    content:
      'Through a combination of third-party validation, public transparency, and legal accountability, B Corp accreditation means being held to account as a business. The scope of B Corp is far-reaching, but at its core it means every aspect of business ought to be conducted as if people and place mattered, both within and outside of the company.'
  },
  {
    title: 'Equality Monitoring',
    content:
      "Just like we introduced equality monitoring internally we're working on ways to accurately understand who we work with and how we can be more representative moving forward."
  },
  {
    title: 'Open Door Hour',
    content:
      'Every week our Diversity Inclusion and Equity Manager holds an open doors forum for staff to bring feedback and concerns, or to seek advice. This is available to all, and is in complete anonymity too.'
  },
  {
    title: 'Mental Health Days',
    content:
      'Mental health is treated like physical health, and we encourage our team to take time off when they need it. As well has having full team days off when we need it.'
  },
  {
    title: 'Flexible Bank Holidays',
    content:
      'We now give employees the option to flex their bank holidays so they can observe and recognise other religious or national holidays that they’d prefer.'
  },
  {
    title: 'Culture Survey',
    content:
      "Twice a year we invite our team to submit anonymous feedback on our culture and their experience working at EVERPRESS. The results largely shape the People strategy and initiatives to tackle areas where we can have a better impact on our team's experience."
  },
  {
    title: 'Policies to Protect Everyone',
    content:
      'We reviewed our hiring, appraisal and disciplinary processes with the goal of removing blockers to genuine inclusivity and compassion. And introduced formal policies to look after our staff.'
  },
  {
    title: 'Diversity & Inclusion Induction',
    content:
      'Every person who starts working on our team has a full D&I induction. This means that everyone on our team knows and understands from the very outset the standards we want to uphold.'
  },
  {
    title: 'Compulsory D&I Workshops',
    content:
      "And this training continues for everyone through the time they're on the team, with D&I workshops to augment our knowledge and ensure we're continuing to work inclusively."
  },
  {
    title: 'Activism Days',
    content:
      'We take a holistic approach to internal culture, which means emboldening our team to help in the community too. Every year each member of our team is entitled to take two activism days, to work with any organisation of their choosing.'
  },
  {
    title: 'Ongoing Events',
    content:
      'We keep an ever-changing programme of events to promote discussion and change, including: workshops on LGBT inclusion and gender, as well as external expert speakers, who can share their experience and knowledge.'
  }
];
