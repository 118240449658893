export const headerItems = [
  {
    title: 'Shop',
    to: '/shop'
  },
  {
    title: 'Design your own',
    to: '/create',
    isExternal: true
  },
  {
    title: 'How it works',
    to: '/how-it-works'
  }
];

export const servicesItems = [
  {
    title: 'Pre-order Campaigns',
    to: '/create'
  },
  {
    title: 'Bulk Orders',
    to: '/landing/merch-bulk-orders'
  },
  {
    to: '/connect',
    title: 'Integrations'
  }
];

export const authenticatedMenuItems = [
  {
    title: 'Profile',
    to: '/dashboard/settings/profile',
    isCreator: true,
    group: 0
  },
  {
    title: 'Dashboard',
    to: '/dashboard',
    group: 0
  },
  {
    title: 'Payouts and Charges',
    to: '/dashboard/payout-history',
    isCreator: true,
    group: 0
  },
  {
    title: 'Creator Toolkit',
    to: '/dashboard/creator-toolkit',
    group: 0
  },
  {
    title: 'Orders',
    to: '/dashboard/orders',
    group: 1
  },
  {
    title: 'Following',
    to: '/dashboard/following',
    group: 1
  },
  {
    title: 'Settings',
    to: '/dashboard/settings',
    group: 1
  }
];

export const integratorMenuItems = [
  {
    title: 'Dashboard',
    to: '/dashboard/connect',
    isCreator: true,
    group: 0
  }
];
