import React from 'react';

import Spacing from '../../../../components/atoms/Spacing';
import Paragraph from '../../../../components/atoms/Paragraph';

import Main from '../../Main';
import Title from '../../Title';

import communityImage from '../../../../img/pages/people/community.jpg';

const Community = () => {
  return (
    <Main title="Community" image={communityImage}>
      <Spacing size={7} position="b">
        <Title titleCopy="Where we were" />
        <Paragraph className="mb-3">
          We were set up with the mission to support grassroots creators.
          Something we've been able to do from day one. And over the years we
          built a platform to help spotlight their work, and act as a creative
          resource too. Running our talk series, Everpress Meets, as well as
          initiatives like Community Broadcast, we've built a platform to help
          our community reach new audiences.
        </Paragraph>
      </Spacing>
      <Spacing size={7} position="b">
        <Title titleCopy="What we’ve done" />
        <Paragraph className="mb-3">
          By the end of 2022 we paid out £7+ million to creators. Supporting
          them and their creativity everyday and helping them cover rent,
          mortgages, utility bills and much more. We have a team dedicated to
          supporting their success and track this along with ours daily. Our
          plan is to double-down on this support, finding more ways to help our
          creators achieve what they want by building an inclusive, supportive
          and creative community like no other.
        </Paragraph>
      </Spacing>
    </Main>
  );
};

export default Community;
