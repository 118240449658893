import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { fontColorMap } from '../../../../helpers/themeMap';
import propToClassName from '../../../../helpers/propToClassName';

import Link from '../../../atoms/Link';

import styles from './styles/general.module.css';

const ContextualMenuItem = ({ label, to, onClick, color = 'black' }) => {
  const colorClassName = propToClassName(color, fontColorMap);

  return (
    <>
      <li className={styles.itemContainer}>
        <Link
          className={classNames(styles.itemLink, colorClassName)}
          isUnderlined={false}
          noHover
          onClick={onClick}
          to={to}
        >
          {label}
        </Link>
      </li>
    </>
  );
};

ContextualMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(fontColorMap))
};

export default ContextualMenuItem;
