import image1 from '../../../img/pages/thank-you/campaign.jpg';
import image2 from '../../../img/pages/thank-you/community.jpg';
import image3 from '../../../img/pages/thank-you/profit.jpg';

const getStartedItems = [
  {
    id: 0,
    image: image1,
    title:
      'If you have a design ready, get started using our campaign builder.',
    buttonLabel: 'Build your campaign',
    buttonSlug: '/create'
  },
  {
    id: 1,
    image: image2,
    title: 'Get to know the Everpress community of creators.',
    buttonLabel: 'Discover success stories',
    buttonSlug: '/case-studies/creative'
  },
  {
    id: 2,
    image: image3,
    title: 'Use our price calculator to estimate your earnings.',
    buttonLabel: 'Calculate your profit',
    buttonSlug: '/price-calculator'
  }
];

export default getStartedItems;
