'use client';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Tooltip from '@radix-ui/react-tooltip';
import { motion, AnimatePresence } from 'framer-motion';
import classNames from 'classnames';

import WrapIf from '../../../utils/WrapIf';

import { theme } from '../../../../theme-config';

import generalStyles from './style/general.module.css';

export default function CustomTooltip({
  className,
  children,
  placement,
  offset,
  align,
  alignOffset,
  content,
  width,
  id,
  borderRadius,
  padding,
  hasListContent,
  externalIsVisible,
  externalVisibilityHandler,
  isMegaMenu,
  ...props
}) {
  const menuRef = useRef();
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const handleVisibleChange = (isVisible) => {
    if (externalVisibilityHandler) {
      externalVisibilityHandler(isVisible);
    } else {
      setTooltipVisible(isVisible);
    }
  };

  const overlayStyle = {
    backgroundColor: theme.colors.white,
    borderRadius,
    padding,
    boxShadow: theme.boxShadow.md,
    width: width || 'auto',
    zIndex: theme.zIndex.header
  };

  const isOpen =
    typeof externalIsVisible !== 'undefined'
      ? externalIsVisible
      : isTooltipVisible;

  return (
    <Tooltip.Provider
      delayDuration={200}
      skipDelayDuration={300}
      disableHoverableContent={false}
    >
      <Tooltip.Root open={isOpen} onOpenChange={handleVisibleChange}>
        <WrapIf
          condition={hasListContent}
          wrapper={(children) => (
            <div
              ref={menuRef}
              onFocus={() => handleVisibleChange(true)}
              onMouseEnter={() => handleVisibleChange(true)}
              onBlur={() => handleVisibleChange(false)}
            >
              {children}
            </div>
          )}
        >
          <Tooltip.Trigger asChild>
            <div className="block">
              {children}
            </div>
          </Tooltip.Trigger>
        </WrapIf>

        <Tooltip.Portal forceMount={true}>
          <AnimatePresence>
            {isOpen && (
              <Tooltip.Content
                side={placement}
                sideOffset={offset}
                align={align}
                alignOffset={alignOffset}
                asChild
                className={classNames(generalStyles.content, className)}
                style={overlayStyle}
                {...props}
              >
                <motion.div
                  initial={{ opacity: 0, y: -3 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -3 }}
                  transition={{ duration: 0.2, ease: 'easeInOut' }}
                  style={
                    isMegaMenu ? { zIndex: theme.zIndex.header - 1 } : undefined
                  }
                  // Not required if using AnimatePresence
                  // onMouseEnter={() => handleVisibleChange(true)} // Keep open on hover
                  // onMouseLeave={() => handleVisibleChange(false)} // Close when cursor leaves
                >
                  {content}
                </motion.div>
              </Tooltip.Content>
            )}
          </AnimatePresence>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

CustomTooltip.defaultProps = {
  placement: 'right',
  borderRadius: theme.extend.borderRadius.lg,
  padding: 10
};

CustomTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  content: PropTypes.node,
  hasListContent: PropTypes.bool,
  externalIsVisible: PropTypes.bool,
  externalVisibilityHandler: PropTypes.func,
  borderRadius: PropTypes.string,
  padding: PropTypes.number
};
