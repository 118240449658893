import React from 'react';

import MaxWidth from '../../../atoms/MaxWidth';

export default function ModalContent({ maxWidth, children }) {
  return <MaxWidth value={maxWidth}>{children}</MaxWidth>;
}

ModalContent.defaultProps = {
  maxWidth: 400
};
