import React from 'react';
import { useDispatch } from 'react-redux';
import Link from 'redux-first-router-link';
import PropTypes from 'prop-types';

import WrapIf from '../../../utils/WrapIf';
import getInitials from '../../../../helpers/getInitials';
import { theme } from '../../../../theme-config';

import Heading from '../../../atoms/Heading';
import Spacing from '../../../atoms/Spacing';
import Avatar from '../../../atoms/Avatar';

import { Rail, RailSlide } from '..';

import generalStyles from '../style/general.module.css';

const FeaturedProfileRail = ({ featuredProfiles }) => {
  const dispatch = useDispatch();

  return (
    <Rail
      type="Featured Profile"
      title="Creator spotlight"
      subtitle="Discover more from some of our favourite creators this month."
      breakpoints={{
        0: {
          slidesPerView: 2.5,
          spaceBetween: 15
        },
        [parseInt(theme.screens.sm)]: {
          slidesPerView: 3.5,
          spaceBetween: 30
        },
        [parseInt(theme.screens.md)]: {
          slidesPerView: 5.5,
          spaceBetween: 50
        },
        [parseInt(theme.screens.lg)]: {
          slidesPerView: 6.5,
          spaceBetween: 60
        }
      }}
    >
      {featuredProfiles.map((profile) => {
        return (
          <RailSlide key={profile.profileId}>
            <div
              className="py-15"
              onClick={() =>
                dispatch({
                  type: 'RAIL_CAMPAIGN_CLICK',
                  payload: {
                    type: 'Featured Profile'
                  }
                })
              }
            >
              <div className="relative text-center">
                <WrapIf
                  condition={!!profile.slug}
                  wrapper={(children) => (
                    <Link
                      to={`/profile/${profile.slug}`}
                      className={generalStyles.link}
                    >
                      {children}
                    </Link>
                  )}
                >
                  <>
                    <Spacing position="b" size={1}>
                      <Avatar
                        src={profile.profilePicture}
                        initials={getInitials(profile.name || '')}
                        size="fill"
                      />
                    </Spacing>
                    <Heading
                      className={generalStyles.creatorHeading}
                      size="xxs"
                      tag="h2"
                    >
                      {profile.name}
                    </Heading>
                  </>
                </WrapIf>
              </div>
            </div>
          </RailSlide>
        );
      })}
    </Rail>
  );
};

FeaturedProfileRail.defaultProps = {
  featuredProfiles: []
};

FeaturedProfileRail.propTypes = {
  featuredProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      profileId: PropTypes.number,
      name: PropTypes.string,
      creatorHeading: PropTypes.string,
      slug: PropTypes.string
    })
  )
};

export default FeaturedProfileRail;
