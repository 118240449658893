import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Select, { SelectNew } from '../Select';
import { Field, DefaultField } from '../Form';

import { getCountries } from '../../../actions/countries';

class SelectCountries extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    data: PropTypes.array.isRequired
  };

  componentDidMount() {
    if (!this.props.data.length) {
      this.props.getCountries();
    }
  }

  render() {
    const {
      data,
      value,
      getCountries,
      standalone,
      children,
      useNewStyles,
      ...props
    } = this.props;

    const favourites = data.length
      ? ['GB', 'US'].map((iso) => data.find((country) => country.iso === iso))
      : [];

    const dataWithoutFavourites = data.filter(
      (country) => !favourites.includes(country)
    );

    const options = [...favourites, ...dataWithoutFavourites].map(
      (country) => ({
        value: country.iso,
        label: country.name
      })
    );

    const placeholder = 'SELECT YOUR COUNTRY';

    const SelectComponent = useNewStyles ? SelectNew : Select;
    const FieldComponent = useNewStyles ? DefaultField : Field;

    if (standalone) {
      return (
        <SelectComponent
          placeholder={placeholder}
          options={options}
          value={value}
          isSearchable={useNewStyles || undefined}
          {...props}
        />
      );
    } else {
      return (
        <FieldComponent
          type="select"
          placeholder={placeholder}
          options={options}
          isInline
          isSearchable={useNewStyles || undefined}
          {...props}
        >
          {children}
        </FieldComponent>
      );
    }
  }
}

const mapStateToProps = ({ countries }) => {
  return {
    data: countries
  };
};

export default connect(mapStateToProps, { getCountries }, null, {
  pure: false // TODO: find a way to avoid this. Will change a lot with React 16.3.0...
})(SelectCountries);
