import flow from 'lodash/flow';
import partial from 'lodash/partial';

import editorsPicksImg from '../../img/pages/shop/editors-picks.jpg';
import independentMusicImg from '../../img/pages/shop/independent-music.jpg';
import repressImg from '../../img/pages/shop/re-press.jpg';
import typeInFocusImg from '../../img/pages/shop/type-in-focus.jpg';
import localBusinessImg from '../../img/pages/shop/local-business.jpg';
import fundraisersImg from '../../img/pages/shop/fundraisers.jpg';
import parallelImg from '../../img/pages/shop/parallel.jpg';
import bestsellersImg from '../../img/pages/shop/bestsellers.jpg';
import inCollaborationImg from '../../img/pages/shop/in-collaboration.jpg';
import balanceImg from '../../img/pages/shop/balance.jpg';
import shapeArtsPresentsImg from '../../img/pages/shop/shape-arts-presents.jpg';
import justForYouImg from '../../img/pages/shop/just-for-you.jpg';
import highpassImg from '../../img/pages/shop/highpass.jpg';
import bestOfTheYearImg from '../../img/pages/shop/best-of-the-year.jpg';
import artistToWatchImg from '../../img/pages/shop/artist-to-watch-22.jpg';
import womenCreatorsImg from '../../img/pages/shop/women-creators.jpg';
import aapiCreatorsImg from '../../img/pages/shop/aapi-creators.jpg';
import nhsFundraisersImg from '../../img/pages/shop/nhs-fundraisers.jpg';

import config from '../../config';

const { defaultTag, podTag } = config.shop;

const TAGS_DATA_MAP = {
  featured: {
    img: editorsPicksImg,
    description:
      "Shop a curated edit of designs that we're obsessed with right now."
  },
  'independent-music': {
    img: independentMusicImg
  },
  're-press': {
    img: repressImg
  },
  'type-in-focus': {
    img: typeInFocusImg
  },
  'local-business': {
    img: localBusinessImg
  },
  fundraisers: {
    img: fundraisersImg
  },
  'parallel-collection': {
    img: parallelImg
  },
  bestsellers: {
    img: bestsellersImg
  },
  'In-Collaboration': {
    img: inCollaborationImg
  },
  balance: {
    img: balanceImg
  },
  'shape-arts-presents': {
    img: shapeArtsPresentsImg
  },
  'recommended-for-you': {
    img: justForYouImg
  },
  highpass: {
    img: highpassImg
  },
  'best-of-the-year': {
    img: bestOfTheYearImg
  },
  'artists-to-watch-22': {
    img: artistToWatchImg
  },
  'women-creators': {
    img: womenCreatorsImg
  },
  'aapi-creators': {
    img: aapiCreatorsImg
  },
  'nhs-fundraisers': {
    img: nhsFundraisersImg
  }
};

const insertAtHead = (items, arr = []) => [...items, ...arr];

const concatTagsData = (tags = []) =>
  tags.map((item) => {
    const data = TAGS_DATA_MAP[item.slug] || {};
    return { ...item, ...data };
  });

const concatDefaultTags = partial(insertAtHead, [
  {
    name: defaultTag.label,
    slug: defaultTag.value,
    description: defaultTag.description
  },
  {
    name: podTag.label,
    slug: podTag.value,
    description: podTag.description
  }
]);

export const buildTags = flow([
  concatDefaultTags,
  concatTagsData
]);
