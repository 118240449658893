import React from 'react';

import generalStyles from './style/general.module.css';

export default class Checkbox extends React.PureComponent {
  render() {
    const { name, id, children, value, onChange, ...domProps } = this.props;

    return (
      <label htmlFor={name} className={generalStyles.holder}>
        <input
          type="checkbox"
          name={name}
          id={id || name}
          value={value}
          checked={value}
          onChange={onChange}
          className={generalStyles.input}
          {...domProps}
        />
        <div className={generalStyles.checkbox} tabIndex={0} />
        {children}
      </label>
    );
  }
}
