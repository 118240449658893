import React from 'react';
import PropTypes from 'prop-types';

import Heading from '../../../../components/atoms/Heading';
import Icon from '../../../../components/atoms/Icon';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';

import { ModalNew as Modal } from '../../../../components/molecules/Modal';

import roundTimeIcon from '../../../../img/sprites/round-time.svg';

import { theme } from '../../../../theme-config';

const BuildingModal = ({ isTooSlow, isOpen }) => {
  return (
    <Modal isOpen={isOpen}>
      <Spacing size={4} position="t">
        <div className="flex justify-center">
          <Icon
            glyph={roundTimeIcon}
            width={30}
            height={30}
            fill={theme.extend.colors.yellow.primary}
          />
        </div>
      </Spacing>
      <Spacing size={15} position="t">
        <Heading size="xs" tag="h3" center>
          Almost done
        </Heading>
      </Spacing>
      <Spacing size={5} position="t">
        {isTooSlow ? (
          <Paragraph size="xxs" center>
            We're still generating your campaign, it'll be just a few more
            seconds.
          </Paragraph>
        ) : (
          <>
            <Paragraph size="xxs" center>
              We're building your campaign, hold tight!
            </Paragraph>
            <Spacing size={1} position="t">
              <Paragraph className="px-2" size="xxs" color="primary-grey" center>
                This page will automatically refresh as soon as it's ready.
              </Paragraph>
            </Spacing>
          </>
        )}
      </Spacing>
    </Modal>
  );
};

BuildingModal.propTypes = {
  isTooSlow: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired
};

BuildingModal.defaultProps = {
  isOpen: false
};

export default BuildingModal;
