import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import { InlineGrid } from '../../atoms/Grid';
import MaxWidth from '../../atoms/MaxWidth';
import Paragraph from '../../atoms/Paragraph';
import Price from '../../atoms/Price';
import Spacing from '../../atoms/Spacing';
import Tooltip from '../../atoms/Tooltip';
import Icon from '../../atoms/Icon';

import questionMarkCircle from '../../../img/sprites/question-mark-circle.svg';
import zapIcon from '../../../img/sprites/zap.svg';

const ProductShippingOnInfo = ({
  delivery,
  minimumSales,
  currency,
  isPrintOnDemand
}) => {
  const { expectedDispatchDate, price } = delivery;
  const formattedDate = format(parse(expectedDispatchDate), 'MMM D');
  const tooltip = (
    <MaxWidth value={300}>
      This campaign has to reach {minimumSales} sales for items to ship on the estimated date.
    </MaxWidth>
  );
  if (expectedDispatchDate == null) {
    return null;
  }
  return (
    <InlineGrid>
      <Paragraph tag="div" size="xxs">
        {isPrintOnDemand ? (
          <InlineGrid>
            <Spacing size="05" position="r">
              <Icon glyph={zapIcon} width={20} height={20} />
            </Spacing>
            <Paragraph size="xxs" className="text-blue-primary">
              Fast shipping
            </Paragraph>
          </InlineGrid>
        ) : (
          <InlineGrid>
            Shipping: {formattedDate}
            <Spacing position="l" size="05">
              {minimumSales > 1 && (
                <Tooltip
                  content={tooltip}
                  glyph={questionMarkCircle}
                  glyphWidth={13}
                  glyphHeight={13}
                />
              )}
            </Spacing>
          </InlineGrid>
        )}
      </Paragraph>
      <Spacing position="l" size={1}>
        <Paragraph tag="div" size="xxs">
          {price || price === 0 ? (
            <Price currency={currency} value={price} size="xxs" />
          ) : (
            'Calculated from delivery address'
          )}
        </Paragraph>
      </Spacing>
    </InlineGrid>
  );
};

export default ProductShippingOnInfo;

ProductShippingOnInfo.propTypes = {
  delivery: PropTypes.shape({
    expectedDispatchDate: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired
  }).isRequired,
  minimumSales: PropTypes.number.isRequired,
  currency: PropTypes.string,
  isPrintOnDemand: PropTypes.bool
};
