import produce from 'immer';
import findKey from 'lodash/findKey';
import merge from 'lodash/merge';

import { getActiveCampaigns } from '../helpers/campaign';

const initialState = {
  featuredTags: [],
  linked: {
    items: []
  },
  byId: {},
  stats: [],
  summary: {
    isReady: false
  }
};

function mergeCampaignsById(state, campaigns) {
  return campaigns.forEach((campaign) => mergeCampaignById(state, campaign));
}

function mergeCampaignById(state, campaign) {
  if (state.byId[campaign.campaignId]) {
    merge(state.byId[campaign.campaignId], campaign);
  } else {
    state.byId[campaign.campaignId] = campaign;
  }
}

function mergeCampaignBySlug(state, campaign) {
  const key = findKey(state.byId, ({ slug }) => slug === campaign.slug);
  if (!!key) delete state.byId[key];

  state.byId[campaign.campaignId] = campaign;
}

export default (state = initialState, action) =>
  produce(state, (newState) => {
    const { type, payload, meta } = action;

    switch (type) {
      case 'GET_FEATURED_TAGS_FULFILLED':
        const featuredTags = payload.map((tagData) => ({
          ...tagData,
          campaigns: getActiveCampaigns(tagData.campaigns)
        }));

        return {
          ...state,
          featuredTags
        };
      case 'GET_LINKED_CAMPAIGNS_FULFILLED':
        return {
          ...state,
          linked: {
            items: payload
          }
        };
      case 'CAMPAIGN_RECEIVED':
      case 'GET_CAMPAIGN_FULFILLED':
        mergeCampaignBySlug(newState, payload);
        newState.byId[payload.campaignId].isReady = true;
        return newState;
      case 'GET_USER_CAMPAIGN_FULFILLED':
        mergeCampaignById(newState, payload);
        newState.byId[payload.campaignId].isReady = true;
        return newState;
      case 'GET_USER_CAMPAIGNS_FULFILLED':
        return mergeCampaignsById(newState, payload.results);
      case 'GET_USER_CAMPAIGNS_FULL_FULFILLED':
        payload.results.forEach(
          (campaign) => (newState.byId[campaign.campaignId] = campaign)
        );
        return newState;
      case 'GET_ADMIN_CAMPAIGN_FULFILLED':
        const {
          campaignId,
          parentCampaignId,
          soldCount,
          stats,
          user
        } = payload;
        newState.byId[payload.campaignId].adminPanel = {
          campaignId,
          parentCampaignId,
          soldCount,
          status: payload.state,
          revenue: stats.revenue,
          campaignUser: user
        };
        return newState;
      case 'EDIT_CAMPAIGN_SETTINGS_PENDING':
        mergeCampaignById(newState, meta);
        return newState;
      case 'GET_CART_FULFILLED':
      case 'GET_ORDER_FULFILLED':
        return mergeCampaignsById(newState, payload.campaigns);
      case 'GET_CAMPAIGNS_STATS_SUMMARY_FULFILLED':
        newState.summary = action.payload;
        newState.summary.isReady = true;
        return newState;
      case 'GET_CAMPAIGNS_STATS_BREAKDOWN_FULFILLED':
        if (meta.id) {
          newState.byId[meta.id].stats = action.payload;
        } else {
          newState.stats = action.payload;
        }
        return newState;
      case 'GET_CAMPAIGN_PRODUCT_SALES_BREAKDOWN_FULFILLED':
        newState.byId[meta.id].sales = action.payload;
        return newState;
      case 'GET_CAMPAIGN_PLATFORM_BREAKDOWN_FULFILLED':
        newState.byId[meta.id].platformBreakdown = action.payload;
        return newState;
      case 'GET_CAMPAIGN_COUNTRY_BREAKDOWN_FULFILLED':
        newState.byId[meta.id].countryBreakdown = action.payload;
        return newState;
      case 'GET_CAMPAIGN_ARTWORKS_POSITIONS_PENDING':
        if (newState.byId[meta.id]) newState.byId[meta.id].artworks = [];
        return newState;
      case 'GET_CAMPAIGN_GARMENTS_PENDING':
        if (newState.byId[meta.id]) newState.byId[meta.id].catalogue = [];
        return newState;
      case 'LAUNCH_CAMPAIGN_PENDING':
      case 'LAUNCH_CAMPAIGN_FULFILLED':
      case 'LAUNCH_CAMPAIGN_REJECTED':
      case 'IS_CAMPAIGN_READY_FULFILLED':
      case 'GET_CAMPAIGN_ARTWORKS_POSITIONS_FULFILLED':
      case 'GET_CAMPAIGN_GARMENTS_FULFILLED':
        // These actions need a campaign to exist to do anything
        // If campaign doesn't exist, create it
        if (!newState.byId[meta.id]) {
          newState.byId[meta.id] = {};
        }
        if (type === 'IS_CAMPAIGN_READY_FULFILLED') {
          newState.byId[meta.id].imagesReady = payload;
        }
        if (type === 'LAUNCH_CAMPAIGN_PENDING') {
          newState.byId[meta.id].isLaunching = true;
        }
        if (type === 'LAUNCH_CAMPAIGN_REJECTED') {
          newState.byId[meta.id].isLaunching = false;
        }
        if (type === 'LAUNCH_CAMPAIGN_FULFILLED') {
          newState.byId[meta.id].isLaunching = false;
          newState.byId[meta.id].draft = false;
        }
        if (type === 'GET_CAMPAIGN_ARTWORKS_POSITIONS_FULFILLED') {
          newState.byId[meta.id].artworks = payload;
        }
        if (type === 'GET_CAMPAIGN_GARMENTS_FULFILLED') {
          newState.byId[meta.id].catalogue = payload;
        }
        return newState;
      case 'END_CAMPAIGN_FULFILLED':
        newState.byId[meta.id].state = 'failed';
        return newState;
      case 'DELETE_CAMPAIGN_FULFILLED':
        newState.byId[meta.id].state = 'deleted';
        return newState;
      default:
        return newState;
    }
  });
