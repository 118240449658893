import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import inRange from 'lodash/inRange';
import debounce from 'lodash/debounce';

import { updateCampaign } from '../../../../actions/campaign-builder';

import Alert from '../../../../components/atoms/Alert';
import Heading from '../../../../components/atoms/Heading';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';
import { Form, DefaultField as Field } from '../../../../components/atoms/Form';
import { TooltipNew as Tooltip } from '../../../../components/atoms/Tooltip';
import Icon from '../../../../components/atoms/Icon';

import Slider from '../../../../components/molecules/Slider';

import questionMarkIcon from '../../../../img/sprites/question-mark-circle.svg';

import generalStyles from './style/general.module.css';

const tooltipContent = (
  <div className="flex flex-col gap-3">
    <Paragraph size="xxs">
      Sales are collected over your chosen campaign length. The maximum is 30
      days. At the end, all orders are printed in a single batch.
    </Paragraph>
    <Paragraph size="xxs">
      Your campaign will then automatically relaunch.
    </Paragraph>
  </div>
);

const SalesDuration = () => {
  const dispatch = useDispatch();

  const duration = useSelector((state) => state.campaignBuilder.length);

  const handleDurationChange = useCallback(
    async (duration) => {
      await dispatch(updateCampaign({ length: duration }));
    },
    [dispatch]
  );

  const debouncedHandleDurationChangeSlider = useMemo(
    () => debounce(handleDurationChange, 500),
    [handleDurationChange]
  );

  const debouncedHandleDurationChangeInput = useMemo(
    () => debounce(handleDurationChange, 2000),
    [handleDurationChange]
  );

  return (
    <div>
      <span className="flex items-center gap-3">
        <Heading tag="h4" size="xs">
          Sales duration
        </Heading>
        <Tooltip
          id="tooltip-sales-duration"
          content={tooltipContent}
          placement="bottom"
          offset={7}
          align="start"
          alignOffset={-10}
          width={320}
        >
          <Icon
            className="mb-px opacity-25 hover:opacity-100 transition-opacity duration-100"
            glyph={questionMarkIcon}
            width={14}
            height={14}
          />
        </Tooltip>
      </span>
      <Spacing size="05" position="t">
        <Paragraph size="xxs">
          Choose how many days you'd like the campaign to run for:
        </Paragraph>
      </Spacing>
      <Spacing size={3} position="t">
        <Form
          onSubmit={() => null}
          onChange={({ durationSlider, durationInput }, { initialValues }) => {
            const {
              durationSlider: prevDurationSlider,
              durationInput: prevDurationInput
            } = initialValues;

            // Check slider first as it has a shorter delay
            if (
              durationSlider !== prevDurationSlider &&
              inRange(durationSlider, 2, 31)
            ) {
              return debouncedHandleDurationChangeSlider(durationSlider);
            }

            if (durationInput !== prevDurationInput) {
              // NOTE: Checks if n is between start and up to, but not including, end
              if (inRange(durationInput, 2, 31)) {
                return debouncedHandleDurationChangeInput(durationInput);
              }

              // Reset to original value instead of last valid typed value
              return debouncedHandleDurationChangeInput(duration);
            }
          }}
          initialValues={{ durationInput: duration, durationSlider: duration }}
          validationSchema={yup.object().shape({
            durationInput: yup
              .number()
              .min(2, 'Sales duration is below minimum.')
              .max(30, 'Sales duration is above maximum.')
              .required('Sales duration is required.')
          })}
          validateOnChange={true}
          enableReinitialize
          render={({ handleSubmit, setValues, values, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={generalStyles.inputs}>
                  <Slider
                    className={generalStyles.slider}
                    value={[Math.min(Math.max(2, values.durationSlider), 30)]}
                    min={2}
                    max={30}
                    step={1}
                    marks={[
                      undefined,
                      { value: 7, label: '7 days' },
                      { value: 14, label: '14 days' },
                      { value: 21, label: '21 days' },
                      { value: 28, label: '28+ days' },
                      undefined
                    ]}
                    onValueChange={(values) =>
                      setValues({
                        durationSlider: values[0],
                        durationInput: values[0]
                      })
                    }
                    onMarkClick={(value) =>
                      setValues({
                        durationSlider: value,
                        durationInput: value
                      })
                    }
                  />
                  <div>
                    <Field
                      type="number"
                      name="durationInput"
                      placeholder="Days"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>

                {errors?.durationInput && (
                  <Spacing size={1} position="t">
                    <Alert>{errors.durationInput}</Alert>
                  </Spacing>
                )}
              </form>
            );
          }}
        />
      </Spacing>
    </div>
  );
};

export default SalesDuration;
