import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RCPagination from 'rc-pagination';
import locale from 'rc-pagination/es/locale/en_GB';

import { ButtonNew as Button } from '../../atoms/Button';

import './style/rctooltip-overrides.css';
import generalStyles from './style/general.module.css';

const Pagination = class extends React.Component {
  itemRender(current, type, element) {
    if (type === 'prev') {
      return (
        <Button kind="ghost" size="tiny">
          Prev
        </Button>
      );
    }
    if (type === 'next') {
      return (
        <Button kind="ghost" size="tiny">
          Next
        </Button>
      );
    }
    if (type === 'page') {
      return (
        <Button
          kind={this.props.currentPage === current ? 'solid' : 'outline'}
          size="tiny"
          className={classNames(generalStyles.button)}
        >
          {current}
        </Button>
      );
    }
    if (type === 'jump-next' || type === 'jump-prev') {
      return (
        <Button
          kind="outline"
          size="tiny"
          className={classNames(generalStyles.button, generalStyles.jumpers)}
        >
          •••
        </Button>
      );
    }

    return element;
  }

  render() {
    const { currentPage, total, onChange, ...otherProps } = this.props;

    return (
      <nav title="Campaign Table Pagination">
        <RCPagination
          className={generalStyles.container}
          current={currentPage}
          onChange={onChange}
          total={total}
          locale={locale}
          itemRender={this.itemRender.bind(this)}
          {...otherProps}
        />
      </nav>
    );
  }
};

Pagination.propTypes = {
  total: PropTypes.number.isRequired
};

export default Pagination;
