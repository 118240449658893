import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import classNames from 'classnames';

import WrapIf from '../../../../components/utils/WrapIf';

import DynamicCarousel from '../../../../components/atoms/DynamicCarousel';
import Image from '../../../../components/atoms/Image';
import Heading from '../../../../components/atoms/Heading';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';

import ProductShot from '../../../../components/molecules/ProductShot';

import generalStyles from './styles/general.module.css';

const fallbackColour = {
  colourId: 0,
  code: '#ffffff',
  name: 'White'
};

export default class ProductShots extends React.Component {
  state = {
    isMobile: false
  };

  componentDidMount() {
    if (window.matchMedia(`(hover: none), (pointer: coarse)`).matches) {
      this.handleResize = throttle(this.handleResize.bind(this), 200);
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      isMobile: window.matchMedia(
        `(orientation: portrait) and (max-width: 767px),
         (orientation: landscape) and (max-width: 811px)`
      ).matches
    });
  }

  render() {
    const { item, campaign, colour = fallbackColour, children } = this.props;
    const { isMobile } = this.state;
    const { images, defaultSide, artworks } = campaign;

    const productImages = images.filter((image) => image.type === 'grey');
    const sides = ['front', 'back'];

    // Some campaign might not have both front/back image
    let image2 = productImages.find(
      (image) =>
        image.campaignProductId === item.campaignProductId &&
        image.colourId === item.featuredColourId &&
        image.area !== defaultSide
    );

    const lifestyleImages = images.filter(
      (image) => image.type === 'lifestyle'
    );

    const productFitImages = item.product?.lifestyleImages || [];

    if (!productImages.length && artworks) {
      image2 = artworks.find(
        (artwork) => artwork.area !== campaign.defaultSide
      );
    }

    const isCarousel =
      isMobile &&
      (image2 || productFitImages.length > 0 || lifestyleImages.length > 0);

    return (
      <div className={generalStyles.container}>
        <div className={generalStyles.overlay}>{children}</div>
        <WrapIf
          condition={isCarousel}
          wrapper={(children) => (
            <DynamicCarousel
              infinite
              slidesPerPage={1}
              slideSpacing={2}
              controls={false}
              dots
            >
              {/*
                Flatten children before they are passed to the carousel to prevent
                lifestyleImages.map() from being rendered as a single child
               */}
              {React.Children.toArray(children)}
            </DynamicCarousel>
          )}
        >
          <div
            className={classNames('w-auto', {
              '-mx-2': isMobile && !image2 && !productFitImages.length
            })}
          >
            <ProductShot
              item={item}
              campaign={campaign}
              colourId={colour.colourId}
              width="1173"
              height="1173"
            />
          </div>
          {image2 && (
            <ProductShot
              item={item}
              campaign={campaign}
              colourId={colour.colourId}
              side={sides.find((side) => side !== campaign.defaultSide)}
              width="1173"
              height="1173"
            />
          )}
          {lifestyleImages.map((image) => (
            <Image
              src={image.url}
              alt="Lifestyle"
              width="1173"
              height="1173"
              key={image.url}
            />
          ))}
          {productFitImages.map((image) => (
            <div className={generalStyles.imageContainer} key={image.imageUrl}>
              <div className={generalStyles.imageInfo}>
                <Heading color="white" size="xxs" tag="h4">
                  Product fit
                </Heading>
                <Spacing size={1} position="t">
                  <Paragraph color="white" size="xxs">
                    Maddy is 170cm and wears size M, Divine is 188cm and wears size L
                  </Paragraph>
                </Spacing>
              </div>
              <Image
                src={image.imageUrl}
                alt="Product fit"
                width="1173"
                height="1173"
              />
            </div>
          ))}
        </WrapIf>
      </div>
    );
  }
}

ProductShots.propTypes = {
  item: PropTypes.object,
  campaign: PropTypes.object,
  colour: PropTypes.object,
  children: PropTypes.node
};
