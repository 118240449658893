import React from 'react';
import PropTypes from 'prop-types';

import Alert from '../../../../components/atoms/Alert';
import Heading from '../../../../components/atoms/Heading';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';

import { ModalNew as Modal } from '../../../../components/molecules/Modal';

import { InlineGrid } from '../../../../components/atoms/Grid';
import { ButtonNew as Button } from '../../../../components/atoms/Button';

const SubmitModal = ({ campaign, onSubmit, onClose, isOpen, errorMessage }) => {
  const handleSubmit = () => {
    onSubmit?.();
  };

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Spacing size={4} position="t">
        <Heading size="xs" tag="h3" center>
          Ready to submit?
        </Heading>
      </Spacing>
      <Spacing size={5} position="t">
        <Paragraph size="xxs" center>
          Your campaign will join a queue for approval. Once it’s submitted you
          won’t be able to make any changes to the campaign or its content.{' '}
        </Paragraph>
        <Spacing size={1} position="t">
          <Paragraph size="xxs" center>
            Now’s the time to make any final modifications - otherwise, go ahead
            and submit.
          </Paragraph>
        </Spacing>
        <Spacing size={2} position="t">
          <InlineGrid direction={['col', 'row']}>
            <Button
              onClick={handleSubmit}
              state={campaign.isLaunching ? 'loading' : 'default'}
              fullWidth
            >
              Submit
            </Button>
            <Button
              kind="outline"
              to={`/campaign/edit/${campaign.campaignId}`}
              className="ml-1"
              fullWidth
              isExternal
            >
              Edit
            </Button>
          </InlineGrid>
        </Spacing>
        {errorMessage && (
          <Spacing position="t" size={2}>
            <Alert kind="error">{errorMessage}</Alert>
          </Spacing>
        )}
      </Spacing>
    </Modal>
  );
};

SubmitModal.propTypes = {
  campaign: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string
};

SubmitModal.defaultProps = {
  isOpen: false
};

export default SubmitModal;
