import React from 'react';

import Heading from '../../atoms/Heading';
import Spacing from '../../atoms/Spacing';
import Paragraph from '../../atoms/Paragraph';
import { Grid, GridItem } from '../../atoms/Grid';
import Icon from '../../atoms/Icon';

import sizeGraph from '../../../img/sprites/tshirt-sizing.svg';

import { Modal } from '../Modal';

import generalStyles from './style/general.module.css';

const GarmentSizeModal = ({ isOpen, onClose, item }) => {
  return (
    <Modal onRequestClose={onClose} isOpen={isOpen} isTransparent>
      <Spacing size={['05', 1]} position="x">
        <Spacing size={3} position="y">
          <Heading tag="div" size="xs" center>
            Size guide
          </Heading>
          <Spacing size="05" position="t">
            <Paragraph size="xxs" color="grey" center>
              {item.product.name}
            </Paragraph>
          </Spacing>
        </Spacing>
        <Grid
          className={generalStyles.modalGrid}
          gap={15}
          row={['flex-col', 'flex-row-reverse']}
          justify="end"
        >
          <GridItem columnSize={[12, 6]}>
            <div className={generalStyles.sizeGraphContainer}>
              <Icon glyph={sizeGraph} height={270} width={290} />
            </div>
          </GridItem>
          <GridItem columnSize={[12, 6]}>
            <table className={generalStyles.sizeTable}>
              <tbody className={generalStyles.sizeTableHeader}>
                <tr>
                  <td>Size</td>
                  <td>Chest</td>
                  <td>Length</td>
                </tr>
              </tbody>
              <tbody
                className={generalStyles.sizeTableContent}
                dangerouslySetInnerHTML={{
                  __html: item.product.sizeGuide
                }}
              />
            </table>
          </GridItem>
          <GridItem columnSize={[12, 6]}>
            <Spacing size={1} position="b">
              <Spacing position="t" size={2}>
                <Paragraph size="xxxs" center>
                  All sizes are in CM with a tolerance of +/- 3
                </Paragraph>
                <Paragraph size="xxxs" center>
                  Chest measurements are pit-to-pit
                </Paragraph>
                <Paragraph size="xxxs" center>
                  Our sizes refer to UK sizing, please check measurements on our
                  size guide to ensure you order the correct size for you
                </Paragraph>
              </Spacing>
            </Spacing>
          </GridItem>
        </Grid>
      </Spacing>
    </Modal>
  );
};

export default GarmentSizeModal;
