import { uniq } from 'lodash';

export const getCampaignById = (id, campaigns) => {
  return campaigns.byId[id];
};

export const getCampaignBySlug = (slug, campaigns) => {
  return Object.values(campaigns.byId).find(
    // Allow type coercion to handle numeric campaign slugs
    // eslint-disable-next-line
    (campaign) => campaign?.slug?.toLowerCase() == slug?.toLowerCase()
  );
};

export const getCartCampaigns = (cart, campaigns) => {
  const campaignsValues = Object.values(campaigns.byId);
  return campaignsValues.length
    ? cart.items.map((item) =>
        campaignsValues.find(
          (campaign) => campaign.campaignId === item.campaignId
        )
      )
    : [];
};

export const getCampaignItem = (itemId, campaign) => {
  return campaign.products.find(
    (product) => product.campaignProductId === itemId
  );
};

export const getCampaignTagsByReference = (reference, tags) => {
  return Array.isArray(tags) && tags.length > 0
    ? tags
        .filter((tag) => tag.typeReference === reference)
        .map((tag) => tag.name)
    : [];
};

export const getCampaignsTagsByReference = (reference, campaigns) => {
  const tags = [];

  campaigns.forEach((campaign) => {
    tags.push(...getCampaignTagsByReference(reference, campaign.tags));
  });

  return tags;
};

export const getCampaignCollections = (tags) => {
  const main = uniq(getCampaignTagsByReference('main', tags));
  const shopCollections = uniq(getCampaignTagsByReference('collections', tags));

  return [...main, ...shopCollections];
};

export const getCampaignsCollections = (campaigns) => {
  const tags = [];

  campaigns.forEach((campaign) => {
    tags.push(...getCampaignCollections(campaign.tags));
  });

  return tags;
};
