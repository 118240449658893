import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import config from '../../../config';

import Spacing from '../../../components/atoms/Spacing';
import Heading from '../../../components/atoms/Heading';
import Paragraph from '../../../components/atoms/Paragraph';
import Link from '../../../components/atoms/Link';

const SearchHeading = ({ query, itemsCount }) => {
  const dispatch = useDispatch();

  const handleExitToShopClick = () => {
    dispatch({
      type: 'SHOP',
      payload: {
        tag: config.shop.defaultTag.value
      },
      meta: {
        exitToShopClick: true
      }
    });
  };

  return (
    <div className="relative text-center">
      <Spacing size={4} position="b" type="padding">
        <div className="absolute bottom-0 md:top-0 md:right-0">
          <Spacing size={2} position="t">
            <Link onClick={handleExitToShopClick}>
              <Paragraph tag="p" size="xxs">
                Exit to Shop
              </Paragraph>
            </Link>
          </Spacing>
        </div>

        <Spacing size={6} position="t" type="padding">
          <Paragraph size="xxs" color="grey-dark">
            {`${itemsCount ?? '0'} search results for `}
          </Paragraph>
        </Spacing>
        <Spacing size="05" position="t" type="padding">
          <Heading size="s">{`"${query}"`}</Heading>
        </Spacing>
      </Spacing>
    </div>
  );
};

SearchHeading.propTypes = {
  query: PropTypes.string.isRequired,
  itemsCount: PropTypes.number
};

export default SearchHeading;
