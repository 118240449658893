import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import * as userActions from '../../../actions/user';

import usePrevious from '../../../helpers/hooks/usePrevious';

import Spacing from '../../atoms/Spacing';

import SignUpForm from './SignUpForm';
import SignInForm from './SignInForm';
import ResetPasswordForm from './ResetPasswordForm';
import ResetPasswordSuccessForm from './ResetPasswordSuccessForm';

import everpressLogo from '../../../img/everpress-logo.svg';

import generalStyles from './style/general.module.css';

const AuthenticationContainer = ({
  noLogo,
  isCreator,
  isBuilder,
  onSuccess,
  initialUserStage
}) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);
  const token = useSelector((state) => state.user.token);
  const [userStage, onUserStageChange] = useState(initialUserStage);
  const [email, setEmail] = useState(null);
  const [existingUserDetails, onExistingUserDetailsChange] = useState({
    email: '',
    password: ''
  });
  const prevToken = usePrevious(token);

  useEffect(() => {
    if (token && token !== prevToken) {
      onSuccess?.(userStage);
    }
  }, [token, prevToken, onSuccess, userStage]);

  if (userId && token) {
    return null;
  }

  const handleSignUp = async ({ email, password }) => {
    const res = await dispatch(userActions.lookupUser(email));
    if ((await res.value.exists) === true) {
      await onExistingUserDetailsChange({ email, password });
      await dispatch({ type: 'SIGN_IN_EXISTING_USER' });
      await onUserStageChange('signIn');
    } else {
      await dispatch(userActions.signUp({ email, password }));
    }
  };

  const handleSignIn = async ({ email, password }) => {
    await dispatch(userActions.lookupUser(email));
    await dispatch(userActions.login(email, password));
  };

  const handleResetPassword = async ({ email }) => {
    await dispatch(userActions.recoverPassword(email));
    setEmail(email);
    onUserStageChange('resetPasswordSuccess');
  };

  const returnSwitchForms = () => {
    switch (userStage) {
      case 'signUp': {
        return (
          <SignUpForm
            onSignUp={handleSignUp}
            onUserStageChange={onUserStageChange}
            isCreator={isCreator}
            isBuilder={isBuilder}
          />
        );
      }
      case 'signIn': {
        return (
          <SignInForm
            onSignIn={handleSignIn}
            onUserStageChange={onUserStageChange}
            existingUserDetails={existingUserDetails}
            onExistingUserDetailsChange={onExistingUserDetailsChange}
          />
        );
      }
      case 'resetPassword': {
        return (
          <ResetPasswordForm
            onUserStageChange={onUserStageChange}
            onResetPassword={handleResetPassword}
          />
        );
      }
      case 'resetPasswordSuccess': {
        return (
          <ResetPasswordSuccessForm
            onUserStageChange={onUserStageChange}
            email={email}
          />
        );
      }
      default:
        return (
          <SignUpForm
            onSignUp={handleSignUp}
            onUserStageChange={onUserStageChange}
          />
        );
    }
  };

  return (
    <div className="text-center">
      <Spacing size={15} position="b">
        {!noLogo && (
          <img
            src={everpressLogo}
            alt="Everpress logo"
            width="40"
            height="35"
            className={generalStyles.logo}
          />
        )}
        {returnSwitchForms()}
      </Spacing>
    </div>
  );
};

AuthenticationContainer.propTypes = {
  noLogo: PropTypes.bool,
  isCreator: PropTypes.bool,
  isBuilder: PropTypes.bool,
  onSuccess: PropTypes.func,
  initialUserStage: PropTypes.oneOf([
    'signUp',
    'signIn',
    'resetPassword',
    'resetPasswordSuccess'
  ])
};

AuthenticationContainer.defaultProps = {
  noLogo: false,
  isCreator: false,
  isBuilder: false,
  initialUserStage: 'signUp'
};

export default AuthenticationContainer;
