import React from 'react';
import Carousel from '@brainhubeu/react-carousel';
import PropTypes from 'prop-types';

import propToClassName from '../../../helpers/propToClassName';
import pxToNumber from '../../../helpers/pxToNumber';

import { theme } from '../../../theme-config';

import IconButton from '../IconButton';

import './style/base.css';
import controlsStyles from './style/controls.module.css';

import leftArrowIcon from '../../../img/sprites/chevron-left.svg';
import rightArrowIcon from '../../../img/sprites/chevron-right.svg';

const LeftArrow = () => (
  <IconButton kind="secondary" glyph={leftArrowIcon} title="Left" />
);

const RightArrow = () => (
  <IconButton kind="secondary" glyph={rightArrowIcon} title="Right" />
);

const DynamicCarousel = (props) => {
  const {
    children,
    slidesPerPage,
    slideSpacing,
    controls,
    leftArrow,
    rightArrow,
    ...carouselProps
  } = props;

  let screenSizes = Object.values(theme.screens);
  screenSizes.pop();
  screenSizes.push('9999px');
  const breakpoints = setBreakpoints(slidesPerPage, screenSizes, children);
  const mergedProps = mergeProps(slideSpacing);
  const spacingClassNames = propToClassName(mergedProps);

  return (
    <div className={spacingClassNames}>
      <Carousel
        animationSpeed={200}
        minDraggableOffset={50}
        keepDirectionWhenDragging
        arrows={controls}
        addArrowClickHandler={controls}
        arrowLeft={
          controls && (
            <div className={controlsStyles.prev}>
              {leftArrow || <LeftArrow />}
            </div>
          )
        }
        arrowRight={
          controls && (
            <div className={controlsStyles.next}>
              {rightArrow || <RightArrow />}
            </div>
          )
        }
        breakpoints={breakpoints}
        {...carouselProps}
      >
        {children}
      </Carousel>
    </div>
  );
};

const setBreakpoints = (slidesPerPage, screenSizes, slides) => {
  let breakpoints = {};

  screenSizes.forEach((size, i) => {
    Object.assign(breakpoints, {
      [pxToNumber(size)]: {
        slidesPerPage: Math.min(
          slides.length,
          Array.isArray(slidesPerPage)
            ? slidesPerPage[i] || slidesPerPage[slidesPerPage.length - 1]
            : slidesPerPage
        )
      }
    });
  });

  return breakpoints;
};

const mergeProps = (size) => {
  if (Array.isArray(size)) {
    return size.map((item, i) => {
      return `-mx-${item}`;
    });
  } else {
    return `-mx-${size}`;
  }
};

export default DynamicCarousel;

DynamicCarousel.defaultProps = {
  slidesPerPage: 1,
  slideSpacing: 2,
  controls: true,
  dots: false,
  infinite: false
};

DynamicCarousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  /** Maps to screen sizes defined in theme-config.js using max-width logic. */
  slidesPerPage: PropTypes.oneOfType([PropTypes.array, PropTypes.number])
    .isRequired,
  /**
   * Should match item spacing size, uses the same responsive logic as the Spacing component.
   * Required as the carousel library only supports px-based slide offset values.
   **/
  slideSpacing: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.number.isRequired,
    PropTypes.string.isRequired
  ]),
  controls: PropTypes.bool,
  dots: PropTypes.bool,
  infinite: PropTypes.bool
};
