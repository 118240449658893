import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';

import { getText, getRichText } from '../../helpers/prismic';
import htmlParser from '../../helpers/htmlParser';

import * as trackingActions from '../../actions/tracking';

import Page from '../../components/utils/Page';
import PageWrap from '../../components/atoms/PageWrap';
import {
  Form,
  Field,
  Label,
  Error,
  handleFormError,
  validateFormWithSchema
} from '../../components/atoms/Form';
import { ButtonNew as Button } from '../../components/atoms/Button';
import Heading from '../../components/atoms/Heading';
import MaxWidth from '../../components/atoms/MaxWidth';
import Paragraph from '../../components/atoms/Paragraph';
import Spacing from '../../components/atoms/Spacing';

import TermsFormField from '../../components/molecules/TermsFormField';

import generalStyles from './style/general.module.css';

class LeadCapture extends PureComponent {
  async handleSubmit(formData, actions) {
    try {
      await this.props.captureSubmitDesign({
        ...formData,
        source: this.props.prevUrlPathname || 'organic'
      });
    } catch (error) {
      handleFormError(error, actions);
      return actions.setSubmitting(false);
    }

    this.props.goToSuccess();
  }

  componentWillUnmount() {
    clearTimeout(this.messageTimeout);
  }

  componentDidMount() {
    // Prefetch "thank you" page
    import('./Thanks').then();
  }

  handleValidate(values) {
    const fileUploadingMessage = 'Please wait until file is fully uploaded';

    const schema = yup.object().shape({
      name: yup.string().required(),
      email: yup
        .string()
        .email()
        .required(),
      url: yup.string().required(),
      description: yup.string().required(),
      artwork: yup
        .string()
        .url(fileUploadingMessage)
        .required(),
      form: yup.string()
    });

    return validateFormWithSchema(schema, values);
  }

  render() {
    const { title, description, buttonLabel, alternative } = this.props;
    return (
      <Page title="Submit your Design">
        <PageWrap>
          <MaxWidth value={415} center>
            <Spacing position="t" type="padding">
              <Heading center size={['m', 'l', 'l']} tag="h2">
                {title}
              </Heading>
            </Spacing>
            <Spacing center position="t" size={2} type="padding">
              <Heading size="s" tag="h3">
                {description}
              </Heading>
            </Spacing>
            <Spacing size={2} position="t" type="padding">
              <Form
                initialValues={{
                  name: '',
                  email: '',
                  url: '',
                  description: '',
                  artwork: '',
                  form: 'submit-your-design'
                }}
                onSubmit={this.handleSubmit.bind(this)}
                validate={this.handleValidate.bind(this)}
                render={({ isSubmitting, handleSubmit, status }) => (
                  <form onSubmit={handleSubmit}>
                    <Spacing size={[1, 2, 2]} position="b">
                      <Field name="name" placeholder="NAME" />
                    </Spacing>
                    <Spacing size={[1, 2, 2]} position="b">
                      <Field name="email" placeholder="EMAIL" />
                    </Spacing>
                    <Spacing size={[1, 2, 2]} position="b">
                      <Field
                        name="url"
                        placeholder="WEBSITE, FOLIO OR SOCIAL MEDIA LINK"
                      />
                    </Spacing>
                    <Spacing size={[1, 2, 2]} position="b">
                      <Field
                        name="description"
                        type="textarea"
                        placeholder="SHORT DESCRIPTION ABOUT YOU & YOUR DESIGN"
                      />
                    </Spacing>
                    <Spacing size={[1, 2, 2]} position="b">
                      <Field
                        type="file"
                        name="artwork"
                        placeholder="Upload"
                        accept="image/png,image/jpg,image/jpeg,image/svg"
                        uploadTo="/_uploader/lead_artwork/upload"
                      >
                        <Label htmlFor="artwork">Artwork</Label>
                      </Field>
                    </Spacing>
                    <Spacing size={1} position="b">
                      <TermsFormField name="acceptTerms" size="xxs" />
                    </Spacing>
                    <Spacing size={[1, 2, 2]} position="b">
                      <Button
                        type="submit"
                        state={isSubmitting && 'loading'}
                        className={generalStyles.button}
                      >
                        {buttonLabel}
                      </Button>
                    </Spacing>
                    <Spacing size={[1, 2, 2]} position="b">
                      {status &&
                        ((status.error && (
                          <Paragraph center>
                            <Error>{status.error}</Error>
                          </Paragraph>
                        )) ||
                          (status.message && (
                            <Paragraph center>{status.message}</Paragraph>
                          )))}
                    </Spacing>
                    <Spacing size={[1, 2, 2]} position="b">
                      {alternative}
                    </Spacing>
                  </form>
                )}
              />
            </Spacing>
          </MaxWidth>
        </PageWrap>
      </Page>
    );
  }
}

const mapStateToProps = ({ pages, location }) => {
  const data = pages.submit_your_design.data || {};

  return {
    prevUrlPathname: location.prev.pathname,
    title: getText(data.title),
    description: getText(data.description),
    buttonLabel: getText(data.button_label) || 'Submit design',
    alternative: htmlParser(getRichText(data.alternative || []))
  };
};

const mapDispatchToProps = {
  ...trackingActions,
  goToSuccess: () => ({ type: 'SUBMIT_YOUR_DESIGN_THANKS' })
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadCapture);
