import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import * as campaignsActions from '../../../../actions/campaigns';

import { ButtonNew as Button } from '../../../../components/atoms/Button';
import { Error } from '../../../../components/atoms/Form';
import { InlineGrid } from '../../../../components/atoms/Grid';
import Heading from '../../../../components/atoms/Heading';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';

import {
  Modal,
  ModalContent,
  ModalHeader
} from '../../../../components/molecules/Modal';

const RelaunchCampaignModal = ({
  isOpen,
  onClose,
  onSuccess,
  campaignId,
  swapOnRelaunch,
  duplicateProductMessage,
  errorOnRelaunch,
  handleErrorOnRelaunch
}) => {
  const dispatch = useDispatch();
  const [isRelaunching, setRelaunching] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);

  const quickRelaunchCampaign = async () => {
    setRelaunching(true);
    handleErrorOnRelaunch(null);

    try {
      await dispatch(campaignsActions.quickRelaunchCampaign(campaignId));

      setRelaunching(false);
      onSuccess();
    } catch (error) {
      setRelaunching(false);
      handleErrorOnRelaunch(
        'Unable to relaunch campaign, please try again later.'
      );
    }
  };

  const duplicateCampaign = async () => {
    setIsDuplicating(true);

    try {
      const response = await dispatch(
        campaignsActions.duplicateCampaign(campaignId)
      );
      window.location = `/campaign/edit/${response.value.campaignId}`;
    } catch (error) {
      setIsDuplicating(false);
      handleErrorOnRelaunch(
        'Unable to duplicate campaign, please try again later.'
      );
    }
  };

  return (
    <Modal isTransparent isOpen={isOpen} onRequestClose={onClose}>
      <Spacing size={[2, 4, 4, 5]} position="all">
        <ModalHeader>Relaunch campaign.</ModalHeader>
        <ModalContent>
          {swapOnRelaunch ? (
            <>
              <Error>{duplicateProductMessage}</Error>
              <Spacing size={2} position="t">
                <Paragraph>
                  Please select ‘Duplicate’ below and choose a new garment
                  within the campaign builder. You can then launch the new
                  campaign.
                </Paragraph>
              </Spacing>
            </>
          ) : (
            <Heading size="xs">
              Selecting 'Relaunch' will make your campaign live immediately.
            </Heading>
          )}
          <Spacing position="t">
            <InlineGrid direction={['col', 'row']} align="start">
              {swapOnRelaunch ? (
                <Button
                  kind="outline"
                  color="black"
                  onClick={duplicateCampaign}
                  className="w-full"
                  state={isDuplicating ? 'loading' : null}
                >
                  {isDuplicating ? 'Duplicating' : 'Duplicate'}
                </Button>
              ) : (
                <Button
                  kind="outline"
                  color="black"
                  onClick={quickRelaunchCampaign}
                  className="w-full"
                  state={isRelaunching ? 'loading' : null}
                >
                  {isRelaunching ? 'Relaunching' : 'Relaunch'}
                </Button>
              )}
              <Button
                kind="outline"
                color="black"
                className="w-full sm:ml-1 mt-1 sm:mt-0"
                onClick={onClose}
              >
                Cancel
              </Button>
            </InlineGrid>
            {errorOnRelaunch ? (
              <Spacing size={[1, 2]} position="t">
                <Error>{errorOnRelaunch}</Error>
              </Spacing>
            ) : null}
          </Spacing>
        </ModalContent>
      </Spacing>
    </Modal>
  );
};

RelaunchCampaignModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  campaignId: PropTypes.number.isRequired,
  swapOnRelaunch: PropTypes.bool,
  duplicateProductMessage: PropTypes.string,
  isDuplicating: PropTypes.bool,
  errorOnRelaunch: PropTypes.string,
  handleErrorOnRelaunch: PropTypes.func.isRequired
};

export default RelaunchCampaignModal;
