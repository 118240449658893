import merge from 'lodash/merge';

import { theme } from '../theme-config';

const fontSizeMap = Object.keys(theme.fontSize).reduce((acc, curr) => {
  acc[curr] = `text-${curr}`;
  return acc;
}, {});

const fontColorMap = merge(
  {
    inherit: 'text-inherit',
    white: 'text-white',
    black: 'text-black',
    grey: 'text-darkest-grey',
    'primary-grey': 'text-grey-primary',
    'darkest-dark-grey': 'text-darkest-dark-grey',
    green: 'text-green-primary',
    'dark-green': 'text-green-dark',
    red: 'text-red-primary'
  },
  Object.keys(theme.extend.colors).reduce((extendColorMap, current) => {
    return merge(
      extendColorMap,
      Object.keys(theme.extend.colors[current]).reduce(
        (shadeColorMap, shade) => {
          shadeColorMap[`${current}-${shade}`] = `text-${current}-${shade}`;
          return shadeColorMap;
        },
        {}
      )
    );
  }, {})
);

export { fontSizeMap, fontColorMap };
