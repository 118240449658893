import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { InlineGrid } from '../../../atoms/Grid';
import Link from '../../../atoms/Link';
import PageWrap from '../../../atoms/PageWrap';

import { ScrollTrackerContext } from '../../../utils/ScrollTracker';

import HeaderLogo from '../../../atoms/HeaderLogo';

import generalStyles from './style/general.module.css';

const cx = classNames.bind({ ...generalStyles });

class Header extends Component {
  state = {};

  componentDidUpdate(prevProps) {
    // If location changed
    if (
      this.props.location.type !== prevProps.location.type ||
      this.props.location.payload !== prevProps.location.payload
    ) {
      this.hideMobileMenu();
    }
  }

  toggleMenu() {
    if (this.state.headerIsActive) {
      this.hideMobileMenu();
    } else {
      this.showMobileMenu();
    }
  }

  showMobileMenu() {
    this.setState({
      headerIsActive: true
    });

    if (typeof window !== 'undefined') {
      document.body.classList.add('overflow-hidden');
    }
  }

  hideMobileMenu() {
    this.setState({
      headerIsActive: false
    });

    if (typeof window !== 'undefined') {
      document.body.classList.remove('overflow-hidden');
    }
  }

  onMenuClick() {
    if (this.state.headerIsActive) {
      this.hideMobileMenu();
    }
  }

  onCartBtnClick() {
    this.props.dispatch({ type: 'TOGGLE_BASKET_MODAL' });
  }

  render() {
    const { headerIsActive } = this.state;
    const { user, theme, navItems } = this.props;
    const innerClassName = cx('inner', {
      innerActive: headerIsActive,
      innerNotActive: !headerIsActive
    });

    if (!theme.isVisible) {
      return null;
    }

    return (
      <ScrollTrackerContext.Consumer>
        {(value) => {
          const scrollY = value.y;
          const isScrolled =
            (!theme.withAnnouncement && theme.isFixed && scrollY >= 20) ||
            false;
          const isLight =
            (!theme.withAnnouncement &&
              theme.color === 'light' &&
              !isScrolled) ||
            false;
          const headerClassName = cx('header', theme.color, {
            fixed: theme.isFixed,
            headerScrolled: isScrolled,
            headerIsActive: headerIsActive,
            headerWithAnnouncement: theme.withAnnouncement,
            'py-2': !isScrolled && !theme.withAnnouncement,
            'py-1': isScrolled,
            'bg-white':
              isScrolled &&
              !theme.withAnnouncement &&
              ['light', 'dark'].includes(theme.color),
            'bg-pastel-grey':
              isScrolled &&
              !theme.withAnnouncement &&
              theme.color === 'pastel-grey'
          });

          return (
            <header className={headerClassName}>
              <PageWrap>
                <div className={cx('outer')}>
                  <div
                    className={cx(
                      'mobileLogoTrigger',
                      theme.logo ? 'justify-between' : 'justify-end'
                    )}
                  >
                    {theme.logo && (
                      <HeaderLogo isLight={isLight && !headerIsActive} />
                    )}
                    {navItems.length ? (
                      <Link
                        className={cx('mobileMenuBtn', 'leading-normal')}
                        isNav
                        isLight={isLight && !headerIsActive}
                        onClick={this.toggleMenu.bind(this)}
                      >
                        menu
                      </Link>
                    ) : (
                      theme.isCartBtn && (
                        <Link
                          isNav
                          isLight={isLight && !headerIsActive}
                          to="/cart"
                          className="flex items-center"
                        >
                          Cart
                        </Link>
                      )
                    )}
                  </div>
                  <div className={innerClassName}>
                    <InlineGrid
                      justify={
                        navItems.length || theme.logo ? 'space-between' : 'end'
                      }
                      align={['start', 'start', 'start', 'center', 'center']}
                      direction={['col', 'col', 'col', 'row', 'row']}
                    >
                      {theme.logo && (
                        <div className="hidden mdlg:block">
                          <HeaderLogo isLight={isLight && !headerIsActive} />
                        </div>
                      )}
                      {navItems.map((item) => {
                        const roles = user.roles;
                        const isMember = roles.includes('member');
                        let linkProps = {
                          to: item.to
                        };

                        if (item.href) {
                          linkProps = {
                            href: item.href
                          };
                        }

                        if (headerIsActive && item.reRouteMobile) {
                          linkProps = {
                            to: item.reRouteMobile
                          };
                        }

                        if (
                          !item.userRole ||
                          (item.userRole === 'member' && isMember) ||
                          (item.userRole === 'visitor' && !isMember)
                        ) {
                          return (
                            <Link
                              {...linkProps}
                              isNav
                              key={item.name}
                              isLight={isLight && !headerIsActive}
                            >
                              {item.name}
                            </Link>
                          );
                        }

                        return null;
                      })}
                      {theme.isCartBtn && (
                        <Link
                          isNav
                          isLight={isLight && !headerIsActive}
                          to="/cart"
                          className="flex items-center leading-normal"
                        >
                          Cart
                        </Link>
                      )}
                    </InlineGrid>
                  </div>
                </div>
              </PageWrap>
            </header>
          );
        }}
      </ScrollTrackerContext.Consumer>
    );
  }
}

const mapStateToProps = ({ location, user, header, basket }) => ({
  location,
  user,
  basket,
  ...header
});

export default connect(mapStateToProps)(Header);
