import { predicate } from '@prismicio/client';

export const getPage = (page, options) => (dispatch, _, { prismicClient }) =>
  dispatch({
    type: 'FETCH_PAGE',
    payload: prismicClient
      .get({
        predicates: [predicate.at('document.type', page)],
        ...options
      })
      .then((document) => document.results[0]),
    meta: page
  });

export const getPageFromCollection = (collection, page, options) => (
  dispatch,
  _,
  { prismicClient }
) =>
  dispatch({
    type: 'FETCH_COLLECTION_PAGE',
    payload: prismicClient
      .get({
        predicates: [predicate.at(`my.${collection}.uid`, page)],
        ...options
      })
      .then((document) => document.results[0]),
    meta: { collection, page }
  });

export const getCollectionByCategory = (
  collection,
  link,
  category,
  options
) => (dispatch, _, { prismicClient }) =>
  dispatch({
    type: 'FETCH_COLLECTION',
    payload: prismicClient
      .get({
        predicates: [
          predicate.at('document.type', collection),
          predicate.at(`my.${collection}.${link}`, category)
        ],
        ...options
      })
      .then((document) => document.results),
    meta: { collection }
  });
