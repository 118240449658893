import produce from 'immer';
import jwtDecode from 'jwt-decode';
import merge from 'lodash/merge';

import config from '../config';

import * as persistentState from '../services/local-storage';

function getLogoutState(initialState, state) {
  return {
    ...initialState,
    country: state.country,
    currency: state.currency
  };
}

const initialState = {
  roles: [config.roles.VISITOR],
  extendedRoles: [],
  token: null,
  refreshToken: null,
  refreshTokenExpiration: null,
  isLoading: null,
  details: {
    isLoading: null
  },
  permissions: {
    canCreateConnection: false
  },
  preferences: {
    isLoading: null
  },
  emailPreferences: {
    isLoading: null
  },
  userId: null,
  country: null,
  currency: null,
  cartToken: null,
  campaigns: {
    ids: [],
    total: 0,
    isLoading: false
  },
  isExisting: null,
  following: {
    total: 0,
    list: []
  },
  favourites: {
    status: 'idle',
    list: []
  },
  rutter: {
    token: null,
    timestamp: null,
    connection: {
      status: 'idle'
    },
    status: 'idle'
  },
  offPlatformProducts: {
    rutter: [],
    bandcamp: []
  },
  connections: {
    rutter: [],
    bandcamp: []
  },
  favouriteCategories: {
    items: [],
    status: 'idle'
  },
  stripe: {
    clientSecret: null,
    paymentMethods: [],
    status: 'idle'
  },
  impersonatedUser: null,
  charges: {
    list: [],
    status: 'idle'
  }
};
const lsData = persistentState.get(config.localStorageAuthKey);
let lsState = { ...initialState, ...lsData };

if (lsData && lsData.token) {
  try {
    const { username, userId } = jwtDecode(lsData.token);
    lsState.roles = [config.roles.VISITOR, config.roles.MEMBER];
    lsState.userId = userId;
    lsState.details.email = username;
  } catch (error) {
    lsState = getLogoutState(initialState, lsState);
  }
}

export default (state = lsState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case 'SET_USER':
        if (action.payload === null) {
          return getLogoutState(initialState, state);
        } else {
          try {
            const { username, userId } = jwtDecode(action.payload.token);

            return {
              ...state,
              roles: [config.roles.VISITOR, config.roles.MEMBER],
              details: {
                ...state.details,
                email: username
              },
              userId,
              ...action.payload
            };
          } catch (error) {
            // If token is invalid, log out user
            return getLogoutState(initialState, state);
          }
        }
      case 'GET_REFRESH_TOKEN_PENDING':
        newState.isLoading = true;
        return newState;
      case 'GET_REFRESH_TOKEN_FULFILLED':
        newState.isLoading = false;
        return newState;
      case 'GET_REFRESH_TOKEN_REJECTED':
        newState.isLoading = false;
        return newState;
      case 'SET_USER_ROLES':
        if (action.payload.token) {
          const { roles } = jwtDecode(action.payload.token);
          const extendedRoles = [];
          if (roles.includes('ROLE_BUYER')) {
            extendedRoles.push(...[config.roles.BUYER]);
          }
          if (roles.includes('ROLE_CREATOR')) {
            extendedRoles.push(...[config.roles.CREATOR]);
          }
          if (roles.includes('ROLE_INTEGRATOR')) {
            extendedRoles.push(...[config.roles.INTEGRATOR]);
          }
          if (roles.filter((item) => item.includes('ROLE_ADMIN')).length > 0) {
            extendedRoles.push(...[config.roles.ADMIN]);
          }
          if (roles.includes('ROLE_ADMIN_SUPER')) {
            extendedRoles.push(...[config.roles.ADMIN_SUPER]);
          }
          newState.extendedRoles = [...extendedRoles];
          return newState;
        } else {
          return state;
        }
      case 'SET_USER_CURRENCY':
        return { ...state, currency: action.payload };
      case 'SET_USER_LOCALE':
        newState.country = action.payload.country;
        newState.currency = action.payload.currency;
        return newState;
      case 'GET_USER_PENDING':
        newState.details.isLoading = true;
        newState.isLoading = true;
        return newState;
      case 'GET_USER_FULFILLED':
        const {
          offPlatformAllowed,
          permissions,
          connections,
          ...details
        } = action.payload;
        return {
          ...state,
          details: {
            ...details,
            isLoading: false
          },
          offPlatformAllowed,
          permissions,
          connections,
          isLoading: false
        };
      case 'EDIT_USER_DETAILS_FULFILLED':
      case 'EDIT_USER_CREATOR_DETAILS_FULFILLED':
        return {
          ...state,
          details: {
            ...action.payload,
            isLoading: false
          }
        };
      case 'GET_USER_PREFERENCES_PENDING':
        newState.preferences.isLoading = true;
        return newState;
      case 'GET_USER_PREFERENCES_FULFILLED':
        newState.preferences = action.payload;
        newState.preferences.isLoading = false;
        return newState;
      case 'GET_EMAIL_USER_PREFERENCES_PENDING':
        newState.emailPreferences.isLoading = true;
        return newState;
      case 'GET_EMAIL_USER_PREFERENCES_FULFILLED':
        newState.emailPreferences.favourites = action.payload.find(
          (item) => item.reference === 'favourite'
        ).optIn;
        newState.emailPreferences.isLoading = false;
        return newState;
      case 'EDIT_USER_PREFERENCES_FULFILLED':
        newState.preferences = action.payload;
        newState.preferences.isLoading = false;
        return newState;
      case 'EDIT_EMAIL_USER_PREFERENCES_FULFILLED':
        newState.emailPreferences.favourites = !newState.emailPreferences
          .favourites;
        return newState;
      case 'CREATE_CART_FULFILLED':
        newState.cartToken = action.payload.token;
        return newState;
      case 'CHECKOUT_PAYPAL_FULFILLED':
      case 'CHECKOUT_STRIPE_FULFILLED':
      case 'CONFIRM_STRIPE_PAYMENT_INTENT_FULFILLED':
        return {
          ...state,
          cartToken: null
        };
      case 'GET_USER_CAMPAIGNS_PENDING':
      case 'GET_USER_CAMPAIGNS_FULL_PENDING':
        newState.campaigns.isLoading = true;
        return newState;
      case 'GET_USER_CAMPAIGNS_REJECTED':
      case 'GET_USER_CAMPAIGNS_FULL_REJECTED':
        newState.campaigns.isLoading = false;
        return newState;
      case 'GET_USER_CAMPAIGNS_FULFILLED':
      case 'GET_USER_CAMPAIGNS_FULL_FULFILLED':
        newState.campaigns.ids = action.payload.results.map(
          (campaign) => campaign.campaignId
        );
        newState.campaigns.total = action.payload.total;
        newState.campaigns.isLoading = false;
        return newState;
      case 'GET_CART_FULFILLED':
        newState.cartToken = action.payload.token;
        return newState;
      case 'LOOKUP_USER_FULFILLED':
        newState.isExisting = action.payload.exists;
        return newState;
      case 'FOLLOW_USER_FULFILLED':
        const newFollowEntry = {
          userId: action.meta.userFollowee,
          isFollowing: true
        };
        const existingFollowEntry = newState.following.list.find(
          (el) => el.userId === newFollowEntry.userId
        );
        if (existingFollowEntry) {
          existingFollowEntry.isFollowing = true;
        } else {
          newState.following.list.push(newFollowEntry);
        }
        return newState;
      case 'UNFOLLOW_USER_FULFILLED':
        const newUnfollowEntry = {
          userId: action.meta,
          isFollowing: false
        };
        const existingUnfollowEntry = newState.following.list.find(
          (el) => el.userId === newUnfollowEntry.userId
        );
        if (existingUnfollowEntry) {
          existingUnfollowEntry.isFollowing = false;
        } else {
          newState.following.list.push(newUnfollowEntry);
        }
        return newState;
      case 'GET_USER_FOLLOWEES_FULFILLED':
        const { results, total } = action.payload;
        newState.following = {
          total,
          list: results.map((item) => {
            return {
              userId: item.userFollowee.userId,
              profileName: item.userFollowee.profileName,
              slug: item.userFollowee.slug,
              isFollowing: true
            };
          })
        };
        return newState;
      case 'FAVOURITE_CAMPAIGN_FULFILLED':
        newState.favourites.list.push(action.payload);
        return newState;
      case 'GET_USER_FAVOURITES_PENDING':
        newState.favourites.status = 'pending';
        return newState;
      case 'GET_USER_FAVOURITES_REJECTED':
        newState.favourites.status = 'rejected';
        return newState;
      case 'GET_USER_FAVOURITES_FULFILLED':
        newState.favourites.list = action.payload;
        newState.favourites.status = 'resolved';
        return newState;
      case 'DELETE_USER_FAVOURITE_FULFILLED':
        const filteredList = newState.favourites.list.filter(
          (favourite) =>
            favourite.userFavouriteId !== action.meta.userFavouriteId
        );
        newState.favourites.list = filteredList;
        return newState;
      case 'LOOKUP_USER_FAVOURITES_PENDING':
        newState.favourites.status = 'pending';
        return newState;
      case 'LOOKUP_USER_FAVOURITES_REJECTED':
        newState.favourites.status = 'rejected';
        return newState;
      case 'LOOKUP_USER_FAVOURITES_FULFILLED':
        newState.favourites.list = action.payload;
        newState.favourites.status = 'resolved';
        return newState;
      case 'SET_USER_RUTTER_TOKEN':
        newState.rutter.token = action.payload;
        newState.rutter.timestamp = action.payload ? Date.now() : null;
        return newState;
      case 'CREATE_USER_RUTTER_CONNECTION_PENDING':
        newState.rutter.connection.status = 'pending';
        return newState;
      case 'CREATE_USER_RUTTER_CONNECTION_REJECTED':
        newState.rutter.connection.status = 'rejected';
        return newState;
      case 'CREATE_USER_RUTTER_CONNECTION_FULFILLED':
        // Connect page connection status
        newState.rutter.connection.status = 'resolved';
        // Rutter Link connection data
        newState.connections.rutter = merge([], state.connections.rutter, [
          action.payload
        ]);
        return newState;
      case 'CREATE_USER_RUTTER_CAMPAIGN_PRODUCTS_PENDING':
      case 'CREATE_USER_RUTTER_CAMPAIGN_PRODUCT_PENDING':
      case 'DELETE_USER_RUTTER_CAMPAIGN_PRODUCTS_PENDING':
        newState.rutter.status = 'pending';
        return newState;
      case 'CREATE_USER_RUTTER_CAMPAIGN_PRODUCTS_REJECTED':
      case 'CREATE_USER_RUTTER_CAMPAIGN_PRODUCT_REJECTED':
      case 'DELETE_USER_RUTTER_CAMPAIGN_PRODUCTS_REJECTED':
        newState.rutter.status = 'rejected';
        return newState;
      case 'CREATE_USER_RUTTER_CAMPAIGN_PRODUCTS_FULFILLED':
      case 'CREATE_USER_RUTTER_CAMPAIGN_PRODUCT_FULFILLED':
      case 'DELETE_USER_RUTTER_CAMPAIGN_PRODUCTS_FULFILLED':
        newState.rutter.status = 'resolved';
        return newState;
      case 'GET_USER_FAVOURITE_CATEGORIES_PENDING':
        if (state.favouriteCategories.status === 'idle') {
          newState.favouriteCategories.status = 'loading';
        }
        return newState;
      case 'GET_USER_FAVOURITE_CATEGORIES_FULFILLED':
        newState.favouriteCategories.items = action.payload;
        if (state.favouriteCategories.status !== 'saved') {
          newState.favouriteCategories.status = 'resolved';
        }
        return newState;
      case 'GET_USER_FAVOURITE_CATEGORIES_REJECTED':
        newState.favouriteCategories.status = 'idle';
        return newState;
      case 'SAVE_USER_FAVOURITE_CATEGORIES_PENDING':
        newState.favouriteCategories.status = 'saving';
        return newState;
      case 'SAVE_USER_FAVOURITE_CATEGORIES_FULFILLED':
        newState.favouriteCategories.status = 'saved';
        return newState;
      case 'RESET_CATEGORY_PREFERENCES_FORM':
        newState.favouriteCategories.status = 'resolved';
        return newState;
      case 'USER_FAVOURITE_CATEGORIES_CLICKED':
        newState.favouriteCategories.status = 'resolved';
        return newState;
      case 'GET_USER_STRIPE_PAYMENT_METHODS_PENDING':
        newState.stripe.status = 'pending';
        return newState;
      case 'CREATE_USER_STRIPE_SETUP_INTENT_REJECTED':
      case 'GET_USER_STRIPE_PAYMENT_METHODS_REJECTED':
        newState.stripe.status = 'rejected';
        return newState;
      case 'CREATE_USER_STRIPE_SETUP_INTENT_FULFILLED':
        newState.stripe.clientSecret = action.payload.clientSecret;
        newState.stripe.status = 'resolved';
        return newState;
      case 'GET_USER_STRIPE_PAYMENT_METHODS_FULFILLED':
        newState.stripe.paymentMethods = action.payload;
        newState.stripe.status = 'resolved';
        return newState;
      case 'SAVE_USER_STRIPE_PAYMENT_METHOD_FULFILLED':
        newState.stripe.clientSecret = initialState.stripe.clientSecret;
        return newState;
      case 'GET_USER_BANDCAMP_PRODUCTS_FULFILLED':
        newState.offPlatformProducts.bandcamp = action.payload;
        return newState;
      case 'SET_IMPERSONATED_USER':
        newState.impersonatedUser = action.payload;
        return newState;
      case 'GET_USER_CHARGES_PENDING':
        newState.charges.status = 'pending';
        return newState;
      case 'GET_USER_CHARGES_REJECTED':
        newState.charges.status = 'rejected';
        return newState;
      case 'GET_USER_CHARGES_FULFILLED':
        newState.charges.list = action.payload;
        newState.charges.status = 'resolved';
        return newState;
      case 'SET_USER_REFRESH_TOKEN':
        newState.refreshToken = action.payload.refreshToken;
        newState.refreshTokenExpiration = action.payload.refreshTokenExpiration;
        return newState;
      default:
        return state;
    }
  });
