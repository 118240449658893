import React from 'react';

import { Grid, GridItem, InlineGrid } from '../../../components/atoms/Grid';
import Heading from '../../../components/atoms/Heading';
import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';
import { ButtonNew as Button } from '../../../components/atoms/Button';

import generalStyles from './style/general.module.css';

export default function Item({ index, title, image, copy, buttons }) {
  return (
    <div className={generalStyles.container}>
      <Grid gap={[1, 1, 2, 5]}>
        <GridItem columnSize={[12, 2, 2]}>
          <div className={generalStyles.index}>{index}</div>
        </GridItem>
        <GridItem columnSize={[10, 10, 6]}>
          <Spacing size={2} position="b">
            <Heading size={['l', 'l', 'l', 'xl']} tag="h3">
              {title}
            </Heading>
          </Spacing>
          <Paragraph size={['xs', 's']}>{copy}</Paragraph>
          {buttons && (
            <Spacing size={25}>
              <InlineGrid
                justify="start"
                direction={['col', 'col', 'row']}
                align={['start', 'start', 'center']}
              >
                {buttons.map((item) => (
                  <Spacing key={item.id} position="b" size={[1, 1, 0]}>
                    <Spacing key={item.id} position="r" size={[0, 0, 1]}>
                      <Button to={item.to}>{item.text}</Button>
                    </Spacing>
                  </Spacing>
                ))}
              </InlineGrid>
            </Spacing>
          )}
        </GridItem>
        <GridItem columnSize={[12, 12, 4]}>
          <Spacing size={[3, 3, 0]} position="t">
            <div className={generalStyles.imgContainer}>
              <img className={generalStyles.img} src={image} alt={title} />
            </div>
          </Spacing>
        </GridItem>
      </Grid>
    </div>
  );
}
