import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';

import * as ordersActions from '../../actions/orders';

import Page from '../../components/utils/Page';

import PageWrap from '../../components/atoms/PageWrap';
import {
  Form,
  Field,
  Label,
  Error,
  handleFormError,
  validateFormWithSchema
} from '../../components/atoms/Form';
import SelectCountries from '../../components/atoms/Select/SelectCountries';
import Heading from '../../components/atoms/Heading';
import { ButtonNew as Button } from '../../components/atoms/Button';
import Paragraph from '../../components/atoms/Paragraph';
import Spacing from '../../components/atoms/Spacing';
import MaxWidth from '../../components/atoms/MaxWidth';
import { InlineGrid } from '../../components/atoms/Grid';
import LiveChat from '../../components/atoms/LiveChat';

const productsOptions = ['T-shirt', 'Sweatshirt', 'Hoodies', 'Womens', 'Other'];

class BulkOrders extends PureComponent {
  async handleSubmit(formData, actions) {
    try {
      this.props.bulkOrder(formData);
    } catch (error) {
      handleFormError(error, actions);
      return actions.setSubmitting(false);
    }

    actions.resetForm();
    actions.setStatus({ message: 'Your message has been sent.' });

    this.messageTimeout = setTimeout(() => {
      actions.setStatus({ message: null });
    }, 3000);
  }

  componentWillUnmount() {
    clearTimeout(this.messageTimeout);
  }

  handleValidate(values) {
    const fileUploadingMessage = 'Please wait until file is fully uploaded';

    const schema = yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup
        .string()
        .required()
        .email(),
      product: yup.string().required(),
      colour: yup.string().required(),
      quantity: yup.number().required(),
      positions: yup.string().required(),
      artwork1: yup
        .string()
        .required()
        .url(fileUploadingMessage),
      artwork2: yup.string().url(fileUploadingMessage)
    });

    return validateFormWithSchema(schema, values);
  }

  render() {
    return (
      <Page title="Bulk Orders">
        <PageWrap>
          <MaxWidth value={550} center>
            <Spacing position="t" type="padding">
              <Heading size={['l', 'xl', 'xxl']} tag="h1">
                Quick <br />
                quote form
              </Heading>
              <Paragraph>
                Get a quick quote for a bulk order of custom printed apparel
              </Paragraph>
            </Spacing>

            <Form
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                product: productsOptions[0],
                colour: '',
                quantity: '',
                positions: '',
                country: 'GB',
                artwork1: '',
                artwork2: ''
              }}
              onSubmit={this.handleSubmit.bind(this)}
              validate={this.handleValidate.bind(this)}
              render={({ isSubmitting, handleSubmit, status }) => (
                <form onSubmit={handleSubmit}>
                  <Spacing size={3} position="t">
                    <Spacing size={1} position="b">
                      <Heading size="xxs">Your details</Heading>
                    </Spacing>
                    <Field name="firstName" placeholder="First name">
                      <Label htmlFor="firstName">First Name*</Label>
                    </Field>
                    <Field name="lastName" placeholder="Last name">
                      <Label htmlFor="lastName">Last Name*</Label>
                    </Field>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Your email address"
                      optional
                    >
                      <Label htmlFor="email">Email*</Label>
                    </Field>
                  </Spacing>
                  <Spacing size={3}>
                    <Spacing size={1} position="b">
                      <Heading size="xxs">Your products</Heading>
                    </Spacing>
                    <Field
                      type="select"
                      name="product"
                      options={productsOptions.map((option) => ({
                        value: option,
                        label: option
                      }))}
                    >
                      <Label htmlFor="product">Product*</Label>
                    </Field>
                    <Field name="colour" placeholder="Black, White, Navy">
                      <Label htmlFor="colour">Colour*</Label>
                    </Field>
                    <Field name="quantity" placeholder="100">
                      <Label htmlFor="quantity">Quantity*</Label>
                    </Field>
                  </Spacing>
                  <Spacing size={3}>
                    <Spacing size={1} position="b">
                      <Heading size="xxs">Your design</Heading>
                    </Spacing>
                    <Field
                      name="positions"
                      placeholder="Left pocket, Center back"
                    >
                      <Label htmlFor="positions">Positions*</Label>
                    </Field>
                    <Field
                      type="file"
                      name="artwork1"
                      placeholder="Upload"
                      accept="image/png,image/jpg,image/jpeg,image/svg"
                      uploadTo="/_uploader/bulk_order_artwork/upload"
                    >
                      <Label htmlFor="artwork1">Artwork 1</Label>
                    </Field>
                    <Field
                      type="file"
                      name="artwork2"
                      placeholder="Upload"
                      accept="image/png,image/jpg,image/jpeg,image/svg"
                      uploadTo="/_uploader/bulk_order_artwork/upload"
                    >
                      <Label htmlFor="artwork2">Artwork 2</Label>
                    </Field>
                  </Spacing>
                  <Spacing size={3}>
                    <Spacing size={1} position="b">
                      <Heading size="xxs">Delivery options</Heading>
                    </Spacing>
                    <SelectCountries name="country">
                      <Label htmlFor="country">Country*</Label>
                    </SelectCountries>
                  </Spacing>
                  <Spacing size={3} position="t">
                    <Spacing size={1} position="b">
                      <Heading size="xxs">Additional comments</Heading>
                    </Spacing>
                    <Field
                      type="textarea"
                      name="comments"
                      placeholder="Your message here"
                      optional
                      align="start"
                    >
                      <Label className="self-start" htmlFor="comments">
                        Your comments
                      </Label>
                    </Field>
                  </Spacing>

                  <Spacing size={2} position="t">
                    <InlineGrid>
                      <Button type="submit" state={isSubmitting && 'loading'}>
                        Submit
                      </Button>

                      {status &&
                        ((status.error && (
                          <Paragraph>
                            <Error>{status.error}</Error>
                          </Paragraph>
                        )) ||
                          (status.message && (
                            <Paragraph>{status.message}</Paragraph>
                          )))}
                    </InlineGrid>
                  </Spacing>
                </form>
              )}
            />
          </MaxWidth>
        </PageWrap>
        <LiveChat />
      </Page>
    );
  }
}

export default connect(null, { ...ordersActions })(BulkOrders);
