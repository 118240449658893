import React from 'react';

import Page from '../../components/utils/Page';

import Heading from '../../components/atoms/Heading';
import { ListWrapper, ListItem } from '../../components/atoms/List';
import PageWrap from '../../components/atoms/PageWrap';
import Paragraph from '../../components/atoms/Paragraph';
import MaxWidth from '../../components/atoms/MaxWidth';
import Spacing from '../../components/atoms/Spacing';
import LiveChat from '../../components/atoms/LiveChat';

import htmlParser from '../../helpers/htmlParser';

import data from './data';

export default function TermsSellers() {
  const paragraphSpacing = 2;
  const paragraphSize = 's';
  const headingSize = 'm';
  const headingTag = 'h3';

  return (
    <Page title="Terms for Creators">
      <PageWrap>
        <MaxWidth value={877} center>
          <Spacing>
            <Heading center tag="h2" size="xxl">
              Terms for creators
            </Heading>
          </Spacing>
          {data.map(item => (
            <Spacing key={item.title} size={5}>
              <Heading tag={headingTag} size={headingSize}>
                {item.title}
              </Heading>
              {item.sections.map((section, index) => {
                if (section.type === 'paragraph') {
                  return (
                    <Spacing key={index} size={paragraphSpacing}>
                      <Paragraph size={paragraphSize}>
                        {section.title && (
                          <Spacing size={1}>
                            <Heading tag="h4" size="xs">
                              {section.title}
                            </Heading>
                          </Spacing>
                        )}
                        {htmlParser(section.content)}
                      </Paragraph>
                    </Spacing>
                  );
                } else {
                  return (
                    <Spacing key={index} size={paragraphSpacing}>
                      <ListWrapper>
                        {section.content.map((listItem, index) => (
                          <ListItem key={index}>
                            {htmlParser(listItem)}
                          </ListItem>
                        ))}
                      </ListWrapper>
                    </Spacing>
                  );
                }
              })}
            </Spacing>
          ))}
        </MaxWidth>
      </PageWrap>
      <LiveChat />
    </Page>
  );
}
