import React from 'react';

import Icon from '../../../atoms/Icon';

import checkIcon from '../../../../img/sprites/check.svg';

import generalStyles from './style/general.module.css';

export default class TickCheckbox extends React.PureComponent {
  render() {
    const { name, id, children, value, onChange, ...domProps } = this.props;

    return (
      <label htmlFor={name} className={generalStyles.holder}>
        <input
          type="checkbox"
          name={name}
          id={id || name}
          value={value}
          checked={value}
          onChange={onChange}
          className={generalStyles.input}
          {...domProps}
        />
        {children}
        <div className={generalStyles.checkbox}>
          <Icon glyph={checkIcon} width="18" height="18" />
        </div>
      </label>
    );
  }
}
