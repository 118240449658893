import React from 'react';
import Paragraph from '../../../components/atoms/Paragraph';

const CheckoutDiscountPanel = ({ deliveries }) => {
  const isFreeStandardDeliveryApplied =
    deliveries.length && deliveries[0].price === 0.0;

  if (!!isFreeStandardDeliveryApplied)
    return (
      <div className="py-15">
        <div className="flex justify-between pt-1">
          <Paragraph size="xxs">Free Standard Delivery</Paragraph>
          <Paragraph color="green" size="xxs">
            Applied
          </Paragraph>
        </div>
      </div>
    );

  return null;
};

export default CheckoutDiscountPanel;
