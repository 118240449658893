import React from 'react';
import * as yup from 'yup';

import Spacing from '../../../../components/atoms/Spacing';
import SelectColours from '../../../../components/atoms/Select/SelectColours';
import { ButtonNew as Button } from '../../../../components/atoms/Button';
import { Form, DefaultField } from '../../../../components/atoms/Form';
import Heading from '../../../../components/atoms/Heading';

const ProductOptionsForm = ({
  item,
  campaign,
  isUserCampaign,
  isEnded,
  colourId,
  size,
  onSubmit,
  onChange,
  children
}) => {
  return (
    <Form
      initialValues={{
        colourId,
        size: size || '',
        campaignProductId: item.campaignProductId
      }}
      validationSchema={yup.object().shape({
        size: yup.string().required('Please select a size')
      })}
      onChange={onChange}
      onSubmit={onSubmit}
      enableReinitialize
      render={({ isSubmitting, handleSubmit, status, errors }) => (
        <form onSubmit={handleSubmit}>
          {!isEnded && (
            <Spacing size={1}>
              <div data-test-id="size">
                <DefaultField
                  type="select"
                  name="size"
                  options={item.product.sizes.map((size) => ({
                    value: size.code,
                    label: size.name
                  }))}
                  placeholder="Size"
                  scrollToError={false}
                />
              </div>
            </Spacing>
          )}

          {campaign.products.length > 1 && (
            <Spacing size={1}>
              <DefaultField
                type="select"
                name="campaignProductId"
                placeholder="Product"
                options={campaign.products.map((item) => ({
                  value: item.campaignProductId,
                  label: item.product.name
                }))}
                scrollToError={false}
              />
            </Spacing>
          )}
          {item.colours.length > 1 && (
            <Spacing size={1}>
              <SelectColours
                data={item.colours}
                name="colourId"
                placeholder="Colour"
                scrollToError={false}
              />
            </Spacing>
          )}

          {children}

          <Spacing size={3} position="t">
            {!isEnded &&
              (campaign.draft ? (
                <Button
                  type="submit"
                  fullWidth
                  state={
                    !campaign.samples || !isUserCampaign ? 'disabled' : null
                  }
                >
                  {campaign.samples
                    ? 'Add sample to cart'
                    : 'Samples unavailable'}
                </Button>
              ) : (
                <Button type="submit" fullWidth>
                  Add to cart
                </Button>
              ))}
          </Spacing>
          {campaign.draft && !campaign.samples && (
            <Spacing size={1} position="t">
              <Heading tag="p" className="opacity-25" size="xxs">
                Samples are currently unavailable
              </Heading>
            </Spacing>
          )}
        </form>
      )}
    />
  );
};

export default ProductOptionsForm;
