import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';

import * as userActions from '../../../actions/user';

import { ButtonNew as Button } from '../../../components/atoms/Button';
import Alert from '../../../components/atoms/Alert';
import {
  DefaultField as Field,
  Form,
  handleFormError
} from '../../../components/atoms/Form';
import Link from '../../../components/atoms/Link';
import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';
import SuccessFeedback from '../../../components/atoms/SuccessFeedback';

import BoxInputFeedback from '../../../components/molecules/BoxInputFeedback';
import { CtaBox } from '../../../components/molecules/Box';
import TermsFormField from '../../../components/molecules/TermsFormField';

const messageMap = {
  0: {
    button: 'Save and continue',
    info: null
  },
  1: {
    button: 'Sign in and continue',
    info:
      'You already have an account, enter your password to sign in for fast checkout'
  },
  2: {
    button: 'Sign up instantly and continue',
    info:
      'Enter a password to sign up for faster checkout and to track your orders'
  },
  3: {
    button: 'Save and continue',
    info: null
  }
};

const CheckoutUserInfo = ({
  paypalEmail,
  isGuest = true,
  email = '',
  onSave,
  onEdit,
  isSaved
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { lookupUser, login, signUp } = userActions;
  // stage 0 = fist visit as guest
  // stage 1 guest email has an account
  // stage 2 guest email has no account
  // stage 3 is signed in
  const [userStage, setUserStage] = useState(0);
  const [savedInfo, setSavedInfo] = useState({ isGuest, email });
  const passwordRequired = userStage === 1 || userStage === 2;

  useEffect(() => {
    const {
      isExisting,
      userId,
      token,
      details: { email }
    } = user;
    if (paypalEmail) {
      saveStep(paypalEmail, true);
      return;
    }

    if (userId && token) {
      setUserStage(3);
      saveStep(email, false);
      return;
    }

    switch (isExisting) {
      case null:
        setUserStage(0);
        break;
      case true:
        setUserStage(1);
        break;
      case false:
        setUserStage(2);
        break;
      default:
        break;
    }
  }, [paypalEmail, user, saveStep]);

  const saveStep = useCallback(
    (email, isGuest, actions) => {
      setSavedInfo({
        isGuest,
        email
      });
      if (!isSaved) {
        onSave({ email });

        if (actions) {
          actions.resetForm();
          actions.setSubmitting(false);
        }
      }
    },
    [onSave, isSaved]
  );

  const handleSubmit = async ({ email, password }, actions) => {
    actions.setSubmitting(true);

    switch (userStage) {
      case 0:
        try {
          const {
            value: { exists }
          } = await dispatch(lookupUser(email));
          setUserStage(exists ? 1 : 2);
        } catch (error) {
          handleFormError(error, actions);
        }

        actions.setSubmitting(false);
        break;
      case 1:
        try {
          await dispatch(lookupUser(email));
          await dispatch(login(email, password));
        } catch (error) {
          handleFormError(error, actions);
        }

        actions.setSubmitting(false);
        break;
      case 2:
        try {
          await dispatch(lookupUser(email));
          await dispatch(signUp({ email, password }));
          await dispatch({ type: 'KLAVIYO_IDENTIFY', payload: { email } });
        } catch (error) {
          handleFormError(error, actions);
        }

        actions.setSubmitting(false);
        break;
      case 3:
        saveStep(email, false, actions);
        break;
      default:
        break;
    }
  };

  const handleGuestCheckout = async (email) => {
    const isValidEmail = await yup
      .string()
      .email()
      .isValid(email);

    if (!isValidEmail) {
      return false;
    }

    saveStep(email, true);
  };

  const handleEditStep = () => {
    if (userStage !== 3) {
      setUserStage(0);
    }
    onEdit();
  };

  if (isSaved) {
    return (
      <CtaBox
        title="1. Your info"
        cta={paypalEmail ? null : 'Edit'}
        onClick={handleEditStep}
      >
        {savedInfo.isGuest ? (
          savedInfo.email
        ) : (
          <SuccessFeedback message={`Signed in as ${savedInfo.email}`} />
        )}
      </CtaBox>
    );
  }

  return (
    <Spacing size={4} position="b">
      <Form
        initialValues={{
          email: email || '',
          password: ''
        }}
        validationSchema={yup.object().shape({
          email: yup
            .string()
            .email()
            .required(
              userStage === 2
                ? 'Email address is required to continue'
                : undefined
            ),
          password: passwordRequired
            ? yup
                .string()
                .required(
                  userStage === 2
                    ? 'Password is required to sign up'
                    : undefined
                )
                .min(8)
            : yup.string()
        })}
        onSubmit={handleSubmit}
        render={({ isSubmitting, handleSubmit, status, values }) => {
          const { error } = { ...status };
          let statusMessage = null;

          if (error) {
            statusMessage = <Alert kind="error" message={error} />;
          }

          return (
            <form onSubmit={handleSubmit}>
              {userStage === 3 ? (
                <Spacing size={1} position="t">
                  <BoxInputFeedback email={user.details.email} />
                </Spacing>
              ) : (
                <>
                  <Spacing size={1}>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email address"
                      data-test-id="email"
                    />
                  </Spacing>
                  {messageMap[userStage].info && (
                    <Spacing size={15} position="b">
                      <Paragraph size="xxs">
                        {messageMap[userStage].info}
                      </Paragraph>
                    </Spacing>
                  )}
                  {userStage !== 0 && (
                    <Spacing size={1}>
                      <Field
                        type="password"
                        name="password"
                        placeholder="Password"
                      />
                      {userStage === 2 && (
                        <Spacing size={1} position="t">
                          <TermsFormField name="acceptTerms" size="xxs" />
                        </Spacing>
                      )}
                    </Spacing>
                  )}
                </>
              )}

              <Spacing size={15} position="t">
                <Button
                  data-test-id="step-1-submit"
                  type="submit"
                  state={isSubmitting ? 'loading' : null}
                  fullWidth
                >
                  {messageMap[userStage].button}
                </Button>
              </Spacing>
              <Spacing size={1} position="t">
                {statusMessage}
              </Spacing>

              {userStage >= 1 && userStage <= 2 && (
                <Spacing size={2} position="t">
                  <Link
                    isUnderlined
                    onClick={() => handleGuestCheckout(values.email)}
                    className="text-xxs block text-center mx-auto"
                    data-test-id="guest-checkout"
                  >
                    Checkout as a guest instead
                  </Link>
                </Spacing>
              )}
            </form>
          );
        }}
      />
    </Spacing>
  );
};

export default CheckoutUserInfo;
