import React from 'react';

import Spacing from '../../../../components/atoms/Spacing';
import Paragraph from '../../../../components/atoms/Paragraph';

import Main from '../../Main';
import Title from '../../Title';
import Card from '../../Card';

import teamImage from '../../../../img/pages/people/team.jpg';
import melanieImage from '../../../../img/pages/people/melanie.png';
import statsTeamImage from '../../../../img/pages/people/stats-team.svg';
import statsTeamMobileImage from '../../../../img/pages/people/stats-team-mobile.svg';
import statsLeadershipImage from '../../../../img/pages/people/stats-leadership.svg';
import statsLeadershipMobileImage from '../../../../img/pages/people/stats-leadership-mobile.svg';

const Team = () => {
  return (
    <Main title="Team" image={teamImage} isReversedGrid>
      <Spacing size={7} position="b">
        <Title titleCopy="Where were we" />
        <Paragraph className="mb-3">
          When we started out in 2016, we were a team of just a few people
          working out of a tiny unit off Broadway Market.
        </Paragraph>
        <Paragraph className="mb-3">
          Fast forward four years to 2020, and we’d bloomed into a team of 33
          people (and upgraded our office too, in the process). Like a lot of
          scrappy startups, this growth happened organically; hiring ad hoc for
          roles as the need for them emerged. As a result, by the time we’d
          become a team of 33, we didn’t have the internal HR department, or the
          rigorous hiring policies, to match our size.
        </Paragraph>
        <Paragraph className="mb-3">
          In the summer of 2020 our community called us out for not doing enough
          to protect our Black and Brown employees. And it made us reflect on
          where we had fallen short. We acknowledge this, and we are sorry.
        </Paragraph>
        <Paragraph>
          The actions we take speak louder than any words could. This is why
          we’re focusing our efforts to do everything we can to make sure the
          same situation couldn’t happen again, and to safeguard the welfare and
          happiness of every single member on our team.
        </Paragraph>
      </Spacing>
      <Spacing size={7} position="b">
        <Title titleCopy="What we've done" />
        <Paragraph className="mb-3">
          Building a team that’s as representative of wider society as it can
          be, in which every person feels supported, empowered, and able to be
          themselves, is work we’re committed to doing. And we made a promise to
          double down on doing this work the right way. It means tangible
          initiatives that make hiring as accessible as possible, working to
          create a supportive internal culture, and constantly monitoring where
          we’re coming up short so that we can be better.
        </Paragraph>
        <img
          src={statsTeamMobileImage}
          alt="Everpress team stats 57.7% Female employees Up 1.19% from August 2020, 5% Non Binary employees Up 5% from August 2020, 37.8% Male employees Down 8.35% from August 2020, 67.6% white employees down 8.35% from August 2020, 32.4% BAME employees Up 10% from August 2020"
          className="w-100 mdlg:hidden"
        />{' '}
        <img
          src={statsTeamImage}
          alt="Everpress team stats: 57.7% Female employees Up 1.19% from August 2020, 5% Non Binary employees Up 5% from August 2020, 37.8% Male employees Down 8.35% from August 2020, 67.6% white employees down 8.35% from August 2020, 32.4% BAME employees Up 10% from August 2020"
          className="w-100 hidden mdlg:block"
        />
        <div className="border-b-1 border-grey-lighter my-7" />
        <Paragraph className="mb-4">
          We know that diversity is important throughout any company too, not
          just at the lowest level. So we’re monitoring our management stats
          too:
        </Paragraph>
        <img
          src={statsLeadershipMobileImage}
          alt="Everpress leadership stats: 57.1% Female up 7% from August 2020, 42.9% Males down from 7.1% from August 2020, 26.7% BAME up 13% from August 2020, 73.3% down 10.9% from August 2020"
          className="w-100 mdlg:hidden"
        />{' '}
        <img
          src={statsLeadershipImage}
          alt="Everpress leadership stats: 57.1% Female up 7% from August 2020, 42.9% Males down from 7.1% from August 2020, 26.7% BAME up 13% from August 2020, 73.3% down 10.9% from August 2020"
          className="w-100 hidden mdlg:block"
        />
      </Spacing>
      <div className="border-b-1 border-grey-lighter my-7" />
      <Paragraph className="mb-3">
        We’ve also created new roles to make sure this work is happening daily
        within our team, and to help foster an inclusive workplace culture:
      </Paragraph>
      <Spacing size={4}>
        <Card
          imageSrc={melanieImage}
          caption={
            <>
              <span className="block">HEAD OF PEOPLE</span>
              Melanie Connor, JOINED MAY '21
            </>
          }
          title="I'M RESPONSIBLE FOR CREATING GREAT EXPERIENCES FOR OUR TEAM. THIS STARTS FROM THE RECRUITMENT PROCESS TO HOW WE CREATE A CULTURE THAT IS INVITING AND INCLUSIVE FOR EVERYONE, HOW WE SUPPORT THE DEVELOPMENT OF OUR TEAM AND EVERYTHING IN BETWEEN."
          subtitle="I work with our leaders to develop great teams in which every person feels valued and is contributing to our success."
        />
      </Spacing>
      <Spacing size={7} position="b">
        <Title titleCopy="What we’re doing next" />
        <Paragraph className="mb-3">
          We are taking strategic steps to make sure each application process is
          as open as possible. This starts with the way that jobs are
          advertised, and includes promoting all our opportunities through
          different platforms, to help drive better representation in the
          applications we receive. We use the data from our Equality Monitoring
          survey to help us understand where we can increase representation when
          hiring.
        </Paragraph>
      </Spacing>
    </Main>
  );
};

export default Team;
