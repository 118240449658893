import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import cropString from '../../../../helpers/cropString';
import getInitials from '../../../../helpers/getInitials';

import {
  followUser,
  unfollowUser,
  getUserFollowees
} from '../../../../actions/user';

import Spacing from '../../../atoms/Spacing';
import Alert from '../../../atoms/Alert';
import Paragraph from '../../../atoms/Paragraph';

import FollowArtist from '../index';
import AuthenticationContainer from '../../AuthenticationContainer';
import { ModalNew } from '../../Modal';
import { TableItem, TableRow } from '../../Table';

const FollowArtistContainer = ({
  campaigns,
  triggerAuthentication,
  showCampaignName = true,
  kind
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user, shallowEqual);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleFollowUser = async (isFollowing, userId) => {
    if (user.userId) {
      setError(null);
      if (isFollowing) {
        try {
          await dispatch(unfollowUser(userId));
        } catch (err) {
          if (err.response.status === 400) {
            setError('Unable to unfollow this creator.');
          }
        }
      } else {
        try {
          await dispatch(
            followUser({
              userFollower: user.userId,
              userFollowee: userId
            })
          );
        } catch (err) {
          if (err.response.status === 400) {
            setError('You are already following this creator.');
          }
        }
      }
    } else {
      if (triggerAuthentication) {
        setShowModal(true);
      } else {
        setError('Please sign in to follow an artist.');
      }
    }
  };

  const handleAuthenticationSuccess = async () => {
    setShowModal(false);
    await dispatch(getUserFollowees());
  };

  const groupedCampaigns = [];
  if (campaigns) {
    for (let i = 0; i < campaigns.length; i++) {
      const currentCampaign = campaigns[i];
      const existingArtist = groupedCampaigns.find(
        (entry) => entry.userId === currentCampaign.user.userId
      );
      const existingEntry = user.following.list.find(
        (item) => item.userId === currentCampaign.user.userId
      );

      if (existingArtist) {
        existingArtist.campaignNames.push(currentCampaign.title);
      } else if (currentCampaign.user.userId !== user.userId) {
        groupedCampaigns.push({
          userId: currentCampaign.user.userId,
          profile: currentCampaign.profile,
          profileName: currentCampaign.user.profileName,
          campaignNames: [currentCampaign.title],
          isFollowing: !!existingEntry?.isFollowing
        });
      }
    }
  }

  const isCampaignPageVariant = kind === 'campaign';

  return (
    <>
      {error && (
        <Spacing size={2} position="b">
          <Alert message={error} kind="info" />
        </Spacing>
      )}

      {groupedCampaigns.length > 0 ? (
        <>
          <ModalNew isOpen={showModal} handleClose={() => setShowModal(false)}>
            <AuthenticationContainer
              initialUserStage="signUp"
              onSuccess={handleAuthenticationSuccess}
            />
          </ModalNew>
          {groupedCampaigns.map((campaign, index) => {
            const joinCampaignNames = cropString(
              campaign.campaignNames.join(', '),
              40
            );

            const { profile } = campaign;

            return (
              <Spacing
                key={campaign.userId}
                size={index > 0 ? 3 : 0}
                position="t"
              >
                <FollowArtist
                  campaignName={showCampaignName && joinCampaignNames}
                  creatorName={
                    isCampaignPageVariant
                      ? profile.name
                      : campaign.profileName
                  }
                  creatorInitials={
                    isCampaignPageVariant
                      ? getInitials(profile.name)
                      : campaign.profileName
                      ? getInitials(campaign.profileName)
                      : null
                  }
                  onButtonClick={() =>
                    handleFollowUser(campaign.isFollowing, campaign.userId)
                  }
                  isFollowing={campaign.isFollowing}
                  slug={campaign.profile?.slug || campaign.slug}
                />
              </Spacing>
            );
          })}
        </>
      ) : user.following.list.length > 0 ? (
        !campaigns &&
        user.following.list.map((creator, index) => {
          return (
            <TableRow
              key={creator.profileName}
              borderWidth={0}
              className={classNames('border-grey-lighter', {
                'border-b-1': index < user.following.list.length - 1
              })}
            >
              <TableItem columnSize={12} className="border-0">
                <FollowArtist
                  creatorName={creator.profileName}
                  creatorInitials={
                    creator.profileName
                      ? getInitials(creator.profileName)
                      : null
                  }
                  onButtonClick={() =>
                    handleFollowUser(creator.isFollowing, creator.userId)
                  }
                  isFollowing={creator.isFollowing}
                  slug={creator.slug}
                />
              </TableItem>
            </TableRow>
          );
        })
      ) : kind !== 'campaign' ? (
        <Spacing size={6} type="padding">
          <Paragraph size="s" center>
            You're not following anyone yet
          </Paragraph>
        </Spacing>
      ) : null}
    </>
  );
};

export default FollowArtistContainer;
