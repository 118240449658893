import produce from 'immer';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';

import config from '../config';
import * as persistentState from '../services/local-storage';

const initialState = {
  checkout: {
    visited: false,
    touched: false
  },
  shop: {
    visited: false,
    timestamp: null
  },
  home: {
    visited: false,
    timestamp: null
  },
  utmParams: {
    timestamp: null
  },
  origin: {
    source: config.salesChannels.everpress,
    campaign: {
      campaignId: null
    },
    creator: {
      userId: null,
      timestamp: null
    }
  }
};

export default (
  state = merge(
    initialState,
    persistentState.get(config.localStorageTrackingKey) || {}
  ),
  action
) =>
  produce(state, (newState) => {
    const { type, payload } = action;

    if (type === 'CHECKOUT') {
      newState.checkout.visited = true;
      return newState;
    }

    if (type === 'SHOP') {
      newState.shop.visited = true;
      newState.shop.timestamp = Date.now();

      return newState;
    }

    if (type === 'HOME') {
      newState.home.visited = true;
      newState.home.timestamp = Date.now();

      return newState;
    }

    if (
      type === 'EDIT_CHECKOUT_DETAILS_PENDING' ||
      type === 'GET_PAYPAL_CHECKOUT_DETAILS_FULFILLED'
    ) {
      newState.checkout.touched = true;
    }

    if (
      action.type === 'CHECKOUT_PAYPAL_FULFILLED' ||
      action.type === 'CHECKOUT_STRIPE_FULFILLED' ||
      action.type === 'CONFIRM_STRIPE_PAYMENT_INTENT_FULFILLED'
    ) {
      return initialState;
    }

    if (action.type === 'SET_UTM_PARAMS') {
      newState.utmParams = { ...state.utmParams, ...payload };

      if (!isEmpty(payload)) {
        newState.utmParams.timestamp = Date.now();
      }
    }

    if (action.type === 'SET_ORIGIN') {
      newState.origin = merge({}, state.origin, payload);

      if (!isEmpty(payload.creator)) {
        newState.origin.creator.timestamp = Date.now();
      }

      return newState;
    }
  });
