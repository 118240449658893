import React from 'react';

import Spacing from '../../../../components/atoms/Spacing';
import Link from '../../../../components/atoms/Link';
import Icon from '../../../../components/atoms/Icon';
import Heading from '../../../../components/atoms/Heading';

export default function SocialLink({ url, title, icon, width, height }) {
  return (
    <Spacing size={[1, 1, 0]} position="b">
      <Spacing size={[2, 2, 0]} position="r">
        <Spacing size={[0, 0, 2]} position="l">
          <Link to={url} isUnderlined={false}>
            <div className="flex items-center">
              <Spacing size={1} position="r">
                <Icon width={width} height={height} glyph={icon} />
              </Spacing>
              <Heading tag="div" size="xxs">
                {title}
              </Heading>
            </div>
          </Link>
        </Spacing>
      </Spacing>
    </Spacing>
  );
}
