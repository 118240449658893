import React from 'react';
import RouterLink from 'redux-first-router-link';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import Icon from '../../../components/atoms/Icon';

import generalStyles from './style/general.module.css';
import kindStyles from './style/kind.module.css';
import sizeStyles from './style/size.module.css';

const cx = classNames.bind({
  ...generalStyles,
  ...kindStyles,
  ...sizeStyles
});

const iconSizeMap = {
  tiny: 12,
  small: 18,
  normal: 24
};

const IconButton = ({
  glyph,
  kind,
  size,
  state,
  title,
  to,
  className,
  ...props
}) => {
  const CustomTag = to ? RouterLink : 'button';

  const buttonProps = {
    to,
    className: cx('iconButton', kind, size, state, className),
    'aria-label': title,
    title: CustomTag === 'button' ? title : undefined
  };

  const iconProps = {
    glyph,
    width: iconSizeMap[size],
    height: iconSizeMap[size]
  };

  return (
    <CustomTag
      onMouseDown={(e) => e.preventDefault()}
      {...buttonProps}
      {...props}
    >
      <Icon {...iconProps} />
    </CustomTag>
  );
};

IconButton.propTypes = {
  glyph: PropTypes.oneOfType([PropTypes.object, PropTypes.symbol]),
  kind: PropTypes.oneOf(Object.keys(kindStyles)),
  size: PropTypes.oneOf(Object.keys(sizeStyles)),
  state: PropTypes.oneOf(['loading', 'disabled']),
  title: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string
};

IconButton.defaultProps = {
  size: 'normal'
};

export default IconButton;
