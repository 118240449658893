import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { fontSizeMap } from '../../../helpers/themeMap';
import propToClassName from '../../../helpers/propToClassName';

import Icon from '../../../components/atoms/Icon';

import loadingIcon from '../../../img/sprites/loading.svg';

import generalStyles from './style/general.module.css';

export const Price = ({
  value,
  lang,
  currency,
  shouldConvert,
  size,
  className
}) => {
  const toLocaleValue = useMemo(() => {
    if ((value || value === 0) && currency) {
      const price = Array.isArray(value)
        ? value.length > 0
          ? value.find((price) => currency.iso === price.iso)?.price
          : undefined
        : parseFloat(value);

      if (!price && price !== 0) return null;

      return (shouldConvert ? price * currency.rate : price).toLocaleString(
        lang,
        {
          style: 'currency',
          currency: currency.iso
        }
      );
    }

    return null;
  }, [value, lang, currency, shouldConvert]);
  const sizeClassName = size ? propToClassName(size, fontSizeMap) : null;
  return (
    <span className={classNames(className, sizeClassName)}>
      {toLocaleValue || (
        <span className={generalStyles.placeholder}>
          <Icon glyph={loadingIcon} width={30} height={7} />
        </span>
      )}
    </span>
  );
};

const mapStateToProps = ({ user, currencies }, ownProps) => {
  return {
    currency: ownProps.currency
      ? currencies.find((currency) => currency.iso === ownProps.currency)
      : currencies.find((currency) => currency.iso === user.currency) ||
        currencies.find((currency) => currency.reference)
  };
};

Price.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string
  ]),
  lang: PropTypes.string,
  currency: PropTypes.object,
  shouldConvert: PropTypes.bool,
  size: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(Object.keys(fontSizeMap))),
    PropTypes.oneOf(Object.keys(fontSizeMap))
  ]),
  className: PropTypes.string
};

Price.defaultProps = {
  lang: 'en'
};

export default connect(mapStateToProps)(Price);
