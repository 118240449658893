import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import urlRegex from '../../../../helpers/urlRegex';

import { ButtonNew as Button } from '../../../../components/atoms/Button';
import { CSSGrid } from '../../../../components/atoms/Grid';
import Heading from '../../../../components/atoms/Heading';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';

import generalStyles from './style/general.module.css';

const cx = classNames.bind({
  ...generalStyles
});

const Hero = ({
  image,
  imagePosition,
  title,
  copy,
  buttonLabel,
  buttonSlug
}) => {
  const isExternalButtonSlug =
    typeof buttonSlug === 'string' &&
    (buttonSlug.match(urlRegex) ||
      ['/create', '/blog'].some((substr) => buttonSlug.includes(substr)));

  return (
    <div
      className={cx('container')}
      style={{
        backgroundImage: `url(${image?.url})`,
        backgroundPosition: imagePosition,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <CSSGrid
        className="w-full h-full"
        template={['repeat(2, auto) / 1fr', '1fr / 1fr auto']}
        align="end"
        alignContent="end"
        rowGap={2}
        columnGap={7}
      >
        <div className={cx('text')}>
          <Heading size={['l', 'l', 'l', 'xl']} tag="h1" color="white">
            {title}
          </Heading>
          <Spacing size={1} position="t">
            <Paragraph size="xs" color="white">
              {copy}
            </Paragraph>
          </Spacing>
        </div>
        {buttonSlug && (
          <div className={cx('cta')}>
            <Button
              color="white"
              to={buttonSlug}
              isExternal={isExternalButtonSlug}
            >
              {buttonLabel}
            </Button>
          </div>
        )}
      </CSSGrid>
    </div>
  );
};

Hero.defaultProps = {
  imagePosition: 'center center'
};

Hero.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string.isRequired
  }),
  imagePosition: PropTypes.oneOf(['top', 'center', 'bottom']),
  title: PropTypes.string.isRequired,
  copy: PropTypes.oneOfType(PropTypes.array, PropTypes.string),
  buttonLabel: PropTypes.string.isRequired,
  buttonSlug: PropTypes.string.isRequired
};

export default Hero;
