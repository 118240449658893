import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback
} from 'react';
import { flushSync } from 'react-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import Moveable from 'react-moveable';
import { RemoveScroll } from 'react-remove-scroll';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames/bind';
import * as Sentry from '@sentry/react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import debounce from 'lodash/debounce';

import {
  createCampaign,
  getCampaignProducts,
  uploadArtwork,
  updateArtwork,
  deleteCampaignProduct,
  updateCampaignProduct,
  createArtworkPositions,
  updateArtworkPositions,
  deleteArtworkPosition
} from '../../../actions/campaign-builder';

import {
  sortColours,
  concatColourToEnd,
  getInitialColours,
  getMaxAreaFromProducts,
  getImageMeta
} from '../../../helpers/campaignBuilder';

import {
  scaleToDPI,
  getImageDimensionsFromDataURL,
  calculateAspectRatioFit
} from '../../../helpers/artwork';

import hexToRgb from '../../../helpers/hexToRgb';
import getAdaptiveColour from '../../../helpers/getAdaptiveColour';
import useTouchDetect from '../../../helpers/hooks/useTouchDetect';

import Alert from '../../../components/atoms/Alert';
import { ButtonNew as Button } from '../../../components/atoms/Button';
import Heading from '../../../components/atoms/Heading';
import Icon from '../../../components/atoms/Icon';
import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';
import Image from '../../../components/atoms/Image';
import ColourCircle from '../../../components/atoms/ColourCircle';
import IconButton from '../../../components/atoms/IconButton';
import { TooltipNew as Tooltip } from '../../../components/atoms/Tooltip';

import AuthenticationContainer from '../../../components/molecules/AuthenticationContainer';
import { ModalNew as Modal } from '../../../components/molecules/Modal';
import { Form, Field } from '../../../components/atoms/Form';

import Loading from '../../../components/utils/Loading';

import generalStyles from './style/general.module.css';

import { boxVariants } from './variants';

import ColourDetectModal from './Modals/ColourDetectModal';

import Catalogue from '../Catalogue';
import Guide from './Guide';

import dropzoneIcon from '../../../img/sprites/dropzone.svg';
import arrowLeftRightIcon from '../../../img/sprites/arrow-left-right-line.svg';
import binIcon from '../../../img/sprites/bin-alt.svg';
import checkIcon from '../../../img/sprites/check.svg';
import crossIcon from '../../../img/sprites/cross.svg';
// import warningIcon from '../../../img/sprites/warning-circle.svg';

// import { theme } from '../../../theme-config';

import config from '../../../config';

const FRONT_AREA_SLUG = 'front';
const BACK_AREA_SLUG = 'back';

const MAX_FILE_SIZE = 25000000; // 25MB

const DEFAULT_COLOUR_CODE = 'ffffff';

const PLATE_TO_BOX_RATIO = config.campaignBuilder.plateToBoxRatio;

function fileSizeValidator(file) {
  if (file.size > MAX_FILE_SIZE) {
    return {
      code: 'size-too-large',
      message: `File size is larger than 25MB`
    };
  }

  return null;
}

function parseTransform(transform) {
  return Array.from(transform.matchAll(/(\w+)\((.+?)\)/gm)).reduce(
    (agg, [, fn, val]) => ({
      ...agg,
      [fn]: val
    }),
    {}
  );
}

const ArtworkUploader = (props) => {
  const [activeAreaType, setActiveAreaType] = useState(FRONT_AREA_SLUG);
  const [maxAreaFront, setMaxAreaFront] = useState(null);
  const [maxAreaBack, setMaxAreaBack] = useState(null);
  const [artworkPosition, setArtworkPosition] = useState({
    x: 0,
    y: 0,
    area: null
  });
  const [artworkSize, setArtworkSize] = useState({
    width: 0,
    height: 0,
    area: null
  });
  const [maxArtworkSize, setMaxArtworkSize] = useState({
    width: 0,
    height: 0,
    area: null
  });
  const [files, setFiles] = useState([]);
  const [tempBgColour, setTempBgColour] = useState(null);
  const [isDndAreaOutlineVisible, setDndAreaOutlineVisible] = useState(true);
  const [isUpdatingArtwork, setUpdatingArtwork] = useState(false);
  const [isDeletingArtwork, setDeletingArtwork] = useState(false);
  const [catalogueOpen, setCatalogueOpen] = useState(false);
  const [artworkError, setArtworkError] = useState(null);
  const [coloursError, setColoursError] = useState(null);
  const [productsError, setProductsError] = useState(null);
  const [colourDetectModalOpen, setColourDetectModalOpen] = useState(false);
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [guideOpen, setGuideOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    status,
    campaignId,
    campaignProducts,
    catalogueProducts,
    featuredProduct,
    artwork,
    artworkPositions
  } = useSelector((state) => state.campaignBuilder, shallowEqual);

  const isAuthenticated = useSelector((state) => !!state.user.token);
  const { isTouch } = useTouchDetect();

  const thumbRef = useRef(null);
  const moveableRef = useRef(null);

  const selectedCampaignProduct = campaignProducts.find(
    (product) => product.featured
  );
  const selectedCatalogueProduct = catalogueProducts.find(
    (product) =>
      product.productId === selectedCampaignProduct?.product?.productId
  );
  const selectedArtworkPosition = artworkPositions.find(
    (pos) => pos.area === activeAreaType
  );

  useEffect(() => {
    if (moveableRef.current) {
      if (thumbRef.current) {
        const currTransform = parseTransform(thumbRef.current.style.transform);
        thumbRef.current.style.transform = `translate(${currTransform.translate})`;
      }

      if (!selectedArtworkPosition) {
        moveableRef.current.request('draggable', {
          isInstant: true,
          // useSnap: true,
          x: 0,
          y: 0
        });
      }

      moveableRef.current.updateRect();
      moveableRef.current.updateTarget();
    }
  }, [activeAreaType, selectedArtworkPosition, moveableRef, thumbRef]);

  useEffect(() => {
    if (selectedArtworkPosition) {
      const { width, height, x, y, area } = selectedArtworkPosition;

      setArtworkPosition({ x, y, area });
      setArtworkSize({ width, height, area });
    }
  }, [selectedArtworkPosition]);

  const handleArtworkDrop = async (files) => {
    setArtworkError(null);

    const file = files[0];
    const url = URL.createObjectURL(file);

    // Get original dimensions from file
    const dimensions = await getImageDimensionsFromDataURL(url);

    // Check if area is limited
    const targetArea = activeMaxArea ?? activeArea;

    // Scale artwork to maximum allowed size
    const { maxWidth, maxHeight } = getMaxArtworkSize(dimensions, targetArea);

    flushSync(() =>
      setMaxArtworkSize({
        width: maxWidth,
        height: maxHeight,
        area: activeAreaType
      })
    );

    // Proceed to render the artwork
    // Force a state flush as moveableRef depends on it (React 16 > 18)
    flushSync(() =>
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          file,
          area: activeAreaType,
          preview: URL.createObjectURL(file),
          dimensions
        }
      ])
    );

    // Centre the artwork - overflow is handled by Moveable
    moveableRef.current.request('draggable', {
      isInstant: true,
      // useSnap: true,
      x: targetArea.width / 2 - maxWidth / 2,
      y: targetArea.height / 2 - maxHeight / 2
    });

    setDndAreaOutlineVisible(true);
  };

  const handleArtworkDropError = (err) => {
    const firstErr =
      err[0]?.errors[0]?.message || 'Artwork file not supported.';

    setArtworkError(firstErr);
  };

  const handleArtworkLoad = async (e) => {
    const dimensions = {
      width: e.currentTarget.naturalWidth,
      height: e.currentTarget.naturalHeight
    };

    // TODO: handleArtworkLoad fires before activeMaxArea is set, causing unnecessary re-render
    const targetArea = activeMaxArea ?? activeArea;

    const { maxWidth, maxHeight } = getMaxArtworkSize(dimensions, targetArea);

    flushSync(() =>
      setMaxArtworkSize({
        width: maxWidth,
        height: maxHeight,
        area: activeAreaType
      })
    );

    flushSync(() =>
      setArtworkSize({
        width: selectedArtworkPosition?.width || maxWidth,
        height: selectedArtworkPosition?.height || maxHeight,
        area: activeAreaType
      })
    );
  };

  const handleAreaChange = () => {
    setActiveAreaType(
      activeAreaType === FRONT_AREA_SLUG ? BACK_AREA_SLUG : FRONT_AREA_SLUG
    );
  };

  const handleArtworkDrag = (e) => {
    const { translate } = e;

    e.target.style.transform = e.transform;

    setArtworkPosition({
      x: Math.round(translate[0]),
      y: Math.round(translate[1]),
      area: activeAreaType
    });
  };

  const handleArtworkResize = (e) => {
    const { width, height, boundingWidth, boundingHeight, dist, drag } = e;

    // Moveable bug causes width to shrink by 1px when trying to scale UP (if maxArtworkSize is set)
    if (dist[0] === -1 && dist[1] === 0) return;

    const clippedWidth = Math.floor(Math.min(width, activeArea.width));
    const clippedHeight = Math.floor(Math.min(height, activeArea.height));

    e.target.style.cssText +=
      `width: ${boundingWidth}px;` +
      `height: ${boundingHeight}px;` +
      `transform: ${drag.transform}`;

    setArtworkSize({
      width: clippedWidth,
      height: clippedHeight,
      area: activeAreaType
    });

    setArtworkPosition({
      x: Math.round(drag.translate[0]),
      y: Math.round(drag.translate[1]),
      area: activeAreaType
    });
  };

  const handleArtworkSave = async (e) => {
    e?.stopPropagation();

    setArtworkError(null);

    if (!isAuthenticated) return setAuthModalOpen(true);

    setUpdatingArtwork(true);

    if (!campaignId) {
      await dispatch(createCampaign());
      await dispatch(getCampaignProducts());
    }

    const positions = {
      area: activeAreaType,
      width: artworkSize.width,
      height: artworkSize.height,
      x: artworkPosition.x,
      y: artworkPosition.y
    };

    if (isTouched) {
      try {
        await dispatch(
          updateArtworkPositions({
            ...selectedArtworkPosition,
            ...positions
          })
        );
      } catch (err) {
        setArtworkError(
          err?.message || 'Unable to update artwork, please try again later.'
        );

        Sentry.captureException(err);
      } finally {
        setUpdatingArtwork(false);
      }
    } else {
      const data = new FormData();
      const { name, type, file } = targetFile;

      data.append('files[]', file, name, type);

      try {
        setUpdatingArtwork(true);

        await dispatch(uploadArtwork(data));
        await dispatch(createArtworkPositions(positions));

        // Fire tracking event after both artwork and default print positions have been created successfully
        dispatch({
          type: 'BUILDER_ARTWORK_UPLOADED',
          payload: { campaignId }
        });

        setColourDetectModalOpen(true);
      } catch (err) {
        setArtworkError(
          err?.message || 'Failed to upload artwork, please try again later.'
        );

        Sentry.captureException(err);
      } finally {
        setUpdatingArtwork(false);
      }
    }
  };

  const handleArtworkColoursSave = async ({ colours }) => {
    try {
      dispatch(updateArtwork({ ...artwork, colours }));
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setColourDetectModalOpen(false);
    }
  };

  const handleArtworkDelete = async (e) => {
    e?.stopPropagation();

    setArtworkError(null);

    if (selectedArtworkPosition) {
      try {
        setDeletingArtwork(true);

        const { campaignArtworkPositionId } = selectedArtworkPosition;

        await dispatch(deleteArtworkPosition({ campaignArtworkPositionId }));
      } catch (err) {
        setArtworkError(
          err?.message || 'Unable to delete artwork, please try again later.'
        );

        Sentry.captureException(err);
      } finally {
        setDeletingArtwork(false);
      }
    }

    setFiles((prevFiles) =>
      prevFiles.filter((file) => file.area !== activeAreaType)
    );
  };

  const handleProductDelete = (campaignProductId) => {
    setProductsError(null);

    try {
      dispatch(deleteCampaignProduct(campaignProductId));
    } catch (err) {
      setProductsError(
        err?.message || 'Unable to delete product, please try again later.'
      );

      Sentry.captureException(err);
    }
  };

  const handleFeaturedProductChange = (product) => {
    setProductsError(null);

    try {
      dispatch(updateCampaignProduct({ ...product, featured: true }));
    } catch (err) {
      setProductsError(
        err?.message || 'Unable to update products, please try again later.'
      );

      Sentry.captureException(err);
    }
  };

  const handleProductColourSelect = useCallback(
    (values, { initialValues }) => {
      if (!isEqual(values, initialValues)) {
        setColoursError(null);

        const colourCodes = Object.keys(pickBy(values.colours));
        const prevColourCodes = Object.keys(pickBy(initialValues.colours));

        const colours = colourCodes.map((code) => {
          return selectedCatalogueProduct.colours.find(
            (colour) => colour.code === code
          );
        });

        const sortedColours = sortColours(colours);

        const newFeaturedColour =
          colourCodes.length < prevColourCodes.length
            ? colours.find(
                (colour) =>
                  colour.colourId ===
                  selectedCampaignProduct.colourFeatured.colourId
              )
              ? undefined
              : sortedColours[0]
            : undefined;

        try {
          dispatch(
            updateCampaignProduct({
              ...selectedCampaignProduct,
              ...(newFeaturedColour && { colourFeatured: newFeaturedColour }),
              colours: sortedColours
            })
          );
        } catch (err) {
          setColoursError(
            err?.message || 'Unable to update colours, please try again later.'
          );

          Sentry.captureException(err);
        }
      }
    },
    [selectedCatalogueProduct, selectedCampaignProduct, dispatch]
  );

  const debouncedProductColourSelect = useMemo(
    () => debounce(handleProductColourSelect, 1000),
    [handleProductColourSelect]
  );

  const handleProductFeaturedColourSelect = useCallback(
    (values, { initialValues }) => {
      if (!isEqual(values, initialValues)) {
        setColoursError(null);

        const { featuredColour: featuredColourCode } = values;

        const featuredColour = selectedCampaignProduct.colours.find(
          (colour) => colour.code === featuredColourCode
        );

        try {
          dispatch(
            updateCampaignProduct({
              ...selectedCampaignProduct,
              colourFeatured: featuredColour
            })
          );
        } catch (err) {
          setColoursError(
            err?.message || 'Unable to update colours, please try again later.'
          );

          Sentry.captureException(err);
        }
      }
    },
    [selectedCampaignProduct, dispatch]
  );

  const debouncedProductFeaturedColourSelect = useMemo(
    () => debounce(handleProductFeaturedColourSelect, 1000),
    [handleProductFeaturedColourSelect]
  );

  const handleAuthSuccess = () => {
    setAuthModalOpen(false);

    return handleArtworkSave();
  };

  const handleCatalogueOpen = async () => {
    setCatalogueOpen(true);

    dispatch({ type: 'BUILDER_CATALOGUE_OPENED' });
  };

  const selectedCampaignProductColours = selectedCampaignProduct
    ? sortColours(selectedCampaignProduct.colours)
    : [];
  const availableColours = selectedCampaignProduct
    ? sortColours(selectedCatalogueProduct.colours)
    : [];
  const initialColours = selectedCampaignProduct
    ? getInitialColours(selectedCatalogueProduct, selectedCampaignProduct)
    : {};
  const initialFeaturedColour = selectedCampaignProduct?.colourFeatured?.code;

  const targetFile = files.find((file) => file.area === activeAreaType);

  const { areas = [] } = selectedCampaignProduct?.product || featuredProduct;

  // const activeArea = areas.find((area) => area.slug === activeAreaType) || {};
  const activeArea = useMemo(() => {
    return areas.find((area) => area.slug === activeAreaType) || {};
  }, [areas, activeAreaType]);

  const activeMaxArea =
    activeAreaType === FRONT_AREA_SLUG ? maxAreaFront : maxAreaBack;
  const isLimitedArea =
    activeMaxArea && activeArea.height > activeMaxArea.height;

  const isTouched = selectedArtworkPosition
    ? !isEqual(artworkPosition, {
        x: selectedArtworkPosition.x,
        y: selectedArtworkPosition.y,
        area: selectedArtworkPosition.area
      }) ||
      !isEqual(artworkSize, {
        width: selectedArtworkPosition.width,
        height: selectedArtworkPosition.height,
        area: selectedArtworkPosition.area
      })
    : false;
  const isArtworkPresent =
    targetFile?.preview || selectedArtworkPosition?.artwork;
  const isDropDisabled = targetFile || selectedArtworkPosition;

  const isLoading = status === 'pending';
  const isDeleting = status === 'deleting';
  const isUpdating = status === 'updating';
  const isUploading = status === 'uploading';

  const adaptiveColour = getAdaptiveColour(
    hexToRgb(`#${tempBgColour || initialFeaturedColour || DEFAULT_COLOUR_CODE}`)
  );

  const getMaxArtworkSize = useCallback((dimensions, targetArea) => {
    if (!dimensions || !targetArea) return;

    // Scale image to 300DPI
    let scaledImage = scaleToDPI({ ...dimensions }, 300);

    // Scale image proportionally to the print area, using ratio between print plate and print area at 72DPI
    scaledImage.width = scaledImage.width * PLATE_TO_BOX_RATIO;
    scaledImage.height = scaledImage.height * PLATE_TO_BOX_RATIO;

    // Fit scaled image to print area, maintain aspect ratio
    const scaledToFitImage = calculateAspectRatioFit(
      scaledImage.width,
      scaledImage.height,
      targetArea.width,
      targetArea.height
    );

    const maxWidth = Math.floor(
      Math.min(scaledToFitImage.width, scaledImage.width)
    );
    const maxHeight = Math.floor(
      Math.min(scaledToFitImage.height, scaledImage.height)
    );

    return { maxWidth, maxHeight };
  }, []);

  useEffect(() => {
    // Products are still being fetched, do nothing
    if (isEmpty(campaignProducts)) return;

    let targetProducts = [...campaignProducts];

    const maxAreaFront = getMaxAreaFromProducts(
      targetProducts,
      FRONT_AREA_SLUG
    );
    const maxAreaBack = getMaxAreaFromProducts(targetProducts, BACK_AREA_SLUG);

    setMaxAreaFront(maxAreaFront);
    setMaxAreaBack(maxAreaBack);

    const maxAreaMap = {
      [FRONT_AREA_SLUG]: maxAreaFront,
      [BACK_AREA_SLUG]: maxAreaBack
    };

    const activeMaxArea = maxAreaMap[activeAreaType];

    if (thumbRef.current) {
      const { naturalWidth: width, naturalHeight: height } = thumbRef.current;

      const {
        maxWidth: activeMaxWidth,
        maxHeight: activeMaxHeight
      } = getMaxArtworkSize({ width, height }, activeMaxArea);

      setMaxArtworkSize({
        width: activeMaxWidth,
        height: activeMaxHeight,
        area: activeAreaType
      });

      if (!isEmpty(artworkPositions)) {
        // Check for overflow, both front and back
        const positionsWithOverflow = artworkPositions.filter((pos) => {
          // Width is static across positions so we only check height
          return pos.height + pos.y > maxAreaMap[pos.area].height;
        });

        // No overflow, do nothing
        if (isEmpty(positionsWithOverflow)) return;

        try {
          // eslint-disable-next-line
          positionsWithOverflow.map((pos) => {
            const { area, artwork } = pos;

            (async () => {
              // Get original dimensions from file
              const img = await getImageMeta(artwork.url);

              const { naturalWidth: width, naturalHeight: height } = img;

              const { maxWidth, maxHeight } = getMaxArtworkSize(
                { width, height },
                maxAreaMap[area]
              );

              // Drag artwork to the correct pos for the _active_ area, so it doesn't move around when the req is resolved
              moveableRef.current.request('draggable', {
                isInstant: true,
                x: Math.floor(
                  maxAreaMap[activeAreaType].width / 2 - activeMaxWidth / 2
                ),
                y: 0
              });

              thumbRef.current.style.width = activeMaxWidth;
              thumbRef.current.style.height = activeMaxHeight;

              // Snap artwork to max allowed dimensions for its area
              await dispatch(
                updateArtworkPositions({
                  ...pos,
                  x: Math.floor(maxAreaMap[area].width / 2 - maxWidth / 2),
                  y: 0,
                  width: maxWidth,
                  height: maxHeight
                })
              );
            })();
          });
        } catch {
          // Do something
        }
      }
    }
  }, [
    campaignProducts,
    featuredProduct,
    activeAreaType,
    selectedArtworkPosition,
    artworkPositions,
    getMaxArtworkSize,
    dispatch
  ]);

  const { getRootProps, getInputProps, open: openFileDialog } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    maxFiles: 1,
    multiple: false,
    noClick: true,
    onDropAccepted: handleArtworkDrop,
    onDropRejected: handleArtworkDropError,
    onError: handleArtworkDropError,
    validator: fileSizeValidator,
    disabled: isDropDisabled
  });

  const dndAreaProps = {
    style: {
      width: activeMaxArea ? activeMaxArea.width : activeArea.width,
      height: activeMaxArea ? activeMaxArea.height : activeArea.height,
      top: activeArea.posY,
      left: activeArea.posX,
      color: adaptiveColour,
      outlineColor:
        isDndAreaOutlineVisible || !isArtworkPresent
          ? adaptiveColour
          : 'transparent',
      transition: 'color .15s linear, outline-color .15s linear'
    }
  };

  const dndControlsProps = {
    style: {
      opacity: isDndAreaOutlineVisible ? 1 : 0,
      transition: 'opacity .15s linear'
    }
  };

  const maxAreaProps = {
    style: {
      width: activeMaxArea?.width,
      height: activeArea.height - activeMaxArea?.height,
      top: activeArea.posY + activeMaxArea?.height,
      left: activeArea.posX,
      opacity: isDndAreaOutlineVisible || !isArtworkPresent ? 0.4 : 0,
      transition: 'opacity .15s linear'
    }
  };

  const isMaxArtworkSizeReached = isArtworkPresent
    ? artworkSize.width * artworkSize.height >=
      (maxArtworkSize.width * maxArtworkSize.height || 1)
    : false;

  if (isLoading && !catalogueOpen) return <Loading />;

  return (
    <>
      {/* Modals */}
      {catalogueOpen ? (
        <div className={generalStyles.catalogueContainer}>
          <Catalogue
            products={catalogueProducts}
            onSave={() => setCatalogueOpen((state) => !state)}
          />
        </div>
      ) : null}
      <Modal isOpen={authModalOpen}>
        <AuthenticationContainer
          initialUserStage="signUp"
          onSuccess={handleAuthSuccess}
          isBuilder={true}
        />
      </Modal>
      <ColourDetectModal
        artwork={artwork}
        isOpen={colourDetectModalOpen && isAuthenticated}
        onSubmit={handleArtworkColoursSave}
      />

      {/* Guide */}
      <RemoveScroll enabled={guideOpen}>
        <AnimatePresence exitBeforeEnter>
          {guideOpen && <Guide onClose={() => setGuideOpen(false)} />}
        </AnimatePresence>
      </RemoveScroll>

      <div className={generalStyles.container}>
        {/* Garment colours */}
        {campaignId && !isEmpty(campaignProducts) ? (
          <aside className={generalStyles.selectedColours}>
            <Heading size="s" className="leading-normal">
              Select product colours
            </Heading>
            <Spacing size="05" position="t">
              <Paragraph size="xxs">
                Hover to preview, then click to confirm/remove a selection.
              </Paragraph>
            </Spacing>
            <Spacing size={2} position="t">
              {!isEmpty(availableColours) && (
                <div className="inline-flex flex-wrap items-center gap-2">
                  <Form
                    enableReinitialize
                    initialValues={{ colours: initialColours }}
                    onSubmit={() => null}
                    onChange={debouncedProductColourSelect}
                    render={({ handleSubmit, values }) => {
                      const selectedColours = Object.keys(
                        pickBy(values.colours)
                      );

                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="inline-flex flex-wrap items-center gap-2">
                            {availableColours.map((colour) => {
                              const isDisabled =
                                !selectedColours[1] &&
                                selectedColours[0] === colour.code;

                              return (
                                <Tooltip
                                  id="tooltip-colour"
                                  hasListContent
                                  content={colour.name}
                                  placement="bottom"
                                  offset={5}
                                  align="start"
                                  alignOffset={-10}
                                  key={colour.colourId}
                                >
                                  <div
                                    style={{ width: '30px' }}
                                    onMouseEnter={() =>
                                      setTempBgColour(colour.code)
                                    }
                                    onMouseLeave={() => setTempBgColour(null)}
                                  >
                                    <Field
                                      type="colourCircleCheckbox"
                                      name={`colours.${colour.code}`}
                                      hex={`#${colour.code}`}
                                      size="l"
                                      disabled={isDisabled}
                                    />
                                  </div>
                                </Tooltip>
                              );
                            })}
                          </div>
                        </form>
                      );
                    }}
                  />
                </div>
              )}
            </Spacing>

            <AnimatePresence exitBeforeEnter>
              {selectedCampaignProductColours.length > 1 && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={boxVariants}
                >
                  <Spacing size={4}>
                    <Heading size="s" className="leading-normal">
                      Select featured colour
                    </Heading>
                    <Spacing size="05" position="t">
                      <Paragraph size="xxs">
                        The default colour to be displayed for this product
                      </Paragraph>
                    </Spacing>
                    <Spacing size={2} position="t">
                      {!isEmpty(selectedCampaignProductColours) && (
                        <div className="inline-flex flex-wrap items-center gap-2">
                          <Form
                            enableReinitialize
                            initialValues={{
                              featuredColour: initialFeaturedColour
                            }}
                            onSubmit={() => null}
                            onChange={debouncedProductFeaturedColourSelect}
                            render={({ handleSubmit }) => (
                              <form onSubmit={handleSubmit}>
                                <div
                                  role="group"
                                  className="inline-flex flex-wrap items-center gap-2"
                                >
                                  {selectedCampaignProductColours.map(
                                    (colour) => {
                                      return (
                                        <Tooltip
                                          id="tooltip-colour"
                                          hasListContent
                                          content={colour.name}
                                          placement="bottom"
                                          offset={5}
                                          align="start"
                                          alignOffset={-10}
                                          key={colour.colourId}
                                        >
                                          <div
                                            style={{ width: '30px' }}
                                            onMouseEnter={() =>
                                              setTempBgColour(colour.code)
                                            }
                                            onMouseLeave={() =>
                                              setTempBgColour(null)
                                            }
                                          >
                                            <Field
                                              type="colourCircleRadio"
                                              id={colour.code}
                                              name="featuredColour"
                                              value={colour.code}
                                              hex={`#${colour.code}`}
                                              size="l"
                                            />
                                          </div>
                                        </Tooltip>
                                      );
                                    }
                                  )}
                                </div>
                              </form>
                            )}
                          />
                        </div>
                      )}
                    </Spacing>
                  </Spacing>
                </motion.div>
              )}
            </AnimatePresence>

            {coloursError && (
              <Spacing size={15} position="t">
                <Alert kind="error">{coloursError}</Alert>
              </Spacing>
            )}
          </aside>
        ) : (
          <div />
        )}

        {/* Uploader */}
        <div className={generalStyles.uploaderContainer}>
          <div className={generalStyles.dndContainer}>
            {activeArea && (
              <>
                <img
                  className={generalStyles.mockup}
                  src={activeArea.whiteUrl}
                  alt="mockup"
                  width={500}
                  height={500}
                  style={{
                    backgroundColor: `#${tempBgColour ||
                      initialFeaturedColour}`,
                    transition: 'background-color .15s linear'
                  }}
                />
                {isLimitedArea && (
                  <div {...maxAreaProps} className={generalStyles.exclArea} />
                )}
                <div
                  {...getRootProps()}
                  {...dndAreaProps}
                  role="button"
                  className={generalStyles.dndArea}
                  onMouseEnter={() => setDndAreaOutlineVisible(true)}
                  onMouseLeave={() =>
                    !isTouch &&
                    !isUploading &&
                    !isUpdating &&
                    setDndAreaOutlineVisible(false)
                  }
                  onClick={isDropDisabled ? undefined : openFileDialog}
                >
                  {isArtworkPresent ? (
                    <>
                      <div className={generalStyles.dndThumb}>
                        <img
                          ref={thumbRef}
                          src={
                            targetFile?.preview ||
                            selectedArtworkPosition?.artwork?.url
                          }
                          alt="Preview"
                          onLoad={handleArtworkLoad}
                          style={
                            selectedArtworkPosition
                              ? {
                                  width: selectedArtworkPosition.width,
                                  height: selectedArtworkPosition.height,
                                  transform: `translate(${selectedArtworkPosition.x}px, ${selectedArtworkPosition.y}px)`
                                }
                              : {
                                  width: maxArtworkSize.width,
                                  height: maxArtworkSize.height
                                }
                          }
                        />

                        <Moveable
                          flushSync={flushSync} // Prevent UI sync weirdness in React 18+
                          ref={moveableRef}
                          target={thumbRef}
                          draggable={true}
                          resizable={true}
                          snappable={true}
                          keepRatio={true}
                          throttleDrag={1}
                          throttleScale={1}
                          edgeDraggable={false}
                          stopPropagation={true}
                          renderDirections={['nw', 'ne', 'se', 'sw']}
                          onDrag={handleArtworkDrag}
                          onResize={handleArtworkResize}
                          onResizeStart={(e) => {
                            e.setMin([10, 10]);
                            e.setMax([
                              maxArtworkSize.width,
                              maxArtworkSize.height
                            ]);
                          }}
                          bounds={{
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            position: 'css'
                          }}
                          preventRightClick={true}
                        />
                      </div>
                      <div
                        className={generalStyles.dndControls}
                        {...dndControlsProps}
                      >
                        {(isTouched || !selectedArtworkPosition) && (
                          <Button
                            onClick={handleArtworkSave}
                            kind="solid"
                            size="tiny"
                            color="white"
                            state={
                              isUpdatingArtwork || authModalOpen
                                ? 'loading'
                                : 'default'
                            }
                          >
                            {!isUpdatingArtwork && (
                              <Icon
                                className="mr-05"
                                glyph={checkIcon}
                                width={16}
                                height={16}
                              />
                            )}
                            Save
                          </Button>
                        )}
                        <Button
                          onClick={handleArtworkDelete}
                          kind="solid"
                          size="tiny"
                          color="red"
                          state={isDeletingArtwork ? 'loading' : 'default'}
                        >
                          <Icon
                            className="mr-05"
                            glyph={binIcon}
                            width={13}
                            height={13}
                          />
                          Delete
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={generalStyles.dndContent}>
                        <div>
                          <Spacing size="05" position="t">
                            <Heading tag="h4" size="xxs" center>
                              Upload your design
                            </Heading>
                          </Spacing>
                          <Spacing size="05" position="t">
                            <Heading
                              className="leading-relaxed"
                              tag="p"
                              size="xxxs"
                              center
                            >
                              Drag and drop it here
                            </Heading>
                          </Spacing>
                        </div>
                        <Spacing size={1} position="y">
                          <Icon glyph={dropzoneIcon} width={70} height={70} />
                        </Spacing>
                        <Paragraph
                          className="align-self-end"
                          size="xxxs"
                          center
                        >
                          Recommended: <br /> PNG format, 300 DPI
                        </Paragraph>
                      </div>
                      <input {...getInputProps()} className="hidden" />
                    </>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="-mt-1 flex flex-col items-center gap-8">
            <div className="flex flex-col items-center gap-4">
              {artworkError && <Alert kind="error">{artworkError}</Alert>}

              <AnimatePresence exitBeforeEnter>
                {isMaxArtworkSizeReached && (
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={{
                      ...boxVariants,
                      hidden: {
                        opacity: 0,
                        transition: {
                          duration: 0
                        }
                      }
                    }}
                  >
                    <Alert kind="info">
                      This is the max print size for your artwork file.
                    </Alert>
                  </motion.div>
                )}
              </AnimatePresence>

              {isLimitedArea && (
                <Alert kind="info">
                  The print area is restricted based on other garments you have
                  selected.
                </Alert>
              )}
            </div>

            {areas[1] && (
              <Button size="small" onClick={handleAreaChange}>
                <span className="mr-05">
                  <Icon glyph={arrowLeftRightIcon} width={13} height={13} />
                </span>
                {activeAreaType === FRONT_AREA_SLUG ? 'See back' : 'See front'}
              </Button>
            )}
          </div>

          <Button kind="outline" onClick={() => setGuideOpen(true)}>
            Artwork guidelines
          </Button>
        </div>

        {/* Garments */}
        {campaignId && !isEmpty(campaignProducts) ? (
          <aside className={generalStyles.selectedGarments}>
            <Heading size="s" className="leading-normal">
              Select products
            </Heading>
            <Spacing size={2} position="t">
              {campaignProducts.map((product, index) => {
                const { product: productData, ...campaignProduct } = product;

                const activeBgColour =
                  campaignProduct.colourFeatured?.code || DEFAULT_COLOUR_CODE;

                const sortedColours = sortColours(campaignProduct.colours);
                const sortedConcatColours = concatColourToEnd(
                  sortedColours,
                  campaignProduct.colourFeatured
                );

                const isFeatured = product.featured;

                return (
                  <div
                    className={classNames(generalStyles.selectedGarment, {
                      [generalStyles.featuredGarment]: isFeatured
                    })}
                    key={campaignProduct.campaignProductId}
                    role={isFeatured ? undefined : 'button'}
                    onClick={
                      isFeatured
                        ? undefined
                        : () => handleFeaturedProductChange(product)
                    }
                  >
                    <span className="p-05">
                      <Image
                        style={{
                          width: '64px',
                          height: '64px',
                          backgroundColor: `#${activeBgColour}`
                        }}
                        src={productData.areas[0].whiteUrl}
                        alt={productData.name}
                        width="64"
                        height="64"
                        placeholderColor={DEFAULT_COLOUR_CODE}
                      />
                    </span>
                    <div>
                      <Heading size="xxs" tag="p">
                        {productData.brandName}
                      </Heading>
                      <Paragraph size="xxs" className="leading-loose">
                        {productData.name}
                      </Paragraph>
                      <div style={{ marginTop: '2px' }}>
                        <div
                          className="inline-flex flex-wrap items-center gap-2"
                          style={{ height: 'fit-content' }}
                        >
                          {sortedConcatColours.map((colour) => {
                            return (
                              <div key={colour.colourId}>
                                <ColourCircle code={colour.code} size="m" />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {campaignProducts.length > 1 && !isFeatured && (
                        <span
                          className={
                            isTouch
                              ? generalStyles.deleteButtonTouch
                              : generalStyles.deleteButton
                          }
                        >
                          <IconButton
                            glyph={crossIcon}
                            size="tiny"
                            kind="secondary"
                            title="Remove"
                            onClick={(e) => {
                              e.stopPropagation();

                              handleProductDelete(
                                campaignProduct.campaignProductId
                              );
                            }}
                          />
                        </span>
                      )}
                      {/*  <span className={generalStyles.warningIcon}>*/}
                      {/*    <Icon*/}
                      {/*      glyph={warningIcon}*/}
                      {/*      width={22}*/}
                      {/*      height={22}*/}
                      {/*      fill={theme.extend.colors.yellow.primary}*/}
                      {/*    />*/}
                      {/*  </span>*/}
                    </div>
                  </div>
                );
              })}
            </Spacing>
            <Spacing size={2} position="t">
              <Button
                fullWidth
                state={isUpdating || isDeleting ? 'loading' : 'default'}
                onClick={handleCatalogueOpen}
              >
                Add/edit products
              </Button>
            </Spacing>

            {productsError && (
              <Spacing size={15} position="t">
                <Alert kind="error">{productsError}</Alert>
              </Spacing>
            )}

            {/*  <Spacing size={15} position="t">*/}
            {/*    <Alert kind="warning">*/}
            {/*      Review artwork positioning before continuing*/}
            {/*    </Alert>*/}
            {/*  </Spacing>*/}
          </aside>
        ) : (
          <div />
        )}
      </div>
    </>
  );
};

export default ArtworkUploader;
