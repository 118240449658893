import produce from 'immer';
import merge from 'lodash/merge';
import omit from 'lodash/omit';

import config from '../config';
import * as persistentState from '../services/local-storage';

const initialState = {
  details: {
    email: null,
    deliveryAddress: {
      firstName: null,
      lastName: null,
      line1: null,
      city: null,
      postcode: null,
      country: null
    },
    isBillingAddressDifferent: false,
    billingAddress: {
      firstName: null,
      lastName: null,
      line1: null,
      city: null,
      postcode: null,
      country: null
    },
    tracked: false,
    trackingAllowed: true,
    isGift: false
  },
  deliveries: [],
  deliveryEstimates: {
    tracked: '',
    untracked: ''
  },
  discount: {
    discountId: null,
    discountCode: null,
    percentageValue: null
  },
  voucherRedemptions: [],
  paypal: {},
  authenticated: false,
  status: 'idle'
};

export default (
  state = {
    ...initialState,
    ...persistentState.get(config.localStorageOrderKey)
  },
  action
) =>
  produce(state, (newState) => {
    switch (action.type) {
      case 'SET_USER':
        if (action.payload) {
          newState.authenticated = !!action.payload.token;
        } else {
          newState = initialState;
          newState.status = state.status;
        }
        return newState;
      case 'GET_USER_FULFILLED':
        const {
          deliveryAddress: userDeliveryAddress,
          billingAddress: userBillingAddress,
          firstName,
          lastName,
          email
        } = action.payload;
        newState.details.email = email;
        newState.details.deliveryAddress = merge(
          {},
          state.details.deliveryAddress,
          userDeliveryAddress,
          { firstName, lastName }
        );
        newState.details.billingAddress = merge(
          {},
          state.details.billingAddress,
          userBillingAddress || {},
          { firstName, lastName }
        );
        return newState;
      case 'GET_CHECKOUT_DETAILS_PENDING':
        newState.status = 'pending';
        return newState;
      case 'GET_CHECKOUT_DETAILS_FULFILLED':
        newState.details.tracked = action.payload.tracked;
        newState.details.trackingAllowed = action.payload.trackingAllowed;
        /**
         * TODO: update the deliveries object based on user delivery address country
         * Pass user token to the API?
         */
        newState.deliveries = action.payload.deliveries;
        newState.deliveryEstimates = action.payload.deliveryEstimates;
        newState.discount = action.payload.discount;
        newState.voucherRedemptions = action.payload.voucherRedemptions;
        newState.status = 'resolved';
        return newState;
      case 'GET_CHECKOUT_DETAILS_REJECTED':
        newState.status = 'rejected';
        return newState;
      case 'APPLY_DISCOUNT_CODE_PENDING':
      case 'APPLY_VOUCHER_CODE_PENDING':
      case 'EDIT_CHECKOUT_DETAILS_PENDING':
        newState.status = 'pending';
        return newState;
      case 'APPLY_DISCOUNT_CODE_FULFILLED':
      case 'EDIT_CHECKOUT_DETAILS_FULFILLED':
        const {
          deliveryEstimates,
          deliveries,
          billingAddress,
          discount,
          voucherRedemptions,
          ...details
        } = action.payload;
        const { deliveryAddress, ...otherDetails } = details;
        newState.deliveryEstimates = deliveryEstimates;
        newState.deliveries = deliveries;
        newState.discount = discount;
        newState.voucherRedemptions = voucherRedemptions;
        newState.details = merge(
          {},
          state.details,
          { deliveryAddress: deliveryAddress || {} },
          omit(otherDetails, [
            'campaigns',
            'items',
            'quantity',
            'showBasketRecommendations',
            'subtotal',
            'currency',
            'delivery',
            'subtotal',
            'token',
            'total',
            'totalsGBP',
            'vatNumber',
            'isGift'
          ]),
          billingAddress || {}
        );
        newState.status = 'resolved';
        return newState;
      case 'APPLY_VOUCHER_CODE_FULFILLED':
        newState.details.total = action.payload.total;
        newState.details.subtotal = action.payload.subtotal;
        newState.details.totalsGBP = action.payload.totalsGBP;
        newState.details.tracked = false;
        newState.voucherRedemptions = action.payload.voucherRedemptions;
        newState.status = 'resolved';
        return newState;
      case 'DELETE_VOUCHER_CODE_FULFILLED':
        newState.voucherRedemptions = state.voucherRedemptions.filter(
          (item) =>
            item.orderVoucherRedemptionId !==
            action.meta.voucherRedemptionId
        );
        return newState;
      case 'APPLY_DISCOUNT_CODE_REJECTED':
      case 'APPLY_VOUCHER_CODE_REJECTED':
      case 'EDIT_CHECKOUT_DETAILS_REJECTED':
        newState.status = 'rejected';
        return newState;
      case 'GET_PAYPAL_CHECKOUT_DETAILS_FULFILLED':
        newState.paypal = {
          payerId: action.meta.paypalData.payerID,
          paymentId: action.meta.paypalData.paymentID
        };
        newState.deliveryEstimates = {
          tracked: action.payload.deliveryEstimates.tracked,
          untracked: action.payload.deliveryEstimates.untracked
        };
        newState.deliveries = action.payload.deliveries;
        newState.details = {
          ...state.details,
          billingAddress: action.payload.billingAddress,
          deliveryAddress: action.payload.deliveryAddress,
          email: action.payload.email,
          tracked: action.payload.tracked,
          trackingAllowed: action.payload.trackingAllowed
        };
        newState.status = 'resolved';
        return newState;
      case 'GET_PAYPAL_CHECKOUT_DETAILS_PENDING':
        newState.status = 'pending';
        return newState;
      case 'CLEAR_CHECKOUT_DETAILS':
        persistentState.set(config.localStorageOrderKey, initialState);
        newState = initialState;
        return newState;
      default:
        return state;
    }
  });
