// Packages
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classNames from 'classnames';
import get from 'lodash/get';
import universal from 'react-universal-component';

import { Grid, GridItem, InlineGrid } from '../../Grid';

// import Error from '../Error';
import Select from '../../Select';
import ScrollToError from '../ScrollToError';
import TinySelect from '../TinySelect';
import Checkbox from '../Checkbox';
import TickCheckbox from '../TickCheckbox';
import ButtonCheckbox from '../ButtonCheckbox';
import ButtonRadioBtn from '../ButtonRadioBtn';
import ColourCircleCheckbox from '../ColourCircleCheckbox';
import ColourCircleRadioBtn from '../ColourCircleRadioBtn';
import RadioBtn from '../RadioBtn';
import TickRadioBtn from '../TickRadioBtn';
import StrictNumber from '../StrictNumber';
import Toggle from '../../../molecules/Toggle';

import generalStyles from './style/general.module.css';

// File uploader is async loaded because it's heavy and rarely used!
const FileInput = universal(() => import(`../FileInput`));

class CustomField extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  onChange(e, onChange) {
    onChange && onChange(e);
    this.props.onCustomChange && this.props.onCustomChange(e.target);
  }

  renderComponent(props) {
    const {
      type,
      children,
      id,
      onCustomChange,
      field,
      setFieldValue,
      setFieldTouched,
      textCenter,
      borderColor,
      isError,
      ...domProps
    } = props;

    const InputComponent = type === 'textarea' ? 'textarea' : 'input';

    switch (type) {
      case 'select':
        return (
          <Select
            id={id || field.name}
            onCustomChange={onCustomChange}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            borderColor={borderColor}
            isError={isError}
            {...field}
            {...domProps}
          />
        );
      case 'tinySelect':
        return (
          <TinySelect
            id={id || field.name}
            {...field}
            onChange={(value) => setFieldValue(field.name, value)}
            {...domProps}
          />
        );
      case 'radio':
        return (
          <RadioBtn
            id={id || field.name}
            checked={field.value === domProps.value}
            {...field}
            {...domProps}
          >
            {children}
          </RadioBtn>
        );
      case 'tickRadio':
        return (
          <TickRadioBtn
            id={id || field.name}
            checked={field.value === domProps.value}
            {...field}
            {...domProps}
          >
            {children}
          </TickRadioBtn>
        );
      case 'buttonRadio':
        return (
          <ButtonRadioBtn
            id={id || field.name}
            checked={field.value === domProps.value}
            {...field}
            {...domProps}
          >
            {children}
          </ButtonRadioBtn>
        );
      case 'checkbox':
        return (
          <Checkbox {...field} {...domProps}>
            {children}
          </Checkbox>
        );
      case 'tickCheckbox':
        return (
          <TickCheckbox {...field} {...domProps}>
            {children}
          </TickCheckbox>
        );
      case 'buttonCheckbox':
        return (
          <ButtonCheckbox {...field} {...domProps}>
            {children}
          </ButtonCheckbox>
        );
      case 'colourCircleCheckbox':
        return (
          <ColourCircleCheckbox {...field} {...domProps}>
            {children}
          </ColourCircleCheckbox>
        );
      case 'colourCircleRadio':
        return (
          <ColourCircleRadioBtn
            checked={field.value === domProps.value}
            {...field}
            {...domProps}
          >
            {children}
          </ColourCircleRadioBtn>
        );
      case 'toggle':
        return (
          <Toggle
            id={id || field.name}
            checked={field.value}
            {...field}
            onChange={(value) => setFieldValue(field.name, value)}
            {...domProps}
          />
        );
      case 'file':
        return (
          <FileInput
            id={id || field.name}
            title={field.name}
            {...field}
            onChange={(e) => this.onChange(e, field.onChange)}
            {...domProps}
            value={field.value || ''}
          />
        );
      case 'strictNumber':
        return (
          <StrictNumber
            className={classNames(generalStyles.input, {
              'md:text-center': textCenter
            })}
            id={id || field.name}
            title={field.name}
            {...field}
            value={field.value || field.min || 0}
            {...domProps}
          />
        );
      default:
        return (
          <InputComponent
            className={classNames(generalStyles.input, {
              [generalStyles.textarea]: type === 'textarea',
              'md:text-center': textCenter
            })}
            type={type}
            id={id || field.name}
            title={field.name}
            {...field}
            value={field.value || ''}
            {...domProps}
          />
        );
    }
  }

  render() {
    const {
      name,
      onChange,
      onBlur,
      border,
      type,
      children,
      align,
      borderColor,
      scrollToError,
      ...otherProps
    } = this.props;
    const selectHasNoLabel = type === 'select' && !children; // let's check if select has label next to it or not
    const noBorderType =
      selectHasNoLabel ||
      [
        'radio',
        'tickRadio',
        'buttonRadio',
        'checkbox',
        'tickCheckbox',
        'buttonCheckbox',
        'buttonRadio',
        'colourCircleCheckbox',
        'colourCircleRadio',
        'tinySelect'
      ].includes(type);

    return (
      <Field
        name={name}
        ref={this.inputRef}
        render={({
          field, // { name, value, onChange, onBlur }
          form: {
            touched,
            errors,
            setFieldTouched,
            setFieldValue,
            ...formikProps
          } // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        }) => {
          const isError = get(touched, name) && get(errors, name);
          const input = this.renderComponent({
            field,
            type,
            children,
            setFieldTouched,
            setFieldValue,
            borderColor,
            isError,
            ...otherProps
          });
          const containerClassName = {
            [generalStyles.isInline]: type === 'tinySelect',
            [generalStyles.border]: noBorderType ? false : border,
            'border-black': borderColor === 'black',
            'border-darker-grey': borderColor === 'grey',
            'Select-grey': borderColor === 'grey',
            [generalStyles.isNotValid]: isError,
            selectHasLabel: !selectHasNoLabel && type === 'select'
          };

          if (
            [
              'radio',
              'tickRadio',
              'buttonRadio',
              'checkbox',
              'tickCheckbox',
              'buttonCheckbox',
              'colourCircleCheckbox',
              'colourCircleRadio',
              'tinySelect'
            ].includes(type)
          ) {
            return (
              <div
                className={classNames(
                  generalStyles.container,
                  containerClassName,
                  { isNotValid: isError }
                )}
              >
                {scrollToError && (
                  <ScrollToError
                    {...formikProps}
                    errors={errors}
                    inputRef={this.inputRef}
                    name={name}
                  />
                )}
                <Fragment>
                  {type === 'tinySelect' ? (
                    input
                  ) : (
                    <InlineGrid justify="start">{input}</InlineGrid>
                  )}
                  {/* {isError && <Error>{get(errors, name)}</Error>} */}
                </Fragment>
              </div>
            );
          }

          return (
            <div
              className={classNames(
                generalStyles.container,
                containerClassName,
                'bg-white'
              )}
            >
              {scrollToError && (
                <ScrollToError
                  {...formikProps}
                  errors={errors}
                  inputRef={this.inputRef}
                  name={name}
                />
              )}
              <Grid gap={0} align={align}>
                <GridItem columnSize={children ? [12, 4] : 0}>
                  {children && children}
                </GridItem>
                <GridItem columnSize={children ? [12, 8] : 12}>
                  {input}
                  {/* {isError && <Error>{get(errors, name)}</Error>} */}
                </GridItem>
              </Grid>
            </div>
          );
        }}
      />
    );
  }
}

CustomField.propTypes = {
  border: PropTypes.bool,
  textCenter: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
  options: PropTypes.array,
  align: PropTypes.string,
  onCustomChange: PropTypes.func,
  borderColor: PropTypes.oneOf(['black', 'grey']),
  scrollToError: PropTypes.bool
};

CustomField.defaultProps = {
  border: true,
  type: 'text',
  align: 'center',
  borderColor: 'black',
  scrollToError: true
};

export default CustomField;
