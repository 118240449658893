import React from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';

import { ButtonNew as Button } from '../../../atoms/Button';
import { Grid, GridItem } from '../../../atoms/Grid';
import Heading from '../../../atoms/Heading';
import Spacing from '../../../atoms/Spacing';
import Paragraph from '../../../atoms/Paragraph';
import Image from '../../../atoms/Image';

import generalStyles from './style/general.module.css';

export default function HugeThumbnail({
  image,
  title,
  cta1,
  cta2,
  isBg,
  width,
  height
}) {
  const bgProps = {
    className: generalStyles.bg,
    style: {
      backgroundImage: `url(${image})`
    }
  };

  return (
    <Grid gap={2} align="stretch">
      <GridItem columnSize={[0, 5]}>
        <Spacing size={[2, 0]} type="padding" position="b">
          {isBg ? (
            <div {...bgProps} />
          ) : (
            <Image
              src={image}
              alt={title}
              width={width}
              height={height}
              lazyLoad={false}
            />
          )}
        </Spacing>
      </GridItem>
      <GridItem columnSize={[12, 7]}>
        <Spacing size={[1, 2, 3]} position="b">
          <Heading
            className="hyphens leading-none"
            size={['l', 'xl', 'xl', 'xxl', 'xxxl']}
          >
            {title}
          </Heading>
        </Spacing>

        <Grid gap={2}>
          <GridItem columnSize={[12, 6]}>
            <Link to={cta1.to}>
              <Spacing size={[2, 0]} position="b">
                <div className="group">
                  <Spacing size={1} position="b">
                    <Heading size="s">{cta1.title}</Heading>
                  </Spacing>
                  <Paragraph size="xs" inline>
                    {cta1.copy}
                  </Paragraph>
                  <Button kind="outline" size="tiny">
                    More
                  </Button>
                </div>
              </Spacing>
            </Link>
          </GridItem>

          <GridItem columnSize={[12, 6]}>
            <Link to={cta2.to}>
              <div className="group">
                <Spacing size={1} position="b">
                  <Heading size="s">{cta2.title}</Heading>
                </Spacing>
                <Paragraph size="xs" inline>
                  {cta2.copy}
                </Paragraph>
                <Button kind="outline" size="tiny">
                  More
                </Button>
              </div>
            </Link>
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  );
}

HugeThumbnail.propTypes = {
  image: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  cta1: PropTypes.object.isRequired,
  cta2: PropTypes.object.isRequired,
  isBg: PropTypes.bool
};

HugeThumbnail.defaultProps = {
  isBg: false
};
