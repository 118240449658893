import produce from 'immer';
import map from 'lodash/map';
import merge from 'lodash/merge';
import zipObject from 'lodash/zipObject';

import deepMergeByKey from '../helpers/deepMergeByKey';

import config from '../config';

const { value: defaultTag } = config.shop.defaultTag;
const { value: podTag } = config.shop.podTag;
const { value: garmentPageTag } = config.shop.garmentPageTag;

const initialState = {
  list: {
    items: [],
    itemsCount: null,
    byTag: {
      [defaultTag]: {
        items: [],
        itemsCount: null
      },
      '50-50': {
        items: [],
        itemsCount: null
      },
      [podTag]: {
        items: [],
        itemsCount: null
      },
      [garmentPageTag]: {
        items: [],
        itemsCount: null
      }
    },
    isLoading: false,
    isLoadingMore: false
  },
  search: {
    query: '',
    items: [],
    pageCount: null,
    page: null,
    status: 'idle',
    itemsCount: null
  },
  tags: [],
  activeFilters: null,
  scroll: { y: 0, x: 0 },
  giftCards: {
    items: [],
    status: 'idle'
  }
};

export default (state = initialState, action) =>
  produce(state, (newState) => {
    const { type, payload, meta } = action;
    let tag = null;
    let currentItems = [];
    let printOnDemand = false;
    let isGarmentPage = false;
    switch (type) {
      case 'GET_SHOP_ITEMS_PENDING':
        newState.list.isLoading = true;
        return newState;
      case 'GET_SHOP_ITEMS_FULFILLED': {
        const { mergeExisting } = meta.reducerOptions;
        const updatedResults = deepMergeByKey(
          payload.results,
          meta.featuredProducts,
          'campaignId'
        );
        tag = meta.tag;
        printOnDemand = meta.printOnDemand;
        isGarmentPage = meta.isGarmentPage;
        if (printOnDemand) {
          currentItems = state.list.byTag[podTag].items;
          newState.list.byTag[podTag].items = mergeExisting
            ? merge([], currentItems, updatedResults)
            : updatedResults;
          newState.list.byTag[podTag].itemsCount = payload.total;
          newState.list.isLoading = false;
          return newState;
        }
        if (isGarmentPage) {
          currentItems = state.list.byTag[garmentPageTag].items;
          newState.list.byTag[garmentPageTag].items = mergeExisting
            ? merge([], currentItems, updatedResults)
            : updatedResults;
          newState.list.byTag[garmentPageTag].itemsCount = payload.total;
          newState.list.isLoading = false;
          return newState;
        }
        if (!tag) {
          currentItems = state.list.items;
          newState.list.items = mergeExisting
            ? merge([], currentItems, updatedResults)
            : updatedResults;
          newState.list.itemsCount = payload.total;
          newState.list.isLoading = false;
          return newState;
        }
        if (state.list.byTag[tag]) {
          currentItems = state.list.byTag[tag].items;
        }
        newState.list.byTag[tag].items = mergeExisting
          ? merge([], currentItems, updatedResults)
          : updatedResults;
        newState.list.byTag[tag].itemsCount = payload.total;
        newState.list.isLoading = false;
        return newState;
      }
      case 'GET_MORE_SHOP_ITEMS_PENDING':
        newState.list.isLoadingMore = true;
        return newState;
      case 'GET_MORE_SHOP_ITEMS_FULFILLED': {
        const updatedResults = deepMergeByKey(
          payload.results,
          meta.featuredProducts,
          'campaignId'
        );
        tag = meta.tag;
        printOnDemand = meta.printOnDemand;
        isGarmentPage = meta.isGarmentPage;
        if (printOnDemand) {
          currentItems = state.list.byTag[podTag].items;
          newState.list.byTag[podTag].items = [
            ...currentItems,
            ...updatedResults
          ];
          newState.list.byTag[podTag].itemsCount = payload.total;
          newState.list.isLoadingMore = false;
          return newState;
        }
        if (isGarmentPage) {
          currentItems = state.list.byTag[garmentPageTag].items;
          newState.list.byTag[garmentPageTag].items = [
            ...currentItems,
            ...updatedResults
          ];
          newState.list.byTag[garmentPageTag].itemsCount = payload.total;
          newState.list.isLoadingMore = false;
          return newState;
        }
        if (!tag) {
          currentItems = state.list.items;
          newState.list.items = [...currentItems, ...updatedResults];
          newState.list.itemsCount = payload.total;
          newState.list.isLoadingMore = false;
          return newState;
        }
        currentItems = state.list.byTag[tag].items;
        newState.list.byTag[tag].items = [...currentItems, ...updatedResults];
        newState.list.byTag[tag].itemsCount = payload.total;
        newState.list.isLoadingMore = false;
        return newState;
      }
      case 'GET_SHOP_COLLECTIONS_FULFILLED':
        const collections = map(payload, 'slug');
        newState.list.byTag = merge(
          {},
          state.list.byTag,
          zipObject(
            collections,
            Array(collections.length).fill(initialState.list.byTag[defaultTag])
          )
        );
        newState.tags = payload;
        return newState;
      case 'GET_SHOP_SEARCH_RESULTS_PRICES_FULFILLED':
        const updatedSearchItems = deepMergeByKey(
          state.search.items,
          payload,
          'objectID'
        );
        newState.search.items = updatedSearchItems;
        return newState;
      case 'SET_SHOP_SEARCH_QUERY':
        newState.search.query = payload;
        newState.search.page = 0;
        return newState;
      case 'SHOP_SEARCH_LOAD_MORE':
        newState.search.page = payload.searchPage + 1;
        newState.search.status = 'loading-more';
        return newState;
      case 'SET_SHOP_SEARCH_RESULTS':
        newState.search.items =
          payload.page > 0 && payload.query === state.search?.query
            ? [...state.search.items, ...payload.hits]
            : payload.hits;
        newState.search.pageCount = payload.nbPages;
        newState.search.itemsCount = payload.nbHits;
        newState.search.page = payload.page;
        newState.search.status = 'idle';
        return newState;
      case 'SAVE_SHOP_ACTIVE_FILTERS':
        newState.activeFilters = payload;
        return newState;
      case 'SAVE_SCROLL_POSITION':
        newState.scroll = payload;
        return newState;
      case 'UPDATE_SHOP_SEARCH_QUERY':
        newState.search.status = 'loading';
        return newState;
      case 'SET_SHOP_SEARCH_STATUS':
        newState.search.status = payload;
        return newState;
      case 'SET_GLOBAL_SEARCH_OPEN':
        newState.search.isOpen = payload;
        return newState;
      case 'GET_GIFT_CARDS_FULFILLED':
        newState.giftCards.items = payload;
        newState.giftCards.status = 'resolved';
        return newState;
      case 'GET_GIFT_CARDS_PENDING':
        newState.giftCards.status = 'pending';
        return newState;
      case 'GET_GIFT_CARDS_REJECTED':
        newState.giftCards.status = 'rejected';
        return newState;
      default:
        return state;
    }
  });
