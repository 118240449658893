import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { redirect } from 'redux-first-router';
import useResizeAware from 'react-resize-aware';
import get from 'lodash/get';
import some from 'lodash/some';
import partition from 'lodash/partition';
import isEmpty from 'lodash/isEmpty';
import * as Sentry from "@sentry/react";

import { theme } from '../../theme-config';
import config from '../../config';

import { wait } from '../../helpers/stripe';

import * as userActions from '../../actions/user';
import * as checkoutActions from '../../actions/checkout';
import * as logActions from '../../actions/log';

import { checkoutSteps, useCheckoutSteps } from './checkoutStepsHook';

import Page from '../../components/utils/Page';

import Alert from '../../components/atoms/Alert';
import Heading from '../../components/atoms/Heading';
import Spacing from '../../components/atoms/Spacing';
import { Grid, GridItem, InlineGrid } from '../../components/atoms/Grid';
import { PageWrapNew as PageWrap } from '../../components/atoms/PageWrap';
import Paragraph from '../../components/atoms/Paragraph';
import PaypalButton from '../../components/atoms/PaypalButton';
import Price from '../../components/atoms/Price';

import {
  AccordionElement,
  AccordionManager
} from '../../components/molecules/Accordion';
import CheckoutBox from '../../components/molecules/CheckoutBox/New';

import OrderOverview from '../../components/organisms/OrderOverview';

import CheckoutUserInfo from './CheckoutUserInfo';
import CheckoutDeliveryOptions from './CheckoutDeliveryOptions';
import CheckoutShippingAddress from './CheckoutShippingAddress';
import CheckoutPaymentDetails from './CheckoutPaymentDetails';
import CheckoutDiscountPanel from './CheckoutDiscountPanel';
import CheckoutDiscountCode from './CheckoutDiscountCode';

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '../../components/molecules/Disclosure';

const verifyAddressComplete = (address) => {
  return (
    address &&
    address.firstName &&
    address.lastName &&
    address.line1 &&
    address.city &&
    address.postcode &&
    address.country
  );
};

const CheckoutWithPaypal = () => {
  const {
    stepsState,
    saveStep,
    editStep,
    openStep,
    closeStep
  } = useCheckoutSteps();
  const [resizeListener, sizes] = useResizeAware();
  const [paymentProvider, setPaymentProvider] = useState('stripe');
  const [paypalError, setPaypalError] = useState(null);
  const [isMixedOrder, setIsMixedOrder] = useState(false);
  const [
    isFreeShippingExperimentOrder,
    setIsFreeShippingExperimentOrder
  ] = useState(false);

  const dispatch = useDispatch();

  const checkout = useSelector((state) => state.checkout, shallowEqual),
    {
      deliveries,
      deliveryEstimates,
      discount,
      voucherRedemptions,
      authenticated: isAuthenticated
    } = checkout;

  const settings = useSelector((state) => state.system.settings);

  const deliveryAddress = useSelector(
    (state) => state.checkout.details.deliveryAddress
  );

  const details = useSelector((state) => state.checkout.details, shallowEqual),
    {
      trackingAllowed,
      tracked,
      isGift,
      billingAddress,
      isBillingAddressDifferent,
      email
    } = details;

  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user, shallowEqual);
  const userDetails = useSelector((state) => state.user.details);
  // eslint-disable-next-line
  const [giftCards, products] = partition(cart.items, 'giftCardId');
  const isGiftCardOrder = isEmpty(products);

  const isMobile = sizes.width < parseInt(theme.screens.mdlg);
  const isPaypalAllowed = settings.paymentProviders?.find(
    (provider) => provider.reference === 'paypal'
  );

  const redirectToOrder = (token) => {
    dispatch(
      redirect({
        type: 'ORDER',
        payload: { token }
      })
    );
  };

  const saveAndContinue = useCallback(
    (currentStep) => {
      saveStep(currentStep);

      // we don't dispatch 'saveCheckoutStep' if step 4
      // as we're dispatching it in the payment handler directly
      if (currentStep !== 'STEP_FOUR') {
        dispatch(checkoutActions.saveCheckoutStep(currentStep));
      }
    },
    [saveStep, dispatch]
  );

  const handleUserInfoSave = async (userInfo) => {
    await dispatch(checkoutActions.editCheckoutDetails({ ...userInfo }));

    saveAndContinue(checkoutSteps.STEP_ONE);

    // Klaviyo hack
    await wait(1000);
    dispatch({ type: 'START_CHECKOUT' });
  };

  const handleDeliveryAddressSave = useCallback(
    async (deliveryAddress) => {
      await dispatch(
        checkoutActions.editCheckoutDetails({
          deliveryAddress,
          tracked: deliveryAddress?.country !== 'GB'
        })
      );
      saveAndContinue(checkoutSteps.STEP_TWO);

      // reopen delivery options step
      // if user switches to non GB country
      if (deliveryAddress.country !== 'GB' && !tracked) {
        editStep(checkoutSteps.STEP_THREE);
      }
    },
    [dispatch, saveAndContinue, editStep, tracked]
  );

  const handleDeliveryOptionsSave = async (tracked) => {
    await dispatch(
      checkoutActions.editCheckoutDetails({
        tracked
      })
    );
    saveAndContinue(checkoutSteps.STEP_THREE);
  };

  const handlePaymentDetailsSaved = async (paymentDetailsForm, actions) => {
    dispatch(checkoutActions.saveCheckoutStep('STEP_FOUR'));

    switch (paymentProvider) {
      case 'stripe':
        const { stripeTokenId, ...paymentDetails } = paymentDetailsForm;

        try {
          const response = await dispatch(
            checkoutActions.checkoutStripe(stripeTokenId, {
              ...paymentDetails,
              tracked,
              deliveryAddress,
              email: email || userDetails.email
            })
          );
          saveAndContinue(checkoutSteps.STEP_FOUR);
          redirectToOrder(response.value.token);
        } catch (error) {
          const errorMessage = get(
            error,
            'response.data.errors.errors[0]',
            config.stripeErrors.default
          );

          actions.setStatus({
            error: errorMessage
          });

          if (error.response && error.response.data.scaUrl) {
            window.location = error.response.data.scaUrl;
          }

          if (errorMessage === config.stripeErrors.default) {
            Sentry.captureException(error);
          }

          return actions.setSubmitting(false);
        }

        break;
      case 'paypal':
        try {
          if (!isPaypalAllowed) return;

          const { isGift } = paymentDetailsForm;
          const res = await dispatch(
            checkoutActions.checkoutPaypal({ isGift })
          );
          saveAndContinue(checkoutSteps.STEP_FOUR);
          redirectToOrder(res.value.token);
        } catch (error) {
          actions.setStatus({
            error:
              'An error happened while processing your Paypal payment, please try again.'
          });

          return actions.setSubmitting(false);
        }
        break;
      case 'free':
        const { paymentDetails: checkoutPaymentDetails } = paymentDetailsForm;

        try {
          const res = await dispatch(
            checkoutActions.checkoutApp({
              ...checkoutPaymentDetails,
              tracked,
              deliveryAddress,
              email: email || userDetails.email
            })
          );
          saveAndContinue(checkoutSteps.STEP_FOUR);
          redirectToOrder(res.value.token);
        } catch (error) {
          actions.setStatus({ error });
          return actions.setSubmitting(false);
        }
        break;
      default:
        break;
    }
  };

  const onPaypalError = (err) => {
    setPaypalError('PayPal error, please try again.');
    Sentry.captureException(err);
  };

  const getPaypalCheckoutDetails = (paypalData) => {
    dispatch(checkoutActions.getPaypalCheckoutDetails(paypalData));
  };

  const onPaypalAuthorized = () => {
    setPaymentProvider('paypal');
    setPaypalError(null);

    openStep(checkoutSteps.STEP_THREE);
    editStep(checkoutSteps.STEP_THREE);
  };

  const logPaypal = (logs) => {
    dispatch(logActions.log(logs));
  };

  useEffect(() => {
    dispatch(
      checkoutActions.editCheckoutDetails({
        currency: cart.currency
      })
    );
  }, [cart.currency, dispatch]);

  useEffect(() => {
    if (cart.total === 0 && paymentProvider !== 'free') {
      setPaymentProvider('free');
    }
  }, [cart.total, paymentProvider, setPaymentProvider]);

  useEffect(() => {
    setIsMixedOrder(
      cart.total > 0 &&
        (!cart.items.every(({ price }) => price > 0) ||
          !deliveries.every(({ price }) => price > 0))
    );
  }, [cart.total, cart.items, deliveries, setIsMixedOrder]);

  useEffect(() => {
    // isFreeShippingExperimentOrder shows whether the order is part of the
    // variant and has free shipping but is not a free order as the isMixedOrder
    // variable will also pick these orders up to be true we needed to
    // differentiate
    setIsFreeShippingExperimentOrder(
      cart.total > 0 &&
        deliveryEstimates.tracked > 0 &&
        deliveryEstimates.untracked === 0 &&
        cart.items.every(({ price }) => price > 0)
    );
  }, [
    cart.total,
    deliveryEstimates.tracked,
    deliveryEstimates.untracked,
    cart.items
  ]);

  useEffect(() => {
    if (
      isMixedOrder &&
      !isFreeShippingExperimentOrder &&
      !stepsState[checkoutSteps.STEP_THREE].saved
    )
      saveAndContinue(checkoutSteps.STEP_THREE);
  }, [
    isMixedOrder,
    isFreeShippingExperimentOrder,
    stepsState,
    paymentProvider,
    saveAndContinue
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(userActions.getUser());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (
      email &&
      stepsState[checkoutSteps.STEP_ONE].empty &&
      !stepsState[checkoutSteps.STEP_ONE].saved
    ) {
      saveAndContinue(checkoutSteps.STEP_ONE);
    }
  }, [email, stepsState, saveAndContinue]);

  useEffect(() => {
    if (
      verifyAddressComplete(deliveryAddress) &&
      !stepsState[checkoutSteps.STEP_TWO].open &&
      !stepsState[checkoutSteps.STEP_TWO].saved
    ) {
      handleDeliveryAddressSave(deliveryAddress);
    } else if (
      !verifyAddressComplete(deliveryAddress) &&
      !stepsState[checkoutSteps.STEP_TWO].empty
    ) {
      closeStep(checkoutSteps.STEP_TWO);
    }
  }, [deliveryAddress, stepsState, handleDeliveryAddressSave, closeStep]);

  useEffect(() => {
    if (
      (!some(deliveryEstimates) || !deliveryEstimates.untracked) &&
      !stepsState[checkoutSteps.STEP_THREE].empty &&
      !isMixedOrder &&
      !isFreeShippingExperimentOrder
    ) {
      closeStep(checkoutSteps.STEP_THREE);
    }
  }, [
    deliveryEstimates,
    stepsState,
    paymentProvider,
    isMixedOrder,
    isFreeShippingExperimentOrder,
    closeStep
  ]);

  useEffect(() => {
    dispatch({ type: 'CHECKOUT_LOAD_WITH_PAYPAL' });
  }, [dispatch]);

  const hasErrored = cart.status === 'rejected';

  const checkoutDeliveryProps = {
    currency: cart.currency,
    deliveryEstimates: deliveryEstimates,
    tracked: tracked,
    trackingAllowed: trackingAllowed,
    isSaved: stepsState[checkoutSteps.STEP_THREE].saved,
    onSave: handleDeliveryOptionsSave,
    onEdit:
      isMixedOrder && !isFreeShippingExperimentOrder
        ? null
        : () => {
            editStep(checkoutSteps.STEP_THREE);
          },
    customDeliveryOptionCopy: isGiftCardOrder
      ? 'Delivered digitally'
      : undefined
  };

  const isDiscountApplied =
    !isEmpty(discount?.discountCode) || !isEmpty(voucherRedemptions);

  return (
    <Page title="Checkout">
      {resizeListener}
      <PageWrap>
        <Spacing size={[0, 0, 0, 2]}>
          <Grid gap={[15, 15, 15, 25]} align="start" row={['flex-row-reverse']}>
            <GridItem columnSize={[12, 12, 12, 6]}>
              <div className="relative">
                <div className="-mx-15 md:mx-0" />
                <div className="-mx-15 absolute w-screen h-full bg-grey-lightest -z-1 md:hidden" />
                <AccordionManager>
                  <AccordionElement
                    label={
                      <Heading size={['xxs', 'xxs', 'xxs', 'xs']}>
                        Shopping bag overview
                      </Heading>
                    }
                    value={
                      isMobile && (
                        <Price
                          value={deliveries.length ? cart.total : cart.subtotal}
                          currency={cart.currency}
                          size="xxs"
                        />
                      )
                    }
                    externalToggle={!isMobile ? true : undefined}
                    size="s"
                    disabled={!isMobile}
                    arrows={isMobile}
                  >
                    <div className="-mx-15 border-b-1 border-grey-lighter md:mx-0 mdlg:border-black" />
                    <Spacing size={[15, 15, 15, 2]} position="t">
                      <OrderOverview
                        items={cart.items}
                        campaigns={cart.campaigns}
                        deliveries={deliveries}
                        currency={cart.currency}
                      />
                      <CheckoutDiscountPanel
                        deliveries={deliveries}
                        currency={cart.currency}
                      />
                      <div className="border-t-1 border-grey-lighter" />
                      <CheckoutBox title="Total">
                        <Price
                          value={deliveries.length ? cart.total : cart.subtotal}
                          currency={cart.currency}
                          size={['xs', 'xs', 'xs', 's']}
                        />
                      </CheckoutBox>
                      <div className="mdlg:border-b-1 mdlg:border-black" />
                    </Spacing>
                  </AccordionElement>
                  <div className="-mx-15 border-b-1 border-grey-lighter md:mx-0 mdlg:border-0" />
                </AccordionManager>
              </div>
              {isMobile ? (
                <Spacing size={1} position="b">
                  <Disclosure defaultOpen={isDiscountApplied}>
                    <InlineGrid justify="end">
                      <DisclosureButton>
                        <Spacing size={15}>
                          <Paragraph
                            className="block text-right leading-normal"
                            tag="span"
                            size="xxxs"
                          >
                            Got a code or gift card?
                          </Paragraph>
                        </Spacing>
                      </DisclosureButton>
                    </InlineGrid>
                    <DisclosurePanel className="relative">
                      <div className="-mx-15 absolute w-screen h-full bg-grey-lightest -z-1 md:hidden" />
                      <Spacing size={15} type="padding">
                        <CheckoutDiscountCode
                          discount={discount}
                          voucherRedemptions={voucherRedemptions}
                          orderCurrency={cart.currency}
                        />
                      </Spacing>
                      <div className="-mx-15 border-b-1 border-grey-lighter md:mx-0 mdlg:border-0" />
                    </DisclosurePanel>
                  </Disclosure>
                </Spacing>
              ) : (
                <Spacing size={15} position="t">
                  <CheckoutDiscountCode
                    discount={discount}
                    voucherRedemptions={voucherRedemptions}
                    orderCurrency={cart.currency}
                  />
                </Spacing>
              )}
            </GridItem>
            <GridItem columnSize={[12, 12, 12, 6]}>
              <div className="hidden mdlg:contents">
                <Spacing size={15}>
                  <Heading size="xs" tag="h1">
                    Checkout
                  </Heading>
                </Spacing>
              </div>
              <div className="mdlg:border-b-1 mb-2" />
              {isMixedOrder && !isFreeShippingExperimentOrder && (
                <Spacing size={2} position="b">
                  <Alert kind="info">
                    You have added both free and paid items and will need to
                    create a new order after making changes to your shopping
                    bag.
                  </Alert>
                </Spacing>
              )}
              {(paymentProvider === 'stripe' && isPaypalAllowed) && (
                <Spacing size={2} position="b">
                  <Spacing size={1} position="b">
                    <PaypalButton
                      locale={config.defaultLocale.replace('-', '_')}
                      env={config.appEnv === 'prod' ? 'production' : 'sandbox'}
                      createOrderUrl={`${config.baseApiUri}/checkout/${cart.token}/paypal/create`}
                      onError={(err) => onPaypalError(err)}
                      onAuthorize={getPaypalCheckoutDetails}
                      onAuthorized={onPaypalAuthorized}
                      log={logPaypal}
                    />
                    {paypalError && (
                      <Spacing size={1} position="t">
                        <Alert kind="error">{paypalError}</Alert>
                      </Spacing>
                    )}
                  </Spacing>
                  <Spacing size={2} position="b" />
                  <Paragraph size="xxxs" className="uppercase">
                    Or checkout with card payment
                  </Paragraph>
                </Spacing>
              )}
              {hasErrored && (
                <Spacing size={1} position="b">
                  <Alert type="error">
                    Something isn't quite right here. Try refreshing the page.
                  </Alert>
                </Spacing>
              )}
              <AccordionManager>
                <AccordionElement
                  label={
                    !stepsState[checkoutSteps.STEP_ONE].saved
                      ? '1. Your Info'
                      : null
                  }
                  externalToggle={stepsState[checkoutSteps.STEP_ONE].open}
                  size="m"
                  className="pb-0 pt-0"
                >
                  <CheckoutUserInfo
                    paypalEmail={paymentProvider === 'paypal' ? email : null}
                    isGuest={!isAuthenticated}
                    email={email || userDetails.email}
                    onSave={handleUserInfoSave}
                    onEdit={() => {
                      editStep(checkoutSteps.STEP_ONE);
                    }}
                    isSaved={stepsState[checkoutSteps.STEP_ONE].saved}
                  />
                </AccordionElement>
                <div
                  className={`${!stepsState[checkoutSteps.STEP_ONE].saved &&
                    'mt-2 border-b-1'} border-grey-lighter`}
                />
                <AccordionElement
                  label={
                    !stepsState[checkoutSteps.STEP_TWO].saved
                      ? '2. Shipping Address'
                      : null
                  }
                  externalToggle={stepsState[checkoutSteps.STEP_TWO].open}
                  size="m"
                  className="pb-0"
                >
                  <CheckoutShippingAddress
                    paymentProvider={paymentProvider}
                    deliveryAddress={deliveryAddress}
                    user={{ country: user.country }}
                    onSave={handleDeliveryAddressSave}
                    onEdit={() => {
                      editStep(checkoutSteps.STEP_TWO);
                    }}
                    isSaved={stepsState[checkoutSteps.STEP_TWO].saved}
                  />
                </AccordionElement>
                <div
                  className={`${!stepsState[checkoutSteps.STEP_TWO].saved &&
                    'mt-2 border-b-1'} border-grey-lighter`}
                />
                <AccordionElement
                  label={
                    !stepsState[checkoutSteps.STEP_THREE].saved
                      ? '3. Delivery Options'
                      : null
                  }
                  externalToggle={stepsState[checkoutSteps.STEP_THREE].open}
                  size="m"
                  className="pb-0"
                >
                  <CheckoutDeliveryOptions {...checkoutDeliveryProps} />
                </AccordionElement>
                <div
                  className={`${!stepsState[checkoutSteps.STEP_THREE].saved &&
                    'mt-2 border-b-1'} border-grey-lighter`}
                />
                <AccordionElement
                  label={
                    !stepsState[checkoutSteps.STEP_FOUR].saved
                      ? '4. Payment Details'
                      : null
                  }
                  externalToggle={stepsState[checkoutSteps.STEP_FOUR].open}
                  size="m"
                  className="pb-0"
                >
                  <CheckoutPaymentDetails
                    paymentProvider={paymentProvider}
                    priceTotal={cart.total}
                    currency={cart.currency}
                    initialValues={{
                      isGift,
                      billingAddress,
                      isBillingAddressDifferent
                    }}
                    onSave={handlePaymentDetailsSaved}
                    onEdit={() => {
                      editStep(checkoutSteps.STEP_FOUR);
                    }}
                    isSaved={stepsState[checkoutSteps.STEP_FOUR].saved}
                  />
                </AccordionElement>
                <div className="mt-4 mdlg:border-b-1 mdlg:border-black" />
              </AccordionManager>
            </GridItem>
          </Grid>
        </Spacing>
      </PageWrap>
    </Page>
  );
};

export default CheckoutWithPaypal;
