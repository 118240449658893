const termsBuyers = [
  {
    title: 'Information about our use of cookies',
    sections: [
      {
        type: 'paragraph',
        content:
          'Our <a href="/" target="_blank">website</a> uses cookies to distinguish you from other users of our site. This helps us to provide you with a good experience when you browse our site, and also allows us to improve our site. By continuing to browse the site, you are agreeing to our use of cookies.'
      },
      {
        type: 'paragraph',
        content:
          "A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive."
      },
      {
        type: 'paragraph',
        content: 'We use the following cookies:'
      },
      {
        type: 'list',
        content: [
          '<b>Strictly necessary cookies.</b> These are cookies that are required for the operation of our site. They include, for example, cookies that enable you to log into secure areas of our site, use a shopping cart or make use of e-billing services.',
          '<b>Analytical/performance cookies.</b> They allow us to recognise and count the number of visitors, and to see how visitors move around our site when they are using it. This helps us to improve the way our site works, for example, by ensuring that users are finding what they are looking for easily.',
          '<b>Functionality cookies.</b> These are used to recognise you when you return to our site. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).',
          '<b>Targeting cookies.</b> These cookies record your visit to our site, the pages you have visited, and the links you have followed. We will use this information to make our site and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.'
        ]
      },
      {
        type: 'paragraph',
        content:
          'You can find more information about the individual cookies we use and the purposes for which we use them in the table below:'
      }
    ]
  },
  {
    title: '',
    sections: [
      {
        type: 'table',
        rows: [
          [['Cookie'], ['Purpose']],
          [
            ['PHPSESSID'],
            [
              'This cookie enables us to:',
              '- Store information about your preferences, and so allow us to customise our site and to provide you with offers that are targeted at your individual interests.',
              '- Allow you to use our site in a way that makes your browsing experience more convenient, for example, by allowing you to store items in an electronic shopping basket between visits.If you register with us or complete our online forms, we will use cookies to remember your details during your current visit, and any future visits provided the cookie was not deleted in the interim.'
            ]
          ],
          [
            ['REMEMBERME'],
            [
              'This cookie enables us to:',
              '- Recognise you when you return to our site.'
            ]
          ],
          [
            ['__ar_v4'],
            [
              'This cookie enables us to:',
              '- Recognise visit data relevant to your browsing session(s) so that we can customise promotional offers targeted at your individual interests.'
            ]
          ],
          [
            ['__qca'],
            [
              'This cookie enables us to:',
              '- Recognise visit data relevant to your browsing session(s).'
            ]
          ],
          [
            ['__zlcmid'],
            [
              'This cookie enables us to:',
              '- Store information about your browsing session so that we can offer live support chat.'
            ]
          ],
          [
            ['_dc_gtm_UA-69603735-1'],
            [
              'This cookie enables us to:',
              '- Recognise visit data relevant to your browsing session(s).'
            ]
          ],
          [
            ['_ga'],
            [
              'This cookie enables us to:',
              '- Recognise visit data relevant to your browsing session(s).'
            ]
          ]
        ]
      }
    ]
  },
  {
    sections: [
      {
        type: 'paragraph',
        content:
          'Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.'
      },
      {
        type: 'paragraph',
        content:
          'You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies), you may not be able to access all or parts of our site.'
      },
      {
        type: 'paragraph',
        content:
          'Except for essential cookies, all cookies will expire after 1 year.'
      }
    ]
  }
];

export default termsBuyers;
