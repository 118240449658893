import React from 'react';

import { getFirstErrorPath } from './utils';

export default class ScrollToError extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      name,
      errors,
      inputRef,
      submitCount,
      isValid,
      isSubmitting
    } = this.props;

    if (
      (prevProps.submitCount !== submitCount ||
        prevProps.isSubmitting !== isSubmitting) &&
      !isValid
    ) {
      if (
        getFirstErrorPath(errors) === name &&
        inputRef?.current instanceof HTMLElement
      ) {
        inputRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  render() {
    return null;
  }
}
