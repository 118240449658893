import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Select, { SelectNew } from '../Select';
import { Field, DefaultField } from '../Form';

class SelectCurrencies extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired
  };

  render() {
    const {
      data,
      getCurrencies,
      standalone,
      value,
      className,
      children,
      useNewStyles,
      ...props
    } = this.props;
    const options = data.map((currency) => ({
      value: currency.iso,
      label: `${currency.symbol} ${currency.iso}`
    }));

    const SelectComponent = useNewStyles ? SelectNew : Select;
    const FieldComponent = useNewStyles ? DefaultField : Field;

    if (standalone) {
      return (
        <SelectComponent
          aria-label="Select currency"
          options={options}
          {...props}
          value={value}
          className={className}
          standalone
        />
      );
    } else {
      return (
        <FieldComponent type="select" options={options} {...props}>
          {children}
        </FieldComponent>
      );
    }
  }
}

const mapStateToProps = ({ currencies }) => {
  return {
    data: currencies
  };
};

export default connect(mapStateToProps, null, null, {
  pure: false // TODO: find a way to avoid this. Will change a lot with React 16.3.0...
})(SelectCurrencies);
