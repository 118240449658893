import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import * as Sentry from "@sentry/react";
import omit from 'lodash/omit';

import { subscribe } from '../../../actions/crm';

import Paragraph from '../../atoms/Paragraph';
import { ButtonNew } from '../../atoms/Button';
import { Form } from '../../atoms/Form';

import generalStyles from './style/general.module.css';

const cx = classNames.bind({ ...generalStyles });

const STATUS_SUCCESS = 'success';
const STATUS_ERROR = 'error';

const KLAVIYO_LIST_ID = process.env.REACT_APP_KLAVIYO_LIST_ID;

const NewsletterForm = ({ isDark }) => {
  const dispatch = useDispatch();
  const utmParams = useSelector((state) => state.tracking.utmParams);

  const handleSubmit = async (formData, actions) => {
    try {
      const { email } = formData;

      await dispatch(
        subscribe(
          email,
          KLAVIYO_LIST_ID,
          'Homepage form',
          omit(utmParams, 'timestamp')
        )
      );

      actions.setStatus(STATUS_SUCCESS);

      dispatch({ type: 'KLAVIYO_IDENTIFY', payload: { email } });
    } catch (err) {
      if (err.response?.status === 400) {
        actions.setStatus(STATUS_ERROR);
      }

      // Fail silently
      Sentry.captureException(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const formClassName = cx({
    formDark: isDark,
    formLight: !isDark
  });

  const inputClassName = cx({
    inputDark: isDark,
    inputLight: !isDark
  });

  return (
    <Form
      initialValues={{
        email: ''
      }}
      validationSchema={yup.object().shape({
        email: yup
          .string()
          .required()
          .email()
      })}
      onSubmit={handleSubmit}
      render={({
        values,
        isSubmitting,
        handleChange,
        handleSubmit,
        status
      }) => (
        <>
          {status === STATUS_SUCCESS ? (
            <Paragraph
              className={generalStyles.successMessage}
              color="white"
              size="xxs"
            >
              You're subscribed! Thanks
            </Paragraph>
          ) : (
            <form
              onSubmit={handleSubmit}
              className={classNames(formClassName, {
                [cx('formError')]: status === STATUS_ERROR
              })}
            >
              <>
                <input
                  className={inputClassName}
                  name="email"
                  type="email"
                  placeholder="Your email address"
                  onChange={handleChange}
                  value={values.email}
                />
                <ButtonNew
                  type="submit"
                  name="subscribe"
                  id="membedded-subscribe"
                  kind="ghost"
                  color={isDark ? 'white' : 'black'}
                  className={cx('submit')}
                  state={
                    (!!status && 'disabled') ||
                    (isSubmitting && 'loading') ||
                    'default'
                  }
                >
                  Submit
                </ButtonNew>
              </>
            </form>
          )}
          {status === STATUS_ERROR ? (
            <Paragraph
              className={generalStyles.errorMessage}
              color="white"
              size="xxs"
            >
              You are already subscribed
            </Paragraph>
          ) : null}
        </>
      )}
    />
  );
};

NewsletterForm.propTypes = {
  isDark: PropTypes.bool
};

NewsletterForm.defaultTypes = {
  isDark: false
};

export default NewsletterForm;
