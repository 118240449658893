import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from "@sentry/react";
import omit from 'lodash/omit';
import * as yup from 'yup';

import config from '../../config';

import { subscribe } from '../../actions/crm';

import useLocalStorage from '../../helpers/hooks/useLocalStorage';

import { ButtonNew as Button } from '../../components/atoms/Button';
import Heading from '../../components/atoms/Heading';
import Paragraph from '../../components/atoms/Paragraph';
import Spacing from '../../components/atoms/Spacing';
import {
  DefaultField as Field,
  Error,
  Form
} from '../../components/atoms/Form';
import Link from '../atoms/Link';

import { ModalNew as Modal } from '../../components/molecules/Modal';

import headerImg from '../../img/concierge-header.jpg';

const STATUS_SUCCESS = 'success';
const STATUS_ERROR = 'error';

const KLAVIYO_LIST_ID = process.env.REACT_APP_KLAVIYO_LIST_ID;

const Concierge = () => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const utmParams = useSelector((state) => state.tracking.utmParams);

  const [conciergeStatus, setConciergeStatus] = useLocalStorage(
    config.localStorageConciergeKey
  );

  const [isMtmInitialised] = useLocalStorage(config.localStorageCookiesKey);

  useEffect(() => {
    if (conciergeStatus?.cookiesInit && !conciergeStatus?.isViewed) {
      const timeout = setTimeout(() => {
        setOpen(true);
        setConciergeStatus({ isViewed: true });

        dispatch({
          type: 'WELCOME_OFFER_MODAL_VIEWED'
        });
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [conciergeStatus, setConciergeStatus, dispatch]);

  useEffect(() => {
    if (!conciergeStatus?.cookiesInit) {
      const handleCookiesInit = (event) => {
        if (event.key?.includes('metomic-consented-pol')) {
          setConciergeStatus({
            ...conciergeStatus,
            cookiesInit: true
          });
        }
      };

      window.addEventListener('storage', handleCookiesInit);

      return () => window.removeEventListener('storage', handleCookiesInit);
    }
  }, [isMtmInitialised, setConciergeStatus, conciergeStatus]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (formData, actions) => {
    try {
      const { email } = formData;

      await dispatch(
        subscribe(
          email,
          KLAVIYO_LIST_ID,
          'Welcome modal',
          omit(utmParams, 'timestamp')
        )
      );

      actions.setStatus(STATUS_SUCCESS);

      dispatch({ type: 'KLAVIYO_IDENTIFY', payload: { email } });
      dispatch({
        type: 'WELCOME_OFFER_MODAL_FORM_COMPLETE',
        payload: { email }
      });
    } catch (err) {
      if (err.response?.status === 400) {
        actions.setStatus(STATUS_ERROR);
      }

      // Fail silently
      Sentry.captureException(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      image={{ url: headerImg, alt: 'Everpress t-shirt' }}
      isCloseInverted={false}
    >
      <div className="text-center">
        <Spacing size={2} position="t">
          <Heading size="xs">Get 10% off</Heading>
        </Spacing>
        <Spacing size={2} position="t">
          <Paragraph size="xxs">
            Sign up today and enjoy an exclusive 10% discount on your first
            order. By joining, you'll also stay updated on our latest drops,
            artist spotlights, and long reads.
          </Paragraph>
        </Spacing>
        <Spacing size={2} position="t">
          <Form
            initialValues={{
              email: ''
            }}
            validationSchema={yup.object().shape({
              email: yup
                .string()
                .required('Please enter a valid email address')
                .email()
            })}
            onSubmit={handleSubmit}
            render={({ isSubmitting, handleSubmit, status }) => {
              return (
                <>
                  {status === STATUS_SUCCESS ? (
                    <Spacing size={2} position="t">
                      <div className="p-15 bg-grey-lightest rounded-md">
                        <Paragraph size="xxs">
                          Thanks for subscribing!
                          <br />
                          Here's your code:
                          <br />
                          <Paragraph tag="span" size="xs" className="mt-2">EVP10</Paragraph>
                        </Paragraph>
                      </div>
                    </Spacing>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <div className="text-left">
                        <Spacing size={2} position="t">
                          <Spacing size={1}>
                            <Field
                              type="email"
                              name="email"
                              placeholder="Email address"
                              data-test-id="email"
                            />
                          </Spacing>
                        </Spacing>
                      </div>
                      <Button
                        type="submit"
                        name="subscribe"
                        state={isSubmitting ? 'loading' : null}
                        fullWidth
                      >
                        Sign up
                      </Button>
                      {status === STATUS_ERROR ? (
                        <Spacing size={1} position="t">
                          <Error>You are already subscribed</Error>
                        </Spacing>
                      ) : null}
                      <Spacing size={2} position="t">
                        <Paragraph color="grey-dark" size="xxxs" center>
                          By continuing you agree to our{' '}
                          <Link to={{ type: 'PRIVACY_POLICY' }} target="_blank">
                            privacy policy
                          </Link>
                          .
                        </Paragraph>
                      </Spacing>
                    </form>
                  )}
                </>
              );
            }}
          />
        </Spacing>
      </div>
    </Modal>
  );
};

export default Concierge;
