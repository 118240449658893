import React from 'react';

import Page from '../../components/utils/Page';

import Heading from '../../components/atoms/Heading';
import { ListWrapper, ListItem } from '../../components/atoms/List';
import PageWrap from '../../components/atoms/PageWrap';
import Paragraph from '../../components/atoms/Paragraph';
import MaxWidth from '../../components/atoms/MaxWidth';
import Spacing from '../../components/atoms/Spacing';
import LiveChat from '../../components/atoms/LiveChat';

import { TableRow, TableItem } from '../../components/molecules/Table';

import htmlParser from '../../helpers/htmlParser';

import data from './data';

export default function CookiePolicy() {
  const paragraphSpacing = 2;
  const paragraphSize = 's';
  const headingSize = 'm';
  const headingTag = 'h3';

  return (
    <Page title="Cookie Policy">
      <PageWrap>
        <MaxWidth value={877} center>
          <Spacing>
            <Heading center tag="h2" size="xxl">
              Cookie policy
            </Heading>
          </Spacing>
          {data.map(item => (
            <Spacing key={item.title} size={5}>
              <Heading tag={headingTag} size={headingSize}>
                {item.title}
              </Heading>
              {item.sections.map((section, index) => {
                if (section.type === 'paragraph') {
                  return (
                    <Spacing key={index} size={paragraphSpacing}>
                      <Paragraph size={paragraphSize}>
                        {section.title && (
                          <Spacing size={1}>
                            <Heading tag="h4" size="xs">
                              {section.title}
                            </Heading>
                          </Spacing>
                        )}
                        {htmlParser(section.content)}
                      </Paragraph>
                    </Spacing>
                  );
                } else if (section.type === 'list') {
                  return (
                    <Spacing key={index} size={paragraphSpacing}>
                      <ListWrapper>
                        {section.content.map((listItem, index) => (
                          <ListItem key={index}>
                            {htmlParser(listItem)}
                          </ListItem>
                        ))}
                      </ListWrapper>
                    </Spacing>
                  );
                } else if (section.type === 'table') {
                  const tabItems = section.rows[0].length;
                  const gridSize = 12 / tabItems;

                  return (
                    <Spacing key={index} size={paragraphSpacing}>
                      {section.rows.map((row, index) => (
                        <TableRow key={index} align="start">
                          {row.map((item, index) => (
                            <TableItem
                              key={index}
                              columnSize={gridSize}
                              paddingSize={{ y: 1 }}
                            >
                              {item.map((string, index) => (
                                <Paragraph key={index} size="xs">
                                  {string}
                                </Paragraph>
                              ))}
                            </TableItem>
                          ))}
                        </TableRow>
                      ))}
                    </Spacing>
                  );
                }

                return false;
              })}
            </Spacing>
          ))}
        </MaxWidth>
      </PageWrap>
      <LiveChat />
    </Page>
  );
}
