import produce from 'immer';

const initialState = {
  results: [],
  order: {},
  total: null
};

export default (state = initialState, action) =>
  produce(state, newState => {
    const { type, payload } = action;

    switch (type) {
      case 'GET_USER_ORDER_FULFILLED':
        newState.order = payload;
        return newState;
      case 'GET_USER_ORDERS_PENDING':
        newState.order = {};
        return newState;
      case 'GET_USER_ORDERS_FULFILLED':
        const { results, total } = payload;
        newState.results = results;
        newState.total = total;
        newState.order = {};
        return newState;
      default:
        return state;
    }
  });
