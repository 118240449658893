import React, { useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';

import PageWrap from '../../components/atoms/PageWrap';
import { ButtonNew as Button } from '../../components/atoms/Button';
import Paragraph from '../../components/atoms/Paragraph';
import Heading from '../../components/atoms/Heading';
import Price from '../../components/atoms/Price';
import Spacing from '../../components/atoms/Spacing';
import Image from '../../components/atoms/Image';

import Toaster from '../../components/molecules/Toaster';

import Page from '../../components/utils/Page';
import Loading from '../../components/utils/Loading';

import { createCartToast } from '../Campaign/CampaignSingle/CartToast';

import { addCartItem } from '../../actions/cart';

import { page } from './config';

import { theme } from '../../theme-config';

const DEFAULT_CURRENCY = 'GBP';

const GiftCards = () => {
  const dispatch = useDispatch();
  const { items, status } = useSelector(
    (state) => state.shop.giftCards,
    shallowEqual
  );
  const userCurrency =
    useSelector((state) => state.user.currency) ?? DEFAULT_CURRENCY;

  const groupedItems = isEmpty(items) ? [] : groupBy(items, 'currencyIso');

  const toastContainerRef = useRef(null);

  const handleAddToCart = async (item) => {
    await dispatch(addCartItem({ quantity: 1, giftCardId: item.giftCardId }));

    createCartToast();
  };

  const isLoading = status === 'pending';

  if (isLoading) return <Loading />;

  return (
    <Page title={page.title}>
      <PageWrap className="no-overflow-anchor" ref={toastContainerRef}>
        <Spacing size={8} position="t">
          <div className="relative text-center m-auto">
            <Heading size="s" tag="h1">
              {page.title}
            </Heading>
            <Spacing size={1} position="t">
              <div className="flex justify-center align-center mx-1 md:mx-0">
                <Paragraph
                  size="xxs"
                  color="grey-dark"
                  center
                  className="max-w-md"
                >
                  {page.description}
                </Paragraph>
              </div>
            </Spacing>
          </div>
          {isEmpty(groupedItems) ? null : (
            <Spacing size={9} position="t">
              <div className="relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-18 md:gap-x-12 lg:gap-x-16">
                {groupedItems[userCurrency]?.map((item) => {
                  const prices = [
                    {
                      iso: item.currencyIso,
                      price: item.amount,
                      default: item.currency === DEFAULT_CURRENCY
                    }
                  ];

                  return (
                    <article className="block" key={item.giftCardId}>
                      <div style={{ minHeight: '200px' }}>
                        <Image
                          src={item.url}
                          alt="Gift card"
                          width="875"
                          height="875"
                          placeholderColor={theme.colors['dark-grey']}
                        />
                      </div>
                      <Spacing size={1}>
                        <Heading size="xs" tag="h2" className="leading-relaxed">
                          {item.name}
                        </Heading>
                        <Paragraph size="xxs" color="darkest-dark-grey">
                          Everpress
                        </Paragraph>
                        <Paragraph size={['xxs', 'xs']}>
                          <Price value={prices} />
                        </Paragraph>
                      </Spacing>
                      <Button
                        size="small"
                        onClick={() => handleAddToCart(item)}
                      >
                        Add to cart
                      </Button>
                    </article>
                  );
                })}
              </div>
            </Spacing>
          )}
        </Spacing>
        <Toaster container={toastContainerRef.current} />
      </PageWrap>
    </Page>
  );
};

export default GiftCards;
