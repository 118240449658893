import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import DisclosureContext from '../DisclosureContext';

const DisclosurePanel = React.forwardRef(
  ({ as: Comp = 'div', children, ...props }, forwardedRef) => {
    const { panelId, open } = useContext(DisclosureContext);

    return (
      <Comp ref={forwardedRef} hidden={!open} id={panelId} {...props}>
        {children}
      </Comp>
    );
  }
);

export default DisclosurePanel;

DisclosurePanel.displayName = "DisclosurePanel";

DisclosurePanel.propTypes = {
  as: PropTypes.any,
  children: PropTypes.node
};
