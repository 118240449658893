import React, { useState } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { leadSignUp } from '../../../actions/user';

import { ButtonNew } from '../../../components/atoms/Button';
import Heading from '../../../components/atoms/Heading';
import {
  DefaultField as Field,
  Error,
  Form,
  handleFormError
} from '../../../components/atoms/Form';
import Spacing from '../../../components/atoms/Spacing';
import Paragraph from '../../../components/atoms/Paragraph';
import Tooltip from '../../../components/atoms/Tooltip';

import questionMarkCircle from '../../../img/sprites/question-mark-circle.svg';

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(150)
    .required('Please enter your first name'),
  lastName: yup
    .string()
    .max(150)
    .required('Please enter your second name'),
  email: yup
    .string()
    .email('The email address is invalid')
    .max(150)
    .required('Please enter your email'),
  url: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
    )
    .max(150)
    .required('Please enter your Bandcamp URL')
});

const BandcampLeadForm = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const [hasRegisterError, setRegisterError] = useState(false);

  const handleSignUp = async (values, actions) => {
    setRegisterError(false);
    actions.setSubmitting(true);

    try {
      await dispatch(leadSignUp({ source: 'Bandcamp', ...values }));

      onSuccess();
    } catch (error) {
      setRegisterError(true);
      handleFormError(error, actions);
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Heading tag="h1" size="xs">
        You can now seamlessly sell your Everpress merch through Bandcamp
      </Heading>
      <Spacing size={15} position="t">
        <Paragraph size="xxs" color="grey-dark">
          Create t-shirts, hoodies or sweats with us. Promote and sell them
          through Bandcamp. We’ll get them printed and shipped to your fans.
          It’s zero risk, zero waste.
        </Paragraph>
      </Spacing>
      <Spacing size={3} position="t">
        <Form
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            url: ''
          }}
          onSubmit={handleSignUp}
          validationSchema={validationSchema}
          render={({ isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Spacing size={15}>
                <Field name="firstName" placeholder="First name" />
              </Spacing>
              <Spacing size={15} position="t">
                <Field name="lastName" placeholder="Last name" />
              </Spacing>
              <Spacing size={15} position="t">
                <Field type="email" name="email" placeholder="Email address" />
              </Spacing>
              <Spacing size={15} position="t">
                <Field
                  name="url"
                  placeholder="Bandcamp URL"
                  endAdornment={
                    <Tooltip
                      content="The full URL of your artist profile on Bandcamp e.g. everpress.bandcamp.com."
                      glyph={questionMarkCircle}
                      glyphWidth={13}
                      glyphHeight={13}
                    />
                  }
                />
              </Spacing>
              <Spacing size={3} position="t">
                <ButtonNew
                  type="submit"
                  state={isSubmitting ? 'loading' : null}
                  fullWidth
                >
                  Sign Up
                </ButtonNew>
                {hasRegisterError ? (
                  <Spacing size={[1, 2]} position="t">
                    <Error>
                      Unable to register your details, please try again later.
                    </Error>
                  </Spacing>
                ) : null}
              </Spacing>
            </form>
          )}
        />
      </Spacing>
    </>
  );
};

BandcampLeadForm.propTypes = {
  onSuccess: PropTypes.func.isRequired
};

export default BandcampLeadForm;
