import React, { Component } from 'react';
import throttle from 'lodash/throttle';
import VisibilitySensor from 'react-visibility-sensor';

import Raf from '../../../../helpers/Raf';

import Image from './Image';
import generalStyles from './style/extra-images.module.css';

export default class ExtraImages extends Component {
  constructor() {
    super();

    this.state = {
      isVisible: false,
      storyHover: false,
      offsetDiff: 0
    };

    this.wrapRef = null;
    this.containerRef = null;

    this.followX = 0; // target value for X
    this.x = 0; // x value after applying friction
    this.factor = 10;
    this.friction = 0.09;

    this.setContainerPosition = throttle(
      this.setContainerPosition.bind(this),
      200
    );
    this.handleMouseMove = throttle(this.handleMouseMove.bind(this), 40);
    this.handleVisibility = this.handleVisibility.bind(this);

    this.RAF = new Raf();
  }

  componentDidMount() {
    this.setContainerPosition();

    window.addEventListener('resize', this.setContainerPosition);
    window.addEventListener('mousemove', this.handleMouseMove);

    this.RAF.subscribe(this.animateContainer.bind(this));
    this.RAF.start();
  }

  componentWillUnmount() {
    this.RAF.unsubscribe();
    window.removeEventListener('resize', this.setContainerPosition);
    window.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleMouseMove(e) {
    const { offsetDiff } = this.state;
    const wrapWidth = this.wrapRef.clientWidth;
    const newX = wrapWidth / 2 - e.clientX;
    const followX = (this.factor * newX) / 100;
    const max = offsetDiff / 2;
    const min = max * -1;

    this.followX = Math.min(Math.max(followX, min), max);
  }

  handleVisibility(isVisible) {
    if (isVisible) {
      this.RAF.start();
      this.setState({ isInView: true });
    } else {
      this.RAF.stop();
      this.setState({ isInView: false });
    }
  }

  setContainerPosition() {
    const wrapWidth = this.wrapRef.clientWidth;
    const totalWidth = this.containerRef.clientWidth;
    const offsetDiff = totalWidth - wrapWidth;
    const marginLeft = Math.round(offsetDiff / 2) * -1;

    this.containerRef.style.marginLeft = `${marginLeft}px`;

    this.setState({ offsetDiff });
  }

  animateContainer() {
    const { offsetDiff } = this.state;
    const newX = (this.followX - this.x) * this.friction;
    this.x += newX;

    if (offsetDiff > 0) {
      const translateX = `translateX(${this.x}px)`;
      const rotate = 'rotate(0.01deg)'; // fake Anti Aliasing on engines that don't support it (firefox for example)

      if (this.containerRef) {
        this.containerRef.style.transform = `${translateX} ${rotate}`;
      }
    }
  }

  render() {
    const { isInView, offsetDiff } = this.state;
    const { images } = this.props;

    const wrapProps = {
      ref: (ref) => {
        this.wrapRef = ref;
      }
    };

    const containerProps = {
      className: generalStyles.container,
      ref: (ref) => {
        this.containerRef = ref;
      }
    };

    const visibilitySensorProps = {
      onChange: this.handleVisibility,
      partialVisibility: true
    };

    return (
      <VisibilitySensor {...visibilitySensorProps}>
        <div {...wrapProps}>
          <div className={generalStyles.collage}>
            <div {...containerProps}>
              {images.map((item) => (
                <Image
                  key={item.image.url}
                  image={item.image.url}
                  imagesCount={images.length}
                  offsetDiff={offsetDiff}
                  isInView={isInView}
                  alt={item.image.alt}
                />
              ))}
            </div>
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}
