import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { getText } from '../../helpers/prismic';

import Page from '../../components/utils/Page';

import { Grid, GridItem } from '../../components/atoms/Grid';
import Heading from '../../components/atoms/Heading';
import PageWrap from '../../components/atoms/PageWrap';
import Paragraph from '../../components/atoms/Paragraph';
import Spacing from '../../components/atoms/Spacing';
import LiveChat from '../../components/atoms/LiveChat';

import CaseStudiesTile from '../../components/molecules/CaseStudiesTile';
import AltCta from '../../components/molecules/AltCta';

import generalStyles from './style/general.module.css';

function CaseStudies({ seoTitle, hero, stats, categories }) {
  const statsLoaded = !!Object.values(stats).filter(v => v !== null).length;

  return (
    <Page title={seoTitle}>
      <PageWrap>
        {hero && (
          <Fragment>
            <Spacing>
              <Heading tag="h2" className={generalStyles.title}>
                {hero.title}
              </Heading>
            </Spacing>
            <Grid gap={0}>
              <GridItem columnSize={[12, 12, 6]}>
                <Paragraph size="s">{hero.subtitle}</Paragraph>
              </GridItem>
            </Grid>
          </Fragment>
        )}

        {categories && (
          <Spacing position="t" size={8}>
            <Grid gap={2}>
              {categories.slice(0, 2).map((category, i) => (
                <CaseStudiesTile
                  key={i}
                  hover
                  background={category.image.url}
                  title={category.title}
                  subtitle={category.subtitle}
                  to={`/case-studies/${category.slug}`}
                />
              ))}
            </Grid>
          </Spacing>
        )}

        {statsLoaded && (
          <Spacing size={4}>
            <div className={generalStyles.stats}>
              <Spacing>
                <Heading className={generalStyles.title}>{stats.stat1}</Heading>
                <Spacing size={1}>
                  <Heading size="s">{stats.stat1subtitle}</Heading>
                </Spacing>
              </Spacing>
            </div>
          </Spacing>
        )}

        {categories && (
          <Grid gap={2}>
            {categories.slice(2, 4).map((category, i) => (
              <CaseStudiesTile
                key={i}
                hover
                background={category.image.url}
                title={category.title}
                subtitle={category.subtitle}
                to={`/case-studies/${category.slug}`}
              />
            ))}
          </Grid>
        )}

        {statsLoaded && (
          <Spacing size={4}>
            <div className={generalStyles.stats}>
              <Spacing>
                <Heading className={generalStyles.title}>{stats.stat2}</Heading>
                <Spacing size={1}>
                  <Heading size="s">{stats.stat2subtitle}</Heading>
                </Spacing>
              </Spacing>
            </div>
          </Spacing>
        )}

        {hero && statsLoaded && categories && <AltCta />}
      </PageWrap>

      <LiveChat />
    </Page>
  );
}

const mapStateToProps = ({ pages }) => {
  const data = pages.case_studies_landing.data || {};

  return {
    seoTitle: data.seo_title,
    hero: {
      title: getText(data.title),
      subtitle: getText(data.subtitle)
    },
    categories:
      data.categories &&
      data.categories.map(item => {
        return {
          slug: item.category.uid,
          title: getText(item.category.data.title),
          subtitle: getText(item.category.data.subtitle),
          description: getText(item.category.data.description),
          image: item.category.data.image
        };
      }),
    stats: {
      stat1: getText(data.stat_1_number),
      stat1subtitle: getText(data.stat_1_subtitle),
      stat2: getText(data.stat_2_number),
      stat2subtitle: getText(data.stat_2_subtitle)
    }
  };
};

export default connect(mapStateToProps)(CaseStudies);
