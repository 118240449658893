import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import isEmpty from 'lodash/isEmpty';
import chunk from 'lodash/chunk';
import debounce from 'lodash/debounce';

import { saveUserProfilePatch } from '../../actions/user-profile';

import {
  getLiveCampaigns,
  getEndedLaunchedCampaigns
} from '../../helpers/selectors/cart';

import {
  getVisibileCampaigns,
  getHiddenCampaigns
} from '../../helpers/profile';

import Page from '../../components/utils/Page';
import Loading from '../../components/utils/Loading';

import Paragraph from '../../components/atoms/Paragraph';
import PageWrap from '../../components/atoms/PageWrap';
import { TabNew as Tab, TabsList } from '../../components/atoms/Tabs';
import MaxWidth from '../../components/atoms/MaxWidth';

import Pagination from '../../components/molecules/Pagination';

import CreatorCampaigns from './CreatorCampaigns';
import UserProfileInfo from './UserProfileInfo';
import ImageSettings from './ImageSettings';

import EmptyProfile from './EmptyProfile';
import Spacing from '../../components/atoms/Spacing';

import styles from './styles/general.module.css';
import { variants } from './variants';

const CAMPAIGNS_PER_PAGE = 9;

const tabs = {
  LIVE: 'live',
  ENDED: 'ended',
  HIDDEN: 'hidden'
};

const Profile = ({ locationSlug }) => {
  const dispatch = useDispatch();
  const [isInitialRender, setInitialRender] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState(tabs.LIVE);
  const [isEditing, setEditing] = useState(false);
  const [isBannerUploaderVisible, setBannerUploaderVisible] = useState(false);
  const [isPictureUploaderVisible, setPictureUploaderVisible] = useState(false);
  const [isUploaderModalOpen, setUploaderModalOpen] = useState(false);
  const isProfileLoading = useSelector((state) => state.profile.isLoading);
  const isProfileCampaignsLoading = useSelector(
    (state) => state.profile.campaignsBySlug.isLoading
  );
  const slug = useSelector((state) => state.profile.slug);
  const [isOwner, setIsOwner] = useState(slug === locationSlug);
  const profile = useSelector((state) =>
    isOwner ? state.profile : state.profile.bySlug[locationSlug] || {}
  );

  const campaigns = useSelector(
    (state) => state.profile.campaignsBySlug[locationSlug] || []
  );

  const hiddenCampaigns = useMemo(() => getHiddenCampaigns(campaigns), [
    campaigns
  ]);

  const visibleCampaigns = useMemo(() => getVisibileCampaigns(campaigns), [
    campaigns
  ]);

  const liveVisibleCampaigns = getLiveCampaigns(visibleCampaigns);
  const endedVisibleCampaigns = getEndedLaunchedCampaigns(visibleCampaigns);

  useEffect(() => {
    setIsOwner(slug === locationSlug);
  }, [slug, locationSlug]);

  useEffect(() => {
    if (!isInitialRender) return;

    if (!isEmpty(liveVisibleCampaigns)) {
      setActiveTab(tabs.LIVE);
      setInitialRender(false);
      return;
    }

    if (!isEmpty(endedVisibleCampaigns)) {
      setActiveTab(tabs.ENDED);
      setInitialRender(false);
      return;
    }

    setActiveTab(tabs.HIDDEN);
  }, [liveVisibleCampaigns, endedVisibleCampaigns, isInitialRender]);

  useEffect(() => {
    if (!isUploaderModalOpen) {
      setBannerUploaderVisible(false);
      setPictureUploaderVisible(false);
    }
  }, [isUploaderModalOpen]);

  const handleTabChange = (tab) => {
    setCurrentPage(1);
    setActiveTab(tab);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleProfileEditToggle = (state) => {
    setEditing(state);
  };

  const handleImageUpload = () => {
    setBannerUploaderVisible(false);
    setPictureUploaderVisible(false);
    setUploaderModalOpen(false);
  };

  const handleProfileEdit = useCallback(
    (data) => {
      const payload = {
        slug: profile.slug,
        ...data
      };
      dispatch(saveUserProfilePatch(payload));

      setEditing(false);
    },
    [dispatch, profile]
  );

  const debouncedHandleProfileEdit = useMemo(
    () => debounce(handleProfileEdit, 1000),
    [handleProfileEdit]
  );

  const hasCampaigns = isOwner
    ? visibleCampaigns.length || hiddenCampaigns.length
    : visibleCampaigns.length;

  const activeCampaigns =
    activeTab === tabs.LIVE
      ? liveVisibleCampaigns
      : activeTab === tabs.ENDED
      ? endedVisibleCampaigns
      : activeTab === tabs.HIDDEN
      ? hiddenCampaigns
      : [];

  const chunkedActiveCampaigns = chunk(activeCampaigns, CAMPAIGNS_PER_PAGE);

  const isLoading = isProfileLoading || isProfileCampaignsLoading;

  const { profileName } = profile;

  const pageTitle = profileName
    ? `Campaigns by ${profileName}`
    : 'Creator campaigns';

  return isLoading ? (
    <Loading />
  ) : (
    <Page title={pageTitle}>
      <PageWrap>
        <div className={styles.profileWrapper}>
          {isOwner && (
            <div
              className={styles.bannerHoverArea}
              onMouseEnter={() =>
                isEditing ? undefined : setBannerUploaderVisible(true)
              }
              onMouseLeave={() =>
                isUploaderModalOpen
                  ? undefined
                  : setBannerUploaderVisible(false)
              }
            >
              <AnimatePresence initial={false}>
                {isBannerUploaderVisible && (
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={variants}
                    className={styles.bannerImageSettings}
                  >
                    <ImageSettings
                      type="banner"
                      profileId={profile.profileId}
                      onModalToggle={(val) => setUploaderModalOpen(val)}
                      onUpload={handleImageUpload}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}
          {profile.profileBanner && (
            <div
              className={styles.profileBanner}
              style={{
                background: `center/cover url('${profile.profileBanner}')`
              }}
            ></div>
          )}
          {isOwner && (
            <div
              className={
                profile.profileBanner
                  ? styles.pictureHoverAreaOffset
                  : styles.pictureHoverArea
              }
              onMouseEnter={() =>
                isEditing ? undefined : setPictureUploaderVisible(true)
              }
              onMouseLeave={() =>
                isUploaderModalOpen
                  ? undefined
                  : setPictureUploaderVisible(false)
              }
            >
              <AnimatePresence initial={false}>
                {isPictureUploaderVisible && (
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={variants}
                    className={styles.pictureImageSettings}
                  >
                    <ImageSettings
                      type="picture"
                      profileId={profile.profileId}
                      onModalToggle={(val) => setUploaderModalOpen(val)}
                      onUpload={handleImageUpload}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}
          <UserProfileInfo
            profile={profile}
            isOwner={isOwner}
            onEdit={debouncedHandleProfileEdit}
            onToggle={handleProfileEditToggle}
          />
        </div>
        <div className={styles.campaignsWrapper}>
          {hasCampaigns ? (
            <>
              <Spacing size={[15, 15, 0]} position="t">
                <TabsList
                  id="campaign-state-tabs"
                  direction="horizontal"
                  onChange={handleTabChange}
                  selectedTabId={activeTab}
                  justify="start"
                >
                  <Tab id={tabs.LIVE}>Live</Tab>
                  <Tab id={tabs.ENDED}>Ended</Tab>
                  {isOwner && <Tab id={tabs.HIDDEN}>Hidden</Tab>}
                </TabsList>
              </Spacing>
              {isEmpty(activeCampaigns) && (
                <Paragraph
                  className="py-7 md:py-5 px-6"
                  size="xxs"
                  color="grey-dark"
                  center
                >
                  You do not currently have any {activeTab} campaigns.
                </Paragraph>
              )}
              {activeTab === tabs.HIDDEN && !isEmpty(activeCampaigns) && (
                <Paragraph
                  className="py-7 md:py-5 px-6"
                  size="xxs"
                  color="grey-dark"
                  center
                >
                  These campaigns are not shown publicly on your profile.
                </Paragraph>
              )}
              <CreatorCampaigns
                profileName={profileName}
                isOwner={isOwner}
                campaigns={chunkedActiveCampaigns[currentPage - 1] || []}
              />
              {!isEmpty(activeCampaigns) && (
                <Spacing size={[2, 2, 4]}>
                  <MaxWidth value={300} center>
                    <Pagination
                      total={activeCampaigns.length}
                      pageSize={CAMPAIGNS_PER_PAGE}
                      currentPage={currentPage}
                      onChange={handlePageChange}
                    />
                  </MaxWidth>
                </Spacing>
              )}
            </>
          ) : (
            <EmptyProfile profileName={profileName} isOwner={isOwner} />
          )}
        </div>
      </PageWrap>
    </Page>
  );
};

const mapStateToProps = ({ location }) => ({
  locationSlug: location.payload.slug
});

export default connect(mapStateToProps)(Profile);
