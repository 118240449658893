import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from "@sentry/react";
import isEmpty from 'lodash/isEmpty';

import config from '../../../config';

import { theme } from '../../../theme-config';

import Image from '../../../components/atoms/Image';

import ProductThumbnail from '../../../components/molecules/Thumbnails/ProductThumbnail';
import { Rail, RailSlide } from '../../../components/molecules/Rail';
import { ButtonNew as Button } from '../../../components/atoms/Button';

const DEFAULT_CURRENCY = 'GBP';

const GiftCards = ({ items = [], onAddToCart }) => {
  const [loadingItemId, setLoadingItemId] = useState(null);
  const dispatch = useDispatch();

  const handleRailItemClick = () => {
    dispatch({
      type: 'RAIL_CAMPAIGN_CLICK',
      payload: {
        type: 'Gift Cards'
      }
    });
  };

  const handleAddToCart = async (item) => {
    try {
      setLoadingItemId(item.giftCardId);

      await onAddToCart?.(item);
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setLoadingItemId(null);
    }
  };

  if (isEmpty(items)) return null;

  return (
    <Rail type="Gift Cards" title="Gift Cards" withControls={false}>
      {items.map((item) => {
        const prices = [
          {
            iso: item.currencyIso,
            price: item.amount,
            default: item.currency === DEFAULT_CURRENCY
          }
        ];

        const isLoading = loadingItemId === item.giftCardId;

        return (
          <RailSlide key={item.giftCardId}>
            <div className="py-15" onClick={handleRailItemClick}>
              <ProductThumbnail
                image={
                  <Image
                    src={item.url}
                    alt={item.name}
                    width="875"
                    height={875 / config.productShotsRatio}
                    placeholderColor={theme.colors['dark-grey']}
                  />
                }
                title={item.name}
                subTitle="Everpress"
                price={prices}
                slug="gift-cards"
                isGreyBg={true}
              />
              <div className="mt-1">
                <Button
                  size="small"
                  onClick={() => handleAddToCart(item)}
                  state={isLoading ? 'loading' : undefined}
                >
                  Add to cart
                </Button>
              </div>
            </div>
          </RailSlide>
        );
      })}
    </Rail>
  );
};

export default GiftCards;
