import React from 'react';
import { connect } from 'react-redux';

import * as campaignsActions from '../../../actions/campaigns';

import ProductShot from './ProductShot';

import { theme } from '../../../theme-config';

class ProductShotContainer extends React.Component {
  componentWillMount() {
    const { campaign, pathname } = this.props;
    const isCartProductShot = pathname === '/cart';

    if (campaign.draft || (!isCartProductShot && !campaign.imagesReady)) {
      if (!campaign.artworks) {
        this.props.getCampaignArtworksPositions(campaign.campaignId);
      }

      if (!campaign.catalogue) {
        this.props.getCampaignGarments(campaign.campaignId);
      }
    }
  }

  renderDraftImage(side, colour, bgColor, hasContainerPadding) {
    const { item, campaign, width, height } = this.props;
    const { artworks, catalogue } = campaign;

    const garment =
      catalogue &&
      catalogue.find((garment) => garment.sku === item.product.sku);
    const garmentAreas = garment && garment.areas;
    const maskImage =
      garmentAreas &&
      garmentAreas.find((garmentArea) => garmentArea.slug === side).greyUrl;

    return (
      <ProductShot
        garmentImageUrl={maskImage}
        color={`#${colour.code}`}
        bgColor={bgColor}
        artwork={artworks && artworks.find((artwork) => artwork.area === side)}
        garmentArea={
          garmentAreas &&
          garmentAreas.find((garmentArea) => garmentArea.slug === side)
        }
        width={width}
        height={height}
        hasContainerPadding={hasContainerPadding}
      />
    );
  }

  render() {
    const {
      item,
      campaign,
      colourId,
      width,
      height,
      overrideImageUrl,
      hasContainerPadding = true
    } = this.props;

    const { images, defaultSide } = campaign;
    const side = this.props.side || defaultSide;
    const colour = item.colours.find((colour) => colour.colourId === colourId);

    let bgColor = theme.colors['dark-grey'];

    if (overrideImageUrl) {
      return (
        <ProductShot
          width={width}
          height={height}
          overrideImageUrl={overrideImageUrl}
          bgColor={bgColor}
          hasContainerPadding={hasContainerPadding}
        />
      );
    } else if (!images || !images.length) {
      return this.renderDraftImage(side, colour, bgColor, hasContainerPadding);
    } else {
      let shots = images.filter((image) => image.type === 'grey');

      // Fallback to `white` if there are no `grey` images
      if (!shots.length) {
        shots = images.filter((image) => image.type === 'white');
        bgColor = theme.colors.white;
      }

      const shot = shots.find(
        (image) =>
          image.campaignProductId === item.campaignProductId &&
          image.colourId === colourId &&
          image.area === side
      );

      /**
       * ! `shot` is sometimes undefined when  campaign.images is missing.
       * TODO: Refactor everything related to ProductShots.
       */
      return shot ? (
        <ProductShot
          garmentImageUrl={shot.url}
          color={`#${colour.code}`}
          bgColor={bgColor}
          width={width}
          height={height}
          hasContainerPadding={hasContainerPadding}
          alt={shot.alt}
        />
      ) : (
        <div style={{ width, height, backgroundColor: bgColor }} />
      );
    }
  }
}

const mapStateToProps = ({ location }) => ({ pathname: location.pathname });

export default connect(mapStateToProps, {
  ...campaignsActions
})(ProductShotContainer);
