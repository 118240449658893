import React, { Fragment } from 'react';

import Item from '../Item';

import buyersItems from '../buyersItems';

export default function Buyers() {
  return (
    <Fragment>
      {buyersItems.map((item, index) => {
        const newIndex = `0${index + 1}`;

        return <Item key={item.id} {...item} index={newIndex} />;
      })}
    </Fragment>
  );
}
