import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { theme } from '../../../../theme-config';

import { updateCampaign } from '../../../../actions/campaign-builder';

import Heading from '../../../../components/atoms/Heading';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';
import Icon from '../../../../components/atoms/Icon';
import Link from '../../../../components/atoms/Link';
import { PillNew as Pill } from '../../../../components/atoms/Pill';

import Toggle from '../../../../components/molecules/Toggle';

import generalStyles from './style/general.module.css';

import tickIcon from '../../../../img/sprites/check.svg';

const AdsNetwork = () => {
  const dispatch = useDispatch();
  const promoOptIn = useSelector((state) => state.campaignBuilder.promoOptIn);

  const handleOptInToggle = async (promoOptIn) => {
    await dispatch(updateCampaign({ promoOptIn }));
  };

  return (
    <div>
      <div className={generalStyles.pill}>
        <Pill size="xxxs" kind="success">
          70% more sales on average
        </Pill>
      </div>
      <Heading tag="h4" size="xs">
        Join the Everpress Promotional Network
      </Heading>
      <Spacing size={1} position="t">
        <Paragraph size="xxs">
          The Everpress Promotional Network can help you reach more people by
          making your campaign eligible to be featured in our shop, collections,
          social media channels and paid advertising.
        </Paragraph>
      </Spacing>
      <Spacing size={1} position="t">
        <Link href="/creator-toolkit/ad-network" className="text-xxs">
          How it works
        </Link>
      </Spacing>

      <div className="relative">
        <Spacing size={3}>
          <hr className="border-grey-lighter" />
        </Spacing>
      </div>

      <div className={generalStyles.grid}>
        <div className="mt-px">
          <Icon
            glyph={tickIcon}
            width={18}
            height={18}
            fill={theme.extend.colors.green.primary}
          />
        </div>
        <Paragraph size="xxs">
          On average, campaigns opted in to the Everpress Promotional Network{' '}
          <span className="text-green-primary">get 70% more sales</span>. It’s
          risk-free and free of charge.
        </Paragraph>
        <div className="mt-px">
          <Icon
            glyph={tickIcon}
            width={18}
            height={18}
            fill={theme.extend.colors.green.primary}
          />
        </div>
        <Paragraph size="xxs">
          For any sales that are driven through our network, we split the
          profits 50/50 with you. For all sales made outside of the network, you
          still make 100% of the profit.
        </Paragraph>
      </div>

      <div className="relative">
        <Spacing size={3}>
          <hr className="border-grey-lighter" />
        </Spacing>
      </div>

      <div className={generalStyles.cta}>
        <Paragraph size="xxs" className="uppercase">
          Activate for this campaign
        </Paragraph>

        <Toggle
          name="promoOptInToggle"
          value={promoOptIn}
          onChange={handleOptInToggle}
          inlineGridClassName="justify-end"
          activeColour="green"
        />
      </div>
    </div>
  );
};

export default AdsNetwork;
