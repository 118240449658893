import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../atoms/Link';
import Paragraph from '../../../atoms/Paragraph';
import { CSSGrid, CSSGridItem } from '../../../atoms/Grid';

import generalStyles from './style/general.module.css';

export default function CtaBox({ title, cta, onClick, children }) {
  return (
    <div className={generalStyles.box}>
      <CSSGrid template="none / repeat(6, 1fr)">
        <CSSGridItem row={{ span: 'full' }} column={{ span: cta ? 5 : 'full' }}>
          {title && (
            <Paragraph color="grey-dark" size="xxxs">
              {title}
            </Paragraph>
          )}
          {typeof children === 'string' ? (
            <Paragraph size="xxs">{children}</Paragraph>
          ) : (
            children
          )}
        </CSSGridItem>
        {cta && (
          <CSSGridItem
            className="text-right"
            row={{ span: 'full' }}
            column={{ span: 1 }}
          >
            <Link
              className="text-xxxs h-full"
              isUnderlined={false}
              onClick={onClick}
            >
              {cta}
            </Link>
          </CSSGridItem>
        )}
      </CSSGrid>
    </div>
  );
}

CtaBox.propTypes = {
  title: PropTypes.string,
  cta: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node
};
