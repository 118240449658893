import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { theme } from '../../../../theme-config';

import WrapIf from '../../../utils/WrapIf';

import { Grid, GridItem, InlineGrid } from '../../../atoms/Grid';
import Heading from '../../../atoms/Heading';
import Icon from '../../../atoms/Icon';
import Link from '../../../atoms/Link';
import Spacing from '../../../atoms/Spacing';

import { AccordionContext } from '../index';

import chevronDownIcon from '../../../../img/sprites/chevron-down.svg';

import generalStyles from './style/general.module.css';
import sizeStyles from './style/size.module.css';

const cx = classNames.bind({ ...generalStyles, ...sizeStyles });

const AccordionElement = ({
  label,
  value,
  externalToggle,
  size,
  disabled,
  arrows,
  className,
  labelTitle,
  labelColumnSize,
  children
}) => {
  const { index, toggleActive, activeIndexes } = useContext(AccordionContext);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (typeof externalToggle !== 'undefined') {
      setActive(externalToggle);
    } else {
      setActive(activeIndexes.indexOf(index) >= 0);
    }
  }, [activeIndexes, index, externalToggle]);

  const toggleClassName = label ? cx(size, className) : className;
  const linkClassName = cx('toggle', {
    'w-full': value
  });

  return (
    <>
      <WrapIf
        condition={!disabled && label}
        wrapper={(wrappedChildren) => (
          <Link
            className={linkClassName}
            isUnderlined={false}
            noHover
            title={labelTitle}
            onClick={() => {
              if (typeof externalToggle === 'undefined') toggleActive(index);
            }}
          >
            {wrappedChildren}
          </Link>
        )}
      >
        <div className={toggleClassName}>
          <Grid gap={0} justify="space-between">
            {label && (
              <GridItem columnSize={labelColumnSize}>
                <InlineGrid align="center">
                  {typeof label === 'string' ? (
                    <Heading size="xs" className="leading-normal">
                      {label}
                    </Heading>
                  ) : (
                    label
                  )}
                  {arrows && (
                    <Spacing size="05" position="l">
                      <Icon
                        glyph={chevronDownIcon}
                        fill={theme.extend.colors.black}
                        width="17"
                        height="17"
                        className={classNames(generalStyles.icon, {
                          [generalStyles.iconInactive]: !active
                        })}
                      />
                    </Spacing>
                  )}
                </InlineGrid>
              </GridItem>
            )}
            {value && (
              <GridItem columnSize="auto">
                <InlineGrid>{value}</InlineGrid>
              </GridItem>
            )}
          </Grid>
        </div>
      </WrapIf>
      {active && children}
    </>
  );
};

AccordionElement.defaultProps = {
  size: 's',
  disabled: false,
  arrows: false,
  labelColumnSize: 'auto'
};

AccordionElement.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelTitle: PropTypes.string,
  labelColumnSize: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string
  ]),
  externalToggle: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.string,
  disabled: PropTypes.bool,
  arrows: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};

export default AccordionElement;
