import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { GridItem } from '../../../atoms/Grid';
import Spacing from '../../../atoms/Spacing';

import propToClassName from '../../../../helpers/propToClassName';

import { TableContext } from '../';

import generalStyles from './style/general.module.css';

const borderMap = {
  t: 'border-t-1',
  r: 'border-r-1',
  b: 'border-b-1',
  l: 'border-l-1'
};

const TableItem = ({
  children,
  columnSize,
  outerBorder,
  border,
  paddingSize,
  id
}) => {
  const outerBorderClass = outerBorder
    ? propToClassName(outerBorder, borderMap)
    : '';
  const borderClass = border ? propToClassName(border, borderMap) : '';
  const borderClassName = classNames(generalStyles.border, borderClass);
  const cPaddingSize =
    typeof paddingSize !== 'object'
      ? { x: paddingSize, y: paddingSize }
      : { ...TableItem.defaultProps.paddingSize, ...paddingSize };

  return (
    <GridItem columnSize={columnSize} className={outerBorderClass}>
      <Spacing size={cPaddingSize.y} position="y" type="padding">
        <Spacing size={cPaddingSize.x} position="x" type="padding">
          <div id={id} className={borderClassName}>
            {children}
          </div>
        </Spacing>
      </Spacing>
    </GridItem>
  );
};

export default (props) => (
  <TableContext.Consumer>
    {(context) =>
      context ? (
        <TableItem {...context.ItemProps} {...props} />
      ) : (
        <TableItem {...props} />
      )
    }
  </TableContext.Consumer>
);

TableItem.propTypes = {
  children: PropTypes.node.isRequired,
  columnSize: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  border: PropTypes.string,
  paddingSize: PropTypes.any,
  id: PropTypes.string
};

TableItem.defaultProps = {
  hasNoPad: false,
  border: null,
  paddingSize: {
    x: 2,
    y: 2
  }
};
