import React from 'react';

import MaxWidth from '../../../../components/atoms/MaxWidth';
import Tooltip from '../../../../components/atoms/Tooltip';

const preorderTooltipContent = (
  <MaxWidth value={300}>
    We hate waste. We take pre-orders and we only make exactly what we sell.
    Once a campaign has ended it will be unavailable to purchase unless it relaunches in the future.
  </MaxWidth>
);

export const PreorderTooltip = ({ children, ...props }) => {
  return (
    <Tooltip placement="top" content={preorderTooltipContent} {...props}>
      {children}
    </Tooltip>
  );
};

