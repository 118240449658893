import React from 'react';

// Snippet taken from MDN: https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API

// Set the name of the hidden property and the change event for visibility
let hidden, visibilityChangeEventName;

if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChangeEventName = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChangeEventName = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChangeEventName = 'webkitvisibilitychange';
}

export default class PageVisibilityTracker extends React.Component {
  constructor() {
    super();

    // No point of doing anything if not rendered in a browser env
    if (!window) {
      return;
    }

    this.state = {
      isPageVisible: true
    };

    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);

    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (
      typeof document.addEventListener !== 'undefined' ||
      hidden !== undefined
    ) {
      // Handle page visibility change
      document.addEventListener(
        visibilityChangeEventName,
        this.handleVisibilityChange,
        false
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      visibilityChangeEventName,
      this.handleVisibilityChange
    );
  }

  handleVisibilityChange() {
    this.setState({ isPageVisible: !document[hidden] });
  }

  render() {
    return this.props.children(this.state.isPageVisible);
  }
}
