const getInitials = (name) => {
  const array = name.split(' ');
  const initials = array.map((letter, index) => {
    if (index >= 2) return null;
    return letter.charAt(0);
  });

  return initials.join('');
};

export default getInitials;
