import produce from 'immer';

const initialState = {
  bySlug: {}
};

export default (state = initialState, action) =>
  produce(state, newState => {
    switch (action.type) {
      case 'STORE_RECEIVED':
        newState.bySlug[action.payload.slug] = action.payload;
        return newState;
      default:
        return state;
    }
  });
