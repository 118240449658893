import React from 'react';

import { Grid, GridItem } from '../../../atoms/Grid';
import Heading from '../../../atoms/Heading';
import MaxWidth from '../../../atoms/MaxWidth';
import PageWrap from '../../../atoms/PageWrap';
import Spacing from '../../../atoms/Spacing';

import generalStyles from './style/general.module.css';

const TextBlock = ({ label, title, copy }) => {
  return (
    <PageWrap>
      <MaxWidth value={876} center>
        <Spacing size={[2, 4, 6]} type="padding">
          <Grid gap={2} align="top">
            <GridItem columnSize={[12, 6]}>
              <Spacing size={[2, 0]} position="b">
                <Heading tag="h5" size={'xxs'}>
                  {label}
                </Heading>
                <Heading
                  className={generalStyles.breakWord}
                  tag="h2"
                  size={['m', 'l', 'xl', 'xxl']}
                >
                  {title}
                </Heading>
              </Spacing>
            </GridItem>
            <GridItem columnSize={[12, 6]}>
              <Spacing size={[0, 2]} position="t">
                <div className={generalStyles.description}>{copy}</div>
              </Spacing>
            </GridItem>
          </Grid>
        </Spacing>
      </MaxWidth>
    </PageWrap>
  );
};

export default TextBlock;
