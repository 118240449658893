import React from 'react';
import PropTypes from 'prop-types';

import { SelectNew } from '../Select';
import { DefaultField } from '../Form';

import coloursStyles from './style/colours.module.css';

class SelectColours extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired
  };

  renderOption(option) {
    return (
      <div className={coloursStyles.container}>
        <span
          className={coloursStyles.colours}
          style={{ backgroundColor: `#${option.code}` }}
        />
        {option.label}
      </div>
    );
  }

  render() {
    const {
      data,
      value,
      standalone,
      scrollToError,
      children,
      ...props
    } = this.props;
    const options = data.map((colour) => ({
      value: colour.colourId,
      label: colour.name,
      code: colour.code
    }));

    if (standalone) {
      return (
        <SelectNew
          options={options}
          {...props}
          value={value}
          optionRenderer={this.renderOption.bind(this)}
          valueRenderer={this.renderOption.bind(this)}
        />
      );
    } else {
      return (
        <DefaultField
          type="select"
          options={options}
          optionRenderer={this.renderOption.bind(this)}
          valueRenderer={this.renderOption.bind(this)}
          scrollToError={scrollToError}
          {...props}
        >
          {children}
        </DefaultField>
      );
    }
  }
}

export default SelectColours;
