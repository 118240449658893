import React from 'react';

import Page from '../../components/utils/Page';

import Heading from '../../components/atoms/Heading';
import { ListWrapper, ListItem } from '../../components/atoms/List';
import PageWrap from '../../components/atoms/PageWrap';
import Paragraph from '../../components/atoms/Paragraph';
import MaxWidth from '../../components/atoms/MaxWidth';
import Spacing from '../../components/atoms/Spacing';
import LiveChat from '../../components/atoms/LiveChat';
import Image from '../../components/atoms/Image';

import htmlParser from '../../helpers/htmlParser';

import adidas from '../../img/pages/ippolicy/adidas.jpg';
import addicted from '../../img/pages/ippolicy/addicted.png';
import rcbPolo from '../../img/pages/ippolicy/rcb-polo.png';
import ldnr from '../../img/pages/ippolicy/ldnr.jpg';
import rihanna from '../../img/pages/ippolicy/rihanna.jpg';
import nike from '../../img/pages/ippolicy/nike.jpg';
import trunki from '../../img/pages/ippolicy/trunki.jpg';

import data from './data';

export default function IntellectualPropertyPolicy() {
  const paragraphSpacing = 2;
  const paragraphSize = 's';
  const headingSize = 'm';
  const headingTag = 'h3';
  const imgSources = {
    adidas: adidas,
    addicted: addicted,
    rcbPolo: rcbPolo,
    ldnr: ldnr,
    rihanna: rihanna,
    nike: nike,
    trunki: trunki
  };

  return (
    <Page title="Intellectual Property Policy">
      <PageWrap>
        <MaxWidth value={877} center>
          <Spacing>
            <Heading center tag="h2" size="xl">
              Guidance for creators on our Intellectual Property Policy
            </Heading>
          </Spacing>
          {data.map((item) => (
            <Spacing key={item.title} size={5}>
              <Heading tag={headingTag} size={headingSize}>
                {item.title}
              </Heading>
              {item.sections.map((section, index) => {
                if (section.type === 'paragraph') {
                  return (
                    <Spacing key={index} size={paragraphSpacing}>
                      <Paragraph size={paragraphSize}>
                        {section.title && (
                          <Spacing size={1}>
                            <Heading tag="h4" size="xs">
                              {section.title}
                            </Heading>
                          </Spacing>
                        )}
                        {htmlParser(section.content)}
                      </Paragraph>
                    </Spacing>
                  );
                } else if (section.type === 'list') {
                  return (
                    <Spacing key={index} size={paragraphSpacing}>
                      <ListWrapper>
                        {section.content.map((listItem, index) => (
                          <ListItem key={index}>
                            {htmlParser(listItem)}
                          </ListItem>
                        ))}
                      </ListWrapper>
                    </Spacing>
                  );
                } else {
                  return (
                    <div className="flex flex-wrap justify-center">
                      <Spacing
                        key={index}
                        size={paragraphSpacing}
                        position="all"
                      >
                        <Image
                          withPlaceholder={false}
                          src={imgSources[section.content]}
                          width="100px"
                          height="100px"
                        />
                      </Spacing>
                    </div>
                  );
                }
              })}
            </Spacing>
          ))}
        </MaxWidth>
      </PageWrap>
      <LiveChat />
    </Page>
  );
}
