import React, { useState } from 'react';
import throttle from 'lodash/throttle';
import { useDispatch } from 'react-redux';

import { deleteUserFavourite } from '../../../actions/user';

import Alert from '../../../components/atoms/Alert';
import { CSSGridItem } from '../../../components/atoms/Grid';
import Spacing from '../../../components/atoms/Spacing';

import FavouriteThumbnail from '../../../components/molecules/Thumbnails/FavouriteThumbnail';

import generalStyles from './style/general.module.css';

const FavouriteItem = ({
  favourite: {
    userFavouriteId,
    campaign: {
      campaignId,
      endAt,
      imageUrl,
      prices,
      slug,
      title,
      imageAlt,
      subTitle,
      user: { profileName = subTitle }
    }
  }
}) => {
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const handleDelete = async () => {
    setError(null);

    try {
      await dispatch(deleteUserFavourite({ userFavouriteId, campaignId }));
    } catch (e) {
      setError('Something went wrong');
    }
  };

  return (
    <CSSGridItem
      row={{ start: 'auto', end: 'auto' }}
      column={{ start: 'auto', end: 'auto' }}
      data-test-id="favourite-item"
    >
      <Spacing size={1} position="b">
        <div className={generalStyles.container}>
          <FavouriteThumbnail
            slug={slug}
            endAt={endAt}
            image={imageUrl}
            title={title}
            profileName={profileName}
            prices={prices}
            alt={imageAlt}
            onDelete={throttle(handleDelete, 1000, { trailing: false })}
          />
          {error && (
            <div className={generalStyles.error}>
              <Alert message={error} />
            </div>
          )}
        </div>
      </Spacing>
    </CSSGridItem>
  );
};

export default FavouriteItem;
