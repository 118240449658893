import React from 'react';
import PropTypes from 'prop-types';

import AuthenticationContainer from '../../../../components/molecules/AuthenticationContainer';
import { ModalNew as Modal } from '../../../../components/molecules/Modal';

const AuthModal = ({ isOpen, onClose, onSuccess }) => {
  return (
    <Modal isOpen={isOpen} handleClose={onClose}>
      <AuthenticationContainer
        initialUserStage="signUp"
        onSuccess={onSuccess}
      />
    </Modal>
  );
};

AuthModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default AuthModal;
