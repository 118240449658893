import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import propToClassName from '../../../../helpers/propToClassName';

const WIDTH_MAP = {
  auto: 'w-auto',
  full: 'w-full'
};

const HEIGHT_MAP = {
  auto: 'h-auto',
  full: 'h-full'
};

const mergeProps = (type, position, size) => {
  if (Array.isArray(size)) {
    return size.map((item) => `${type}-${position}-${item}`);
  } else {
    return `${type}-${position}-${size}`;
  }
};

const mapPropsToClassNames = (name, positionObj) => {
  let mappedClassNames = [];
  Object.keys(positionObj).forEach((key) =>
    mappedClassNames.push(
      propToClassName(mergeProps(name, key, positionObj[key]))
    )
  );
  return mappedClassNames;
};

const CSSGridItem = ({
  className,
  children,
  row,
  column,
  width,
  height,
  ...props
}) => {
  const rowClassNames = mapPropsToClassNames('row', row);
  const columnClassNames = mapPropsToClassNames('col', column);
  const widthClassNames = propToClassName(width, WIDTH_MAP);
  const heightClassNames = propToClassName(height, HEIGHT_MAP);
  const gridItemClassName = classNames(
    className,
    rowClassNames,
    columnClassNames,
    widthClassNames,
    heightClassNames
  );
  return (
    <div className={gridItemClassName} {...props}>
      {children}
    </div>
  );
};

export default CSSGridItem;

CSSGridItem.defaultProps = {
  width: 'full',
  height: 'full'
};

const trackPropTypes = {
  start: PropTypes.oneOfType([
    PropTypes.oneOf(['auto']),
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.oneOf(['auto']), PropTypes.number])
    )
  ]),
  end: PropTypes.oneOfType([
    PropTypes.oneOf(['auto']),
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.oneOf(['auto']), PropTypes.number])
    )
  ]),
  span: PropTypes.oneOfType([
    PropTypes.oneOf(['full']),
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.oneOf(['full']), PropTypes.number])
    )
  ])
};

CSSGridItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  row: PropTypes.shape({ ...trackPropTypes }).isRequired,
  column: PropTypes.shape({ ...trackPropTypes }).isRequired,
  width: PropTypes.oneOf(['auto', 'full']),
  height: PropTypes.oneOf(['auto', 'full'])
};
