import React, { PureComponent } from 'react';

import Page from '../../components/utils/Page';

import Heading from '../../components/atoms/Heading';
import { ListItem, ListWrapper } from '../../components/atoms/List';
import PageWrap from '../../components/atoms/PageWrap';
import Paragraph from '../../components/atoms/Paragraph';
import MaxWidth from '../../components/atoms/MaxWidth';
import Spacing from '../../components/atoms/Spacing';
import Link from '../../components/atoms/Link';
import LiveChat from '../../components/atoms/LiveChat';

export default class Terms extends PureComponent {
  render() {
    const paragraphSpacing = 2;
    const paragraphSize = 's';
    const headingSize = 'm';
    const headingTag = 'h3';

    return (
      <Page title="Terms of Use">
        <PageWrap>
          <MaxWidth value={877} center>
            <Spacing>
              <Heading center tag="h2" size="xxl">
                Terms of use
              </Heading>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING
                THIS SITE
              </Heading>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                Website terms of use
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  Everpress.com (“our site”) is a site where creators can submit
                  designs for application by us to products, which we sell to
                  buyers on our site.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  These terms of use (together with the documents referred to in
                  them) tell you the terms of use on which you may browse our{' '}
                  <Link to="https://everpress.com" target="_blank">
                    website
                  </Link>{' '}
                  (our site), whether as a guest or as a registered user. Use of
                  our site includes accessing, browsing, or registering to use
                  our site.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  By browsing our site, you confirm that you accept these terms
                  of use and that you agree to comply with them.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  If you do not agree to these terms of use, you must not use
                  our site.
                </Paragraph>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                Other applicable terms
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  These terms of use refer to the following additional terms,
                  which also apply to your use of our site:
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <ListWrapper>
                  <ListItem>
                    Our{' '}
                    <Link to="/privacy-policy" target="_blank">
                      privacy policy
                    </Link>
                    , which sets out the terms on which we process any personal
                    data we collect from you, or that you provide to us. By
                    using our site, you consent to such processing and you
                    warrant that all data provided by you is accurate.
                  </ListItem>
                  <ListItem>
                    Our{' '}
                    <Link to="/use-policy" target="_blank">
                      acceptable use policy
                    </Link>
                    , which sets out the permitted uses and prohibited uses of
                    our site. When using our site, you must comply with this
                    acceptable use policy.
                  </ListItem>
                  <ListItem>
                    Our{' '}
                    <Link to="/cookie-policy" target="_blank">
                      cookie policy
                    </Link>
                    , which sets out information about the cookies on our site.
                  </ListItem>
                  <ListItem>
                    Our{' '}
                    <Link to="/intellectual-property-policy" target="_blank">
                      intellectual property policy
                    </Link>
                    , which sets out compliance guidance on designs which
                    creators need to be aware of.
                  </ListItem>
                </ListWrapper>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  The following terms may also apply to you:
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <ListWrapper>
                  <ListItem>
                    If you purchase garments using our site, our{' '}
                    <Link to="/terms-for-buyers" target="_blank">
                      terms for buyers
                    </Link>{' '}
                    will apply to you.
                  </ListItem>
                  <ListItem>
                    If you use our site to sell garments to buyers, our{' '}
                    <Link to="/terms-for-sellers" target="_blank">
                      terms for creators
                    </Link>{' '}
                    will apply to you.
                  </ListItem>
                </ListWrapper>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                Information about us
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  <Link to="https://everpress.com" target="_blank">
                    everpress.com
                  </Link>{' '}
                  is a site operated by Everpress Limited (we or us). We are a
                  limited company registered in England and Wales under company
                  number 09419903, and have our registered office and main
                  trading address at Unit 2, 55 Dalston Lane,
                  London, England, E8 2NG. Our UK VAT number is 2192548 04.
                </Paragraph>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                Changes to these terms
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  We may revise these terms of use at any time by amending this
                  page.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  Please check this page from time to time to take notice of any
                  changes we make, as they are binding on you.
                </Paragraph>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                Changes to our site
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  We may update our site from time to time, and may change the
                  content at any time. However, please note that any of the
                  content on our site may be out of date at any given time, and
                  we are under no obligation to update it.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  We do not guarantee that our site, or any content on it, will
                  be free from errors or omissions.
                </Paragraph>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                Accessing our site
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  Our site is made available free of charge.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  We do not guarantee that our site, or any content on it, will
                  always be available or be uninterrupted. Access to our site is
                  permitted on a temporary basis only. We may suspend, withdraw,
                  discontinue or change all or any part of our site without
                  notice. We will not be liable to you if for any reason our
                  site is unavailable at any time or for any period.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  You are responsible for making all arrangements necessary for
                  you to have access to our site.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  You are also responsible for ensuring that all persons who
                  access our site through your internet connection are aware of
                  these terms of use and other applicable terms and conditions,
                  and that they comply with them.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  Our site is directed to people residing in the United Kingdom.
                  We do not represent that content available on or through our
                  site is appropriate or available in other locations. We may
                  limit the availability of our site or any service or product
                  described on our site to any person or geographic area at any
                  time. If you choose to access our site from outside the United
                  Kingdom, you do so at your own risk.
                </Paragraph>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                Your account and password
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  If you choose, or you are provided with, a user identification
                  code, password or any other piece of information as part of
                  our security procedures, you must treat such information as
                  confidential. You must not disclose it to any third party.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  We have the right to disable any user identification code or
                  password, whether chosen by you or allocated by us, at any
                  time, if in our reasonable opinion you have failed to comply
                  with any of the provisions of these terms of use.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  If you know or suspect that anyone other than you knows your
                  user identification code or password, you must promptly notify
                  us at{' '}
                  <Link to="mailto:hello@everpress.com">
                    hello@everpress.com
                  </Link>
                  .
                </Paragraph>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                Intellectual property rights
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  We are the owner or the licensee of all intellectual property
                  rights in our site, and in the material published on it. Those
                  works are protected by copyright laws and treaties around the
                  world. All such rights are reserved. The intellectual property
                  rights in the branding and designs of creators on Everpress
                  belong to the relevant creator.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  You may print off one copy, and may download extracts, of any
                  page(s) from our site for your personal use, and you may draw
                  the attention of others within your organisation to content
                  posted on our site.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  You must not modify the paper or digital copies of any
                  materials you have printed off or downloaded in any way, and
                  you must not use any illustrations, photographs, video or
                  audio sequences or any graphics separately from any
                  accompanying text.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  Our status (and that of any identified contributors) as the
                  authors of content on our site must always be acknowledged.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  You must not use any part of the content on our site for
                  commercial purposes without obtaining a licence to do so from
                  us or from our licensors (as the case may be).
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  If you print off, copy or download any part of our site in
                  breach of these terms of use, your right to use our site will
                  cease immediately and you must, at our option, return or
                  destroy any copies of the materials you have made.
                </Paragraph>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                No reliance on information
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  The content on our site is provided for general information
                  only. It is not intended to amount to advice on which you
                  should rely. You must obtain professional or specialist advice
                  before taking, or refraining from, any action on the basis of
                  the content on our site.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  Although we make reasonable efforts to update the information
                  on our site, we make no representations, warranties or
                  guarantees, whether express or implied, that the content on
                  our site is accurate, complete or up-to-date.
                </Paragraph>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                Limitation of our liability
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  Nothing in these terms of use excludes or limits our liability
                  for death or personal injury arising from our negligence, or
                  our fraud or fraudulent misrepresentation, or any other
                  liability that cannot be excluded or limited by English law.
                </Paragraph>
                <Spacing size={paragraphSpacing}>
                  <Paragraph size={paragraphSize}>
                    To the extent permitted by law, we exclude all conditions,
                    warranties, representations or other terms which may apply
                    to our site or any content on it, whether express or
                    implied.
                  </Paragraph>
                </Spacing>
                <Spacing size={paragraphSpacing}>
                  <Paragraph size={paragraphSize}>
                    We will not be liable to any user for any loss or damage,
                    whether in contract, tort (including negligence), breach of
                    statutory duty, or otherwise, even if foreseeable, arising
                    under or in connection with:
                  </Paragraph>
                </Spacing>
                <Spacing size={paragraphSpacing}>
                  <ListWrapper>
                    <ListItem>
                      use of, or inability to use, our site; or
                    </ListItem>
                    <ListItem>
                      use of or reliance on any content displayed on our site.
                    </ListItem>
                  </ListWrapper>
                </Spacing>
                <Spacing size={paragraphSpacing}>
                  <Paragraph size={paragraphSize}>
                    If you are a business user, please note that in particular,
                    we will not be liable for:
                  </Paragraph>
                </Spacing>
                <Spacing size={paragraphSpacing}>
                  <ListWrapper>
                    <ListItem>
                      loss of profits, sales, business, or revenue;
                    </ListItem>
                    <ListItem>business interruption;</ListItem>
                    <ListItem>loss of anticipated savings;</ListItem>
                    <ListItem>
                      loss of business opportunity, goodwill or reputation; or
                    </ListItem>
                    <ListItem>
                      any indirect or consequential loss or damage.
                    </ListItem>
                  </ListWrapper>
                </Spacing>
                <Spacing size={paragraphSpacing}>
                  <Paragraph size={paragraphSize}>
                    If you are a consumer user, please note that we only provide
                    our site for domestic and private use. You agree not to use
                    our site for any commercial or business purposes, and we
                    have no liability to you for any loss of profit, loss of
                    business, business interruption, or loss of business
                    opportunity.
                  </Paragraph>
                </Spacing>
                <Spacing size={paragraphSpacing}>
                  <Paragraph size={paragraphSize}>
                    We will not be liable for any loss or damage caused by a
                    virus, distributed denial-of-service attack, or other
                    technologically harmful material that may infect your
                    computer equipment, computer programs, data or other
                    proprietary material due to your use of our site or to your
                    downloading of any content on it, or on any website linked
                    to it.
                  </Paragraph>
                </Spacing>
                <Spacing size={paragraphSpacing}>
                  <Paragraph size={paragraphSize}>
                    We assume no responsibility for the content of websites
                    linked on our site. Such links should not be interpreted as
                    endorsement by us of those linked websites. We will not be
                    liable for any loss or damage that may arise from your use
                    of them.
                  </Paragraph>
                </Spacing>
                <Spacing size={paragraphSpacing}>
                  <Paragraph size={paragraphSize}>
                    Different limitations and exclusions of liability will apply
                    to liability arising as a result of:
                  </Paragraph>
                </Spacing>
                <Spacing size={paragraphSpacing}>
                  <ListWrapper>
                    <ListItem>
                      the supply of any goods by us to you, which will be set
                      out in our{' '}
                      <Link to="/terms-for-buyers" target="_blank">
                        terms for buyers
                      </Link>
                      ; or
                    </ListItem>
                    <ListItem>
                      you subscribing to any services through our site, which
                      will be set out in our{' '}
                      <Link to="/terms-for-sellers" target="_blank">
                        terms for creators
                      </Link>
                      .
                    </ListItem>
                  </ListWrapper>
                </Spacing>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                How we may use your personal information
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  We will only use your personal information as set out in our{' '}
                  <Link to="/privacy-policy" target="_blank">
                    privacy policy
                  </Link>
                  .
                </Paragraph>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                Viruses
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  We do not guarantee that our site will be secure or free from
                  bugs or viruses.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  You are responsible for configuring your information
                  technology, computer programmes and platform in order to
                  access our site. You should use your own virus protection
                  software.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  You must not misuse our site by knowingly introducing viruses,
                  Trojans, worms, logic bombs or other material which is
                  malicious or technologically harmful. You must not attempt to
                  gain unauthorised access to our site, the server on which our
                  site is stored, or any server, computer or database connected
                  to our site. You must not attack our site via a
                  denial-of-service attack or a distributed denial-of service
                  attack. By breaching this provision, you would commit a
                  criminal offence under the Computer Misuse Act 1990. We will
                  report any such breach to the relevant law enforcement
                  authorities, and we will co-operate with those authorities by
                  disclosing your identity to them. In the event of such a
                  breach, your right to use our site will cease immediately.
                </Paragraph>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                Linking to our site
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  You may link to our site, provided you do so in a way that is
                  fair and legal and does not damage our reputation or take
                  advantage of it.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  You must not establish a link in such a way as to suggest any
                  form of association, approval or endorsement on our part where
                  none exists.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  You must not establish a link to our site in any website that
                  is not owned by you.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  Our site must not be framed on any other site.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  We reserve the right to withdraw linking permission without
                  notice
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  The website in which you are linking must comply in all
                  respects with the content standards set out in our acceptable{' '}
                  <Link to="use-policy" target="_blank">
                    use policy
                  </Link>
                  .
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  If you wish to make any use of content on our site other than
                  that set out above, please contact{' '}
                  <Link to="mailto:hello@everpress.com">
                    hello@everpress.com
                  </Link>
                  .
                </Paragraph>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                Third party links and resources in our site
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  Where our site contains links to other sites and resources
                  provided by third parties, these links are provided for your
                  information only.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  We have no control over the contents of those sites or
                  resources. You are advised to check the terms that apply to
                  your use of any third party sites you enter, in particular any
                  privacy policy that will apply to you when you use the site in
                  question.
                </Paragraph>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                Applicable law
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  If you are a consumer, please note that these terms of use,
                  their subject matter and formation, are governed by English
                  law. You and we both agree that the courts of England and
                  Wales will have non-exclusive jurisdiction. However, if you
                  are a resident of Northern Ireland you may also bring
                  proceedings in Northern Ireland, and if you are resident of
                  Scotland, you may also bring proceedings in Scotland.
                </Paragraph>
              </Spacing>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  If you are a business, these terms of use, their subject
                  matter and formation (and any non-contractual disputes or
                  claims) are governed by English law. We both agree to the
                  exclusive jurisdiction of the courts of England and Wales.
                </Paragraph>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                Trade marks
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  Everpress is a UK, EU, & US registered trade mark of Everpress
                  Limited.
                </Paragraph>
              </Spacing>
            </Spacing>

            <Spacing size={5}>
              <Heading tag={headingTag} size={headingSize}>
                Contact us
              </Heading>
              <Spacing size={paragraphSpacing}>
                <Paragraph size={paragraphSize}>
                  To contact us, please email{' '}
                  <Link to="mailto:hello@everpress.com">
                    hello@everpress.com
                  </Link>
                  . Thank you for visiting our site.
                </Paragraph>
              </Spacing>
            </Spacing>
          </MaxWidth>
        </PageWrap>
        <LiveChat />
      </Page>
    );
  }
}
