import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import partition from 'lodash/partition';

import config from '../../../config';

import { garmentTypes } from './garmentTypes';

import Heading from '../../atoms/Heading';
import Icon from '../../atoms/Icon';
import Link from '../../atoms/Link';
import Paragraph from '../../atoms/Paragraph';
import Spacing from '../../atoms/Spacing';
import { TooltipNew } from '../../atoms/Tooltip';
import PageWrap from '../../atoms/PageWrap';

import WrapIf from '../../utils/WrapIf';

import shiningIcon from '../../../img/sprites/shining-fill.svg';

import generalStyles from './style/general.module.css';

const MegaMenu = ({ collections = [], closeMenu }) => {
  const dispatch = useDispatch();
  const [seasonalCollections, mainCollections] = partition(
    collections,
    (item) => config.shop.seasonalTags.includes(item.slug)
  );
  const slicedCollections = mainCollections.slice(0, 6);

  const trackMegaMenuClick = (name, slug) => {
    dispatch({
      type: 'MEGA_MENU_CLICKED',
      payload: {
        type: 'Desktop',
        name,
        slug
      }
    });
  };

  const handleItemClick = (name, slug) => {
    trackMegaMenuClick(name, slug);
    closeMenu();
  };

  return (
    <PageWrap>
      <Spacing size={3} position="t" type="padding">
        <Spacing size={5} position="x" type="padding">
          <div className={generalStyles.outerContainer}>
            <div className={generalStyles.container}>
              <div>
                <Spacing size={3} position="b" type="padding">
                  <Spacing size={1} position="b">
                    <Heading size="xxs" color="grey-primary">
                      Collections
                    </Heading>
                  </Spacing>
                  <ul className={generalStyles.items}>
                    {slicedCollections.map((collection) => (
                      <li
                        key={collection.slug}
                        onClick={() =>
                          handleItemClick(collection.name, collection.slug)
                        }
                      >
                        <Spacing size={1} position="y" type="padding">
                          <Link
                            to={`/shop/${collection.slug}`}
                            isUnderlined={false}
                          >
                            <Paragraph
                              size="xxs"
                              className={generalStyles.collectionName}
                            >
                              {collection.name}
                            </Paragraph>
                          </Link>
                        </Spacing>
                      </li>
                    ))}
                  </ul>
                </Spacing>
              </div>
              <div>
                <Spacing size={3} position="b" type="padding">
                  <Spacing size={1} position="b">
                    <Heading size="xxs" color="grey-primary">
                      Products
                    </Heading>
                  </Spacing>
                  <ul className={generalStyles.itemsGrid}>
                    {garmentTypes.map((garment, index) => (
                      <li
                        key={garment.slug}
                        onClick={() =>
                          handleItemClick(garment.name, garment.slug)
                        }
                      >
                        <Spacing size={1} position="y" type="padding">
                          <Link
                            to={`/shop/${garment.slug}`}
                            isUnderlined={false}
                          >
                            <Paragraph size="xxs">{garment.name}</Paragraph>
                          </Link>
                        </Spacing>
                      </li>
                    ))}
                  </ul>
                </Spacing>
              </div>
              <div className={generalStyles.line} />
              <div>
                <Spacing size={3} position="b" type="padding">
                  <Spacing size={1} position="b">
                    <Heading size="xxs" color="grey-primary">
                      <span className="inline-flex items-center gap-3">
                        Gifting
                        <Icon
                          glyph={shiningIcon}
                          width={10}
                          height={10}
                          className="text-black"
                        />
                      </span>
                    </Heading>
                  </Spacing>
                  <ul className={generalStyles.featuredItems}>
                    {seasonalCollections.map((collection) => {
                      return (
                        <li
                          key={collection.slug}
                          onClick={() =>
                            handleItemClick(collection.name, collection.slug)
                          }
                        >
                          <Spacing size={1} position="y" type="padding">
                            <Link
                              to={`/shop/${collection.slug}`}
                              isUnderlined={false}
                            >
                              <Paragraph size="xxs">
                                {collection.name}
                              </Paragraph>
                            </Link>
                          </Spacing>
                        </li>
                      );
                    })}
                    <li
                      onClick={() =>
                        handleItemClick('Gift Cards', 'gift-cards')
                      }
                    >
                      <Spacing size={1} position="y" type="padding">
                        <Link to="/gift-cards" isUnderlined={false}>
                          <Paragraph size="xxs">Gift Cards</Paragraph>
                        </Link>
                      </Spacing>
                    </li>
                  </ul>
                </Spacing>
              </div>
            </div>
          </div>
        </Spacing>
      </Spacing>
    </PageWrap>
  );
};

const MegaMenuDesktop = ({ collections, children }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);

  return (
    <WrapIf
      condition={collections.length > 1}
      wrapper={(children) => (
        <TooltipNew
          content={
            <MegaMenu
              collections={collections}
              closeMenu={() => setMenuVisible(false)}
            />
          }
          placement="bottom"
          offset={13}
          width="100vw"
          id="mega-menu"
          borderRadius="0"
          padding={0}
          hasListContent
          externalIsVisible={isMenuVisible}
          externalVisibilityHandler={setMenuVisible}
          isMegaMenu
        >
          {/* Tooltip.Trigger asChild makes this the trigger */}
          <div
            onClick={() => setMenuVisible(false)} // Ensures click also closes menu
          >
            {children}
          </div>
        </TooltipNew>
      )}
    >
      {children}
    </WrapIf>
  );
};

export default MegaMenuDesktop;
