import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames/bind';

import config from '../../../../config';

import CSSGrid from '../../../atoms/Grid/CSSGrid';

import Banner from './Banner';
import Navigation from './Navigation';
import NavigationMinimal from './NavigationMinimal';

import generalStyles from './style/general.module.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isNavigationVisible = useSelector(
    (state) => state.headerNew.navigation.isNavigationVisible
  );
  const isBannerActive = useSelector(
    (state) => state.headerNew.banner.isBannerActive
  );
  const isHidden = useSelector((state) => state.headerNew.navigation.isHidden);
  const theme = useSelector((state) => state.headerNew.theme);
  const location = useSelector((state) => state.location);
  const prevLocation = useSelector((state) => state.location.prev);
  const { extendedRoles: extendedUserRoles } = useSelector(
    (state) => state.user,
    shallowEqual
  );
  const announcementCopy = useSelector(
    (state) => state.headerNew.banner.announcementCopy
  );

  const isMinimalNavigation = extendedUserRoles.includes(
    config.roles.INTEGRATOR
  );

  useEffect(() => {
    if (location !== prevLocation) {
      setIsMenuOpen(false);
    }
  }, [setIsMenuOpen, location, prevLocation]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('overflow-hidden');
      document.body.classList.add('mdlg:overflow-auto');
    } else {
      document.body.classList.remove('overflow-hidden');
      document.body.classList.remove('mdlg:overflow-auto');
    }
  }, [isMenuOpen]);

  if (isMinimalNavigation) {
    return (
      <header
        className={classNames(generalStyles.header, {
          'h-screen': isMenuOpen
        })}
      >
        <NavigationMinimal
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
      </header>
    );
  }

  return (
    <header
      className={classNames(generalStyles.header, {
        'h-screen': isMenuOpen,
        'bg-white': theme.color === 'white',
        'bg-pastel-grey': theme.color === 'pastel-grey',
        'hidden': isHidden
      })}
    >
      <CSSGrid
        className={classNames(generalStyles.headerGrid, {
          'h-100': isMenuOpen,
          'mdlg:h-auto': isMenuOpen,
          'h-auto': !isMenuOpen
        })}
        rows={`${isBannerActive ? 'auto ' : ''}auto${isMenuOpen ? ' 1fr' : ''}`}
        justifyContent="stretch"
      >
        {isBannerActive && <Banner announcementCopy={announcementCopy} />}

        {isNavigationVisible && (
          <Navigation isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        )}
      </CSSGrid>
    </header>
  );
};

export default Header;
