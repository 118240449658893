import React from 'react';
import classNames from 'classnames';

import CarouselContext from '../CarouselContext';

import generalStyles from './style/general.module.css';

export default function CarouselItem({ children, className, index }) {
  return (
    <CarouselContext.Consumer>
      {value => {
        const itemClassName = classNames(`mx-${value.gap}`, className, {
          [generalStyles.isFade]: value.isFade,
          [generalStyles.isFadeActive]:
            value.isFade && value.currentIndex === index
        });

        return <div className={itemClassName}>{children}</div>;
      }}
    </CarouselContext.Consumer>
  );
}
