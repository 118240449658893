import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { ButtonNew } from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Spacing from '../../atoms/Spacing';
import NewsletterForm from '../../molecules/NewsletterForm';

import generalStyles from './style/general.module.css';

const cx = classNames.bind({ ...generalStyles });

export default function Cta({
  children,
  icon,
  linkProps,
  linkText,
  isDark,
  kind
}) {
  const ctaClassName = cx('cta', {
    ctaDark: isDark
  });

  const iconClassName = cx('icon', {
    'fill-white': isDark
  });

  return (
    <div className={ctaClassName}>
      <Spacing position="all" type="padding" size={[1, 2, 3]}>
        <div className={cx('inner')}>
          <Icon glyph={icon} className={iconClassName} />
          <Spacing size={[2, 2, 0]}>
            <Spacing size={2} position="x">
              {children}
            </Spacing>
          </Spacing>
          <div
            className={cx('right', {
              mobileNewsletterFormWrapper: kind === 'newsletter'
            })}
          >
            {kind === 'newsletter' ? (
              <NewsletterForm isDark={isDark} />
            ) : (
              <ButtonNew
                kind="outline"
                color={isDark && 'white'}
                size="tiny"
                {...linkProps}
              >
                {linkText || 'Learn more'}
              </ButtonNew>
            )}
          </div>
        </div>
      </Spacing>
    </div>
  );
}

Cta.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.object.isRequired,
  linkProps: PropTypes.object,
  linkText: PropTypes.string,
  isDark: PropTypes.bool,
  kind: PropTypes.oneOf(['default', 'newsletter'])
};

Cta.defaultProps = {
  isDark: false,
  kind: 'default'
};
