import React from 'react';

import config from '../../../config';

import Spacing from '../../../components/atoms/Spacing';
import CampaignTimeLeft from '../../../components/atoms/CampaignTimeLeft';
import { TinySelect } from '../../../components/atoms/Form';
import Link from '../../../components/atoms/Link';
import {
  CSSGrid,
  CSSGridItem,
  InlineGrid
} from '../../../components/atoms/Grid';
import Heading from '../../../components/atoms/Heading';
import Paragraph from '../../../components/atoms/Paragraph';
import Price from '../../../components/atoms/Price';
import Tooltip from '../../../components/atoms/Tooltip';
import MaxWidth from '../../../components/atoms/MaxWidth';
import Icon from '../../../components/atoms/Icon';
import Image from '../../../components/atoms/Image';

import ProductShot from '../../../components/molecules/ProductShot';

import questionMarkCircle from '../../../img/sprites/question-mark-circle.svg';
import zapIcon from '../../../img/sprites/zap.svg';
import mailDownloadIcon from '../../../img/sprites/mail-download-line.svg';

import styles from './style/general.module.css';

const CartItem = ({ item, campaign, currency, onEdit, onDelete }) => {
  const campaignItem = campaign?.products.find(
    (product) => product.campaignProductId === item.campaignProductId
  );

  const selectedColour = campaignItem?.colours.find(
    (colour) => colour.colourId === item.colourId
  );

  const quantityOptions = [...Array(20)]
    .map((x, i) => ({
      value: i + 1,
      label: i + 1
    }))
    .concat(
      // Additional options for higher quantities (20 to 50) in increments of 5
      [...Array(6)].map((x, i) => ({
        value: (i + 5) * 5,
        label: (i + 5) * 5
      }))
    );

  const tooltip = campaign ? (
    <MaxWidth value={300}>
      This campaign has to reach {campaign.minimum} sales for items to ship on
      the estimated date.
    </MaxWidth>
  ) : null;

  const handleQuantityChange = (value) => {
    const updatedItem = { ...item, quantity: value };
    onEdit(updatedItem);
  };

  const handleDelete = () => {
    onDelete(item.orderItemId);
  };

  return (
    <Spacing size={15} position="t">
      <CSSGrid
        template={[
          'minmax(28px, auto) repeat(4, 33px) / minmax(90px, auto) 1fr',
          'minmax(28px, auto) repeat(4, 28px) / minmax(90px, auto) 1fr'
        ]}
        columnGap={15}
      >
        {!campaign ? (
          <>
            <CSSGridItem
              row={{ start: 1, end: 5 }}
              column={{ start: 1, end: 2 }}
            >
              <Link to="/gift-cards">
                <div
                  style={{
                    padding: '10% 5%',
                    backgroundColor: 'rgb(247, 248, 249)',
                    userSelect: 'none'
                  }}
                >
                  <div className="relative">
                    <Image
                      src={item.image}
                      alt={item.giftCardName}
                      width={90}
                      height={70 / config.productShotsRatio}
                      withPlaceholder={false}
                      lazyLoad={false}
                    />
                  </div>
                </div>
              </Link>
            </CSSGridItem>
            <CSSGridItem
              row={{ start: 1, span: 1 }}
              column={{ start: 2, span: 1 }}
            >
              <InlineGrid align="start" className={styles.itemDetails}>
                <Heading className={styles.itemTitle} size="xxs">
                  <Link to="/gift-cards" isUnderlined={false}>
                    {item.giftCardName}
                  </Link>
                </Heading>
                {item.price && (
                  <Spacing size={1} position="l">
                    <Price currency={currency} value={item.total} size="xxs" />
                  </Spacing>
                )}
              </InlineGrid>
            </CSSGridItem>
            <CSSGridItem
              row={{ start: 2, span: 1 }}
              column={{ start: 2, span: 1 }}
            >
              <span className={styles.itemDetails}>
                <span className={styles.inline}>Everpress</span>
              </span>
            </CSSGridItem>
            <CSSGridItem
              row={{ start: 3, span: 1 }}
              column={{ start: 2, span: 1 }}
            >
              <InlineGrid justify="start" className={styles.itemDetails}>
                <>
                  <Spacing size="05" position="r">
                    <Icon glyph={mailDownloadIcon} width={18} height={18} />
                  </Spacing>
                  <Paragraph size="xxs" className="text-blue-primary">
                    Delivered digitally
                  </Paragraph>
                </>
              </InlineGrid>
            </CSSGridItem>
          </>
        ) : (
          <>
            <CSSGridItem
              row={{ start: 1, end: 5 }}
              column={{ start: 1, end: 2 }}
            >
              <Link
                to={
                  campaign.draft
                    ? `/${campaign.slug}?key=${campaign.key}`
                    : `/${campaign.slug}`
                }
              >
                <ProductShot
                  item={campaignItem}
                  campaign={campaign}
                  colourId={item.colourId}
                  width={90}
                  height={72 / config.productShotsRatio}
                  overrideImageUrl={campaign.draft ? undefined : item.image}
                />
              </Link>
            </CSSGridItem>
            <CSSGridItem
              row={{ start: 1, span: 1 }}
              column={{ start: 2, span: 1 }}
            >
              <InlineGrid align="start" className={styles.itemDetails}>
                <Heading className={styles.itemTitle} size="xxs">
                  <Link
                    to={
                      campaign.draft
                        ? `/${campaign.slug}?key=${campaign.key}`
                        : `/${campaign.slug}`
                    }
                    isUnderlined={false}
                  >
                    {campaign.title}
                  </Link>
                </Heading>
                {item.price && (
                  <Spacing size={1} position="l">
                    <Price currency={currency} value={item.total} size="xxs" />
                  </Spacing>
                )}
              </InlineGrid>
            </CSSGridItem>
            <CSSGridItem
              row={{ start: 2, span: 1 }}
              column={{ start: 2, span: 1 }}
            >
              <span className={styles.itemDetails}>
                <span className={styles.inline}>{selectedColour.name}</span>
                <span className={styles.inline}>{item.size}</span>
              </span>
            </CSSGridItem>
            <CSSGridItem
              row={{ start: 3, span: 1 }}
              column={{ start: 2, span: 1 }}
            >
              <InlineGrid justify="start" className={styles.itemDetails}>
                {campaign.printOnDemand ? (
                  <>
                    <Spacing size="05" position="r">
                      <Icon glyph={zapIcon} width={20} height={20} />
                    </Spacing>
                    <Paragraph size="xxs" className="text-blue-primary">
                      Fast shipping
                    </Paragraph>
                  </>
                ) : campaign.endAt ? (
                  <>
                    <CampaignTimeLeft
                      endAt={campaign.endAt}
                      color="text-grey-dark"
                      endedColor="text-red-primary"
                    />
                    {campaign.minimum > 1 && (
                      <Tooltip
                        className={styles.tooltipIcon}
                        content={tooltip}
                        glyph={questionMarkCircle}
                        glyphWidth={23}
                        glyphHeight={23}
                      />
                    )}
                  </>
                ) : (
                  <Paragraph size="xxs" color="grey-dark">
                    Sample
                  </Paragraph>
                )}
              </InlineGrid>
            </CSSGridItem>
          </>
        )}
        <CSSGridItem row={{ start: 4, span: 1 }} column={{ start: 2, span: 1 }}>
          <span className={styles.itemDetails}>
            <TinySelect
              name={`quantity${item.orderItemId}`}
              options={quantityOptions}
              value={item.quantity}
              newStyles
              onChange={handleQuantityChange}
              data-test-id="cart-quantity"
            >
              Quantity &nbsp;
            </TinySelect>
          </span>
        </CSSGridItem>
        <CSSGridItem row={{ start: 5, span: 1 }} column={{ start: 2, span: 1 }}>
          <Link className={styles.itemDetails} onClick={handleDelete}>
            Remove
          </Link>
        </CSSGridItem>
      </CSSGrid>
    </Spacing>
  );
};

export default CartItem;
