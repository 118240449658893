import React from 'react';
import { useWizard } from 'react-use-wizard';
import { useSelector, shallowEqual } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import config from '../../../config';

import useLocalStorage from '../../../helpers/hooks/useLocalStorage';

import { ButtonNew as Button } from '../../../components/atoms/Button';
import { CSSGrid } from '../../../components/atoms/Grid';
import Icon from '../../../components/atoms/Icon';
import Spacing from '../../../components/atoms/Spacing';
import Link from '../../../components/atoms/Link';

import Step from './Step';

import arrowRightIcon from '../../../img/sprites/arrow-right.svg';
import arrowLeftIcon from '../../../img/sprites/arrow-left.svg';
import everpressLogo from '../../../img/everpress-logo.svg';

import generalStyles from './style/general.module.css';

const STEPS = [
  {
    title: 'Step 1',
    description: 'Upload artwork'
  },
  {
    title: 'Step 2',
    description: 'Set prices and duration'
  },
  {
    title: 'Step 3',
    description: 'Edit store and launch'
  }
];

const Navigation = () => {
  const { activeStep: activeStepIndex, nextStep, previousStep } = useWizard();
  const {
    status,
    campaignProducts,
    artworkPositions,
    profitEstimate,
    darkColoursToastDismissed
  } = useSelector((state) => state.campaignBuilder, shallowEqual);

  // eslint-disable-next-line
  const [_, setBuilderLsState] = useLocalStorage(config.localStorageBuilderKey);

  const isStep2Allowed =
    status === 'resolved' &&
    !isEmpty(campaignProducts) &&
    !isEmpty(artworkPositions);

  const isStep3Allowed =
    !isEmpty(campaignProducts) &&
    !isEmpty(profitEstimate) &&
    status === 'resolved';

  const handleNextStep = async () => {
    if (activeStepIndex === 0 && isStep2Allowed) {
      setBuilderLsState({
        step: 1,
        ...{ darkColoursToastDismissed }
      });

      await nextStep();
    }

    if (activeStepIndex === 1 && isStep3Allowed) {
      window.location.assign('/create/step-3');
    }
  };

  const handlePrevStep = async () => {
    setBuilderLsState({
      step: activeStepIndex - 1,
      ...{ darkColoursToastDismissed }
    });

    await previousStep();
  };

  const isUpdating = status === 'updating';

  return (
    <Spacing size={3} position="y">
      <CSSGrid template="1fr / 1fr auto 1fr" gap={2} align="center">
        <Link to="/" className="mdlg:mr-15 my-auto">
          <img
            src={everpressLogo}
            alt="Everpress logo"
            width="40"
            className={generalStyles.logo}
          />
        </Link>
        <div className={generalStyles.steps}>
          {STEPS.map((step, index) => (
            <Step
              key={step.title}
              title={step.title}
              description={step.description}
              isActive={index === activeStepIndex}
            />
          ))}
        </div>
        <div className={generalStyles.items}>
          {activeStepIndex === 1 ? (
            <>
              <Button onClick={handlePrevStep} kind="outline">
                <Icon width={12} height={12} glyph={arrowLeftIcon} />
              </Button>
              <Button
                state={
                  isUpdating
                    ? 'loading'
                    : isStep3Allowed
                    ? 'default'
                    : 'disabled'
                }
                onClick={handleNextStep}
              >
                Next step
                <span className="ml-05">
                  <Icon width={12} height={12} glyph={arrowRightIcon} />
                </span>
              </Button>
            </>
          ) : (
            <Button
              state={
                isUpdating ? 'loading' : isStep2Allowed ? 'default' : 'disabled'
              }
              onClick={handleNextStep}
            >
              Next step
              <span className="ml-05">
                <Icon width={12} height={12} glyph={arrowRightIcon} />
              </span>
            </Button>
          )}
        </div>
      </CSSGrid>
    </Spacing>
  );
};

export default Navigation;
