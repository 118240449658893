import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';

import config from '../../../config';

import * as checkoutActions from '../../../actions/checkout';

import { DefaultField as Field, Form } from '../../../components/atoms/Form';
import { ButtonNew as Button } from '../../../components/atoms/Button';
import Alert from '../../../components/atoms/Alert';
import { InlineGrid } from '../../../components/atoms/Grid';
import Paragraph from '../../../components/atoms/Paragraph';
import Icon from '../../../components/atoms/Icon';
import Spacing from '../../../components/atoms/Spacing';

import generalStyles from './style/general.module.css';

import tagIcon from '../../../img/sprites/price-tag-line.svg';
import cardIcon from '../../../img/sprites/card-line.svg';

const giftCardCodeRegex = new RegExp(
  /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/
);

const validationSchema = yup.object().shape({
  discountCode: yup
    .string()
    .max(150)
    .required('Please enter a your code')
});

const formatCurrencyAmount = (amount, iso) =>
  Number(amount).toLocaleString(config.defaultLocale, {
    style: 'currency',
    currency: iso,
    currencyDisplay: 'narrowSymbol',
    useGrouping: true
  });

const CheckoutDiscountCode = ({
  discount,
  voucherRedemptions = [],
  orderCurrency
}) => {
  const [error, setError] = useState(null);
  const [deletingVoucherId, setDeletingVoucherId] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = async ({ discountCode }, actions) => {
    const formattedDiscountCode = discountCode.toUpperCase();

    setError(null);
    actions.setSubmitting(true);

    const isGiftCard = giftCardCodeRegex.test(formattedDiscountCode);

    dispatch({
      type: 'CHECKOUT_CODE_ATTEMPT',
      payload: { discountCode: formattedDiscountCode }
    });

    try {
      if (isGiftCard) {
        await dispatch(checkoutActions.applyVoucherCode(formattedDiscountCode));
        await dispatch(checkoutActions.editCheckoutDetails({ tracked: false }));
        actions.resetForm();
      } else {
        await dispatch(
          checkoutActions.applyDiscountCode(formattedDiscountCode)
        );
        await dispatch(checkoutActions.editCheckoutDetails({ tracked: false }));
        actions.resetForm();
      }
    } catch (error) {
      if (error.response?.status === 400) {
        setError(error.message);
      } else {
        setError('Something went wrong, try again later');
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleVoucherDelete = async (orderVoucherRedemptionId) => {
    setDeletingVoucherId(orderVoucherRedemptionId);

    try {
      await dispatch(
        checkoutActions.deleteVoucherCode(orderVoucherRedemptionId)
      );
      await dispatch(checkoutActions.editCheckoutDetails({ tracked: false }));
    } catch (error) {
      if (error.response?.status === 400) {
        setError(error.message);
      } else {
        setError('Something went wrong, try again later');
      }
    } finally {
      setDeletingVoucherId(null);
    }
  };

  return (
    <>
      {isEmpty(discount?.discountCode) ? null : (
        <div className="mb-1">
          <InlineGrid>
            <InlineGrid>
              <Spacing size="05" position="r">
                <Icon glyph={tagIcon} width={15} height={15} />
              </Spacing>
              <Paragraph size="xxs">{discount.discountCode}</Paragraph>
            </InlineGrid>
            <Paragraph size="xxs" color="green">
              {discount.isPercentage
                ? `${discount.percentageValue}% off applied on eligible items`
                : 'Promo applied on eligible items'}
            </Paragraph>
          </InlineGrid>
        </div>
      )}

      {isEmpty(voucherRedemptions)
        ? null
        : voucherRedemptions.map((voucherRedemption) => {
            const {
              orderVoucherRedemptionId,
              amount,
              currencyAmount,
              voucher
            } = voucherRedemption;

            const isDeleting = deletingVoucherId === orderVoucherRedemptionId;

            const remainingBalance = (voucher.balance - amount).toFixed(2);

            const formattedRemainingBalance = formatCurrencyAmount(
              remainingBalance,
              voucher.iso
            );

            const formattedAmount = formatCurrencyAmount(
              currencyAmount,
              orderCurrency
            );

            return (
              <div
                className="grid grid-rows-auto gap-5"
                key={orderVoucherRedemptionId}
              >
                <InlineGrid>
                  <InlineGrid>
                    <Spacing size="05" position="r">
                      <Icon glyph={cardIcon} width={15} height={15} />
                    </Spacing>
                    <Paragraph size="xxs">
                      &bull;&bull;&bull;&bull; {voucher.code.slice(-4)}
                    </Paragraph>
                    <Spacing size="05" position="l">
                      <Button
                        size="tiny"
                        color="red"
                        kind="ghost"
                        onClick={() =>
                          handleVoucherDelete(orderVoucherRedemptionId)
                        }
                        state={
                          isDeleting
                            ? 'loading'
                            : deletingVoucherId
                            ? 'disabled'
                            : 'default'
                        }
                      >
                        Remove
                      </Button>
                    </Spacing>
                  </InlineGrid>
                  <div className="flex flex-col md:flex-row gap-0 md:gap-1 text-green-primary text-xxs text-right md:text-left">
                    <span>{formattedAmount} applied</span>
                    <span className="md:hidden">
                      {formattedRemainingBalance} remaining
                    </span>
                    <span className="hidden md:inline-block">
                      ({formattedRemainingBalance} remaining)
                    </span>
                  </div>
                </InlineGrid>
              </div>
            );
          })}

      <div className="mt-15" />

      <Form
        initialValues={{ discountCode: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        render={({ isSubmitting, handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="flex">
                <div className="w-100 flex-2">
                  <Field
                    placeholder="Got a code or gift card?"
                    name="discountCode"
                    scrollToError={false}
                  />
                </div>
                <Button
                  state={
                    isSubmitting
                      ? 'loading'
                      : values.discountCode
                      ? null
                      : 'disabled'
                  }
                  data-test-id="discountCode"
                  type="submit"
                  className={generalStyles.button}
                >
                  Apply
                </Button>
              </div>
              {error && (
                <Spacing size={1} position="t">
                  <Alert message={error} />
                </Spacing>
              )}
            </form>
          );
        }}
      />
    </>
  );
};

export default CheckoutDiscountCode;
