import React from 'react';
import { connect } from 'react-redux';

import * as orderActions from '../../../actions/orders';

import Spacing from '../../../components/atoms/Spacing';
import Paragraph from '../../../components/atoms/Paragraph';
import Heading from '../../../components/atoms/Heading';
import FollowArtistContainer from '../../../components/molecules/FollowArtist/FollowArtistContainer';

const FollowArtists = ({ campaignLength, campaigns }) => {
  return (
    <div data-test-id="follow-artist-box">
      <Spacing size={2} position="t">
        <Heading size="xs">
          Follow the {campaignLength > 1 ? 'artists' : 'artist'}
        </Heading>
        <Spacing size={1} position="y">
          <Paragraph size="xxs">
            {campaignLength > 1
              ? "Get updates by email when the artists you've bought from release new drops"
              : "Get updates by email when the artist you've bought from releases new drops"}
          </Paragraph>
        </Spacing>
        <Spacing size={3} position="t">
          <FollowArtistContainer campaigns={campaigns} />
        </Spacing>
      </Spacing>
    </div>
  );
};

export default connect(null, {
  ...orderActions
})(FollowArtists);
