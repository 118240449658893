import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { CSSGrid } from '../../../../components/atoms/Grid';
import Heading from '../../../../components/atoms/Heading';
import Image from '../../../../components/atoms/Image';
import Paragraph from '../../../../components/atoms/Paragraph';
import Spacing from '../../../../components/atoms/Spacing';

import generalStyles from './style/general.module.css';

const ImageTextBlock = ({ image, imagePosition, title, subtitle, copy }) => {
  const gridClassNames =
    imagePosition === 'right' ? generalStyles.reverse : undefined;

  return (
    <CSSGrid
      className={gridClassNames}
      template={[
        'repeat(2, auto) / 1fr',
        'repeat(2, auto) / 1fr',
        'repeat(2, auto) / 1fr',
        '1fr / repeat(2, 1fr)'
      ]}
      align="start"
      alignContent="start"
      rowGap={2}
      columnGap={4}
    >
      <div className={generalStyles.image}>
        {isEmpty(image) ? null : (
          <Image
            src={image.url}
            alt={image.alt || title}
            width={image.dimensions.width}
            height={image.dimensions.height}
            withPlaceholder
          />
        )}
      </div>
      <div>
        {isEmpty(subtitle) ? null : (
          <Spacing size={15} position="b">
            <Paragraph className="uppercase" size="xs" tag="span">
              {subtitle}
            </Paragraph>
          </Spacing>
        )}
        <Heading size={['m', 'm', 'l']} tag="h2">
          {title}
        </Heading>
        <Spacing size={15} position="t">
          <div className={generalStyles.copy}>{copy}</div>
        </Spacing>
      </div>
    </CSSGrid>
  );
};

ImageTextBlock.defaultProps = {
  imagePosition: 'left'
};

ImageTextBlock.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string.isRequired
  }),
  imagePosition: PropTypes.oneOf(['left', 'right']),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  copy: PropTypes.node
};

export default ImageTextBlock;
