export const transition = {
  duration: 0.25,
  when: 'beforeChildren'
};

export const variants = {
  visible: {
    opacity: 1,
    transition: { ...transition, delay: 0.25 }
  },
  hidden: {
    opacity: 0,
    transition: { ...transition, delay: 0 }
  }
};
