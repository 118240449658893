import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import AddressLookUpHOC from '../../../../components/atoms/Form/AddressLookUpHOC';
import { DefaultField as Field } from '../../../../components/atoms/Form';
import Link from '../../../../components/atoms/Link';
import Spacing from '../../../../components/atoms/Spacing';

import { AddressSwitchContext } from '../AddressSwitchContext';

const AddressLookUpField = AddressLookUpHOC(Field);

const AddressSwitch = ({
  values,
  onAddressLookupUpdate,
  validationProps: { isSubmitting, isValid, submitCount }
}) => {
  const { manualStep, setManualStep } = useContext(AddressSwitchContext);
  const [addressLookup, setAddressLookup] = useState(null);
  const { postcode } = values;

  useEffect(() => {
    setManualStep(postcode || false);
  }, [postcode, setManualStep]);

  useEffect(() => {
    if (addressLookup) handleUpdateValues(addressLookup);
  }, [addressLookup, handleUpdateValues]);

  useEffect(() => {
    if (
      isSubmitting &&
      !isValid &&
      submitCount > 0 &&
      (!values.line1 || !values.city || !values.postcode)
    ) {
      // we are switching to manual form view
      // if there are any errors in the address
      // as you wouldn't be able to see them
      // if you stayed on address lookup form view
      setManualStep(true);
    }
  }, [isSubmitting, isValid, submitCount, values, setManualStep]);

  const handleUpdateValues = useCallback(
    (addressFields) => {
      onAddressLookupUpdate({ ...values, ...addressFields });
      setManualStep(true);
    },
    [values, onAddressLookupUpdate, setManualStep]
  );

  return (
    <Fragment>
      {manualStep ? (
        <Spacing size={3} position="b">
          <Spacing size={1}>
            <Field type="text" name="line1" placeholder="Address line 1" />
          </Spacing>
          <Spacing size={1}>
            <Field
              type="text"
              name="line2"
              placeholder="Address line 2 (optional)"
            />
          </Spacing>
          <Spacing size={1}>
            <Field type="text" name="city" placeholder="City or town" />
          </Spacing>
          <Spacing size={1}>
            <Field
              type="text"
              name="county"
              placeholder="County, State, Province (optional)"
            />
          </Spacing>
          <Spacing size={1}>
            <Field
              type="text"
              name="postcode"
              placeholder="Post code, Zip code"
            />
          </Spacing>
          <Spacing size={15} position="t">
            <Link
              className="text-xxs"
              onClick={() => {
                setManualStep(false);
              }}
            >
              Search for another address
            </Link>
          </Spacing>
        </Spacing>
      ) : (
        <Spacing size={3} position="b">
          <AddressLookUpField
            noLabel
            type="text"
            name="search"
            autoComplete="new-search-term"
            placeholder="Start typing your address or postcode"
            countryCode={values.country}
            updateValues={setAddressLookup}
          />
          <Spacing size={15} position="t">
            <Link
              className="text-xxs"
              onClick={() => {
                setManualStep(true);
              }}
              data-test-id="manual-delivery-address"
            >
              Enter address manually
            </Link>
          </Spacing>
        </Spacing>
      )}
    </Fragment>
  );
};

export default AddressSwitch;
