import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Heading from '../../../components/atoms/Heading';
import Spacing from '../../../components/atoms/Spacing';

import { CSSGrid } from '../../../components/atoms/Grid';

import generalStyles from './styles/general.module.css';

const Main = ({ image, title, children, isReversedGrid }) => {
  const sideImageClassName = classNames(generalStyles.image, {
    'mdlg:order-first': isReversedGrid
  });
  return (
    <section>
      <CSSGrid columns={['none', 'none', 'none', 'repeat(2, 1fr)']}>
        <div className="mdlg:pt-7 w-100">
          <Spacing size={[8]} type="padding">
            <Heading
              size={['m', 'l', 'xl', 'xl', 'xxl']}
              tag="h2"
              center
              className="block"
            >
              {title}
            </Heading>
          </Spacing>
          <Spacing size={[0, 0, 0, 3]} position="x">
            {children}
          </Spacing>
        </div>
        <div
          style={{ backgroundImage: `url(${image})` }}
          className={sideImageClassName}
        />
      </CSSGrid>
    </section>
  );
};

Main.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isReversedGrid: PropTypes.bool
};

export default Main;
