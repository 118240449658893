import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { TabsContext } from '../index';

import Link from '../../Link';
import { ButtonNew as Button } from '../../Button';

import generalStyles from './style/general.module.css';

const Tab = ({
  id,
  to,
  onClick,
  exact,
  className,
  buttonClassName,
  children,
  ...props
}) => {
  const { direction, onSelect, selectedId } = useContext(TabsContext);

  const handleClick = (event) => {
    if (!!onSelect) onSelect(id);
    if (!!onClick) onClick(event);
  };

  return (
    <li
      id={id}
      className={classNames(generalStyles.tab, className, {
        [generalStyles.vertical]: direction === 'vertical'
      })}
      {...props}
    >
      {to ? (
        <Link isNav exact={exact} to={to} className={buttonClassName}>
          {children}
        </Link>
      ) : (
        <Button
          type="button"
          kind="ghost"
          size="small"
          color="black"
          to={to}
          onClick={handleClick}
          state={selectedId === id ? 'active' : 'default'}
          className={buttonClassName}
        >
          {children}
        </Button>
      )}
    </li>
  );
};

export default Tab;

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.any,
  exact: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  buttonClassName: PropTypes.string
};

Tab.defaultProps = {
  exact: true
};
