import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import throttle from 'lodash/throttle';
import * as Sentry from "@sentry/react";

import {
  deleteUserFavourite,
  favouriteCampaign,
  lookupFavourites
} from '../../../../actions/user';

import IconButton from '../../../../components/atoms/IconButton';

import { ModalNew } from '../../../../components/molecules/Modal';
import AuthenticationContainer from '../../../../components/molecules/AuthenticationContainer';

import favouriteIcon from '../../../../img/sprites/favourite.svg';
import favouriteFilledIcon from '../../../../img/sprites/favourite-filled.svg';

const ReminderFavourite = ({ campaignId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user, shallowEqual);
  const favourites = useSelector((state) => state.user.favourites.list);
  const [showModal, setShowModal] = useState(false);
  const userFavourite = favourites.find(
    (favourite) => favourite.campaign.campaignId === campaignId
  );

  useEffect(() => {
    if (user.token) {
      dispatch(lookupFavourites([campaignId]));
    }
  }, [user.token, campaignId, dispatch]);

  const handleButtonClick = throttle(
    async () => {
      if (user.userId) {
        if (userFavourite) {
          await handleDeleteFavourite();
        } else {
          await handleFavourite();
        }
      } else {
        setShowModal(true);
      }
    },
    1000,
    { trailing: false }
  );

  const handleFavourite = async () => {
    try {
      await dispatch(favouriteCampaign({ campaignId }));
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleDeleteFavourite = async () => {
    try {
      await dispatch(
        deleteUserFavourite({
          userFavouriteId: userFavourite.userFavouriteId,
          campaignId
        })
      );
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleAuthenticationSuccess = async () => {
    setShowModal(false);
    await handleFavourite();
  };

  return (
    <>
      <ModalNew isOpen={showModal} handleClose={() => setShowModal(false)}>
        <AuthenticationContainer
          initialUserStage="signUp"
          onSuccess={handleAuthenticationSuccess}
        />
      </ModalNew>

      <div className="block">
        {userFavourite ? (
          <span data-test-id="favourite-successful">
            <IconButton
              data-test-id="favourite-button"
              kind="secondary"
              className="shadow-md"
              glyph={favouriteFilledIcon}
              title="Added to Favourites"
              onClick={handleButtonClick}
            />
          </span>
        ) : (
          <IconButton
            data-test-id="favourite-button"
            kind="secondary"
            className="shadow-md"
            glyph={favouriteIcon}
            title="Add to Favourites"
            onClick={handleButtonClick}
          />
        )}
      </div>
    </>
  );
};

export default ReminderFavourite;
