import React from 'react';

import config from '../../../config';

import {
  getFacebookUrl,
  getPinterestUrl,
  getTwitterUrl
} from '../../../helpers/socialShareUrls';

import { sendAmplitudeEvent } from '../../../services/amplitude';

import { ModalNew as Modal } from '../../../components/molecules/Modal';

import Spacing from '../../../components/atoms/Spacing';
import Heading from '../../../components/atoms/Heading';
import Paragraph from '../../../components/atoms/Paragraph';
import Icon from '../../../components/atoms/Icon';
import { InlineGrid } from '../../../components/atoms/Grid';
import { ButtonNew as Button } from '../../../components/atoms/Button';

import shareIcon from '../../../img/sprites/share-icon.svg';
import facebookIcon from '../../../img/sprites/facebook.svg';
import twitterIcon from '../../../img/sprites/twitter.svg';
import pinterestIcon from '../../../img/sprites/pinterest.svg';

import styles from './styles/general.module.css';

const ShareProfileModal = ({ slug, showModal, setShowModal }) => {
  const profileURL = `${config.appUrl}profile/${slug}`;
  return (
    <Modal isOpen={showModal} handleClose={() => setShowModal(false)}>
      <Spacing size={15} position="x">
        <Spacing size={2} position="t">
          <Heading tag="h1" center>
            <Spacing size="05">
              <Icon
                glyph={shareIcon}
                className={styles.headerIcon}
                height={25}
                width={25}
              />
            </Spacing>
          </Heading>
        </Spacing>
        <Spacing size={3} position="y">
          <Heading tag="h2" size="xs" center>
            Share your profile
          </Heading>
          <Spacing size={15} position="t">
            <Paragraph size="xxs" center>
              Put yourself and your work out in the world and share your profile
              on social media or copy and paste your profile URL.
            </Paragraph>
          </Spacing>
        </Spacing>
        <Spacing size={15} position="b">
          <input
            className={styles.profileLink}
            readOnly
            value={profileURL}
            onFocus={(e) => {
              e.target.select();
              sendAmplitudeEvent('Share Profile Modal', {
                'Icon Click': 'Link'
              });
            }}
          />
        </Spacing>
        <Spacing size={2} position="b">
          <InlineGrid>
            <Button
              onClick={() => {
                sendAmplitudeEvent('Share Profile Modal', {
                  'Icon Click': 'Facebook'
                });
                window.open(getFacebookUrl(profileURL), '_blank');
              }}
            >
              <Icon
                className={styles.socialButtonIcon}
                glyph={facebookIcon}
                width="16"
                height="12"
              />
              <span className={styles.socialButtonLabel}>Share</span>
            </Button>
            <Button
              onClick={() => {
                sendAmplitudeEvent('Share Profile Modal', {
                  'Icon Click': 'Twitter'
                });
                window.open(
                  getTwitterUrl(
                    `Check out my active campaigns available here ${profileURL} via @EverPressHQ`
                  ),
                  '_blank'
                );
              }}
            >
              <Icon
                className={styles.socialButtonIcon}
                glyph={twitterIcon}
                width="16"
                height="12"
              />
              <span className={styles.socialButtonLabel}>Tweet</span>
            </Button>
            <Button
              onClick={() => {
                sendAmplitudeEvent('Share Profile Modal', {
                  'Icon Click': 'Pinterest'
                });
                window.open(
                  getPinterestUrl(
                    profileURL,
                    `Check out ${slug}'s Everpress profile!`
                  )
                );
              }}
            >
              <Icon
                className={styles.socialButtonIcon}
                glyph={pinterestIcon}
                width="16"
                height="12"
              />
              <span className={styles.socialButtonLabel}>Pin</span>
            </Button>
          </InlineGrid>
        </Spacing>
      </Spacing>
    </Modal>
  );
};

export default ShareProfileModal;
