const getFullCountryName = (iso) => {
  try {
    const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
    return regionNames.of(iso);
  } catch {
    return undefined;
  }
};

export default getFullCountryName;
