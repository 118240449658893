import React, { PureComponent } from 'react';
import scriptLoader from 'react-async-script-loader';
import axios from 'axios';

import config from '../../../config';

class Iframely extends PureComponent {
  constructor() {
    super();

    this.state = {
      html: '',
      iframelyHeight: 'auto'
    };

    this.iframelyRef = null;
  }

  componentDidMount() {
    const { url } = this.props;
    const fetchUrl = 'https://iframe.ly/api/oembed';

    axios
      .get(fetchUrl, {
        params: {
          url: url,
          api_key: config.iframelyApiKey,
          iframe: true,
          omit_script: true
        }
      })
      .then((response) => {
        this.setState({ html: response.data.html });
      })
      .catch((error) => {
        if (error.response.data.status === 415) {
          // Avoid thowing errors when 415
          // as it's most likely to be an instagram profile
          // which Iframely doesn't support anymore.
          // If it's not an IG profile it's another unspported link
          // which doesn't need to pollute Sentry.
          return;
        }

        throw error;
      });
  }

  componentDidUpdate() {
    if (this.state.html && window.iframely) {
      window.iframely.load();
    }
  }

  render() {
    const { html } = this.state;

    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }
}

export default scriptLoader(
  `//cdn.iframe.ly/embed.js?api_key=${config.iframelyApiKey}`
)(Iframely);
