import produce from 'immer';

const initialState = {
  items: []
};

export default (state = initialState, action) =>
  produce(state, newState => {
    const { type, payload } = action;

    if (type === 'GET_GARMENTS_FULFILLED') {
      newState.items = payload;
      return newState;
    }
  });
