import React, { Fragment } from 'react';
import classnames from 'classnames';
import parse from 'date-fns/parse';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';
import isPast from 'date-fns/is_past';
import differenceInHours from 'date-fns/difference_in_hours';
import PropTypes from 'prop-types';

import generalStyles from './style/general.module.css';

export default function CampaignTimeLeft({
  onClick,
  endAt,
  color = 'text-darkest-dark-grey',
  endedColor = color,
  label = true,
  isPreorder
}) {
  const endDate = parse(endAt);
  const hasEnded = isPast(endDate);
  const isFinishingSoon =
    !hasEnded && differenceInHours(endDate, new Date()) <= 24;
  const timeLeft = distanceInWordsStrict(new Date(), endDate, {
    roundingMethod: 'floor'
  });

  return (
    <span
      onClick={!hasEnded ? onClick : undefined}
      className={classnames(color, {
        [endedColor]: hasEnded,
        [generalStyles.label]: label,
        [generalStyles.red]: isFinishingSoon,
        [generalStyles.clickable]: !!onClick && !hasEnded,
        [generalStyles.preorder]: isPreorder
      })}
    >
      {hasEnded && 'Campaign ended'}
      {!hasEnded && !isPreorder && `${timeLeft} left`}
      {!hasEnded && isPreorder && (
        <Fragment>{`${timeLeft} left to pre-order`}</Fragment>
      )}
    </span>
  );
}

CampaignTimeLeft.propTypes = {
  onClick: PropTypes.func,
  endAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  endedColor: PropTypes.string,
  label: PropTypes.bool,
  isPreorder: PropTypes.bool
};
