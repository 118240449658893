import React from 'react';

import generalStyles from './style/general.module.css';

import {
  DefaultField as Field,
  Form,
  Label
} from '../../../components/atoms/Form';
import { ButtonNew as Button } from '../../../components/atoms/Button';
import Spacing from '../../../components/atoms/Spacing';
import Price from '../../../components/atoms/Price';
import Paragraph from '../../../components/atoms/Paragraph';

import { CtaBox } from '../../../components/molecules/Box';

const CheckoutDeliveryOptions = ({
  currency,
  deliveryEstimates,
  tracked,
  trackingAllowed,
  onSave,
  onEdit,
  isSaved,
  customDeliveryOptionCopy
}) => {
  const handleSubmit = async (values, actions) => {
    await onSave(values.tracked === 'true');

    actions.setSubmitting(false);
  };

  const handleEditStep = () => {
    onEdit();
  };

  const savedDeliveryOptionCopy =
    customDeliveryOptionCopy ??
    (tracked ? `Tracked delivery: ` : `Standard delivery: `);

  return isSaved ? (
    <Spacing size={1} position="t">
      <CtaBox
        title="3. Delivery option"
        cta={onEdit ? 'Edit' : null}
        onClick={handleEditStep}
      >
        <Paragraph size="xxs">
          {savedDeliveryOptionCopy}
          <Price
            currency={currency}
            value={
              tracked ? deliveryEstimates.tracked : deliveryEstimates.untracked
            }
          />
        </Paragraph>
      </CtaBox>
    </Spacing>
  ) : (
    <Form
      initialValues={{
        tracked: tracked === true ? 'true' : 'false'
      }}
      onSubmit={handleSubmit}
      render={({ isSubmitting, handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={generalStyles.deliveryOptionsRadioBtnContainer}>
              {[
                {
                  id: 'standard_delivery',
                  value: 'false',
                  labelCopy:
                    customDeliveryOptionCopy || 'Standard delivery: by first class mail (untracked)',
                  estimatedPrice:
                    deliveryEstimates && deliveryEstimates.untracked,
                  checked: values.tracked === 'false'
                },
                ...(trackingAllowed
                  ? [
                      {
                        id: 'tracked_delivery',
                        value: 'true',
                        labelCopy:
                          'Tracked delivery: recommended for non-UK orders',
                        estimatedPrice:
                          deliveryEstimates && deliveryEstimates.tracked,
                        checked: values.tracked === 'true'
                      }
                    ]
                  : [])
              ].map(
                (
                  {
                    id,
                    value,
                    labelCopy,
                    secondaryLabelCopy,
                    estimatedPrice,
                    onClick,
                    checked
                  },
                  index
                ) => (
                  <div
                    className={generalStyles.deliveryOptionsRadioBtn}
                    key={index}
                  >
                    <Field
                      type="radio"
                      name="tracked"
                      id={id}
                      value={value}
                      onClick={onClick}
                      checked={checked}
                    >
                      <Label
                        htmlFor={id}
                        data-test-id={`label-${id}`}
                        type="paragraph"
                        size="xxs"
                        noPad
                        className={generalStyles.fullwidth}
                      >
                        <Paragraph
                          size="xxs"
                          className="flex justify-between leading-normal"
                        >
                          <span>{labelCopy}</span>
                          <span className={generalStyles.labelPrice}>
                            <Price currency={currency} value={estimatedPrice} />
                          </span>
                        </Paragraph>
                        {secondaryLabelCopy && (
                          <Paragraph
                            size="xxs"
                            className="flex justify-between leading-normal"
                          >
                            {secondaryLabelCopy}
                          </Paragraph>
                        )}
                      </Label>
                    </Field>
                  </div>
                )
              )}
            </div>
            <Spacing size={15} position="t">
              <Button
                type="submit"
                state={isSubmitting ? 'loading' : null}
                fullWidth
                data-test-id="step-3-submit"
              >
                Save and continue
              </Button>
            </Spacing>
          </form>
        );
      }}
    />
  );
};

export default CheckoutDeliveryOptions;
