import React from 'react';
import Parser from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';

import Link from '../components/atoms/Link';
import Iframely from '../components/atoms/Iframely';

const htmlParser = function(content) {
  return Parser(content, {
    replace: ({ attribs, children, name }) => {
      if (!attribs) return;

      if (attribs && name === 'a') {
        return <Link to={attribs.href}>{domToReact(children)}</Link>;
      }

      if (attribs && attribs.class === 'iframely') {
        return <Iframely url={attribs['data-url']} />;
      }
    }
  });
};

export default htmlParser;
