import * as Sentry from "@sentry/react";
import flow from 'lodash/flow';
import filter from 'lodash/filter';
import head from 'lodash/head';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import overEvery from 'lodash/overEvery';
import partialRight from 'lodash/partialRight';
import pick from 'lodash/pick';
import unary from 'lodash/unary';

import {
  getFeaturedProduct,
  listColourRefs,
  listParentColourRefs,
  sortByCategory
} from '../helpers/campaign';
import { transformActiveFilterSets } from '../helpers/shop';

const SORTED_CATEGORIES = [
  't-shirts',
  'long-sleeve',
  'hoodies',
  'sweatshirts',
  'womens',
  'ethical',
  'caps'
];

export default function shopMiddleware() {
  return ({ getState }) => (next) => (action) => {
    if (
      ['GET_SHOP_ITEMS_FULFILLED', 'GET_MORE_SHOP_ITEMS_FULFILLED'].includes(
        action.type
      )
    ) {
      const { results: campaigns } = action.payload;

      if (isEmpty(campaigns)) return next(action);

      const { activeFilters: activeFilterSets } = getState().shop;

      const transformedActiveFilterSets = transformActiveFilterSets(
        pick(activeFilterSets, ['productCategories', 'productColours'])
      );

      const {
        productCategories: selectedProductCategories,
        productColours: selectedProductColours
      } = transformedActiveFilterSets;

      const byProductCategories = (product, categories) => {
        return isEmpty(categories) || categories.includes(product.category);
      };

      const byProductColours = (product, colours) => {
        return (
          isEmpty(colours) ||
          !isEmpty(intersection(listParentColourRefs(product.colours), colours))
        );
      };

      const byFeatured = (product) => product.featured;

      const getFeaturedProductIfExists = (products) => {
        const featuredProduct = products.find(byFeatured);

        if (featuredProduct) return [{ ...featuredProduct }];

        return products;
      };

      const productFilters = overEvery([
        unary(partialRight(byProductCategories, selectedProductCategories)),
        unary(partialRight(byProductColours, selectedProductColours))
      ]);

      const getFeaturedSelectedProduct = flow(
        partialRight(filter, productFilters),
        partialRight(sortByCategory, SORTED_CATEGORIES),
        getFeaturedProductIfExists, // Check if any of the filtered products happen to be featured
        head
      );

      const featuredProductsMetadata = campaigns.map((campaign) => {
        const {
          campaignId,
          defaultSide: featuredImageArea,
          images,
          products
        } = campaign;

        const featuredImageType = 'grey'; // Always use grey background images

        try {
          const featuredProduct = Object.values(
            transformedActiveFilterSets
          ).every((val) => isEmpty(val))
            ? getFeaturedProduct(products)
            : getFeaturedSelectedProduct(products);

          const featuredProductColour = isEmpty(selectedProductColours)
            ? featuredProduct.colours.find(
                (colour) => colour.colourId === featuredProduct.featuredColourId
              )
            : featuredProduct.colours.find(
                (colour) =>
                  !isEmpty(
                    intersection(
                      listColourRefs(colour.parentColours),
                      selectedProductColours
                    )
                  )
              );

          const featuredProductImageFallback = images[0];

          const featuredProductImage =
            images.find(
              (image) =>
                image.campaignProductId === featuredProduct.campaignProductId &&
                image.colourId === featuredProductColour.colourId &&
                image.type === featuredImageType &&
                image.area === featuredImageArea
            ) || featuredProductImageFallback;

          return {
            campaignId,
            featuredCampaignProductId: featuredProduct.campaignProductId,
            featuredCampaignProductImage: featuredProductImage
          };
        } catch (err) {
          Sentry.captureException(err);

          return {
            campaignId,
            featuredCampaignProductId: products[0].campaignProductId,
            featuredCampaignProductImage: images[0]
          };
        }
      });

      // Pass the selected products/images as action meta to avoid any potential conflicts with the API response
      action.meta = {
        ...action.meta,
        featuredProducts: featuredProductsMetadata
      };
    }

    return next(action);
  };
}
