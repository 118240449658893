import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import stickybits from 'stickybits';
import classNames from 'classnames';
import parse from 'date-fns/parse';
import isPast from 'date-fns/is_past';
import addDays from 'date-fns/add_days';
import isEqual from 'react-fast-compare';

import * as campaignsActions from '../../../actions/campaigns';
import * as cartActions from '../../../actions/cart';
import * as headerActions from '../../../actions/header';
import * as systemActions from '../../../actions/system';
import { getNewItemValue } from '../../../reducers/cart';

import { getCampaignBySlug } from '../../../helpers/selectors/campaigns';
import { getFirstErrorWithCode } from '../../../helpers/api';

import Page from '../../../components/utils/Page';
import Loading from '../../../components/utils/Loading';
import PageVisibilityTracker from '../../../components/utils/PageVisibilityTracker';
import Concierge from '../../../components/utils/Concierge';

import { ButtonNew as Button } from '../../../components/atoms/Button';
import Link from '../../../components/atoms/Link';
import PageWrap from '../../../components/atoms/PageWrap';
import Heading from '../../../components/atoms/Heading';
import Spacing from '../../../components/atoms/Spacing';
import Paragraph from '../../../components/atoms/Paragraph';
import Price from '../../../components/atoms/Price';
import { Grid, GridItem, InlineGrid } from '../../../components/atoms/Grid';
import {
  handleFormError,
  handleFormSuccess
} from '../../../components/atoms/Form';
import Image from '../../../components/atoms/Image';

import {
  Modal,
  ModalContent,
  ModalHeader
} from '../../../components/molecules/Modal';
import PreviewHeader from '../../../components/molecules/PreviewHeader';
import GarmentSizeModal from '../../../components/molecules/GarmentSizeModal';
import CampaignDonationInfo from '../../../components/molecules/CampaignDonationInfo';
import CampaignShippingOnInfo from '../../../components/molecules/CampaignShippingOnInfo';
import CampaignPODShippingInfo from '../../../components/molecules/CampaignPODShippingInfo';
import CampaignTimeLeft from '../../../components/molecules/CampaignTimeLeft';
import FeaturedCampaignsRail from '../../../components/molecules/Rail/FeaturedCampaignsRail';
import Toaster from '../../../components/molecules/Toaster';
import FollowArtistContainer from '../../../components/molecules/FollowArtist/FollowArtistContainer';

import WrapIf from '../../../components/utils/WrapIf';

// Partials
import ProductShots from './ProductShots';
import ProductInfo from './ProductInfo';
import CampaignInfo from './CampaignInfo';
import EndedCampaignForm from './EndedCampaignForm';
import ProductOptionsForm from './ProductOptionsForm';
import ReminderFavourite from './ReminderFavourite';
import { createCartToast } from './CartToast';
import BuildingModal from './BuildingModal';
import SuccessModal from './SuccessModal';
import LaunchModal from './LaunchModal';
import SubmitModal from './SubmitModal';

import generalStyles from './style/general.module.css';

export class CampaignSingle extends React.Component {
  constructor(props) {
    super();

    const { campaign, query, system } = props;

    this.isCampaignReadyInterval = null;
    this.timeSpent = new Date();

    this.navigateToProfile = this.navigateToProfile.bind(this);

    const itemFromUrl =
      query.product &&
      campaign.products.find((item) => item.product.sku === query.product);
    const selectedItem =
      itemFromUrl || campaign.products.find((item) => item.featured);
    const colourFromUrl =
      query.colour &&
      selectedItem.colours.find((colour) => colour.code === props.query.colour);

    this.state = {
      campaignProductId: selectedItem.campaignProductId,
      colourId: colourFromUrl
        ? colourFromUrl.colourId
        : selectedItem.featuredColourId,
      isTooSlow: false, // Used to change modal copy during images processing
      isRequestingConfirmation: false, // Request user confirmation modal
      isSuccess: false, // Success + share buttons modal
      errorOnLaunch: null,
      sizeModalOpen: false,
      submitModalOpen: system.settings.approvalFlag,
      launchModalOpen: !system.settings.approvalFlag,
      successModalViewed: false
    };

    this.toastContainerRef = React.createRef();
  }

  componentWillMount() {
    if (this.props.isReady) {
      this.onReady();
    }
  }

  componentDidMount() {
    stickybits('.stickybits');

    this.props.getSettings();
  }

  componentDidUpdate(prevProps) {
    // Use deep comparison to check each campaign product for changes
    if (!isEqual(this.props.campaign.products, prevProps.campaign.products)) {
      const selectedItem = this.props.campaign.products.find(
        (product) => product.featured
      );
      this.setState({
        campaignProductId: selectedItem.campaignProductId,
        colourId: selectedItem.featuredColourId
      });
    }

    if (!prevProps.isReady && this.props.isReady) {
      this.onReady();
    }

    if (this.props.isReady) {
      if (prevProps.campaign.draft && !this.props.campaign.draft) {
        this.pollImagesState();
      }

      // We test imagesReady === false explicitly cause it can also be undefined
      if (
        prevProps.campaign.imagesReady === false &&
        this.props.campaign.imagesReady
      ) {
        clearInterval(this.isCampaignReadyInterval);

        // remove ?key query from url without messing with reduxx-router redirects
        window.history.replaceState(null, null, window.location.pathname);

        this.setState({ isSuccess: true });
      }
    }

    // When page is hidden, remove image polling
    if (prevProps.isPageVisible && !this.props.isPageVisible) {
      clearInterval(this.isCampaignReadyInterval);
    }

    // When page goes visible
    if (!prevProps.isPageVisible && this.props.isPageVisible) {
      // if needed, poll images
      if (!this.props.campaign.draft && !this.props.campaign.imagesReady) {
        this.pollImagesState();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.isCampaignReadyInterval);
  }

  onReady() {
    if (!this.props.campaign.draft && !this.props.campaign.imagesReady) {
      this.pollImagesState();
    }

    if (this.props.isUserCampaign) {
      this.props.getUserCampaign(this.props.campaign.campaignId);
    }
  }

  async onRequestEndedItem({ email }, actions) {
    try {
      await this.props.requestEndedCampaign(email, this.props.campaign.slug);

      handleFormSuccess(
        'Thanks! We’ll be in touch if the campaign relaunches.',
        actions
      );

      actions.setSubmitting(false);
      actions.setFieldValue('email', '');
    } catch (error) {
      handleFormError(error, actions);
      return actions.setSubmitting(false);
    }
  }

  async onRemindLiveItem({ email }, actions) {
    try {
      const response = await this.props.remindLiveCampaign(
        email,
        this.props.campaign.slug
      );
      if (response) {
        actions.setFieldValue('email', '');
        actions.setSubmitting(false);
        return response;
      }
    } catch (error) {
      handleFormError(error, actions);
      return actions.setSubmitting(false);
    }
  }

  async launchCampaign() {
    try {
      await this.props.launchCampaign(this.props.campaign.campaignId);
    } catch (error) {
      const err = getFirstErrorWithCode(error);

      return this.setState({ errorOnLaunch: err.message });
    }

    this.setState({ isRequestingConfirmation: false });
  }

  pollImagesState() {
    if (window) {
      this.isCampaignReadyInterval = setInterval(async () => {
        if (new Date() - this.timeSpent >= 10000) {
          this.setState({
            isTooSlow: true
          });
        }

        this.props.isCampaignReady(this.props.campaign.slug);
        const readyOrNot = await this.props.isCampaignReady(
          this.props.campaign.slug
        );

        if (readyOrNot.value) {
          this.props.getCampaign(this.props.campaign.campaignId);
        }
      }, 2000);
    }
  }

  onAddItem(formData, actions) {
    const { campaign } = this.props;
    const selectedItem = campaign.products.find(
      (product) => product.campaignProductId === formData.campaignProductId
    );
    const item = {
      campaignId: campaign.campaignId,
      user: campaign.user,
      ...formData,
      size: formData.size || selectedItem.product.sizes[0].code
    };

    this.props.addCartItem(item);

    createCartToast();
  }

  onFormChange(values) {
    const { campaign } = this.props;
    const { campaignProductId, colourId, size } = this.state;
    const selectedItemValues = { campaignProductId, colourId, size };

    // If product type changed
    if (values.campaignProductId !== campaignProductId) {
      // then we do a "smart" state reduction to keep users preferences
      const selectedItem = campaign.products.find(
        (product) => product.campaignProductId === values.campaignProductId
      );

      this.setState({
        campaignProductId: selectedItem.campaignProductId,
        colourId:
          colourId &&
          getNewItemValue(
            'colourId',
            selectedItem,
            selectedItemValues,
            campaign
          ),
        size: getNewItemValue(
          'size',
          selectedItem,
          selectedItemValues,
          campaign
        )
      });
    } else {
      this.setState({
        colourId: values.colourId,
        size: values.size
      });
    }
  }

  handleLaunchCampaignClick() {
    this.setState({
      isRequestingConfirmation: true,
      submitModalOpen: this.props.system.settings.approvalFlag,
      launchModalOpen: !this.props.system.settings.approvalFlag
    });
  }

  toggleSizeModal(modalToggleTrigger) {
    this.props.viewSizeChart({ modalToggleTrigger });
    this.setState({ sizeModalOpen: !this.state.sizeModalOpen });
  }

  toggleSubmitModal() {
    this.setState({ submitModalOpen: !this.state.submitModalOpen });
  }

  toggleLaunchModal() {
    this.setState({ launchModalOpen: !this.state.launchModalOpen });
  }

  hideSuccessModal() {
    this.setState({ successModalViewed: true });
  }

  navigateToProfile() {
    const {
      campaign: {
        user: { profileName, slug }
      }
    } = this.props;

    this.props.dispatch({
      type: 'PROFILE',
      payload: { slug }
    });

    this.props.dispatch({
      type: 'PROFILE_LINK_CLICKED',
      payload: {
        profileName,
        slug
      }
    });
  }

  render() {
    const {
      campaign,
      hasLinkedCampaigns,
      linkedCampaigns,
      isReady,
      isUserCampaign,
      system: { settings }
    } = this.props;
    const {
      campaignProductId,
      colourId,
      size,
      isRequestingConfirmation,
      isSuccess,
      isTooSlow,
      errorOnLaunch,
      sizeModalOpen,
      submitModalOpen,
      launchModalOpen,
      successModalViewed
    } = this.state;

    if (
      !campaign.products.find(
        (product) => product.campaignProductId === campaignProductId
      )
    ) {
      // campaignProductId does not match any products in the selected campaign,
      // render a spinner until campaignProductId is reset inside componentDidUpdate
      return <Loading />;
    }

    const selectedItem = campaign.products.find(
      (product) => product.campaignProductId === campaignProductId
    );
    const selectedColour = selectedItem.colours.find(
      (colour) =>
        colour.colourId === (colourId || selectedItem.featuredColourId)
    );
    const stickybitsProps = {
      className: classNames('stickybits', generalStyles.stickyAddCart),
      style: {
        top: 0,
        bottom: 'auto'
      }
    };
    const endDate = parse(campaign.endAt);
    const isEnded =
      isPast(endDate) && !campaign.draft && campaign.endAt !== null;
    const prices = selectedItem.prices;
    const samplePrices = prices.map((price) => ({
      ...price,
      price: price.priceSample
    }));

    if (!isReady) {
      return <Loading />;
    }

    return (
      <Page title={campaign.seoTitle || campaign.title}>
        <Concierge />
        <GarmentSizeModal
          isOpen={sizeModalOpen}
          item={selectedItem}
          onClose={() => this.toggleSizeModal('onClose')}
        />
        {isRequestingConfirmation && (
          <>
            <SubmitModal
              campaign={campaign}
              onSubmit={this.launchCampaign.bind(this)}
              onClose={this.toggleSubmitModal.bind(this)}
              isOpen={submitModalOpen}
              errorMessage={errorOnLaunch}
            />
            <LaunchModal
              campaign={campaign}
              onSubmit={this.launchCampaign.bind(this)}
              onClose={this.toggleLaunchModal.bind(this)}
              isOpen={launchModalOpen}
              errorMessage={errorOnLaunch}
            />
          </>
        )}
        <BuildingModal
          isTooSlow={isTooSlow}
          isOpen={!campaign.imagesReady && !campaign.draft}
        />
        <SuccessModal
          campaign={campaign}
          isOpen={
            !successModalViewed &&
            campaign.imagesReady &&
            !(settings.approvalFlag && campaign.approved) &&
            isSuccess
          }
          onClose={this.hideSuccessModal.bind(this)}
        />
        {campaign.imagesReady &&
          campaign.awaitingApproval &&
          settings.approvalFlag && (
            <Modal isOpen isTransparent>
              <Spacing size={[2, 4, 4, 5]} position="all">
                <Spacing size={2} position="b">
                  <ModalHeader>That's it</ModalHeader>
                  <ModalContent>
                    <Spacing size={2}>
                      Your campaign is now in a queue for approval. We review
                      all campaigns within 24 hours, and usually much sooner.
                    </Spacing>
                    <Spacing size={2}>
                      We’ll send you an email to confirm once its live.
                    </Spacing>
                    <Spacing size={2} position="b">
                      <Spacing size={3} position="t">
                        <Button kind="outline" to="/dashboard/creator-toolkit">
                          Go to the Creator Toolkit
                        </Button>
                      </Spacing>
                    </Spacing>
                  </ModalContent>
                </Spacing>
              </Spacing>
            </Modal>
          )}
        {campaign.draft && (
          <PreviewHeader
            campaignId={campaign.campaignId}
            isEditable={campaign.permissions && campaign.permissions.edit}
            onLaunchClick={this.handleLaunchCampaignClick.bind(this)}
            approvalFlag={this.props.system.settings.approvalFlag}
          />
        )}
        <PageWrap ref={this.toastContainerRef}>
          <Grid
            row={['flex-column', 'flex-row']}
            gap={[0, 1, 2]}
            align="stretch"
          >
            {isUserCampaign && !campaign.draft && (
              <GridItem columnSize={[12, 12, 7, 8]}>
                <div className={generalStyles.mobileDashboardLink}>
                  <Link
                    to={{
                      type: 'DASHBOARD_CAMPAIGNS',
                      payload: {
                        campaignId: campaign.campaignId
                      }
                    }}
                  >
                    View in Dashboard
                  </Link>
                </div>
              </GridItem>
            )}
            <GridItem columnSize={[12, 12, 7, 8]}>
              <div className={generalStyles.productShots}>
                <ProductShots
                  item={selectedItem}
                  campaign={campaign}
                  colour={selectedColour}
                >
                  {!isEnded && (
                    <ReminderFavourite campaignId={campaign.campaignId} />
                  )}
                </ProductShots>
              </div>
            </GridItem>
            <GridItem columnSize={[12, 12, 5, 4]}>
              {isUserCampaign && !campaign.draft && (
                <div className={generalStyles.desktopDashboardLink}>
                  <Button
                    size="small"
                    kind="outline"
                    to={{
                      type: 'DASHBOARD_CAMPAIGNS',
                      payload: {
                        campaignId: campaign.campaignId
                      }
                    }}
                  >
                    View in Dashboard
                  </Button>
                </div>
              )}
              <div {...stickybitsProps}>
                <Spacing size={[1, 1, 2]} position="b">
                  <WrapIf
                    condition={!!campaign.user.slug}
                    wrapper={(children) => (
                      <Link
                        isUnderlined={false}
                        onClick={this.navigateToProfile}
                      >
                        {children}
                      </Link>
                    )}
                  >
                    {campaign.logoUrl ? (
                      <Image
                        imgClassName={generalStyles.logo}
                        src={campaign.logoUrl}
                        height="100"
                        withPlaceholder={false}
                      />
                    ) : (
                      <Spacing size="05" position="b">
                        <Heading
                          tag="div"
                          size={['xxxs', 'xxs', 'xs']}
                          className="leading-normal"
                        >
                          {campaign.user.profileName}
                        </Heading>
                      </Spacing>
                    )}
                  </WrapIf>
                  <Spacing size="05" position="b">
                    <Heading
                      tag="h1"
                      size={['s', 's', 's']}
                      className="leading-normal"
                    >
                      {campaign.title}
                    </Heading>
                  </Spacing>
                  {!campaign.draft &&
                  isUserCampaign &&
                  selectedItem.prices.find((price) => 'priceCost' in price) ? (
                    <>
                      <Spacing size="05" position="b">
                        <InlineGrid justify="start">
                          <Heading
                            tag="div"
                            size="xs"
                            color="dark-green"
                            className="leading-normal"
                          >
                            <Price
                              value={selectedItem.prices.map((price) => ({
                                ...price,
                                price: price.priceCost
                              }))}
                            />
                          </Heading>
                          <Spacing size="05" position="l">
                            <Heading
                              tag="div"
                              size="xs"
                              color="grey"
                              className="leading-normal line-through"
                            >
                              <Price value={prices} />
                            </Heading>
                          </Spacing>
                        </InlineGrid>
                      </Spacing>
                      <Paragraph
                        size="xxs"
                        color="dark-green"
                        className="leading-normal"
                      >
                        Order at a reduced price for your own campaign
                      </Paragraph>
                    </>
                  ) : (
                    <Heading tag="div" size="xs" className="leading-normal">
                      <Price value={prices} />
                    </Heading>
                  )}
                </Spacing>

                <ProductOptionsForm
                  campaign={campaign}
                  isUserCampaign={isUserCampaign}
                  isEnded={isEnded}
                  item={selectedItem}
                  colourId={colourId}
                  size={size}
                  onSubmit={this.onAddItem.bind(this)}
                  onChange={this.onFormChange.bind(this)}
                >
                  <Spacing size={1}>
                    <Link
                      onClick={() => this.toggleSizeModal('productShopForm')}
                      isUnderlined={true}
                    >
                      <Paragraph size="xxs" className="leading-relaxed">
                        Size guide
                      </Paragraph>
                    </Link>
                  </Spacing>
                </ProductOptionsForm>

                {!isEnded && <Spacing size={2} />}

                {campaign.charityCampaign && (
                  <Spacing size={15} position="b">
                    <CampaignDonationInfo />
                  </Spacing>
                )}

                {campaign.printOnDemand && (
                  <Spacing size={15} position="b">
                    <CampaignPODShippingInfo />
                  </Spacing>
                )}

                <Spacing size={campaign.printOnDemand ? 0 : 15} position="b">
                  {campaign.draft || !campaign.imagesReady ? ( // TODO: fix API instead of this workaround
                    <CampaignTimeLeft
                      isPreorder={!campaign.printOnDemand}
                      endAt={addDays(Date.now(), campaign.length).toString()}
                    />
                  ) : (
                    <CampaignTimeLeft
                      isPreorder={!campaign.printOnDemand}
                      endAt={campaign.endAt}
                    />
                  )}
                </Spacing>

                {isEnded && (
                  <Spacing size={15} position="t">
                    <EndedCampaignForm
                      onRequestEndedItem={this.onRequestEndedItem.bind(this)}
                    />
                  </Spacing>
                )}

                {!campaign.printOnDemand && !isEnded && (
                  <CampaignShippingOnInfo campaign={campaign} />
                )}

                {campaign.draft && campaign.samples && (
                  <Spacing size={4} position="t">
                    <Heading tag="div" size="xs">
                      Request a sample for <Price value={samplePrices} />
                    </Heading>
                  </Spacing>
                )}

                <Spacing size={4}>
                  <hr className="border-grey-lighter" />
                </Spacing>

                <Spacing size={1} position="b">
                  <Paragraph size="xxs">{campaign.description}</Paragraph>
                </Spacing>

                {campaign.content && campaign.content.length > 0 && (
                  <div className="block">
                    <Button
                      to="#more-info"
                      isExternal
                      size="tiny"
                      kind="outline"
                    >
                      Read more
                    </Button>
                  </div>
                )}

                <Spacing size={4}>
                  <hr className="border-grey-lighter" />
                </Spacing>

                {campaign.profile?.name && (
                  <FollowArtistContainer
                    campaigns={[campaign]}
                    showCampaignName={false}
                    kind="campaign"
                  />
                )}
              </div>
            </GridItem>
          </Grid>
          <div id="more-info" className={generalStyles.moreInfoAnchor} />
          {isEnded && linkedCampaigns.length > 0 ? (
            <Spacing size={['4', '4', '0']} position="t">
              <FeaturedCampaignsRail
                title="More From This Creator"
                campaigns={linkedCampaigns}
              />
            </Spacing>
          ) : null}
          <CampaignInfo campaign={campaign} />
          <ProductInfo
            campaign={campaign}
            campaignProductId={campaignProductId}
            styles={generalStyles} // TODO: refactor the tables as they have common styles instead of doing this
            onSizeChartClick={() => this.toggleSizeModal('productInfoBox')}
          />
          {!isEnded && hasLinkedCampaigns && linkedCampaigns.length > 0 ? (
            <Spacing size={8}>
              <FeaturedCampaignsRail
                title="More From This Creator"
                campaigns={linkedCampaigns}
              />
            </Spacing>
          ) : null}
          <Toaster container={this.toastContainerRef.current} />
        </PageWrap>
      </Page>
    );
  }
}

const mapStateToProps = ({ location, campaigns, user, system }) => {
  const { slug_1, slug_2 } = location.payload;
  const campaign =
    getCampaignBySlug(slug_2, campaigns) ||
    getCampaignBySlug(slug_1, campaigns);
  const isUserCampaign = campaign.user && user.userId === campaign.user.userId;

  return {
    userDetails: user.details,
    campaign,
    hasLinkedCampaigns: campaign.linkedCampaigns,
    linkedCampaigns: campaigns.linked.items,
    isReady: campaign.isReady,
    query: location.query || {},
    system,
    isUserCampaign
  };
};

export default connect(mapStateToProps, (dispatch) => ({
  ...bindActionCreators(cartActions, dispatch),
  ...bindActionCreators(campaignsActions, dispatch),
  ...bindActionCreators(headerActions, dispatch),
  ...bindActionCreators(systemActions, dispatch),
  redirectToCart: () => dispatch({ type: 'CART' }),
  dispatch
}))((props) => (
  <PageVisibilityTracker>
    {(value) => <CampaignSingle {...props} isPageVisible={value} />}
  </PageVisibilityTracker>
));
