import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';

import { convertToSubunit } from '../../../../helpers/stripe';

import config from '../../../../config';

const StripeContainer = ({ amount, currency, children }) => {
  const [stripe, setStripe] = useState(() => window.Stripe?.(config.stripeKey));

  const options = {
    amount: convertToSubunit(amount),
    currency: currency.toLowerCase(),
    loader: 'auto',
    mode: 'payment',
    paymentMethodCreation: 'manual'
  };

  useEffect(() => {
    if (!stripe) {
      const stripePromise = loadStripe(config.stripeKey);

      setStripe(stripePromise);
    }
  }, [stripe]);

  if (!stripe) return null;

  return (
    <Elements stripe={stripe} options={options}>
      {children}
    </Elements>
  );
};

StripeContainer.defaultProps = {
  currency: 'gbp'
};

StripeContainer.propTypes = {
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  children: PropTypes.node
};

export default StripeContainer;
