import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { TabsContext } from '../index';

import Link from '../../Link';

import bigStyles from './style/big.module.css';
import defaultStyles from './style/default.module.css';
import lightStyles from './style/light.module.css';

const kindStyles = {
  default: defaultStyles,
  big: bigStyles,
  light: lightStyles
};

class Tab extends React.Component {
  handleClick = (e) => {
    this.props.onSelect && this.props.onSelect(this.props.id);
    this.props.onClick && this.props.onClick(e);
  };

  render() {
    const {
      to,
      children,
      id,
      direction,
      kind,
      selectedId,
      isActive,
      exact,
      className,
      ctaClassName
    } = this.props;
    const style = kindStyles[kind];

    return (
      <li
        className={classNames(style.item, className, {
          [style.vertical]: direction === 'vertical'
        })}
      >
        {to ? (
          <Link
            isNav
            exact={exact}
            to={to}
            className={classNames(style.cta, ctaClassName, {
              [style.ctaVertical]: direction === 'vertical'
            })}
            activeClassName={style.active}
            isActive={isActive}
            noHover={kind === 'default'}
          >
            {children}
          </Link>
        ) : (
          <button
            type="button"
            onClick={this.handleClick}
            className={classNames(style.cta, ctaClassName, {
              [style.active]: selectedId === id
            })}
          >
            {children}
          </button>
        )}
      </li>
    );
  }
}

export default (props) => (
  <TabsContext.Consumer>
    {(context) => <Tab {...props} {...context} />}
  </TabsContext.Consumer>
);

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.any,
  exact: PropTypes.bool,
  onClick: PropTypes.func
};

Tab.defaultProps = {
  exact: true
};
