import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import optimist from 'redux-optimist';
import {
  createStateSyncMiddleware,
  initMessageListener
} from 'redux-state-sync';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { connectRoutes } from 'redux-first-router';
import restoreScroll from 'redux-first-router-restore-scroll';
import queryString from 'query-string';
import * as Sentry from '@sentry/react';

import routesMap from './routesMap';
import * as reducers from './reducers';
import config from './config';

import trackingEmitterMiddleware from './services/trackingEmitter';
import errorMiddleware from './services/errorMiddleware';
import jwtMiddleware from './services/jwtMiddleware';
import optimistMiddleware from './services/optimistMiddleware';
import adsNetworkMiddleware from './services/adsNetworkMiddleware';
import shopMiddleware from './services/shopMiddleware';

/**
 * This file is scary but it's a "set-and-forget-it" kinda thing.
 * The rare time you'll have to touch it, it should be a copy/paste from
 * a Github readme.md related to React/Redux...
 */

export default ({ middlewareExtraArguments, onBeforeLocationChange } = {}) => {
  const routerOptions = {
    onBeforeChange: onBeforeLocationChange,
    restoreScroll: restoreScroll({
      shouldUpdateScroll: (prev, locationState) => {
        return routesMap[locationState.type] &&
          routesMap[locationState.type].shouldUpdateScroll
          ? routesMap[locationState.type].shouldUpdateScroll(prev)
          : [0, 0];
      }
    }),
    initialDispatch: false,
    extra: middlewareExtraArguments,
    querySerializer: queryString
  };
  const { reducer, middleware, enhancer, initialDispatch } = connectRoutes(
    routesMap,
    routerOptions
  );

  const rootReducer = optimist(
    combineReducers({ ...reducers, location: reducer })
  );
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: (action) => {
      if (action.type === 'LOGIN_USER' || action.type === 'SIGNUP_USER') {
        // Return a transformed action to remove sensitive information
        return {
          ...action,
          password: null
        };
      }
      return action;
    }
  });
  const middlewares = applyMiddleware(
    middleware,
    thunk.withExtraArgument(middlewareExtraArguments),
    errorMiddleware(),
    promiseMiddleware(),
    optimistMiddleware(),
    adsNetworkMiddleware(),
    shopMiddleware(),
    jwtMiddleware({
      routesToValidate: config.jwtRoutesToValidate
    }),
    trackingEmitterMiddleware,
    createStateSyncMiddleware({
      whitelist: [
        ...config.stateSync.cart,
        ...config.stateSync.user,
        ...config.stateSync.campaignBuilder
      ]
    })
  );
  const enhancers = composeWithDevTools(enhancer, middlewares);
  const store = createStore(
    rootReducer,
    compose(enhancers, sentryReduxEnhancer)
  );

  initMessageListener(store);

  if (module.hot && process.env.NODE_ENV === 'development') {
    module.hot.accept('./reducers/index', () => {
      const reducers = require('./reducers/index');
      const rootReducer = combineReducers({ ...reducers, location: reducer });
      store.replaceReducer(rootReducer);
    });
  }

  return { store, initialDispatch };
};
