import React from 'react';

import Page from '../../components/utils/Page';

import Heading from '../../components/atoms/Heading';
import PageWrap from '../../components/atoms/PageWrap';
import Paragraph from '../../components/atoms/Paragraph';
import Spacing from '../../components/atoms/Spacing';
import { Grid, GridItem } from '../../components/atoms/Grid';
import { ButtonNew } from '../../components/atoms/Button';

import QuoteThumbnail from '../../components/molecules/Thumbnails/QuoteThumbnail';

import ExtraImages from './ExtraImages';
import HeroImages from './HeroImages';

import generalStyles from './style/general.module.css';

import alexImage from '../../img/pages/about/alex-econs.jpg';

export default function About() {
  return (
    <Page title="About" className="bg-black">
      <PageWrap>
        <Spacing position="t">
          <h1 className={generalStyles.title}>
            Hey,
            <br />
            welcome to everpress!
          </h1>
        </Spacing>
        <Spacing>
          <Grid gap={[2, 3]} align="start">
            <GridItem columnSize={[12, 12, 4]}>
              <Paragraph size={['xs', 's']} color="white">
                You’ve entered a place where brilliant stuff gets made. Here
                you’ll find a curated emporium of original designs with the
                tools to manufacture, promote, sell and distribute your own
                creations; a genuine alternative to the tyranny and wastefulness
                of conventional retail.
              </Paragraph>
              <Spacing size={2}>
                <Paragraph size={['xs', 's']} color="white">
                  We believe creative originality cannot thrive without
                  independence. For us, it’s about You: what You know is right
                  for you; what You know is right for the world. We’re on a
                  mission to empower creative independence in a commercial
                  world.
                </Paragraph>
              </Spacing>
              <Paragraph size={['xs', 's']} color="white">
                Let’s get started.
              </Paragraph>
            </GridItem>
            <GridItem columnSize={[12, 12, 8]}>
              <Spacing size={[2, 2, 0]} position="t">
                <HeroImages />
              </Spacing>
            </GridItem>
          </Grid>
        </Spacing>

        <Spacing size={2}>
          <div className="border-t-1 border-b-1 border-solid border-white">
            <Spacing size={5} type="padding">
              <QuoteThumbnail
                isLight
                row="flex-row-reverse"
                size="s"
                image={alexImage}
                quote="The creative community in East London has shaped our philosophy from day one. Over ten years in the printing business working with artists, designers, indie music labels and small businesses, it was clear retail was broken. Traditional retail is based solely on profit margins resulting in questionable ethics and huge amounts of waste. I saw we could make things better, and so we do."
                title="Everpress Founder"
                author="Alex Econs"
              />
            </Spacing>
          </div>
        </Spacing>

        <div className="border-b-1 border-solid border-white">
          <Spacing size={5} type="padding">
            <h3 className={generalStyles.h3}>
              Make better things.
              <br />
              No risk. No waste.
            </h3>
            <Grid gap={0}>
              <GridItem columnSize={[12, 12, 6]}>
                <Spacing size={3}>
                  <Paragraph size="s" color="white">
                    If retail doesn’t change, we will have even more mountains
                    of waste, fewer choices, less originality, and only lower
                    quality. It’s not about us. It’s about shaping the future in
                    the best way possible.
                  </Paragraph>
                </Spacing>
              </GridItem>
            </Grid>
          </Spacing>
        </div>
      </PageWrap>

      <Spacing size={3}>
        <ExtraImages />
      </Spacing>
      <PageWrap>
        <Spacing size={6} type="padding">
          <Grid gap={0}>
            <GridItem columnSize={[12, 12, 12, 6]} />
            <GridItem columnSize={[12, 12, 12, 6]}>
              <Spacing size={5} position="b">
                <Paragraph color="white" size="s">
                  Working together: We are first and foremost a community of
                  creators who support each other. We learn together, we grow
                  together. Behind the scenes, our culture thrives on trust and
                  independence driven by our mandate to:
                </Paragraph>
              </Spacing>
              <Spacing size={2}>
                <Heading tag="h4" color="white" size="xl">
                  Remove Risk
                </Heading>
              </Spacing>
              <Spacing size={2}>
                <Heading tag="h4" color="white" size="xl">
                  Simplify Production
                </Heading>
              </Spacing>
              <Spacing size={2}>
                <Heading tag="h4" color="white" size="xl">
                  Eliminate Waste
                </Heading>
              </Spacing>
              <Spacing size={2}>
                <Heading tag="h4" color="white" size="xl">
                  Promote independent creativity
                </Heading>
              </Spacing>
              <Spacing size={2}>
                <Heading tag="h4" color="white" size="xl">
                  Elevate community
                </Heading>
              </Spacing>
              <Spacing size={3}>
                <Paragraph color="white" size="s">
                  If you believe there’s a better way to make things, we want to
                  work with you. Challenge us. Push us. Help us learn.
                </Paragraph>
              </Spacing>
              <Spacing size={4}>
                <ButtonNew to="/contact" color="white">
                  Get in touch
                </ButtonNew>
              </Spacing>
            </GridItem>
          </Grid>
        </Spacing>
      </PageWrap>
    </Page>
  );
}
