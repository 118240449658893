import React from 'react';

import Spacing from '../../../atoms/Spacing';
import Heading from '../../../atoms/Heading';
import MaxWidth from '../../../atoms/MaxWidth';

export default function ModalHeader({ children }) {
  return (
    <Spacing type="margin" size={2} position="b">
      <MaxWidth value={400}>
        <Heading size={['l', 'xl']}>{children}</Heading>
      </MaxWidth>
    </Spacing>
  );
}
