import React from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';

import Spacing from '../../../atoms/Spacing';
import Heading from '../../../atoms/Heading';
import Tag from '../../../atoms/Tag';
import Image from '../../../atoms/Image';

export default function GarmentThumbnail({ image, title, tag, to }) {
  return (
    <Link to={to}>
      <Image src={image} alt={title} width="500" height="500" />
      <Spacing size={'05'}>
        <Heading size="xs">{title}</Heading>
      </Spacing>
      <Spacing size={'05'}>
        <Tag>{tag}</Tag>
      </Spacing>
    </Link>
  );
}

GarmentThumbnail.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
};
