import React from 'react';

import Heading from '../../../components/atoms/Heading';
import Spacing from '../../../components/atoms/Spacing';
import Paragraph from '../../../components/atoms/Paragraph';
import Price from '../../../components/atoms/Price';

import CheckoutBox from '../../../components/molecules/CheckoutBox/New';

import generalStyles from './style/general.module.css';

const CartSummary = ({ subtotal }) => {
  return (
    <>
      <div className="hidden mdlg:block border-b-1">
        <Spacing size={[2, 1]} position={['t', 'b']}>
          <Heading size="xs">Summary</Heading>
        </Spacing>
      </div>
      <CheckoutBox title="Subtotal">
        <Price value={subtotal} />
      </CheckoutBox>
      <Spacing size={[0, 1]} position={['t', 'b']}>
        <div className={generalStyles.cartSummary}>
          <Heading size="xxs">Shipping</Heading>
          <Paragraph size="xxs" color="grey-primary">
            Calculated at checkout
          </Paragraph>
        </div>
      </Spacing>
    </>
  );
};

export default CartSummary;
