'use strict';

// Tests specific polyfills. Since the browser version uses polyfill.io
if (process.env.NODE_ENV === 'test') {
  if (typeof Promise === 'undefined') {
    // Rejection tracking prevents a common issue where React gets into an
    // inconsistent state due to an error, but it gets swallowed by a Promise,
    // and the user has no idea what causes React's erratic future behavior.
    require('promise/lib/rejection-tracking').enable();
    window.Promise = require('promise/lib/es6-extensions.js');
  }

  // fetch() polyfill for making API calls.
  require('whatwg-fetch');

  // Object.assign() is commonly used with React.
  // It will use the native implementation if it's present and isn't buggy.
  Object.assign = require('object-assign');

  // Polyfill requestAnimationFrame since jsdom doesn't provide it yet.
  require('raf').polyfill(global);
}

// scrollIntoView({behavior: 'smooth'})
import('smoothscroll-polyfill').then(function(smoothscroll) {
  smoothscroll.polyfill();
});

// IntersectionObserver
require('intersection-observer');

// document.scrollingElement
require('scrolling-element');

require('@formatjs/intl-getcanonicallocales/polyfill');
// Intl.Locale for working with Unicode locales
require('@formatjs/intl-locale/polyfill');
// Intl.DisplayNames for working with countryName in stage-checkout
require('@formatjs/intl-displaynames/polyfill');
require('@formatjs/intl-displaynames/locale-data/en');

// CSS :focus-visible
require('focus-visible');
