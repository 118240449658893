import React from 'react';

import Heading from '../../../components/atoms/Heading';
import Spacing from '../../../components/atoms/Spacing';

import generalStyles from './style/general.module.css';

const Title = ({ titleCopy }) => {
  return (
    <Spacing size={2} position="b">
      <Heading className={generalStyles.title} tag="h3" size="xs">
        <span className={generalStyles.titleCircle} />
        {titleCopy}
      </Heading>
    </Spacing>
  );
};

export default Title;
