import React, { useState, useCallback } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import ContentEditable from 'react-contenteditable';
import classNames from 'classnames/bind';
import DOMPurify from 'dompurify';
import * as yup from 'yup';

import getInitials from '../../../helpers/getInitials';

import Alert from '../../../components/atoms/Alert';
import Avatar from '../../../components/atoms/Avatar';
import Spacing from '../../../components/atoms/Spacing';
import Paragraph from '../../../components/atoms/Paragraph';
import Link from '../../../components/atoms/Link';
import Heading from '../../../components/atoms/Heading';

import OwnerActions from './OwnerActions';
import VisitorActions from './VisitorActions';

import styles from './styles/general.module.css';
import { variants, transition } from './variants';

const cx = classNames.bind({ ...styles });

const protocolRegex = /(^\w+:|^)\/\//;
const urlRegex = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const profileSchemas = {
  profileName: yup
    .string()
    .max(30)
    .required('Name is a required field'),
  website: yup
    .string()
    .matches(urlRegex, 'URL is not valid')
    .max(100)
    .nullable(true),
  bio: yup
    .string()
    .max(160)
    .nullable(true)
};

const disableEnterKeyDown = (e) =>
  ['Enter'].includes(e.key) ? e.preventDefault() : null;

const UserProfileInfo = ({ profile, isOwner, onEdit, onToggle }) => {
  const [error, setError] = useState(null);

  const handleContentChange = useCallback(
    async (event, prop) => {
      try {
        setError(null);

        const val = event.currentTarget.textContent;
        const cleanVal = DOMPurify.sanitize(val);
        await profileSchemas[prop].validate(cleanVal);

        if (profile[prop] === cleanVal) {
          onToggle?.(false);
          return;
        }

        const data = {
          ...(prop === 'profileName'
            ? { name: cleanVal }
            : { [prop]: cleanVal })
        };

        onEdit?.(data);
      } catch (err) {
        err.name === 'ValidationError'
          ? setError(err.message)
          : setError('Unable to edit profile, please try again later.');
      }
    },
    [profile, onEdit, onToggle]
  );

  return (
    <div
      className={cx({
        infoWrapper: true,
        offset: !!profile.profileBanner
      })}
    >
      <Spacing size={2} position="b">
        <div
          className={cx({
            avatarWrapper: true,
            'sm:mt-9': !!profile.profileBanner
          })}
        >
          <Avatar
            src={profile.profilePicture}
            initials={getInitials(profile.profileName || '')}
            size="xxl"
          />
        </div>
      </Spacing>
      <Spacing size={1} position="b">
        <Heading tag="h1" size="s">
          {isOwner ? (
            <ContentEditable
              tagName="span"
              onFocus={() => onToggle?.(true)}
              onBlur={(val) => handleContentChange(val, 'profileName')}
              onKeyDown={disableEnterKeyDown}
              html={profile.profileName}
              inputMode="text"
              role="textbox"
            />
          ) : (
            profile.profileName
          )}
        </Heading>
      </Spacing>
      {profile.website && (
        <Spacing size={2} position="b">
          <Paragraph size="xxs">
            {isOwner ? (
              <ContentEditable
                tagName="span"
                onFocus={() => onToggle?.(true)}
                onBlur={(val) => handleContentChange(val, 'website')}
                onKeyDown={disableEnterKeyDown}
                html={profile.website.replace(protocolRegex, '')}
                inputMode="url"
                role="textbox"
                className={styles.website}
              />
            ) : (
              <Link
                href={profile.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {profile.website.replace(protocolRegex, '')}
              </Link>
            )}
          </Paragraph>
        </Spacing>
      )}
      <Spacing size={2} position="b">
        <Paragraph size="xxs">
          {isOwner ? (
            <ContentEditable
              tagName="span"
              onFocus={() => onToggle?.(true)}
              onBlur={(val) => handleContentChange(val, 'bio')}
              onKeyDown={disableEnterKeyDown}
              html={profile.bio}
              inputMode="text"
              role="textbox"
            />
          ) : (
            profile.bio
          )}
        </Paragraph>
      </Spacing>

      <motion.div
        initial={false}
        animate={{
          height: error ? 65 : 0
        }}
        transition={transition}
      >
        <AnimatePresence initial={false}>
          {error && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={variants}
            >
              <Alert kind="error">{error}</Alert>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      {isOwner ? (
        <OwnerActions slug={profile.slug} />
      ) : (
        <VisitorActions profileUserId={profile.userId} />
      )}
    </div>
  );
};

export default UserProfileInfo;
