import React from 'react';

import Heading from '../../../atoms/Heading';
import MaxWidth from '../../../atoms/MaxWidth';
import PageWrap from '../../../atoms/PageWrap';
import Spacing from '../../../atoms/Spacing';

import generalStyles from './style/general.module.css';

const Intro = ({ title, copy }) => {
  return (
    <PageWrap>
      <div className={generalStyles.container}>
        <Spacing size={[2, 4, 6]} position="b" type="padding">
          <MaxWidth value={800}>
            <Spacing size={2} position="b">
              <Heading size={['l', 'xl', 'xxl']} tag="h2">
                {title}
              </Heading>
            </Spacing>
            <div className={generalStyles.description}>{copy}</div>
          </MaxWidth>
        </Spacing>
      </div>
    </PageWrap>
  );
};

export default Intro;
