import * as Sentry from "@sentry/react";
import compact from 'lodash/compact';
import intersectionWith from 'lodash/intersectionWith';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';

import config from '../config';

import * as trackingActions from '../actions/tracking';

import { timestampsExpired } from '../helpers/tracking';

const TRACKING_EXPIRY_HOURS = 24;
const UTM_EXPIRY_HOURS = 72;
const CREATOR_EXPIRY_HOURS = 24;

export default function adsNetworkMiddleware() {
  return ({ dispatch, getState }) => (next) => (action) => {
    try {
      if (action.$isSync) {
        // Pass through actions synced using BroadcastChannel
        return next(action);
      }

      if (['SHOP', 'HOME'].includes(action.type)) {
        dispatch(
          trackingActions.setOrigin({ source: config.salesChannels.everpress })
        );
      }

      if (
        [
          'CAMPAIGN_RECEIVED',
          'STORE_RECEIVED',
          'GET_PROFILE_FULFILLED',
          'ADD_CART_ITEM_FULFILLED'
        ].includes(action.type)
      ) {
        const { location } = getState();
        const { shop, home, utmParams, origin } = getState().tracking;

        const campaignId =
          action.payload?.campaignId ?? action.meta?.campaignId;
        const campaignUserId =
          action.payload?.user?.userId ?? action.meta?.user?.userId;

        const trackingTimestamps = compact([shop.timestamp, home.timestamp]);
        const utmTimestamps = compact([utmParams.timestamp]);
        const creatorTimestamps = compact([origin.creator.timestamp]);

        const isFirstVisit = timestampsExpired(
          trackingTimestamps,
          TRACKING_EXPIRY_HOURS
        );
        const isUtmSession = !timestampsExpired(
          utmTimestamps,
          UTM_EXPIRY_HOURS
        );
        const isCreatorSession = !timestampsExpired(
          creatorTimestamps,
          CREATOR_EXPIRY_HOURS
        );

        const isLandingPage = !location.prev.type;

        const isMarketingUtmSession =
          isUtmSession &&
          !isEmpty(
            intersectionWith(
              toPairs(utmParams),
              toPairs(config.utmParams.marketing),
              isEqual
            )
          );

        if (['STORE_RECEIVED', 'GET_PROFILE_FULFILLED'].includes(action.type)) {
          const { userId } = action.payload?.user ?? action.payload;

          if (
            !isMarketingUtmSession &&
            !isCreatorSession &&
            isLandingPage &&
            isFirstVisit
          ) {
            dispatch(trackingActions.setOrigin({ creator: { userId } }));
          }

          return next(action);
        }

        /**
         * We use ?? here to handle the two different `state.tracking.origin` objects
         * users may have in localStorage, depending on when they first visited the site.
         */
        const originalCampaignId =
          origin.campaign?.campaignId ?? origin.campaignId;

        const isOriginalCampaign = originalCampaignId === campaignId;

        const isOriginalCreatorCampaign =
          origin.creator.userId === campaignUserId;

        const payload = isMarketingUtmSession
          ? { source: config.salesChannels.utm }
          : isCreatorSession && isOriginalCreatorCampaign
          ? { source: config.salesChannels.creator }
          : isOriginalCampaign
          ? { source: config.salesChannels.creator }
          : isLandingPage
          ? isFirstVisit
            ? { source: config.salesChannels.creator, campaign: { campaignId } }
            : { source: config.salesChannels.everpress }
          : { source: config.salesChannels.everpress };

        dispatch(trackingActions.setOrigin(payload));
      }

      return next(action);
    } catch (err) {
      Sentry.captureException(err);

      return next(action);
    }
  };
}
