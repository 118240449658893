import React from 'react';

import PageWrap from '../../../atoms/PageWrap';
import Spacing from '../../../atoms/Spacing';
import Heading from '../../../atoms/Heading';
import { Grid, GridItem } from '../../../atoms/Grid';

import generalStyles from './style/general.module.css';

const Features = ({ title, features }) => {
  return (
    <PageWrap>
      <Spacing size={[2, 3, 4, 5, 6]} type="padding">
        <Spacing size={[2, 4, 4]} position="b">
          <Heading size={['l', 'xl', 'xxl']} center tag="h2">
            {title}
          </Heading>
        </Spacing>
        <div className={generalStyles.table}>
          {features.map((item, index) => (
            <div key={index} className={generalStyles.tableItem}>
              <Grid gap={1}>
                <GridItem columnSize={[12, 12, 12, 2]}>
                  <Spacing size={[2, 2, 2, 0]} position="b">
                    <img
                      className={generalStyles.icon}
                      src={item.icon}
                      alt={item.title}
                    />
                  </Spacing>
                </GridItem>
                <GridItem columnSize={[12, 12, 12, 10]}>
                  <Spacing size={2} position="b">
                    <Heading>{item.title}</Heading>
                  </Spacing>
                  <div>{item.description}</div>
                </GridItem>
              </Grid>
            </div>
          ))}
        </div>
      </Spacing>
    </PageWrap>
  );
};

export default Features;
