import React from 'react';
import PropTypes from 'prop-types';

import hexToRgb from '../../../../helpers/hexToRgb';
import getAdaptiveColour from '../../../../helpers/getAdaptiveColour';

import ColourCircle from '../../../atoms/ColourCircle';
import Icon from '../../../atoms/Icon';

import checkIcon from '../../../../img/sprites/check.svg';

import generalStyles from './style/general.module.css';

const iconSizes = {
  m: 16,
  l: 18
};

export default function ColourCircleCheckbox({
  name,
  hex,
  size,
  id,
  children,
  value,
  onChange,
  ...domProps
}) {
  const hexCode = hex && hex.replace('#', '');
  const rgb = hexToRgb(hex);
  const tickColour = getAdaptiveColour(rgb);
  return (
    <label htmlFor={name} className={generalStyles.holder}>
      <input
        type="checkbox"
        name={name}
        id={id || name}
        value={value}
        checked={value}
        onChange={onChange}
        className={generalStyles.input}
        {...domProps}
      />
      <div className={generalStyles.container}>
        <div className={generalStyles.circle}>
          <ColourCircle code={hexCode} size={size} />
          <div className={generalStyles.checkbox}>
            <Icon glyph={checkIcon} fill={tickColour} width={iconSizes[size]} height={iconSizes[size]} />
          </div>
        </div>
        {children}
      </div>
    </label>
  );
}

ColourCircleCheckbox.propTypes = {
  name: PropTypes.string,
  hex: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func
};

ColourCircleCheckbox.defaultProps = {
  size: 'l'
};
