import produce from 'immer';

const initialState = {
  bySlug: {}
};

export default (state = initialState, action) =>
  produce(state, newState => {
    const { type, payload, meta } = action;

    switch (type) {
      case 'GET_ORDER_FULFILLED':
        const { campaigns, ...data } = payload;
        newState.bySlug[meta.token] = {
          ...newState.bySlug[payload.token],
          data,
          isLoaded: true
        };
        return newState;
      case 'GET_ORDER_REJECTED':
        newState.bySlug[meta.token] = {
          isLoaded: true
        };
        return newState;
      case 'CLAIM_ORDER_FULFILLED':
        return state;
      case 'CLAIM_ORDER_REJECTED':
        return state;
      default:
        return state;
    }
  });
