import React from 'react';

import Spacing from '../../../../components/atoms/Spacing';
import Paragraph from '../../../../components/atoms/Paragraph';
import {
  Form,
  FieldSubmit,
  Error,
  Success
} from '../../../../components/atoms/Form';

const EndedCampaignForm = ({ onRequestEndedItem }) => {
  return (
    <Form
      initialValues={{
        email: ''
      }}
      onSubmit={onRequestEndedItem}
      render={({ isSubmitting, handleSubmit, status }) => {
        let statusMessage = null;
        const { error, success } = { ...status };
        if (error) {
          statusMessage = <Error>{status.error}</Error>;
        }
        if (success) {
          statusMessage = <Success>{status.success}</Success>;
        }
        return (
          <form onSubmit={handleSubmit}>
            <Paragraph size="xxxs">
              Want this product to come back? <br /> Enter your email and we'll
              let you know when it's back.
            </Paragraph>
            <Spacing size={1}>
              <FieldSubmit name="email" placeholder="Your email address" />
            </Spacing>
            {statusMessage}
          </form>
        );
      }}
    />
  );
};

export default EndedCampaignForm;
