import React from 'react';

import richTextSerializer from '../../../helpers/prismic/richTextSerializer';

import { ButtonNew as Button } from '../../../components/atoms/Button';
import PageWrap from '../../../components/atoms/PageWrap';
import Heading from '../../../components/atoms/Heading';
import MaxWidth from '../../../components/atoms/MaxWidth';
import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';
import { CarouselFade, CarouselItem } from '../../../components/atoms/Carousel';

import generalStyles from './style/general.module.css';

export default function SingleFeature({ slides, heading, isCustomLanding }) {
  const headingText =
    typeof heading === 'string' ? heading : richTextSerializer(heading);

  return (
    <div className={generalStyles.singleFeature}>
      {!!heading ? (
        <div className="z-10">
          <PageWrap>
            <MaxWidth value={614}>
              <Spacing size={3} type="padding">
                <Heading tag="h1" size={['m', 'l', 'xl', 'xxl']} color="white">
                  {headingText}
                </Heading>
              </Spacing>
            </MaxWidth>
          </PageWrap>
        </div>
      ) : null}

      <CarouselFade duration={6000}>
        {slides.map((item, index) => {
          const bgImageStyles = {
            backgroundImage: `url(${item.image && item.image.url})`,
            backgroundPosition: 'center'
          };

          const bgImageProps = {
            className: generalStyles.bgImage,
            style: bgImageStyles
          };

          const key = `carouselItem-${index}`;

          return (
            <CarouselItem
              className="flex flex-col justify-end"
              index={index}
              key={key}
            >
              <div {...bgImageProps} />
              <div className="z-10">
                <PageWrap>
                  {isCustomLanding ? (
                    <Spacing size={[2, 4]} type="padding">
                      <MaxWidth value={600}>
                        <Spacing size={[1, 2]} type="padding">
                          <Heading
                            color="white"
                            size={['xs', 'base', 's', 'm']}
                            tag="h4"
                          >
                            {item.title[0].text}
                          </Heading>
                        </Spacing>
                        <Button
                          to={item.slug && item.slug[0].text}
                          color="white"
                        >
                          {item.button[0].text}
                        </Button>
                      </MaxWidth>
                    </Spacing>
                  ) : (
                    <Spacing size={[2, 4, 8]} type="padding">
                      <MaxWidth value={460}>
                        <Heading color="white" size={['m', 'l', 'xl']} tag="h2">
                          {item.title[0].text}
                        </Heading>
                        <div className="flex items-end">
                          <Paragraph size="xs" color="white" inline>
                            <span className="mr-1">
                              {item.description && item.description[0].text}
                            </span>
                            <Button
                              to={item.slug && item.slug[0].text}
                              kind="outline"
                              size="tiny"
                              color="white"
                            >
                              Shop now
                            </Button>
                          </Paragraph>
                        </div>
                      </MaxWidth>
                    </Spacing>
                  )}
                </PageWrap>
              </div>
            </CarouselItem>
          );
        })}
      </CarouselFade>
    </div>
  );
}
