export const convertPxToMm = (px) => {
  return Math.round((px * 25.4) / 300);
};

export const convertMmToIn = (mm) => {
  return mm / 25.4;
};

export const scaleToDPI = (box, dpi) => {
  box.width = (box.width * 72) / dpi;
  box.height = (box.height * 72) / dpi;

  return box;
};

export const getImageDimensionsFromDataURL = (dataURL) =>
  new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width
      });
    };

    img.src = dataURL;
  });

export const calculateAspectRatioFit = (
  srcWidth,
  srcHeight,
  maxWidth,
  maxHeight
) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return {
    width: Math.floor(srcWidth * ratio),
    height: Math.floor(srcHeight * ratio)
  };
};
