import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import differenceInHours from 'date-fns/difference_in_hours';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';
import isPast from 'date-fns/is_past';
import parse from 'date-fns/parse';

import Icon from '../../../components/atoms/Icon';
import { InlineGrid } from '../../../components/atoms/Grid';
import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';

import WrapIf from '../../../components/utils/WrapIf';

import Timer from './Timer';
import { PreorderTooltip } from './Tooltips';

import timeIcon from '../../../img/sprites/time.svg';

const COUNTDOWN_HOURS = 48;

const CampaignTimeLeft = ({ endAt, isPreorder = false, ...props }) => {
  const endDate = parse(endAt);
  const hasEnded = isPast(endDate);
  const isEndingSoon =
    !hasEnded && differenceInHours(endDate, new Date()) < COUNTDOWN_HOURS;
  const timeLeft =
    !hasEnded &&
    !isEndingSoon &&
    distanceInWordsStrict(new Date(), endDate, {
      roundingMethod: 'floor'
    });

  return (
    <InlineGrid justify="start" align="center" {...props}>
      <Spacing size="05" position="r">
        <Icon glyph={timeIcon} width={22} height={22} />
      </Spacing>
      <Paragraph color="darkest-dark-grey" size="xxs">
        {hasEnded ? (
          <span className="text-black">This campaign has now ended</span>
        ) : (
          <>
            <span className="text-black">
              Only{' '}
              <WrapIf
                condition={isPreorder}
                wrapper={(children) => (
                  <PreorderTooltip>{children}</PreorderTooltip>
                )}
              >
                <span
                  className={classNames('border-b', 'border-dotted', {
                    'text-red-dark border-red-dark': isEndingSoon,
                    'cursor-pointer': isPreorder
                  })}
                >
                  {isEndingSoon ? (
                    <Timer deadline={endDate} />
                  ) : (
                    `${timeLeft} left`
                  )}
                </span>
              </WrapIf>
            </span>{' '}
            to {isPreorder ? 'pre-order' : 'order'} this campaign
          </>
        )}
      </Paragraph>
    </InlineGrid>
  );
};

export default CampaignTimeLeft;

CampaignTimeLeft.propTypes = {
  endAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  isPreorder: PropTypes.bool
};
