import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { fontSizeMap, fontColorMap } from '../../../helpers/themeMap';
import propToClassName from '../../../helpers/propToClassName';

Heading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(Object.keys(fontSizeMap))),
    PropTypes.string
  ]),
  tag: PropTypes.string,
  center: PropTypes.bool,
  color: PropTypes.string
};

export default function Heading({
  className,
  children,
  size,
  tag,
  center,
  color,
  ...props
}) {
  const Tag = tag;
  const sizeClassName = propToClassName(size, fontSizeMap);
  const colorClassName = propToClassName(color, fontColorMap);

  const headingClassName = classNames('heading', className, sizeClassName, {
    [colorClassName]: color !== Heading.defaultProps.color,
    'text-center': center
  });

  return (
    <Tag className={headingClassName} {...props}>
      {children}
    </Tag>
  );
}

Heading.defaultProps = {
  size: 'm',
  tag: 'span',
  center: false,
  color: 'black',
  className: ''
};
