export const garmentTypes = [
  {
    slug: 't-shirts',
    name: 'T-Shirts',
    description: 'New drops daily from independent artists.'
  },
  {
    slug: 'long-sleeve',
    name: 'Long Sleeve T-Shirts',
    description: 'New drops daily from independent artists.'
  },
  {
    slug: 'sweatshirts',
    name: 'Sweatshirts',
    description: 'New drops daily from independent artists.'
  },
  {
    slug: 'hoodies',
    name: 'Hoodies',
    description: 'New drops daily from independent artists.'
  },
  {
    slug: 'caps',
    name: 'Caps',
    description: 'New drops daily from independent artists.'
  },
  {
    slug: 'beanies',
    name: 'Beanies',
    description: 'New drops daily from independent artists.'
  },
  {
    slug: 'art-prints',
    name: 'Art Prints',
    description: 'New drops daily from independent artists.'
  },
  {
    slug: 'bags',
    name: 'Bags',
    description: 'New drops daily from independent artists.'
  },
  {
    slug: 'crops-vests',
    name: 'Crops & Vests',
    description: 'New drops daily from independent artists.'
  },
  {
    slug: 'lifestyle',
    name: 'Lifestyle',
    description: 'New drops daily from independent artists.'
  },
  {
    slug: 'mugs',
    name: 'Mugs',
    description: 'New drops daily from independent artists.'
  }
];
