import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import flow from 'lodash/flow';
import partialRight from 'lodash/partialRight';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import size from 'lodash/size';
import flatMapFp from 'lodash/fp/flatMap';
import filterFp from 'lodash/fp/filter';
import mapFp from 'lodash/fp/map';
import minByFp from 'lodash/fp/minBy';
import some from 'lodash/fp/some';

export const sortColours = (colours) => {
  if (isEmpty(colours)) return [];

  const sortedColours = sortBy(colours, (colour) => {
    if (
      // White can have a different colourId depending on product
      colour.name === 'White'
    )
      return 0;

    if (colour.name === 'Black') return 1;
  });

  return sortedColours;
};

export const concatColourToEnd = (arr, colour) => {
  const updatedArr = [
    ...arr.filter((item) => item.colourId !== colour.colourId),
    colour
  ];

  return updatedArr;
};

export const transformInitial = (arr) =>
  arr.reduce(
    (acc, f) => ({
      ...acc,
      [f]: false
    }),
    {}
  );

export const objectDeepDiff = (data, prevData) => {
  const record = {};

  Object.keys(data).forEach((key) => {
    // Checks that isn't an object and isn't equal
    if (!(typeof data[key] === 'object' && isEqual(data[key], prevData[key]))) {
      record[key] = data[key];
    }

    // If is an object, and the object isn't equal
    if (typeof data[key] === 'object' && !isEqual(data[key], prevData[key])) {
      record[key] = objectDeepDiff(data[key], prevData[key]);
    }
  });

  return record;
};

export const getInitialProducts = (catalogueProducts, campaignProducts) => {
  return Object.assign(
    {},
    ...catalogueProducts.map((product) => {
      const colours = Object.assign(
        {},
        ...product.colours.map((colour) => {
          const isSelected = campaignProducts.find(
            (campaignProduct) =>
              campaignProduct.product.sku === product.sku &&
              campaignProduct.colours.find((col) => col.code === colour.code)
          );
          return { [colour.code]: !!isSelected };
        })
      );

      return {
        [product.sku]: {
          colours
        }
      };
    })
  );
};

export const getInitialColours = (catalogueProduct, campaignProduct) => {
  return Object.assign(
    {},
    catalogueProduct.colours.reduce((acc, colour) => {
      const isSelected =
        campaignProduct.product.sku === catalogueProduct.sku &&
        campaignProduct.colours.find((col) => col.code === colour.code);

      return { ...acc, [colour.code]: !!isSelected };
    }, {})
  );
};

export const getSelectedColoursLength = (formValues) =>
  flow([
    partialRight(flatMap, 'colours'),
    partialRight(map, (obj) => Object.values(obj)),
    flatten,
    filter,
    size
  ])(formValues);

export const getMaxAreaFromProducts = (products, areaType) =>
  flow([
    mapFp('product'),
    flatMapFp('areas'),
    filterFp({ slug: areaType }),
    minByFp((area) => area.width * area.height)
  ])(products);

export const getImageMeta = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = url;
  });
};

export const hasDarkColours = (campaignProducts = []) =>
  flow([flatMapFp('colours'), some({ dark: true })])(campaignProducts);
