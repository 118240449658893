import React, { Fragment } from 'react';
import classNames from 'classnames';
import Observer from 'react-intersection-observer';

import { theme } from '../../../theme-config';
import getOptimImgUrl from '../../../helpers/getOptimImgUrl';

export default class Image extends React.Component {
  static defaultProps = {
    lazyLoad: true,
    withPlaceholder: true,
    placeholderColor: theme.colors['dark-grey']
  };

  componentDidMount() {
    // If image was cached
    if (
      this.imgEl &&
      this.imgEl.complete &&
      this.imgEl.naturalHeight !== 0 &&
      this.props.onLoad
    ) {
      // Trigger onload event manually
      this.props.onLoad();
    }
  }

  renderImage(isInViewport) {
    const {
      onLoad,
      className,
      imgClassName,
      width,
      height,
      withPlaceholder,
      placeholderColor,
      src,
      alt,
      lazyLoad,
      ...props
    } = this.props;
    const paddingBottomRatio = height / width;
    const placeholderStyles = {
      paddingBottom: `${paddingBottomRatio * 100}%`,
      height: 0,
      backgroundColor: placeholderColor
    };
    const imgixOptions = withPlaceholder
      ? {
          bg: placeholderColor.substr(1),
          fit: 'fill'
        }
      : {};
    const imgSrc = src && getOptimImgUrl(src, width, height, imgixOptions);

    return (
      <picture
        className={classNames(className)}
        style={withPlaceholder ? placeholderStyles : {}}
        onLoad={onLoad}
        {...props}
      >
        {imgSrc && (
          <Fragment>
            <source
              media="(min-width: 1200px)"
              srcSet={
                isInViewport
                  ? `${imgSrc} 1x,
                    ${imgSrc}&q=80&dpr=2 2x`
                  : undefined
              }
            />
            <img
              className={classNames(imgClassName)}
              srcSet={
                isInViewport
                  ? `${imgSrc} 1x,
                    ${imgSrc}&q=80&dpr=2 2x`
                  : undefined
              }
              src={isInViewport ? imgSrc : undefined}
              ref={(ref) => (this.imgEl = ref)}
              alt={alt}
              style={{ visibility: isInViewport ? 'visible' : 'hidden' }}
            />
          </Fragment>
        )}
      </picture>
    );
  }

  render() {
    const { lazyLoad } = this.props;
    if (lazyLoad) {
      return (
        <Observer triggerOnce>
          {(isInViewport) => this.renderImage(isInViewport)}
        </Observer>
      );
    } else {
      return this.renderImage(true);
    }
  }
}
