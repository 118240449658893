import React from 'react';
import { connect } from 'react-redux';

import { getCampaignBySlug } from '../../../helpers/selectors/campaigns';
import generalStyles from './style/general.module.css';
import * as campaignsActions from '../../../actions/campaigns';
import config from '../../../config';

const CampaignAdminPanel = ({ campaign }) => {
  if (!campaign || !campaign.adminPanel) {
    return false;
  }

  const {
    adminPanel: {
      campaignId,
      parentCampaignId,
      status,
      revenue,
      soldCount,
      campaignUser
    }
  } = campaign;

  const { adminUrl } = config;

  return (
    <div className={generalStyles.header}>
      <ul>
        <li className={generalStyles.dataItem}>
          Campaign ID:{' '}
          <a
            href={`${adminUrl}campaign/${campaignId}`}
            target="_blank"
            rel="noreferrer"
            className={generalStyles.dataItemLink}
          >
            {campaignId}
          </a>
        </li>
        {parentCampaignId && (
          <li className={generalStyles.dataItem}>
            Parent Campaign Id:{' '}
            <a
              href={`${adminUrl}campaign/${parentCampaignId}`}
              target="_blank"
              rel="noreferrer"
              className={generalStyles.dataItemLink}
            >
              {parentCampaignId}
            </a>
          </li>
        )}
        <li
          className={`${generalStyles.dataItem} ${generalStyles.dataItemUppercase}`}
        >
          Status: {status}
        </li>
        <li className={generalStyles.dataItem}>Sold: {soldCount}</li>
        <li className={generalStyles.dataItem}>Revenue: £{revenue}</li>
        <li className={generalStyles.dataItem}>
          Creator: {campaignUser.firstName} {campaignUser.lastName} (
          {campaignUser.email})
        </li>
        <li className={generalStyles.dataItem}>
          Creator ID:{' '}
          <a
            href={`${adminUrl}user/${campaignUser.userId}`}
            target="_blank"
            rel="noreferrer"
            className={generalStyles.dataItemLink}
          >
            {campaignUser.userId}
          </a>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = ({ campaigns, location }) => {
  const { slug_1, slug_2 } = location.payload;
  const campaign =
    getCampaignBySlug(slug_2, campaigns) ||
    getCampaignBySlug(slug_1, campaigns);
  return { campaign };
};

export default connect(mapStateToProps, { ...campaignsActions })(
  CampaignAdminPanel
);
