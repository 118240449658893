import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Heading from '../../../components/atoms/Heading';
import MaxWidth from '../../../components/atoms/MaxWidth';
import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';
import Price from '../../../components/atoms/Price';
import { ButtonNew as Button } from '../../../components/atoms/Button';
import { Grid, GridItem } from '../../../components/atoms/Grid';
import ColourCircle from '../../../components/atoms/ColourCircle';
import Tag from '../../../components/atoms/Tag';
import Image from '../../../components/atoms/Image';

import { Modal } from '../../../components/molecules/Modal';

import generalStyles from './style/general.module.css';

class GarmentModal extends Component {
  state = {
    selectedColourCode: null
  };

  handleMouseEnter(colourCode) {
    this.setState({
      selectedColourCode: colourCode
    });
  }

  handleMouseLeave() {
    this.setState({
      selectedColourCode: this.props.item.featuredColour.code
    });
  }

  render() {
    const { isOpen, item, onClose } = this.props;
    const { selectedColourCode } = this.state;

    if (!item) {
      return null;
    }

    return (
      <Modal isOpen={isOpen} onRequestClose={onClose} isTransparent>
        <div className={generalStyles.wrapper}>
          <Grid gap={[1, 1, 4]} align="flex">
            <GridItem columnSize={[12, 12, 6, 5]}>
              <MaxWidth value={500} center>
                <div
                  className="flex items-start justify-center"
                  style={{
                    backgroundColor: `#${selectedColourCode}`
                  }}
                >
                  <Image
                    src={item.imageUrl}
                    alt={`${item.name} shot`}
                    className="flex items-start justify-center"
                    width="500"
                    height="500"
                    lazyLoad={false}
                    withPlaceholder={false}
                  />
                </div>
              </MaxWidth>
            </GridItem>
            <GridItem columnSize={[12, 12, 6, 7]}>
              <Spacing position="b" size={[3, 5]}>
                <Heading size="xs" tag="h3">
                  {item.brand}
                </Heading>
                <Spacing size={1}>
                  <Heading size={['m', 'l', 'xl']} tag="h1">
                    {item.name}
                  </Heading>
                </Spacing>
              </Spacing>

              <Grid align="start" gap={[1, 1, 4]}>
                <GridItem columnSize={[12, 12, 12, 6]}>
                  <Heading size="xs" tag="h4">
                    Production information
                  </Heading>
                  <Spacing size={2}>
                    <Paragraph size="xxxs">Product ID</Paragraph>
                    <Tag>{item.sku}</Tag>
                  </Spacing>
                  <Spacing size={2}>
                    <Paragraph size="xxs">{item.description}</Paragraph>
                  </Spacing>
                  <Paragraph size="xxxs">Base cost:</Paragraph>
                  <Heading size="xs" tag="div">
                    <Price value={item.baseCost} />
                  </Heading>
                </GridItem>
                <GridItem columnSize={[12, 12, 12, 6]}>
                  <Spacing size={[2, 2, 2, 0]}>
                    <Heading size="xs" tag="h4">
                      Colour options
                    </Heading>
                    <Spacing size={2}>
                      <div className="flex flex-wrap">
                        {item.colours.map((colour) => (
                          <div
                            key={colour.code}
                            className={generalStyles.colour}
                            onMouseEnter={() =>
                              this.handleMouseEnter(colour.code)
                            }
                            onMouseLeave={this.handleMouseLeave.bind(this)}
                          >
                            <ColourCircle code={colour.code} size="m" />
                          </div>
                        ))}
                      </div>
                    </Spacing>
                    {item.care && (
                      <Fragment>
                        <Heading size="xs" tag="h4">
                          Garment care
                        </Heading>
                        <Spacing size={2}>
                          <Paragraph size="xxs">
                            <span
                              dangerouslySetInnerHTML={{ __html: item.care }}
                            />
                          </Paragraph>
                        </Spacing>
                      </Fragment>
                    )}
                    <Spacing size={2}>
                      <Button to={`/create/product/${item.sku}`} isExternal>
                        Create campaign
                      </Button>
                    </Spacing>
                  </Spacing>
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(GarmentModal);
