import compact from 'lodash/compact';
import flow from 'lodash/flow';
import keys from 'lodash/keys';
import partial from 'lodash/partial';
import pickBy from 'lodash/pickBy';
import merge from 'lodash/merge';

const count = (arr = []) => arr.length;
const flattenToArray = (obj) =>
  obj && typeof obj === 'object'
    ? Object.values(obj)
        .map(flattenToArray)
        .flat()
    : [obj];
const toEntries = (obj) => Object.entries(obj);
const fromEntries = (entries) => Object.fromEntries(entries);
const transformActive = ([key, val]) => [key, keys(pickBy(val))];
const transformActiveEntries = (entries) => entries.map(transformActive);

const transformEnabled = (value, [key, val]) => {
  const newVal = val.reduce((acc, curr) => {
    if (curr.enabled) acc[curr.value] = value;
    return acc;
  }, {});

  return [key, newVal];
};

export const getEnabledFilterOptions = (options) => {
  return options.filter((item) => item.enabled);
};

export const getActiveFiltersCount = (filters) =>
  flow(flattenToArray, compact, count)(filters);

export const transformActiveFilterSets = (filterSets) =>
  flow(toEntries, transformActiveEntries, fromEntries)(filterSets);

export const transformInitialFilterSets = (filterSets, value = true) => {
  const transformEnabledEntries = (entries) =>
    entries.map(partial(transformEnabled, value));

  return flow(toEntries, transformEnabledEntries, fromEntries)(filterSets);
};

export const getSearchHitsProductPrices = (hits = []) => {
  return hits.map(({ objectID: id, campaignProductPrice }) =>
    merge({ id }, campaignProductPrice)
  );
};
