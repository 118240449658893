import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Spacing from '../../../atoms/Spacing';

import propToClassName from '../../../../helpers/propToClassName';

const borderMap = {
  l: 'border-l-1',
  r: 'border-r-1',
  t: 'border-t-1',
  b: 'border-b-1',
  all: 'border-1'
};

const sizeMap = {
  s: {
    x: 0,
    y: 2
  },
  m: {
    x: [1, 3, 5],
    y: [1, 2, 3]
  }
};

export default function BoxItem({
  children,
  border,
  size,
  isBorderLight,
  className
}) {
  const borderClassName = propToClassName(border, borderMap);
  const boxItemClassName = classNames(borderClassName, className, {
    'border-solid': border,
    'border-darker-grey': isBorderLight
  });

  return (
    <div className={boxItemClassName}>
      <Spacing size={sizeMap[size].x} type="padding" position="x">
        <Spacing size={sizeMap[size].y} type="padding">
          {children}
        </Spacing>
      </Spacing>
    </div>
  );
}

BoxItem.propTypes = {
  children: PropTypes.node.isRequired,
  border: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  size: PropTypes.oneOf(['s', 'm']),
  isBorderLight: PropTypes.bool
};

BoxItem.defaultProps = {
  border: '',
  size: 'm',
  isBorderLight: false
};
