import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Link from '../../../atoms/Link';
import Heading from '../../../atoms/Heading';
import Spacing from '../../../atoms/Spacing';

import WrapIf from '../../../utils/WrapIf';

import generalStyles from './style/general.module.css';

export default function ContentCard({
  title,
  image,
  slug,
  fullWidth,
  noBorderRadius
}) {
  const dispatch = useDispatch();
  const imgSrc = fullWidth ? image.SingleCard.url : image.url;

  const handleCardClick = () => {
    dispatch({ type: 'CONTENT_CARD_CLICKED', payload: { slug } });
  };

  return (
    <WrapIf
      condition={!!slug}
      wrapper={(children) => (
        <Link to={slug} noHover isUnderlined={false}>
          {children}
        </Link>
      )}
    >
      <div
        className={classNames('relative', 'bg-cover', 'bg-center', {
          [generalStyles.fullWidth]: fullWidth,
          [generalStyles.defaultWidth]: !fullWidth,
          'rounded-md': !noBorderRadius
        })}
        style={{ backgroundImage: `url("${imgSrc}")` }}
        onClick={handleCardClick}
      />
      <Spacing size={15} position="t">
        <Heading size="xxs" tag="h3">
          {title}
        </Heading>
      </Spacing>
    </WrapIf>
  );
}

ContentCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  addtional_title: PropTypes.string,
  cta: PropTypes.string,
  slug: PropTypes.string,
  img: PropTypes.shape({
    url: PropTypes.string
  })
};
