import React from 'react';
import { PropTypes } from 'prop-types';

import WrapIf from '../../utils/WrapIf';

import Avatar from '../../atoms/Avatar';
import { ButtonNew as Button } from '../../atoms/Button';
import { CSSGrid, CSSGridItem } from '../../atoms/Grid';
import Paragraph from '../../atoms/Paragraph';
import Link from '../../atoms/Link';
import Heading from '../../atoms/Heading';

import { campaignNamePropTypes, creatorNamePropTypes } from './propTypes';

const FollowArtist = ({
  campaignName,
  creatorName,
  creatorInitials,
  onButtonClick,
  isFollowing,
  slug
}) => {
  const creatorNameFallback = campaignName ? 'Creator of' : 'Creator';

  const handleFollow = async () => {
    onButtonClick();
  };

  return (
    <CSSGrid
      template="repeat(2, auto) / auto 1fr"
      columnGap={1}
      align="center"
      alignContent="center"
    >
      <CSSGridItem
        row={{ start: 1, end: 2 }}
        column={{ start: 1, end: 2 }}
        height="auto"
      >
        <WrapIf
          condition={slug}
          wrapper={(children) => (
            <Link to={`/profile/${slug}`}>{children}</Link>
          )}
        >
          <Avatar initials={creatorInitials} />
        </WrapIf>
      </CSSGridItem>
      <CSSGridItem
        row={{ start: 1, end: 2 }}
        column={{ start: 2, end: 3 }}
        height="auto"
      >
        <WrapIf
          condition={slug}
          wrapper={(children) => (
            <Link to={`/profile/${slug}`} isUnderlined={false}>
              {children}
            </Link>
          )}
        >
          <Heading tag="h2" size="xs" style={{ wordBreak: 'break-word' }}>
            {creatorName || creatorNameFallback}
          </Heading>
        </WrapIf>
        {campaignName && (
          <Paragraph size="xxs" color="grey-dark" className="uppercase">
            {campaignName}
          </Paragraph>
        )}
      </CSSGridItem>
      <CSSGridItem
        row={{ start: 1, end: 2 }}
        column={{ start: 3 }}
        height="auto"
      >
        <Button
          kind={isFollowing ? 'outline' : 'solid'}
          size="small"
          onClick={handleFollow}
          style={{ width: '111px' }}
          data-test-id="follow-artist-button"
        >
          {isFollowing ? 'Following' : 'Follow'}
        </Button>
      </CSSGridItem>
    </CSSGrid>
  );
};

FollowArtist.propTypes = {
  campaignName: campaignNamePropTypes,
  creatorName: creatorNamePropTypes,
  onButtonClick: PropTypes.func.isRequired,
  isFollowing: PropTypes.bool.isRequired,
  slug: PropTypes.string
};

export default FollowArtist;
