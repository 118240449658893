import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Alert from '../../../components/atoms/Alert';
import { ButtonNew as Button } from '../../../components/atoms/Button';
import Heading from '../../../components/atoms/Heading';
import Icon from '../../../components/atoms/Icon';
import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';
import LoadingSpinner from '../../../components/atoms/Button/ButtonNew/LoadingSpinner';

import { ModalNew as Modal } from '../../../components/molecules/Modal';

import {
  saveUserProfileBanner,
  saveUserProfilePicture
} from '../../../actions/user-profile';

import uploadIcon from '../../../img/sprites/upload.svg';
import cameraIcon from '../../../img/sprites/camera.svg';

const MAX_FILE_SIZE = 10000000; // 10MB

function fileSizeValidator(file) {
  if (file.size > MAX_FILE_SIZE) {
    return {
      code: 'size-too-large',
      message: `File size is larger than 10MB`
    };
  }

  return null;
}

const ImageSettings = ({ type, profileId, onModalToggle, onUpload }) => {
  const [thumb, setThumb] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDrop = async (files) => {
    setUploadError(null);

    const file = files[0];

    try {
      setThumb({ ...file, preview: URL.createObjectURL(file) });
      setFile(file);
    } catch (err) {
      // Errors handled by react-dropzone (fileRejections)
    }
  };

  const handleDropError = (err) => {
    setUploadError(err);
  };

  const handleFileDialogOpen = () => {
    setFile(null);
  };

  const handleSave = async () => {
    const data = new FormData();

    data.append('files', file);
    data.append('profileId', profileId);

    try {
      setLoading(true);

      if (type === 'picture') {
        await dispatch(saveUserProfilePicture(file.preview, data));
      }

      if (type === 'banner') {
        await dispatch(saveUserProfileBanner(file.preview, data));
      }

      setModalOpen(false);
      onUpload?.();
    } catch (err) {
      setUploadError(err);
    } finally {
      setLoading(false);
    }
  };

  const {
    fileRejections,
    open,
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    maxFiles: 1,
    multiple: false,
    onFileDialogOpen: handleFileDialogOpen,
    onDrop: handleDrop,
    onError: handleDropError,
    validator: fileSizeValidator
  });

  const handleModalOpen = () => {
    onModalToggle?.(true);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setThumb(null);
    setFile(null);
    onModalToggle?.(false);
    setUploadError(null);
    setModalOpen(false);
  };

  useEffect(() => {
    if (isEmpty(fileRejections)) return;

    const errorMessage = fileRejections[0].errors[0]?.message;

    setUploadError(errorMessage);
  }, [fileRejections]);

  const thumbProps =
    type === 'picture'
      ? {
          className: 'rounded-full',
          style: { width: '120px', height: '120px', objectFit: 'cover' }
        }
      : type === 'banner'
      ? { style: { width: '100%', height: '100px', objectFit: 'cover' } }
      : undefined;

  const recommendedSize =
    type === 'picture'
      ? '300x300px'
      : type === 'banner'
      ? '2000x500px'
      : undefined;

  return (
    <div>
      <Modal isOpen={isModalOpen} handleClose={handleModalClose}>
        <Spacing size={4} position="t">
          <div className="flex justify-center">
            <div
              {...getRootProps()}
              className="flex items-center justify-center bg-grey-lightest rounded-sm"
              style={{
                width: '200px',
                height: '200px',
                border: '1px dashed gray'
              }}
            >
              {isLoading ? (
                <LoadingSpinner
                  kind="solid"
                  size="large"
                  color="white"
                  style={{ top: '17rem' }}
                />
              ) : (
                <Icon glyph={uploadIcon} width={30} height={30} />
              )}
              <input {...getInputProps()} className="hidden" />
            </div>
          </div>
        </Spacing>
        <Spacing size={2} position="t">
          <Heading size="xs" tag="h3" center>
            {isDragActive ? 'Drop the file here' : 'Drag and drop file here'}
          </Heading>
        </Spacing>
        {file && (
          <Spacing size={1} position="t">
            <aside>
              <Spacing size={5} position="t">
                {thumb?.preview && (
                  <div className="my-1 flex justify-center">
                    <img
                      src={thumb.preview}
                      alt="Preview"
                      // Revoke data uri after image is loaded
                      onLoad={() => {
                        URL.revokeObjectURL(thumb.preview);
                      }}
                      {...thumbProps}
                    />
                  </div>
                )}
                <Paragraph size="xxs" className="ellipsis" center>
                  {file.name}
                </Paragraph>
              </Spacing>
            </aside>
          </Spacing>
        )}
        {uploadError ? (
          <Spacing size={15} position="t">
            <div className="flex justify-center">
              <Alert kind="error">{uploadError}</Alert>
            </div>
          </Spacing>
        ) : null}
        <Spacing size={5} position="t">
          <Paragraph size="xxs" center>
            Recommended size: {recommendedSize} JPG or PNG.
            <br />
            10MB max.
          </Paragraph>
        </Spacing>
        <Spacing size={2} position="t">
          <div className="flex gap-4 justify-center">
            <Button onClick={open} kind={file ? 'outline' : undefined}>
              Select
            </Button>
            {file && <Button onClick={handleSave}>Save</Button>}
          </div>
        </Spacing>
      </Modal>
      <Button size="small" kind="solid" color="white" onClick={handleModalOpen}>
        <span className="mr-05">
          <Icon glyph={cameraIcon} width={14} height={14} />
        </span>
        Edit image
      </Button>
    </div>
  );
};

ImageSettings.propTypes = {
  profileId: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['picture', 'banner']).isRequired,
  onModalToggle: PropTypes.func,
  onUpload: PropTypes.func
};

export default ImageSettings;
