import React, { Fragment } from 'react';
import * as yup from 'yup';
import get from 'lodash/get';
import {
  CardCVCElement,
  injectStripe,
  CardExpiryElement,
  CardNumberElement
} from 'react-stripe-elements';

import config from '../../../../config';

import Alert from '../../../../components/atoms/Alert';
import Spacing from '../../../../components/atoms/Spacing';
import Paragraph from '../../../../components/atoms/Paragraph';
import {
  DefaultField as Field,
  Form,
  handleFormError
} from '../../../../components/atoms/Form';
import { NewFieldStripe as FieldStripe } from '../../../../components/atoms/Form/Field/NewFieldStripe';
import { ButtonNew as Button } from '../../../../components/atoms/Button';
import Price from '../../../../components/atoms/Price';
import SelectCountries from '../../../../components/atoms/Select/SelectCountries';

import TermsFormField from '../../../../components/molecules/TermsFormField';

const StripeForm = ({
  initialValues,
  handleSubmit,
  priceTotal,
  currency,
  stripe,
  isGuest
}) => {
  const submitStripeToken = async (values, actions) => {
    try {
      const response = await stripe.createToken();
      if (response && response.token) {
        handleSubmit({ ...values, stripeTokenId: response.token.id }, actions);
      } else {
        handleFormError(response.error, actions);
        return actions.setSubmitting(false);
      }
    } catch (error) {
      actions.setStatus({
        error: get(
          error,
          'response.data.errors.errors[0]',
          config.stripeErrors.default
        )
      });
      if (error.response.data.scaUrl) {
        window.location = error.response.data.scaUrl;
      }
      return actions.setSubmitting(false);
    }
  };

  return (
    <Form
      enableReinitialize
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        isBillingAddressDifferent: yup.boolean(),
        billingAddress: yup
          .object()
          .nullable()
          .when('isBillingAddressDifferent', {
            is: true,
            then: yup
              .object()
              .required()
              .shape({
                firstName: yup
                  .string()
                  .required()
                  .nullable(),
                lastName: yup
                  .string()
                  .required()
                  .nullable(),
                line1: yup
                  .string()
                  .required()
                  .max(45)
                  .nullable(),
                line2: yup
                  .string()
                  .nullable()
                  .max(45),
                city: yup
                  .string()
                  .required()
                  .nullable(),
                postcode: yup
                  .string()
                  .required()
                  .nullable(),
                country: yup
                  .string()
                  .required()
                  .nullable()
              })
          })
      })}
      onSubmit={submitStripeToken}
      render={({ isSubmitting, handleSubmit, status, values }) => {
        let statusMessage = null;
        const { error } = { ...status };
        if (error) {
          statusMessage = <Alert kind="error" message={status.error} />;
        }

        return (
          <form onSubmit={handleSubmit} data-test-id="checkout-form">
            {stripe && (
              <Fragment>
                <Spacing size={1} position="y">
                  <FieldStripe type={CardNumberElement} label="Card number" />
                </Spacing>
                <Spacing size={1} position="y">
                  <FieldStripe
                    type={CardExpiryElement}
                    label="Expiration date (MM / YY)"
                  />
                </Spacing>
                <Spacing size={1} position="y">
                  <FieldStripe
                    type={CardCVCElement}
                    label="Security code (3 digits on the back)"
                  />
                </Spacing>
              </Fragment>
            )}

            <Spacing size={2} position="y">
              <Field type="checkbox" name="isBillingAddressDifferent">
                <Paragraph size="xxs">
                  Billing address is different to shipping address
                </Paragraph>
              </Field>
            </Spacing>
            {values.isBillingAddressDifferent && (
              <Spacing size={2} position="y">
                <Spacing size={1}>
                  <Field
                    type="text"
                    name="billingAddress.firstName"
                    placeholder="First name"
                  />
                </Spacing>
                <Spacing size={1}>
                  <Field
                    type="text"
                    name="billingAddress.lastName"
                    placeholder="Last name"
                  />
                </Spacing>
                <Spacing size={3} position="t">
                  <SelectCountries
                    name="billingAddress.country"
                    placeholder="Country"
                    useNewStyles
                  />
                </Spacing>
                <Spacing size={1}>
                  <Field
                    type="text"
                    name="billingAddress.line1"
                    placeholder="Address line 1"
                  />
                </Spacing>
                <Spacing size={1}>
                  <Field
                    type="text"
                    name="billingAddress.line2"
                    placeholder="Address line 2 (optional)"
                  />
                </Spacing>
                <Spacing size={1}>
                  <Field
                    type="text"
                    name="billingAddress.city"
                    placeholder="City or town"
                  />
                </Spacing>
                <Spacing size={1}>
                  <Field
                    type="text"
                    name="billingAddress.county"
                    placeholder="County, State, Province (optional)"
                  />
                </Spacing>
                <Spacing size={1}>
                  <Field
                    type="text"
                    name="billingAddress.postcode"
                    placeholder="Post code, Zip code"
                  />
                </Spacing>
              </Spacing>
            )}

            <div className="my-4 border-b-1 border-grey-lighter" />
            <Spacing size={2} position="y">
              {isGuest && (
                <Spacing size={1} position="y">
                  <TermsFormField
                    name="acceptTerms"
                    size="xxs"
                    data-test-id="accept-terms"
                  />
                </Spacing>
              )}
              <Field type="checkbox" name="isGift">
                <Paragraph size="xxs">This order is a gift</Paragraph>
              </Field>
            </Spacing>
            <Spacing size={4} position="b">
              <Button
                type="submit"
                state={isSubmitting ? 'loading' : null}
                fullWidth
                data-test-id="step-4-submit"
              >
                Place order and pay{' '}
                <Price
                  value={priceTotal}
                  currency={currency}
                  className="contents"
                />
              </Button>
              <Spacing size={15} position="t">
                {statusMessage}
              </Spacing>
            </Spacing>
          </form>
        );
      }}
    />
  );
};

export default injectStripe(StripeForm);
