import React from 'react';
import { useDispatch } from 'react-redux';
import SwiperCore, { A11y } from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/components/a11y/a11y.min.css';

import config from '../../../config';

import useLocalStorage from '../../../helpers/hooks/useLocalStorage';

import ProductThumbnail from '../../../components/molecules/Thumbnails/ProductThumbnail';
import { Rail, RailSlide } from '../../../components/molecules/Rail';

import './style/ShopRecentlyViewed.css';

SwiperCore.use([A11y]);

const ShopRecentlyViewed = () => {
  const [campaigns] = useLocalStorage(config.localStorageCampaignsKey);
  const dispatch = useDispatch();

  const displayCampaigns = campaigns?.filter((campaign, index) => {
    const featuredProduct = campaign.products?.find(
      (product) => product.featured
    );

    return (
      campaign.imageUrl &&
      campaign.title &&
      campaign.endAt &&
      campaign.slug &&
      featuredProduct &&
      featuredProduct.prices
    );
  });

  if (!displayCampaigns?.length) {
    return null;
  }

  return (
    <Rail
      type="Recently Viewed"
      title="Deja-Vu"
      subtitle="Revisit these campaigns"
      data-test-id="recently-viewed-rail"
    >
      {displayCampaigns.map((item) => {
        const featuredProduct = item.products?.find(
          (product) => product.featured
        );

        if (!displayCampaigns?.length) return null;

        return (
          <RailSlide key={item.key}>
            <div
              className="py-15"
              onClick={() =>
                dispatch({
                  type: 'RAIL_CAMPAIGN_CLICK',
                  payload: {
                    type: 'Recently Viewed'
                  }
                })
              }
            >
              <ProductThumbnail
                image={item.imageUrl}
                title={item.title}
                subTitle={item.subTitle || ''}
                price={featuredProduct.prices}
                timeLeft={item.endAt}
                slug={item.slug}
                isGreyBg={true}
              />
            </div>
          </RailSlide>
        );
      })}
    </Rail>
  );
};

export default ShopRecentlyViewed;
