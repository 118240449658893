import dazedIcon from '../../../img/sprites/logo-dazed.svg';
import amnestyIcon from '../../../img/sprites/logo-amnesty.svg';
import niceThatIcon from '../../../img/sprites/logo-nicethat.svg';
import idIcon from '../../../img/sprites/logo-id.svg';
import viceIcon from '../../../img/sprites/logo-vice.svg';
import bmgIcon from '../../../img/sprites/logo-bmg.svg';

const logos = [
  {
    id: 0,
    logo: dazedIcon,
    width: 100,
    height: 30
  },
  {
    id: 1,
    logo: amnestyIcon,
    width: 66,
    height: 102
  },
  {
    id: 2,
    logo: niceThatIcon,
    width: 125,
    height: 35
  },
  {
    id: 3,
    logo: idIcon,
    width: 30,
    height: 60
  },
  {
    id: 4,
    logo: viceIcon,
    width: 100,
    height: 40
  },
  {
    id: 5,
    logo: bmgIcon,
    width: 110,
    height: 40
  }
];

export default logos;
