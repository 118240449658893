import formatDate from 'date-fns/format';

import { getCampaignBySlug } from '../helpers/selectors/campaigns';

export const getLinkedCampaigns = (slug) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'GET_LINKED_CAMPAIGNS',
    payload: baseApi.get(`/campaign/${slug}/linked-campaigns`)
  });

export const requestEndedCampaign = (email, slug) => (
  dispatch,
  getState,
  { baseApi }
) =>
  dispatch({
    type: 'REQUEST_ENDED_CAMPAIGN',
    payload: baseApi.post(`/campaign/${slug}/request`, { email }),
    meta: { id: getCampaignBySlug(slug, getState().campaigns) }
  });

export const remindLiveCampaign = (email, slug) => (
  dispatch,
  getState,
  { baseApi }
) =>
  dispatch({
    type: 'REMIND_LIVE_CAMPAIGN',
    payload: baseApi.post(`/campaign/${slug}/reminder`, { email }),
    meta: { id: getCampaignBySlug(slug, getState().campaigns) }
  });

export const launchCampaign = (campaignId) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'LAUNCH_CAMPAIGN',
    payload: baseApi.post(`/campaign/${campaignId}/launch`),
    meta: { id: campaignId }
  });

export const isCampaignReady = (slug) => (dispatch, getState, { baseApi }) => {
  const campaign = getCampaignBySlug(slug, getState().campaigns);

  return dispatch({
    type: 'IS_CAMPAIGN_READY',
    payload: baseApi.get(`/campaign/${slug}/images-ready`),
    meta: { id: campaign.campaignId, campaign }
  });
};

export const getCampaign = (campaignId) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'GET_CAMPAIGN',
    payload: baseApi.get(`/campaign/${campaignId}`)
  });

export const endCampaign = (campaignId) => (dispatch, getState, { baseApi }) =>
  dispatch({
    type: 'END_CAMPAIGN',
    payload: baseApi.post(`/campaign/${campaignId}/end`),
    meta: { id: campaignId }
  });

export const deleteCampaign = (campaignId) => (
  dispatch,
  getState,
  { baseApi }
) =>
  dispatch({
    type: 'DELETE_CAMPAIGN',
    payload: baseApi.delete(`/campaign/${campaignId}`),
    meta: { id: campaignId }
  });

export const getCampaignArtworksPositions = (campaignId) => (
  dispatch,
  getState,
  { baseApi }
) => {
  dispatch({
    type: 'GET_CAMPAIGN_ARTWORKS_POSITIONS',
    payload: baseApi.get(`/campaign/${campaignId}/artwork-positions`),
    meta: { id: campaignId }
  });
};

export const getCampaignsStatsSummary = (startDate, endDate) => (
  dispatch,
  _,
  { baseApi }
) => {
  const format = 'YYYY-MM-DD';
  const params = {
    startDate: formatDate(startDate, format),
    endDate: formatDate(endDate, format)
  };

  return dispatch({
    type: 'GET_CAMPAIGNS_STATS_SUMMARY',
    payload: baseApi.get(`/user/stats/summary`, { params })
  });
};

export const getCampaignsStatsBreakdown = (startDate, endDate, campaignId) => (
  dispatch,
  _,
  { baseApi }
) => {
  const format = 'YYYY-MM-DD';
  const params = {
    startDate: formatDate(startDate, format),
    endDate: formatDate(endDate, format),
    campaignId: campaignId
  };

  return dispatch({
    type: 'GET_CAMPAIGNS_STATS_BREAKDOWN',
    payload: baseApi.get(`/user/stats/breakdown`, { params }),
    meta: { id: campaignId }
  });
};

export const getCampaignsProductSalesBreakdown = (campaignId) => (
  dispatch,
  _,
  { baseApi }
) => {
  return dispatch({
    type: 'GET_CAMPAIGN_PRODUCT_SALES_BREAKDOWN',
    payload: baseApi.get(`/user/stats/sales/${campaignId}`),
    meta: { id: campaignId }
  });
};

export const getCampaignsCountryBreakdown = (campaignId) => (
  dispatch,
  _,
  { baseApi }
) => {
  return dispatch({
    type: 'GET_CAMPAIGN_COUNTRY_BREAKDOWN',
    payload: baseApi.get(`/user/stats/${campaignId}/country-breakdown`),
    meta: { id: campaignId }
  });
};

export const getCampaignPlatformBreakdown = (campaignId) => (
  dispatch,
  _,
  { baseApi }
) => {
  return dispatch({
    type: 'GET_CAMPAIGN_PLATFORM_BREAKDOWN',
    payload: baseApi.get(`/user/stats/${campaignId}/platform-breakdown`),
    meta: { id: campaignId }
  });
};

export const getUserCampaigns = (options) => (dispatch, _, { baseApi }) => {
  const defaultOptions = {
    offset: 0,
    limit: 10,
    state: null,
    sort: 'createdAt',
    direction: 'DESC'
  };
  const params = { ...defaultOptions, ...options };

  return dispatch({
    type: 'GET_USER_CAMPAIGNS',
    payload: baseApi.get(`/user/campaigns`, { params })
  });
};

export const getUserCampaignsFull = (options) => (dispatch, _, { baseApi }) => {
  const defaultOptions = {
    offset: 0,
    state: null,
    sort: 'createdAt',
    direction: 'DESC'
  };
  const params = { ...defaultOptions, ...options };

  return dispatch({
    type: 'GET_USER_CAMPAIGNS_FULL',
    payload: baseApi.get(`/user/campaigns`, { params })
  });
};

export const getUserCampaign = (campaignId) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'GET_USER_CAMPAIGN',
    payload: baseApi.get(`/user/campaign/${campaignId}`)
  });

export const getAdminCampaign = (campaignId) => (dispatch, _, { baseApi }) => {
  dispatch({
    type: 'GET_ADMIN_CAMPAIGN',
    payload: baseApi.get(`/admin/campaign/${campaignId}`)
  });
};

export const getCampaignGarments = (campaignId) => (
  dispatch,
  getState,
  { baseApi }
) =>
  dispatch({
    type: 'GET_CAMPAIGN_GARMENTS',
    payload: baseApi.get(`/catalogue/products/${campaignId}`),
    meta: { id: campaignId }
  });

export const editCampaignSettings = (campaignId, data) => (
  dispatch,
  getState,
  { baseApi }
) =>
  dispatch({
    type: 'EDIT_CAMPAIGN_SETTINGS',
    async payload() {
      await baseApi.put(`/campaign/${campaignId}/settings`, data);
      return {
        campaignId,
        ...data
      };
    },
    meta: {
      campaignId,
      ...data
    }
  });

export const requestRelaunchCampaign = (campaignId) => (
  dispatch,
  _,
  { baseApi }
) =>
  dispatch({
    type: 'REQUEST_RELAUNCH_CAMPAIGN',
    payload: baseApi.get(`/campaign/${campaignId}/relaunch-request`)
  });

export const quickRelaunchCampaign = (campaignId) => (
  dispatch,
  _,
  { baseApi }
) =>
  dispatch({
    type: 'QUICK_RELAUNCH_CAMPAIGN',
    payload: baseApi.post(`/campaign/${campaignId}/relaunch`)
  });

export const requestDuplicateCampaign = (campaignId) => (
  dispatch,
  _,
  { baseApi }
) =>
  dispatch({
    type: 'REQUEST_DUPLICATE_CAMPAIGN',
    payload: baseApi.get(`/campaign/${campaignId}/duplicate-request`)
  });

export const duplicateCampaign = (campaignId) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'DUPLICATE_CAMPAIGN',
    payload: baseApi.post(`/campaign/${campaignId}/duplicate`)
  });

export const viewSizeChart = ({ modalToggleTrigger }) => (dispatch) => {
  return dispatch({
    type: 'VIEW_SIZE_CHART',
    meta: { modalToggleTrigger }
  });
};

export const downloadOrdersCSV = (campaignId) => (dispatch) =>
  dispatch({
    type: 'DOWNLOAD_ORDERS_CSV',
    meta: { campaignId }
  });
