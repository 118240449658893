import React from 'react';
import classNames from 'classnames/bind';

import generalStyles from './style/general.module.css';
import kindStyles from './style/kind.module.css';

const cx = classNames.bind({ ...generalStyles, ...kindStyles });

export default function Pill({ children, kind }) {
  return <span className={cx(generalStyles.pill, kind)}>{children}</span>;
}

Pill.defaultProps = {
  kind: 'default'
};
