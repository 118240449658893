import produce from 'immer';
import set from 'lodash/set';
import merge from 'lodash/merge';

const initialState = {
  bySlug: {},
  categories: {
    bySlug: {}
  }
};

function mergeCaseStudies(state, caseStudies) {
  return caseStudies.forEach(caseStudy => mergeCaseStudy(state, caseStudy));
}

function mergeCaseStudy(state, caseStudy) {
  if (state.bySlug[caseStudy.uid]) {
    merge(state.bySlug[caseStudy.uid], caseStudy);
  } else {
    state.bySlug[caseStudy.uid] = caseStudy;
  }
}

export default (state = initialState, action) =>
  produce(state, newState => {
    const { type, payload, meta } = action;

    if (
      type === 'FETCH_COLLECTION_PAGE_FULFILLED' &&
      meta.collection === 'case_studies_category'
    ) {
      return set(newState, ['categories', 'bySlug', meta.page], payload);
    }

    if (
      type === 'FETCH_COLLECTION_PAGE_FULFILLED' &&
      meta.collection === 'case_study'
    ) {
      return mergeCaseStudy(newState, payload);
    }

    if (
      type === 'FETCH_COLLECTION_FULFILLED' &&
      meta.collection === 'case_study'
    ) {
      return mergeCaseStudies(newState, payload);
    }
  });
